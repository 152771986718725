import { Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VisitorsAndAppointmentsComponent } from './visitors-and-appointments/visitors-and-appointments.component';
import { RoadwaysComponent } from './roadways/roadways.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { DailyStatisticsComponent } from './daily-statistics/daily-statistics.component';
import { AuthGuard } from './shared/auth/authGuard';
import { environment } from '../environments/environment';

export const routes: Routes = [
  { path: 'roadways', component: RoadwaysComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
  { path: 'vehicles', component: VehiclesComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
  { path: 'visitorsandappointments', component: VisitorsAndAppointmentsComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
  { path: 'statistics', component: DailyStatisticsComponent, canActivate: environment.authEnabled ? [AuthGuard] : []},
  { path: '',   redirectTo: '/roadways', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];
