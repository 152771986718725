<!-- <ng-container *ngIf="params.data && params.data.vehicle.isActive; else noDataLabel" > -->
<ng-container *ngIf="params.data">
  <button ds-button
          [icon]="params.data.vehicle.pairing ? 'link': 'unlink'"
          (click)="onClick()"
          variant="outline"
          class="custom-button"
          >
    {{params.data.vehicle.pairing ? "Verheiratet" : "Nicht Verheiratet"}}
  </button>
</ng-container>

<ng-template #noDataLabel>
  <span *ngIf="params.data" class="custom-label">
    <ds-icon [icon]="params.data.vehicle.pairing ? 'check': 'close'"></ds-icon> {{params.data.vehicle.pairing ? "Verheiratet" : "Nicht Verheiratet"}}
  </span>

</ng-template>

<app-pairing-dialog
  *ngIf="showPairingDialog() && params.data && params.data.vehicle.licensePlate"
  (dismissModalEvent)="closeModal()"
  (pairingResultEvent)="handlePairingResult()"
  [license_plate]="params.data.vehicle.licensePlate"
  [pairing]="{
    guideNumber: params.data.vehicle.pairing?.guideNumber,
    reason: params.data.vehicle.visitReason,
    vin: params.data.vehicle.vin,
  }">
</app-pairing-dialog>
