import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map, take } from "rxjs";
import debug from 'debug';

export const AuthGuard: CanActivateFn = () => {

    const logDebug = debug('app:AuthGuard:log');
    const logError = debug('app:AuthGuard:error*');
    
    logDebug.log = console.log.bind(console);
    logError.log = console.error.bind(console);

    const oidcSecurityService = inject(OidcSecurityService);

    logDebug("In AuthGuard");
    return oidcSecurityService.isAuthenticated$.pipe(
        take(1),
        map(({ isAuthenticated }) => {
            logDebug("isAuthenticated: " + isAuthenticated);
            if (!isAuthenticated) {
                // oidcSecurityService.authorize();
                return false;
            } else {
                return true
            }
        })
    );
};