import { Injectable, signal } from "@angular/core";
import { VehicleStayService } from "../vehicle-stay/vehicle-stay.service";
import { VehicleStay } from "../vehicle-stay/vehicle-stay.model";
import { DateService } from "../date/date.service";
import { VisitReason } from "../visit-reason.type";

@Injectable()
export class StatisticsService {

    public dataChangesAreMonitored = signal<boolean>(false);

    public data = signal<{timerange: string, Service: number, Gast: number, GA: number}[]>([]);

    public readonly timeranges = ['05:00-06:00', '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-00:00', '00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00', '04:00-05:00'];

    private generateEmptyTimeRangesArray() {
        const result: {timerange: string, Service: number, Gast: number, GA: number}[] = [];
        for (let i = 0; i < this.timeranges.length; i++) {
            result.push({
                timerange: this.timeranges[i],
                Service: 0,
                Gast: 0,
                GA: 0,
            });
        }
        return result;
    }

    private entryData: {timerange: string, Service: number, Gast: number, GA: number}[] = this.generateEmptyTimeRangesArray();
    private exitData: {timerange: string, Service: number, Gast: number, GA: number}[] = this.generateEmptyTimeRangesArray();

    constructor(private vehicleStayService: VehicleStayService,
        private dateService: DateService
    ) {
    }

    public async loadAll(): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        const newData = structuredClone(this.entryData);
        this.data.set(newData);
        return newData;
    }

    public async loadServiceEntryData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Service' && vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Service' && vehicleStay.identifiedDatetime);
        }
        
        this.entryData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.Service = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.identifiedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.entryData;
    }

    public async loadServiceExitData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Service' && vehicleStay.exitedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.exitedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Service' && vehicleStay.exitedDatetime);
        }
        this.exitData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.Service = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.exitedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.exitData;
    }

    public async loadGuestEntryData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Gast' && vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Gast' && vehicleStay.identifiedDatetime);
        }
        
        this.entryData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.Gast = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.identifiedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.entryData;
    }

    public async loadGuestExitData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Gast' && vehicleStay.exitedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.exitedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'Gast' && vehicleStay.exitedDatetime);
        }
        this.exitData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.Gast = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.exitedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.exitData;
    }

    public async loadGAEntryData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'GA' && vehicleStay.identifiedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.identifiedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'GA' && vehicleStay.identifiedDatetime);
        }
        
        this.entryData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.GA = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.identifiedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.entryData;
    }

    public async loadGAExitData(date?: Date): Promise<{timerange: string, Service: number, Gast: number, GA: number}[]> {
        let vehicleStays = await this.vehicleStayService.loadAll();
        if (date) {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'GA' && vehicleStay.exitedDatetime && this.dateService.areDatesOnSameDay(vehicleStay.exitedDatetime, date));
        } else {
            vehicleStays = vehicleStays.filter((vehicleStay: VehicleStay) => vehicleStay.vehicle.visitReason === 'GA' && vehicleStay.exitedDatetime);
        }
        this.exitData.forEach((data) => {
            const timerangeStart = parseInt(data.timerange.split('-')[0].split(':')[0]);
            data.GA = vehicleStays.filter((vehicleStay: VehicleStay) => {
                const vehicleStayIdentifiedHour = vehicleStay.exitedDatetime?.getHours();
                return vehicleStayIdentifiedHour && timerangeStart <= vehicleStayIdentifiedHour && vehicleStayIdentifiedHour < timerangeStart + 1;
            }).length
        });

        return this.exitData
    }

    public resetEntryData(serviceType: VisitReason) {
        switch (serviceType) {
            case "Service": {
                this.entryData.forEach((data) => {
                    data.Service = 0;
                });
                break;
            }
            case "Gast": {
                this.entryData.forEach((data) => {
                    data.Gast = 0;
                });
                break;
            }
            case "GA": {
                this.entryData.forEach((data) => {
                    data.GA = 0;
                });
                break;
            }
        }

        return this.entryData;
    }

    public resetExitData(serviceType: VisitReason) {
        switch (serviceType) {
            case "Service": {
                this.exitData.forEach((data) => {
                    data.Service = 0;
                });
                break;
            }
            case "Gast": {
                this.exitData.forEach((data) => {
                    data.Gast = 0;
                });
                break;
            }
            case "GA": {
                this.exitData.forEach((data) => {
                    data.GA = 0;
                });
                break;
            }
        }

        return this.exitData;
    }
}