import { Injectable } from '@angular/core';
import { BaseBackendService } from '../base-backend/base-backend.service';
import { Appointment } from './appointment.model';
import { AppointmentTransfer } from './appointment-transfer.model';
import { AppointmentService } from './appointment.service';

@Injectable()
export class AppointmentBackendService extends BaseBackendService<Appointment> implements AppointmentService {

  protected override getEndpoint() {
    return "v1/appointment/list";
  }

  protected override getWebsocketEndpoint = () => {
    return "";
  }

  protected override mapBackendToFrontend(data: AppointmentTransfer): Appointment {
    const {
      appointmentDatetime,
      ...otherFields
    } = data;

    const ret: Appointment = {
      appointmentDatetime: new Date(appointmentDatetime),
      ...otherFields
    };

    return ret;
  }
}
