import { Component, computed, effect, Input, input, signal } from '@angular/core';
import { DsButtonModule, DsFormFieldModule, DsLabelModule, DsLoadingAreaModule, DsTagModule, DsToastModule, } from '@bmw-ds/components';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { VehicleAllButtonCellRendererComponent } from '../vehicle-all-button-cell-renderer/vehicle-all-button-cell-renderer.component';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import { DateService } from '../shared/date/date.service';
import GridExportEvent from '../visitors-and-appointments/grid-export-event';
import { VehicleStatusCellRendererComponent } from '../vehicle-status-cell-renderer/vehicle-status-cell-renderer.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LaneService } from '../shared/streams/lane.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, GridApi, ProcessCellForExportParams, ColDef, SideBarDef, GridOptions, GridReadyEvent, DateFilterModel, IRowNode, SizeColumnsToContentStrategy, FilterModel, GetRowIdFunc, GetRowIdParams } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, StatusBarModule, MenuModule, SetFilterModule]);

@Component({
  selector: 'app-vehicle-ag-grid',
  standalone: true,
  imports: [
    AgGridAngular,
    CommonModule,
    DsButtonModule,
    DsToastModule,
    DsLoadingAreaModule,
    DsFormFieldModule,
    DsLabelModule,
    DsTagModule,
    FormsModule
  ],
  templateUrl: './vehicle-ag-grid.component.html',
  styleUrl: './vehicle-ag-grid.component.scss'
})
export class VehicleAgGridComponent {
  public gridApi!: GridApi;
  vehicles = input.required<VehicleStay[]>();
  vehiclesGroupedByLicensePlate = computed(() => {
    const allVehicles = this.vehicles();
    return this.groupBy(allVehicles, (vehicle: VehicleStay) => vehicle.vehicle.licensePlate);
  });
  localeText = AG_GRID_LOCALE_DE;
  _selectedFilterType: string = '';
  @Input()
  public set selectedFilterType(selectedFilterType: string) {
    if (selectedFilterType && selectedFilterType !== '') {
      if (this.gridApi) {
        this._selectedFilterType = selectedFilterType;
        this.gridApi.onFilterChanged();
      }
    }
  }

  get selectedFilterType(): string {
    return this._selectedFilterType;
  }

  _entryNumber: number = 0;
  @Input()
  public set entryNumber(entryNumber: number) {
    if (entryNumber && entryNumber !== 0) {
      if (this.gridApi) {
        this._entryNumber = entryNumber;
        this.gridApi.onFilterChanged();
      }
    }
  }

  get entryNumber(): number {
    return this._entryNumber;
  }

  groupModeIsActive = input.required<boolean>();

  constructor(
    private dateService: DateService,
    private gridExportEvent: GridExportEvent,
    public streamService: LaneService,
  ) {
    this.streamService.loadAll();
    this.exportGridToCsv = this.exportGridToCsv.bind(this);
    this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
    this.gridExportEvent.getEventEmitter().subscribe(this.exportGridToCsv);

    effect(() => {
      if (!this.groupModeIsActive()) {
        this.columns.update(
          columns => columns.map(column => column.field === "vehicle.licensePlate" ? {
            ...column,
            rowGroup: false,
            hide: false,
          } : column
          )
        )
      }
      else {
        this.columns.update(
          columns => columns.map(column => column.field === "vehicle.licensePlate" ? {
            ...column,
            rowGroup: true,
            hide: true,
          } : column
          )
        )
      }
    }, { allowSignalWrites: true })
  }

  private exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv({
        processCellCallback: (params: ProcessCellForExportParams) => {
          if (params && params.node && params.column.getColId() == 'vehicle.pairing') {
            return params.value ? "Verheiratet" : "Nicht Verheiratet"
          }

          return params.value;
        }
      });
    }
  }

  public autoGroupColumnDef: ColDef = {
    headerName: 'Kennzeichen'
  };

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",

        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filter",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
        },
      },
    ],
    defaultToolPanel: "",
  };

  columns = signal<ColDef<VehicleStay>[]>([
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
      rowGroup: true,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      field: 'vehicle.customer.companyName',
      headerName: 'Kunde',

    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunft',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.identifiedDatetime ? p.data?.identifiedDatetime : p.data?.transitedDatetime,
      valueFormatter: p => {
        if (p.data?.identifiedDatetime) {
          return `${p.data?.identifiedDatetime?.toLocaleDateString()} ${p.data?.identifiedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }

        if (p.data?.transitedDatetime) {
          return `${p.data?.transitedDatetime?.toLocaleDateString()} ${p.data?.transitedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }

        return "";
      },
      sort: "asc",
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Identifiziert',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.identifiedDatetime,
      valueFormatter: p => {
        if (p.data?.identifiedDatetime) {
          return `${p.data?.identifiedDatetime?.toLocaleDateString()} ${p.data?.identifiedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'transitedDatetime',
      headerName: 'Durchfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.transitedDatetime,
      valueFormatter: p => {
        if (p.data?.transitedDatetime) {
          return `${p.data?.transitedDatetime?.toLocaleDateString()} ${p.data?.transitedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'exitedDatetime',
      headerName: 'Ausfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.exitedDatetime,
      valueFormatter: p => {
        if (p.data?.exitedDatetime) {
          return `${p.data?.exitedDatetime?.toLocaleDateString()} ${p.data?.exitedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'vehicle.pairing',
      headerName: 'Verheiratet',
      cellRenderer: VehicleAllButtonCellRendererComponent,
    },
    {
      field: 'lane',
      headerName: 'Einfahrt',
      valueGetter: d => this.streamService.data().filter(lane => lane.laneId === d.data?.lane)[0]?.name,
    },
    {
      headerName: 'Status',
      hide: false,
      cellRenderer: VehicleStatusCellRendererComponent
    },
    {
      field: 'customerIsWaiting',
      headerName: 'Kunde wartet',
      hide: false,
      valueGetter: d => d.data?.customerIsWaiting ? "Ja" : "",
    },
    {
      filter: 'agNumberColumnFilter',
      valueGetter: d => d.data && d.data.identifiedDatetime && d.data.exitedDatetime && d.data.exitedDatetime > d.data.identifiedDatetime ? Math.round(this.dateService.getTimeDeltaInMinutes(d.data.exitedDatetime ? d.data.exitedDatetime : new Date(), d.data.identifiedDatetime)) : null,
      headerName: 'Dauer in Minuten',
      aggFunc: "sum"
    }
  ]);

  gridOptions = signal<GridOptions>({
    treeData: false,
    groupDefaultExpanded: -1,
    getDataPath: data => {
      return data.vehicle_license_plate;
    },
  });
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    // Filter by today's date
    const dateString = new Date(Date.now()).toISOString();
    const filterModel: DateFilterModel = {
      dateFrom: dateString,
      type: 'equals',
      dateTo: null
    }
    this.gridApi.setFilterModel({
      arrivalDatetime: filterModel
    });

    this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    // return this && this.selectedFilterType !== undefined;
    return this.selectedFilterType !== "" && this.selectedFilterType !== 'all';
  }

  doesExternalFilterPass(node: IRowNode): boolean {
    if (node.data) {
      const licensePlate: string = node.data.vehicle.licensePlate as string;
      const vehiclesByLicensePlateCount: number | undefined = this.vehiclesGroupedByLicensePlate().get(licensePlate)?.length;
      if (vehiclesByLicensePlateCount) {
        switch (this.selectedFilterType) {
          case 'lessThan':
            return vehiclesByLicensePlateCount < this.entryNumber;
          case 'greaterThan':
            return vehiclesByLicensePlateCount > this.entryNumber;
          case 'equals':
            return vehiclesByLicensePlateCount === this.entryNumber;
          case 'lessThanOrEquals':
            return vehiclesByLicensePlateCount <= this.entryNumber;
          case 'greaterThanOrEquals':
            return vehiclesByLicensePlateCount >= this.entryNumber;
          default:
            return true;
        }
      }
    }

    return true;
  }

  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
      },
    ],
  };

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }

  public dismissFilterForColumn(item: FilterModel) {
    this.gridApi.setColumnFilterModel(item['key'], null);
    this.gridApi.onFilterChanged();
  }

  public resetAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  public isDateFilterModel(item: DateFilterModel) {
    return 'dateFrom' in item;
  }

  public getColumnHeader(columnId: string) {
    const colDef = this.gridApi.getColumn(columnId);
    return colDef ? colDef.getColDef().headerName : columnId;
  }

  public getFormattedDate(dateString: string) {
    return new Date(dateString).toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  public paginationPageSize = 50;
  public paginationPageSizeSelector: number[] | boolean = [25, 50, 100, 200, 500, 1000];

  private groupBy(list: Array<VehicleStay>, keyGetter: (vehicleStay: VehicleStay) => string | undefined): Map<string, VehicleStay[]> {
    const map = new Map<string, VehicleStay[]>();
    list.forEach((item) => {
         const key = keyGetter(item);
         if (key) {
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
         }
    });
    return map;
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams<VehicleStay>) =>
    params.data.id;
}