import { CommonModule } from '@angular/common';
import { Component, computed, Signal } from '@angular/core';
import { DsBoxModule, DsTabsModule, DsToastService } from '@bmw-ds/components';
import { AppointmentComponent } from '../appointment/appointment.component';
import { Appointment } from '../shared/appointment/appointment.model';
import { AppointmentService } from '../shared/appointment/appointment.service';
import { DateService } from '../shared/date/date.service';

@Component({
  selector: 'app-appointments-list',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    DsTabsModule,
    AppointmentComponent
  ],
  templateUrl: './appointments-list.component.html',
  styleUrl: './appointments-list.component.scss'
})
export class AppointmentsListComponent {
  constructor(private dateService: DateService,
    private appointmentService: AppointmentService,
    private toastService: DsToastService,
  ) {
    (async() => {
      try {
        await this.appointmentService.loadAll();
      } catch (err) {
        this.toastService.pushToast({
          id: 'error-toast',
          tone: 'critical',
          toastText: 'Fehler beim Laden der Termine.'
        });
      }
    })();
  }

  public appointments: Signal<Appointment[]> = computed(() => {
    const appointments = this.appointmentService.data();
    const hoursToShow = 12;
    const minDate = new Date();
    minDate.setUTCHours(minDate.getUTCHours()-hoursToShow);
    const maxDate = new Date();
    maxDate.setUTCHours(maxDate.getUTCHours()+hoursToShow);
    const filteredAppointments = appointments.filter((a) => a.appointmentDatetime && a.appointmentDatetime >= minDate && a.appointmentDatetime <= maxDate);
    return filteredAppointments.sort((a, b) => a.appointmentDatetime.getTime() - b.appointmentDatetime.getTime());
  });

  public impendingAppointments: Signal<Appointment[]> = computed(() => {
    const appointments = this.appointments();
    const today = new Date();
    return appointments?.filter((appointment) => {
      const deltaMinutes = this.dateService.getTimeDeltaInMinutes(appointment.appointmentDatetime, today);
      return (today.getDate() === appointment.appointmentDatetime.getDate()) && (deltaMinutes > 0);
    });
  });

  public lateAppointments: Signal<Appointment[]> = computed(() => {
    const now = new Date();
    const appointments = this.appointments();
    return appointments?.filter((appointment) => {
      return now > appointment.appointmentDatetime;
    });
  });
}
