// community-modules/locale/src/en-US.ts
var AG_GRID_LOCALE_EN = {
  // Set Filter
  selectAll: "(Select All)",
  selectAllSearchResults: "(Select All Search Results)",
  addCurrentSelectionToFilter: "Add current selection to filter",
  searchOoo: "Search...",
  blanks: "(Blanks)",
  noMatches: "No matches",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Equals",
  notEqual: "Does not equal",
  blank: "Blank",
  notBlank: "Not blank",
  empty: "Choose one",
  // Number Filter
  lessThan: "Less than",
  greaterThan: "Greater than",
  lessThanOrEqual: "Less than or equal to",
  greaterThanOrEqual: "Greater than or equal to",
  inRange: "Between",
  inRangeStart: "From",
  inRangeEnd: "To",
  // Text Filter
  contains: "Contains",
  notContains: "Does not contain",
  startsWith: "Begins with",
  endsWith: "Ends with",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Before",
  after: "After",
  // Filter Conditions
  andCondition: "AND",
  orCondition: "OR",
  // Filter Buttons
  applyFilter: "Apply",
  resetFilter: "Reset",
  clearFilter: "Clear",
  cancelFilter: "Cancel",
  // Filter Titles
  textFilter: "Text Filter",
  numberFilter: "Number Filter",
  dateFilter: "Date Filter",
  setFilter: "Set Filter",
  // Group Column Filter
  groupFilterSelect: "Select field:",
  // Advanced Filter
  advancedFilterContains: "contains",
  advancedFilterNotContains: "does not contain",
  advancedFilterTextEquals: "equals",
  advancedFilterTextNotEqual: "does not equal",
  advancedFilterStartsWith: "begins with",
  advancedFilterEndsWith: "ends with",
  advancedFilterBlank: "is blank",
  advancedFilterNotBlank: "is not blank",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "is true",
  advancedFilterFalse: "is false",
  advancedFilterAnd: "AND",
  advancedFilterOr: "OR",
  advancedFilterApply: "Apply",
  advancedFilterBuilder: "Builder",
  advancedFilterValidationMissingColumn: "Column is missing",
  advancedFilterValidationMissingOption: "Option is missing",
  advancedFilterValidationMissingValue: "Value is missing",
  advancedFilterValidationInvalidColumn: "Column not found",
  advancedFilterValidationInvalidOption: "Option not found",
  advancedFilterValidationMissingQuote: "Value is missing an end quote",
  advancedFilterValidationNotANumber: "Value is not a number",
  advancedFilterValidationInvalidDate: "Value is not a valid date",
  advancedFilterValidationMissingCondition: "Condition is missing",
  advancedFilterValidationJoinOperatorMismatch: "Join operators within a condition must be the same",
  advancedFilterValidationInvalidJoinOperator: "Join operator not found",
  advancedFilterValidationMissingEndBracket: "Missing end bracket",
  advancedFilterValidationExtraEndBracket: "Too many end brackets",
  advancedFilterValidationMessage: "Expression has an error. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Expression has an error. ${variable} at end of expression.",
  advancedFilterBuilderTitle: "Advanced Filter",
  advancedFilterBuilderApply: "Apply",
  advancedFilterBuilderCancel: "Cancel",
  advancedFilterBuilderAddButtonTooltip: "Add Filter or Group",
  advancedFilterBuilderRemoveButtonTooltip: "Remove",
  advancedFilterBuilderMoveUpButtonTooltip: "Move Up",
  advancedFilterBuilderMoveDownButtonTooltip: "Move Down",
  advancedFilterBuilderAddJoin: "Add Group",
  advancedFilterBuilderAddCondition: "Add Filter",
  advancedFilterBuilderSelectColumn: "Select a column",
  advancedFilterBuilderSelectOption: "Select an option",
  advancedFilterBuilderEnterValue: "Enter a value...",
  advancedFilterBuilderValidationAlreadyApplied: "Current filter already applied.",
  advancedFilterBuilderValidationIncomplete: "Not all conditions are complete.",
  advancedFilterBuilderValidationSelectColumn: "Must select a column.",
  advancedFilterBuilderValidationSelectOption: "Must select an option.",
  advancedFilterBuilderValidationEnterValue: "Must enter a value.",
  // Side Bar
  columns: "Columns",
  filters: "Filters",
  // columns tool panel
  pivotMode: "Pivot Mode",
  groups: "Row Groups",
  rowGroupColumnsEmptyMessage: "Drag here to set row groups",
  values: "Values",
  valueColumnsEmptyMessage: "Drag here to aggregate",
  pivots: "Column Labels",
  pivotColumnsEmptyMessage: "Drag here to set column labels",
  // Header of the Default Group Column
  group: "Group",
  // Row Drag
  rowDragRow: "row",
  rowDragRows: "rows",
  // Other
  loadingOoo: "Loading...",
  loadingError: "ERR",
  noRowsToShow: "No Rows To Show",
  enabled: "Enabled",
  // Menu
  pinColumn: "Pin Column",
  pinLeft: "Pin Left",
  pinRight: "Pin Right",
  noPin: "No Pin",
  valueAggregation: "Value Aggregation",
  noAggregation: "None",
  autosizeThisColumn: "Autosize This Column",
  autosizeAllColumns: "Autosize All Columns",
  groupBy: "Group by",
  ungroupBy: "Un-Group by",
  ungroupAll: "Un-Group All",
  addToValues: "Add ${variable} to values",
  removeFromValues: "Remove ${variable} from values",
  addToLabels: "Add ${variable} to labels",
  removeFromLabels: "Remove ${variable} from labels",
  resetColumns: "Reset Columns",
  expandAll: "Expand All Row Groups",
  collapseAll: "Close All Row Groups",
  copy: "Copy",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copy With Headers",
  copyWithGroupHeaders: "Copy with Group Headers",
  cut: "Cut",
  paste: "Paste",
  ctrlV: "Ctrl+V",
  export: "Export",
  csvExport: "CSV Export",
  excelExport: "Excel Export",
  columnFilter: "Column Filter",
  columnChooser: "Choose Columns",
  chooseColumns: "Choose Columns",
  sortAscending: "Sort Ascending",
  sortDescending: "Sort Descending",
  sortUnSort: "Clear Sort",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Sum",
  first: "First",
  last: "Last",
  min: "Min",
  max: "Max",
  none: "None",
  count: "Count",
  avg: "Average",
  filteredRows: "Filtered",
  selectedRows: "Selected",
  totalRows: "Total Rows",
  totalAndFilteredRows: "Rows",
  more: "More",
  to: "to",
  of: "of",
  page: "Page",
  pageLastRowUnknown: "?",
  nextPage: "Next Page",
  lastPage: "Last Page",
  firstPage: "First Page",
  previousPage: "Previous Page",
  pageSizeSelectorLabel: "Page Size:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
  pivotChart: "Pivot Chart",
  chartRange: "Chart Range",
  columnChart: "Column",
  groupedColumn: "Grouped",
  stackedColumn: "Stacked",
  normalizedColumn: "100% Stacked",
  barChart: "Bar",
  groupedBar: "Grouped",
  stackedBar: "Stacked",
  normalizedBar: "100% Stacked",
  pieChart: "Pie",
  pie: "Pie",
  donut: "Donut",
  line: "Line",
  xyChart: "X Y (Scatter)",
  scatter: "Scatter",
  bubble: "Bubble",
  areaChart: "Area",
  area: "Area",
  stackedArea: "Stacked",
  normalizedArea: "100% Stacked",
  histogramChart: "Histogram",
  polarChart: "Polar",
  radarLine: "Radar Line",
  radarArea: "Radar Area",
  nightingale: "Nightingale",
  radialColumn: "Radial Column",
  radialBar: "Radial Bar",
  statisticalChart: "Statistical",
  boxPlot: "Box Plot",
  rangeBar: "Range Bar",
  rangeArea: "Range Area",
  hierarchicalChart: "Hierarchical",
  treemap: "Treemap",
  sunburst: "Sunburst",
  specializedChart: "Specialized",
  waterfall: "Waterfall",
  heatmap: "Heatmap",
  combinationChart: "Combination",
  columnLineCombo: "Column & Line",
  AreaColumnCombo: "Area & Column",
  // Charts
  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Chart",
  data: "Set Up",
  format: "Customize",
  categories: "Categories",
  defaultCategory: "(None)",
  series: "Series",
  switchCategorySeries: "Switch Category / Series",
  categoryValues: "Category Values",
  seriesLabels: "Series Labels",
  aggregate: "Aggregate",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  xAxis: "Horizontal Axis",
  yAxis: "Vertical Axis",
  polarAxis: "Polar Axis",
  radiusAxis: "Radius Axis",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "Crosshair",
  color: "Color",
  thickness: "Thickness",
  preferredLength: "Preferred Length",
  xType: "X Type",
  axisType: "Axis Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  timeFormat: "Time Format",
  autoRotate: "Auto Rotate",
  labelRotation: "Rotation",
  circle: "Circle",
  polygon: "Polygon",
  square: "Square",
  cross: "Cross",
  diamond: "Diamond",
  plus: "Plus",
  triangle: "Triangle",
  heart: "Heart",
  orientation: "Orientation",
  fixed: "Fixed",
  parallel: "Parallel",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Position",
  ticks: "Ticks",
  gridLines: "Grid Lines",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chartStyle: "Chart Style",
  title: "Title",
  chartTitles: "Titles",
  chartTitle: "Chart Title",
  chartSubtitle: "Subtitle",
  horizontalAxisTitle: "Horizontal Axis Title",
  verticalAxisTitle: "Vertical Axis Title",
  polarAxisTitle: "Polar Axis Title",
  titlePlaceholder: "Chart Title",
  background: "Background",
  font: "Font",
  weight: "Weight",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  calloutLabels: "Callout Labels",
  sectorLabels: "Sector Labels",
  positionRatio: "Position Ratio",
  size: "Size",
  shape: "Shape",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  lineDash: "Line Dash",
  lineDashOffset: "Dash Offset",
  scrollingZoom: "Scrolling",
  scrollingStep: "Scrolling Step",
  selectingZoom: "Selecting",
  durationMillis: "Duration (ms)",
  crosshairLabel: "Label",
  crosshairSnap: "Snap to Node",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeColor: "Line Color",
  strokeOpacity: "Line Opacity",
  miniChart: "Mini-Chart",
  histogramBinCount: "Bin count",
  connectorLine: "Connector Line",
  seriesItems: "Series Items",
  seriesItemType: "Item Type",
  seriesItemPositive: "Positive",
  seriesItemNegative: "Negative",
  seriesItemLabels: "Item Labels",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  polarGroup: "Polar",
  statisticalGroup: "Statistical",
  hierarchicalGroup: "Hierarchical",
  specializedGroup: "Specialized",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  donutTooltip: "Donut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radial Column",
  radialBarTooltip: "Radial Bar",
  radarLineTooltip: "Radar Line",
  radarAreaTooltip: "Radar Area",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Range Bar",
  rangeAreaTooltip: "Range Area",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Waterfall",
  heatmapTooltip: "Heatmap",
  columnLineComboTooltip: "Column & Line",
  areaColumnComboTooltip: "Area & Column",
  customComboTooltip: "Custom Combination",
  innerRadius: "Inner Radius",
  startAngle: "Start Angle",
  endAngle: "End Angle",
  reverseDirection: "Reverse Direction",
  groupPadding: "Group Padding",
  seriesPadding: "Series Padding",
  tile: "Tile",
  whisker: "Whisker",
  cap: "Cap",
  capLengthRatio: "Length Ratio",
  labelPlacement: "Placement",
  inside: "Inside",
  outside: "Outside",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Edit Chart",
  chartAdvancedSettings: "Advanced Settings",
  chartLink: "Link to Grid",
  chartUnlink: "Unlink from Grid",
  chartDownload: "Download Chart",
  histogramFrequency: "Frequency",
  seriesChartType: "Series Chart Type",
  seriesType: "Series Type",
  secondaryAxis: "Secondary Axis",
  seriesAdd: "Add a series",
  categoryAdd: "Add a category",
  bar: "Bar",
  column: "Column",
  histogram: "Histogram",
  advancedSettings: "Advanced Settings",
  direction: "Direction",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Group Type",
  groupedSeriesGroupType: "Grouped",
  stackedSeriesGroupType: "Stacked",
  normalizedSeriesGroupType: "100% Stacked",
  legendEnabled: "Enabled",
  invalidColor: "Color value is invalid",
  groupedColumnFull: "Grouped Column",
  stackedColumnFull: "Stacked Column",
  normalizedColumnFull: "100% Stacked Column",
  groupedBarFull: "Grouped Bar",
  stackedBarFull: "Stacked Bar",
  normalizedBarFull: "100% Stacked Bar",
  stackedAreaFull: "Stacked Area",
  normalizedAreaFull: "100% Stacked Area",
  customCombo: "Custom Combination",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Level ${variable}. Press ENTER to edit",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Level ${variable}. ${variable} Press ENTER to edit",
  ariaAdvancedFilterBuilderList: "Advanced Filter Builder List",
  ariaAdvancedFilterBuilderFilterItem: "Filter Condition",
  ariaAdvancedFilterBuilderGroupItem: "Filter Group",
  ariaAdvancedFilterBuilderColumn: "Column",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValueP: "Value",
  ariaAdvancedFilterBuilderJoinOperator: "Join Operator",
  ariaAdvancedFilterInput: "Advanced Filter Input",
  ariaChecked: "checked",
  ariaColumn: "Column",
  ariaColumnGroup: "Column Group",
  ariaColumnFiltered: "Column Filtered",
  ariaColumnSelectAll: "Toggle All Columns Visibility",
  ariaDateFilterInput: "Date Filter Input",
  ariaDefaultListName: "List",
  ariaFilterColumnsInput: "Filter Columns Input",
  ariaFilterFromValue: "Filter from value",
  ariaFilterInput: "Filter Input",
  ariaFilterList: "Filter List",
  ariaFilterToValue: "Filter to value",
  ariaFilterValue: "Filter Value",
  ariaFilterMenuOpen: "Open Filter Menu",
  ariaFilteringOperator: "Filtering Operator",
  ariaHidden: "hidden",
  ariaIndeterminate: "indeterminate",
  ariaInputEditor: "Input Editor",
  ariaMenuColumn: "Press ALT DOWN to open column menu",
  ariaFilterColumn: "Press CTRL ENTER to open filter",
  ariaRowDeselect: "Press SPACE to deselect this row",
  ariaRowSelectAll: "Press Space to toggle all rows selection",
  ariaRowToggleSelection: "Press Space to toggle row selection",
  ariaRowSelect: "Press SPACE to select this row",
  ariaRowSelectionDisabled: "Row Selection is disabled for this row",
  ariaSearch: "Search",
  ariaSortableColumn: "Press ENTER to sort",
  ariaToggleVisibility: "Press SPACE to toggle visibility",
  ariaToggleCellValue: "Press SPACE to toggle cell value",
  ariaUnchecked: "unchecked",
  ariaVisible: "visible",
  ariaSearchFilterValues: "Search filter values",
  ariaPageSizeSelectorLabel: "Page Size",
  ariaChartMenuClose: "Close Chart Edit Menu",
  ariaChartSelected: "Selected",
  ariaSkeletonCellLoadingFailed: "Row failed to load",
  ariaSkeletonCellLoading: "Row data is loading",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Row Groups",
  ariaValuesDropZonePanelLabel: "Values",
  ariaPivotDropZonePanelLabel: "Column Labels",
  ariaDropZoneColumnComponentDescription: "Press DELETE to remove",
  ariaDropZoneColumnValueItemDescription: "Press ENTER to change the aggregation type",
  ariaDropZoneColumnGroupItemDescription: "Press ENTER to sort",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " of ",
  ariaDropZoneColumnComponentSortAscending: "ascending",
  ariaDropZoneColumnComponentSortDescending: "descending",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Column Menu",
  ariaLabelColumnFilter: "Column Filter",
  ariaLabelCellEditor: "Cell Editor",
  ariaLabelSelectField: "Select Field",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rich Select Field",
  ariaLabelRichSelectToggleSelection: "Press SPACE to toggle selection",
  ariaLabelRichSelectDeselectAllItems: "Press DELETE to deselect all items",
  ariaLabelRichSelectDeleteSelection: "Press DELETE to deselect item",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Context Menu",
  ariaLabelSubMenu: "SubMenu",
  ariaLabelAggregationFunction: "Aggregation Function",
  ariaLabelAdvancedFilterAutocomplete: "Advanced Filter Autocomplete",
  ariaLabelAdvancedFilterBuilderAddField: "Advanced Filter Builder Add Field",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Advanced Filter Builder Column Select Field",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Advanced Filter Builder Option Select Field",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Advanced Filter Builder Join Operator Select Field",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Column List",
  ariaFilterPanelList: "Filter List",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "True",
  false: "False",
  invalidDate: "Invalid Date",
  invalidNumber: "Invalid Number",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/ar-EG.ts
var AG_GRID_LOCALE_EG = {
  // Set Filter
  selectAll: "(\u062A\u062D\u062F\u064A\u062F \u0627\u0644\u0643\u0644)",
  selectAllSearchResults: "(\u062A\u062D\u062F\u064A\u062F \u0643\u0644 \u0646\u062A\u0627\u0626\u062C \u0627\u0644\u0628\u062D\u062B)",
  addCurrentSelectionToFilter: "\u0625\u0636\u0627\u0641\u0629 \u0627\u0644\u062A\u062D\u062F\u064A\u062F \u0627\u0644\u062D\u0627\u0644\u064A \u0625\u0644\u0649 \u0627\u0644\u0641\u0644\u062A\u0631",
  searchOoo: "\u0628\u062D\u062B...",
  blanks: "(\u0641\u0627\u0631\u063A)",
  noMatches: "\u0644\u0627 \u062A\u0648\u062C\u062F \u062A\u0637\u0627\u0628\u0642\u0627\u062A",
  // Number Filter & Text Filter
  filterOoo: "\u062A\u0635\u0641\u064A\u0629...",
  equals: "\u064A\u0633\u0627\u0648\u064A",
  notEqual: "\u0644\u0627 \u064A\u0633\u0627\u0648\u064A",
  blank: "\u0641\u0627\u0631\u063A",
  notBlank: "\u0644\u064A\u0633 \u0641\u0627\u0631\u063A",
  empty: "\u0627\u062E\u062A\u0631 \u0648\u0627\u062D\u062F",
  // Number Filter
  lessThan: "\u0623\u0642\u0644 \u0645\u0646",
  greaterThan: "\u0623\u0643\u0628\u0631 \u0645\u0646",
  lessThanOrEqual: "\u0623\u0642\u0644 \u0645\u0646 \u0623\u0648 \u064A\u0633\u0627\u0648\u064A",
  greaterThanOrEqual: "\u0623\u0643\u0628\u0631 \u0645\u0646 \u0623\u0648 \u064A\u0633\u0627\u0648\u064A",
  inRange: "\u0628\u064A\u0646",
  inRangeStart: "\u0645\u0646",
  inRangeEnd: "\u0625\u0644\u0649",
  // Text Filter
  contains: "\u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649",
  notContains: "\u0644\u0627 \u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649",
  startsWith: "\u064A\u0628\u062F\u0623 \u0628\u0640",
  endsWith: "\u064A\u0646\u062A\u0647\u064A \u0628\u0640",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u0642\u0628\u0644",
  after: "\u0628\u0639\u062F",
  // Filter Conditions
  andCondition: "\u0648",
  orCondition: "\u0623\u0648",
  // Filter Buttons
  applyFilter: "\u062A\u0637\u0628\u064A\u0642",
  resetFilter: "\u0625\u0639\u0627\u062F\u0629 \u062A\u0639\u064A\u064A\u0646",
  clearFilter: "\u0645\u0633\u062D",
  cancelFilter: "\u0625\u0644\u063A\u0627\u0621",
  // Filter Titles
  textFilter: "\u0641\u0644\u062A\u0631 \u0627\u0644\u0646\u0635",
  numberFilter: "\u0641\u0644\u062A\u0631 \u0627\u0644\u0623\u0631\u0642\u0627\u0645",
  dateFilter: "\u0641\u0644\u062A\u0631 \u0627\u0644\u062A\u0627\u0631\u064A\u062E",
  setFilter: "\u0641\u0644\u062A\u0631 \u0627\u0644\u0645\u062C\u0645\u0648\u0639\u0629",
  // Group Column Filter
  groupFilterSelect: "\u0627\u062E\u062A\u0631 \u0627\u0644\u062D\u0642\u0644:",
  // Advanced Filter
  advancedFilterContains: "\u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649",
  advancedFilterNotContains: "\u0644\u0627 \u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649",
  advancedFilterTextEquals: "\u064A\u0633\u0627\u0648\u064A",
  advancedFilterTextNotEqual: "\u0644\u0627 \u064A\u0633\u0627\u0648\u064A",
  advancedFilterStartsWith: "\u064A\u0628\u062F\u0623 \u0628\u0640",
  advancedFilterEndsWith: "\u064A\u0646\u062A\u0647\u064A \u0628\u0640",
  advancedFilterBlank: "\u0641\u0627\u0631\u063A",
  advancedFilterNotBlank: "\u063A\u064A\u0631 \u0641\u0627\u0631\u063A",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u0635\u062D\u064A\u062D",
  advancedFilterFalse: "\u062E\u0627\u0637\u0626",
  advancedFilterAnd: "\u0648",
  advancedFilterOr: "\u0623\u0648",
  advancedFilterApply: "\u062A\u0637\u0628\u064A\u0642",
  advancedFilterBuilder: "\u0627\u0644\u0645\u0646\u0634\u0626",
  advancedFilterValidationMissingColumn: "\u0627\u0644\u0639\u0645\u0648\u062F \u0645\u0641\u0642\u0648\u062F",
  advancedFilterValidationMissingOption: "\u0627\u0644\u062E\u064A\u0627\u0631 \u0645\u0641\u0642\u0648\u062F",
  advancedFilterValidationMissingValue: "\u0627\u0644\u0642\u064A\u0645\u0629 \u0645\u0641\u0642\u0648\u062F\u0629",
  advancedFilterValidationInvalidColumn: "\u0627\u0644\u0639\u0645\u0648\u062F \u063A\u064A\u0631 \u0645\u0648\u062C\u0648\u062F",
  advancedFilterValidationInvalidOption: "\u0627\u0644\u062E\u064A\u0627\u0631 \u063A\u064A\u0631 \u0645\u0648\u062C\u0648\u062F",
  advancedFilterValidationMissingQuote: "\u0627\u0644\u0642\u064A\u0645\u0629 \u062A\u0641\u062A\u0642\u062F \u0639\u0644\u0627\u0645\u0629 \u0627\u0644\u0627\u0642\u062A\u0628\u0627\u0633 \u0627\u0644\u0646\u0647\u0627\u0626\u064A\u0629",
  advancedFilterValidationNotANumber: "\u0627\u0644\u0642\u064A\u0645\u0629 \u0644\u064A\u0633\u062A \u0631\u0642\u0645\u0627\u064B",
  advancedFilterValidationInvalidDate: "\u0627\u0644\u0642\u064A\u0645\u0629 \u0644\u064A\u0633\u062A \u062A\u0627\u0631\u064A\u062E\u0627\u064B \u0635\u062D\u064A\u062D\u0627\u064B",
  advancedFilterValidationMissingCondition: "\u0627\u0644\u0634\u0631\u0637 \u0645\u0641\u0642\u0648\u062F",
  advancedFilterValidationJoinOperatorMismatch: "\u064A\u062C\u0628 \u0623\u0646 \u062A\u0643\u0648\u0646 \u0639\u0648\u0627\u0645\u0644 \u0627\u0644\u0631\u0628\u0637 \u062F\u0627\u062E\u0644 \u0627\u0644\u0634\u0631\u0637 \u0646\u0641\u0633\u0647\u0627",
  advancedFilterValidationInvalidJoinOperator: "\u0639\u0627\u0645\u0644 \u0627\u0644\u0631\u0628\u0637 \u063A\u064A\u0631 \u0645\u0648\u062C\u0648\u062F",
  advancedFilterValidationMissingEndBracket: "\u0627\u0644\u0642\u0648\u0633 \u0627\u0644\u062E\u062A\u0627\u0645\u064A \u0645\u0641\u0642\u0648\u062F",
  advancedFilterValidationExtraEndBracket: "\u0623\u0642\u0648\u0627\u0633 \u062E\u062A\u0627\u0645\u064A\u0629 \u0643\u062B\u064A\u0631\u0629 \u062C\u062F\u0627\u064B",
  advancedFilterValidationMessage: "\u0627\u0644\u062A\u0639\u0628\u064A\u0631 \u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649 \u062E\u0637\u0623. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0627\u0644\u062A\u0639\u0628\u064A\u0631 \u064A\u062D\u062A\u0648\u064A \u0639\u0644\u0649 \u062E\u0637\u0623. ${variable} \u0641\u064A \u0646\u0647\u0627\u064A\u0629 \u0627\u0644\u062A\u0639\u0628\u064A\u0631.",
  advancedFilterBuilderTitle: "\u0639\u0627\u0645\u0644 \u062A\u0635\u0641\u064A\u0629 \u0645\u062A\u0642\u062F\u0645",
  advancedFilterBuilderApply: "\u062A\u0637\u0628\u064A\u0642",
  advancedFilterBuilderCancel: "\u0625\u0644\u063A\u0627\u0621",
  advancedFilterBuilderAddButtonTooltip: "\u0625\u0636\u0627\u0641\u0629 \u0639\u0627\u0645\u0644 \u062A\u0635\u0641\u064A\u0629 \u0623\u0648 \u0645\u062C\u0645\u0648\u0639\u0629",
  advancedFilterBuilderRemoveButtonTooltip: "\u0625\u0632\u0627\u0644\u0629",
  advancedFilterBuilderMoveUpButtonTooltip: "\u062A\u062D\u0631\u064A\u0643 \u0644\u0644\u0623\u0639\u0644\u0649",
  advancedFilterBuilderMoveDownButtonTooltip: "\u062A\u062D\u0631\u064A\u0643 \u0644\u0644\u0623\u0633\u0641\u0644",
  advancedFilterBuilderAddJoin: "\u0625\u0636\u0627\u0641\u0629 \u0645\u062C\u0645\u0648\u0639\u0629",
  advancedFilterBuilderAddCondition: "\u0625\u0636\u0627\u0641\u0629 \u0639\u0627\u0645\u0644 \u062A\u0635\u0641\u064A\u0629",
  advancedFilterBuilderSelectColumn: "\u0627\u062E\u062A\u0631 \u0639\u0645\u0648\u062F\u064B\u0627",
  advancedFilterBuilderSelectOption: "\u0627\u062E\u062A\u0631 \u062E\u064A\u0627\u0631\u064B\u0627",
  advancedFilterBuilderEnterValue: "\u0623\u062F\u062E\u0644 \u0642\u064A\u0645\u0629...",
  advancedFilterBuilderValidationAlreadyApplied: "\u0639\u0627\u0645\u0644 \u0627\u0644\u062A\u0635\u0641\u064A\u0629 \u0627\u0644\u062D\u0627\u0644\u064A \u0645\u064F\u0637\u0628\u0651\u064E\u0642 \u0628\u0627\u0644\u0641\u0639\u0644.",
  advancedFilterBuilderValidationIncomplete: "\u0644\u0645 \u064A\u062A\u0645 \u0625\u0643\u0645\u0627\u0644 \u0643\u0644 \u0627\u0644\u0634\u0631\u0648\u0637.",
  advancedFilterBuilderValidationSelectColumn: "\u064A\u062C\u0628 \u0627\u062E\u062A\u064A\u0627\u0631 \u0639\u0645\u0648\u062F.",
  advancedFilterBuilderValidationSelectOption: "\u064A\u062C\u0628 \u0627\u062E\u062A\u064A\u0627\u0631 \u062E\u064A\u0627\u0631.",
  advancedFilterBuilderValidationEnterValue: "\u064A\u062C\u0628 \u0625\u062F\u062E\u0627\u0644 \u0642\u064A\u0645\u0629.",
  // Side Bar
  columns: "\u0623\u0639\u0645\u062F\u0629",
  filters: "\u0645\u0631\u0634\u062D\u0627\u062A",
  // columns tool panel
  pivotMode: "\u0648\u0636\u0639 \u0627\u0644\u0645\u062D\u0648\u0631",
  groups: "\u0645\u062C\u0645\u0648\u0639\u0627\u062A \u0627\u0644\u0635\u0641\u0648\u0641",
  rowGroupColumnsEmptyMessage: "\u0627\u0633\u062D\u0628 \u0647\u0646\u0627 \u0644\u062A\u0639\u064A\u064A\u0646 \u0645\u062C\u0645\u0648\u0639\u0627\u062A \u0627\u0644\u0635\u0641\u0648\u0641",
  values: "\u0627\u0644\u0642\u064A\u0645",
  valueColumnsEmptyMessage: "\u0627\u0633\u062D\u0628 \u0647\u0646\u0627 \u0644\u0644\u062A\u062C\u0645\u064A\u0639",
  pivots: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  pivotColumnsEmptyMessage: "\u0627\u0633\u062D\u0628 \u0647\u0646\u0627 \u0644\u062A\u0639\u064A\u064A\u0646 \u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  // Header of the Default Group Column
  group: "\u0645\u062C\u0645\u0648\u0639\u0629",
  // Row Drag
  rowDragRow: "\u0635\u0641",
  rowDragRows: "\u0635\u0641\u0648\u0641",
  // Other
  loadingOoo: "\u062C\u0627\u0631\u064D \u0627\u0644\u062A\u062D\u0645\u064A\u0644...",
  loadingError: "\u062E\u0637\u0623",
  noRowsToShow: "\u0644\u0627 \u062A\u0648\u062C\u062F \u0635\u0641\u0648\u0641 \u0644\u0644\u0639\u0631\u0636",
  enabled: "\u0645\u064F\u0645\u0643\u0651\u0646",
  // Menu
  pinColumn: "\u062A\u062B\u0628\u064A\u062A \u0627\u0644\u0639\u0645\u0648\u062F",
  pinLeft: "\u062A\u062B\u0628\u064A\u062A \u0644\u0644\u064A\u0633\u0627\u0631",
  pinRight: "\u062A\u062B\u0628\u064A\u062A \u0644\u0644\u064A\u0645\u064A\u0646",
  noPin: "\u0628\u062F\u0648\u0646 \u062A\u062B\u0628\u064A\u062A",
  valueAggregation: "\u062A\u062C\u0645\u064A\u0639 \u0627\u0644\u0642\u064A\u0645\u0629",
  noAggregation: "\u0628\u062F\u0648\u0646 \u062A\u062C\u0645\u064A\u0639",
  autosizeThisColumn: "\u062A\u063A\u064A\u064A\u0631 \u062D\u062C\u0645 \u0647\u0630\u0627 \u0627\u0644\u0639\u0645\u0648\u062F \u062A\u0644\u0642\u0627\u0626\u064A\u064B\u0627",
  autosizeAllColumns: "\u062A\u063A\u064A\u064A\u0631 \u062D\u062C\u0645 \u062C\u0645\u064A\u0639 \u0627\u0644\u0623\u0639\u0645\u062F\u0629 \u062A\u0644\u0642\u0627\u0626\u064A\u064B\u0627",
  groupBy: "\u062A\u062C\u0645\u064A\u0639 \u0628\u0648\u0627\u0633\u0637\u0629",
  ungroupBy: "\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u062A\u062C\u0645\u064A\u0639 \u0628\u0648\u0627\u0633\u0637\u0629",
  ungroupAll: "\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u062A\u062C\u0645\u064A\u0639 \u0627\u0644\u0643\u0644",
  addToValues: "\u0625\u0636\u0627\u0641\u0629 ${variable} \u0625\u0644\u0649 \u0627\u0644\u0642\u064A\u0645",
  removeFromValues: "\u0625\u0632\u0627\u0644\u0629 ${variable} \u0645\u0646 \u0627\u0644\u0642\u064A\u0645",
  addToLabels: "\u0625\u0636\u0627\u0641\u0629 ${variable} \u0625\u0644\u0649 \u0627\u0644\u062A\u0633\u0645\u064A\u0627\u062A",
  removeFromLabels: "\u0625\u0632\u0627\u0644\u0629 ${variable} \u0645\u0646 \u0627\u0644\u062A\u0633\u0645\u064A\u0627\u062A",
  resetColumns: "\u0625\u0639\u0627\u062F\u0629 \u0636\u0628\u0637 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  expandAll: "\u062A\u0648\u0633\u064A\u0639 \u062C\u0645\u064A\u0639 \u0645\u062C\u0645\u0648\u0639\u0627\u062A \u0627\u0644\u0635\u0641\u0648\u0641",
  collapseAll: "\u0625\u063A\u0644\u0627\u0642 \u062C\u0645\u064A\u0639 \u0645\u062C\u0645\u0648\u0639\u0627\u062A \u0627\u0644\u0635\u0641\u0648\u0641",
  copy: "\u0646\u0633\u062E",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u0646\u0633\u062E \u0645\u0639 \u0627\u0644\u0631\u0624\u0648\u0633",
  copyWithGroupHeaders: "\u0646\u0633\u062E \u0645\u0639 \u0631\u0624\u0648\u0633 \u0627\u0644\u0645\u062C\u0645\u0648\u0639\u0627\u062A",
  cut: "\u0642\u0635",
  paste: "\u0644\u0635\u0642",
  ctrlV: "Ctrl+V",
  export: "\u062A\u0635\u062F\u064A\u0631",
  csvExport: "\u062A\u0635\u062F\u064A\u0631 CSV",
  excelExport: "\u062A\u0635\u062F\u064A\u0631 Excel",
  columnFilter: "\u062A\u0635\u0641\u064A\u0629 \u0627\u0644\u0639\u0645\u0648\u062F",
  columnChooser: "\u0627\u062E\u062A\u064A\u0627\u0631 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  chooseColumns: "\u0627\u062E\u062A\u064A\u0627\u0631 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  sortAscending: "\u062A\u0631\u062A\u064A\u0628 \u062A\u0635\u0627\u0639\u062F\u064A",
  sortDescending: "\u062A\u0631\u062A\u064A\u0628 \u062A\u0646\u0627\u0632\u0644\u064A",
  sortUnSort: "\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u062A\u0631\u062A\u064A\u0628",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u0627\u0644\u0645\u062C\u0645\u0648\u0639",
  first: "\u0627\u0644\u0623\u0648\u0644",
  last: "\u0627\u0644\u0623\u062E\u064A\u0631",
  min: "\u0627\u0644\u062D\u062F \u0627\u0644\u0623\u062F\u0646\u0649",
  max: "\u0627\u0644\u062D\u062F \u0627\u0644\u0623\u0642\u0635\u0649",
  none: "\u0628\u062F\u0648\u0646",
  count: "\u0627\u0644\u0639\u062F\u062F",
  avg: "\u0627\u0644\u0645\u062A\u0648\u0633\u0637",
  filteredRows: "\u0627\u0644\u0645\u0631\u0634\u062D\u0629",
  selectedRows: "\u0627\u0644\u0645\u062D\u062F\u062F\u0629",
  totalRows: "\u0625\u062C\u0645\u0627\u0644\u064A \u0627\u0644\u0635\u0641\u0648\u0641",
  totalAndFilteredRows: "\u0627\u0644\u0635\u0641\u0648\u0641",
  more: "\u0627\u0644\u0645\u0632\u064A\u062F",
  to: "\u0625\u0644\u0649",
  of: "\u0645\u0646",
  page: "\u0627\u0644\u0635\u0641\u062D\u0629",
  pageLastRowUnknown: "?",
  nextPage: "\u0627\u0644\u0635\u0641\u062D\u0629 \u0627\u0644\u062A\u0627\u0644\u064A\u0629",
  lastPage: "\u0627\u0644\u0635\u0641\u062D\u0629 \u0627\u0644\u0623\u062E\u064A\u0631\u0629",
  firstPage: "\u0627\u0644\u0635\u0641\u062D\u0629 \u0627\u0644\u0623\u0648\u0644\u0649",
  previousPage: "\u0627\u0644\u0635\u0641\u062D\u0629 \u0627\u0644\u0633\u0627\u0628\u0642\u0629",
  pageSizeSelectorLabel: "\u062D\u062C\u0645 \u0627\u0644\u0635\u0641\u062D\u0629:",
  footerTotal: "\u0627\u0644\u0645\u062C\u0645\u0648\u0639",
  // Pivoting
  pivotColumnGroupTotals: "\u0627\u0644\u0645\u062C\u0645\u0648\u0639",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0645\u062D\u0648\u0631\u064A \u0648\u0648\u0636\u0639 \u0627\u0644\u0645\u062D\u0648\u0631\u064A",
  pivotChart: "\u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0645\u062D\u0648\u0631\u064A",
  chartRange: "\u0646\u0637\u0627\u0642 \u0627\u0644\u0631\u0633\u0645",
  columnChart: "\u0639\u0645\u0648\u062F",
  groupedColumn: "\u0645\u062C\u0645\u0648\u0639\u0629",
  stackedColumn: "\u0645\u0643\u062F\u0633",
  normalizedColumn: "100% \u0645\u0643\u062F\u0633",
  barChart: "\u0634\u0631\u064A\u0637",
  groupedBar: "\u0645\u062C\u0645\u0648\u0639\u0629",
  stackedBar: "\u0645\u0643\u062F\u0633",
  normalizedBar: "100% \u0645\u0643\u062F\u0633",
  pieChart: "\u062F\u0627\u0626\u0631\u0629",
  pie: "\u062F\u0627\u0626\u0631\u0629",
  donut: "\u062F\u0648\u0646\u0627\u062A",
  line: "\u062E\u0637",
  xyChart: "\u0633 \u0633 (\u0646\u0642\u0637\u0629)",
  scatter: "\u0646\u0642\u0627\u0637 \u0645\u0628\u0639\u062B\u0631\u0629",
  bubble: "\u0641\u0642\u0627\u0639\u0629",
  areaChart: "\u0645\u0646\u0637\u0642\u0629",
  area: "\u0645\u0646\u0637\u0642\u0629",
  stackedArea: "\u0645\u0643\u062F\u0633",
  normalizedArea: "100% \u0645\u0643\u062F\u0633",
  histogramChart: "\u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A \u0644\u0644\u062A\u0648\u0632\u064A\u0639",
  polarChart: "\u0642\u0637\u0628\u064A",
  radarLine: "\u062E\u0637 \u0627\u0644\u0631\u0627\u062F\u0627\u0631",
  radarArea: "\u0645\u0646\u0637\u0642\u0629 \u0627\u0644\u0631\u0627\u062F\u0627\u0631",
  nightingale: "\u0646\u0627\u064A\u062A\u0646\u062C\u064A\u0644",
  radialColumn: "\u0639\u0645\u0648\u062F \u0634\u0639\u0627\u0639\u064A",
  radialBar: "\u0634\u0631\u064A\u0637 \u0634\u0639\u0627\u0639\u064A",
  statisticalChart: "\u0625\u062D\u0635\u0627\u0626\u064A",
  boxPlot: "\u0635\u0646\u062F\u0648\u0642 \u0645\u062E\u0637\u0637",
  rangeBar: "\u0634\u0631\u064A\u0637 \u0627\u0644\u0645\u062F\u0649",
  rangeArea: "\u0645\u0646\u0637\u0642\u0629 \u0627\u0644\u0645\u062F\u0649",
  hierarchicalChart: "\u0627\u0644\u062A\u0633\u0644\u0633\u0644 \u0627\u0644\u0647\u0631\u0645\u064A",
  treemap: "\u062E\u0631\u064A\u0637\u0629 \u0627\u0644\u0634\u062C\u0631\u0629",
  sunburst: "\u0627\u0646\u0641\u062C\u0627\u0631 \u0634\u0645\u0633\u064A",
  specializedChart: "\u062A\u062E\u0635\u0635\u064A",
  waterfall: "\u0634\u0644\u0627\u0644",
  heatmap: "\u062E\u0631\u064A\u0637\u0629 \u0627\u0644\u062D\u0631\u0627\u0631\u0629",
  combinationChart: "\u0645\u0632\u064A\u062C",
  columnLineCombo: "\u0639\u0645\u0648\u062F \u0648\u062E\u0637",
  AreaColumnCombo: "\u0645\u0646\u0637\u0642\u0629 \u0648\u0639\u0645\u0648\u062F",
  // Charts
  pivotChartTitle: "\u0631\u0633\u0645 \u0645\u062D\u0648\u0631\u064A",
  rangeChartTitle: "\u0631\u0633\u0645 \u0646\u0637\u0627\u0642",
  settings: "\u0625\u0639\u062F\u0627\u062F\u0627\u062A",
  data: "\u0625\u0639\u062F\u0627\u062F \u0627\u0644\u0628\u064A\u0627\u0646\u0627\u062A",
  format: "\u062A\u062E\u0635\u064A\u0635",
  categories: "\u0627\u0644\u0641\u0626\u0627\u062A",
  defaultCategory: "(\u0644\u0627 \u0634\u064A\u0621)",
  series: "\u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  switchCategorySeries: "\u062A\u0628\u062F\u064A\u0644 \u0627\u0644\u0641\u0626\u0629 / \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  categoryValues: "\u0642\u064A\u0645 \u0627\u0644\u0641\u0626\u0629",
  seriesLabels: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  aggregate: "\u0645\u062C\u0645\u0648\u0639\u0629",
  xyValues: "\u0642\u064A\u0645 \u0625\u062D\u062F\u0627\u062B\u064A\u0627\u062A X Y",
  paired: "\u0627\u0644\u0648\u0636\u0639 \u0627\u0644\u0645\u0642\u062A\u0631\u0646",
  axis: "\u0645\u062D\u0648\u0631",
  xAxis: "\u0627\u0644\u0645\u062D\u0648\u0631 \u0627\u0644\u0623\u0641\u0642\u064A",
  yAxis: "\u0627\u0644\u0645\u062D\u0648\u0631 \u0627\u0644\u0631\u0623\u0633\u064A",
  polarAxis: "\u0645\u062D\u0648\u0631 \u0642\u0637\u0628\u064A",
  radiusAxis: "\u0645\u062D\u0648\u0631 \u0646\u0635\u0641 \u0627\u0644\u0642\u0637\u0631",
  navigator: "\u0645\u0633\u062A\u0643\u0634\u0641",
  zoom: "\u062A\u0643\u0628\u064A\u0631",
  animation: "\u062A\u062D\u0631\u064A\u0643",
  crosshair: "\u0645\u0624\u0634\u0631",
  color: "\u0644\u0648\u0646",
  thickness: "\u0633\u064F\u0645\u0643",
  preferredLength: "\u0627\u0644\u0637\u0648\u0644 \u0627\u0644\u0645\u0641\u0636\u0644",
  xType: "\u0646\u0648\u0639 X",
  axisType: "\u0646\u0648\u0639 \u0627\u0644\u0645\u062D\u0648\u0631",
  automatic: "\u062A\u0644\u0642\u0627\u0626\u064A",
  category: "\u0641\u0626\u0629",
  number: "\u0631\u0642\u0645",
  time: "\u0648\u0642\u062A",
  timeFormat: "\u0635\u064A\u063A\u0629 \u0627\u0644\u0648\u0642\u062A",
  autoRotate: "\u062A\u062F\u0648\u064A\u0631 \u062A\u0644\u0642\u0627\u0626\u064A",
  labelRotation: "\u062A\u062F\u0648\u064A\u0631 \u0627\u0644\u062A\u0633\u0645\u064A\u0627\u062A",
  circle: "\u062F\u0627\u0626\u0631\u0629",
  polygon: "\u0645\u0636\u0644\u0639",
  square: "\u0645\u0631\u0628\u0639",
  cross: "\u0635\u0644\u064A\u0628",
  diamond: "\u0645\u0627\u0633",
  plus: "\u0632\u0627\u0626\u062F",
  triangle: "\u0645\u062B\u0644\u062B",
  heart: "\u0642\u0644\u0628",
  orientation: "\u0627\u062A\u062C\u0627\u0647",
  fixed: "\u062B\u0627\u0628\u062A",
  parallel: "\u0645\u062A\u0648\u0627\u0632\u064A",
  perpendicular: "\u0639\u0645\u0648\u062F\u064A",
  radiusAxisPosition: "\u0627\u0644\u0645\u0648\u0642\u0639",
  ticks: "\u0639\u0644\u0627\u0645\u0627\u062A",
  gridLines: "\u062E\u0637\u0648\u0637 \u0627\u0644\u0634\u0628\u0643\u0629",
  width: "\u0639\u0631\u0636",
  height: "\u0627\u0631\u062A\u0641\u0627\u0639",
  length: "\u0637\u0648\u0644",
  padding: "\u062D\u0634\u0648\u0629",
  spacing: "\u062A\u0628\u0627\u0639\u062F",
  chartStyle: "\u0646\u0645\u0637 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  title: "\u0639\u0646\u0648\u0627\u0646",
  chartTitles: "\u0639\u0646\u0627\u0648\u064A\u0646 \u0627\u0644\u0631\u0633\u0648\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A\u0629",
  chartTitle: "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  chartSubtitle: "\u0627\u0644\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0641\u0631\u0639\u064A",
  horizontalAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0645\u062D\u0648\u0631 \u0627\u0644\u0623\u0641\u0642\u064A",
  verticalAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0645\u062D\u0648\u0631 \u0627\u0644\u0631\u0623\u0633\u064A",
  polarAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0645\u062D\u0648\u0631 \u0627\u0644\u0642\u0637\u0628\u064A",
  titlePlaceholder: "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  background: "\u062E\u0644\u0641\u064A\u0629",
  font: "\u062E\u0637",
  weight: "\u0648\u0632\u0646",
  top: "\u0623\u0639\u0644\u0649",
  right: "\u064A\u0645\u064A\u0646",
  bottom: "\u0623\u0633\u0641\u0644",
  left: "\u064A\u0633\u0627\u0631",
  labels: "\u0627\u0644\u062A\u0633\u0645\u064A\u0627\u062A",
  calloutLabels: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u062A\u0646\u0628\u064A\u0647",
  sectorLabels: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0642\u0637\u0627\u0639",
  positionRatio: "\u0646\u0633\u0628\u0629 \u0627\u0644\u0645\u0648\u0642\u0639",
  size: "\u062D\u062C\u0645",
  shape: "\u0634\u0643\u0644",
  minSize: "\u0627\u0644\u062D\u062F \u0627\u0644\u0623\u062F\u0646\u0649 \u0644\u0644\u062D\u062C\u0645",
  maxSize: "\u0627\u0644\u062D\u062F \u0627\u0644\u0623\u0642\u0635\u0649 \u0644\u0644\u062D\u062C\u0645",
  legend: "\u0648\u0633\u064A\u0644\u0629 \u0627\u0644\u0625\u064A\u0636\u0627\u062D",
  position: "\u0627\u0644\u0645\u0648\u0642\u0639",
  markerSize: "\u062D\u062C\u0645 \u0627\u0644\u0639\u0644\u0627\u0645\u0629",
  markerStroke: "\u062E\u0637 \u0627\u0644\u0639\u0644\u0627\u0645\u0629",
  markerPadding: "\u062D\u0634\u0648\u0629 \u0627\u0644\u0639\u0644\u0627\u0645\u0629",
  itemSpacing: "\u062A\u0628\u0627\u0639\u062F \u0627\u0644\u0639\u0646\u0627\u0635\u0631",
  itemPaddingX: "\u062D\u0634\u0648\u0629 \u0627\u0644\u0639\u0646\u0635\u0631 X",
  itemPaddingY: "\u062D\u0634\u0648\u0629 \u0627\u0644\u0639\u0646\u0635\u0631 Y",
  layoutHorizontalSpacing: "\u0627\u0644\u062A\u0628\u0627\u0639\u062F \u0627\u0644\u0623\u0641\u0642\u064A",
  layoutVerticalSpacing: "\u0627\u0644\u062A\u0628\u0627\u0639\u062F \u0627\u0644\u0631\u0623\u0633\u064A",
  strokeWidth: "\u0633\u0645\u0643 \u0627\u0644\u062E\u0637",
  offset: "\u0625\u0632\u0627\u062D\u0629",
  offsets: "\u0625\u0632\u0627\u062D\u0627\u062A",
  tooltips: "\u0623\u062F\u0648\u0627\u062A \u0627\u0644\u0625\u0631\u0634\u0627\u062F",
  callout: "\u062A\u0646\u0628\u064A\u0647",
  markers: "\u0639\u0644\u0627\u0645\u0627\u062A",
  shadow: "\u0638\u0644",
  blur: "\u0636\u0628\u0627\u0628\u064A\u0629",
  xOffset: "\u0625\u0632\u0627\u062D\u0629 X",
  yOffset: "\u0625\u0632\u0627\u062D\u0629 Y",
  lineWidth: "\u0639\u0631\u0636 \u0627\u0644\u062E\u0637",
  lineDash: "\u062E\u0637 \u0645\u0646\u0642\u0637",
  lineDashOffset: "\u0625\u0632\u0627\u062D\u0629 \u0627\u0644\u0646\u0642\u0637\u0629",
  scrollingZoom: "\u062A\u0643\u0628\u064A\u0631 \u0627\u0644\u062A\u0645\u0631\u064A\u0631",
  scrollingStep: "\u062E\u0637\u0648\u0629 \u0627\u0644\u062A\u0645\u0631\u064A\u0631",
  selectingZoom: "\u062A\u0643\u0628\u064A\u0631 \u0627\u0644\u062A\u062D\u062F\u064A\u062F",
  durationMillis: "\u0627\u0644\u0645\u062F\u0629 (\u0645\u0644\u0644\u064A \u062B\u0627\u0646\u064A\u0629)",
  crosshairLabel: "\u062A\u0633\u0645\u064A\u0629 \u0627\u0644\u0645\u0624\u0634\u0631",
  crosshairSnap: "\u0627\u0644\u062A\u0642\u0627\u0637 \u0627\u0644\u0645\u0624\u0634\u0631",
  normal: "\u0639\u0627\u062F\u064A",
  bold: "\u063A\u0627\u0645\u0642",
  italic: "\u0645\u0627\u0626\u0644",
  boldItalic: "\u063A\u0627\u0645\u0642 \u0648\u0645\u0627\u0626\u0644",
  predefined: "\u0645\u062D\u062F\u062F \u0645\u0633\u0628\u0642\u064B\u0627",
  fillOpacity: "\u0634\u0641\u0627\u0641\u064A\u0629 \u0627\u0644\u062A\u0639\u0628\u0626\u0629",
  strokeColor: "\u0644\u0648\u0646 \u0627\u0644\u062E\u0637",
  strokeOpacity: "\u0634\u0641\u0627\u0641\u064A\u0629 \u0627\u0644\u062E\u0637",
  miniChart: "\u0631\u0633\u0645 \u0635\u063A\u064A\u0631",
  histogramBinCount: "\u0639\u062F\u062F \u0627\u0644\u0635\u0646\u0627\u062F\u064A\u0642",
  connectorLine: "\u062E\u0637 \u0627\u0644\u0645\u0648\u0635\u0644",
  seriesItems: "\u0639\u0646\u0627\u0635\u0631 \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  seriesItemType: "\u0646\u0648\u0639 \u0627\u0644\u0639\u0646\u0635\u0631",
  seriesItemPositive: "\u0625\u064A\u062C\u0627\u0628\u064A",
  seriesItemNegative: "\u0633\u0644\u0628\u064A",
  seriesItemLabels: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0639\u0646\u0627\u0635\u0631",
  columnGroup: "\u0639\u0645\u0648\u062F",
  barGroup: "\u0634\u0631\u064A\u0637",
  pieGroup: "\u0641\u0637\u064A\u0631\u0629",
  lineGroup: "\u062E\u0637",
  scatterGroup: "X Y (\u0645\u0628\u0639\u062B\u0631)",
  areaGroup: "\u0645\u0633\u0627\u062D\u0629",
  polarGroup: "\u0642\u0637\u0628\u064A",
  statisticalGroup: "\u0625\u062D\u0635\u0627\u0626\u064A",
  hierarchicalGroup: "\u0647\u0631\u0645\u064A",
  specializedGroup: "\u0645\u062A\u062E\u0635\u0635",
  combinationGroup: "\u0645\u0632\u064A\u062C",
  groupedColumnTooltip: "\u0645\u062C\u0645\u0648\u0639\u0629",
  stackedColumnTooltip: "\u0645\u0643\u062F\u0633",
  normalizedColumnTooltip: "100% \u0645\u0643\u062F\u0633",
  groupedBarTooltip: "\u0645\u062C\u0645\u0648\u0639\u0629",
  stackedBarTooltip: "\u0645\u0643\u062F\u0633",
  normalizedBarTooltip: "100% \u0645\u0643\u062F\u0633",
  pieTooltip: "\u0641\u0637\u064A\u0631\u0629",
  donutTooltip: "\u062F\u0648\u0646\u0627\u062A",
  lineTooltip: "\u062E\u0637",
  groupedAreaTooltip: "\u0645\u0633\u0627\u062D\u0629 \u0645\u062C\u0645\u0648\u0639\u0629",
  stackedAreaTooltip: "\u0645\u0633\u0627\u062D\u0629 \u0645\u0643\u062F\u0633\u0629",
  normalizedAreaTooltip: "100% \u0645\u0643\u062F\u0633\u0629",
  scatterTooltip: "\u0645\u0628\u0639\u062B\u0631",
  bubbleTooltip: "\u0641\u0642\u0627\u0639\u0629",
  histogramTooltip: "\u0631\u0633\u0645 \u0628\u064A\u0627\u0646\u064A",
  radialColumnTooltip: "\u0639\u0645\u0648\u062F \u0634\u0639\u0627\u0639\u064A",
  radialBarTooltip: "\u0634\u0631\u064A\u0637 \u0634\u0639\u0627\u0639\u064A",
  radarLineTooltip: "\u062E\u0637 \u0631\u0627\u062F\u0627\u0631\u064A",
  radarAreaTooltip: "\u0645\u0633\u0627\u062D\u0629 \u0631\u0627\u062F\u0627\u0631\u064A\u0629",
  nightingaleTooltip: "\u0646\u0627\u064A\u062A\u0646\u062C\u064A\u0644",
  rangeBarTooltip: "\u0634\u0631\u064A\u0637 \u0627\u0644\u0646\u0637\u0627\u0642",
  rangeAreaTooltip: "\u0645\u0633\u0627\u062D\u0629 \u0627\u0644\u0646\u0637\u0627\u0642",
  boxPlotTooltip: "\u0645\u0631\u0628\u0639 \u0645\u062E\u0637\u0637",
  treemapTooltip: "\u062E\u0631\u064A\u0637\u0629 \u0627\u0644\u0634\u062C\u0631\u0629",
  sunburstTooltip: "\u0634\u0645\u0633 \u0645\u062A\u0641\u062C\u0631\u0629",
  waterfallTooltip: "\u0634\u0644\u0627\u0644",
  heatmapTooltip: "\u062E\u0631\u064A\u0637\u0629 \u062D\u0631\u0627\u0631\u064A\u0629",
  columnLineComboTooltip: "\u0639\u0645\u0648\u062F \u0648\u062E\u0637",
  areaColumnComboTooltip: "\u0645\u0633\u0627\u062D\u0629 \u0648\u0639\u0645\u0648\u062F",
  customComboTooltip: "\u0645\u0632\u064A\u062C \u0645\u062E\u0635\u0635",
  innerRadius: "\u0646\u0635\u0641 \u0627\u0644\u0642\u0637\u0631 \u0627\u0644\u062F\u0627\u062E\u0644\u064A",
  startAngle: "\u0632\u0627\u0648\u064A\u0629 \u0627\u0644\u0628\u062F\u0627\u064A\u0629",
  endAngle: "\u0632\u0627\u0648\u064A\u0629 \u0627\u0644\u0646\u0647\u0627\u064A\u0629",
  reverseDirection: "\u0639\u0643\u0633 \u0627\u0644\u0627\u062A\u062C\u0627\u0647",
  groupPadding: "\u062D\u0634\u0648\u0629 \u0627\u0644\u0645\u062C\u0645\u0648\u0639\u0629",
  seriesPadding: "\u062D\u0634\u0648\u0629 \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  tile: "\u0628\u0644\u0627\u0637\u0629",
  whisker: "\u0634\u0639\u0631",
  cap: "\u063A\u0637\u0627\u0621",
  capLengthRatio: "\u0646\u0633\u0628\u0629 \u0637\u0648\u0644 \u0627\u0644\u063A\u0637\u0627\u0621",
  labelPlacement: "\u0645\u0648\u0636\u0639 \u0627\u0644\u062A\u0633\u0645\u064A\u0629",
  inside: "\u062F\u0627\u062E\u0644",
  outside: "\u062E\u0627\u0631\u062C",
  noDataToChart: "\u0644\u0627 \u062A\u0648\u062C\u062F \u0628\u064A\u0627\u0646\u0627\u062A \u0644\u0639\u0631\u0636\u0647\u0627 \u0641\u064A \u0627\u0644\u0631\u0633\u0645.",
  pivotChartRequiresPivotMode: "\u064A\u062A\u0637\u0644\u0628 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0645\u062D\u0648\u0631\u064A \u062A\u0641\u0639\u064A\u0644 \u0648\u0636\u0639 \u0627\u0644\u0645\u062D\u0648\u0631\u064A\u0629.",
  chartSettingsToolbarTooltip: "\u0642\u0627\u0626\u0645\u0629",
  chartLinkToolbarTooltip: "\u0645\u0631\u062A\u0628\u0637 \u0628\u0627\u0644\u0634\u0628\u0643\u0629",
  chartUnlinkToolbarTooltip: "\u063A\u064A\u0631 \u0645\u0631\u062A\u0628\u0637 \u0628\u0627\u0644\u0634\u0628\u0643\u0629",
  chartDownloadToolbarTooltip: "\u062A\u0646\u0632\u064A\u0644 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  chartMenuToolbarTooltip: "\u0642\u0627\u0626\u0645\u0629",
  chartEdit: "\u062A\u062D\u0631\u064A\u0631 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  chartAdvancedSettings: "\u0625\u0639\u062F\u0627\u062F\u0627\u062A \u0645\u062A\u0642\u062F\u0645\u0629",
  chartLink: "\u0631\u0627\u0628\u0637 \u0644\u0644\u0634\u0628\u0643\u0629",
  chartUnlink: "\u0625\u0644\u063A\u0627\u0621 \u0627\u0644\u0631\u0627\u0628\u0637 \u0645\u0646 \u0627\u0644\u0634\u0628\u0643\u0629",
  chartDownload: "\u062A\u0646\u0632\u064A\u0644 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  histogramFrequency: "\u0627\u0644\u062A\u0631\u062F\u062F",
  seriesChartType: "\u0646\u0648\u0639 \u0631\u0633\u0645 \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  seriesType: "\u0646\u0648\u0639 \u0627\u0644\u0633\u0644\u0633\u0644\u0629",
  secondaryAxis: "\u0645\u062D\u0648\u0631 \u062B\u0627\u0646\u0648\u064A",
  seriesAdd: "\u0625\u0636\u0627\u0641\u0629 \u0633\u0644\u0633\u0644\u0629",
  categoryAdd: "\u0625\u0636\u0627\u0641\u0629 \u0641\u0626\u0629",
  bar: "\u0634\u0631\u064A\u0637",
  column: "\u0639\u0645\u0648\u062F",
  histogram: "\u0631\u0633\u0645 \u0628\u064A\u0627\u0646\u064A",
  advancedSettings: "\u0627\u0644\u0625\u0639\u062F\u0627\u062F\u0627\u062A \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  direction: "\u0627\u062A\u062C\u0627\u0647",
  horizontal: "\u0623\u0641\u0642\u064A",
  vertical: "\u0631\u0623\u0633\u064A",
  seriesGroupType: "\u0646\u0648\u0639 \u0627\u0644\u0645\u062C\u0645\u0648\u0639\u0629",
  groupedSeriesGroupType: "\u0645\u062C\u0645\u0648\u0639\u0629",
  stackedSeriesGroupType: "\u0645\u0643\u062F\u0633",
  normalizedSeriesGroupType: "100% \u0645\u0643\u062F\u0633",
  legendEnabled: "\u0645\u0645\u0643\u0651\u0646",
  invalidColor: "\u0642\u064A\u0645\u0629 \u0627\u0644\u0644\u0648\u0646 \u063A\u064A\u0631 \u0635\u062D\u064A\u062D\u0629",
  groupedColumnFull: "\u0639\u0645\u0648\u062F \u0645\u062C\u0645\u0639",
  stackedColumnFull: "\u0639\u0645\u0648\u062F \u0645\u0643\u062F\u0633",
  normalizedColumnFull: "100% \u0639\u0645\u0648\u062F \u0645\u0643\u062F\u0633",
  groupedBarFull: "\u0634\u0631\u064A\u0637 \u0645\u062C\u0645\u0639",
  stackedBarFull: "\u0634\u0631\u064A\u0637 \u0645\u0643\u062F\u0633",
  normalizedBarFull: "100% \u0634\u0631\u064A\u0637 \u0645\u0643\u062F\u0633",
  stackedAreaFull: "\u0645\u0633\u0627\u062D\u0629 \u0645\u0643\u062F\u0633\u0629",
  normalizedAreaFull: "100% \u0645\u0633\u0627\u062D\u0629 \u0645\u0643\u062F\u0633\u0629",
  customCombo: "\u0645\u0632\u064A\u062C \u0645\u062E\u0635\u0635",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u0627\u0644\u0645\u0633\u062A\u0648\u0649 ${variable}. \u0627\u0636\u063A\u0637 ENTER \u0644\u062A\u0639\u062F\u064A\u0644.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u0627\u0644\u0645\u0633\u062A\u0648\u0649 ${variable}. ${variable} \u0627\u0636\u063A\u0637 ENTER \u0644\u062A\u0639\u062F\u064A\u0644.",
  ariaAdvancedFilterBuilderList: "\u0642\u0627\u0626\u0645\u0629 \u0645\u064F\u0646\u0634\u0626 \u0627\u0644\u0641\u0644\u062A\u0631\u0629 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaAdvancedFilterBuilderFilterItem: "\u0634\u0631\u0637 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaAdvancedFilterBuilderGroupItem: "\u0645\u062C\u0645\u0648\u0639\u0629 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaAdvancedFilterBuilderColumn: "\u0627\u0644\u0639\u0645\u0648\u062F",
  ariaAdvancedFilterBuilderOption: "\u0627\u0644\u062E\u064A\u0627\u0631",
  ariaAdvancedFilterBuilderValueP: "\u0627\u0644\u0642\u064A\u0645\u0629",
  ariaAdvancedFilterBuilderJoinOperator: "\u0627\u0644\u0639\u0645\u0644\u064A\u0629 \u0627\u0644\u0631\u0628\u0637",
  ariaAdvancedFilterInput: "\u0625\u062F\u062E\u0627\u0644 \u0627\u0644\u0641\u0644\u062A\u0631\u0629 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaChecked: "\u0645\u062D\u062F\u062F",
  ariaColumn: "\u0627\u0644\u0639\u0645\u0648\u062F",
  ariaColumnGroup: "\u0645\u062C\u0645\u0648\u0639\u0629 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaColumnFiltered: "\u0627\u0644\u0639\u0645\u0648\u062F \u0645\u0641\u0644\u062A\u0631",
  ariaColumnSelectAll: "\u062A\u0628\u062F\u064A\u0644 \u062A\u062D\u062F\u064A\u062F \u0643\u0644 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaDateFilterInput: "\u0625\u062F\u062E\u0627\u0644 \u0641\u0644\u062A\u0631 \u0627\u0644\u062A\u0627\u0631\u064A\u062E",
  ariaDefaultListName: "\u0642\u0627\u0626\u0645\u0629",
  ariaFilterColumnsInput: "\u0625\u062F\u062E\u0627\u0644 \u0641\u0644\u062A\u0631\u0629 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaFilterFromValue: "\u0627\u0644\u0641\u0644\u062A\u0631\u0629 \u0645\u0646 \u0627\u0644\u0642\u064A\u0645\u0629",
  ariaFilterInput: "\u0625\u062F\u062E\u0627\u0644 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaFilterList: "\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaFilterToValue: "\u0627\u0644\u0641\u0644\u062A\u0631\u0629 \u0625\u0644\u0649 \u0627\u0644\u0642\u064A\u0645\u0629",
  ariaFilterValue: "\u0642\u064A\u0645\u0629 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaFilterMenuOpen: "\u0641\u062A\u062D \u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaFilteringOperator: "\u0645\u0634\u063A\u0644 \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaHidden: "\u0645\u062E\u0641\u064A",
  ariaIndeterminate: "\u063A\u064A\u0631 \u0645\u062D\u062F\u062F",
  ariaInputEditor: "\u0645\u062D\u0631\u0631 \u0627\u0644\u0625\u062F\u062E\u0627\u0644",
  ariaMenuColumn: "\u0627\u0636\u063A\u0637 ALT DOWN \u0644\u0641\u062A\u062D \u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0639\u0645\u0648\u062F",
  ariaFilterColumn: "\u0627\u0636\u063A\u0637 CTRL ENTER \u0644\u0641\u062A\u062D \u0627\u0644\u0641\u0644\u062A\u0631\u0629",
  ariaRowDeselect: "\u0627\u0636\u063A\u0637 SPACE \u0644\u0625\u0644\u063A\u0627\u0621 \u062A\u062D\u062F\u064A\u062F \u0647\u0630\u0627 \u0627\u0644\u0635\u0641",
  ariaRowSelectAll: "\u0627\u0636\u063A\u0637 Space \u0644\u062A\u0628\u062F\u064A\u0644 \u062A\u062D\u062F\u064A\u062F \u0643\u0644 \u0627\u0644\u0635\u0641\u0648\u0641",
  ariaRowToggleSelection: "\u0627\u0636\u063A\u0637 Space \u0644\u062A\u0628\u062F\u064A\u0644 \u062A\u062D\u062F\u064A\u062F \u0627\u0644\u0635\u0641",
  ariaRowSelect: "\u0627\u0636\u063A\u0637 SPACE \u0644\u062A\u062D\u062F\u064A\u062F \u0647\u0630\u0627 \u0627\u0644\u0635\u0641",
  ariaRowSelectionDisabled: "\u062A\u062D\u062F\u064A\u062F \u0627\u0644\u0635\u0641 \u0645\u0639\u0637\u0644 \u0644\u0647\u0630\u0627 \u0627\u0644\u0635\u0641",
  ariaSearch: "\u0628\u062D\u062B",
  ariaSortableColumn: "\u0627\u0636\u063A\u0637 ENTER \u0644\u0641\u0631\u0632",
  ariaToggleVisibility: "\u0627\u0636\u063A\u0637 SPACE \u0644\u062A\u0628\u062F\u064A\u0644 \u0627\u0644\u0631\u0624\u064A\u0629",
  ariaToggleCellValue: "\u0627\u0636\u063A\u0637 SPACE \u0644\u062A\u0628\u062F\u064A\u0644 \u0642\u064A\u0645\u0629 \u0627\u0644\u062E\u0644\u064A\u0629",
  ariaUnchecked: "\u063A\u064A\u0631 \u0645\u062D\u062F\u062F",
  ariaVisible: "\u0645\u0631\u0626\u064A",
  ariaSearchFilterValues: "\u0642\u064A\u0645\u0629 \u0641\u0644\u062A\u0631\u0629 \u0627\u0644\u0628\u062D\u062B",
  ariaPageSizeSelectorLabel: "\u062D\u062C\u0645 \u0627\u0644\u0635\u0641\u062D\u0629",
  ariaChartMenuClose: "\u0625\u063A\u0644\u0627\u0642 \u0642\u0627\u0626\u0645\u0629 \u062A\u062D\u0631\u064A\u0631 \u0627\u0644\u0631\u0633\u0645 \u0627\u0644\u0628\u064A\u0627\u0646\u064A",
  ariaChartSelected: "\u0645\u062D\u062F\u062F",
  ariaSkeletonCellLoadingFailed: "\u0641\u0634\u0644 \u062A\u062D\u0645\u064A\u0644 \u0627\u0644\u0635\u0641",
  ariaSkeletonCellLoading: "\u062C\u0627\u0631\u064A \u062A\u062D\u0645\u064A\u0644 \u0628\u064A\u0627\u0646\u0627\u062A \u0627\u0644\u0635\u0641",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u0645\u062C\u0645\u0648\u0639\u0627\u062A \u0627\u0644\u0635\u0641\u0648\u0641",
  ariaValuesDropZonePanelLabel: "\u0627\u0644\u0642\u064A\u0645",
  ariaPivotDropZonePanelLabel: "\u062A\u0633\u0645\u064A\u0627\u062A \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaDropZoneColumnComponentDescription: "\u0627\u0636\u063A\u0637 \u0639\u0644\u0649 DELETE \u0644\u0644\u0625\u0632\u0627\u0644\u0629",
  ariaDropZoneColumnValueItemDescription: "\u0627\u0636\u063A\u0637 \u0639\u0644\u0649 ENTER \u0644\u062A\u063A\u064A\u064A\u0631 \u0646\u0648\u0639 \u0627\u0644\u062A\u062C\u0645\u064A\u0639",
  ariaDropZoneColumnGroupItemDescription: "\u0627\u0636\u063A\u0637 \u0639\u0644\u0649 ENTER \u0644\u0644\u0641\u0631\u0632",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u0645\u0646 ",
  ariaDropZoneColumnComponentSortAscending: "\u062A\u0635\u0627\u0639\u062F\u064A",
  ariaDropZoneColumnComponentSortDescending: "\u062A\u0646\u0627\u0632\u0644\u064A",
  ariaLabelDialog: "\u062D\u0648\u0627\u0631",
  ariaLabelColumnMenu: "\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaLabelColumnFilter: "\u062A\u0635\u0641\u064A\u0629 \u0627\u0644\u0639\u0645\u0648\u062F",
  ariaLabelCellEditor: "\u0645\u062D\u0631\u0631 \u0627\u0644\u062E\u0644\u064A\u0629",
  ariaLabelSelectField: "\u0627\u062E\u062A\u064A\u0627\u0631 \u0627\u0644\u062D\u0642\u0644",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u062D\u0642\u0644 \u0627\u062E\u062A\u064A\u0627\u0631 \u0645\u062A\u0642\u062F\u0645",
  ariaLabelRichSelectToggleSelection: "\u0627\u0636\u063A\u0637 SPACE \u0644\u062A\u063A\u064A\u064A\u0631 \u0627\u0644\u062A\u062D\u062F\u064A\u062F",
  ariaLabelRichSelectDeselectAllItems: "\u0627\u0636\u063A\u0637 DELETE \u0644\u0625\u0644\u063A\u0627\u0621 \u062A\u062D\u062F\u064A\u062F \u0643\u0644 \u0627\u0644\u0639\u0646\u0627\u0635\u0631",
  ariaLabelRichSelectDeleteSelection: "\u0627\u0636\u063A\u0637 DELETE \u0644\u0625\u0644\u063A\u0627\u0621 \u062A\u062D\u062F\u064A\u062F \u0627\u0644\u0639\u0646\u0635\u0631",
  ariaLabelTooltip: "\u062A\u0644\u0645\u064A\u062D",
  ariaLabelContextMenu: "\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0633\u064A\u0627\u0642",
  ariaLabelSubMenu: "\u0627\u0644\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0641\u0631\u0639\u064A\u0629",
  ariaLabelAggregationFunction: "\u062F\u0627\u0644\u0629 \u0627\u0644\u062A\u062C\u0645\u064A\u0639",
  ariaLabelAdvancedFilterAutocomplete: "\u0627\u0644\u0625\u0643\u0645\u0627\u0644 \u0627\u0644\u062A\u0644\u0642\u0627\u0626\u064A \u0644\u0644\u0641\u0644\u0627\u062A\u0631 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaLabelAdvancedFilterBuilderAddField: "\u0625\u0636\u0627\u0641\u0629 \u062D\u0642\u0644 \u0645\u0646\u0634\u0626 \u0627\u0644\u0641\u0644\u0627\u062A\u0631 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u062D\u0642\u0644 \u0627\u062E\u062A\u064A\u0627\u0631 \u0627\u0644\u0623\u0639\u0645\u062F\u0629 \u0641\u064A \u0645\u0646\u0634\u0626 \u0627\u0644\u0641\u0644\u0627\u062A\u0631 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u062D\u0642\u0644 \u0627\u062E\u062A\u064A\u0627\u0631 \u0627\u0644\u062E\u064A\u0627\u0631\u0627\u062A \u0641\u064A \u0645\u0646\u0634\u0626 \u0627\u0644\u0641\u0644\u0627\u062A\u0631 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u062D\u0642\u0644 \u0627\u062E\u062A\u064A\u0627\u0631 \u0645\u0634\u063A\u0644 \u0627\u0644\u0631\u0628\u0637 \u0641\u064A \u0645\u0646\u0634\u0626 \u0627\u0644\u0641\u0644\u0627\u062A\u0631 \u0627\u0644\u0645\u062A\u0642\u062F\u0645\u0629",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0623\u0639\u0645\u062F\u0629",
  ariaFilterPanelList: "\u0642\u0627\u0626\u0645\u0629 \u0627\u0644\u0645\u0631\u0634\u062D\u0627\u062A",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "\u060C",
  decimalSeparator: ".",
  // Data types
  true: "\u0635\u062D",
  false: "\u062E\u0637\u0623",
  invalidDate: "\u062A\u0627\u0631\u064A\u062E \u063A\u064A\u0631 \u0635\u0627\u0644\u062D",
  invalidNumber: "\u0631\u0642\u0645 \u063A\u064A\u0631 \u0635\u0627\u0644\u062D",
  january: "\u064A\u0646\u0627\u064A\u0631",
  february: "\u0641\u0628\u0631\u0627\u064A\u0631",
  march: "\u0645\u0627\u0631\u0633",
  april: "\u0623\u0628\u0631\u064A\u0644",
  may: "\u0645\u0627\u064A\u0648",
  june: "\u064A\u0648\u0646\u064A\u0648",
  july: "\u064A\u0648\u0644\u064A\u0648",
  august: "\u0623\u063A\u0633\u0637\u0633",
  september: "\u0633\u0628\u062A\u0645\u0628\u0631",
  october: "\u0623\u0643\u062A\u0648\u0628\u0631",
  november: "\u0646\u0648\u0641\u0645\u0628\u0631",
  december: "\u062F\u064A\u0633\u0645\u0628\u0631",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "\u0633\u0627\u0639\u0627\u062A:\u062F\u0642\u0627\u0626\u0642:\u062B\u0648\u0627\u0646\u064A",
  timeFormatHHMMSSAmPm: "\u0633\u0627\u0639\u0627\u062A:\u062F\u0642\u0627\u0626\u0642:\u062B\u0648\u0627\u0646\u064A \u0635/\u0645"
};

// community-modules/locale/src/bg-BG.ts
var AG_GRID_LOCALE_BG = {
  // Set Filter
  selectAll: "(\u0418\u0437\u0431\u0435\u0440\u0438 \u0412\u0441\u0438\u0447\u043A\u0438)",
  selectAllSearchResults: "(\u0418\u0437\u0431\u0435\u0440\u0438 \u0412\u0441\u0438\u0447\u043A\u0438 \u0420\u0435\u0437\u0443\u043B\u0442\u0430\u0442\u0438 \u043E\u0442 \u0422\u044A\u0440\u0441\u0435\u043D\u0435\u0442\u043E)",
  addCurrentSelectionToFilter: "\u0414\u043E\u0431\u0430\u0432\u0438 \u0442\u0435\u043A\u0443\u0449\u0438\u044F \u0438\u0437\u0431\u043E\u0440 \u043A\u044A\u043C \u0444\u0438\u043B\u0442\u044A\u0440\u0430",
  searchOoo: "\u0422\u044A\u0440\u0441\u0435\u043D\u0435...",
  blanks: "(\u041F\u0440\u0430\u0437\u043D\u0438)",
  noMatches: "\u041D\u044F\u043C\u0430 \u0441\u044A\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u044F",
  // Number Filter & Text Filter
  filterOoo: "\u0424\u0438\u043B\u0442\u044A\u0440...",
  equals: "\u0420\u0430\u0432\u043D\u043E \u043D\u0430",
  notEqual: "\u041D\u0435 \u0435 \u0440\u0430\u0432\u043D\u043E \u043D\u0430",
  blank: "\u041F\u0440\u0430\u0437\u043D\u043E",
  notBlank: "\u041D\u0435 \u0435 \u043F\u0440\u0430\u0437\u043D\u043E",
  empty: "\u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0435\u0434\u043D\u043E",
  // Number Filter
  lessThan: "\u041F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442",
  greaterThan: "\u041F\u043E\u0432\u0435\u0447\u0435 \u043E\u0442",
  lessThanOrEqual: "\u041F\u043E-\u043C\u0430\u043B\u043A\u043E \u0438\u043B\u0438 \u0440\u0430\u0432\u043D\u043E \u043D\u0430",
  greaterThanOrEqual: "\u041F\u043E\u0432\u0435\u0447\u0435 \u0438\u043B\u0438 \u0440\u0430\u0432\u043D\u043E \u043D\u0430",
  inRange: "\u041C\u0435\u0436\u0434\u0443",
  inRangeStart: "\u041E\u0442",
  inRangeEnd: "\u0414\u043E",
  // Text Filter
  contains: "\u0421\u044A\u0434\u044A\u0440\u0436\u0430",
  notContains: "\u041D\u0435 \u0441\u044A\u0434\u044A\u0440\u0436\u0430",
  startsWith: "\u0417\u0430\u043F\u043E\u0447\u0432\u0430 \u0441",
  endsWith: "\u0417\u0430\u0432\u044A\u0440\u0448\u0432\u0430 \u0441",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u041F\u0440\u0435\u0434\u0438",
  after: "\u0421\u043B\u0435\u0434",
  // Filter Conditions
  andCondition: "\u0418",
  orCondition: "\u0418\u041B\u0418",
  // Filter Buttons
  applyFilter: "\u041F\u0440\u0438\u043B\u043E\u0436\u0438",
  resetFilter: "\u041D\u0443\u043B\u0438\u0440\u0430\u043D\u0435",
  clearFilter: "\u0418\u0437\u0447\u0438\u0441\u0442\u0438",
  cancelFilter: "\u041E\u0442\u043A\u0430\u0437",
  // Filter Titles
  textFilter: "\u0424\u0438\u043B\u0442\u044A\u0440 \u0437\u0430 \u0442\u0435\u043A\u0441\u0442",
  numberFilter: "\u0424\u0438\u043B\u0442\u044A\u0440 \u0437\u0430 \u0447\u0438\u0441\u043B\u043E",
  dateFilter: "\u0424\u0438\u043B\u0442\u044A\u0440 \u0437\u0430 \u0434\u0430\u0442\u0430",
  setFilter: "\u041D\u0430\u0431\u043E\u0440\u0435\u043D \u0444\u0438\u043B\u0442\u044A\u0440",
  // Group Column Filter
  groupFilterSelect: "\u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043F\u043E\u043B\u0435:",
  // Advanced Filter
  advancedFilterContains: "\u0441\u044A\u0434\u044A\u0440\u0436\u0430",
  advancedFilterNotContains: "\u043D\u0435 \u0441\u044A\u0434\u044A\u0440\u0436\u0430",
  advancedFilterTextEquals: "\u0440\u0430\u0432\u0435\u043D \u0435 \u043D\u0430",
  advancedFilterTextNotEqual: "\u043D\u0435 \u0435 \u0440\u0430\u0432\u0435\u043D \u043D\u0430",
  advancedFilterStartsWith: "\u0437\u0430\u043F\u043E\u0447\u0432\u0430 \u0441",
  advancedFilterEndsWith: "\u0437\u0430\u0432\u044A\u0440\u0448\u0432\u0430 \u0441",
  advancedFilterBlank: "\u0435 \u043F\u0440\u0430\u0437\u043D\u043E",
  advancedFilterNotBlank: "\u043D\u0435 \u0435 \u043F\u0440\u0430\u0437\u043D\u043E",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u0435 \u0432\u044F\u0440\u043D\u043E",
  advancedFilterFalse: "\u0435 \u043D\u0435\u0432\u044F\u0440\u043D\u043E",
  advancedFilterAnd: "\u0418",
  advancedFilterOr: "\u0418\u041B\u0418",
  advancedFilterApply: "\u041F\u0440\u0438\u043B\u043E\u0436\u0438",
  advancedFilterBuilder: "\u0421\u044A\u0437\u0434\u0430\u0442\u0435\u043B",
  advancedFilterValidationMissingColumn: "\u041A\u043E\u043B\u043E\u043D\u0430\u0442\u0430 \u043B\u0438\u043F\u0441\u0432\u0430",
  advancedFilterValidationMissingOption: "\u041E\u043F\u0446\u0438\u044F\u0442\u0430 \u043B\u0438\u043F\u0441\u0432\u0430",
  advancedFilterValidationMissingValue: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0442\u0430 \u043B\u0438\u043F\u0441\u0432\u0430",
  advancedFilterValidationInvalidColumn: "\u041A\u043E\u043B\u043E\u043D\u0430\u0442\u0430 \u043D\u0435 \u0435 \u043D\u0430\u043C\u0435\u0440\u0435\u043D\u0430",
  advancedFilterValidationInvalidOption: "\u041E\u043F\u0446\u0438\u044F\u0442\u0430 \u043D\u0435 \u0435 \u043D\u0430\u043C\u0435\u0440\u0435\u043D\u0430",
  advancedFilterValidationMissingQuote: "\u041B\u0438\u043F\u0441\u0432\u0430 \u043A\u0440\u0430\u0439\u043D\u0430 \u043A\u0430\u0432\u0438\u0447\u043A\u0430 \u043D\u0430 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0442\u0430",
  advancedFilterValidationNotANumber: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0442\u0430 \u043D\u0435 \u0435 \u0447\u0438\u0441\u043B\u043E",
  advancedFilterValidationInvalidDate: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0442\u0430 \u043D\u0435 \u0435 \u0432\u0430\u043B\u0438\u0434\u043D\u0430 \u0434\u0430\u0442\u0430",
  advancedFilterValidationMissingCondition: "\u041B\u0438\u043F\u0441\u0432\u0430 \u0443\u0441\u043B\u043E\u0432\u0438\u0435",
  advancedFilterValidationJoinOperatorMismatch: "\u041E\u043F\u0435\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u0438\u0442\u0435 \u0441\u044A\u0435\u0434\u0438\u043D\u0438\u0442\u0435\u043B\u0438 \u0432 \u0435\u0434\u043D\u043E \u0443\u0441\u043B\u043E\u0432\u0438\u0435 \u0442\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0441\u0430 \u0435\u0434\u043D\u0430\u043A\u0432\u0438",
  advancedFilterValidationInvalidJoinOperator: "\u041E\u043F\u0435\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u0438\u044F\u0442 \u0441\u044A\u0435\u0434\u0438\u043D\u0438\u0442\u0435\u043B \u043D\u0435 \u0435 \u043D\u0430\u043C\u0435\u0440\u0435\u043D",
  advancedFilterValidationMissingEndBracket: "\u041B\u0438\u043F\u0441\u0432\u0430 \u043A\u0440\u0430\u0439\u043D\u0430 \u0441\u043A\u043E\u0431\u0430",
  advancedFilterValidationExtraEndBracket: "\u041F\u0440\u0435\u043A\u0430\u043B\u0435\u043D\u043E \u043C\u043D\u043E\u0433\u043E \u043A\u0440\u0430\u0439\u043D\u0438 \u0441\u043A\u043E\u0431\u0438",
  advancedFilterValidationMessage: "\u0418\u043C\u0430 \u0433\u0440\u0435\u0448\u043A\u0430 \u0432 \u0438\u0437\u0440\u0430\u0437\u0430. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0418\u043C\u0430 \u0433\u0440\u0435\u0448\u043A\u0430 \u0432 \u0438\u0437\u0440\u0430\u0437\u0430. ${variable} \u0432 \u043A\u0440\u0430\u044F \u043D\u0430 \u0438\u0437\u0440\u0430\u0437\u0430.",
  advancedFilterBuilderTitle: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u0444\u0438\u043B\u0442\u044A\u0440",
  advancedFilterBuilderApply: "\u041F\u0440\u0438\u043B\u043E\u0436\u0438",
  advancedFilterBuilderCancel: "\u041E\u0442\u043C\u0435\u043D\u0438",
  advancedFilterBuilderAddButtonTooltip: "\u0414\u043E\u0431\u0430\u0432\u0438 \u0444\u0438\u043B\u0442\u044A\u0440 \u0438\u043B\u0438 \u0433\u0440\u0443\u043F\u0430",
  advancedFilterBuilderRemoveButtonTooltip: "\u041F\u0440\u0435\u043C\u0430\u0445\u043D\u0438",
  advancedFilterBuilderMoveUpButtonTooltip: "\u041F\u0440\u0435\u043C\u0435\u0441\u0442\u0438 \u043D\u0430\u0433\u043E\u0440\u0435",
  advancedFilterBuilderMoveDownButtonTooltip: "\u041F\u0440\u0435\u043C\u0435\u0441\u0442\u0438 \u043D\u0430\u0434\u043E\u043B\u0443",
  advancedFilterBuilderAddJoin: "\u0414\u043E\u0431\u0430\u0432\u0438 \u0433\u0440\u0443\u043F\u0430",
  advancedFilterBuilderAddCondition: "\u0414\u043E\u0431\u0430\u0432\u0438 \u0444\u0438\u043B\u0442\u044A\u0440",
  advancedFilterBuilderSelectColumn: "\u0418\u0437\u0431\u0435\u0440\u0438 \u043A\u043E\u043B\u043E\u043D\u0430",
  advancedFilterBuilderSelectOption: "\u0418\u0437\u0431\u0435\u0440\u0438 \u043E\u043F\u0446\u0438\u044F",
  advancedFilterBuilderEnterValue: "\u0412\u044A\u0432\u0435\u0434\u0438 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442...",
  advancedFilterBuilderValidationAlreadyApplied: "\u0422\u0435\u043A\u0443\u0449\u0438\u044F\u0442 \u0444\u0438\u043B\u0442\u044A\u0440 \u0432\u0435\u0447\u0435 \u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D.",
  advancedFilterBuilderValidationIncomplete: "\u041D\u0435 \u0432\u0441\u0438\u0447\u043A\u0438 \u0443\u0441\u043B\u043E\u0432\u0438\u044F \u0441\u0430 \u0437\u0430\u0432\u044A\u0440\u0448\u0435\u043D\u0438.",
  advancedFilterBuilderValidationSelectColumn: "\u0422\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043A\u043E\u043B\u043E\u043D\u0430.",
  advancedFilterBuilderValidationSelectOption: "\u0422\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043E\u043F\u0446\u0438\u044F.",
  advancedFilterBuilderValidationEnterValue: "\u0422\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0432\u044A\u0432\u0435\u0434\u0435\u0442\u0435 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442.",
  // Side Bar
  columns: "\u041A\u043E\u043B\u043E\u043D\u0438",
  filters: "\u0424\u0438\u043B\u0442\u0440\u0438",
  // columns tool panel
  pivotMode: "\u0420\u0435\u0436\u0438\u043C \u043D\u0430 \u043E\u0431\u043E\u0431\u0449\u0435\u043D\u0438\u0435",
  groups: "\u0413\u0440\u0443\u043F\u0438 \u043F\u043E \u0440\u0435\u0434\u043E\u0432\u0435",
  rowGroupColumnsEmptyMessage: "\u041F\u043B\u044A\u0437\u043D\u0435\u0442\u0435 \u0442\u0443\u043A, \u0437\u0430 \u0434\u0430 \u0437\u0430\u0434\u0430\u0434\u0435\u0442\u0435 \u0433\u0440\u0443\u043F\u0438 \u043F\u043E \u0440\u0435\u0434\u043E\u0432\u0435",
  values: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  valueColumnsEmptyMessage: "\u041F\u043B\u044A\u0437\u043D\u0435\u0442\u0435 \u0442\u0443\u043A, \u0437\u0430 \u0434\u0430 \u0430\u0433\u0440\u0435\u0433\u0438\u0440\u0430\u043D\u0435",
  pivots: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  pivotColumnsEmptyMessage: "\u041F\u043B\u044A\u0437\u043D\u0435\u0442\u0435 \u0442\u0443\u043A, \u0437\u0430 \u0434\u0430 \u0437\u0430\u0434\u0430\u0434\u0435\u0442\u0435 \u0435\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  // Header of the Default Group Column
  group: "\u0413\u0440\u0443\u043F\u0430",
  // Row Drag
  rowDragRow: "\u0440\u0435\u0434",
  rowDragRows: "\u0440\u0435\u0434\u043E\u0432\u0435",
  // Other
  loadingOoo: "\u0417\u0430\u0440\u0435\u0436\u0434\u0430\u043D\u0435...",
  loadingError: "\u0413\u0420\u0415\u0428\u041A\u0410",
  noRowsToShow: "\u041D\u044F\u043C\u0430 \u0440\u0435\u0434\u043E\u0432\u0435 \u0437\u0430 \u043F\u043E\u043A\u0430\u0437\u0432\u0430\u043D\u0435",
  enabled: "\u0410\u043A\u0442\u0438\u0432\u0438\u0440\u0430\u043D",
  // Menu
  pinColumn: "\u0417\u0430\u043A\u0440\u0435\u043F\u0432\u0430\u043D\u0435 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430\u0442\u0430",
  pinLeft: "\u0417\u0430\u043A\u0440\u0435\u043F\u0432\u0430\u043D\u0435 \u043D\u0430\u043B\u044F\u0432\u043E",
  pinRight: "\u0417\u0430\u043A\u0440\u0435\u043F\u0432\u0430\u043D\u0435 \u043D\u0430\u0434\u044F\u0441\u043D\u043E",
  noPin: "\u0411\u0435\u0437 \u0437\u0430\u043A\u0440\u0435\u043F\u0432\u0430\u043D\u0435",
  valueAggregation: "\u0410\u0433\u0440\u0435\u0433\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  noAggregation: "\u041D\u044F\u043C\u0430",
  autosizeThisColumn: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u043E\u0440\u0430\u0437\u043C\u0435\u0440\u044F\u0432\u0430\u043D\u0435 \u043D\u0430 \u0442\u0430\u0437\u0438 \u043A\u043E\u043B\u043E\u043D\u0430",
  autosizeAllColumns: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u043E\u0440\u0430\u0437\u043C\u0435\u0440\u044F\u0432\u0430\u043D\u0435 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u043A\u043E\u043B\u043E\u043D\u0438",
  groupBy: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0435 \u043F\u043E",
  ungroupBy: "\u0420\u0430\u0437\u0433\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0435 \u043F\u043E",
  ungroupAll: "\u0420\u0430\u0437\u0433\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438",
  addToValues: "\u0414\u043E\u0431\u0430\u0432\u044F\u043D\u0435 \u043D\u0430 ${variable} \u043A\u044A\u043C \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  removeFromValues: "\u041F\u0440\u0435\u043C\u0430\u0445\u0432\u0430\u043D\u0435 \u043D\u0430 ${variable} \u043E\u0442 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  addToLabels: "\u0414\u043E\u0431\u0430\u0432\u044F\u043D\u0435 \u043D\u0430 ${variable} \u043A\u044A\u043C \u0435\u0442\u0438\u043A\u0435\u0442\u0438",
  removeFromLabels: "\u041F\u0440\u0435\u043C\u0430\u0445\u0432\u0430\u043D\u0435 \u043D\u0430 ${variable} \u043E\u0442 \u0435\u0442\u0438\u043A\u0435\u0442\u0438",
  resetColumns: "\u041D\u0443\u043B\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  expandAll: "\u0420\u0430\u0437\u0448\u0438\u0440\u044F\u0432\u0430\u043D\u0435 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u0433\u0440\u0443\u043F\u0438 \u0440\u0435\u0434\u043E\u0432\u0435",
  collapseAll: "\u0417\u0430\u0442\u0432\u0430\u0440\u044F\u043D\u0435 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u0433\u0440\u0443\u043F\u0438 \u0440\u0435\u0434\u043E\u0432\u0435",
  copy: "\u041A\u043E\u043F\u0438\u0440\u0430\u043D\u0435",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u041A\u043E\u043F\u0438\u0440\u0430\u043D\u0435 \u0441 \u0437\u0430\u0433\u043B\u0430\u0432\u0438\u044F",
  copyWithGroupHeaders: "\u041A\u043E\u043F\u0438\u0440\u0430\u043D\u0435 \u0441 \u0433\u0440\u0443\u043F\u043E\u0432\u0438 \u0437\u0430\u0433\u043B\u0430\u0432\u0438\u044F",
  cut: "\u0418\u0437\u0440\u044F\u0437\u0432\u0430\u043D\u0435",
  paste: "\u041F\u043E\u0441\u0442\u0430\u0432\u044F\u043D\u0435",
  ctrlV: "Ctrl+V",
  export: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435",
  csvExport: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435 \u0432 CSV",
  excelExport: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435 \u0432 Excel",
  columnFilter: "\u0424\u0438\u043B\u0442\u044A\u0440 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430",
  columnChooser: "\u0418\u0437\u0431\u043E\u0440 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  chooseColumns: "\u0418\u0437\u0431\u043E\u0440 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  sortAscending: "\u0421\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435 \u0432\u044A\u0432 \u0432\u044A\u0437\u0445\u043E\u0434\u044F\u0449 \u0440\u0435\u0434",
  sortDescending: "\u0421\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435 \u0432 \u043D\u0438\u0437\u0445\u043E\u0434\u044F\u0449 \u0440\u0435\u0434",
  sortUnSort: "\u0418\u0437\u0447\u0438\u0441\u0442\u0432\u0430\u043D\u0435 \u043D\u0430 \u0441\u043E\u0440\u0442\u0438\u0440\u0430\u043D\u0435\u0442\u043E",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u0421\u0443\u043C\u0430",
  first: "\u041F\u044A\u0440\u0432\u0438",
  last: "\u041F\u043E\u0441\u043B\u0435\u0434\u0435\u043D",
  min: "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u0435\u043D",
  max: "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u0435\u043D",
  none: "\u041D\u044F\u043C\u0430",
  count: "\u0411\u0440\u043E\u0439",
  avg: "\u0421\u0440\u0435\u0434\u043D\u043E",
  filteredRows: "\u0424\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0438",
  selectedRows: "\u0418\u0437\u0431\u0440\u0430\u043D\u0438",
  totalRows: "\u041E\u0431\u0449\u043E \u0420\u0435\u0434\u043E\u0432\u0435",
  totalAndFilteredRows: "\u0420\u0435\u0434\u043E\u0432\u0435",
  more: "\u041E\u0449\u0435",
  to: "\u0434\u043E",
  of: "\u043E\u0442",
  page: "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430",
  pageLastRowUnknown: "?",
  nextPage: "\u0421\u043B\u0435\u0434\u0432\u0430\u0449\u0430 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430",
  lastPage: "\u041F\u043E\u0441\u043B\u0435\u0434\u043D\u0430 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430",
  firstPage: "\u041F\u044A\u0440\u0432\u0430 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430",
  previousPage: "\u041F\u0440\u0435\u0434\u0438\u0448\u043D\u0430 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430",
  pageSizeSelectorLabel: "\u0420\u0430\u0437\u043C\u0435\u0440 \u043D\u0430 \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430\u0442\u0430:",
  footerTotal: "\u041E\u0431\u0449\u043E",
  // Pivoting
  pivotColumnGroupTotals: "\u041E\u0431\u0449\u043E",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u0412\u044A\u0440\u0442\u044F\u0449\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430 \u0438 \u0432\u044A\u0440\u0442\u044F\u0449 \u0440\u0435\u0436\u0438\u043C",
  pivotChart: "\u0412\u044A\u0440\u0442\u044F\u0449\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  chartRange: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u043D\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430\u0442\u0430",
  columnChart: "\u041A\u043E\u043B\u043E\u043D\u0430",
  groupedColumn: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0438",
  stackedColumn: "\u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0438",
  normalizedColumn: "100% \u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0438",
  barChart: "\u041B\u0435\u043D\u0442\u0430",
  groupedBar: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0438",
  stackedBar: "\u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0438",
  normalizedBar: "100% \u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0438",
  pieChart: "\u041A\u0440\u044A\u0433\u043E\u0432\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  pie: "\u041A\u0440\u044A\u0433",
  donut: "\u0420\u0438\u043D\u0433",
  line: "\u041B\u0438\u043D\u0438\u044F",
  xyChart: "X Y (\u0420\u0430\u0437\u043F\u0440\u044A\u0441\u043D\u0430\u0442\u043E)",
  scatter: "\u0420\u0430\u0437\u043F\u0440\u044A\u0441\u043D\u0430\u0442\u043E",
  bubble: "\u041C\u0435\u0445\u0443\u0440\u0447\u0435\u0441\u0442\u0430",
  areaChart: "\u041E\u0431\u043B\u0430\u0441\u0442",
  area: "\u041E\u0431\u043B\u0430\u0441\u0442",
  stackedArea: "\u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0430",
  normalizedArea: "100% \u041F\u043E\u0434\u0440\u0435\u0434\u0435\u043D\u0430",
  histogramChart: "\u0425\u0438\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  polarChart: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430",
  radarLine: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043B\u0438\u043D\u0438\u044F",
  radarArea: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442",
  nightingale: "\u041D\u0430\u0439\u0442\u0438\u043D\u0433\u0435\u0439\u043B",
  radialColumn: "\u0420\u0430\u0434\u0438\u0430\u043B\u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430",
  radialBar: "\u0420\u0430\u0434\u0438\u0430\u043B\u043D\u0430 \u043B\u0435\u043D\u0442\u0430",
  statisticalChart: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u0447\u0435\u0441\u043A\u0430",
  boxPlot: "\u041A\u0443\u0442\u0438\u0435\u0432\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  rangeBar: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u043B\u0435\u043D\u0442\u0430",
  rangeArea: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442",
  hierarchicalChart: "\u0419\u0435\u0440\u0430\u0440\u0445\u0438\u0447\u043D\u0430",
  treemap: "\u0414\u044A\u0440\u0432\u043E\u0432\u0438\u0434\u043D\u0430 \u043A\u0430\u0440\u0442\u0430",
  sunburst: "\u0421\u043B\u044A\u043D\u0447\u0435\u0432 \u0438\u0437\u0440\u0438\u0433\u0432\u0430\u043D\u0435",
  specializedChart: "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0430",
  waterfall: "\u041A\u0430\u0441\u043A\u0430\u0434\u043D\u0430",
  heatmap: "\u0422\u043E\u043F\u043B\u0438\u043D\u043D\u0430 \u043A\u0430\u0440\u0442\u0430",
  combinationChart: "\u041A\u043E\u043C\u0431\u0438\u043D\u0438\u0440\u0430\u043D\u0430",
  columnLineCombo: "\u041A\u043E\u043B\u043E\u043D\u0430 \u0438 \u043B\u0438\u043D\u0438\u044F",
  AreaColumnCombo: "\u041E\u0431\u043B\u0430\u0441\u0442 \u0438 \u043A\u043E\u043B\u043E\u043D\u0430",
  // Charts
  pivotChartTitle: "\u0421\u0432\u043E\u0434\u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  rangeChartTitle: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  settings: "\u0413\u0440\u0430\u0444\u0438\u043A\u0430",
  data: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
  format: "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0435",
  categories: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438",
  defaultCategory: "(\u041D\u044F\u043C\u0430)",
  series: "\u0421\u0435\u0440\u0438\u0438",
  switchCategorySeries: "\u0421\u043C\u044F\u043D\u0430 \u043D\u0430 \u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F/\u0421\u0435\u0440\u0438\u044F",
  categoryValues: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438 \u043D\u0430 \u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F",
  seriesLabels: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u0421\u0435\u0440\u0438\u0438",
  aggregate: "\u0410\u0433\u0440\u0435\u0433\u0430\u0446\u0438\u044F",
  xyValues: "X Y \u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  paired: "\u0421\u0432\u044A\u0440\u0437\u0430\u043D \u0420\u0435\u0436\u0438\u043C",
  axis: "\u041E\u0441",
  xAxis: "\u0425\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u043D\u0430 \u041E\u0441",
  yAxis: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u043D\u0430 \u041E\u0441",
  polarAxis: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430 \u041E\u0441",
  radiusAxis: "\u0420\u0430\u0434\u0438\u0443\u0441 \u041E\u0441",
  navigator: "\u041D\u0430\u0432\u0438\u0433\u0430\u0442\u043E\u0440",
  zoom: "\u0423\u0432\u0435\u043B\u0438\u0447\u0430\u0432\u0430\u043D\u0435",
  animation: "\u0410\u043D\u0438\u043C\u0430\u0446\u0438\u044F",
  crosshair: "\u041F\u0440\u0438\u0446\u0435\u043B",
  color: "\u0426\u0432\u044F\u0442",
  thickness: "\u0414\u0435\u0431\u0435\u043B\u0438\u043D\u0430",
  preferredLength: "\u041F\u0440\u0435\u0434\u043F\u043E\u0447\u0438\u0442\u0430\u043D\u0430 \u0414\u044A\u043B\u0436\u0438\u043D\u0430",
  xType: "X \u0422\u0438\u043F",
  axisType: "\u0422\u0438\u043F \u041E\u0441",
  automatic: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E",
  category: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F",
  number: "\u0427\u0438\u0441\u043B\u043E",
  time: "\u0412\u0440\u0435\u043C\u0435",
  timeFormat: "\u0424\u043E\u0440\u043C\u0430\u0442 \u043D\u0430 \u0412\u0440\u0435\u043C\u0435\u0442\u043E",
  autoRotate: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u0417\u0430\u0432\u044A\u0440\u0442\u0430\u043D\u0435",
  labelRotation: "\u0417\u0430\u0432\u044A\u0440\u0442\u0430\u043D\u0435 \u043D\u0430 \u0415\u0442\u0438\u043A\u0435\u0442\u0438",
  circle: "\u041A\u0440\u044A\u0433",
  polygon: "\u041C\u043D\u043E\u0433\u043E\u044A\u0433\u044A\u043B\u043D\u0438\u043A",
  square: "\u041A\u0432\u0430\u0434\u0440\u0430\u0442",
  cross: "\u041A\u0440\u044A\u0441\u0442",
  diamond: "\u0420\u043E\u043C\u0431",
  plus: "\u041F\u043B\u044E\u0441",
  triangle: "\u0422\u0440\u0438\u044A\u0433\u044A\u043B\u043D\u0438\u043A",
  heart: "\u0421\u044A\u0440\u0446\u0435",
  orientation: "\u041E\u0440\u0438\u0435\u043D\u0442\u0430\u0446\u0438\u044F",
  fixed: "\u0424\u0438\u043A\u0441\u0438\u0440\u0430\u043D",
  parallel: "\u041F\u0430\u0440\u0430\u043B\u0435\u043B\u0435\u043D",
  perpendicular: "\u041F\u0435\u0440\u043F\u0435\u043D\u0434\u0438\u043A\u0443\u043B\u044F\u0440\u0435\u043D",
  radiusAxisPosition: "\u041F\u043E\u0437\u0438\u0446\u0438\u044F",
  ticks: "\u041C\u0435\u0440\u043A\u0438",
  gridLines: "\u0412\u043E\u0434\u0435\u0449\u0438 \u041B\u0438\u043D\u0438\u0438",
  width: "\u0428\u0438\u0440\u0438\u043D\u0430",
  height: "\u0412\u0438\u0441\u043E\u0447\u0438\u043D\u0430",
  length: "\u0414\u044A\u043B\u0436\u0438\u043D\u0430",
  padding: "\u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435",
  spacing: "\u0420\u0430\u0437\u0441\u0442\u043E\u044F\u043D\u0438\u0435",
  chartStyle: "\u0421\u0442\u0438\u043B \u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  title: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435",
  chartTitles: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u044F \u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  chartTitle: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435 \u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  chartSubtitle: "\u041F\u043E\u0434\u0437\u0430\u0433\u043B\u0430\u0432\u0438\u0435",
  horizontalAxisTitle: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435 \u043D\u0430 \u0425\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u043D\u0430 \u041E\u0441",
  verticalAxisTitle: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435 \u043D\u0430 \u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u043D\u0430 \u041E\u0441",
  polarAxisTitle: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435 \u043D\u0430 \u041F\u043E\u043B\u044F\u0440\u043D\u0430 \u041E\u0441",
  titlePlaceholder: "\u0417\u0430\u0433\u043B\u0430\u0432\u0438\u0435 \u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  background: "\u0424\u043E\u043D",
  font: "\u0428\u0440\u0438\u0444\u0442",
  weight: "\u0422\u0435\u0433\u043B\u043E",
  top: "\u0413\u043E\u0440\u0435",
  right: "\u0414\u044F\u0441\u043D\u043E",
  bottom: "\u0414\u043E\u043B\u0443",
  left: "\u041B\u044F\u0432\u043E",
  labels: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438",
  calloutLabels: "\u041E\u0431\u0430\u0436\u0434\u0430\u0449\u0438 \u0415\u0442\u0438\u043A\u0435\u0442\u0438",
  sectorLabels: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u0421\u0435\u043A\u0442\u043E\u0440\u0438",
  positionRatio: "\u0421\u044A\u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0435 \u043D\u0430 \u041F\u043E\u0437\u0438\u0446\u0438\u044F",
  size: "\u0420\u0430\u0437\u043C\u0435\u0440",
  shape: "\u0424\u043E\u0440\u043C\u0430",
  minSize: "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u0435\u043D \u0420\u0430\u0437\u043C\u0435\u0440",
  maxSize: "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u0435\u043D \u0420\u0430\u0437\u043C\u0435\u0440",
  legend: "\u041B\u0435\u0433\u0435\u043D\u0434\u0430",
  position: "\u041F\u043E\u0437\u0438\u0446\u0438\u044F",
  markerSize: "\u0420\u0430\u0437\u043C\u0435\u0440 \u043D\u0430 \u041C\u0430\u0440\u043A\u0435\u0440\u0430",
  markerStroke: "\u041A\u043E\u043D\u0442\u0443\u0440 \u043D\u0430 \u041C\u0430\u0440\u043A\u0435\u0440\u0430",
  markerPadding: "\u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435 \u043D\u0430 \u041C\u0430\u0440\u043A\u0435\u0440\u0430",
  itemSpacing: "\u0420\u0430\u0437\u0441\u0442\u043E\u044F\u043D\u0438\u0435 \u041C\u0435\u0436\u0434\u0443 \u0410\u0440\u0442\u0438\u043A\u0443\u043B\u0438",
  itemPaddingX: "\u0425\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u043D\u043E \u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435 \u043D\u0430 \u0410\u0440\u0442\u0438\u043A\u0443\u043B",
  itemPaddingY: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u043D\u043E \u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435 \u043D\u0430 \u0410\u0440\u0442\u0438\u043A\u0443\u043B",
  layoutHorizontalSpacing: "\u0425\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u043D\u043E \u0420\u0430\u0437\u0441\u0442\u043E\u044F\u043D\u0438\u0435",
  layoutVerticalSpacing: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u043D\u043E \u0420\u0430\u0437\u0441\u0442\u043E\u044F\u043D\u0438\u0435",
  strokeWidth: "\u0414\u0435\u0431\u0435\u043B\u0438\u043D\u0430 \u043D\u0430 \u041B\u0438\u043D\u0438\u044F\u0442\u0430",
  offset: "\u0418\u0437\u043C\u0435\u0441\u0442\u0432\u0430\u043D\u0435",
  offsets: "\u0418\u0437\u043C\u0435\u0441\u0442\u0432\u0430\u043D\u0438\u044F",
  tooltips: "\u041F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0438",
  callout: "\u041E\u0431\u0430\u0436\u0434\u0430\u043D\u0435",
  markers: "\u041C\u0430\u0440\u043A\u0435\u0440\u0438",
  shadow: "\u0421\u044F\u043D\u043A\u0430",
  blur: "\u0420\u0430\u0437\u043C\u0430\u0437\u0432\u0430\u043D\u0435",
  xOffset: "\u0418\u0437\u043C\u0435\u0441\u0442\u0432\u0430\u043D\u0435 \u043F\u043E X",
  yOffset: "\u0418\u0437\u043C\u0435\u0441\u0442\u0432\u0430\u043D\u0435 \u043F\u043E Y",
  lineWidth: "\u0428\u0438\u0440\u0438\u043D\u0430 \u043D\u0430 \u041B\u0438\u043D\u0438\u044F\u0442\u0430",
  lineDash: "\u041F\u0440\u0435\u043A\u044A\u0441\u043D\u0430\u0442\u0438 \u041B\u0438\u043D\u0438\u0438",
  lineDashOffset: "\u0418\u0437\u043C\u0435\u0441\u0442\u0432\u0430\u043D\u0435 \u043D\u0430 \u041F\u0440\u0435\u043A\u044A\u0441\u043D\u0430\u0442\u0438 \u041B\u0438\u043D\u0438\u0438",
  scrollingZoom: "\u041F\u0440\u0435\u0432\u044A\u0440\u0442\u0430\u043D\u0435",
  scrollingStep: "\u0421\u0442\u044A\u043F\u043A\u0430 \u043F\u0440\u0438 \u041F\u0440\u0435\u0432\u044A\u0440\u0442\u0430\u043D\u0435",
  selectingZoom: "\u0418\u0437\u0431\u0438\u0440\u0430\u043D\u0435",
  durationMillis: "\u041F\u0440\u043E\u0434\u044A\u043B\u0436\u0438\u0442\u0435\u043B\u043D\u043E\u0441\u0442 (ms)",
  crosshairLabel: "\u0415\u0442\u0438\u043A\u0435\u0442",
  crosshairSnap: "\u041F\u0440\u0438\u0432\u044A\u0440\u0437\u0432\u0430\u043D\u0435 \u043A\u044A\u043C \u0412\u0440\u044A\u0437\u043A\u0430",
  normal: "\u041D\u043E\u0440\u043C\u0430\u043B\u0435\u043D",
  bold: "\u0423\u0434\u0435\u0431\u0435\u043B\u0435\u043D",
  italic: "\u041D\u0430\u043A\u043B\u043E\u043D\u0435\u043D",
  boldItalic: "\u0423\u0434\u0435\u0431\u0435\u043B\u0435\u043D \u0438 \u041D\u0430\u043A\u043B\u043E\u043D\u0435\u043D",
  predefined: "\u041F\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043B\u043D\u043E \u041E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D",
  fillOpacity: "\u041F\u0440\u043E\u0437\u0440\u0430\u0447\u043D\u043E\u0441\u0442 \u043D\u0430 \u0417\u0430\u043F\u044A\u043B\u0432\u0430\u043D\u0435",
  strokeColor: "\u0426\u0432\u044F\u0442 \u043D\u0430 \u041B\u0438\u043D\u0438\u044F\u0442\u0430",
  strokeOpacity: "\u041F\u0440\u043E\u0437\u0440\u0430\u0447\u043D\u043E\u0441\u0442 \u043D\u0430 \u041B\u0438\u043D\u0438\u044F\u0442\u0430",
  miniChart: "\u041C\u0438\u043D\u0438 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  histogramBinCount: "\u0411\u0440\u043E\u0439 \u043D\u0430 \u041A\u043E\u0444\u0438",
  connectorLine: "\u0421\u0432\u044A\u0440\u0437\u0432\u0430\u0449\u0430 \u041B\u0438\u043D\u0438\u044F",
  seriesItems: "\u041E\u0431\u0435\u043A\u0442\u0438 \u0432 \u0421\u0435\u0440\u0438\u044F",
  seriesItemType: "\u0422\u0438\u043F \u041E\u0431\u0435\u043A\u0442",
  seriesItemPositive: "\u041F\u043E\u043B\u043E\u0436\u0438\u0442\u0435\u043B\u043D\u043E",
  seriesItemNegative: "\u041E\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043B\u043D\u043E",
  seriesItemLabels: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u041E\u0431\u0435\u043A\u0442\u0438",
  columnGroup: "\u041A\u043E\u043B\u043E\u043D\u0430",
  barGroup: "\u041B\u0435\u043D\u0442\u0430",
  pieGroup: "\u041F\u0430\u0439",
  lineGroup: "\u041B\u0438\u043D\u0438\u044F",
  scatterGroup: "X Y (\u0422\u043E\u0447\u043A\u0438)",
  areaGroup: "\u041E\u0431\u043B\u0430\u0441\u0442",
  polarGroup: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430",
  statisticalGroup: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u0447\u0435\u0441\u043A\u0430",
  hierarchicalGroup: "\u0419\u0435\u0440\u0430\u0440\u0445\u0438\u0447\u043D\u0430",
  specializedGroup: "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0430",
  combinationGroup: "\u041A\u043E\u043C\u0431\u0438\u043D\u0438\u0440\u0430\u043D\u0430",
  groupedColumnTooltip: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u043E",
  stackedColumnTooltip: "\u0421\u043B\u043E\u0435\u043D\u043E",
  normalizedColumnTooltip: "100% \u0421\u043B\u043E\u0435\u043D\u043E",
  groupedBarTooltip: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u043E",
  stackedBarTooltip: "\u0421\u043B\u043E\u0435\u043D\u043E",
  normalizedBarTooltip: "100% \u0421\u043B\u043E\u0435\u043D\u043E",
  pieTooltip: "\u041F\u0430\u0439",
  donutTooltip: "\u041F\u043E\u043D\u0438\u0447\u043A\u0430",
  lineTooltip: "\u041B\u0438\u043D\u0438\u044F",
  groupedAreaTooltip: "\u041E\u0431\u043B\u0430\u0441\u0442",
  stackedAreaTooltip: "\u0421\u043B\u043E\u0435\u043D\u0430",
  normalizedAreaTooltip: "100% \u0421\u043B\u043E\u0435\u043D\u0430",
  scatterTooltip: "\u0422\u043E\u0447\u043A\u0438",
  bubbleTooltip: "\u041C\u0435\u0445\u0443\u0440\u0447\u0435",
  histogramTooltip: "\u0425\u0438\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  radialColumnTooltip: "\u0420\u0430\u0434\u0438\u0430\u0446\u0438\u043E\u043D\u043D\u0430 \u041A\u043E\u043B\u043E\u043D\u0430",
  radialBarTooltip: "\u0420\u0430\u0434\u0438\u0430\u0446\u0438\u043E\u043D\u043D\u0430 \u041B\u0435\u043D\u0442\u0430",
  radarLineTooltip: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u041B\u0438\u043D\u0438\u044F",
  radarAreaTooltip: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u041E\u0431\u043B\u0430\u0441\u0442",
  nightingaleTooltip: "\u041D\u0430\u0439\u0442\u0438\u043D\u0433\u0435\u0439\u043B",
  rangeBarTooltip: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u041B\u0435\u043D\u0442\u0430",
  rangeAreaTooltip: "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u041E\u0431\u043B\u0430\u0441\u0442",
  boxPlotTooltip: "\u041A\u0443\u0442\u0438\u0435\u0432\u043E \u0420\u0430\u0437\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u0435",
  treemapTooltip: "\u0414\u044A\u0440\u0432\u0435\u0441\u043D\u0430 \u041A\u0430\u0440\u0442\u0430",
  sunburstTooltip: "\u0421\u043B\u044A\u043D\u0447\u0435\u0432\u043E \u0418\u0437\u043B\u044A\u0447\u0432\u0430\u043D\u0435",
  waterfallTooltip: "\u0412\u043E\u0434\u043E\u043E\u043F\u0430\u0434",
  heatmapTooltip: "\u0422\u043E\u043F\u043B\u0438\u043D\u043D\u0430 \u041A\u0430\u0440\u0442\u0430",
  columnLineComboTooltip: "\u041A\u043E\u043B\u043E\u043D\u0430 \u0438 \u041B\u0438\u043D\u0438\u044F",
  areaColumnComboTooltip: "\u041E\u0431\u043B\u0430\u0441\u0442 \u0438 \u041A\u043E\u043B\u043E\u043D\u0430",
  customComboTooltip: "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0430 \u041A\u043E\u043C\u0431\u0438\u043D\u0430\u0446\u0438\u044F",
  innerRadius: "\u0412\u044A\u0442\u0440\u0435\u0448\u0435\u043D \u0420\u0430\u0434\u0438\u0443\u0441",
  startAngle: "\u041D\u0430\u0447\u0430\u043B\u0435\u043D \u042A\u0433\u044A\u043B",
  endAngle: "\u041A\u0440\u0430\u0435\u043D \u042A\u0433\u044A\u043B",
  reverseDirection: "\u041E\u0431\u0440\u0430\u0442\u043D\u0430 \u041F\u043E\u0441\u043E\u043A\u0430",
  groupPadding: "\u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435 \u043D\u0430 \u0413\u0440\u0443\u043F\u0430",
  seriesPadding: "\u041F\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435 \u043D\u0430 \u0421\u0435\u0440\u0438\u044F",
  tile: "\u041F\u043B\u043E\u0447\u043A\u0430",
  whisker: "\u0423\u0438\u0441\u043A\u0438",
  cap: "\u0421\u0430\u043F\u0443\u043D",
  capLengthRatio: "\u0421\u044A\u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0435 \u043D\u0430 \u0414\u044A\u043B\u0436\u0438\u043D\u0430",
  labelPlacement: "\u041F\u043E\u0441\u0442\u0430\u0432\u044F\u043D\u0435 \u043D\u0430 \u0415\u0442\u0438\u043A\u0435\u0442\u0430",
  inside: "\u0412\u044A\u0442\u0440\u0435",
  outside: "\u041D\u0430\u0432\u044A\u043D",
  noDataToChart: "\u041D\u044F\u043C\u0430 \u043D\u0430\u043B\u0438\u0447\u043D\u0438 \u0434\u0430\u043D\u043D\u0438 \u0437\u0430 \u0438\u0437\u043E\u0431\u0440\u0430\u0437\u044F\u0432\u0430\u043D\u0435.",
  pivotChartRequiresPivotMode: "\u0421\u0432\u043E\u0434\u043D\u0430\u0442\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430 \u0438\u0437\u0438\u0441\u043A\u0432\u0430 \u0420\u0435\u0436\u0438\u043C \u043D\u0430 \u0421\u0432\u043E\u0434.",
  chartSettingsToolbarTooltip: "\u041C\u0435\u043D\u044E",
  chartLinkToolbarTooltip: "\u0421\u0432\u044A\u0440\u0437\u0430\u043D\u043E \u0441 \u0420\u0435\u0448\u0435\u0442\u043A\u0430\u0442\u0430",
  chartUnlinkToolbarTooltip: "\u0420\u0430\u0437\u0434\u0435\u043B\u0435\u043D\u043E \u043E\u0442 \u0420\u0435\u0448\u0435\u0442\u043A\u0430\u0442\u0430",
  chartDownloadToolbarTooltip: "\u0418\u0437\u0442\u0435\u0433\u043B\u0438 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  chartMenuToolbarTooltip: "\u041C\u0435\u043D\u044E",
  chartEdit: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0430\u0439 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  chartAdvancedSettings: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D\u0438 \u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
  chartLink: "\u0421\u0432\u044A\u0440\u0436\u0438 \u0441 \u0420\u0435\u0448\u0435\u0442\u043A\u0430\u0442\u0430",
  chartUnlink: "\u0420\u0430\u0437\u0434\u0435\u043B\u0438 \u043E\u0442 \u0420\u0435\u0448\u0435\u0442\u043A\u0430\u0442\u0430",
  chartDownload: "\u0418\u0437\u0442\u0435\u0433\u043B\u0438 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430",
  histogramFrequency: "\u0427\u0435\u0441\u0442\u043E\u0442\u0430",
  seriesChartType: "\u0422\u0438\u043F \u043D\u0430 \u0414\u0438\u0430\u0433\u0440\u0430\u043C\u0430\u0442\u0430",
  seriesType: "\u0422\u0438\u043F \u043D\u0430 \u0421\u0435\u0440\u0438\u044F\u0442\u0430",
  secondaryAxis: "\u0412\u0442\u043E\u0440\u0438\u0447\u043D\u0430 \u041E\u0441",
  seriesAdd: "\u0414\u043E\u0431\u0430\u0432\u0438 \u0421\u0435\u0440\u0438\u044F",
  categoryAdd: "\u0414\u043E\u0431\u0430\u0432\u0438 \u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F",
  bar: "\u041B\u0435\u043D\u0442\u0430",
  column: "\u041A\u043E\u043B\u043E\u043D\u0430",
  histogram: "\u0425\u0438\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  advancedSettings: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D\u0438 \u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
  direction: "\u041F\u043E\u0441\u043E\u043A\u0430",
  horizontal: "\u0425\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u043D\u043E",
  vertical: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u043D\u043E",
  seriesGroupType: "\u0422\u0438\u043F \u043D\u0430 \u0413\u0440\u0443\u043F\u0430\u0442\u0430",
  groupedSeriesGroupType: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u043E",
  stackedSeriesGroupType: "\u0421\u043B\u043E\u0435\u043D\u043E",
  normalizedSeriesGroupType: "100% \u0421\u043B\u043E\u0435\u043D\u043E",
  legendEnabled: "\u0410\u043A\u0442\u0438\u0432\u0438\u0440\u0430\u043D\u043E",
  invalidColor: "\u041D\u0435\u0432\u0430\u043B\u0438\u0434\u043D\u0430 \u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442 \u043D\u0430 \u0426\u0432\u044F\u0442",
  groupedColumnFull: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0430 \u041A\u043E\u043B\u043E\u043D\u0430",
  stackedColumnFull: "\u0421\u043B\u043E\u0435\u043D\u0430 \u041A\u043E\u043B\u043E\u043D\u0430",
  normalizedColumnFull: "100% \u0421\u043B\u043E\u0435\u043D\u0430 \u041A\u043E\u043B\u043E\u043D\u0430",
  groupedBarFull: "\u0413\u0440\u0443\u043F\u0438\u0440\u0430\u043D\u0430 \u041B\u0435\u043D\u0442\u0430",
  stackedBarFull: "\u0421\u043B\u043E\u0435\u043D\u0430 \u041B\u0435\u043D\u0442\u0430",
  normalizedBarFull: "100% \u0421\u043B\u043E\u0435\u043D\u0430 \u041B\u0435\u043D\u0442\u0430",
  stackedAreaFull: "\u0421\u043B\u043E\u0435\u043D\u0430 \u041E\u0431\u043B\u0430\u0441\u0442",
  normalizedAreaFull: "100% \u0421\u043B\u043E\u0435\u043D\u0430 \u041E\u0431\u043B\u0430\u0441\u0442",
  customCombo: "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0430 \u041A\u043E\u043C\u0431\u0438\u043D\u0430\u0446\u0438\u044F",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u041D\u0438\u0432\u043E ${variable}. \u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ENTER \u0437\u0430 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0430\u043D\u0435",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u041D\u0438\u0432\u043E ${variable}. ${variable} \u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ENTER \u0437\u0430 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0430\u043D\u0435.",
  ariaAdvancedFilterBuilderList: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u0441\u043F\u0438\u0441\u044A\u043A \u0437\u0430 \u0441\u044A\u0437\u0434\u0430\u0432\u0430\u043D\u0435 \u043D\u0430 \u0444\u0438\u043B\u0442\u0440\u0438",
  ariaAdvancedFilterBuilderFilterItem: "\u0423\u0441\u043B\u043E\u0432\u0438\u0435 \u0437\u0430 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435",
  ariaAdvancedFilterBuilderGroupItem: "\u0413\u0440\u0443\u043F\u0430 \u0437\u0430 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435",
  ariaAdvancedFilterBuilderColumn: "\u041A\u043E\u043B\u043E\u043D\u0430",
  ariaAdvancedFilterBuilderOption: "\u041E\u043F\u0446\u0438\u044F",
  ariaAdvancedFilterBuilderValueP: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442",
  ariaAdvancedFilterBuilderJoinOperator: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0437\u0430 \u0441\u0432\u044A\u0440\u0437\u0432\u0430\u043D\u0435",
  ariaAdvancedFilterInput: "\u0412\u0445\u043E\u0434 \u0437\u0430 \u0440\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u0444\u0438\u043B\u0442\u044A\u0440",
  ariaChecked: "\u043C\u0430\u0440\u043A\u0438\u0440\u0430\u043D\u043E",
  ariaColumn: "\u041A\u043E\u043B\u043E\u043D\u0430",
  ariaColumnGroup: "\u0413\u0440\u0443\u043F\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  ariaColumnFiltered: "\u041A\u043E\u043B\u043E\u043D\u0430\u0442\u0430 \u0435 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0430",
  ariaColumnSelectAll: "\u041F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0432\u0430\u043D\u0435 \u0437\u0430 \u0438\u0437\u0431\u043E\u0440 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u043A\u043E\u043B\u043E\u043D\u0438",
  ariaDateFilterInput: "\u0412\u0445\u043E\u0434 \u0437\u0430 \u0444\u0438\u043B\u0442\u044A\u0440 \u043F\u043E \u0434\u0430\u0442\u0430",
  ariaDefaultListName: "\u0421\u043F\u0438\u0441\u044A\u043A",
  ariaFilterColumnsInput: "\u0412\u0445\u043E\u0434 \u0437\u0430 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  ariaFilterFromValue: "\u0424\u0438\u043B\u0442\u044A\u0440 \u043E\u0442 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442",
  ariaFilterInput: "\u0412\u0445\u043E\u0434 \u0437\u0430 \u0444\u0438\u043B\u0442\u044A\u0440",
  ariaFilterList: "\u0421\u043F\u0438\u0441\u044A\u043A \u0437\u0430 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435",
  ariaFilterToValue: "\u0424\u0438\u043B\u0442\u044A\u0440 \u0434\u043E \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442",
  ariaFilterValue: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442 \u043D\u0430 \u0444\u0438\u043B\u0442\u044A\u0440\u0430",
  ariaFilterMenuOpen: "\u041E\u0442\u0432\u043E\u0440\u0435\u0442\u0435 \u043C\u0435\u043D\u044E\u0442\u043E \u0437\u0430 \u0444\u0438\u043B\u0442\u044A\u0440",
  ariaFilteringOperator: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0437\u0430 \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435",
  ariaHidden: "\u0441\u043A\u0440\u0438\u0442\u043E",
  ariaIndeterminate: "\u043D\u0435\u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u043E",
  ariaInputEditor: "\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440 \u0437\u0430 \u0432\u0445\u043E\u0434",
  ariaMenuColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ALT + \u0421\u0422\u0420\u0415\u041B\u041A\u0410 \u041D\u0410\u0414\u041E\u041B\u0423, \u0437\u0430 \u0434\u0430 \u043E\u0442\u0432\u043E\u0440\u0438\u0442\u0435 \u043C\u0435\u043D\u044E\u0442\u043E \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430\u0442\u0430",
  ariaFilterColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 CTRL + ENTER, \u0437\u0430 \u0434\u0430 \u043E\u0442\u0432\u043E\u0440\u0438\u0442\u0435 \u0444\u0438\u043B\u0442\u044A\u0440\u0430",
  ariaRowDeselect: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043E\u0442\u043C\u0430\u0440\u043A\u0438\u0440\u0430\u0442\u0435 \u0442\u043E\u0437\u0438 \u0440\u0435\u0434",
  ariaRowSelectAll: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0438\u0437\u0431\u043E\u0440\u0430 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u0440\u0435\u0434\u043E\u0432\u0435",
  ariaRowToggleSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0438\u0437\u0431\u043E\u0440\u0430 \u043D\u0430 \u0440\u0435\u0434\u0430",
  ariaRowSelect: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0442\u043E\u0437\u0438 \u0440\u0435\u0434",
  ariaRowSelectionDisabled: "\u0418\u0437\u0431\u043E\u0440\u044A\u0442 \u043D\u0430 \u0440\u0435\u0434\u043E\u0432\u0435 \u0435 \u0434\u0435\u0430\u043A\u0442\u0438\u0432\u0438\u0440\u0430\u043D \u0437\u0430 \u0442\u043E\u0437\u0438 \u0440\u0435\u0434",
  ariaSearch: "\u0422\u044A\u0440\u0441\u0435\u043D\u0435",
  ariaSortableColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ENTER, \u0437\u0430 \u0434\u0430 \u0441\u043E\u0440\u0442\u0438\u0440\u0430\u0442\u0435",
  ariaToggleVisibility: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0432\u0438\u0434\u0438\u043C\u043E\u0441\u0442\u0442\u0430",
  ariaToggleCellValue: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0442\u0430 \u043D\u0430 \u043A\u043B\u0435\u0442\u043A\u0430\u0442\u0430",
  ariaUnchecked: "\u043D\u0435\u043C\u0430\u0440\u043A\u0438\u0440\u0430\u043D\u043E",
  ariaVisible: "\u0432\u0438\u0434\u0438\u043C\u043E",
  ariaSearchFilterValues: "\u0422\u044A\u0440\u0441\u0435\u043D\u0435 \u043D\u0430 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438 \u0437\u0430 \u0444\u0438\u043B\u0442\u044A\u0440",
  ariaPageSizeSelectorLabel: "\u0420\u0430\u0437\u043C\u0435\u0440 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430\u0442\u0430",
  ariaChartMenuClose: "\u0417\u0430\u0442\u0432\u043E\u0440\u0435\u0442\u0435 \u043C\u0435\u043D\u044E\u0442\u043E \u0437\u0430 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u0434\u0438\u0430\u0433\u0440\u0430\u043C\u0430\u0442\u0430",
  ariaChartSelected: "\u0418\u0437\u0431\u0440\u0430\u043D\u043E",
  ariaSkeletonCellLoadingFailed: "\u0420\u0435\u0434\u044A\u0442 \u043D\u0435 \u043C\u043E\u0436\u0430 \u0434\u0430 \u0441\u0435 \u0437\u0430\u0440\u0435\u0434\u0438",
  ariaSkeletonCellLoading: "\u0414\u0430\u043D\u043D\u0438\u0442\u0435 \u043D\u0430 \u0440\u0435\u0434\u0430 \u0441\u0435 \u0437\u0430\u0440\u0435\u0436\u0434\u0430\u0442",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u0420\u0435\u0434\u043E\u0432\u0438 \u0433\u0440\u0443\u043F\u0438",
  ariaValuesDropZonePanelLabel: "\u0421\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
  ariaPivotDropZonePanelLabel: "\u0415\u0442\u0438\u043A\u0435\u0442\u0438 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0438",
  ariaDropZoneColumnComponentDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 DELETE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u043C\u0430\u0445\u043D\u0435\u0442\u0435",
  ariaDropZoneColumnValueItemDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ENTER, \u0437\u0430 \u0434\u0430 \u043F\u0440\u043E\u043C\u0435\u043D\u0438\u0442\u0435 \u0442\u0438\u043F\u0430 \u043D\u0430 \u0430\u0433\u0440\u0435\u0433\u0430\u0446\u0438\u044F",
  ariaDropZoneColumnGroupItemDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 ENTER, \u0437\u0430 \u0434\u0430 \u0441\u043E\u0440\u0442\u0438\u0440\u0430\u0442\u0435",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u043E\u0442 ",
  ariaDropZoneColumnComponentSortAscending: "\u0432\u044A\u0437\u0445\u043E\u0434\u044F\u0449\u043E",
  ariaDropZoneColumnComponentSortDescending: "\u043D\u0438\u0437\u0445\u043E\u0434\u044F\u0449\u043E",
  ariaLabelDialog: "\u0414\u0438\u0430\u043B\u043E\u0433",
  ariaLabelColumnMenu: "\u041C\u0435\u043D\u044E \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430",
  ariaLabelColumnFilter: "\u0424\u0438\u043B\u0442\u044A\u0440 \u043D\u0430 \u043A\u043E\u043B\u043E\u043D\u0430",
  ariaLabelCellEditor: "\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440 \u043D\u0430 \u043A\u043B\u0435\u0442\u043A\u0430",
  ariaLabelSelectField: "\u0418\u0437\u0431\u043E\u0440 \u043D\u0430 \u043F\u043E\u043B\u0435",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u041F\u043E\u043B\u0435 \u0437\u0430 \u0431\u043E\u0433\u0430\u0442\u043E \u0438\u0437\u0431\u0438\u0440\u0430\u043D\u0435",
  ariaLabelRichSelectToggleSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 SPACE, \u0437\u0430 \u0434\u0430 \u043F\u0440\u0435\u0432\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0438\u0437\u0431\u043E\u0440\u0430",
  ariaLabelRichSelectDeselectAllItems: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 DELETE, \u0437\u0430 \u0434\u0430 \u043E\u0442\u043C\u0435\u043D\u0438\u0442\u0435 \u0438\u0437\u0431\u043E\u0440\u0430 \u043D\u0430 \u0432\u0441\u0438\u0447\u043A\u0438 \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0438",
  ariaLabelRichSelectDeleteSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0435\u0442\u0435 DELETE, \u0437\u0430 \u0434\u0430 \u043E\u0442\u043C\u0435\u043D\u0438\u0442\u0435 \u0438\u0437\u0431\u043E\u0440\u0430 \u043D\u0430 \u0435\u043B\u0435\u043C\u0435\u043D\u0442",
  ariaLabelTooltip: "\u041F\u043E\u044F\u0441\u043D\u0435\u043D\u0438\u0435",
  ariaLabelContextMenu: "\u041A\u043E\u043D\u0442\u0435\u043A\u0441\u0442\u043D\u043E \u043C\u0435\u043D\u044E",
  ariaLabelSubMenu: "\u041F\u043E\u0434\u043C\u0435\u043D\u044E",
  ariaLabelAggregationFunction: "\u0424\u0443\u043D\u043A\u0446\u0438\u044F \u0437\u0430 \u0430\u0433\u0440\u0435\u0433\u0438\u0440\u0430\u043D\u0435",
  ariaLabelAdvancedFilterAutocomplete: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D\u043E \u0444\u0438\u043B\u0442\u0440\u0438\u0440\u0430\u043D\u0435 \u0441 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u0434\u043E\u043F\u044A\u043B\u0432\u0430\u043D\u0435",
  ariaLabelAdvancedFilterBuilderAddField: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0442\u043E\u0440 \u043D\u0430 \u0444\u0438\u043B\u0442\u0440\u0438 - \u0414\u043E\u0431\u0430\u0432\u044F\u043D\u0435 \u043D\u0430 \u043F\u043E\u043B\u0435",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0442\u043E\u0440 \u043D\u0430 \u0444\u0438\u043B\u0442\u0440\u0438 - \u0418\u0437\u0431\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u043F\u043E\u043B\u0435 \u0437\u0430 \u043A\u043E\u043B\u043E\u043D\u0430",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0442\u043E\u0440 \u043D\u0430 \u0444\u0438\u043B\u0442\u0440\u0438 - \u0418\u0437\u0431\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u043F\u043E\u043B\u0435 \u0437\u0430 \u043E\u043F\u0446\u0438\u044F",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u0420\u0430\u0437\u0448\u0438\u0440\u0435\u043D \u043A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0442\u043E\u0440 \u043D\u0430 \u0444\u0438\u043B\u0442\u0440\u0438 - \u0418\u0437\u0431\u0438\u0440\u0430\u043D\u0435 \u043D\u0430 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0437\u0430 \u0438\u043D\u0438\u0446\u0438\u0438\u0440\u0430\u043D\u0435",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u0421\u043F\u0438\u0441\u044A\u043A \u0441 \u043A\u043E\u043B\u043E\u043D\u0438",
  ariaFilterPanelList: "\u0421\u043F\u0438\u0441\u044A\u043A \u0441 \u0444\u0438\u043B\u0442\u0440\u0438",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u0412\u044F\u0440\u043D\u043E",
  false: "\u041D\u0435\u0432\u044F\u0440\u043D\u043E",
  invalidDate: "\u041D\u0435\u0432\u0430\u043B\u0438\u0434\u043D\u0430 \u0434\u0430\u0442\u0430",
  invalidNumber: "\u041D\u0435\u0432\u0430\u043B\u0438\u0434\u0435\u043D \u043D\u043E\u043C\u0435\u0440",
  january: "\u042F\u043D\u0443\u0430\u0440\u0438",
  february: "\u0424\u0435\u0432\u0440\u0443\u0430\u0440\u0438",
  march: "\u041C\u0430\u0440\u0442",
  april: "\u0410\u043F\u0440\u0438\u043B",
  may: "\u041C\u0430\u0439",
  june: "\u042E\u043D\u0438",
  july: "\u042E\u043B\u0438",
  august: "\u0410\u0432\u0433\u0443\u0441\u0442",
  september: "\u0421\u0435\u043F\u0442\u0435\u043C\u0432\u0440\u0438",
  october: "\u041E\u043A\u0442\u043E\u043C\u0432\u0440\u0438",
  november: "\u041D\u043E\u0435\u043C\u0432\u0440\u0438",
  december: "\u0414\u0435\u043A\u0435\u043C\u0432\u0440\u0438",
  // Time formats
  timeFormatSlashesDDMMYYYY: "\u0414\u0414/\u041C\u041C/\u0413\u0413\u0413\u0413",
  timeFormatSlashesMMDDYYYY: "\u041C\u041C/\u0414\u0414/\u0413\u0413\u0413\u0413",
  timeFormatSlashesDDMMYY: "\u0414\u0414/\u041C\u041C/\u0413\u0413",
  timeFormatSlashesMMDDYY: "\u041C\u041C/\u0414\u0414/\u0413\u0413",
  timeFormatDotsDDMYY: "\u0414\u0414.\u041C.\u0413\u0413",
  timeFormatDotsMDDYY: "\u041C.\u0414\u0414.\u0413\u0413",
  timeFormatDashesYYYYMMDD: "\u0413\u0413\u0413\u0413-\u041C\u041C-\u0414\u0414",
  timeFormatSpacesDDMMMMYYYY: "\u0414\u0414 \u041CMMM \u0413\u0413\u0413\u0413",
  timeFormatHHMMSS: "\u0427\u0427:\u041C\u041C:\u0421\u0421",
  timeFormatHHMMSSAmPm: "\u0427\u0427:\u041C\u041C:\u0421\u0421 AM/PM"
};

// community-modules/locale/src/cs-CZ.ts
var AG_GRID_LOCALE_CZ = {
  // Set Filter
  selectAll: "(Vybrat v\u0161e)",
  selectAllSearchResults: "(Vybrat v\u0161echny v\xFDsledky hled\xE1n\xED)",
  addCurrentSelectionToFilter: "P\u0159idat aktu\xE1ln\xED v\xFDb\u011Br do filtru",
  searchOoo: "Hledat...",
  blanks: "(Pr\xE1zdn\xE9)",
  noMatches: "\u017D\xE1dn\xE9 shody",
  // Number Filter & Text Filter
  filterOoo: "Filtr...",
  equals: "Rovn\xE1 se",
  notEqual: "Nerovn\xE1 se",
  blank: "Pr\xE1zdn\xFD",
  notBlank: "Nen\xED pr\xE1zdn\xFD",
  empty: "Vyberte jednu",
  // Number Filter
  lessThan: "M\xE9n\u011B ne\u017E",
  greaterThan: "V\xEDce ne\u017E",
  lessThanOrEqual: "M\xE9n\u011B ne\u017E nebo rovno",
  greaterThanOrEqual: "V\xEDce ne\u017E nebo rovno",
  inRange: "Mezi",
  inRangeStart: "Od",
  inRangeEnd: "Do",
  // Text Filter
  contains: "Obsahuje",
  notContains: "Neobsahuje",
  startsWith: "Za\u010D\xEDn\xE1 na",
  endsWith: "Kon\u010D\xED na",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "P\u0159ed",
  after: "Po",
  // Filter Conditions
  andCondition: "A",
  orCondition: "NEBO",
  // Filter Buttons
  applyFilter: "Pou\u017E\xEDt",
  resetFilter: "Obnovit",
  clearFilter: "Vymazat",
  cancelFilter: "Zru\u0161it",
  // Filter Titles
  textFilter: "Textov\xFD filtr",
  numberFilter: "\u010C\xEDseln\xFD filtr",
  dateFilter: "Datumov\xFD filtr",
  setFilter: "Sada filtr\u016F",
  // Group Column Filter
  groupFilterSelect: "Vyberte pole:",
  // Advanced Filter
  advancedFilterContains: "obsahuje",
  advancedFilterNotContains: "neobsahuje",
  advancedFilterTextEquals: "rovn\xE1 se",
  advancedFilterTextNotEqual: "nerovn\xE1 se",
  advancedFilterStartsWith: "za\u010D\xEDn\xE1 na",
  advancedFilterEndsWith: "kon\u010D\xED na",
  advancedFilterBlank: "je pr\xE1zdn\xE9",
  advancedFilterNotBlank: "nen\xED pr\xE1zdn\xE9",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "je pravda",
  advancedFilterFalse: "je nepravda",
  advancedFilterAnd: "A",
  advancedFilterOr: "NEBO",
  advancedFilterApply: "Pou\u017E\xEDt",
  advancedFilterBuilder: "Stavitel",
  advancedFilterValidationMissingColumn: "Chyb\xED sloupec",
  advancedFilterValidationMissingOption: "Chyb\xED mo\u017Enost",
  advancedFilterValidationMissingValue: "Chyb\xED hodnota",
  advancedFilterValidationInvalidColumn: "Sloupec nenalezen",
  advancedFilterValidationInvalidOption: "Mo\u017Enost nenalezena",
  advancedFilterValidationMissingQuote: "Hodnota postr\xE1d\xE1 ukon\u010Dovac\xED uvozovku",
  advancedFilterValidationNotANumber: "Hodnota nen\xED \u010D\xEDslo",
  advancedFilterValidationInvalidDate: "Hodnota nen\xED platn\xE9 datum",
  advancedFilterValidationMissingCondition: "Chyb\xED podm\xEDnka",
  advancedFilterValidationJoinOperatorMismatch: "Oper\xE1tory spojen\xED v r\xE1mci podm\xEDnky mus\xED b\xFDt stejn\xE9",
  advancedFilterValidationInvalidJoinOperator: "Oper\xE1tor spojen\xED nenalezen",
  advancedFilterValidationMissingEndBracket: "Chyb\xED koncov\xE1 z\xE1vorka",
  advancedFilterValidationExtraEndBracket: "P\u0159\xEDli\u0161 mnoho koncov\xFDch z\xE1vorek",
  advancedFilterValidationMessage: "V\xFDraz obsahuje chybu. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "V\xFDraz obsahuje chybu. ${variable} na konci v\xFDrazu.",
  advancedFilterBuilderTitle: "Pokro\u010Dil\xFD filtr",
  advancedFilterBuilderApply: "Pou\u017E\xEDt",
  advancedFilterBuilderCancel: "Zru\u0161it",
  advancedFilterBuilderAddButtonTooltip: "P\u0159idat filtr nebo skupinu",
  advancedFilterBuilderRemoveButtonTooltip: "Odebrat",
  advancedFilterBuilderMoveUpButtonTooltip: "Posunout nahoru",
  advancedFilterBuilderMoveDownButtonTooltip: "Posunout dol\u016F",
  advancedFilterBuilderAddJoin: "P\u0159idat skupinu",
  advancedFilterBuilderAddCondition: "P\u0159idat filtr",
  advancedFilterBuilderSelectColumn: "Vyberte sloupec",
  advancedFilterBuilderSelectOption: "Vyberte mo\u017Enost",
  advancedFilterBuilderEnterValue: "Zadejte hodnotu...",
  advancedFilterBuilderValidationAlreadyApplied: "Aktu\xE1ln\xED filtr ji\u017E pou\u017Eit.",
  advancedFilterBuilderValidationIncomplete: "V\u0161echny podm\xEDnky nejsou kompletn\xED.",
  advancedFilterBuilderValidationSelectColumn: "Mus\xEDte vybrat sloupec.",
  advancedFilterBuilderValidationSelectOption: "Mus\xEDte vybrat mo\u017Enost.",
  advancedFilterBuilderValidationEnterValue: "Mus\xEDte zadat hodnotu.",
  // Side Bar
  columns: "Sloupce",
  filters: "Filtry",
  // columns tool panel
  pivotMode: "Re\u017Eim Pivot",
  groups: "Skupiny \u0159\xE1dk\u016F",
  rowGroupColumnsEmptyMessage: "P\u0159et\xE1hn\u011Bte sem pro nastaven\xED skupin \u0159\xE1dk\u016F",
  values: "Hodnoty",
  valueColumnsEmptyMessage: "P\u0159et\xE1hn\u011Bte sem pro agregaci",
  pivots: "\u0160t\xEDtky sloupc\u016F",
  pivotColumnsEmptyMessage: "P\u0159et\xE1hn\u011Bte sem pro nastaven\xED \u0161t\xEDtk\u016F sloupc\u016F",
  // Header of the Default Group Column
  group: "Skupina",
  // Row Drag
  rowDragRow: "\u0159\xE1dek",
  rowDragRows: "\u0159\xE1dky",
  // Other
  loadingOoo: "Na\u010D\xEDt\xE1n\xED...",
  loadingError: "CHYBA",
  noRowsToShow: "\u017D\xE1dn\xE9 \u0159\xE1dky k zobrazen\xED",
  enabled: "Povoleno",
  // Menu
  pinColumn: "P\u0159ipnout sloupec",
  pinLeft: "P\u0159ipnout vlevo",
  pinRight: "P\u0159ipnout vpravo",
  noPin: "Nep\u0159ip\xEDnat",
  valueAggregation: "Agregace hodnot",
  noAggregation: "\u017D\xE1dn\xE1",
  autosizeThisColumn: "Automatick\xE1 velikost tohoto sloupce",
  autosizeAllColumns: "Automatick\xE1 velikost v\u0161ech sloupc\u016F",
  groupBy: "Seskupit podle",
  ungroupBy: "Zru\u0161it seskupen\xED podle",
  ungroupAll: "Zru\u0161it seskupen\xED v\u0161ech",
  addToValues: "P\u0159idat ${variable} k hodnot\xE1m",
  removeFromValues: "Odebrat ${variable} z hodnot",
  addToLabels: "P\u0159idat ${variable} k popisk\u016Fm",
  removeFromLabels: "Odebrat ${variable} z popisk\u016F",
  resetColumns: "Resetovat sloupce",
  expandAll: "Rozbalit v\u0161echny skupiny \u0159\xE1dk\u016F",
  collapseAll: "Sbalit v\u0161echny skupiny \u0159\xE1dk\u016F",
  copy: "Kop\xEDrovat",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kop\xEDrovat s hlavi\u010Dkami",
  copyWithGroupHeaders: "Kop\xEDrovat se skupinov\xFDmi hlavi\u010Dkami",
  cut: "Vyjmout",
  paste: "Vlo\u017Eit",
  ctrlV: "Ctrl+V",
  export: "Export",
  csvExport: "Export CSV",
  excelExport: "Export Excel",
  columnFilter: "Filtr sloupce",
  columnChooser: "Vybrat sloupce",
  chooseColumns: "Vybrat sloupce",
  sortAscending: "T\u0159\xEDdit vzestupn\u011B",
  sortDescending: "T\u0159\xEDdit sestupn\u011B",
  sortUnSort: "Zru\u0161it t\u0159\xEDd\u011Bn\xED",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Sou\u010Det",
  first: "Prvn\xED",
  last: "Posledn\xED",
  min: "Min",
  max: "Max",
  none: "\u017D\xE1dn\xE9",
  count: "Po\u010Det",
  avg: "Pr\u016Fm\u011Br",
  filteredRows: "Filtrovan\xE9",
  selectedRows: "Vybran\xE9",
  totalRows: "Celkem \u0159\xE1dk\u016F",
  totalAndFilteredRows: "\u0158\xE1dky",
  more: "V\xEDce",
  to: "do",
  of: "z",
  page: "Str\xE1nka",
  pageLastRowUnknown: "?",
  nextPage: "Dal\u0161\xED str\xE1nka",
  lastPage: "Posledn\xED str\xE1nka",
  firstPage: "Prvn\xED str\xE1nka",
  previousPage: "P\u0159edchoz\xED str\xE1nka",
  pageSizeSelectorLabel: "Velikost str\xE1nky:",
  footerTotal: "Celkem",
  // Pivoting
  pivotColumnGroupTotals: "Celkem",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Kontingen\u010Dn\xED graf a re\u017Eim kontingen\u010Dn\xED tabulky",
  pivotChart: "Kontingen\u010Dn\xED graf",
  chartRange: "Rozsah grafu",
  columnChart: "Sloupcov\xFD graf",
  groupedColumn: "Seskupen\xFD",
  stackedColumn: "Skl\xE1dan\xFD",
  normalizedColumn: "100% skl\xE1dan\xFD",
  barChart: "Pruhov\xFD graf",
  groupedBar: "Seskupen\xFD",
  stackedBar: "Skl\xE1dan\xFD",
  normalizedBar: "100% skl\xE1dan\xFD",
  pieChart: "Kol\xE1\u010Dov\xFD graf",
  pie: "Kol\xE1\u010D",
  donut: "Prstenec",
  line: "\u010C\xE1ra",
  xyChart: "X Y (Rozptylov\xFD graf)",
  scatter: "Rozptylov\xFD graf",
  bubble: "Bublinkov\xFD graf",
  areaChart: "Plo\u0161n\xFD graf",
  area: "Plocha",
  stackedArea: "Skl\xE1dan\xE1 plocha",
  normalizedArea: "100% skl\xE1dan\xE1 plocha",
  histogramChart: "Histogram",
  polarChart: "Pol\xE1rn\xED graf",
  radarLine: "Radarov\xE1 \u010D\xE1ra",
  radarArea: "Radarov\xE1 plocha",
  nightingale: "Nightingale",
  radialColumn: "Radi\xE1ln\xED sloupec",
  radialBar: "Radi\xE1ln\xED pruh",
  statisticalChart: "Statistick\xFD graf",
  boxPlot: "Krabicov\xFD graf",
  rangeBar: "Pruhov\xFD graf s rozsahem",
  rangeArea: "Plo\u0161n\xFD graf s rozsahem",
  hierarchicalChart: "Hierarchick\xFD graf",
  treemap: "Stromov\xE1 mapa",
  sunburst: "Slune\u010Dn\xED mapa",
  specializedChart: "Specializovan\xFD graf",
  waterfall: "Vodop\xE1dov\xFD graf",
  heatmap: "Heatmapa",
  combinationChart: "Kombina\u010Dn\xED graf",
  columnLineCombo: "Sloupcov\xFD a \u010D\xE1rov\xFD graf",
  AreaColumnCombo: "Plo\u0161n\xFD a sloupcov\xFD graf",
  // Charts
  pivotChartTitle: "Kontingen\u010Dn\xED graf",
  rangeChartTitle: "Rozsahov\xFD graf",
  settings: "Graf",
  data: "Nastaven\xED",
  format: "P\u0159izp\u016Fsobit",
  categories: "Kategorie",
  defaultCategory: "(\u017D\xE1dn\xE1)",
  series: "S\xE9rie",
  switchCategorySeries: "P\u0159epnout Kategorie / S\xE9rie",
  categoryValues: "Hodnoty kategori\xED",
  seriesLabels: "\u0160t\xEDtky s\xE9ri\xED",
  aggregate: "Souhrn",
  xyValues: "Hodnoty XY",
  paired: "Re\u017Eim P\u0159i\u0159azen\xED",
  axis: "Osa",
  xAxis: "Vodorovn\xE1 osa",
  yAxis: "Svisl\xE1 osa",
  polarAxis: "Pol\xE1rn\xED osa",
  radiusAxis: "Polom\u011Brn\xE1 osa",
  navigator: "Navig\xE1tor",
  zoom: "P\u0159ibl\xED\u017Een\xED",
  animation: "Animace",
  crosshair: "Zam\u011B\u0159ova\u010D",
  color: "Barva",
  thickness: "Tlou\u0161\u0165ka",
  preferredLength: "Preferovan\xE1 d\xE9lka",
  xType: "Typ osy X",
  axisType: "Typ osy",
  automatic: "Automaticky",
  category: "Kategorie",
  number: "\u010C\xEDslo",
  time: "\u010Cas",
  timeFormat: "Form\xE1t \u010Dasu",
  autoRotate: "Automatick\xE9 ot\xE1\u010Den\xED",
  labelRotation: "Rotace",
  circle: "Kruh",
  polygon: "Mnoho\xFAheln\xEDk",
  square: "\u010Ctverec",
  cross: "K\u0159\xED\u017E",
  diamond: "Koso\u010Dtverec",
  plus: "Plus",
  triangle: "Troj\xFAheln\xEDk",
  heart: "Srdce",
  orientation: "Orientace",
  fixed: "Pevn\xFD",
  parallel: "Rovnob\u011B\u017En\xFD",
  perpendicular: "Kolm\xFD",
  radiusAxisPosition: "Pozice",
  ticks: "Rysky",
  gridLines: "M\u0159\xED\u017Ekov\xE9 \u010D\xE1ry",
  width: "\u0160\xED\u0159ka",
  height: "V\xFD\u0161ka",
  length: "D\xE9lka",
  padding: "Odsazen\xED",
  spacing: "Mezera",
  chartStyle: "Styl grafu",
  title: "N\xE1zev",
  chartTitles: "N\xE1zvy",
  chartTitle: "N\xE1zev grafu",
  chartSubtitle: "Podn\xE1zev",
  horizontalAxisTitle: "N\xE1zev vodorovn\xE9 osy",
  verticalAxisTitle: "N\xE1zev svisl\xE9 osy",
  polarAxisTitle: "N\xE1zev pol\xE1rn\xED osy",
  titlePlaceholder: "N\xE1zev grafu",
  background: "Pozad\xED",
  font: "P\xEDsmo",
  weight: "V\xE1ha",
  top: "Naho\u0159e",
  right: "Vpravo",
  bottom: "Dole",
  left: "Vlevo",
  labels: "\u0160t\xEDtky",
  calloutLabels: "\u0160t\xEDtky s odkazem",
  sectorLabels: "\u0160t\xEDtky sektor\u016F",
  positionRatio: "Pom\u011Br pozice",
  size: "Velikost",
  shape: "Tvar",
  minSize: "Minim\xE1ln\xED velikost",
  maxSize: "Maxim\xE1ln\xED velikost",
  legend: "Legenda",
  position: "Pozice",
  markerSize: "Velikost zna\u010Dky",
  markerStroke: "Obrys zna\u010Dky",
  markerPadding: "Odsazen\xED zna\u010Dky",
  itemSpacing: "Odstupy polo\u017Eek",
  itemPaddingX: "Odsazen\xED polo\u017Eky X",
  itemPaddingY: "Odsazen\xED polo\u017Eky Y",
  layoutHorizontalSpacing: "Horizont\xE1ln\xED odstupy",
  layoutVerticalSpacing: "Vertik\xE1ln\xED odstupy",
  strokeWidth: "\u0160\xED\u0159ka \u010D\xE1ry",
  offset: "Posunut\xED",
  offsets: "Posunut\xED",
  tooltips: "Popisky",
  callout: "Popisek",
  markers: "Zna\u010Dky",
  shadow: "St\xEDn",
  blur: "Rozost\u0159en\xED",
  xOffset: "Posunut\xED X",
  yOffset: "Posunut\xED Y",
  lineWidth: "\u0160\xED\u0159ka \u010D\xE1ry",
  lineDash: "P\u0159eru\u0161ovan\xE1 \u010D\xE1ra",
  lineDashOffset: "Posun p\u0159eru\u0161ovan\xE9 \u010D\xE1ry",
  scrollingZoom: "P\u0159ibl\xED\u017Een\xED rolov\xE1n\xEDm",
  scrollingStep: "Krok rolov\xE1n\xED",
  selectingZoom: "P\u0159ibl\xED\u017Een\xED v\xFDb\u011Brem",
  durationMillis: "Doba trv\xE1n\xED (ms)",
  crosshairLabel: "\u0160t\xEDtek",
  crosshairSnap: "P\u0159ichytit k bodu",
  normal: "Norm\xE1ln\xED",
  bold: "Tu\u010Dn\xE9",
  italic: "Kurz\xEDva",
  boldItalic: "Tu\u010Dn\xE1 kurz\xEDva",
  predefined: "P\u0159eddefinovan\xE9",
  fillOpacity: "Pr\u016Fhlednost v\xFDpln\u011B",
  strokeColor: "Barva \u010D\xE1ry",
  strokeOpacity: "Pr\u016Fhlednost \u010D\xE1ry",
  miniChart: "Mini-graf",
  histogramBinCount: "Po\u010Det bal\xEDk\u016F",
  connectorLine: "Spojovac\xED \u010D\xE1ra",
  seriesItems: "Polo\u017Eky s\xE9rie",
  seriesItemType: "Typ polo\u017Eky",
  seriesItemPositive: "Pozitivn\xED",
  seriesItemNegative: "Negativn\xED",
  seriesItemLabels: "\u0160t\xEDtky polo\u017Eek",
  columnGroup: "Sloupec",
  barGroup: "Li\u0161ta",
  pieGroup: "Kol\xE1\u010D",
  lineGroup: "\u010C\xE1ra",
  scatterGroup: "XY (Rozptyl)",
  areaGroup: "Oblast",
  polarGroup: "Pol\xE1rn\xED",
  statisticalGroup: "Statistick\xFD",
  hierarchicalGroup: "Hierarchick\xFD",
  specializedGroup: "Specializovan\xFD",
  combinationGroup: "Kombinovan\xFD",
  groupedColumnTooltip: "Seskupen\xFD",
  stackedColumnTooltip: "Stohovan\xFD",
  normalizedColumnTooltip: "100% stohovan\xFD",
  groupedBarTooltip: "Seskupen\xFD",
  stackedBarTooltip: "Stohovan\xFD",
  normalizedBarTooltip: "100% stohovan\xFD",
  pieTooltip: "Kol\xE1\u010D",
  donutTooltip: "Prstencov\xFD",
  lineTooltip: "\u010C\xE1ra",
  groupedAreaTooltip: "Oblast",
  stackedAreaTooltip: "Stohovan\xFD",
  normalizedAreaTooltip: "100% stohovan\xFD",
  scatterTooltip: "Rozptyl",
  bubbleTooltip: "Bublina",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radi\xE1ln\xED sloupec",
  radialBarTooltip: "Radi\xE1ln\xED li\u0161ta",
  radarLineTooltip: "Radarov\xE1 \u010D\xE1ra",
  radarAreaTooltip: "Radarov\xE1 oblast",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Rozsahov\xE1 li\u0161ta",
  rangeAreaTooltip: "Rozsahov\xE1 oblast",
  boxPlotTooltip: "Krabicov\xFD graf",
  treemapTooltip: "Stromov\xE1 mapa",
  sunburstTooltip: "V\xFDbuch slunce",
  waterfallTooltip: "Vodop\xE1d",
  heatmapTooltip: "Tepeln\xE1 mapa",
  columnLineComboTooltip: "Sloupec a \u010D\xE1ra",
  areaColumnComboTooltip: "Oblast a sloupec",
  customComboTooltip: "Vlastn\xED kombinace",
  innerRadius: "Vnit\u0159n\xED polom\u011Br",
  startAngle: "Po\u010D\xE1te\u010Dn\xED \xFAhel",
  endAngle: "Koncov\xFD \xFAhel",
  reverseDirection: "Obr\xE1tit sm\u011Br",
  groupPadding: "Odsazen\xED skupiny",
  seriesPadding: "Odsazen\xED s\xE9rie",
  tile: "Dla\u017Edice",
  whisker: "Vous",
  cap: "Uz\xE1v\u011Br",
  capLengthRatio: "Pom\u011Br d\xE9lky",
  labelPlacement: "Um\xEDst\u011Bn\xED \u0161t\xEDtku",
  inside: "Uvnit\u0159",
  outside: "Vn\u011B",
  noDataToChart: "Nejsou k dispozici \u017E\xE1dn\xE1 data k vykreslen\xED.",
  pivotChartRequiresPivotMode: "Kontingen\u010Dn\xED graf vy\u017Eaduje zapnut\xFD kontingen\u010Dn\xED re\u017Eim.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Propojen\xFD s m\u0159\xED\u017Ekou",
  chartUnlinkToolbarTooltip: "Odpojen\xFD od m\u0159\xED\u017Eky",
  chartDownloadToolbarTooltip: "St\xE1hnout graf",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Upravit graf",
  chartAdvancedSettings: "Pokro\u010Dil\xE1 nastaven\xED",
  chartLink: "Propojit s m\u0159\xED\u017Ekou",
  chartUnlink: "Odpojit od m\u0159\xED\u017Eky",
  chartDownload: "St\xE1hnout graf",
  histogramFrequency: "Frekvence",
  seriesChartType: "Typ grafu s\xE9rie",
  seriesType: "Typ s\xE9rie",
  secondaryAxis: "Sekund\xE1rn\xED osa",
  seriesAdd: "P\u0159idat s\xE9rii",
  categoryAdd: "P\u0159idat kategorii",
  bar: "Li\u0161ta",
  column: "Sloupec",
  histogram: "Histogram",
  advancedSettings: "Pokro\u010Dil\xE1 nastaven\xED",
  direction: "Sm\u011Br",
  horizontal: "Horizont\xE1ln\xED",
  vertical: "Vertik\xE1ln\xED",
  seriesGroupType: "Typ skupiny",
  groupedSeriesGroupType: "Seskupen\xE1",
  stackedSeriesGroupType: "Stohovan\xE1",
  normalizedSeriesGroupType: "100% stohovan\xE1",
  legendEnabled: "Povoleno",
  invalidColor: "Neplatn\xE1 hodnota barvy",
  groupedColumnFull: "Seskupen\xFD sloupec",
  stackedColumnFull: "Stohovan\xFD sloupec",
  normalizedColumnFull: "100% stohovan\xFD sloupec",
  groupedBarFull: "Seskupen\xE1 li\u0161ta",
  stackedBarFull: "Stohovan\xE1 li\u0161ta",
  normalizedBarFull: "100% stohovan\xE1 li\u0161ta",
  stackedAreaFull: "Stohovan\xE1 oblast",
  normalizedAreaFull: "100% stohovan\xE1 oblast",
  customCombo: "Vlastn\xED kombinace",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \xDArove\u0148 ${variable}. Stiskn\u011Bte ENTER pro \xFApravu.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \xDArove\u0148 ${variable}. ${variable} Stiskn\u011Bte ENTER pro \xFApravu.",
  ariaAdvancedFilterBuilderList: "Seznam pokro\u010Dil\xFDch filtr\u016F",
  ariaAdvancedFilterBuilderFilterItem: "Podm\xEDnka filtru",
  ariaAdvancedFilterBuilderGroupItem: "Skupina filtr\u016F",
  ariaAdvancedFilterBuilderColumn: "Sloupec",
  ariaAdvancedFilterBuilderOption: "Mo\u017Enost",
  ariaAdvancedFilterBuilderValueP: "Hodnota",
  ariaAdvancedFilterBuilderJoinOperator: "Spojovac\xED oper\xE1tor",
  ariaAdvancedFilterInput: "Vstup pokro\u010Dil\xE9ho filtru",
  ariaChecked: "za\u0161krtnuto",
  ariaColumn: "Sloupec",
  ariaColumnGroup: "Skupina sloupc\u016F",
  ariaColumnFiltered: "Sloupec filtrov\xE1n",
  ariaColumnSelectAll: "P\u0159epnout v\xFDb\u011Br v\u0161ech sloupc\u016F",
  ariaDateFilterInput: "Vstup datov\xE9ho filtru",
  ariaDefaultListName: "Seznam",
  ariaFilterColumnsInput: "Vstup filtru sloupc\u016F",
  ariaFilterFromValue: "Filtrovat od hodnoty",
  ariaFilterInput: "Vstup filtru",
  ariaFilterList: "Seznam filtr\u016F",
  ariaFilterToValue: "Filtrovat do hodnoty",
  ariaFilterValue: "Hodnota filtru",
  ariaFilterMenuOpen: "Otev\u0159\xEDt nab\xEDdku filtru",
  ariaFilteringOperator: "Oper\xE1tor filtrov\xE1n\xED",
  ariaHidden: "skryto",
  ariaIndeterminate: "neur\u010Deno",
  ariaInputEditor: "Editor vstupu",
  ariaMenuColumn: "Stiskn\u011Bte ALT DOL\u016E pro otev\u0159en\xED nab\xEDdky sloupce",
  ariaFilterColumn: "Stiskn\u011Bte CTRL ENTER pro otev\u0159en\xED filtru",
  ariaRowDeselect: "Stiskn\u011Bte SPACE pro zru\u0161en\xED v\xFDb\u011Bru tohoto \u0159\xE1dku",
  ariaRowSelectAll: "Stiskn\u011Bte SPACE pro p\u0159epnut\xED v\xFDb\u011Bru v\u0161ech \u0159\xE1dk\u016F",
  ariaRowToggleSelection: "Stiskn\u011Bte SPACE pro p\u0159epnut\xED v\xFDb\u011Bru \u0159\xE1dku",
  ariaRowSelect: "Stiskn\u011Bte SPACE pro v\xFDb\u011Br tohoto \u0159\xE1dku",
  ariaRowSelectionDisabled: "V\xFDb\u011Br \u0159\xE1dku je pro tento \u0159\xE1dek zak\xE1z\xE1n",
  ariaSearch: "Hledat",
  ariaSortableColumn: "Stiskn\u011Bte ENTER pro t\u0159\xEDd\u011Bn\xED",
  ariaToggleVisibility: "Stiskn\u011Bte SPACE pro p\u0159epnut\xED viditelnosti",
  ariaToggleCellValue: "Stiskn\u011Bte SPACE pro p\u0159epnut\xED hodnoty bu\u0148ky",
  ariaUnchecked: "neza\u0161krtnuto",
  ariaVisible: "viditeln\xE9",
  ariaSearchFilterValues: "Hled\xE1n\xED hodnot filtru",
  ariaPageSizeSelectorLabel: "Velikost str\xE1nky",
  ariaChartMenuClose: "Zav\u0159\xEDt nab\xEDdku \xFApravy grafu",
  ariaChartSelected: "Vybr\xE1no",
  ariaSkeletonCellLoadingFailed: "\u0158\xE1dek se nepoda\u0159ilo na\u010D\xEDst",
  ariaSkeletonCellLoading: "Data \u0159\xE1dku se na\u010D\xEDtaj\xED",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Skupiny \u0159\xE1dk\u016F",
  ariaValuesDropZonePanelLabel: "Hodnoty",
  ariaPivotDropZonePanelLabel: "Popisky sloupc\u016F",
  ariaDropZoneColumnComponentDescription: "Stiskn\u011Bte DELETE pro odstran\u011Bn\xED",
  ariaDropZoneColumnValueItemDescription: "Stiskn\u011Bte ENTER pro zm\u011Bnu typu agregace",
  ariaDropZoneColumnGroupItemDescription: "Stiskn\u011Bte ENTER pro se\u0159azen\xED",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " z ",
  ariaDropZoneColumnComponentSortAscending: "vzestupn\u011B",
  ariaDropZoneColumnComponentSortDescending: "sestupn\u011B",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Nab\xEDdka sloupc\u016F",
  ariaLabelColumnFilter: "Filtr sloupc\u016F",
  ariaLabelCellEditor: "Editor bu\u0148ky",
  ariaLabelSelectField: "Vyberte pole",
  // aria labels for rich select
  ariaLabelRichSelectField: "V\xFDb\u011Brov\xE9 pole",
  ariaLabelRichSelectToggleSelection: "Stiskn\u011Bte MEZERN\xCDK pro p\u0159epnut\xED v\xFDb\u011Bru",
  ariaLabelRichSelectDeselectAllItems: "Stiskn\u011Bte DELETE pro zru\u0161en\xED v\xFDb\u011Bru v\u0161ech polo\u017Eek",
  ariaLabelRichSelectDeleteSelection: "Stiskn\u011Bte DELETE pro zru\u0161en\xED v\xFDb\u011Bru polo\u017Eky",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Kontextov\xE1 nab\xEDdka",
  ariaLabelSubMenu: "Podnab\xEDdka",
  ariaLabelAggregationFunction: "Agrega\u010Dn\xED funkce",
  ariaLabelAdvancedFilterAutocomplete: "Pokro\u010Dil\xFD filtr Autocomplete",
  ariaLabelAdvancedFilterBuilderAddField: "Pokro\u010Dil\xFD filtr P\u0159idat pole",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Pokro\u010Dil\xFD filtr V\xFDb\u011Br sloupce",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Pokro\u010Dil\xFD filtr V\xFDb\u011Br mo\u017Enosti",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Pokro\u010Dil\xFD filtr V\xFDb\u011Br oper\xE1tora spojen\xED",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Seznam sloupc\u016F",
  ariaFilterPanelList: "Seznam filtr\u016F",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "\u201A",
  decimalSeparator: ",",
  // Data types
  true: "Pravda",
  false: "Nepravda",
  invalidDate: "Neplatn\xE9 datum",
  invalidNumber: "Neplatn\xE9 \u010D\xEDslo",
  january: "Leden",
  february: "\xDAnor",
  march: "B\u0159ezen",
  april: "Duben",
  may: "Kv\u011Bten",
  june: "\u010Cerven",
  july: "\u010Cervenec",
  august: "Srpen",
  september: "Z\xE1\u0159\xED",
  october: "\u0158\xEDjen",
  november: "Listopad",
  december: "Prosinec",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/RRRR",
  timeFormatSlashesMMDDYYYY: "MM/DD/RRRR",
  timeFormatSlashesDDMMYY: "DD/MM/RR",
  timeFormatSlashesMMDDYY: "MM/DD/RR",
  timeFormatDotsDDMYY: "DD.M.RR",
  timeFormatDotsMDDYY: "M.DD.RR",
  timeFormatDashesYYYYMMDD: "RRRR-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM RRRR",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/da-DK.ts
var AG_GRID_LOCALE_DK = {
  // Set Filter
  selectAll: "(V\xE6lg alle)",
  selectAllSearchResults: "(V\xE6lg alle s\xF8geresultater)",
  addCurrentSelectionToFilter: "Tilf\xF8j aktuelle valg til filter",
  searchOoo: "S\xF8g...",
  blanks: "(Tomt)",
  noMatches: "Ingen resultater",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Lige med",
  notEqual: "Ikke lige med",
  blank: "Tom",
  notBlank: "Ikke tom",
  empty: "V\xE6lg \xE9n",
  // Number Filter
  lessThan: "Mindre end",
  greaterThan: "St\xF8rre end",
  lessThanOrEqual: "Mindre end eller lig med",
  greaterThanOrEqual: "St\xF8rre end eller lig med",
  inRange: "Mellem",
  inRangeStart: "Fra",
  inRangeEnd: "Til",
  // Text Filter
  contains: "Indeholder",
  notContains: "Indeholder ikke",
  startsWith: "Begynder med",
  endsWith: "Slutter med",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "F\xF8r",
  after: "Efter",
  // Filter Conditions
  andCondition: "OG",
  orCondition: "ELLER",
  // Filter Buttons
  applyFilter: "Anvend",
  resetFilter: "Nulstil",
  clearFilter: "Ryd",
  cancelFilter: "Annuller",
  // Filter Titles
  textFilter: "Tekstfilter",
  numberFilter: "Nummerfilter",
  dateFilter: "Dato filter",
  setFilter: "S\xE6tfilter",
  // Group Column Filter
  groupFilterSelect: "V\xE6lg felt:",
  // Advanced Filter
  advancedFilterContains: "indeholder",
  advancedFilterNotContains: "indeholder ikke",
  advancedFilterTextEquals: "er lig med",
  advancedFilterTextNotEqual: "er ikke lig med",
  advancedFilterStartsWith: "begynder med",
  advancedFilterEndsWith: "slutter med",
  advancedFilterBlank: "er tom",
  advancedFilterNotBlank: "er ikke tom",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "er sand",
  advancedFilterFalse: "er falsk",
  advancedFilterAnd: "OG",
  advancedFilterOr: "ELLER",
  advancedFilterApply: "Anvend",
  advancedFilterBuilder: "Builder",
  advancedFilterValidationMissingColumn: "Kolonne mangler",
  advancedFilterValidationMissingOption: "Valgmulighed mangler",
  advancedFilterValidationMissingValue: "V\xE6rdi mangler",
  advancedFilterValidationInvalidColumn: "Kolonne ikke fundet",
  advancedFilterValidationInvalidOption: "Valgmulighed ikke fundet",
  advancedFilterValidationMissingQuote: "V\xE6rdi mangler et afsluttende anf\xF8rselstegn",
  advancedFilterValidationNotANumber: "V\xE6rdi er ikke et tal",
  advancedFilterValidationInvalidDate: "V\xE6rdi er ikke en gyldig dato",
  advancedFilterValidationMissingCondition: "Betingelse mangler",
  advancedFilterValidationJoinOperatorMismatch: "Join-operat\xF8rer inden for en betingelse skal v\xE6re ens",
  advancedFilterValidationInvalidJoinOperator: "Join-operator ikke fundet",
  advancedFilterValidationMissingEndBracket: "Mangler afsluttende parentes",
  advancedFilterValidationExtraEndBracket: "For mange afsluttende parenteser",
  advancedFilterValidationMessage: "Udtrykket har en fejl. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Udtrykket har en fejl. ${variable} i slutningen af udtrykket.",
  advancedFilterBuilderTitle: "Avanceret Filter",
  advancedFilterBuilderApply: "Anvend",
  advancedFilterBuilderCancel: "Annuller",
  advancedFilterBuilderAddButtonTooltip: "Tilf\xF8j Filter eller Gruppe",
  advancedFilterBuilderRemoveButtonTooltip: "Fjern",
  advancedFilterBuilderMoveUpButtonTooltip: "Flyt op",
  advancedFilterBuilderMoveDownButtonTooltip: "Flyt ned",
  advancedFilterBuilderAddJoin: "Tilf\xF8j Gruppe",
  advancedFilterBuilderAddCondition: "Tilf\xF8j Filter",
  advancedFilterBuilderSelectColumn: "V\xE6lg en kolonne",
  advancedFilterBuilderSelectOption: "V\xE6lg en valgmulighed",
  advancedFilterBuilderEnterValue: "Indtast en v\xE6rdi...",
  advancedFilterBuilderValidationAlreadyApplied: "Aktuelt filter allerede anvendt.",
  advancedFilterBuilderValidationIncomplete: "Ikke alle betingelser er fuldf\xF8rt.",
  advancedFilterBuilderValidationSelectColumn: "Skal v\xE6lge en kolonne.",
  advancedFilterBuilderValidationSelectOption: "Skal v\xE6lge en valgmulighed.",
  advancedFilterBuilderValidationEnterValue: "Skal indtaste en v\xE6rdi.",
  // Side Bar
  columns: "Kolonner",
  filters: "Filtre",
  // columns tool panel
  pivotMode: "Pivot-tilstand",
  groups: "R\xE6kkegrupper",
  rowGroupColumnsEmptyMessage: "Tr\xE6k her for at s\xE6tte r\xE6kkegrupper",
  values: "V\xE6rdier",
  valueColumnsEmptyMessage: "Tr\xE6k her for at samle",
  pivots: "Kolonneetiketter",
  pivotColumnsEmptyMessage: "Tr\xE6k her for at s\xE6tte kolonneetiketter",
  // Header of the Default Group Column
  group: "Gruppe",
  // Row Drag
  rowDragRow: "r\xE6kke",
  rowDragRows: "r\xE6kker",
  // Other
  loadingOoo: "Indl\xE6ser...",
  loadingError: "FEJL",
  noRowsToShow: "Ingen r\xE6kker at vise",
  enabled: "Aktiveret",
  // Menu
  pinColumn: "Fastg\xF8r Kolonne",
  pinLeft: "Fastg\xF8r Venstre",
  pinRight: "Fastg\xF8r H\xF8jre",
  noPin: "Ingen Fastg\xF8relse",
  valueAggregation: "V\xE6rdi Aggregering",
  noAggregation: "Ingen",
  autosizeThisColumn: "Autosize Denne Kolonne",
  autosizeAllColumns: "Autosize Alle Kolonner",
  groupBy: "Grupp\xE9r efter",
  ungroupBy: "Fjern Grupp\xE9r efter",
  ungroupAll: "Fjern Alle Grupp\xE9r",
  addToValues: "Tilf\xF8j ${variable} til v\xE6rdier",
  removeFromValues: "Fjern ${variable} fra v\xE6rdier",
  addToLabels: "Tilf\xF8j ${variable} til etiketter",
  removeFromLabels: "Fjern ${variable} fra etiketter",
  resetColumns: "Nulstil Kolonner",
  expandAll: "Udvid Alle R\xE6kkegrupper",
  collapseAll: "Luk Alle R\xE6kkegrupper",
  copy: "Kopier",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopier Med Overskrifter",
  copyWithGroupHeaders: "Kopier med Gruppeoverskrifter",
  cut: "Klip",
  paste: "S\xE6t Ind",
  ctrlV: "Ctrl+V",
  export: "Eksporter",
  csvExport: "CSV Eksport",
  excelExport: "Excel Eksport",
  columnFilter: "Kolonne Filter",
  columnChooser: "V\xE6lg Kolonner",
  chooseColumns: "V\xE6lg Kolonner",
  sortAscending: "Sorter Stigende",
  sortDescending: "Sorter Faldende",
  sortUnSort: "Ryd Sortering",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Sum",
  first: "F\xF8rste",
  last: "Sidste",
  min: "Min",
  max: "Maks",
  none: "Ingen",
  count: "T\xE6lle",
  avg: "Gennemsnit",
  filteredRows: "Filtreret",
  selectedRows: "Valgte",
  totalRows: "Total R\xE6kker",
  totalAndFilteredRows: "R\xE6kker",
  more: "Mere",
  to: "til",
  of: "af",
  page: "Side",
  pageLastRowUnknown: "?",
  nextPage: "N\xE6ste Side",
  lastPage: "Sidste Side",
  firstPage: "F\xF8rste Side",
  previousPage: "Forrige Side",
  pageSizeSelectorLabel: "Side St\xF8rrelse:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Totaler",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivotdiagram og pivottilstand",
  pivotChart: "Pivotdiagram",
  chartRange: "Diagramomr\xE5de",
  columnChart: "S\xF8jlediagram",
  groupedColumn: "Gruperet",
  stackedColumn: "Stablet",
  normalizedColumn: "100% Stablet",
  barChart: "S\xF8jlediagram",
  groupedBar: "Gruperet",
  stackedBar: "Stablet",
  normalizedBar: "100% Stablet",
  pieChart: "Cirkeldiagram",
  pie: "Cirkeldiagram",
  donut: "Donut",
  line: "Linjediagram",
  xyChart: "X Y (Punktdiagram)",
  scatter: "Punktdiagram",
  bubble: "Boblediagram",
  areaChart: "Arealdiagram",
  area: "Areal",
  stackedArea: "Stablet",
  normalizedArea: "100% Stablet",
  histogramChart: "Histogram",
  polarChart: "Polardiagram",
  radarLine: "Radarlinje",
  radarArea: "Radarareal",
  nightingale: "Nightingale",
  radialColumn: "Radial S\xF8jle",
  radialBar: "Radial S\xF8jle",
  statisticalChart: "Statistisk diagram",
  boxPlot: "Box Plot",
  rangeBar: "Intervalls\xF8jle",
  rangeArea: "Intervallsareal",
  hierarchicalChart: "Hierarkisk diagram",
  treemap: "Tr\xE6diagram",
  sunburst: "Solstr\xE5lediagram",
  specializedChart: "Specialiseret diagram",
  waterfall: "Vandfaldsdiagram",
  heatmap: "Heatmap",
  combinationChart: "Kombinationsdiagram",
  columnLineCombo: "S\xF8jle & Linje",
  AreaColumnCombo: "Areal & S\xF8jle",
  // Charts
  pivotChartTitle: "Pivot Diagram",
  rangeChartTitle: "R\xE6kkediagram",
  settings: "Diagram",
  data: "Ops\xE6tning",
  format: "Tilpas",
  categories: "Kategorier",
  defaultCategory: "(Ingen)",
  series: "Serier",
  switchCategorySeries: "Skift Kategori / Serier",
  categoryValues: "Kategoriv\xE6rdier",
  seriesLabels: "Seriem\xE6rker",
  aggregate: "Aggregeret",
  xyValues: "X Y V\xE6rdier",
  paired: "Parret Tilstand",
  axis: "Akse",
  xAxis: "Horisontal Akse",
  yAxis: "Vertikal Akse",
  polarAxis: "Polar Akse",
  radiusAxis: "Radius Akse",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "Tv\xE6rh\xE5r",
  color: "Farve",
  thickness: "Tykkelse",
  preferredLength: "Foretrukken L\xE6ngde",
  xType: "X Type",
  axisType: "Akse Type",
  automatic: "Automatisk",
  category: "Kategori",
  number: "Nummer",
  time: "Tid",
  timeFormat: "Tidsformat",
  autoRotate: "Auto Rotate",
  labelRotation: "Rotation",
  circle: "Cirkel",
  polygon: "Polygon",
  square: "Firkant",
  cross: "Kryds",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Trekant",
  heart: "Hjerte",
  orientation: "Orientering",
  fixed: "Fast",
  parallel: "Parallelt",
  perpendicular: "Vinkelret",
  radiusAxisPosition: "Position",
  ticks: "Markeringer",
  gridLines: "Gitterlinjer",
  width: "Bredde",
  height: "H\xF8jde",
  length: "L\xE6ngde",
  padding: "Polstring",
  spacing: "Mellemrum",
  chartStyle: "Diagramstil",
  title: "Titel",
  chartTitles: "Titler",
  chartTitle: "Diagramtitel",
  chartSubtitle: "Undertitel",
  horizontalAxisTitle: "Horisontal Akse Titel",
  verticalAxisTitle: "Vertikal Akse Titel",
  polarAxisTitle: "Polar Akse Titel",
  titlePlaceholder: "Diagramtitel",
  background: "Baggrund",
  font: "Skrifttype",
  weight: "V\xE6gt",
  top: "Top",
  right: "H\xF8jre",
  bottom: "Bund",
  left: "Venstre",
  labels: "M\xE6rker",
  calloutLabels: "Callout M\xE6rker",
  sectorLabels: "Sektorm\xE6rker",
  positionRatio: "Positionsforhold",
  size: "St\xF8rrelse",
  shape: "Form",
  minSize: "Minimumsst\xF8rrelse",
  maxSize: "Maksimalst\xF8rrelse",
  legend: "Legende",
  position: "Position",
  markerSize: "Mark\xF8rst\xF8rrelse",
  markerStroke: "Mark\xF8rstr\xF8g",
  markerPadding: "Mark\xF8rpolstring",
  itemSpacing: "Vareafstand",
  itemPaddingX: "Varepolstring X",
  itemPaddingY: "Varepolstring Y",
  layoutHorizontalSpacing: "Horisontal Afstand",
  layoutVerticalSpacing: "Vertikal Afstand",
  strokeWidth: "Str\xF8gtykkelse",
  offset: "Forskydning",
  offsets: "Forskydninger",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Mark\xF8rer",
  shadow: "Skygge",
  blur: "Sl\xF8ring",
  xOffset: "X Forskydning",
  yOffset: "Y Forskydning",
  lineWidth: "Linjetykkelse",
  lineDash: "Linje Markering",
  lineDashOffset: "Markering Forskydning",
  scrollingZoom: "Rulning",
  scrollingStep: "Rulnigstrin",
  selectingZoom: "Valg",
  durationMillis: "Varighed (ms)",
  crosshairLabel: "M\xE6rkat",
  crosshairSnap: "Snap til knude",
  normal: "Normal",
  bold: "Fed",
  italic: "Kursiv",
  boldItalic: "Fed Kursiv",
  predefined: "Foruddefineret",
  fillOpacity: "Fyld Opacitet",
  strokeColor: "Linje Farve",
  strokeOpacity: "Linjegennemsigtighed",
  miniChart: "Mini-diagram",
  histogramBinCount: "Bin t\xE6lling",
  connectorLine: "Forbindelselinje",
  seriesItems: "Seriens Elementer",
  seriesItemType: "Varetype",
  seriesItemPositive: "Positiv",
  seriesItemNegative: "Negativ",
  seriesItemLabels: "Varem\xE6rker",
  columnGroup: "Kolonne",
  barGroup: "S\xF8jle",
  pieGroup: "T\xE6rte",
  lineGroup: "Linje",
  scatterGroup: "X Y (Spredning)",
  areaGroup: "Areal",
  polarGroup: "Polar",
  statisticalGroup: "Statistisk",
  hierarchicalGroup: "Hierarkisk",
  specializedGroup: "Specialiseret",
  combinationGroup: "Kombination",
  groupedColumnTooltip: "Grupperet",
  stackedColumnTooltip: "Stablet",
  normalizedColumnTooltip: "100% Stablet",
  groupedBarTooltip: "Grupperet",
  stackedBarTooltip: "Stablet",
  normalizedBarTooltip: "100% Stablet",
  pieTooltip: "T\xE6rte",
  donutTooltip: "Donut",
  lineTooltip: "Linje",
  groupedAreaTooltip: "Areal",
  stackedAreaTooltip: "Stablet",
  normalizedAreaTooltip: "100% Stablet",
  scatterTooltip: "Spredning",
  bubbleTooltip: "Boble",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radial Kolonne",
  radialBarTooltip: "Radial S\xF8jle",
  radarLineTooltip: "Radar Linje",
  radarAreaTooltip: "Radar Areal",
  nightingaleTooltip: "Nattergal",
  rangeBarTooltip: "Omr\xE5de S\xF8jle",
  rangeAreaTooltip: "Omr\xE5de Areal",
  boxPlotTooltip: "Boksplot",
  treemapTooltip: "Trekort",
  sunburstTooltip: "Solstr\xE5le",
  waterfallTooltip: "Vandfald",
  heatmapTooltip: "Varmekort",
  columnLineComboTooltip: "Kolonne & Linje",
  areaColumnComboTooltip: "Areal & Kolonne",
  customComboTooltip: "Brugerdefineret Kombination",
  innerRadius: "Indre Radius",
  startAngle: "Startvinkel",
  endAngle: "Slutvinkel",
  reverseDirection: "Omvendt Retning",
  groupPadding: "Gruppepolstring",
  seriesPadding: "Seriepolstring",
  tile: "Flise",
  whisker: "Vibrisse",
  cap: "H\xE6tte",
  capLengthRatio: "L\xE6ngdeforhold",
  labelPlacement: "M\xE6rkeplacering",
  inside: "Inde",
  outside: "Ude",
  noDataToChart: "Ingen data til r\xE5dighed til at lave diagram",
  pivotChartRequiresPivotMode: "Pivot Diagrams kr\xE6ver at Pivot Tilstand er aktiveret",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Knyttet til Gitter",
  chartUnlinkToolbarTooltip: "Ikke knyttet til Gitter",
  chartDownloadToolbarTooltip: "Download Diagram",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Rediger Diagram",
  chartAdvancedSettings: "Avancerede Indstillinger",
  chartLink: "Knyt til Gitter",
  chartUnlink: "Fjern Knytning fra Gitter",
  chartDownload: "Download Diagram",
  histogramFrequency: "Frekvens",
  seriesChartType: "Serie Diagram Type",
  seriesType: "Serietype",
  secondaryAxis: "Sekund\xE6r Akse",
  seriesAdd: "Tilf\xF8j en serie",
  categoryAdd: "Tilf\xF8j en kategori",
  bar: "S\xF8jle",
  column: "Kolonne",
  histogram: "Histogram",
  advancedSettings: "Avancerede Indstillinger",
  direction: "Retning",
  horizontal: "Horisontal",
  vertical: "Vertikal",
  seriesGroupType: "Gruppetype",
  groupedSeriesGroupType: "Grupperet",
  stackedSeriesGroupType: "Stablet",
  normalizedSeriesGroupType: "100% Stablet",
  legendEnabled: "Aktiveret",
  invalidColor: "Farvev\xE6rdien er ugyldig",
  groupedColumnFull: "Grupperet Kolonne",
  stackedColumnFull: "Stablet Kolonne",
  normalizedColumnFull: "100% Stablet Kolonne",
  groupedBarFull: "Grupperet S\xF8jle",
  stackedBarFull: "Stablet S\xF8jle",
  normalizedBarFull: "100% Stablet S\xF8jle",
  stackedAreaFull: "Stablet Areal",
  normalizedAreaFull: "100% Stablet Areal",
  customCombo: "Brugerdefineret Kombination",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Niveau ${variable}. Tryk p\xE5 ENTER for at redigere.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Niveau ${variable}. ${variable} Tryk p\xE5 ENTER for at redigere.",
  ariaAdvancedFilterBuilderList: "Avanceret Filter Builder Liste",
  ariaAdvancedFilterBuilderFilterItem: "Filterbetingelse",
  ariaAdvancedFilterBuilderGroupItem: "Filtergruppe",
  ariaAdvancedFilterBuilderColumn: "Kolonne",
  ariaAdvancedFilterBuilderOption: "Valgmulighed",
  ariaAdvancedFilterBuilderValueP: "V\xE6rdi",
  ariaAdvancedFilterBuilderJoinOperator: "Sammenk\xE6dningsoperator",
  ariaAdvancedFilterInput: "Avanceret Filter Input",
  ariaChecked: "markeret",
  ariaColumn: "Kolonne",
  ariaColumnGroup: "Kolonnegruppe",
  ariaColumnFiltered: "Kolonne Filtreret",
  ariaColumnSelectAll: "Skift valg af alle kolonner",
  ariaDateFilterInput: "Dato Filter Input",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "Filtrer Kolonner Input",
  ariaFilterFromValue: "Filtrer fra v\xE6rdi",
  ariaFilterInput: "Filter Input",
  ariaFilterList: "Filterliste",
  ariaFilterToValue: "Filtrer til v\xE6rdi",
  ariaFilterValue: "Filtrerv\xE6rdi",
  ariaFilterMenuOpen: "\xC5bn Filtermenu",
  ariaFilteringOperator: "Filtreringsoperator",
  ariaHidden: "skjult",
  ariaIndeterminate: "ubestemt",
  ariaInputEditor: "Inputredakt\xF8r",
  ariaMenuColumn: "Tryk p\xE5 ALT NED for at \xE5bne kolonnemenu",
  ariaFilterColumn: "Tryk p\xE5 CTRL ENTER for at \xE5bne filter",
  ariaRowDeselect: "Tryk p\xE5 MELLEMRUM for at frav\xE6lge denne r\xE6kke",
  ariaRowSelectAll: "Tryk p\xE5 MELLEMRUM for at skifte alle r\xE6kkers valg",
  ariaRowToggleSelection: "Tryk p\xE5 MELLEMRUM for at skifte r\xE6kkes valg",
  ariaRowSelect: "Tryk p\xE5 MELLEMRUM for at v\xE6lge denne r\xE6kke",
  ariaRowSelectionDisabled: "R\xE6kkevalg er deaktiveret for denne r\xE6kke",
  ariaSearch: "S\xF8g",
  ariaSortableColumn: "Tryk p\xE5 ENTER for at sortere",
  ariaToggleVisibility: "Tryk p\xE5 MELLEMRUM for at skifte synlighed",
  ariaToggleCellValue: "Tryk p\xE5 MELLEMRUM for at skifte cellev\xE6rdi",
  ariaUnchecked: "umarkeret",
  ariaVisible: "synlig",
  ariaSearchFilterValues: "S\xF8g filterv\xE6rdier",
  ariaPageSizeSelectorLabel: "Sidest\xF8rrelse",
  ariaChartMenuClose: "Luk Diagram Redigeringsmenu",
  ariaChartSelected: "Valgt",
  ariaSkeletonCellLoadingFailed: "R\xE6kke kunne ikke indl\xE6ses",
  ariaSkeletonCellLoading: "R\xE6kke data indl\xE6ses",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "R\xE6kkegrupper",
  ariaValuesDropZonePanelLabel: "V\xE6rdier",
  ariaPivotDropZonePanelLabel: "Kolonneetiketter",
  ariaDropZoneColumnComponentDescription: "Tryk DELETE for at fjerne",
  ariaDropZoneColumnValueItemDescription: "Tryk ENTER for at \xE6ndre aggregattypen",
  ariaDropZoneColumnGroupItemDescription: "Tryk ENTER for at sortere",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " of ",
  ariaDropZoneColumnComponentSortAscending: "stigende",
  ariaDropZoneColumnComponentSortDescending: "faldende",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Kolonnemenu",
  ariaLabelColumnFilter: "Kolonnefilter",
  ariaLabelCellEditor: "Celleredigering",
  ariaLabelSelectField: "V\xE6lg felt",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rich Select Felt",
  ariaLabelRichSelectToggleSelection: "Tryk p\xE5 MELLEMRUM for at skifte valg",
  ariaLabelRichSelectDeselectAllItems: "Tryk p\xE5 DELETE for at fjerne markeringen af alle emner",
  ariaLabelRichSelectDeleteSelection: "Tryk p\xE5 DELETE for at fjerne markeringen af emne",
  ariaLabelTooltip: "V\xE6rkt\xF8jstip",
  ariaLabelContextMenu: "Kontekstmenu",
  ariaLabelSubMenu: "UnderMenu",
  ariaLabelAggregationFunction: "Aggregeringsfunktion",
  ariaLabelAdvancedFilterAutocomplete: "Avanceret Filter Autocomplete",
  ariaLabelAdvancedFilterBuilderAddField: "Avanceret Filter Builder Tilf\xF8j Felt",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Avanceret Filter Builder Kolonne V\xE6lg Felt",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Avanceret Filter Builder Valg V\xE6lg Felt",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Avanceret Filter Builder Join Operator V\xE6lg Felt",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Kolonner Liste",
  ariaFilterPanelList: "Filter Liste",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "True",
  false: "False",
  invalidDate: "Ugyldig Dato",
  invalidNumber: "Ugyldigt Nummer",
  january: "Januar",
  february: "Februar",
  march: "Marts",
  april: "April",
  may: "Maj",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "TT:MM:SS",
  timeFormatHHMMSSAmPm: "TT:MM:SS AM/PM"
};

// community-modules/locale/src/de-DE.ts
var AG_GRID_LOCALE_DE = {
  // Set Filter
  selectAll: "(Alles ausw\xE4hlen)",
  selectAllSearchResults: "(Alle Suchergebnisse ausw\xE4hlen)",
  addCurrentSelectionToFilter: "Aktuelle Auswahl zum Filter hinzuf\xFCgen",
  searchOoo: "Suchen...",
  blanks: "(Leer)",
  noMatches: "Keine \xDCbereinstimmungen",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Gleich",
  notEqual: "Ungleich",
  blank: "Leer",
  notBlank: "Nicht leer",
  empty: "W\xE4hlen Sie eine",
  // Number Filter
  lessThan: "Weniger als",
  greaterThan: "Gr\xF6\xDFer als",
  lessThanOrEqual: "Weniger als oder gleich",
  greaterThanOrEqual: "Gr\xF6\xDFer als oder gleich",
  inRange: "Zwischen",
  inRangeStart: "Von",
  inRangeEnd: "Bis",
  // Text Filter
  contains: "Enth\xE4lt",
  notContains: "Enth\xE4lt nicht",
  startsWith: "Beginnt mit",
  endsWith: "Endet mit",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Vor",
  after: "Nach",
  // Filter Conditions
  andCondition: "UND",
  orCondition: "ODER",
  // Filter Buttons
  applyFilter: "Anwenden",
  resetFilter: "Zur\xFCcksetzen",
  clearFilter: "L\xF6schen",
  cancelFilter: "Abbrechen",
  // Filter Titles
  textFilter: "Textfilter",
  numberFilter: "Zahlenfilter",
  dateFilter: "Datumsfilter",
  setFilter: "Satzfilter",
  // Group Column Filter
  groupFilterSelect: "Feld ausw\xE4hlen:",
  // Advanced Filter
  advancedFilterContains: "enth\xE4lt",
  advancedFilterNotContains: "enth\xE4lt nicht",
  advancedFilterTextEquals: "gleich",
  advancedFilterTextNotEqual: "ungleich",
  advancedFilterStartsWith: "beginnt mit",
  advancedFilterEndsWith: "endet mit",
  advancedFilterBlank: "ist leer",
  advancedFilterNotBlank: "ist nicht leer",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "ist wahr",
  advancedFilterFalse: "ist falsch",
  advancedFilterAnd: "UND",
  advancedFilterOr: "ODER",
  advancedFilterApply: "Anwenden",
  advancedFilterBuilder: "Ersteller",
  advancedFilterValidationMissingColumn: "Spalte fehlt",
  advancedFilterValidationMissingOption: "Option fehlt",
  advancedFilterValidationMissingValue: "Wert fehlt",
  advancedFilterValidationInvalidColumn: "Spalte nicht gefunden",
  advancedFilterValidationInvalidOption: "Option nicht gefunden",
  advancedFilterValidationMissingQuote: "Wert fehlt ein Schlusszeichen",
  advancedFilterValidationNotANumber: "Wert ist keine Nummer",
  advancedFilterValidationInvalidDate: "Wert ist kein g\xFCltiges Datum",
  advancedFilterValidationMissingCondition: "Bedingung fehlt",
  advancedFilterValidationJoinOperatorMismatch: "Verkn\xFCpfungsoperatoren innerhalb einer Bedingung m\xFCssen gleich sein",
  advancedFilterValidationInvalidJoinOperator: "Verkn\xFCpfungsoperator nicht gefunden",
  advancedFilterValidationMissingEndBracket: "Endklammer fehlt",
  advancedFilterValidationExtraEndBracket: "Zu viele Endklammern",
  advancedFilterValidationMessage: "Ausdruck enth\xE4lt einen Fehler. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Ausdruck enth\xE4lt einen Fehler. ${variable} am Ende des Ausdrucks.",
  advancedFilterBuilderTitle: "Erweiterter Filter",
  advancedFilterBuilderApply: "Anwenden",
  advancedFilterBuilderCancel: "Abbrechen",
  advancedFilterBuilderAddButtonTooltip: "Filter oder Gruppe hinzuf\xFCgen",
  advancedFilterBuilderRemoveButtonTooltip: "Entfernen",
  advancedFilterBuilderMoveUpButtonTooltip: "Nach oben bewegen",
  advancedFilterBuilderMoveDownButtonTooltip: "Nach unten bewegen",
  advancedFilterBuilderAddJoin: "Gruppe hinzuf\xFCgen",
  advancedFilterBuilderAddCondition: "Filter hinzuf\xFCgen",
  advancedFilterBuilderSelectColumn: "Eine Spalte ausw\xE4hlen",
  advancedFilterBuilderSelectOption: "Eine Option ausw\xE4hlen",
  advancedFilterBuilderEnterValue: "Einen Wert eingeben...",
  advancedFilterBuilderValidationAlreadyApplied: "Aktueller Filter bereits angewendet.",
  advancedFilterBuilderValidationIncomplete: "Nicht alle Bedingungen sind vollst\xE4ndig.",
  advancedFilterBuilderValidationSelectColumn: "Eine Spalte muss ausgew\xE4hlt werden.",
  advancedFilterBuilderValidationSelectOption: "Eine Option muss ausgew\xE4hlt werden.",
  advancedFilterBuilderValidationEnterValue: "Ein Wert muss eingegeben werden.",
  // Side Bar
  columns: "Spalten",
  filters: "Filter",
  // columns tool panel
  pivotMode: "Pivot-Modus",
  groups: "Zeilengruppen",
  rowGroupColumnsEmptyMessage: "Ziehen Sie hierher, um Zeilengruppen festzulegen",
  values: "Werte",
  valueColumnsEmptyMessage: "Ziehen Sie hierher, um zu aggregieren",
  pivots: "Spaltenbezeichnungen",
  pivotColumnsEmptyMessage: "Ziehen Sie hierher, um Spaltenbezeichnungen festzulegen",
  // Header of the Default Group Column
  group: "Gruppe",
  // Row Drag
  rowDragRow: "Reihe",
  rowDragRows: "Reihen",
  // Other
  loadingOoo: "Wird geladen...",
  loadingError: "FEHLER",
  noRowsToShow: "Keine Zeilen zum Anzeigen",
  enabled: "Aktiviert",
  // Menu
  pinColumn: "Spalte anheften",
  pinLeft: "Links anheften",
  pinRight: "Rechts anheften",
  noPin: "Nicht anheften",
  valueAggregation: "Wertaggregation",
  noAggregation: "Keine",
  autosizeThisColumn: "Diese Spalte automatisch anpassen",
  autosizeAllColumns: "Alle Spalten automatisch anpassen",
  groupBy: "Gruppieren nach",
  ungroupBy: "Gruppierung aufheben nach",
  ungroupAll: "Alle Gruppierungen aufheben",
  addToValues: "F\xFCge ${variable} zu Werten hinzu",
  removeFromValues: "Entferne ${variable} aus Werten",
  addToLabels: "F\xFCge ${variable} zu Labels hinzu",
  removeFromLabels: "Entferne ${variable} aus Labels",
  resetColumns: "Spalten zur\xFCcksetzen",
  expandAll: "Alle Zeilengruppen erweitern",
  collapseAll: "Alle Zeilengruppen schlie\xDFen",
  copy: "Kopieren",
  ctrlC: "Strg+C",
  ctrlX: "Strg+X",
  copyWithHeaders: "Mit Kopfzeilen kopieren",
  copyWithGroupHeaders: "Mit Gruppenkopfzeilen kopieren",
  cut: "Ausschneiden",
  paste: "Einf\xFCgen",
  ctrlV: "Strg+V",
  export: "Exportieren",
  csvExport: "CSV-Export",
  excelExport: "Excel-Export",
  columnFilter: "Spaltenfilter",
  columnChooser: "Spalten ausw\xE4hlen",
  chooseColumns: "Spalten ausw\xE4hlen",
  sortAscending: "Aufsteigend sortieren",
  sortDescending: "Absteigend sortieren",
  sortUnSort: "Sortierung aufheben",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Summe",
  first: "Erste",
  last: "Letzte",
  min: "Min",
  max: "Max",
  none: "Keine",
  count: "Anzahl",
  avg: "Durchschnitt",
  filteredRows: "Gefiltert",
  selectedRows: "Ausgew\xE4hlt",
  totalRows: "Gesamtanzahl Zeilen",
  totalAndFilteredRows: "Zeilen",
  more: "Mehr",
  to: "bis",
  of: "von",
  page: "Seite",
  pageLastRowUnknown: "?",
  nextPage: "N\xE4chste Seite",
  lastPage: "Letzte Seite",
  firstPage: "Erste Seite",
  previousPage: "Vorherige Seite",
  pageSizeSelectorLabel: "Seitengr\xF6\xDFe:",
  footerTotal: "Gesamt",
  // Pivoting
  pivotColumnGroupTotals: "Gesamt",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot-Diagramm & Pivot-Modus",
  pivotChart: "Pivot-Diagramm",
  chartRange: "Diagrammbereich",
  columnChart: "S\xE4ule",
  groupedColumn: "Gruppiert",
  stackedColumn: "Gestapelt",
  normalizedColumn: "100% Gestapelt",
  barChart: "Balken",
  groupedBar: "Gruppiert",
  stackedBar: "Gestapelt",
  normalizedBar: "100% Gestapelt",
  pieChart: "Kreisdiagramm",
  pie: "Kreis",
  donut: "Donut",
  line: "Linie",
  xyChart: "X Y (Streudiagramm)",
  scatter: "Streu",
  bubble: "Blase",
  areaChart: "Fl\xE4che",
  area: "Fl\xE4che",
  stackedArea: "Gestapelt",
  normalizedArea: "100% Gestapelt",
  histogramChart: "Histogramm",
  polarChart: "Polar",
  radarLine: "Radarlinie",
  radarArea: "Radarfl\xE4che",
  nightingale: "Nachtigall",
  radialColumn: "Radiale S\xE4ule",
  radialBar: "Radialer Balken",
  statisticalChart: "Statistisch",
  boxPlot: "Box-Plot",
  rangeBar: "Bereichsbalken",
  rangeArea: "Bereichsfl\xE4che",
  hierarchicalChart: "Hierarchisch",
  treemap: "Baumkarte",
  sunburst: "Sonnenstrahl",
  specializedChart: "Spezialisiert",
  waterfall: "Wasserfall",
  heatmap: "Heatmap",
  combinationChart: "Kombination",
  columnLineCombo: "S\xE4ule & Linie",
  AreaColumnCombo: "Fl\xE4che & S\xE4ule",
  // Charts
  pivotChartTitle: "Pivot-Diagramm",
  rangeChartTitle: "Bereichsdiagramm",
  settings: "Diagramm",
  data: "Einrichten",
  format: "Anpassen",
  categories: "Kategorien",
  defaultCategory: "(Keine)",
  series: "Reihen",
  switchCategorySeries: "Kategorie / Serie wechseln",
  categoryValues: "Kategorie Werte",
  seriesLabels: "Serienbeschriftungen",
  aggregate: "Aggregieren",
  xyValues: "X Y Werte",
  paired: "Gekoppelter Modus",
  axis: "Achse",
  xAxis: "Horizontale Achse",
  yAxis: "Vertikale Achse",
  polarAxis: "Polare Achse",
  radiusAxis: "Radius-Achse",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "Fadenkreuz",
  color: "Farbe",
  thickness: "Dicke",
  preferredLength: "Bevorzugte L\xE4nge",
  xType: "X-Typ",
  axisType: "Achsentyp",
  automatic: "Automatisch",
  category: "Kategorie",
  number: "Zahl",
  time: "Zeit",
  timeFormat: "Zeit Format",
  autoRotate: "Automatisch Rotieren",
  labelRotation: "Drehung",
  circle: "Kreis",
  polygon: "Polygon",
  square: "Quadrat",
  cross: "Kreuz",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Dreieck",
  heart: "Herz",
  orientation: "Ausrichtung",
  fixed: "Fest",
  parallel: "Parallel",
  perpendicular: "Senkrecht",
  radiusAxisPosition: "Position",
  ticks: "Striche",
  gridLines: "Gitternetzlinien",
  width: "Breite",
  height: "H\xF6he",
  length: "L\xE4nge",
  padding: "Abstand",
  spacing: "Abstand",
  chartStyle: "Diagrammstil",
  title: "Titel",
  chartTitles: "Titel",
  chartTitle: "Diagrammtitel",
  chartSubtitle: "Untertitel",
  horizontalAxisTitle: "Titel der horizontalen Achse",
  verticalAxisTitle: "Titel der vertikalen Achse",
  polarAxisTitle: "Titel der Polarakse",
  titlePlaceholder: "Diagrammtitel",
  background: "Hintergrund",
  font: "Schriftart",
  weight: "Gewicht",
  top: "Oben",
  right: "Rechts",
  bottom: "Unten",
  left: "Links",
  labels: "Beschriftungen",
  calloutLabels: "Beschriftungen",
  sectorLabels: "Sektorbeschriftungen",
  positionRatio: "Positionsverh\xE4ltnis",
  size: "Gr\xF6\xDFe",
  shape: "Form",
  minSize: "Mindestgr\xF6\xDFe",
  maxSize: "Maximalgr\xF6\xDFe",
  legend: "Legende",
  position: "Position",
  markerSize: "Markierungsgr\xF6\xDFe",
  markerStroke: "Markierungsstrich",
  markerPadding: "Markierungsabstand",
  itemSpacing: "Elementabstand",
  itemPaddingX: "Elementabstand X",
  itemPaddingY: "Elementabstand Y",
  layoutHorizontalSpacing: "Horizontaler Abstand",
  layoutVerticalSpacing: "Vertikaler Abstand",
  strokeWidth: "Strichbreite",
  offset: "Versatz",
  offsets: "Vers\xE4tze",
  tooltips: "Tooltips",
  callout: "Beschriftung",
  markers: "Markierungen",
  shadow: "Schatten",
  blur: "Unsch\xE4rfe",
  xOffset: "X-Versatz",
  yOffset: "Y-Versatz",
  lineWidth: "Linienbreite",
  lineDash: "Linienstrich",
  lineDashOffset: "Strichversatz",
  scrollingZoom: "Scrollen",
  scrollingStep: "Scrollschritt",
  selectingZoom: "Ausw\xE4hlen",
  durationMillis: "Dauer (ms)",
  crosshairLabel: "Beschriftung",
  crosshairSnap: "An Knoten schnappen",
  normal: "Normal",
  bold: "Fett",
  italic: "Kursiv",
  boldItalic: "Fett Kursiv",
  predefined: "Vordefiniert",
  fillOpacity: "F\xFCllopazit\xE4t",
  strokeColor: "Linienfarbe",
  strokeOpacity: "Linienopazit\xE4t",
  miniChart: "Mini-Diagramm",
  histogramBinCount: "Anzahl der Bins",
  connectorLine: "Verbindungslinie",
  seriesItems: "Serienartikel",
  seriesItemType: "Artikeltyp",
  seriesItemPositive: "Positiv",
  seriesItemNegative: "Negativ",
  seriesItemLabels: "Artikel Beschriftungen",
  columnGroup: "Spalte",
  barGroup: "Balken",
  pieGroup: "Kreis",
  lineGroup: "Linie",
  scatterGroup: "X Y (Streudiagramm)",
  areaGroup: "Bereich",
  polarGroup: "Polar",
  statisticalGroup: "Statistisch",
  hierarchicalGroup: "Hierarchisch",
  specializedGroup: "Spezialisiert",
  combinationGroup: "Kombination",
  groupedColumnTooltip: "Gruppiert",
  stackedColumnTooltip: "Gestapelt",
  normalizedColumnTooltip: "100% Gestapelt",
  groupedBarTooltip: "Gruppiert",
  stackedBarTooltip: "Gestapelt",
  normalizedBarTooltip: "100% Gestapelt",
  pieTooltip: "Kreis",
  donutTooltip: "Donut",
  lineTooltip: "Linie",
  groupedAreaTooltip: "Bereich",
  stackedAreaTooltip: "Gestapelt",
  normalizedAreaTooltip: "100% Gestapelt",
  scatterTooltip: "Streudiagramm",
  bubbleTooltip: "Blase",
  histogramTooltip: "Histogramm",
  radialColumnTooltip: "Radiale Spalte",
  radialBarTooltip: "Radialer Balken",
  radarLineTooltip: "Radarlinie",
  radarAreaTooltip: "Radarbereich",
  nightingaleTooltip: "Nachtigall",
  rangeBarTooltip: "Bereichsbalken",
  rangeAreaTooltip: "Bereichsbereich",
  boxPlotTooltip: "Boxplot",
  treemapTooltip: "Baumkarte",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Wasserfall",
  heatmapTooltip: "Heatmap",
  columnLineComboTooltip: "Spalte & Linie",
  areaColumnComboTooltip: "Bereich & Spalte",
  customComboTooltip: "Benutzerdefinierte Kombination",
  innerRadius: "Innerer Radius",
  startAngle: "Startwinkel",
  endAngle: "Endwinkel",
  reverseDirection: "Richtung umkehren",
  groupPadding: "Gruppenabstand",
  seriesPadding: "Serienabstand",
  tile: "Kachel",
  whisker: "Schnurrbart",
  cap: "Kappe",
  capLengthRatio: "L\xE4ngenverh\xE4ltnis",
  labelPlacement: "Platzierung",
  inside: "Innen",
  outside: "Au\xDFen",
  noDataToChart: "Keine Daten zum Chart vorhanden.",
  pivotChartRequiresPivotMode: "Pivot-Diagramm erfordert aktivierten Pivot-Modus.",
  chartSettingsToolbarTooltip: "Men\xFC",
  chartLinkToolbarTooltip: "Mit Gitter verbunden",
  chartUnlinkToolbarTooltip: "Vom Gitter getrennt",
  chartDownloadToolbarTooltip: "Diagramm herunterladen",
  chartMenuToolbarTooltip: "Men\xFC",
  chartEdit: "Diagramm bearbeiten",
  chartAdvancedSettings: "Erweiterte Einstellungen",
  chartLink: "Mit Gitter verkn\xFCpfen",
  chartUnlink: "Vom Gitter trennen",
  chartDownload: "Diagramm herunterladen",
  histogramFrequency: "H\xE4ufigkeit",
  seriesChartType: "Serien-Diagrammtyp",
  seriesType: "Serientyp",
  secondaryAxis: "Sekund\xE4re Achse",
  seriesAdd: "Eine Serie hinzuf\xFCgen",
  categoryAdd: "Eine Kategorie hinzuf\xFCgen",
  bar: "Balken",
  column: "Spalte",
  histogram: "Histogramm",
  advancedSettings: "Erweiterte Einstellungen",
  direction: "Richtung",
  horizontal: "Horizontal",
  vertical: "Vertikal",
  seriesGroupType: "Gruppentyp",
  groupedSeriesGroupType: "Gruppiert",
  stackedSeriesGroupType: "Gestapelt",
  normalizedSeriesGroupType: "100% Gestapelt",
  legendEnabled: "Aktiviert",
  invalidColor: "Farbwert ist ung\xFCltig",
  groupedColumnFull: "Gruppierte Spalte",
  stackedColumnFull: "Gestapelte Spalte",
  normalizedColumnFull: "100% Gestapelte Spalte",
  groupedBarFull: "Gruppierter Balken",
  stackedBarFull: "Gestapelter Balken",
  normalizedBarFull: "100% Gestapelter Balken",
  stackedAreaFull: "Gestapelter Bereich",
  normalizedAreaFull: "100% Gestapelter Bereich",
  customCombo: "Benutzerdefinierte Kombination",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Ebene ${variable}. Dr\xFCcken Sie ENTER, um zu bearbeiten.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Ebene ${variable}. ${variable} Dr\xFCcken Sie ENTER, um zu bearbeiten.",
  ariaAdvancedFilterBuilderList: "Erweiterte Filter-Builder-Liste",
  ariaAdvancedFilterBuilderFilterItem: "Filterbedingung",
  ariaAdvancedFilterBuilderGroupItem: "Filtergruppe",
  ariaAdvancedFilterBuilderColumn: "Spalte",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValueP: "Wert",
  ariaAdvancedFilterBuilderJoinOperator: "Verkn\xFCpfungsoperator",
  ariaAdvancedFilterInput: "Erweiterter Filtereingang",
  ariaChecked: "gepr\xFCft",
  ariaColumn: "Spalte",
  ariaColumnGroup: "Spaltengruppe",
  ariaColumnFiltered: "Spalte gefiltert",
  ariaColumnSelectAll: "Alle Spalten ausw\xE4hlen umschalten",
  ariaDateFilterInput: "Datum-Filtereingang",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "Filterspalteneingang",
  ariaFilterFromValue: "Filter vom Wert",
  ariaFilterInput: "Filtereingang",
  ariaFilterList: "Filterliste",
  ariaFilterToValue: "Filter zum Wert",
  ariaFilterValue: "Filterwert",
  ariaFilterMenuOpen: "Filtermen\xFC \xF6ffnen",
  ariaFilteringOperator: "Filteroperator",
  ariaHidden: "versteckt",
  ariaIndeterminate: "unbestimmt",
  ariaInputEditor: "Eingabeeditor",
  ariaMenuColumn: "Dr\xFCcken Sie ALT NACH UNTEN, um das Spaltenmen\xFC zu \xF6ffnen",
  ariaFilterColumn: "Dr\xFCcken Sie STRG EINGABETASTE, um den Filter zu \xF6ffnen",
  ariaRowDeselect: "Dr\xFCcken Sie LEERTASTE, um diese Zeile abzuwaehlen",
  ariaRowSelectAll: "Dr\xFCcken Sie LEERTASTE, um die Auswahl aller Zeilen umzuschalten",
  ariaRowToggleSelection: "Dr\xFCcken Sie LEERTASTE, um die Zeilenauswahl umzuschalten",
  ariaRowSelect: "Dr\xFCcken Sie LEERTASTE, um diese Zeile auszuw\xE4hlen",
  ariaRowSelectionDisabled: "Zeilenauswahl ist f\xFCr diese Zeile deaktiviert",
  ariaSearch: "Suche",
  ariaSortableColumn: "Dr\xFCcken Sie EINGABETASTE, um zu sortieren",
  ariaToggleVisibility: "Dr\xFCcken Sie LEERTASTE, um die Sichtbarkeit umzuschalten",
  ariaToggleCellValue: "Dr\xFCcken Sie LEERTASTE, um den Zellenwert umzuschalten",
  ariaUnchecked: "ungepr\xFCft",
  ariaVisible: "sichtbar",
  ariaSearchFilterValues: "Filterwerte suchen",
  ariaPageSizeSelectorLabel: "Seitengr\xF6\xDFe",
  ariaChartMenuClose: "Diagramm-Bearbeitungsmen\xFC schlie\xDFen",
  ariaChartSelected: "Ausgew\xE4hlt",
  ariaSkeletonCellLoadingFailed: "Das Laden der Zeile ist fehlgeschlagen",
  ariaSkeletonCellLoading: "Zeilendaten werden geladen",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Zeilengruppen",
  ariaValuesDropZonePanelLabel: "Werte",
  ariaPivotDropZonePanelLabel: "Spaltenbeschriftungen",
  ariaDropZoneColumnComponentDescription: "Dr\xFCcken Sie DELETE, um zu entfernen",
  ariaDropZoneColumnValueItemDescription: "Dr\xFCcken Sie ENTER, um den Aggregationstyp zu \xE4ndern",
  ariaDropZoneColumnGroupItemDescription: "Dr\xFCcken Sie ENTER, um zu sortieren",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " von ",
  ariaDropZoneColumnComponentSortAscending: "aufsteigend",
  ariaDropZoneColumnComponentSortDescending: "absteigend",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Spaltenmen\xFC",
  ariaLabelColumnFilter: "Spaltenfilter",
  ariaLabelCellEditor: "Zelleneditor",
  ariaLabelSelectField: "Feld ausw\xE4hlen",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rich-Select-Feld",
  ariaLabelRichSelectToggleSelection: "Dr\xFCcken Sie die LEERTASTE, um die Auswahl umzuschalten",
  ariaLabelRichSelectDeselectAllItems: "Dr\xFCcken Sie ENTFERNEN, um alle Elemente abzuw\xE4hlen",
  ariaLabelRichSelectDeleteSelection: "Dr\xFCcken Sie ENTFERNEN, um das Element abzuw\xE4hlen",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Kontextmen\xFC",
  ariaLabelSubMenu: "Untermen\xFC",
  ariaLabelAggregationFunction: "Aggregationsfunktion",
  ariaLabelAdvancedFilterAutocomplete: "Erweiterte Filter-Autovervollst\xE4ndigung",
  ariaLabelAdvancedFilterBuilderAddField: "Erweiterte Filtererstellung Feld hinzuf\xFCgen",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Erweiterte Filtererstellung Spaltenauswahlfeld",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Erweiterte Filtererstellung Optionsauswahlfeld",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Erweiterte Filtererstellung Verkn\xFCpfungsoperatore Auswahlfeld",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Spaltenliste",
  ariaFilterPanelList: "Filterliste",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Wahr",
  false: "Falsch",
  invalidDate: "Ung\xFCltiges Datum",
  invalidNumber: "Ung\xFCltige Nummer",
  january: "Januar",
  february: "Februar",
  march: "M\xE4rz",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Dezember",
  // Time formats
  timeFormatSlashesDDMMYYYY: "TT/MM/JJJJ",
  timeFormatSlashesMMDDYYYY: "MM/TT/JJJJ",
  timeFormatSlashesDDMMYY: "TT/MM/JJ",
  timeFormatSlashesMMDDYY: "MM/TT/JJ",
  timeFormatDotsDDMYY: "TT.M.JJ",
  timeFormatDotsMDDYY: "M.TT.JJ",
  timeFormatDashesYYYYMMDD: "JJJJ-MM-TT",
  timeFormatSpacesDDMMMMYYYY: "TT MMMM JJJJ",
  timeFormatHHMMSS: "SS:MM:SS",
  timeFormatHHMMSSAmPm: "SS:MM:SS VM/NM"
};

// community-modules/locale/src/el-GR.ts
var AG_GRID_LOCALE_GR = {
  // Set Filter
  selectAll: "(\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03CC\u03BB\u03C9\u03BD)",
  selectAllSearchResults: "(\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03CC\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u03B1\u03C0\u03BF\u03C4\u03B5\u03BB\u03B5\u03C3\u03BC\u03AC\u03C4\u03C9\u03BD \u03B1\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7\u03C2)",
  addCurrentSelectionToFilter: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03C4\u03C1\u03AD\u03C7\u03BF\u03C5\u03C3\u03B1\u03C2 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2 \u03C3\u03C4\u03BF \u03C6\u03AF\u03BB\u03C4\u03C1\u03BF",
  searchOoo: "\u0391\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7...",
  blanks: "(\u039A\u03B5\u03BD\u03AC)",
  noMatches: "\u0394\u03B5\u03BD \u03B2\u03C1\u03AD\u03B8\u03B7\u03BA\u03B1\u03BD \u03B1\u03C0\u03BF\u03C4\u03B5\u03BB\u03AD\u03C3\u03BC\u03B1\u03C4\u03B1",
  // Number Filter & Text Filter
  filterOoo: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF...",
  equals: "\u038A\u03C3\u03BF \u03BC\u03B5",
  notEqual: "\u0394\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03AF\u03C3\u03BF \u03BC\u03B5",
  blank: "\u039A\u03B5\u03BD\u03CC",
  notBlank: "\u0394\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03BA\u03B5\u03BD\u03CC",
  empty: "\u0395\u03C0\u03B9\u03BB\u03AD\u03BE\u03C4\u03B5 \u03AD\u03BD\u03B1",
  // Number Filter
  lessThan: "\u039B\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC",
  greaterThan: "\u039C\u03B5\u03B3\u03B1\u03BB\u03CD\u03C4\u03B5\u03C1\u03BF \u03B1\u03C0\u03CC",
  lessThanOrEqual: "\u039B\u03B9\u03B3\u03CC\u03C4\u03B5\u03C1\u03BF \u03AE \u03AF\u03C3\u03BF \u03BC\u03B5",
  greaterThanOrEqual: "\u039C\u03B5\u03B3\u03B1\u03BB\u03CD\u03C4\u03B5\u03C1\u03BF \u03AE \u03AF\u03C3\u03BF \u03BC\u03B5",
  inRange: "\u0391\u03BD\u03AC\u03BC\u03B5\u03C3\u03B1",
  inRangeStart: "\u0391\u03C0\u03CC",
  inRangeEnd: "\u0388\u03C9\u03C2",
  // Text Filter
  contains: "\u03A0\u03B5\u03C1\u03B9\u03AD\u03C7\u03B5\u03B9",
  notContains: "\u0394\u03B5\u03BD \u03C0\u03B5\u03C1\u03B9\u03AD\u03C7\u03B5\u03B9",
  startsWith: "\u039E\u03B5\u03BA\u03B9\u03BD\u03AC \u03BC\u03B5",
  endsWith: "\u03A4\u03B5\u03BB\u03B5\u03B9\u03CE\u03BD\u03B5\u03B9 \u03BC\u03B5",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u03A0\u03C1\u03B9\u03BD",
  after: "\u039C\u03B5\u03C4\u03AC",
  // Filter Conditions
  andCondition: "\u039A\u0391\u0399",
  orCondition: "\u0389",
  // Filter Buttons
  applyFilter: "\u0395\u03C6\u03B1\u03C1\u03BC\u03BF\u03B3\u03AE",
  resetFilter: "\u0395\u03C0\u03B1\u03BD\u03B1\u03C6\u03BF\u03C1\u03AC",
  clearFilter: "\u0395\u03BA\u03BA\u03B1\u03B8\u03AC\u03C1\u03B9\u03C3\u03B7",
  cancelFilter: "\u0391\u03BA\u03CD\u03C1\u03C9\u03C3\u03B7",
  // Filter Titles
  textFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u039A\u03B5\u03B9\u03BC\u03AD\u03BD\u03BF\u03C5",
  numberFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u0391\u03C1\u03B9\u03B8\u03BC\u03BF\u03CD",
  dateFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u0397\u03BC\u03B5\u03C1\u03BF\u03BC\u03B7\u03BD\u03AF\u03B1\u03C2",
  setFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u03A3\u03C5\u03BD\u03CC\u03BB\u03BF\u03C5",
  // Group Column Filter
  groupFilterSelect: "\u0395\u03C0\u03B9\u03BB\u03AD\u03BE\u03C4\u03B5 \u03C0\u03B5\u03B4\u03AF\u03BF:",
  // Advanced Filter
  advancedFilterContains: "\u03C0\u03B5\u03C1\u03B9\u03AD\u03C7\u03B5\u03B9",
  advancedFilterNotContains: "\u03B4\u03B5\u03BD \u03C0\u03B5\u03C1\u03B9\u03AD\u03C7\u03B5\u03B9",
  advancedFilterTextEquals: "\u03B5\u03AF\u03BD\u03B1\u03B9 \u03AF\u03C3\u03BF \u03BC\u03B5",
  advancedFilterTextNotEqual: "\u03B4\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03AF\u03C3\u03BF \u03BC\u03B5",
  advancedFilterStartsWith: "\u03B1\u03C1\u03C7\u03AF\u03B6\u03B5\u03B9 \u03BC\u03B5",
  advancedFilterEndsWith: "\u03C4\u03B5\u03BB\u03B5\u03B9\u03CE\u03BD\u03B5\u03B9 \u03BC\u03B5",
  advancedFilterBlank: "\u03B5\u03AF\u03BD\u03B1\u03B9 \u03BA\u03B5\u03BD\u03CC",
  advancedFilterNotBlank: "\u03B4\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03BA\u03B5\u03BD\u03CC",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u03B5\u03AF\u03BD\u03B1\u03B9 \u03B1\u03BB\u03B7\u03B8\u03AD\u03C2",
  advancedFilterFalse: "\u03B5\u03AF\u03BD\u03B1\u03B9 \u03C8\u03B5\u03C5\u03B4\u03AD\u03C2",
  advancedFilterAnd: "\u039A\u0391\u0399",
  advancedFilterOr: "\u0389",
  advancedFilterApply: "\u0395\u03C6\u03B1\u03C1\u03BC\u03BF\u03B3\u03AE",
  advancedFilterBuilder: "\u039A\u03B1\u03C4\u03B1\u03C3\u03BA\u03B5\u03C5\u03B1\u03C3\u03C4\u03AE\u03C2",
  advancedFilterValidationMissingColumn: "\u0397 \u03C3\u03C4\u03AE\u03BB\u03B7 \u03BB\u03B5\u03AF\u03C0\u03B5\u03B9",
  advancedFilterValidationMissingOption: "\u0397 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03BB\u03B5\u03AF\u03C0\u03B5\u03B9",
  advancedFilterValidationMissingValue: "\u0397 \u03C4\u03B9\u03BC\u03AE \u03BB\u03B5\u03AF\u03C0\u03B5\u03B9",
  advancedFilterValidationInvalidColumn: "\u0397 \u03C3\u03C4\u03AE\u03BB\u03B7 \u03B4\u03B5\u03BD \u03B2\u03C1\u03AD\u03B8\u03B7\u03BA\u03B5",
  advancedFilterValidationInvalidOption: "\u0397 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03B4\u03B5\u03BD \u03B2\u03C1\u03AD\u03B8\u03B7\u03BA\u03B5",
  advancedFilterValidationMissingQuote: "\u0397 \u03C4\u03B9\u03BC\u03AE \u03BB\u03B5\u03AF\u03C0\u03B5\u03B9 \u03C4\u03B5\u03BB\u03B9\u03BA\u03AE \u03B5\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03B9\u03BA\u03AE",
  advancedFilterValidationNotANumber: "\u0397 \u03C4\u03B9\u03BC\u03AE \u03B4\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03B1\u03C1\u03B9\u03B8\u03BC\u03CC\u03C2",
  advancedFilterValidationInvalidDate: "\u0397 \u03C4\u03B9\u03BC\u03AE \u03B4\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03AD\u03B3\u03BA\u03C5\u03C1\u03B7 \u03B7\u03BC\u03B5\u03C1\u03BF\u03BC\u03B7\u03BD\u03AF\u03B1",
  advancedFilterValidationMissingCondition: "\u0397 \u03C3\u03C5\u03BD\u03B8\u03AE\u03BA\u03B7 \u03BB\u03B5\u03AF\u03C0\u03B5\u03B9",
  advancedFilterValidationJoinOperatorMismatch: "\u039F\u03B9 \u03C4\u03B5\u03BB\u03B5\u03C3\u03C4\u03AD\u03C2 \u03AD\u03BD\u03C9\u03C3\u03B7\u03C2 \u03B5\u03BD\u03C4\u03CC\u03C2 \u03C3\u03C5\u03BD\u03B8\u03AE\u03BA\u03B7\u03C2 \u03C0\u03C1\u03AD\u03C0\u03B5\u03B9 \u03BD\u03B1 \u03B5\u03AF\u03BD\u03B1\u03B9 \u03BF\u03B9 \u03AF\u03B4\u03B9\u03BF\u03B9",
  advancedFilterValidationInvalidJoinOperator: "\u039F \u03C4\u03B5\u03BB\u03B5\u03C3\u03C4\u03AE\u03C2 \u03AD\u03BD\u03C9\u03C3\u03B7\u03C2 \u03B4\u03B5\u03BD \u03B2\u03C1\u03AD\u03B8\u03B7\u03BA\u03B5",
  advancedFilterValidationMissingEndBracket: "\u039B\u03B5\u03AF\u03C0\u03B5\u03B9 \u03C4\u03BF \u03C4\u03B5\u03BB\u03B9\u03BA\u03AC \u03C3\u03C4\u03B7\u03C1\u03AF\u03B3\u03BC\u03B1",
  advancedFilterValidationExtraEndBracket: "\u03A0\u03B5\u03C1\u03B9\u03C3\u03C3\u03CC\u03C4\u03B5\u03C1\u03B1 \u03B1\u03C0\u03CC \u03AD\u03BD\u03B1 \u03C4\u03B5\u03BB\u03B9\u03BA\u03AC \u03C3\u03C4\u03B7\u03C1\u03AF\u03B3\u03BC\u03B1\u03C4\u03B1",
  advancedFilterValidationMessage: "\u0397 \u03AD\u03BA\u03C6\u03C1\u03B1\u03C3\u03B7 \u03AD\u03C7\u03B5\u03B9 \u03C3\u03C6\u03AC\u03BB\u03BC\u03B1. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0397 \u03AD\u03BA\u03C6\u03C1\u03B1\u03C3\u03B7 \u03AD\u03C7\u03B5\u03B9 \u03C3\u03C6\u03AC\u03BB\u03BC\u03B1. ${variable} \u03C3\u03C4\u03BF \u03C4\u03AD\u03BB\u03BF\u03C2 \u03C4\u03B7\u03C2 \u03AD\u03BA\u03C6\u03C1\u03B1\u03C3\u03B7\u03C2.",
  advancedFilterBuilderTitle: "\u03A0\u03C1\u03BF\u03B7\u03B3\u03BC\u03AD\u03BD\u03BF \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF",
  advancedFilterBuilderApply: "\u0395\u03C6\u03B1\u03C1\u03BC\u03BF\u03B3\u03AE",
  advancedFilterBuilderCancel: "\u0386\u03BA\u03C5\u03C1\u03BF",
  advancedFilterBuilderAddButtonTooltip: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5 \u03AE \u039F\u03BC\u03AC\u03B4\u03B1\u03C2",
  advancedFilterBuilderRemoveButtonTooltip: "\u0391\u03C6\u03B1\u03AF\u03C1\u03B5\u03C3\u03B7",
  advancedFilterBuilderMoveUpButtonTooltip: "\u039C\u03B5\u03C4\u03B1\u03BA\u03AF\u03BD\u03B7\u03C3\u03B7 \u03A0\u03AC\u03BD\u03C9",
  advancedFilterBuilderMoveDownButtonTooltip: "\u039C\u03B5\u03C4\u03B1\u03BA\u03AF\u03BD\u03B7\u03C3\u03B7 \u039A\u03AC\u03C4\u03C9",
  advancedFilterBuilderAddJoin: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u039F\u03BC\u03AC\u03B4\u03B1\u03C2",
  advancedFilterBuilderAddCondition: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  advancedFilterBuilderSelectColumn: "\u0395\u03C0\u03B9\u03BB\u03AD\u03BE\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03C3\u03C4\u03AE\u03BB\u03B7",
  advancedFilterBuilderSelectOption: "\u0395\u03C0\u03B9\u03BB\u03AD\u03BE\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE",
  advancedFilterBuilderEnterValue: "\u0395\u03B9\u03C3\u03AC\u03B3\u03B5\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03C4\u03B9\u03BC\u03AE...",
  advancedFilterBuilderValidationAlreadyApplied: "\u03A4\u03BF \u03C4\u03C1\u03AD\u03C7\u03BF\u03BD \u03C6\u03AF\u03BB\u03C4\u03C1\u03BF \u03AD\u03C7\u03B5\u03B9 \u03AE\u03B4\u03B7 \u03B5\u03C6\u03B1\u03C1\u03BC\u03BF\u03C3\u03C4\u03B5\u03AF.",
  advancedFilterBuilderValidationIncomplete: "\u0394\u03B5\u03BD \u03AD\u03C7\u03BF\u03C5\u03BD \u03BF\u03BB\u03BF\u03BA\u03BB\u03B7\u03C1\u03C9\u03B8\u03B5\u03AF \u03CC\u03BB\u03B5\u03C2 \u03BF\u03B9 \u03C3\u03C5\u03BD\u03B8\u03AE\u03BA\u03B5\u03C2.",
  advancedFilterBuilderValidationSelectColumn: "\u03A0\u03C1\u03AD\u03C0\u03B5\u03B9 \u03BD\u03B1 \u03B5\u03C0\u03B9\u03BB\u03AD\u03BE\u03B5\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03C3\u03C4\u03AE\u03BB\u03B7.",
  advancedFilterBuilderValidationSelectOption: "\u03A0\u03C1\u03AD\u03C0\u03B5\u03B9 \u03BD\u03B1 \u03B5\u03C0\u03B9\u03BB\u03AD\u03BE\u03B5\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE.",
  advancedFilterBuilderValidationEnterValue: "\u03A0\u03C1\u03AD\u03C0\u03B5\u03B9 \u03BD\u03B1 \u03B5\u03B9\u03C3\u03AC\u03B3\u03B5\u03C4\u03B5 \u03BC\u03B9\u03B1 \u03C4\u03B9\u03BC\u03AE.",
  // Side Bar
  columns: "\u03A3\u03C4\u03AE\u03BB\u03B5\u03C2",
  filters: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03B1",
  // columns tool panel
  pivotMode: "\u039B\u03B5\u03B9\u03C4\u03BF\u03C5\u03C1\u03B3\u03AF\u03B1 \u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE\u03C2",
  groups: "\u039F\u03BC\u03AC\u03B4\u03B5\u03C2 \u0393\u03C1\u03B1\u03BC\u03BC\u03CE\u03BD",
  rowGroupColumnsEmptyMessage: "\u03A3\u03CD\u03C1\u03B5\u03C4\u03B5 \u03B5\u03B4\u03CE \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03BF\u03C1\u03AF\u03C3\u03B5\u03C4\u03B5 \u03BF\u03BC\u03AC\u03B4\u03B5\u03C2 \u03B3\u03C1\u03B1\u03BC\u03BC\u03CE\u03BD",
  values: "\u03A4\u03B9\u03BC\u03AD\u03C2",
  valueColumnsEmptyMessage: "\u03A3\u03CD\u03C1\u03B5\u03C4\u03B5 \u03B5\u03B4\u03CE \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03C3\u03C5\u03B3\u03BA\u03B5\u03BD\u03C4\u03C1\u03CE\u03C3\u03B5\u03C4\u03B5",
  pivots: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  pivotColumnsEmptyMessage: "\u03A3\u03CD\u03C1\u03B5\u03C4\u03B5 \u03B5\u03B4\u03CE \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03BF\u03C1\u03AF\u03C3\u03B5\u03C4\u03B5 \u03B5\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03C3\u03C4\u03B7\u03BB\u03CE\u03BD",
  // Header of the Default Group Column
  group: "\u039F\u03BC\u03AC\u03B4\u03B1",
  // Row Drag
  rowDragRow: "\u03B3\u03C1\u03B1\u03BC\u03BC\u03AE",
  rowDragRows: "\u03B3\u03C1\u03B1\u03BC\u03BC\u03AD\u03C2",
  // Other
  loadingOoo: "\u03A6\u03CC\u03C1\u03C4\u03C9\u03C3\u03B7...",
  loadingError: "\u03A3\u03A6\u0391\u039B\u039C\u0391",
  noRowsToShow: "\u0394\u03B5\u03BD \u03C5\u03C0\u03AC\u03C1\u03C7\u03BF\u03C5\u03BD \u03B3\u03C1\u03B1\u03BC\u03BC\u03AD\u03C2 \u03B3\u03B9\u03B1 \u03B5\u03BC\u03C6\u03AC\u03BD\u03B9\u03C3\u03B7",
  enabled: "\u0395\u03BD\u03B5\u03C1\u03B3\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF",
  // Menu
  pinColumn: "\u039A\u03B1\u03C1\u03C6\u03AF\u03C4\u03C3\u03C9\u03BC\u03B1 \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  pinLeft: "\u039A\u03B1\u03C1\u03C6\u03AF\u03C4\u03C3\u03C9\u03BC\u03B1 \u0391\u03C1\u03B9\u03C3\u03C4\u03B5\u03C1\u03AC",
  pinRight: "\u039A\u03B1\u03C1\u03C6\u03AF\u03C4\u03C3\u03C9\u03BC\u03B1 \u0394\u03B5\u03BE\u03B9\u03AC",
  noPin: "\u03A7\u03C9\u03C1\u03AF\u03C2 \u039A\u03B1\u03C1\u03C6\u03AF\u03C4\u03C3\u03C9\u03BC\u03B1",
  valueAggregation: "\u03A3\u03C5\u03B3\u03BA\u03AD\u03BD\u03C4\u03C1\u03C9\u03C3\u03B7 \u03A4\u03B9\u03BC\u03CE\u03BD",
  noAggregation: "\u039A\u03B1\u03BC\u03AF\u03B1",
  autosizeThisColumn: "\u0391\u03C5\u03C4\u03CC\u03BC\u03B1\u03C4\u03BF \u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2 \u0391\u03C5\u03C4\u03AE\u03C2 \u03C4\u03B7\u03C2 \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  autosizeAllColumns: "\u0391\u03C5\u03C4\u03CC\u03BC\u03B1\u03C4\u03BF \u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2 \u038C\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  groupBy: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03AF\u03B7\u03C3\u03B7 \u03BA\u03B1\u03C4\u03AC",
  ungroupBy: "\u0394\u03B9\u03B1\u03BA\u03BF\u03C0\u03AE \u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03AF\u03B7\u03C3\u03B7\u03C2 \u03BA\u03B1\u03C4\u03AC",
  ungroupAll: "\u0394\u03B9\u03B1\u03BA\u03BF\u03C0\u03AE \u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03AF\u03B7\u03C3\u03B7\u03C2 \u038C\u03BB\u03C9\u03BD",
  addToValues: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 ${variable} \u03C3\u03C4\u03B9\u03C2 \u03C4\u03B9\u03BC\u03AD\u03C2",
  removeFromValues: "\u0391\u03C6\u03B1\u03AF\u03C1\u03B5\u03C3\u03B7 ${variable} \u03B1\u03C0\u03CC \u03C4\u03B9\u03C2 \u03C4\u03B9\u03BC\u03AD\u03C2",
  addToLabels: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 ${variable} \u03C3\u03C4\u03B9\u03C2 \u03B5\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2",
  removeFromLabels: "\u0391\u03C6\u03B1\u03AF\u03C1\u03B5\u03C3\u03B7 ${variable} \u03B1\u03C0\u03CC \u03C4\u03B9\u03C2 \u03B5\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2",
  resetColumns: "\u0395\u03C0\u03B1\u03BD\u03B1\u03C6\u03BF\u03C1\u03AC \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  expandAll: "\u0395\u03C0\u03AD\u03BA\u03C4\u03B1\u03C3\u03B7 \u038C\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u039F\u03BC\u03AC\u03B4\u03C9\u03BD \u0393\u03C1\u03B1\u03BC\u03BC\u03CE\u03BD",
  collapseAll: "\u039A\u03BB\u03B5\u03AF\u03C3\u03B9\u03BC\u03BF \u038C\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u039F\u03BC\u03AC\u03B4\u03C9\u03BD \u0393\u03C1\u03B1\u03BC\u03BC\u03CE\u03BD",
  copy: "\u0391\u03BD\u03C4\u03B9\u03B3\u03C1\u03B1\u03C6\u03AE",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u0391\u03BD\u03C4\u03B9\u03B3\u03C1\u03B1\u03C6\u03AE \u03BC\u03B5 \u039A\u03B5\u03C6\u03B1\u03BB\u03AF\u03B4\u03B5\u03C2",
  copyWithGroupHeaders: "\u0391\u03BD\u03C4\u03B9\u03B3\u03C1\u03B1\u03C6\u03AE \u03BC\u03B5 \u039F\u03BC\u03B1\u03B4\u03B9\u03BA\u03AD\u03C2 \u039A\u03B5\u03C6\u03B1\u03BB\u03AF\u03B4\u03B5\u03C2",
  cut: "\u0391\u03C0\u03BF\u03BA\u03BF\u03C0\u03AE",
  paste: "\u0395\u03C0\u03B9\u03BA\u03CC\u03BB\u03BB\u03B7\u03C3\u03B7",
  ctrlV: "Ctrl+V",
  export: "\u0395\u03BE\u03B1\u03B3\u03C9\u03B3\u03AE",
  csvExport: "\u0395\u03BE\u03B1\u03B3\u03C9\u03B3\u03AE \u03C3\u03B5 CSV",
  excelExport: "\u0395\u03BE\u03B1\u03B3\u03C9\u03B3\u03AE \u03C3\u03B5 Excel",
  columnFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  columnChooser: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  chooseColumns: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  sortAscending: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u0391\u03CD\u03BE\u03BF\u03C5\u03C3\u03B1\u03C2 \u03A4\u03B1\u03BE\u03B9\u03BD\u03CC\u03BC\u03B7\u03C3\u03B7\u03C2",
  sortDescending: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A6\u03B8\u03AF\u03BD\u03BF\u03C5\u03C3\u03B1\u03C2 \u03A4\u03B1\u03BE\u03B9\u03BD\u03CC\u03BC\u03B7\u03C3\u03B7\u03C2",
  sortUnSort: "\u039A\u03B1\u03B8\u03B1\u03C1\u03B9\u03C3\u03BC\u03CC\u03C2 \u03A4\u03B1\u03BE\u03B9\u03BD\u03CC\u03BC\u03B7\u03C3\u03B7\u03C2",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u0386\u03B8\u03C1\u03BF\u03B9\u03C3\u03BC\u03B1",
  first: "\u03A0\u03C1\u03CE\u03C4\u03BF",
  last: "\u03A4\u03B5\u03BB\u03B5\u03C5\u03C4\u03B1\u03AF\u03BF",
  min: "\u0395\u03BB\u03AC\u03C7.",
  max: "\u039C\u03AD\u03B3.",
  none: "\u039A\u03B1\u03BD\u03AD\u03BD\u03B1",
  count: "\u0391\u03C1\u03B9\u03B8\u03BC\u03CC\u03C2",
  avg: "\u039C\u03AD\u03C3\u03BF\u03C2 \u038C\u03C1\u03BF\u03C2",
  filteredRows: "\u03A6\u03B9\u03BB\u03C4\u03C1\u03AC\u03C1\u03B9\u03C3\u03BC\u03B1",
  selectedRows: "\u0395\u03C0\u03B9\u03BB\u03B5\u03B3\u03BC\u03AD\u03BD\u03B5\u03C2",
  totalRows: "\u03A3\u03C5\u03BD\u03BF\u03BB\u03B9\u03BA\u03AD\u03C2 \u0393\u03C1\u03B1\u03BC\u03BC\u03AD\u03C2",
  totalAndFilteredRows: "\u0393\u03C1\u03B1\u03BC\u03BC\u03AD\u03C2",
  more: "\u03A0\u03B5\u03C1\u03B9\u03C3\u03C3\u03CC\u03C4\u03B5\u03C1\u03B1",
  to: "\u03AD\u03C9\u03C2",
  of: "\u03B1\u03C0\u03CC",
  page: "\u03A3\u03B5\u03BB\u03AF\u03B4\u03B1",
  pageLastRowUnknown: "?",
  nextPage: "\u0395\u03C0\u03CC\u03BC\u03B5\u03BD\u03B7 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1",
  lastPage: "\u03A4\u03B5\u03BB\u03B5\u03C5\u03C4\u03B1\u03AF\u03B1 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1",
  firstPage: "\u03A0\u03C1\u03CE\u03C4\u03B7 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1",
  previousPage: "\u03A0\u03C1\u03BF\u03B7\u03B3\u03BF\u03CD\u03BC\u03B5\u03BD\u03B7 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1",
  pageSizeSelectorLabel: "\u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1\u03C2:",
  footerTotal: "\u03A3\u03CD\u03BD\u03BF\u03BB\u03BF",
  // Pivoting
  pivotColumnGroupTotals: "\u03A3\u03CD\u03BD\u03BF\u03BB\u03BF",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1 \u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE\u03C2 & \u039B\u03B5\u03B9\u03C4\u03BF\u03C5\u03C1\u03B3\u03AF\u03B1 \u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE\u03C2",
  pivotChart: "\u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1 \u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE\u03C2",
  chartRange: "\u0395\u03CD\u03C1\u03BF\u03C2 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  columnChart: "\u03A3\u03C4\u03AE\u03BB\u03B7",
  groupedColumn: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03B7",
  stackedColumn: "\u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  normalizedColumn: "100% \u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  barChart: "\u03A1\u03AC\u03B2\u03B4\u03BF\u03C2",
  groupedBar: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03B7",
  stackedBar: "\u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  normalizedBar: "100% \u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  pieChart: "\u03A0\u03AF\u03C4\u03B1",
  pie: "\u03A0\u03AF\u03C4\u03B1",
  donut: "\u039D\u03C4\u03CC\u03BD\u03B1\u03C4",
  line: "\u0393\u03C1\u03B1\u03BC\u03BC\u03AE",
  xyChart: "X Y (Scatter)",
  scatter: "\u0394\u03B9\u03B1\u03C3\u03C0\u03BF\u03C1\u03AC",
  bubble: "\u03A6\u03BF\u03CD\u03C3\u03BA\u03B1",
  areaChart: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  area: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  stackedArea: "\u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  normalizedArea: "100% \u03A3\u03C9\u03C1\u03B5\u03C5\u03C4\u03B9\u03BA\u03AE",
  histogramChart: "\u0399\u03C3\u03C4\u03CC\u03B3\u03C1\u03B1\u03BC\u03BC\u03B1",
  polarChart: "\u03A0\u03BF\u03BB\u03B9\u03BA\u03CC",
  radarLine: "Radar \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  radarArea: "Radar \u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE\u03C2",
  nightingale: "Nightingale",
  radialColumn: "\u0391\u03BA\u03C4\u03B9\u03BD\u03C9\u03C4\u03AE \u03A3\u03C4\u03AE\u03BB\u03B7",
  radialBar: "\u0391\u03BA\u03C4\u03B9\u03BD\u03C9\u03C4\u03AE \u03A1\u03AC\u03B2\u03B4\u03BF\u03C2",
  statisticalChart: "\u03A3\u03C4\u03B1\u03C4\u03B9\u03C3\u03C4\u03B9\u03BA\u03CC",
  boxPlot: "\u0394\u03B9\u03AC\u03B3\u03C1\u03B1\u03BC\u03BC\u03B1 \u039A\u03BF\u03C5\u03C4\u03B9\u03BF\u03CD",
  rangeBar: "\u03A1\u03AC\u03B2\u03B4\u03BF\u03C2 \u0395\u03CD\u03C1\u03BF\u03C5\u03C2",
  rangeArea: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE \u0395\u03CD\u03C1\u03BF\u03C5\u03C2",
  hierarchicalChart: "\u0399\u03B5\u03C1\u03B1\u03C1\u03C7\u03B9\u03BA\u03CC",
  treemap: "\u03A7\u03AC\u03C1\u03C4\u03B7\u03C2 \u0394\u03AD\u03BD\u03C4\u03C1\u03BF\u03C5",
  sunburst: "\u0389\u03BB\u03B9\u03BF\u03C2",
  specializedChart: "\u0395\u03BE\u03B5\u03B9\u03B4\u03B9\u03BA\u03B5\u03C5\u03BC\u03AD\u03BD\u03BF",
  waterfall: "\u039A\u03B1\u03C4\u03B1\u03C1\u03C1\u03AC\u03BA\u03C4\u03B7\u03C2",
  heatmap: "\u0398\u03B5\u03C1\u03BC\u03B9\u03BA\u03CC\u03C2 \u03A7\u03AC\u03C1\u03C4\u03B7\u03C2",
  combinationChart: "\u03A3\u03C5\u03BD\u03B4\u03C5\u03B1\u03C3\u03BC\u03AD\u03BD\u03BF",
  columnLineCombo: "\u03A3\u03C4\u03AE\u03BB\u03B7 \u03BA\u03B1\u03B9 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE",
  AreaColumnCombo: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE & \u03A3\u03C4\u03AE\u03BB\u03B7",
  // Charts
  pivotChartTitle: "\u03A3\u03C5\u03B3\u03BA\u03B5\u03BD\u03C4\u03C1\u03C9\u03C4\u03B9\u03BA\u03CC \u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  rangeChartTitle: "\u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1 \u0395\u03CD\u03C1\u03BF\u03C5\u03C2",
  settings: "\u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  data: "\u03A1\u03CD\u03B8\u03BC\u03B9\u03C3\u03B7",
  format: "\u0395\u03BE\u03B1\u03C4\u03BF\u03BC\u03AF\u03BA\u03B5\u03C5\u03C3\u03B7",
  categories: "\u039A\u03B1\u03C4\u03B7\u03B3\u03BF\u03C1\u03AF\u03B5\u03C2",
  defaultCategory: "(\u039A\u03B1\u03BC\u03AF\u03B1)",
  series: "\u03A3\u03B5\u03B9\u03C1\u03AD\u03C2",
  switchCategorySeries: "\u0391\u03BB\u03BB\u03B1\u03B3\u03AE \u039A\u03B1\u03C4\u03B7\u03B3\u03BF\u03C1\u03AF\u03B1\u03C2 / \u03A3\u03B5\u03B9\u03C1\u03AC\u03C2",
  categoryValues: "\u03A4\u03B9\u03BC\u03AD\u03C2 \u039A\u03B1\u03C4\u03B7\u03B3\u03BF\u03C1\u03AF\u03B1\u03C2",
  seriesLabels: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03A3\u03B5\u03B9\u03C1\u03CE\u03BD",
  aggregate: "\u03A3\u03C5\u03B3\u03BA\u03B5\u03BD\u03C4\u03C1\u03C9\u03C4\u03B9\u03BA\u03AC",
  xyValues: "\u03A4\u03B9\u03BC\u03AD\u03C2 \u03A7 \u03A5",
  paired: "\u03A3\u03C5\u03BD\u03B4\u03C5\u03B1\u03C3\u03BC\u03AD\u03BD\u03B7 \u039B\u03B5\u03B9\u03C4\u03BF\u03C5\u03C1\u03B3\u03AF\u03B1",
  axis: "\u0386\u03BE\u03BF\u03BD\u03B1\u03C2",
  xAxis: "\u039F\u03C1\u03B9\u03B6\u03CC\u03BD\u03C4\u03B9\u03BF\u03C2 \u0386\u03BE\u03BF\u03BD\u03B1\u03C2",
  yAxis: "\u039A\u03B1\u03C4\u03B1\u03BA\u03CC\u03C1\u03C5\u03C6\u03BF\u03C2 \u0386\u03BE\u03BF\u03BD\u03B1\u03C2",
  polarAxis: "\u03A0\u03BF\u03BB\u03B9\u03BA\u03CC\u03C2 \u0386\u03BE\u03BF\u03BD\u03B1\u03C2",
  radiusAxis: "\u0386\u03BE\u03BF\u03BD\u03B1\u03C2 \u0391\u03BA\u03C4\u03AF\u03BD\u03B1\u03C2",
  navigator: "\u03A0\u03BB\u03BF\u03B7\u03B3\u03CC\u03C2",
  zoom: "\u039C\u03B5\u03B3\u03AD\u03B8\u03C5\u03BD\u03C3\u03B7",
  animation: "\u039A\u03B9\u03BD\u03BF\u03CD\u03BC\u03B5\u03BD\u03B1 \u0393\u03C1\u03B1\u03C6\u03B9\u03BA\u03AC",
  crosshair: "\u0394\u03B9\u03C7\u03CE\u03C2",
  color: "\u03A7\u03C1\u03CE\u03BC\u03B1",
  thickness: "\u03A0\u03AC\u03C7\u03BF\u03C2",
  preferredLength: "\u03A0\u03C1\u03BF\u03C4\u03B9\u03BC\u03CE\u03BC\u03B5\u03BD\u03BF \u039C\u03AE\u03BA\u03BF\u03C2",
  xType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u03A7",
  axisType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u0386\u03BE\u03BF\u03BD\u03B1",
  automatic: "\u0391\u03C5\u03C4\u03CC\u03BC\u03B1\u03C4\u03BF",
  category: "\u039A\u03B1\u03C4\u03B7\u03B3\u03BF\u03C1\u03AF\u03B1",
  number: "\u0391\u03C1\u03B9\u03B8\u03BC\u03CC\u03C2",
  time: "\u03A7\u03C1\u03CC\u03BD\u03BF\u03C2",
  timeFormat: "\u039C\u03BF\u03C1\u03C6\u03AE \u03A7\u03C1\u03CC\u03BD\u03BF\u03C5",
  autoRotate: "\u0391\u03C5\u03C4\u03CC\u03BC\u03B1\u03C4\u03B7 \u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE",
  labelRotation: "\u03A0\u03B5\u03C1\u03B9\u03C3\u03C4\u03C1\u03BF\u03C6\u03AE",
  circle: "\u039A\u03CD\u03BA\u03BB\u03BF\u03C2",
  polygon: "\u03A0\u03BF\u03BB\u03CD\u03B3\u03C9\u03BD\u03BF",
  square: "\u03A4\u03B5\u03C4\u03C1\u03AC\u03B3\u03C9\u03BD\u03BF",
  cross: "\u03A3\u03C4\u03B1\u03C5\u03C1\u03CC\u03C2",
  diamond: "\u0394\u03B9\u03B1\u03BC\u03AC\u03BD\u03C4\u03B9",
  plus: "\u03A3\u03C5\u03BD",
  triangle: "\u03A4\u03C1\u03AF\u03B3\u03C9\u03BD\u03BF",
  heart: "\u039A\u03B1\u03C1\u03B4\u03B9\u03AC",
  orientation: "\u03A0\u03C1\u03BF\u03C3\u03B1\u03BD\u03B1\u03C4\u03BF\u03BB\u03B9\u03C3\u03BC\u03CC\u03C2",
  fixed: "\u03A3\u03C4\u03B1\u03B8\u03B5\u03C1\u03CC",
  parallel: "\u03A0\u03B1\u03C1\u03AC\u03BB\u03BB\u03B7\u03BB\u03BF",
  perpendicular: "\u039A\u03B1\u03C4\u03B1\u03BA\u03CC\u03C1\u03C5\u03C6\u03BF",
  radiusAxisPosition: "\u0398\u03AD\u03C3\u03B7",
  ticks: "\u03A4\u03B9\u03BA",
  gridLines: "\u0393\u03C1\u03B1\u03BC\u03BC\u03AD\u03C2 \u03A0\u03BB\u03AD\u03B3\u03BC\u03B1\u03C4\u03BF\u03C2",
  width: "\u03A0\u03BB\u03AC\u03C4\u03BF\u03C2",
  height: "\u038E\u03C8\u03BF\u03C2",
  length: "\u039C\u03AE\u03BA\u03BF\u03C2",
  padding: "\u0395\u03C3\u03BF\u03C7\u03AE",
  spacing: "\u0394\u03B9\u03AC\u03BA\u03B5\u03BD\u03BF",
  chartStyle: "\u03A3\u03C4\u03C5\u03BB \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  title: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2",
  chartTitles: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03B9",
  chartTitle: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  chartSubtitle: "\u03A5\u03C0\u03CC\u03C4\u03B9\u03C4\u03BB\u03BF\u03C2",
  horizontalAxisTitle: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2 \u039F\u03C1\u03B9\u03B6\u03CC\u03BD\u03C4\u03B9\u03BF\u03C5 \u0386\u03BE\u03BF\u03BD\u03B1",
  verticalAxisTitle: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2 \u039A\u03B1\u03C4\u03B1\u03BA\u03CC\u03C1\u03C5\u03C6\u03BF\u03C5 \u0386\u03BE\u03BF\u03BD\u03B1",
  polarAxisTitle: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2 \u03A0\u03BF\u03BB\u03B9\u03BA\u03BF\u03CD \u0386\u03BE\u03BF\u03BD\u03B1",
  titlePlaceholder: "\u03A4\u03AF\u03C4\u03BB\u03BF\u03C2 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  background: "\u0399\u03C3\u03C4\u03BF\u03C1\u03B9\u03BA\u03CC",
  font: "\u0393\u03C1\u03B1\u03BC\u03BC\u03B1\u03C4\u03BF\u03C3\u03B5\u03B9\u03C1\u03AC",
  weight: "\u0392\u03AC\u03C1\u03BF\u03C2",
  top: "\u03A0\u03AC\u03BD\u03C9",
  right: "\u0394\u03B5\u03BE\u03B9\u03AC",
  bottom: "\u039A\u03AC\u03C4\u03C9",
  left: "\u0391\u03C1\u03B9\u03C3\u03C4\u03B5\u03C1\u03AC",
  labels: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2",
  calloutLabels: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u039A\u03BB\u03AE\u03C3\u03B7\u03C2",
  sectorLabels: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03A4\u03BF\u03BC\u03AD\u03C9\u03BD",
  positionRatio: "\u0391\u03BD\u03B1\u03BB\u03BF\u03B3\u03AF\u03B1 \u0398\u03AD\u03C3\u03B7\u03C2",
  size: "\u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2",
  shape: "\u03A3\u03C7\u03AE\u03BC\u03B1",
  minSize: "\u0395\u03BB\u03AC\u03C7\u03B9\u03C3\u03C4\u03BF \u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2",
  maxSize: "\u039C\u03AD\u03B3\u03B9\u03C3\u03C4\u03BF \u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2",
  legend: "\u03A5\u03C0\u03CC\u03BC\u03BD\u03B7\u03BC\u03B1",
  position: "\u0398\u03AD\u03C3\u03B7",
  markerSize: "\u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2 \u0394\u03B5\u03AF\u03BA\u03C4\u03B7",
  markerStroke: "\u0392\u03AC\u03C1\u03BF\u03C2 \u0394\u03B5\u03AF\u03BA\u03C4\u03B7",
  markerPadding: "\u039F\u03BB\u03BF\u03BA\u03BB\u03B7\u03C1\u03C9\u03C4\u03B9\u03BA\u03AE \u0395\u03C3\u03BF\u03C7\u03AE",
  itemSpacing: "\u0394\u03B9\u03AC\u03BA\u03B5\u03BD\u03BF \u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03BF\u03C5",
  itemPaddingX: "\u0395\u03C3\u03BF\u03C7\u03AE \u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03BF\u03C5 X",
  itemPaddingY: "\u0395\u03C3\u03BF\u03C7\u03AE \u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03BF\u03C5 Y",
  layoutHorizontalSpacing: "\u039F\u03C1\u03B9\u03B6\u03CC\u03BD\u03C4\u03B9\u03BF \u0394\u03B9\u03AC\u03BA\u03B5\u03BD\u03BF",
  layoutVerticalSpacing: "\u039A\u03B1\u03C4\u03B1\u03BA\u03CC\u03C1\u03C5\u03C6\u03BF \u0394\u03B9\u03AC\u03BA\u03B5\u03BD\u03BF",
  strokeWidth: "\u03A0\u03AC\u03C7\u03BF\u03C2 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  offset: "\u039C\u03B5\u03C4\u03B1\u03C4\u03CC\u03C0\u03B9\u03C3\u03B7",
  offsets: "\u039C\u03B5\u03C4\u03B1\u03C4\u03BF\u03C0\u03AF\u03C3\u03B5\u03B9\u03C2",
  tooltips: "\u03A5\u03C0\u03BF\u03B4\u03B5\u03AF\u03BE\u03B5\u03B9\u03C2",
  callout: "\u039A\u03BB\u03AE\u03C3\u03B7",
  markers: "\u0394\u03B5\u03AF\u03BA\u03C4\u03B5\u03C2",
  shadow: "\u03A3\u03BA\u03B9\u03AC",
  blur: "\u0398\u03AC\u03BC\u03C0\u03C9\u03BC\u03B1",
  xOffset: "\u039C\u03B5\u03C4\u03B1\u03C4\u03CC\u03C0\u03B9\u03C3\u03B7 \u03A7",
  yOffset: "\u039C\u03B5\u03C4\u03B1\u03C4\u03CC\u03C0\u03B9\u03C3\u03B7 \u03A5",
  lineWidth: "\u03A0\u03AC\u03C7\u03BF\u03C2 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  lineDash: "\u03A0\u03B1\u03CD\u03BB\u03B1 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  lineDashOffset: "\u039C\u03B5\u03C4\u03B1\u03C4\u03CC\u03C0\u03B9\u03C3\u03B7 \u03A0\u03B1\u03C5\u03BB\u03B1\u03C2",
  scrollingZoom: "\u039A\u03CD\u03BB\u03B9\u03C3\u03B7",
  scrollingStep: "\u0392\u03AE\u03BC\u03B1 \u039A\u03CD\u03BB\u03B9\u03C3\u03B7\u03C2",
  selectingZoom: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE",
  durationMillis: "\u0394\u03B9\u03AC\u03C1\u03BA\u03B5\u03B9\u03B1 (\u03BC\u03C3)",
  crosshairLabel: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B1",
  crosshairSnap: "\u03A3\u03C5\u03BB\u03BB\u03AE\u03C8\u03C4\u03B5 \u03C4\u03BF\u03BD \u039A\u03CC\u03BC\u03B2\u03BF",
  normal: "\u039A\u03B1\u03BD\u03BF\u03BD\u03B9\u03BA\u03CC",
  bold: "\u0388\u03BD\u03C4\u03BF\u03BD\u03BF",
  italic: "\u03A0\u03BB\u03AC\u03B3\u03B9\u03BF",
  boldItalic: "\u0388\u03BD\u03C4\u03BF\u03BD\u03BF \u03A0\u03BB\u03AC\u03B3\u03B9\u03BF",
  predefined: "\u03A0\u03C1\u03BF\u03BA\u03B1\u03B8\u03BF\u03C1\u03B9\u03C3\u03BC\u03AD\u03BD\u03BF",
  fillOpacity: "\u0391\u03B4\u03B9\u03B1\u03C6\u03AC\u03BD\u03B5\u03B9\u03B1 \u03A3\u03C5\u03BC\u03C0\u03BB\u03AE\u03C1\u03C9\u03C3\u03B7\u03C2",
  strokeColor: "\u03A7\u03C1\u03CE\u03BC\u03B1 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  strokeOpacity: "\u0391\u03B4\u03B9\u03B1\u03C6\u03AC\u03BD\u03B5\u03B9\u03B1 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  miniChart: "\u039C\u03AF\u03BD\u03B9-\u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  histogramBinCount: "\u03A0\u03BB\u03AE\u03B8\u03BF\u03C2 \u039A\u03AC\u03B4\u03C9\u03BD",
  connectorLine: "\u0393\u03C1\u03B1\u03BC\u03BC\u03AE \u03A3\u03CD\u03BD\u03B4\u03B5\u03C3\u03B7\u03C2",
  seriesItems: "\u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03B1 \u03A3\u03B5\u03B9\u03C1\u03CE\u03BD",
  seriesItemType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03BF\u03C5",
  seriesItemPositive: "\u0398\u03B5\u03C4\u03B9\u03BA\u03CC",
  seriesItemNegative: "\u0391\u03C1\u03BD\u03B7\u03C4\u03B9\u03BA\u03CC",
  seriesItemLabels: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03A3\u03C4\u03BF\u03B9\u03C7\u03B5\u03AF\u03C9\u03BD",
  columnGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  barGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  pieGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A0\u03AF\u03C4\u03B1\u03C2",
  lineGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  scatterGroup: "\u03A7 \u03A5 (\u03A3\u03BA\u03CC\u03C1\u03C0\u03B9\u03BF)",
  areaGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE\u03C2",
  polarGroup: "\u03A0\u03BF\u03BB\u03B9\u03BA\u03AE \u039F\u03BC\u03AC\u03B4\u03B1",
  statisticalGroup: "\u03A3\u03C4\u03B1\u03C4\u03B9\u03C3\u03C4\u03B9\u03BA\u03AE \u039F\u03BC\u03AC\u03B4\u03B1",
  hierarchicalGroup: "\u0399\u03B5\u03C1\u03B1\u03C1\u03C7\u03B9\u03BA\u03AE \u039F\u03BC\u03AC\u03B4\u03B1",
  specializedGroup: "\u0395\u03B9\u03B4\u03B9\u03BA\u03B5\u03C5\u03BC\u03AD\u03BD\u03B7 \u039F\u03BC\u03AC\u03B4\u03B1",
  combinationGroup: "\u03A3\u03C5\u03BD\u03B4\u03C5\u03B1\u03C3\u03C4\u03B9\u03BA\u03AE \u039F\u03BC\u03AC\u03B4\u03B1",
  groupedColumnTooltip: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF",
  stackedColumnTooltip: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  normalizedColumnTooltip: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  groupedBarTooltip: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF",
  stackedBarTooltip: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  normalizedBarTooltip: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  pieTooltip: "\u03A0\u03AF\u03C4\u03B1",
  donutTooltip: "\u039D\u03C4\u03CC\u03BD\u03B1\u03C4",
  lineTooltip: "\u0393\u03C1\u03B1\u03BC\u03BC\u03AE",
  groupedAreaTooltip: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  stackedAreaTooltip: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7",
  normalizedAreaTooltip: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7",
  scatterTooltip: "\u03A3\u03BA\u03CC\u03C1\u03C0\u03B9\u03BF",
  bubbleTooltip: "\u03A6\u03BF\u03CD\u03C3\u03BA\u03B1",
  histogramTooltip: "\u0399\u03C3\u03C4\u03CC\u03B3\u03C1\u03B1\u03BC\u03BC\u03B1",
  radialColumnTooltip: "\u0391\u03BA\u03C4\u03B9\u03BD\u03B9\u03BA\u03AE \u03A3\u03C4\u03AE\u03BB\u03B7",
  radialBarTooltip: "\u0391\u03BA\u03C4\u03B9\u03BD\u03B9\u03BA\u03CC \u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  radarLineTooltip: "\u03A1\u03B1\u03BD\u03C4\u03AC\u03C1 \u0393\u03C1\u03B1\u03BC\u03BC\u03AE",
  radarAreaTooltip: "\u03A1\u03B1\u03BD\u03C4\u03AC\u03C1 \u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  nightingaleTooltip: "\u0391\u03B7\u03B4\u03CC\u03BD\u03B9",
  rangeBarTooltip: "\u039C\u03C0\u03AC\u03C1\u03B1 \u0395\u03CD\u03C1\u03BF\u03C5\u03C2",
  rangeAreaTooltip: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE \u0395\u03CD\u03C1\u03BF\u03C5\u03C2",
  boxPlotTooltip: "\u039A\u03BF\u03C5\u03C4\u03AF \u03A3\u03C7\u03B5\u03B4\u03AF\u03BF\u03C5",
  treemapTooltip: "\u03A7\u03AC\u03C1\u03C4\u03B7\u03C2 \u0394\u03AD\u03BD\u03C4\u03C1\u03BF\u03C5",
  sunburstTooltip: "\u0397\u03BB\u03B9\u03B1\u03BA\u03AE \u0388\u03BA\u03BB\u03B1\u03BC\u03C8\u03B7",
  waterfallTooltip: "\u039A\u03B1\u03C4\u03B1\u03C1\u03C1\u03AC\u03BA\u03C4\u03B7\u03C2",
  heatmapTooltip: "\u03A7\u03AC\u03C1\u03C4\u03B7\u03C2 \u0398\u03B5\u03C1\u03BC\u03CC\u03C4\u03B7\u03C4\u03B1\u03C2",
  columnLineComboTooltip: "\u03A3\u03C4\u03AE\u03BB\u03B7 & \u0393\u03C1\u03B1\u03BC\u03BC\u03AE",
  areaColumnComboTooltip: "\u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE & \u03A3\u03C4\u03AE\u03BB\u03B7",
  customComboTooltip: "\u03A0\u03C1\u03BF\u03C3\u03B1\u03C1\u03BC\u03BF\u03C3\u03BC\u03AD\u03BD\u03BF\u03C2 \u03A3\u03C5\u03BD\u03B4\u03C5\u03B1\u03C3\u03BC\u03CC\u03C2",
  innerRadius: "\u0395\u03C3\u03C9\u03C4\u03B5\u03C1\u03B9\u03BA\u03AE \u0391\u03BA\u03C4\u03AF\u03BD\u03B1",
  startAngle: "\u0393\u03C9\u03BD\u03AF\u03B1 \u0395\u03BA\u03BA\u03AF\u03BD\u03B7\u03C3\u03B7\u03C2",
  endAngle: "\u03A4\u03B5\u03BB\u03B9\u03BA\u03AE \u0393\u03C9\u03BD\u03AF\u03B1",
  reverseDirection: "\u0391\u03BD\u03C4\u03AF\u03B8\u03B5\u03C4\u03B7 \u039A\u03B1\u03C4\u03B5\u03CD\u03B8\u03C5\u03BD\u03C3\u03B7",
  groupPadding: "\u0395\u03C3\u03BF\u03C7\u03AE \u039F\u03BC\u03AC\u03B4\u03B1\u03C2",
  seriesPadding: "\u0395\u03C3\u03BF\u03C7\u03AE \u03A3\u03B5\u03B9\u03C1\u03AC\u03C2",
  tile: "\u03A0\u03BB\u03B1\u03BA\u03AC\u03BA\u03B9",
  whisker: "\u039C\u03BF\u03C5\u03C3\u03C4\u03AC\u03BA\u03B9",
  cap: "\u039A\u03B1\u03C0\u03AC\u03BA\u03B9",
  capLengthRatio: "\u039B\u03CC\u03B3\u03BF\u03C2 \u039C\u03AE\u03BA\u03BF\u03C5\u03C2",
  labelPlacement: "\u03A4\u03BF\u03C0\u03BF\u03B8\u03AD\u03C4\u03B7\u03C3\u03B7 \u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B1\u03C2",
  inside: "\u0395\u03C3\u03C9\u03C4\u03B5\u03C1\u03B9\u03BA\u03AC",
  outside: "\u0395\u03BE\u03C9\u03C4\u03B5\u03C1\u03B9\u03BA\u03AC",
  noDataToChart: "\u0394\u03B5\u03BD \u03C5\u03C0\u03AC\u03C1\u03C7\u03BF\u03C5\u03BD \u03B4\u03B9\u03B1\u03B8\u03AD\u03C3\u03B9\u03BC\u03B1 \u03B4\u03B5\u03B4\u03BF\u03BC\u03AD\u03BD\u03B1 \u03B3\u03B9\u03B1 \u03C4\u03BF \u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1.",
  pivotChartRequiresPivotMode: "\u03A4\u03BF \u03A3\u03C5\u03B3\u03BA\u03B5\u03BD\u03C4\u03C1\u03C9\u03C4\u03B9\u03BA\u03CC \u0393\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1 \u03B1\u03C0\u03B1\u03B9\u03C4\u03B5\u03AF \u03C4\u03B7\u03BD \u03B5\u03BD\u03B5\u03C1\u03B3\u03BF\u03C0\u03BF\u03AF\u03B7\u03C3\u03B7 \u03C4\u03B7\u03C2 \u03BB\u03B5\u03B9\u03C4\u03BF\u03C5\u03C1\u03B3\u03AF\u03B1\u03C2 \u03A3\u03C5\u03B3\u03BA\u03B5\u03BD\u03C4\u03C1\u03C9\u03C4\u03B9\u03BA\u03BF\u03CD.",
  chartSettingsToolbarTooltip: "\u039C\u03B5\u03BD\u03BF\u03CD",
  chartLinkToolbarTooltip: "\u03A3\u03C5\u03BD\u03B4\u03B5\u03B4\u03B5\u03BC\u03AD\u03BD\u03BF \u03C3\u03C4\u03BF \u03A0\u03BB\u03AD\u03B3\u03BC\u03B1",
  chartUnlinkToolbarTooltip: "\u0391\u03C0\u03BF\u03C3\u03C5\u03BD\u03B4\u03B5\u03B4\u03B5\u03BC\u03AD\u03BD\u03BF \u03B1\u03C0\u03CC \u03C4\u03BF \u03A0\u03BB\u03AD\u03B3\u03BC\u03B1",
  chartDownloadToolbarTooltip: "\u039B\u03AE\u03C8\u03B7 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  chartMenuToolbarTooltip: "\u039C\u03B5\u03BD\u03BF\u03CD",
  chartEdit: "\u0395\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03AF\u03B1 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  chartAdvancedSettings: "\u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03B5\u03C2 \u03A1\u03C5\u03B8\u03BC\u03AF\u03C3\u03B5\u03B9\u03C2",
  chartLink: "\u03A3\u03CD\u03BD\u03B4\u03B5\u03C3\u03B7 \u03C3\u03C4\u03BF \u03A0\u03BB\u03AD\u03B3\u03BC\u03B1",
  chartUnlink: "\u0391\u03C0\u03BF\u03C3\u03CD\u03BD\u03B4\u03B5\u03C3\u03B7 \u03B1\u03C0\u03CC \u03C4\u03BF \u03A0\u03BB\u03AD\u03B3\u03BC\u03B1",
  chartDownload: "\u039B\u03AE\u03C8\u03B7 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  histogramFrequency: "\u03A3\u03C5\u03C7\u03BD\u03CC\u03C4\u03B7\u03C4\u03B1",
  seriesChartType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2 \u03A3\u03B5\u03B9\u03C1\u03AC\u03C2",
  seriesType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u03A3\u03B5\u03B9\u03C1\u03AC\u03C2",
  secondaryAxis: "\u0394\u03B5\u03C5\u03C4\u03B5\u03C1\u03B5\u03CD\u03C9\u03BD \u0386\u03BE\u03BF\u03BD\u03B1\u03C2",
  seriesAdd: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03C3\u03B5\u03B9\u03C1\u03AC\u03C2",
  categoryAdd: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03BA\u03B1\u03C4\u03B7\u03B3\u03BF\u03C1\u03AF\u03B1\u03C2",
  bar: "\u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  column: "\u03A3\u03C4\u03AE\u03BB\u03B7",
  histogram: "\u0399\u03C3\u03C4\u03CC\u03B3\u03C1\u03B1\u03BC\u03BC\u03B1",
  advancedSettings: "\u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03B5\u03C2 \u03A1\u03C5\u03B8\u03BC\u03AF\u03C3\u03B5\u03B9\u03C2",
  direction: "\u039A\u03B1\u03C4\u03B5\u03CD\u03B8\u03C5\u03BD\u03C3\u03B7",
  horizontal: "\u039F\u03C1\u03B9\u03B6\u03CC\u03BD\u03C4\u03B9\u03BF",
  vertical: "\u039A\u03B1\u03C4\u03B1\u03BA\u03CC\u03C1\u03C5\u03C6\u03BF",
  seriesGroupType: "\u03A4\u03CD\u03C0\u03BF\u03C2 \u039F\u03BC\u03AC\u03B4\u03B1\u03C2",
  groupedSeriesGroupType: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF",
  stackedSeriesGroupType: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  normalizedSeriesGroupType: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF",
  legendEnabled: "\u0395\u03BD\u03B5\u03C1\u03B3\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF",
  invalidColor: "\u0397 \u03C4\u03B9\u03BC\u03AE \u03C4\u03BF\u03C5 \u03C7\u03C1\u03CE\u03BC\u03B1\u03C4\u03BF\u03C2 \u03B4\u03B5\u03BD \u03B5\u03AF\u03BD\u03B1\u03B9 \u03AD\u03B3\u03BA\u03C5\u03C1\u03B7",
  groupedColumnFull: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03B7 \u03A3\u03C4\u03AE\u03BB\u03B7",
  stackedColumnFull: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7 \u03A3\u03C4\u03AE\u03BB\u03B7",
  normalizedColumnFull: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7 \u03A3\u03C4\u03AE\u03BB\u03B7",
  groupedBarFull: "\u039F\u03BC\u03B1\u03B4\u03BF\u03C0\u03BF\u03B9\u03B7\u03BC\u03AD\u03BD\u03BF \u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  stackedBarFull: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF \u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  normalizedBarFull: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03BF \u03A1\u03B1\u03B2\u03B4\u03BF\u03B3\u03C1\u03AC\u03C6\u03B7\u03BC\u03B1",
  stackedAreaFull: "\u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7 \u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  normalizedAreaFull: "100% \u03A3\u03C4\u03BF\u03B9\u03B2\u03B1\u03B3\u03BC\u03AD\u03BD\u03B7 \u03A0\u03B5\u03C1\u03B9\u03BF\u03C7\u03AE",
  customCombo: "\u03A0\u03C1\u03BF\u03C3\u03B1\u03C1\u03BC\u03BF\u03C3\u03BC\u03AD\u03BD\u03BF\u03C2 \u03A3\u03C5\u03BD\u03B4\u03C5\u03B1\u03C3\u03BC\u03CC\u03C2",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u0395\u03C0\u03AF\u03C0\u03B5\u03B4\u03BF ${variable}. \u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 ENTER \u03B3\u03B9\u03B1 \u03B5\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03AF\u03B1",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u0395\u03C0\u03AF\u03C0\u03B5\u03B4\u03BF ${variable}. ${variable} \u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 ENTER \u03B3\u03B9\u03B1 \u03B5\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03AF\u03B1.",
  ariaAdvancedFilterBuilderList: "\u039B\u03AF\u03C3\u03C4\u03B1 \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u039A\u03B1\u03C4\u03B1\u03C3\u03BA\u03B5\u03C5\u03B1\u03C3\u03C4\u03AE \u03A6\u03AF\u03BB\u03C4\u03C1\u03C9\u03BD",
  ariaAdvancedFilterBuilderFilterItem: "\u03A3\u03C5\u03BD\u03B8\u03AE\u03BA\u03B7 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaAdvancedFilterBuilderGroupItem: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaAdvancedFilterBuilderColumn: "\u03A3\u03C4\u03AE\u03BB\u03B7",
  ariaAdvancedFilterBuilderOption: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE",
  ariaAdvancedFilterBuilderValueP: "\u03A4\u03B9\u03BC\u03AE",
  ariaAdvancedFilterBuilderJoinOperator: "\u03A4\u03B5\u03BB\u03B5\u03C3\u03C4\u03AE\u03C2 \u03A3\u03C5\u03BD\u03AD\u03BD\u03C9\u03C3\u03B7\u03C2",
  ariaAdvancedFilterInput: "\u0395\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03AE \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaChecked: "\u03B5\u03BB\u03B5\u03B3\u03BC\u03AD\u03BD\u03BF",
  ariaColumn: "\u03A3\u03C4\u03AE\u03BB\u03B7",
  ariaColumnGroup: "\u039F\u03BC\u03AC\u03B4\u03B1 \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  ariaColumnFiltered: "\u03A6\u03B9\u03BB\u03C4\u03C1\u03B1\u03C1\u03B9\u03C3\u03BC\u03AD\u03BD\u03B7 \u03A3\u03C4\u03AE\u03BB\u03B7",
  ariaColumnSelectAll: "\u0395\u03BD\u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2 \u038C\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  ariaDateFilterInput: "\u0395\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03AE \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5 \u0397\u03BC\u03B5\u03C1\u03BF\u03BC\u03B7\u03BD\u03AF\u03B1\u03C2",
  ariaDefaultListName: "\u039B\u03AF\u03C3\u03C4\u03B1",
  ariaFilterColumnsInput: "\u0395\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03AE \u03A6\u03B9\u03BB\u03C4\u03C1\u03B1\u03C1\u03AF\u03C3\u03BC\u03B1\u03C4\u03BF\u03C2 \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  ariaFilterFromValue: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u03B1\u03C0\u03CC \u03C4\u03B9\u03BC\u03AE",
  ariaFilterInput: "\u0395\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03AE \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaFilterList: "\u039B\u03AF\u03C3\u03C4\u03B1 \u03A6\u03AF\u03BB\u03C4\u03C1\u03C9\u03BD",
  ariaFilterToValue: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u03C3\u03B5 \u03C4\u03B9\u03BC\u03AE",
  ariaFilterValue: "\u03A4\u03B9\u03BC\u03AE \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaFilterMenuOpen: "\u0386\u03BD\u03BF\u03B9\u03B3\u03BC\u03B1 \u039C\u03B5\u03BD\u03BF\u03CD \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaFilteringOperator: "\u03A4\u03B5\u03BB\u03B5\u03C3\u03C4\u03AE\u03C2 \u03A6\u03B9\u03BB\u03C4\u03C1\u03B1\u03C1\u03AF\u03C3\u03BC\u03B1\u03C4\u03BF\u03C2",
  ariaHidden: "\u03BA\u03C1\u03C5\u03C6\u03CC",
  ariaIndeterminate: "\u03B1\u03C0\u03C1\u03BF\u03C3\u03B4\u03B9\u03CC\u03C1\u03B9\u03C3\u03C4\u03BF",
  ariaInputEditor: "\u0395\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03C4\u03AE\u03C2 \u0395\u03B9\u03C3\u03B1\u03B3\u03C9\u03B3\u03AE\u03C2",
  ariaMenuColumn: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 ALT DOWN \u03B3\u03B9\u03B1 \u03AC\u03BD\u03BF\u03B9\u03B3\u03BC\u03B1 \u03BC\u03B5\u03BD\u03BF\u03CD \u03C3\u03C4\u03AE\u03BB\u03B7\u03C2",
  ariaFilterColumn: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 CTRL ENTER \u03B3\u03B9\u03B1 \u03AC\u03BD\u03BF\u03B9\u03B3\u03BC\u03B1 \u03C6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaRowDeselect: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B1\u03C0\u03BF\u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03B1\u03C5\u03C4\u03AE\u03C2 \u03C4\u03B7\u03C2 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  ariaRowSelectAll: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2 \u03CC\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u03B3\u03C1\u03B1\u03BC\u03BC\u03CE\u03BD",
  ariaRowToggleSelection: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B5\u03BD\u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  ariaRowSelect: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03B1\u03C5\u03C4\u03AE\u03C2 \u03C4\u03B7\u03C2 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  ariaRowSelectionDisabled: "\u0397 \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2 \u03AD\u03C7\u03B5\u03B9 \u03B1\u03C0\u03B5\u03BD\u03B5\u03C1\u03B3\u03BF\u03C0\u03BF\u03B9\u03B7\u03B8\u03B5\u03AF \u03B3\u03B9\u03B1 \u03B1\u03C5\u03C4\u03AE \u03C4\u03B7 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE",
  ariaSearch: "\u0391\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7",
  ariaSortableColumn: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 ENTER \u03B3\u03B9\u03B1 \u03C4\u03B1\u03BE\u03B9\u03BD\u03CC\u03BC\u03B7\u03C3\u03B7",
  ariaToggleVisibility: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u03BF\u03C1\u03B1\u03C4\u03CC\u03C4\u03B7\u03C4\u03B1\u03C2",
  ariaToggleCellValue: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B5\u03BD\u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u03C4\u03B9\u03BC\u03AE\u03C2 \u03BA\u03B5\u03BB\u03B9\u03BF\u03CD",
  ariaUnchecked: "\u03BC\u03B7 \u03B5\u03BB\u03B5\u03B3\u03BC\u03AD\u03BD\u03BF",
  ariaVisible: "\u03BF\u03C1\u03B1\u03C4\u03CC",
  ariaSearchFilterValues: "\u0391\u03BD\u03B1\u03B6\u03AE\u03C4\u03B7\u03C3\u03B7 \u03C4\u03B9\u03BC\u03CE\u03BD \u03C6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaPageSizeSelectorLabel: "\u039C\u03AD\u03B3\u03B5\u03B8\u03BF\u03C2 \u03A3\u03B5\u03BB\u03AF\u03B4\u03B1\u03C2",
  ariaChartMenuClose: "\u039A\u03BB\u03B5\u03AF\u03C3\u03B9\u03BC\u03BF \u039C\u03B5\u03BD\u03BF\u03CD \u0395\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03AF\u03B1\u03C2 \u0393\u03C1\u03B1\u03C6\u03AE\u03BC\u03B1\u03C4\u03BF\u03C2",
  ariaChartSelected: "\u0395\u03C0\u03B9\u03BB\u03B5\u03B3\u03BC\u03AD\u03BD\u03BF",
  ariaSkeletonCellLoadingFailed: "\u0391\u03C0\u03BF\u03C4\u03C5\u03C7\u03AF\u03B1 \u03C6\u03CC\u03C1\u03C4\u03C9\u03C3\u03B7\u03C2 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2",
  ariaSkeletonCellLoading: "\u03A4\u03B1 \u03B4\u03B5\u03B4\u03BF\u03BC\u03AD\u03BD\u03B1 \u03B3\u03C1\u03B1\u03BC\u03BC\u03AE\u03C2 \u03C6\u03BF\u03C1\u03C4\u03CE\u03BD\u03BF\u03BD\u03C4\u03B1\u03B9",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u039F\u03BC\u03AC\u03B4\u03B5\u03C2 \u03A3\u03B5\u03B9\u03C1\u03CE\u03BD",
  ariaValuesDropZonePanelLabel: "\u03A4\u03B9\u03BC\u03AD\u03C2",
  ariaPivotDropZonePanelLabel: "\u0395\u03C4\u03B9\u03BA\u03AD\u03C4\u03B5\u03C2 \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  ariaDropZoneColumnComponentDescription: "\u03A0\u03B9\u03AD\u03C3\u03C4\u03B5 DELETE \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03B1\u03C6\u03B1\u03B9\u03C1\u03AD\u03C3\u03B5\u03C4\u03B5",
  ariaDropZoneColumnValueItemDescription: "\u03A0\u03B9\u03AD\u03C3\u03C4\u03B5 ENTER \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03B1\u03BB\u03BB\u03AC\u03BE\u03B5\u03C4\u03B5 \u03C4\u03BF\u03BD \u03C4\u03CD\u03C0\u03BF \u03C3\u03C5\u03BD\u03AC\u03B8\u03C1\u03BF\u03B9\u03C3\u03B7\u03C2",
  ariaDropZoneColumnGroupItemDescription: "\u03A0\u03B9\u03AD\u03C3\u03C4\u03B5 ENTER \u03B3\u03B9\u03B1 \u03BD\u03B1 \u03C4\u03B1\u03BE\u03B9\u03BD\u03BF\u03BC\u03AE\u03C3\u03B5\u03C4\u03B5",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u03B1\u03C0\u03CC ",
  ariaDropZoneColumnComponentSortAscending: "\u03B1\u03CD\u03BE\u03BF\u03C5\u03C3\u03B1",
  ariaDropZoneColumnComponentSortDescending: "\u03C6\u03B8\u03AF\u03BD\u03BF\u03C5\u03C3\u03B1",
  ariaLabelDialog: "\u0394\u03B9\u03B1\u03BB\u03BF\u03B3\u03AE",
  ariaLabelColumnMenu: "\u039C\u03B5\u03BD\u03BF\u03CD \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  ariaLabelColumnFilter: "\u03A6\u03AF\u03BB\u03C4\u03C1\u03BF \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2",
  ariaLabelCellEditor: "\u0395\u03C0\u03B5\u03BE\u03B5\u03C1\u03B3\u03B1\u03C3\u03C4\u03AE\u03C2 \u039A\u03B5\u03BB\u03B9\u03BF\u03CD",
  ariaLabelSelectField: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A0\u03B5\u03B4\u03AF\u03BF\u03C5",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u03A0\u03B5\u03B4\u03AF\u03BF Rich Select",
  ariaLabelRichSelectToggleSelection: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 SPACE \u03B3\u03B9\u03B1 \u03B5\u03BD\u03B1\u03BB\u03BB\u03B1\u03B3\u03AE \u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2",
  ariaLabelRichSelectDeselectAllItems: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 DELETE \u03B3\u03B9\u03B1 \u03B1\u03C0\u03BF\u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03CC\u03BB\u03C9\u03BD \u03C4\u03C9\u03BD \u03B1\u03BD\u03C4\u03B9\u03BA\u03B5\u03B9\u03BC\u03AD\u03BD\u03C9\u03BD",
  ariaLabelRichSelectDeleteSelection: "\u03A0\u03B1\u03C4\u03AE\u03C3\u03C4\u03B5 DELETE \u03B3\u03B9\u03B1 \u03B1\u03C0\u03BF\u03B5\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03B1\u03BD\u03C4\u03B9\u03BA\u03B5\u03B9\u03BC\u03AD\u03BD\u03BF\u03C5",
  ariaLabelTooltip: "\u0395\u03C1\u03B3\u03B1\u03BB\u03B5\u03AF\u03BF \u03A3\u03C5\u03BC\u03B2\u03BF\u03C5\u03BB\u03CE\u03BD",
  ariaLabelContextMenu: "\u039C\u03B5\u03BD\u03BF\u03CD \u03A0\u03B5\u03C1\u03B9\u03B5\u03C7\u03BF\u03BC\u03AD\u03BD\u03BF\u03C5",
  ariaLabelSubMenu: "\u03A5\u03C0\u03BF\u03BC\u03B5\u03BD\u03BF\u03CD",
  ariaLabelAggregationFunction: "\u03A3\u03C5\u03BD\u03AC\u03C1\u03C4\u03B7\u03C3\u03B7 \u03A3\u03C5\u03BD\u03BF\u03BB\u03B9\u03BA\u03BF\u03C0\u03BF\u03AF\u03B7\u03C3\u03B7\u03C2",
  ariaLabelAdvancedFilterAutocomplete: "\u0391\u03C5\u03C4\u03CC\u03BC\u03B1\u03C4\u03B7 \u03A3\u03C5\u03BC\u03C0\u03BB\u03AE\u03C1\u03C9\u03C3\u03B7 \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaLabelAdvancedFilterBuilderAddField: "\u03A0\u03C1\u03BF\u03C3\u03B8\u03AE\u03BA\u03B7 \u03A0\u03B5\u03B4\u03AF\u03BF\u03C5 \u0394\u03B7\u03BC\u03B9\u03BF\u03C5\u03C1\u03B3\u03BF\u03CD \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A3\u03C4\u03AE\u03BB\u03B7\u03C2 \u0394\u03B7\u03BC\u03B9\u03BF\u03C5\u03C1\u03B3\u03BF\u03CD \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE\u03C2 \u0394\u03B7\u03BC\u03B9\u03BF\u03C5\u03C1\u03B3\u03BF\u03CD \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u0395\u03C0\u03B9\u03BB\u03BF\u03B3\u03AE \u03A4\u03B5\u03BB\u03B5\u03C3\u03C4\u03AE \u03A3\u03CD\u03BD\u03B4\u03B5\u03C3\u03B7\u03C2 \u0394\u03B7\u03BC\u03B9\u03BF\u03C5\u03C1\u03B3\u03BF\u03CD \u03A0\u03C1\u03BF\u03C7\u03C9\u03C1\u03B7\u03BC\u03AD\u03BD\u03BF\u03C5 \u03A6\u03AF\u03BB\u03C4\u03C1\u03BF\u03C5",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u039B\u03AF\u03C3\u03C4\u03B1 \u03A3\u03C4\u03B7\u03BB\u03CE\u03BD",
  ariaFilterPanelList: "\u039B\u03AF\u03C3\u03C4\u03B1 \u03A6\u03AF\u03BB\u03C4\u03C1\u03C9\u03BD",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "\u0391\u03BB\u03B7\u03B8\u03AD\u03C2",
  false: "\u03A8\u03B5\u03C5\u03B4\u03AD\u03C2",
  invalidDate: "\u039C\u03B7 \u03AD\u03B3\u03BA\u03C5\u03C1\u03B7 \u03B7\u03BC\u03B5\u03C1\u03BF\u03BC\u03B7\u03BD\u03AF\u03B1",
  invalidNumber: "\u039C\u03B7 \u03AD\u03B3\u03BA\u03C5\u03C1\u03BF\u03C2 \u03B1\u03C1\u03B9\u03B8\u03BC\u03CC\u03C2",
  january: "\u0399\u03B1\u03BD\u03BF\u03C5\u03AC\u03C1\u03B9\u03BF\u03C2",
  february: "\u03A6\u03B5\u03B2\u03C1\u03BF\u03C5\u03AC\u03C1\u03B9\u03BF\u03C2",
  march: "\u039C\u03AC\u03C1\u03C4\u03B9\u03BF\u03C2",
  april: "\u0391\u03C0\u03C1\u03AF\u03BB\u03B9\u03BF\u03C2",
  may: "\u039C\u03AC\u03B9\u03BF\u03C2",
  june: "\u0399\u03BF\u03CD\u03BD\u03B9\u03BF\u03C2",
  july: "\u0399\u03BF\u03CD\u03BB\u03B9\u03BF\u03C2",
  august: "\u0391\u03CD\u03B3\u03BF\u03C5\u03C3\u03C4\u03BF\u03C2",
  september: "\u03A3\u03B5\u03C0\u03C4\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2",
  october: "\u039F\u03BA\u03C4\u03CE\u03B2\u03C1\u03B9\u03BF\u03C2",
  november: "\u039D\u03BF\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2",
  december: "\u0394\u03B5\u03BA\u03AD\u03BC\u03B2\u03C1\u03B9\u03BF\u03C2",
  // Time formats
  timeFormatSlashesDDMMYYYY: "\u0397\u0397/\u039C\u039C/\u0395\u0395\u0395\u0395",
  timeFormatSlashesMMDDYYYY: "\u039C\u039C/\u0397\u0397/\u0395\u0395\u0395\u0395",
  timeFormatSlashesDDMMYY: "\u0397\u0397/\u039C\u039C/\u0395\u0395",
  timeFormatSlashesMMDDYY: "\u039C\u039C/\u0397\u0397/\u0395\u0395",
  timeFormatDotsDDMYY: "\u0397\u0397.\u039C.\u0395\u0395",
  timeFormatDotsMDDYY: "\u039C.\u0397\u0397.\u0395\u0395",
  timeFormatDashesYYYYMMDD: "\u0395\u0395\u0395\u0395-\u039C\u039C-\u0397\u0397",
  timeFormatSpacesDDMMMMYYYY: "\u0397\u0397 \u039C\u039C\u039C\u039C \u0395\u0395\u0395\u0395",
  timeFormatHHMMSS: "\u03A9\u03A9:\u039B\u039B:\u0394\u0394",
  timeFormatHHMMSSAmPm: "\u03A9\u03A9:\u039B\u039B:\u0394\u0394 \u03A0\u039C/\u039C\u039C"
};

// community-modules/locale/src/fi-FI.ts
var AG_GRID_LOCALE_FI = {
  // Set Filter
  selectAll: "(Valitse kaikki)",
  selectAllSearchResults: "(Valitse kaikki hakutulokset)",
  addCurrentSelectionToFilter: "Lis\xE4\xE4 nykyinen valinta suodattimeen",
  searchOoo: "Haku...",
  blanks: "(Tyhj\xE4t)",
  noMatches: "Ei osumia",
  // Number Filter & Text Filter
  filterOoo: "Suodata...",
  equals: "Yht\xE4 kuin",
  notEqual: "Eri kuin",
  blank: "Tyhj\xE4",
  notBlank: "Ei tyhj\xE4",
  empty: "Valitse yksi",
  // Number Filter
  lessThan: "V\xE4hemm\xE4n kuin",
  greaterThan: "Enemm\xE4n kuin",
  lessThanOrEqual: "V\xE4hemm\xE4n tai yht\xE4 paljon kuin",
  greaterThanOrEqual: "Enemm\xE4n tai yht\xE4 paljon kuin",
  inRange: "V\xE4lill\xE4",
  inRangeStart: "Alkaen",
  inRangeEnd: "Loppuun",
  // Text Filter
  contains: "Sis\xE4lt\xE4\xE4",
  notContains: "Ei sis\xE4ll\xE4",
  startsWith: "Alkaa",
  endsWith: "P\xE4\xE4ttyy",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Ennen",
  after: "J\xE4lkeen",
  // Filter Conditions
  andCondition: "JA",
  orCondition: "TAI",
  // Filter Buttons
  applyFilter: "K\xE4yt\xE4",
  resetFilter: "Nollaa",
  clearFilter: "Tyhjenn\xE4",
  cancelFilter: "Peruuta",
  // Filter Titles
  textFilter: "Tekstisuodatin",
  numberFilter: "Numerosuodatin",
  dateFilter: "P\xE4iv\xE4m\xE4\xE4r\xE4suodatin",
  setFilter: "Joukko suodatin",
  // Group Column Filter
  groupFilterSelect: "Valitse kentt\xE4:",
  // Advanced Filter
  advancedFilterContains: "sis\xE4lt\xE4\xE4",
  advancedFilterNotContains: "ei sis\xE4ll\xE4",
  advancedFilterTextEquals: "on yht\xE4 kuin",
  advancedFilterTextNotEqual: "ei ole yht\xE4 kuin",
  advancedFilterStartsWith: "alkaa",
  advancedFilterEndsWith: "p\xE4\xE4ttyy",
  advancedFilterBlank: "on tyhj\xE4",
  advancedFilterNotBlank: "ei ole tyhj\xE4",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "on tosi",
  advancedFilterFalse: "on ep\xE4tosi",
  advancedFilterAnd: "JA",
  advancedFilterOr: "TAI",
  advancedFilterApply: "K\xE4yt\xE4",
  advancedFilterBuilder: "Rakentaja",
  advancedFilterValidationMissingColumn: "Sarake puuttuu",
  advancedFilterValidationMissingOption: "Valinta puuttuu",
  advancedFilterValidationMissingValue: "Arvo puuttuu",
  advancedFilterValidationInvalidColumn: "Sarake ei l\xF6ydy",
  advancedFilterValidationInvalidOption: "Valintaa ei l\xF6ydy",
  advancedFilterValidationMissingQuote: "Arvosta puuttuu p\xE4\xE4te lainausmerkki",
  advancedFilterValidationNotANumber: "Arvo ei ole numero",
  advancedFilterValidationInvalidDate: "Arvo ei ole kelvollinen p\xE4iv\xE4m\xE4\xE4r\xE4",
  advancedFilterValidationMissingCondition: "Ehto puuttuu",
  advancedFilterValidationJoinOperatorMismatch: "Yhdisteoperaattoreiden t\xE4ytyy olla samat ehdon sis\xE4ll\xE4",
  advancedFilterValidationInvalidJoinOperator: "Yhdisteoperaattori ei l\xF6ydy",
  advancedFilterValidationMissingEndBracket: "P\xE4\xE4tt\xE4v\xE4 sulje puuttuu",
  advancedFilterValidationExtraEndBracket: "Liian monta p\xE4\xE4tt\xE4v\xE4\xE4 suljetta",
  advancedFilterValidationMessage: "Lausekkeessa on virhe. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Lausekkeessa on virhe. ${variable} lausekkeen lopussa.",
  advancedFilterBuilderTitle: "Tarkennettu suodatin",
  advancedFilterBuilderApply: "K\xE4yt\xE4",
  advancedFilterBuilderCancel: "Peruuta",
  advancedFilterBuilderAddButtonTooltip: "Lis\xE4\xE4 suodatin tai ryhm\xE4",
  advancedFilterBuilderRemoveButtonTooltip: "Poista",
  advancedFilterBuilderMoveUpButtonTooltip: "Siirr\xE4 yl\xF6s",
  advancedFilterBuilderMoveDownButtonTooltip: "Siirr\xE4 alas",
  advancedFilterBuilderAddJoin: "Lis\xE4\xE4 ryhm\xE4",
  advancedFilterBuilderAddCondition: "Lis\xE4\xE4 suodatin",
  advancedFilterBuilderSelectColumn: "Valitse sarake",
  advancedFilterBuilderSelectOption: "Valitse vaihtoehto",
  advancedFilterBuilderEnterValue: "Sy\xF6t\xE4 arvo...",
  advancedFilterBuilderValidationAlreadyApplied: "Nykyinen suodatin on jo k\xE4yt\xF6ss\xE4.",
  advancedFilterBuilderValidationIncomplete: "Kaikki ehdot eiv\xE4t ole valmiita.",
  advancedFilterBuilderValidationSelectColumn: "T\xE4ytyy valita sarake.",
  advancedFilterBuilderValidationSelectOption: "T\xE4ytyy valita vaihtoehto.",
  advancedFilterBuilderValidationEnterValue: "T\xE4ytyy sy\xF6tt\xE4\xE4 arvo.",
  // Side Bar
  columns: "Sarakkeet",
  filters: "Suodattimet",
  // columns tool panel
  pivotMode: "Pivot-tila",
  groups: "Riviryhm\xE4t",
  rowGroupColumnsEmptyMessage: "Ved\xE4 t\xE4nne asettaaksesi riviryhm\xE4t",
  values: "Arvot",
  valueColumnsEmptyMessage: "Ved\xE4 t\xE4nne tehd\xE4ksesi aggregaatin",
  pivots: "Sarakemerkinn\xE4t",
  pivotColumnsEmptyMessage: "Ved\xE4 t\xE4nne asettaaksesi sarakemerkinn\xE4t",
  // Header of the Default Group Column
  group: "Ryhm\xE4",
  // Row Drag
  rowDragRow: "rivi",
  rowDragRows: "rivit",
  // Other
  loadingOoo: "Ladataan...",
  loadingError: "VIRHE",
  noRowsToShow: "Ei n\xE4ytett\xE4vi\xE4 rivej\xE4",
  enabled: "K\xE4yt\xF6ss\xE4",
  // Menu
  pinColumn: "Kiinnit\xE4 sarake",
  pinLeft: "Kiinnit\xE4 vasemmalle",
  pinRight: "Kiinnit\xE4 oikealle",
  noPin: "Ei kiinnityst\xE4",
  valueAggregation: "Arvon yhteenveto",
  noAggregation: "Ei yhteenvetoa",
  autosizeThisColumn: "Automaattinen koko t\xE4lle sarakkeelle",
  autosizeAllColumns: "Automaattinen koko kaikille sarakkeille",
  groupBy: "Ryhmittele",
  ungroupBy: "Poista ryhmittely",
  ungroupAll: "Poista kaikkien ryhmien ryhmittely",
  addToValues: "Lis\xE4\xE4 ${variable} arvoihin",
  removeFromValues: "Poista ${variable} arvoista",
  addToLabels: "Lis\xE4\xE4 ${variable} etiketteihin",
  removeFromLabels: "Poista ${variable} etiketeist\xE4",
  resetColumns: "Palauta sarakkeet",
  expandAll: "Laajenna kaikki riviryhm\xE4t",
  collapseAll: "Sulje kaikki riviryhm\xE4t",
  copy: "Kopioi",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopioi otsikoiden kanssa",
  copyWithGroupHeaders: "Kopioi ryhm\xE4otsikoiden kanssa",
  cut: "Leikkaa",
  paste: "Liit\xE4",
  ctrlV: "Ctrl+V",
  export: "Vie",
  csvExport: "Vie CSV-muodossa",
  excelExport: "Vie Excel-muodossa",
  columnFilter: "Sarakkeen suodatus",
  columnChooser: "Valitse sarakkeet",
  chooseColumns: "Valitse sarakkeet",
  sortAscending: "Lajittele nousevasti",
  sortDescending: "Lajittele laskevasti",
  sortUnSort: "Tyhjenn\xE4 lajittelu",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Summa",
  first: "Ensimm\xE4inen",
  last: "Viimeinen",
  min: "Minimi",
  max: "Maksimi",
  none: "Ei mit\xE4\xE4n",
  count: "Lukum\xE4\xE4r\xE4",
  avg: "Keskiarvo",
  filteredRows: "Suodatettu",
  selectedRows: "Valitut",
  totalRows: "Rivit yhteens\xE4",
  totalAndFilteredRows: "Rivit",
  more: "Lis\xE4\xE4",
  to: "-",
  of: " / ",
  page: "Sivu",
  pageLastRowUnknown: "?",
  nextPage: "Seuraava sivu",
  lastPage: "Viimeinen sivu",
  firstPage: "Ensimm\xE4inen sivu",
  previousPage: "Edellinen sivu",
  pageSizeSelectorLabel: "Sivun koko:",
  footerTotal: "Yhteens\xE4",
  // Pivoting
  pivotColumnGroupTotals: "Yhteens\xE4",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Kaavio & Pivot Tila",
  pivotChart: "Pivot Kaavio",
  chartRange: "Kaavion Alue",
  columnChart: "Pylv\xE4s",
  groupedColumn: "Ryhmitelty",
  stackedColumn: "Pinottu",
  normalizedColumn: "100% Pinottu",
  barChart: "Palkki",
  groupedBar: "Ryhmitelty",
  stackedBar: "Pinottu",
  normalizedBar: "100% Pinottu",
  pieChart: "Piirakka",
  pie: "Piirakka",
  donut: "Donitsi",
  line: "Viiva",
  xyChart: "X Y (Hajontakaavio)",
  scatter: "Hajonta",
  bubble: "Kupla",
  areaChart: "Alue",
  area: "Alue",
  stackedArea: "Pinottu",
  normalizedArea: "100% Pinottu",
  histogramChart: "Histogrammi",
  polarChart: "Polaarinen",
  radarLine: "Tutkaviiva",
  radarArea: "Tutka-alue",
  nightingale: "Nightingale",
  radialColumn: "S\xE4depylv\xE4s",
  radialBar: "S\xE4depalkki",
  statisticalChart: "Tilastollinen",
  boxPlot: "Laatikkokaavio",
  rangeBar: "Aluepalkki",
  rangeArea: "Aluekaavio",
  hierarchicalChart: "Hierarkkinen",
  treemap: "Puukaavio",
  sunburst: "Auringonpurkaus",
  specializedChart: "Erikoistunut",
  waterfall: "Vesiputous",
  heatmap: "L\xE4mp\xF6kartta",
  combinationChart: "Yhdistelm\xE4",
  columnLineCombo: "Pylv\xE4s & Viiva",
  AreaColumnCombo: "Alue & Pylv\xE4s",
  // Charts
  pivotChartTitle: "Pivot-kaavio",
  rangeChartTitle: "Aluekaavio",
  settings: "Kaavio",
  data: "M\xE4\xE4ritys",
  format: "Mukauta",
  categories: "Luokat",
  defaultCategory: "(Ei mit\xE4\xE4n)",
  series: "Sarjat",
  switchCategorySeries: "Vaihda luokkaa / sarjaa",
  categoryValues: "Luokka-arvot",
  seriesLabels: "Sarjojen tunnisteet",
  aggregate: "Yhdist\xE4",
  xyValues: "X Y -arvot",
  paired: "Paritettu tila",
  axis: "Akseli",
  xAxis: "Vaaka-akseli",
  yAxis: "Pysty-akseli",
  polarAxis: "Polaarinen akseli",
  radiusAxis: "S\xE4deakseli",
  navigator: "Navigator",
  zoom: "Zoomaa",
  animation: "Animaatio",
  crosshair: "Ristikko",
  color: "V\xE4ri",
  thickness: "Paksuus",
  preferredLength: "Suositeltu pituus",
  xType: "X-tyyppi",
  axisType: "Akseleiden tyyppi",
  automatic: "Automaattinen",
  category: "Luokka",
  number: "Numero",
  time: "Aika",
  timeFormat: "Aikaformaatti",
  autoRotate: "Automaattinen kierto",
  labelRotation: "Kierr\xE4",
  circle: "Ympyr\xE4",
  polygon: "Monikulmio",
  square: "Neli\xF6",
  cross: "Risti",
  diamond: "Timantti",
  plus: "Plus",
  triangle: "Kolmio",
  heart: "Syd\xE4n",
  orientation: "Orientaatio",
  fixed: "Kiinnitetty",
  parallel: "Rinnakkainen",
  perpendicular: "Kohtisuora",
  radiusAxisPosition: "Sijainti",
  ticks: "Merkit",
  gridLines: "Ruudukko viivat",
  width: "Leveys",
  height: "Korkeus",
  length: "Pituus",
  padding: "T\xE4ytt\xF6",
  spacing: "V\xE4li",
  chartStyle: "Kaavion tyyli",
  title: "Otsikko",
  chartTitles: "Kaavio-otsikot",
  chartTitle: "Kaavio-otsikko",
  chartSubtitle: "Alaotsikko",
  horizontalAxisTitle: "Vaaka-akselin otsikko",
  verticalAxisTitle: "Pysty-akselin otsikko",
  polarAxisTitle: "Polaarisen akselin otsikko",
  titlePlaceholder: "Kaavion otsikko",
  background: "Tausta",
  font: "Fontti",
  weight: "Paksuus",
  top: "Yl\xE4",
  right: "Oikea",
  bottom: "Ala",
  left: "Vasen",
  labels: "Tunnisteet",
  calloutLabels: "Huomautustunnisteet",
  sectorLabels: "Sektoritunnisteet",
  positionRatio: "Suhteen sijainti",
  size: "Koko",
  shape: "Muoto",
  minSize: "Minimikoko",
  maxSize: "Maksimikoko",
  legend: "Selite",
  position: "Sijainti",
  markerSize: "Merkin koko",
  markerStroke: "Merkki veto",
  markerPadding: "Merkin t\xE4ytt\xF6",
  itemSpacing: "Kohteen v\xE4li",
  itemPaddingX: "Kohteen t\xE4ytt\xF6 X",
  itemPaddingY: "Kohteen t\xE4ytt\xF6 Y",
  layoutHorizontalSpacing: "Vaakasuuntainen v\xE4li",
  layoutVerticalSpacing: "Pystysuuntainen v\xE4li",
  strokeWidth: "Viivan leveys",
  offset: "Siirtym\xE4",
  offsets: "Offsetit",
  tooltips: "Ty\xF6kaluvihjeet",
  callout: "Huomautus",
  markers: "Merkit",
  shadow: "Varjo",
  blur: "Sumennus",
  xOffset: "X-siirtym\xE4",
  yOffset: "Y-siirtym\xE4",
  lineWidth: "Viivan leveys",
  lineDash: "Viivan viivaus",
  lineDashOffset: "Viivaus siirtym\xE4",
  scrollingZoom: "Vieritys",
  scrollingStep: "Vieritys askel",
  selectingZoom: "Valinta",
  durationMillis: "Kesto (ms)",
  crosshairLabel: "Tunniste",
  crosshairSnap: "Napsauta solmuun",
  normal: "Normaali",
  bold: "Lihavoitu",
  italic: "Kursiivi",
  boldItalic: "Lihavoitu kursiivi",
  predefined: "Ennalta m\xE4\xE4ritelty",
  fillOpacity: "T\xE4ytt\xF6peitt\xE4vyys",
  strokeColor: "Viivav\xE4ri",
  strokeOpacity: "Viivapeitt\xE4vyys",
  miniChart: "Mini-kaavio",
  histogramBinCount: "Bin count",
  connectorLine: "Liit\xE4nt\xE4viiva",
  seriesItems: "Sarjan kohteet",
  seriesItemType: "Kohteen tyyppi",
  seriesItemPositive: "Positiivinen",
  seriesItemNegative: "Negatiivinen",
  seriesItemLabels: "Kohteen tunnisteet",
  columnGroup: "Pylv\xE4s",
  barGroup: "Palkki",
  pieGroup: "Piirakka",
  lineGroup: "Viiva",
  scatterGroup: "X Y (Hajanaisuus)",
  areaGroup: "Alue",
  polarGroup: "Polaarinen",
  statisticalGroup: "Tilastollinen",
  hierarchicalGroup: "Hierarkkinen",
  specializedGroup: "Erikoistunut",
  combinationGroup: "Yhdistelm\xE4",
  groupedColumnTooltip: "Ryhmitetty",
  stackedColumnTooltip: "Pinottu",
  normalizedColumnTooltip: "100 % pinottu",
  groupedBarTooltip: "Ryhmitetty",
  stackedBarTooltip: "Pinottu",
  normalizedBarTooltip: "100 % pinottu",
  pieTooltip: "Piirakka",
  donutTooltip: "Donitsi",
  lineTooltip: "Viiva",
  groupedAreaTooltip: "Alue",
  stackedAreaTooltip: "Pinottu",
  normalizedAreaTooltip: "100 % pinottu",
  scatterTooltip: "Hajanaisuus",
  bubbleTooltip: "Kupla",
  histogramTooltip: "Histogrammi",
  radialColumnTooltip: "S\xE4teitt\xE4inen pylv\xE4s",
  radialBarTooltip: "S\xE4teitt\xE4inen palkki",
  radarLineTooltip: "Tutka viiva",
  radarAreaTooltip: "Tutka-alue",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Alue palkki",
  rangeAreaTooltip: "Alue",
  boxPlotTooltip: "Box plot",
  treemapTooltip: "Puukartta",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Vesiputous",
  heatmapTooltip: "L\xE4mp\xF6kartta",
  columnLineComboTooltip: "Pylv\xE4s & viiva",
  areaColumnComboTooltip: "Alue & pylv\xE4s",
  customComboTooltip: "Mukautettu yhdistelm\xE4",
  innerRadius: "Sis\xE4inen s\xE4de",
  startAngle: "Alkukulma",
  endAngle: "P\xE4\xE4tekulma",
  reverseDirection: "K\xE4\xE4nteinen suunta",
  groupPadding: "Ryhm\xE4 T\xE4ytt\xF6",
  seriesPadding: "Sarjan T\xE4ytt\xF6",
  tile: "Laatoittaa",
  whisker: "Viikset",
  cap: "Korkki",
  capLengthRatio: "Pituusosuus",
  labelPlacement: "Sijoitus",
  inside: "Sis\xE4ll\xE4",
  outside: "Ulkopuolella",
  noDataToChart: "Ei dataa kaavioon.",
  pivotChartRequiresPivotMode: "Pivot-kaavio tarvitsee Pivot-tilan aktivoidun.",
  chartSettingsToolbarTooltip: "Valikko",
  chartLinkToolbarTooltip: "Linkitetty ruudukkoon",
  chartUnlinkToolbarTooltip: "Irrotettu ruudukosta",
  chartDownloadToolbarTooltip: "Lataa kaavio",
  chartMenuToolbarTooltip: "Valikko",
  chartEdit: "Muokkaa kaaviota",
  chartAdvancedSettings: "Edistyneemm\xE4t asetukset",
  chartLink: "Linkit\xE4 ruudukkoon",
  chartUnlink: "Irrota ruudukosta",
  chartDownload: "Lataa kaavio",
  histogramFrequency: "Taajuus",
  seriesChartType: "Sarjakaavion tyyppi",
  seriesType: "Sarjan tyyppi",
  secondaryAxis: "Toissijainen akseli",
  seriesAdd: "Lis\xE4\xE4 sarjat",
  categoryAdd: "Lis\xE4\xE4 luokka",
  bar: "Palkki",
  column: "Pylv\xE4s",
  histogram: "Histogrammi",
  advancedSettings: "Edistyneemm\xE4t asetukset",
  direction: "Suunta",
  horizontal: "Vaakasuora",
  vertical: "Pystysuora",
  seriesGroupType: "Ryhm\xE4tyyppi",
  groupedSeriesGroupType: "Ryhmitetty",
  stackedSeriesGroupType: "Pinottu",
  normalizedSeriesGroupType: "100 % pinottu",
  legendEnabled: "K\xE4yt\xF6ss\xE4",
  invalidColor: "V\xE4riarvo on virheellinen",
  groupedColumnFull: "Ryhmitetty pylv\xE4s",
  stackedColumnFull: "Pinottu pylv\xE4s",
  normalizedColumnFull: "100 % pinottu pylv\xE4s",
  groupedBarFull: "Ryhmitetty palkki",
  stackedBarFull: "Pinottu palkki",
  normalizedBarFull: "100 % pinottu palkki",
  stackedAreaFull: "Pinottu alue",
  normalizedAreaFull: "100 % pinottu alue",
  customCombo: "Mukautettu yhdistelm\xE4",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Taso ${variable}. Paina ENTER muokataksesi.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Taso ${variable}. ${variable} Paina ENTER muokataksesi.",
  ariaAdvancedFilterBuilderList: "Tarkennettu suodattimen rakentaja lista",
  ariaAdvancedFilterBuilderFilterItem: "Suodatusehto",
  ariaAdvancedFilterBuilderGroupItem: "Suodatuksen ryhm\xE4",
  ariaAdvancedFilterBuilderColumn: "Sarake",
  ariaAdvancedFilterBuilderOption: "Vaihtoehto",
  ariaAdvancedFilterBuilderValueP: "Arvo",
  ariaAdvancedFilterBuilderJoinOperator: "Liitosoperaattori",
  ariaAdvancedFilterInput: "Tarkennettu suodattimen sy\xF6te",
  ariaChecked: "valittu",
  ariaColumn: "Sarake",
  ariaColumnGroup: "Sarakeryhm\xE4",
  ariaColumnFiltered: "Suodatettu sarake",
  ariaColumnSelectAll: "Vaihda kaikkien sarakkeiden valinta",
  ariaDateFilterInput: "P\xE4iv\xE4m\xE4\xE4r\xE4n suodattimen sy\xF6te",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Suodata sarakkeiden sy\xF6te",
  ariaFilterFromValue: "Suodata arvosta",
  ariaFilterInput: "Suodattimen sy\xF6te",
  ariaFilterList: "Suodatinlista",
  ariaFilterToValue: "Suodata arvoon",
  ariaFilterValue: "Suodattimen arvo",
  ariaFilterMenuOpen: "Avaa suodattimen valikko",
  ariaFilteringOperator: "Suodatuksen operaattori",
  ariaHidden: "piilotettu",
  ariaIndeterminate: "ep\xE4m\xE4\xE4r\xE4inen",
  ariaInputEditor: "Sy\xF6tteen muokkaaja",
  ariaMenuColumn: "Paina ALT ALAS avataksesi sarakevalikon",
  ariaFilterColumn: "Paina CTRL ENTER avataksesi suodattimen",
  ariaRowDeselect: "Paina V\xC4LILY\xD6NTI poistaaksesi t\xE4m\xE4n rivin valinnan",
  ariaRowSelectAll: "Paina V\xC4LILY\xD6NTI vaihtaaksesi kaikkien rivien valintaa",
  ariaRowToggleSelection: "Paina V\xC4LILY\xD6NTI vaihtaaksesi rivin valintaa",
  ariaRowSelect: "Paina V\xC4LILY\xD6NTI valitaksesi t\xE4m\xE4n rivin",
  ariaRowSelectionDisabled: "Rivin valinta on poistettu k\xE4yt\xF6st\xE4 t\xE4lle riville",
  ariaSearch: "Haku",
  ariaSortableColumn: "Paina ENTER lajitellaksesi",
  ariaToggleVisibility: "Paina V\xC4LILY\xD6NTI vaihtaaksesi n\xE4kyvyytt\xE4",
  ariaToggleCellValue: "Paina V\xC4LILY\xD6NTI vaihtaaksesi solun arvoa",
  ariaUnchecked: "valitsematon",
  ariaVisible: "n\xE4kyv\xE4",
  ariaSearchFilterValues: "Etsi suodattimen arvoja",
  ariaPageSizeSelectorLabel: "Sivun koko",
  ariaChartMenuClose: "Sulje kaavion muokkausvalikko",
  ariaChartSelected: "Valittu",
  ariaSkeletonCellLoadingFailed: "Rivin lataus ep\xE4onnistui",
  ariaSkeletonCellLoading: "Rivin tietoja ladataan",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Riviryhm\xE4t",
  ariaValuesDropZonePanelLabel: "Arvot",
  ariaPivotDropZonePanelLabel: "Sarakkeet",
  ariaDropZoneColumnComponentDescription: "Poista painamalla DELETE",
  ariaDropZoneColumnValueItemDescription: "Vaihda aggregaatiotyyppi\xE4 painamalla ENTER",
  ariaDropZoneColumnGroupItemDescription: "J\xE4rjest\xE4 painamalla ENTER",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " kohta ",
  ariaDropZoneColumnComponentSortAscending: "nouseva",
  ariaDropZoneColumnComponentSortDescending: "laskeva",
  ariaLabelDialog: "Dialogi",
  ariaLabelColumnMenu: "Sarakkeen valikko",
  ariaLabelColumnFilter: "Sarakkeen suodatin",
  ariaLabelCellEditor: "Solun muokkaus",
  ariaLabelSelectField: "Valitse kentt\xE4",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rikas valintakentt\xE4",
  ariaLabelRichSelectToggleSelection: "Paina V\xC4LILY\xD6NTI\xC4 vaihtaaksesi valintaa",
  ariaLabelRichSelectDeselectAllItems: "Paina DELETE poistaaksesi kaikkien kohteiden valinnan",
  ariaLabelRichSelectDeleteSelection: "Paina DELETE poistaaksesi kohteen valinnan",
  ariaLabelTooltip: "Vihje",
  ariaLabelContextMenu: "Kontekstivalikko",
  ariaLabelSubMenu: "Alivalikko",
  ariaLabelAggregationFunction: "Aggregointitoiminto",
  ariaLabelAdvancedFilterAutocomplete: "Edistynyt suodatin automaattinen t\xE4ydennys",
  ariaLabelAdvancedFilterBuilderAddField: "Edistynyt suodattimen luonti lis\xE4\xE4 kentt\xE4",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Edistynyt suodattimen luonti sarakkeen valintakentt\xE4",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Edistynyt suodattimen luonti vaihtoehdon valintakentt\xE4",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Edistynyt suodattimen luonti liittymisoperaattorin valintakentt\xE4",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Sarakeluettelo",
  ariaFilterPanelList: "Suodatinluettelo",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: " ",
  decimalSeparator: ",",
  // Data types
  true: "Totta",
  false: "V\xE4\xE4rin",
  invalidDate: "Virheellinen p\xE4iv\xE4m\xE4\xE4r\xE4",
  invalidNumber: "Virheellinen numero",
  january: "Tammikuu",
  february: "Helmikuu",
  march: "Maaliskuu",
  april: "Huhtikuu",
  may: "Toukokuu",
  june: "Kes\xE4kuu",
  july: "Hein\xE4kuu",
  august: "Elokuu",
  september: "Syyskuu",
  october: "Lokakuu",
  november: "Marraskuu",
  december: "Joulukuu",
  // Time formats
  timeFormatSlashesDDMMYYYY: "PP/KK/VVVV",
  timeFormatSlashesMMDDYYYY: "KK/PP/VVVV",
  timeFormatSlashesDDMMYY: "PP/KK/VV",
  timeFormatSlashesMMDDYY: "KK/PP/VV",
  timeFormatDotsDDMYY: "PP.K.VV",
  timeFormatDotsMDDYY: "K.PP.VV",
  timeFormatDashesYYYYMMDD: "VVVV-KK-PP",
  timeFormatSpacesDDMMMMYYYY: "PP KKKK VVVV",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/fr-FR.ts
var AG_GRID_LOCALE_FR = {
  // Set Filter
  selectAll: "(S\xE9lectionner tout)",
  selectAllSearchResults: "(S\xE9lectionner tous les r\xE9sultats de recherche)",
  addCurrentSelectionToFilter: "Ajouter la s\xE9lection actuelle au filtre",
  searchOoo: "Chercher...",
  blanks: "(Vides)",
  noMatches: "Aucune correspondance",
  // Number Filter & Text Filter
  filterOoo: "Filtrer...",
  equals: "\xC9gal \xE0",
  notEqual: "N'est pas \xE9gal \xE0",
  blank: "Vide",
  notBlank: "Non vide",
  empty: "Choisissez-en un",
  // Number Filter
  lessThan: "Moins de",
  greaterThan: "Plus de",
  lessThanOrEqual: "Moins de ou \xE9gal \xE0",
  greaterThanOrEqual: "Plus de ou \xE9gal \xE0",
  inRange: "Entre",
  inRangeStart: "De",
  inRangeEnd: "\xC0",
  // Text Filter
  contains: "Contient",
  notContains: "Ne contient pas",
  startsWith: "Commence par",
  endsWith: "Se termine par",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Avant",
  after: "Apr\xE8s",
  // Filter Conditions
  andCondition: "ET",
  orCondition: "OU",
  // Filter Buttons
  applyFilter: "Appliquer",
  resetFilter: "R\xE9initialiser",
  clearFilter: "Effacer",
  cancelFilter: "Annuler",
  // Filter Titles
  textFilter: "Filtre de texte",
  numberFilter: "Filtre num\xE9rique",
  dateFilter: "Filtre de date",
  setFilter: "Filtre de jeu",
  // Group Column Filter
  groupFilterSelect: "S\xE9lectionner le champ :",
  // Advanced Filter
  advancedFilterContains: "contient",
  advancedFilterNotContains: "ne contient pas",
  advancedFilterTextEquals: "\xE9gal",
  advancedFilterTextNotEqual: "n'est pas \xE9gal",
  advancedFilterStartsWith: "commence par",
  advancedFilterEndsWith: "se termine par",
  advancedFilterBlank: "est vide",
  advancedFilterNotBlank: "n'est pas vide",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "est vrai",
  advancedFilterFalse: "est faux",
  advancedFilterAnd: "ET",
  advancedFilterOr: "OU",
  advancedFilterApply: "Appliquer",
  advancedFilterBuilder: "Constructeur",
  advancedFilterValidationMissingColumn: "La colonne est manquante",
  advancedFilterValidationMissingOption: "L'option est manquante",
  advancedFilterValidationMissingValue: "La valeur est manquante",
  advancedFilterValidationInvalidColumn: "Colonne non trouv\xE9e",
  advancedFilterValidationInvalidOption: "Option non trouv\xE9e",
  advancedFilterValidationMissingQuote: "Il manque une guillemet de fin \xE0 la valeur",
  advancedFilterValidationNotANumber: "La valeur n'est pas un nombre",
  advancedFilterValidationInvalidDate: "La valeur n'est pas une date valide",
  advancedFilterValidationMissingCondition: "La condition est manquante",
  advancedFilterValidationJoinOperatorMismatch: "Les op\xE9rateurs de jonction au sein d'une condition doivent \xEAtre identiques",
  advancedFilterValidationInvalidJoinOperator: "Op\xE9rateur de jonction non trouv\xE9",
  advancedFilterValidationMissingEndBracket: "Guillemet de fin manquante",
  advancedFilterValidationExtraEndBracket: "Trop de crochets de fin",
  advancedFilterValidationMessage: "L'expression contient une erreur. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "L'expression contient une erreur. ${variable} \xE0 la fin de l'expression.",
  advancedFilterBuilderTitle: "Filtre Avanc\xE9",
  advancedFilterBuilderApply: "Appliquer",
  advancedFilterBuilderCancel: "Annuler",
  advancedFilterBuilderAddButtonTooltip: "Ajouter un filtre ou un groupe",
  advancedFilterBuilderRemoveButtonTooltip: "Supprimer",
  advancedFilterBuilderMoveUpButtonTooltip: "Monter",
  advancedFilterBuilderMoveDownButtonTooltip: "Descendre",
  advancedFilterBuilderAddJoin: "Ajouter un groupe",
  advancedFilterBuilderAddCondition: "Ajouter un filtre",
  advancedFilterBuilderSelectColumn: "S\xE9lectionner une colonne",
  advancedFilterBuilderSelectOption: "S\xE9lectionner une option",
  advancedFilterBuilderEnterValue: "Entrer une valeur...",
  advancedFilterBuilderValidationAlreadyApplied: "Le filtre actuel est d\xE9j\xE0 appliqu\xE9.",
  advancedFilterBuilderValidationIncomplete: "Toutes les conditions ne sont pas remplies.",
  advancedFilterBuilderValidationSelectColumn: "Vous devez s\xE9lectionner une colonne.",
  advancedFilterBuilderValidationSelectOption: "Vous devez s\xE9lectionner une option.",
  advancedFilterBuilderValidationEnterValue: "Vous devez entrer une valeur.",
  // Side Bar
  columns: "Colonnes",
  filters: "Filtres",
  // columns tool panel
  pivotMode: "Mode pivot",
  groups: "Groupes de lignes",
  rowGroupColumnsEmptyMessage: "Faites glisser ici pour d\xE9finir les groupes de lignes",
  values: "Valeurs",
  valueColumnsEmptyMessage: "Faites glisser ici pour agr\xE9ger",
  pivots: "\xC9tiquettes de colonnes",
  pivotColumnsEmptyMessage: "Faites glisser ici pour d\xE9finir les \xE9tiquettes de colonnes",
  // Header of the Default Group Column
  group: "Groupe",
  // Row Drag
  rowDragRow: "rang",
  rowDragRows: "rangs",
  // Other
  loadingOoo: "Chargement...",
  loadingError: "ERR",
  noRowsToShow: "Aucune ligne \xE0 afficher",
  enabled: "Activ\xE9",
  // Menu
  pinColumn: "\xC9pingler la colonne",
  pinLeft: "\xC9pingler \xE0 gauche",
  pinRight: "\xC9pingler \xE0 droite",
  noPin: "Pas d'\xE9pingle",
  valueAggregation: "Agr\xE9gation de valeur",
  noAggregation: "Aucun",
  autosizeThisColumn: "Ajuster automatiquement cette colonne",
  autosizeAllColumns: "Ajuster automatiquement toutes les colonnes",
  groupBy: "Grouper par",
  ungroupBy: "D\xE9grouper par",
  ungroupAll: "D\xE9grouper tout",
  addToValues: "Ajouter ${variable} aux valeurs",
  removeFromValues: "Retirer ${variable} des valeurs",
  addToLabels: "Ajouter ${variable} aux \xE9tiquettes",
  removeFromLabels: "Retirer ${variable} des \xE9tiquettes",
  resetColumns: "R\xE9initialiser les colonnes",
  expandAll: "D\xE9velopper tous les groupes de lignes",
  collapseAll: "Fermer tous les groupes de lignes",
  copy: "Copier",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copier avec en-t\xEAtes",
  copyWithGroupHeaders: "Copier avec en-t\xEAtes de groupe",
  cut: "Couper",
  paste: "Coller",
  ctrlV: "Ctrl+V",
  export: "Exporter",
  csvExport: "Export CSV",
  excelExport: "Export Excel",
  columnFilter: "Filtre de colonne",
  columnChooser: "Choisir les colonnes",
  chooseColumns: "Choisir les colonnes",
  sortAscending: "Trier par ordre croissant",
  sortDescending: "Trier par ordre d\xE9croissant",
  sortUnSort: "Effacer le tri",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Somme",
  first: "Premier",
  last: "Dernier",
  min: "Min",
  max: "Max",
  none: "Aucun",
  count: "Nombre",
  avg: "Moyenne",
  filteredRows: "Filtr\xE9",
  selectedRows: "S\xE9lectionn\xE9",
  totalRows: "Total des Lignes",
  totalAndFilteredRows: "Lignes",
  more: "Plus",
  to: "\xE0",
  of: "de",
  page: "Page",
  pageLastRowUnknown: "?",
  nextPage: "Page Suivante",
  lastPage: "Derni\xE8re Page",
  firstPage: "Premi\xE8re Page",
  previousPage: "Page Pr\xE9c\xE9dente",
  pageSizeSelectorLabel: "Taille de la Page :",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Graphique pivot et mode pivot",
  pivotChart: "Graphique pivot",
  chartRange: "Plage de graphique",
  columnChart: "Colonne",
  groupedColumn: "Group\xE9",
  stackedColumn: "Empil\xE9",
  normalizedColumn: "100% empil\xE9",
  barChart: "Barre",
  groupedBar: "Group\xE9",
  stackedBar: "Empil\xE9",
  normalizedBar: "100% empil\xE9",
  pieChart: "Camembert",
  pie: "Camembert",
  donut: "Donut",
  line: "Ligne",
  xyChart: "X Y (Nuage de points)",
  scatter: "Nuage de points",
  bubble: "Bulle",
  areaChart: "Aire",
  area: "Aire",
  stackedArea: "Empil\xE9",
  normalizedArea: "100% empil\xE9",
  histogramChart: "Histogramme",
  polarChart: "Polaire",
  radarLine: "Ligne radar",
  radarArea: "Aire radar",
  nightingale: "Nightingale",
  radialColumn: "Colonne radiale",
  radialBar: "Barre radiale",
  statisticalChart: "Statistique",
  boxPlot: "Bo\xEEte \xE0 moustaches",
  rangeBar: "Barre de plage",
  rangeArea: "Aire de plage",
  hierarchicalChart: "Hi\xE9rarchique",
  treemap: "Arborescence",
  sunburst: "Rayonnement",
  specializedChart: "Sp\xE9cialis\xE9",
  waterfall: "Cascade",
  heatmap: "Carte thermique",
  combinationChart: "Combinaison",
  columnLineCombo: "Colonne et ligne",
  AreaColumnCombo: "Aire et colonne",
  // Charts
  pivotChartTitle: "Graphique crois\xE9 dynamique",
  rangeChartTitle: "Graphique en plage",
  settings: "Graphique",
  data: "Configurer",
  format: "Personnaliser",
  categories: "Cat\xE9gories",
  defaultCategory: "(Aucun)",
  series: "S\xE9ries",
  switchCategorySeries: "Changer Cat\xE9gorie / S\xE9ries",
  categoryValues: "Valeurs de cat\xE9gorie",
  seriesLabels: "\xC9tiquettes de s\xE9ries",
  aggregate: "Agr\xE9gat",
  xyValues: "Valeurs X Y",
  paired: "Mode appari\xE9",
  axis: "Axe",
  xAxis: "Axe horizontal",
  yAxis: "Axe vertical",
  polarAxis: "Axe polaire",
  radiusAxis: "Axe de rayon",
  navigator: "Navigateur",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "R\xE9ticule",
  color: "Couleur",
  thickness: "\xC9paisseur",
  preferredLength: "Longueur pr\xE9f\xE9r\xE9e",
  xType: "Type X",
  axisType: "Type d'axe",
  automatic: "Automatique",
  category: "Cat\xE9gorie",
  number: "Nombre",
  time: "Temps",
  timeFormat: "Format de temps",
  autoRotate: "Rotation auto",
  labelRotation: "Rotation",
  circle: "Cercle",
  polygon: "Polygone",
  square: "Carr\xE9",
  cross: "Croix",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Triangle",
  heart: "C\u0153ur",
  orientation: "Orientation",
  fixed: "Fix\xE9",
  parallel: "Parall\xE8le",
  perpendicular: "Perpendiculaire",
  radiusAxisPosition: "Position",
  ticks: "Graduations",
  gridLines: "Lignes de grille",
  width: "Largeur",
  height: "Hauteur",
  length: "Longueur",
  padding: "Rembourrage",
  spacing: "Espacement",
  chartStyle: "Style de graphique",
  title: "Titre",
  chartTitles: "Titres",
  chartTitle: "Titre du graphique",
  chartSubtitle: "Sous-titre",
  horizontalAxisTitle: "Titre de l'axe horizontal",
  verticalAxisTitle: "Titre de l'axe vertical",
  polarAxisTitle: "Titre de l'axe polaire",
  titlePlaceholder: "Titre du graphique",
  background: "Arri\xE8re-plan",
  font: "Police",
  weight: "Poids",
  top: "Haut",
  right: "Droite",
  bottom: "Bas",
  left: "Gauche",
  labels: "\xC9tiquettes",
  calloutLabels: "\xC9tiquettes d'appel",
  sectorLabels: "\xC9tiquettes de secteur",
  positionRatio: "Ratio de position",
  size: "Taille",
  shape: "Forme",
  minSize: "Taille minimum",
  maxSize: "Taille maximum",
  legend: "L\xE9gende",
  position: "Position",
  markerSize: "Taille du marqueur",
  markerStroke: "Contour du marqueur",
  markerPadding: "Rembourrage du marqueur",
  itemSpacing: "Espacement des \xE9l\xE9ments",
  itemPaddingX: "Rembourrage d'\xE9l\xE9ment X",
  itemPaddingY: "Rembourrage d'\xE9l\xE9ment Y",
  layoutHorizontalSpacing: "Espacement horizontal",
  layoutVerticalSpacing: "Espacement vertical",
  strokeWidth: "\xC9paisseur du trait",
  offset: "D\xE9calage",
  offsets: "D\xE9calages",
  tooltips: "Info-bulles",
  callout: "Appel",
  markers: "Marqueurs",
  shadow: "Ombre",
  blur: "Flou",
  xOffset: "D\xE9calage X",
  yOffset: "D\xE9calage Y",
  lineWidth: "\xC9paisseur de la ligne",
  lineDash: "Tirets de la ligne",
  lineDashOffset: "D\xE9calage des tirets",
  scrollingZoom: "D\xE9filement",
  scrollingStep: "\xC9tape de d\xE9filement",
  selectingZoom: "S\xE9lection",
  durationMillis: "Dur\xE9e (ms)",
  crosshairLabel: "\xC9tiquette",
  crosshairSnap: "Accrocher au n\u0153ud",
  normal: "Normal",
  bold: "Gras",
  italic: "Italique",
  boldItalic: "Gras Italique",
  predefined: "Pr\xE9d\xE9fini",
  fillOpacity: "Opacit\xE9 de remplissage",
  strokeColor: "Couleur du trait",
  strokeOpacity: "Opacit\xE9 du trait",
  miniChart: "Mini-Graphique",
  histogramBinCount: "Nombre de bins",
  connectorLine: "Ligne de connexion",
  seriesItems: "\xC9l\xE9ments de s\xE9rie",
  seriesItemType: "Type d'\xE9l\xE9ment",
  seriesItemPositive: "Positif",
  seriesItemNegative: "N\xE9gatif",
  seriesItemLabels: "\xC9tiquettes d'\xE9l\xE9ments",
  columnGroup: "Colonne",
  barGroup: "Barre",
  pieGroup: "Camembert",
  lineGroup: "Ligne",
  scatterGroup: "X Y (Nuage de points)",
  areaGroup: "Zone",
  polarGroup: "Polaire",
  statisticalGroup: "Statistique",
  hierarchicalGroup: "Hi\xE9rarchique",
  specializedGroup: "Sp\xE9cialis\xE9",
  combinationGroup: "Combinaison",
  groupedColumnTooltip: "Groupe",
  stackedColumnTooltip: "Empil\xE9",
  normalizedColumnTooltip: "100% Empil\xE9",
  groupedBarTooltip: "Groupe",
  stackedBarTooltip: "Empil\xE9",
  normalizedBarTooltip: "100% Empil\xE9",
  pieTooltip: "Camembert",
  donutTooltip: "Donut",
  lineTooltip: "Ligne",
  groupedAreaTooltip: "Zone",
  stackedAreaTooltip: "Empil\xE9",
  normalizedAreaTooltip: "100% Empil\xE9",
  scatterTooltip: "Nuage de points",
  bubbleTooltip: "Bulle",
  histogramTooltip: "Histogramme",
  radialColumnTooltip: "Colonne radiale",
  radialBarTooltip: "Barre radiale",
  radarLineTooltip: "Ligne radar",
  radarAreaTooltip: "Zone radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Barre de plage",
  rangeAreaTooltip: "Zone de plage",
  boxPlotTooltip: "Bo\xEEte \xE0 moustaches",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Rayon de soleil",
  waterfallTooltip: "Chute d'eau",
  heatmapTooltip: "Carte thermique",
  columnLineComboTooltip: "Colonne & Ligne",
  areaColumnComboTooltip: "Zone & Colonne",
  customComboTooltip: "Combinaison Personnalis\xE9e",
  innerRadius: "Rayon int\xE9rieur",
  startAngle: "Angle de d\xE9part",
  endAngle: "Angle de fin",
  reverseDirection: "Inverser la direction",
  groupPadding: "Rembourrage de groupe",
  seriesPadding: "Rembourrage de s\xE9ries",
  tile: "Tuile",
  whisker: "Moustache",
  cap: "Capuchon",
  capLengthRatio: "Ratio de longueur",
  labelPlacement: "Placement",
  inside: "\xC0 l'int\xE9rieur",
  outside: "\xC0 l'ext\xE9rieur",
  noDataToChart: "Aucune donn\xE9e disponible \xE0 afficher.",
  pivotChartRequiresPivotMode: "Le graphique crois\xE9 dynamique n\xE9cessite le mode crois\xE9 activ\xE9.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Li\xE9 \xE0 la grille",
  chartUnlinkToolbarTooltip: "D\xE9tach\xE9 de la grille",
  chartDownloadToolbarTooltip: "T\xE9l\xE9charger le graphique",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Modifier le graphique",
  chartAdvancedSettings: "Param\xE8tres avanc\xE9s",
  chartLink: "Lier \xE0 la grille",
  chartUnlink: "D\xE9tacher de la grille",
  chartDownload: "T\xE9l\xE9charger le graphique",
  histogramFrequency: "Fr\xE9quence",
  seriesChartType: "Type de graphique de s\xE9ries",
  seriesType: "Type de s\xE9rie",
  secondaryAxis: "Axe secondaire",
  seriesAdd: "Ajouter une s\xE9rie",
  categoryAdd: "Ajouter une cat\xE9gorie",
  bar: "Barre",
  column: "Colonne",
  histogram: "Histogramme",
  advancedSettings: "Param\xE8tres avanc\xE9s",
  direction: "Direction",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Type de groupe",
  groupedSeriesGroupType: "Group\xE9",
  stackedSeriesGroupType: "Empil\xE9",
  normalizedSeriesGroupType: "100% Empil\xE9",
  legendEnabled: "Activ\xE9",
  invalidColor: "La valeur de couleur est invalide",
  groupedColumnFull: "Colonne group\xE9e",
  stackedColumnFull: "Colonne empil\xE9e",
  normalizedColumnFull: "Colonne 100% empil\xE9e",
  groupedBarFull: "Barre group\xE9e",
  stackedBarFull: "Barre empil\xE9e",
  normalizedBarFull: "Barre 100% empil\xE9e",
  stackedAreaFull: "Zone empil\xE9e",
  normalizedAreaFull: "Zone 100% empil\xE9e",
  customCombo: "Combinaison personnalis\xE9e",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Niveau ${variable}. Appuyez sur ENTER pour \xE9diter.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Niveau ${variable}. ${variable} Appuyez sur ENTER pour \xE9diter.",
  ariaAdvancedFilterBuilderList: "Liste de cadre de filtre avanc\xE9",
  ariaAdvancedFilterBuilderFilterItem: "Condition de filtre",
  ariaAdvancedFilterBuilderGroupItem: "Groupe de filtre",
  ariaAdvancedFilterBuilderColumn: "Colonne",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValueP: "Valeur",
  ariaAdvancedFilterBuilderJoinOperator: "Op\xE9rateur de jonction",
  ariaAdvancedFilterInput: "Entr\xE9e de filtre avanc\xE9",
  ariaChecked: "coch\xE9",
  ariaColumn: "Colonne",
  ariaColumnGroup: "Groupe de colonnes",
  ariaColumnFiltered: "Colonne filtr\xE9e",
  ariaColumnSelectAll: "Basculer la s\xE9lection de toutes les colonnes",
  ariaDateFilterInput: "Entr\xE9e de filtre de date",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "Entr\xE9e de filtre de colonnes",
  ariaFilterFromValue: "Filtrer depuis la valeur",
  ariaFilterInput: "Entr\xE9e de filtre",
  ariaFilterList: "Liste de filtres",
  ariaFilterToValue: "Filtrer jusqu'\xE0 la valeur",
  ariaFilterValue: "Valeur du filtre",
  ariaFilterMenuOpen: "Ouvrir le menu de filtre",
  ariaFilteringOperator: "Op\xE9rateur de filtrage",
  ariaHidden: "cach\xE9",
  ariaIndeterminate: "ind\xE9termin\xE9",
  ariaInputEditor: "\xC9diteur d'entr\xE9e",
  ariaMenuColumn: "Appuyez sur ALT BAS pour ouvrir le menu de colonne",
  ariaFilterColumn: "Appuyez sur CTRL ENTER pour ouvrir le filtre",
  ariaRowDeselect: "Appuyez sur ESPACE pour d\xE9s\xE9lectionner cette ligne",
  ariaRowSelectAll: "Appuyez sur ESPACE pour basculer la s\xE9lection de toutes les lignes",
  ariaRowToggleSelection: "Appuyez sur ESPACE pour basculer la s\xE9lection de la ligne",
  ariaRowSelect: "Appuyez sur ESPACE pour s\xE9lectionner cette ligne",
  ariaRowSelectionDisabled: "La s\xE9lection de lignes est d\xE9sactiv\xE9e pour cette ligne",
  ariaSearch: "Rechercher",
  ariaSortableColumn: "Appuyez sur ENTER pour trier",
  ariaToggleVisibility: "Appuyez sur ESPACE pour basculer la visibilit\xE9",
  ariaToggleCellValue: "Appuyez sur ESPACE pour basculer la valeur de la cellule",
  ariaUnchecked: "non coch\xE9",
  ariaVisible: "visible",
  ariaSearchFilterValues: "Rechercher les valeurs de filtre",
  ariaPageSizeSelectorLabel: "Taille de page",
  ariaChartMenuClose: "Fermer le menu d'\xE9dition de graphique",
  ariaChartSelected: "S\xE9lectionn\xE9",
  ariaSkeletonCellLoadingFailed: "Le chargement de la ligne a \xE9chou\xE9",
  ariaSkeletonCellLoading: "Les donn\xE9es de la ligne sont en cours de chargement",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Groupes de lignes",
  ariaValuesDropZonePanelLabel: "Valeurs",
  ariaPivotDropZonePanelLabel: "\xC9tiquettes de colonnes",
  ariaDropZoneColumnComponentDescription: "Appuyez sur SUPPRIMER pour enlever",
  ariaDropZoneColumnValueItemDescription: "Appuyez sur ENTR\xC9E pour changer le type d'agr\xE9gation",
  ariaDropZoneColumnGroupItemDescription: "Appuyez sur ENTR\xC9E pour trier",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " de ",
  ariaDropZoneColumnComponentSortAscending: "ascendant",
  ariaDropZoneColumnComponentSortDescending: "descendant",
  ariaLabelDialog: "Dialogue",
  ariaLabelColumnMenu: "Menu de colonne",
  ariaLabelColumnFilter: "Filtre de colonne",
  ariaLabelCellEditor: "\xC9diteur de cellule",
  ariaLabelSelectField: "Champ de s\xE9lection",
  // aria labels for rich select
  ariaLabelRichSelectField: "Champ de s\xE9lection enrichie",
  ariaLabelRichSelectToggleSelection: "Appuyez sur ESPACE pour basculer la s\xE9lection",
  ariaLabelRichSelectDeselectAllItems: "Appuyez sur SUPPRIMER pour d\xE9s\xE9lectionner tous les \xE9l\xE9ments",
  ariaLabelRichSelectDeleteSelection: "Appuyez sur SUPPRIMER pour d\xE9s\xE9lectionner l'\xE9l\xE9ment",
  ariaLabelTooltip: "Info-bulle",
  ariaLabelContextMenu: "Menu contextuel",
  ariaLabelSubMenu: "Sous-menu",
  ariaLabelAggregationFunction: "Fonction d'agr\xE9gation",
  ariaLabelAdvancedFilterAutocomplete: "Filtre avanc\xE9 auto-compl\xE9tion",
  ariaLabelAdvancedFilterBuilderAddField: "Filtre avanc\xE9 constructeur Ajouter un champ",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Filtre avanc\xE9 constructeur S\xE9lectionnez le champ de colonne",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Filtre avanc\xE9 constructeur S\xE9lectionnez le champ d'option",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Filtre avanc\xE9 constructeur S\xE9lectionnez le champ de l'op\xE9rateur de jointure",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Liste des colonnes",
  ariaFilterPanelList: "Liste des filtres",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Vrai",
  false: "Faux",
  invalidDate: "Date invalide",
  invalidNumber: "Nombre invalide",
  january: "Janvier",
  february: "F\xE9vrier",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Ao\xFBt",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "D\xE9cembre",
  // Time formats
  timeFormatSlashesDDMMYYYY: "JJ/MM/AAAA",
  timeFormatSlashesMMDDYYYY: "MM/JJ/AAAA",
  timeFormatSlashesDDMMYY: "JJ/MM/AA",
  timeFormatSlashesMMDDYY: "MM/JJ/AA",
  timeFormatDotsDDMYY: "JJ.M.AA",
  timeFormatDotsMDDYY: "M.JJ.AA",
  timeFormatDashesYYYYMMDD: "AAAA-MM-JJ",
  timeFormatSpacesDDMMMMYYYY: "JJ MMMM AAAA",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/he-IL.ts
var AG_GRID_LOCALE_IL = {
  // Set Filter
  selectAll: "(\u05D1\u05D7\u05E8 \u05D4\u05DB\u05DC)",
  selectAllSearchResults: "(\u05D1\u05D7\u05E8 \u05D0\u05EA \u05DB\u05DC \u05EA\u05D5\u05E6\u05D0\u05D5\u05EA \u05D4\u05D7\u05D9\u05E4\u05D5\u05E9)",
  addCurrentSelectionToFilter: "\u05D4\u05D5\u05E1\u05E3 \u05D0\u05EA \u05D4\u05D1\u05D7\u05D9\u05E8\u05D4 \u05D4\u05E0\u05D5\u05DB\u05D7\u05D9\u05EA \u05DC\u05E1\u05D9\u05E0\u05D5\u05DF",
  searchOoo: "\u05D7\u05E4\u05E9...",
  blanks: "(\u05E8\u05D9\u05E7\u05D9\u05DD)",
  noMatches: "\u05D0\u05D9\u05DF \u05D4\u05EA\u05D0\u05DE\u05D5\u05EA",
  // Number Filter & Text Filter
  filterOoo: "\u05E1\u05D9\u05E0\u05D5\u05DF...",
  equals: "\u05E9\u05D5\u05D5\u05D4",
  notEqual: "\u05DC\u05D0 \u05E9\u05D5\u05D5\u05D4",
  blank: "\u05E8\u05D9\u05E7",
  notBlank: "\u05DC\u05D0 \u05E8\u05D9\u05E7",
  empty: "\u05D1\u05D7\u05E8 \u05D0\u05D7\u05D3",
  // Number Filter
  lessThan: "\u05E4\u05D7\u05D5\u05EA \u05DE",
  greaterThan: "\u05D9\u05D5\u05EA\u05E8 \u05DE",
  lessThanOrEqual: "\u05E4\u05D7\u05D5\u05EA \u05D0\u05D5 \u05E9\u05D5\u05D5\u05D4 \u05DC",
  greaterThanOrEqual: "\u05D9\u05D5\u05EA\u05E8 \u05D0\u05D5 \u05E9\u05D5\u05D5\u05D4 \u05DC",
  inRange: "\u05D1\u05D9\u05DF",
  inRangeStart: "\u05DE",
  inRangeEnd: "\u05E2\u05D3",
  // Text Filter
  contains: "\u05DE\u05DB\u05D9\u05DC",
  notContains: "\u05D0\u05D9\u05E0\u05D5 \u05DE\u05DB\u05D9\u05DC",
  startsWith: "\u05DE\u05EA\u05D7\u05D9\u05DC \u05D1",
  endsWith: "\u05DE\u05E1\u05EA\u05D9\u05D9\u05DD \u05D1",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u05DC\u05E4\u05E0\u05D9",
  after: "\u05D0\u05D7\u05E8\u05D9",
  // Filter Conditions
  andCondition: "\u05D5\u05D2\u05DD",
  orCondition: "\u05D0\u05D5",
  // Filter Buttons
  applyFilter: "\u05D4\u05D7\u05DC",
  resetFilter: "\u05D0\u05E4\u05E1",
  clearFilter: "\u05E0\u05E7\u05D4",
  cancelFilter: "\u05D1\u05D8\u05DC",
  // Filter Titles
  textFilter: "\u05DE\u05E1\u05E0\u05DF \u05D8\u05E7\u05E1\u05D8",
  numberFilter: "\u05DE\u05E1\u05E0\u05DF \u05DE\u05E1\u05E4\u05E8",
  dateFilter: "\u05DE\u05E1\u05E0\u05DF \u05EA\u05D0\u05E8\u05D9\u05DA",
  setFilter: "\u05DE\u05E1\u05E0\u05DF \u05E1\u05D8",
  // Group Column Filter
  groupFilterSelect: "\u05D1\u05D7\u05E8 \u05E9\u05D3\u05D4:",
  // Advanced Filter
  advancedFilterContains: "\u05DE\u05DB\u05D9\u05DC",
  advancedFilterNotContains: "\u05DC\u05D0 \u05DE\u05DB\u05D9\u05DC",
  advancedFilterTextEquals: "\u05E9\u05D5\u05D5\u05D4 \u05DC",
  advancedFilterTextNotEqual: "\u05DC\u05D0 \u05E9\u05D5\u05D5\u05D4 \u05DC",
  advancedFilterStartsWith: "\u05DE\u05EA\u05D7\u05D9\u05DC \u05D1",
  advancedFilterEndsWith: "\u05DE\u05E1\u05EA\u05D9\u05D9\u05DD \u05D1",
  advancedFilterBlank: "\u05E8\u05D9\u05E7",
  advancedFilterNotBlank: "\u05DC\u05D0 \u05E8\u05D9\u05E7",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u05D0\u05DE\u05EA",
  advancedFilterFalse: "\u05E9\u05E7\u05E8",
  advancedFilterAnd: "\u05D5\u05D2\u05DD",
  advancedFilterOr: "\u05D0\u05D5",
  advancedFilterApply: "\u05D4\u05D7\u05DC",
  advancedFilterBuilder: "\u05D1\u05D5\u05E0\u05D4",
  advancedFilterValidationMissingColumn: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05D7\u05E1\u05E8\u05D4",
  advancedFilterValidationMissingOption: "\u05D0\u05E4\u05E9\u05E8\u05D5\u05EA \u05D7\u05E1\u05E8\u05D4",
  advancedFilterValidationMissingValue: "\u05E2\u05E8\u05DA \u05D7\u05E1\u05E8",
  advancedFilterValidationInvalidColumn: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05DC\u05D0 \u05E0\u05DE\u05E6\u05D0\u05D4",
  advancedFilterValidationInvalidOption: "\u05D0\u05E4\u05E9\u05E8\u05D5\u05EA \u05DC\u05D0 \u05E0\u05DE\u05E6\u05D0\u05D4",
  advancedFilterValidationMissingQuote: "\u05DC\u05E2\u05E8\u05DA \u05D7\u05E1\u05E8 \u05E6\u05D9\u05D8\u05D5\u05D8 \u05E1\u05D9\u05D5\u05DD",
  advancedFilterValidationNotANumber: "\u05D4\u05E2\u05E8\u05DA \u05D0\u05D9\u05E0\u05D5 \u05DE\u05E1\u05E4\u05E8",
  advancedFilterValidationInvalidDate: "\u05D4\u05E2\u05E8\u05DA \u05D0\u05D9\u05E0\u05D5 \u05EA\u05D0\u05E8\u05D9\u05DA \u05D7\u05D5\u05E7\u05D9",
  advancedFilterValidationMissingCondition: "\u05D7\u05E1\u05E8 \u05EA\u05E0\u05D0\u05D9",
  advancedFilterValidationJoinOperatorMismatch: "\u05D4\u05DE\u05E4\u05E2\u05D9\u05DC\u05D9\u05DD \u05D1\u05EA\u05E0\u05D0\u05D9 \u05D7\u05D9\u05D9\u05D1\u05D9\u05DD \u05DC\u05D4\u05D9\u05D5\u05EA \u05D0\u05D5\u05EA\u05D5 \u05D3\u05D1\u05E8",
  advancedFilterValidationInvalidJoinOperator: "\u05D0\u05D5\u05E4\u05E8\u05D8\u05D5\u05E8 \u05E6\u05E8\u05D5\u05E3 \u05DC\u05D0 \u05E0\u05DE\u05E6\u05D0",
  advancedFilterValidationMissingEndBracket: "\u05D7\u05E1\u05E8 \u05E1\u05D5\u05D2\u05E8 \u05E1\u05D9\u05D5\u05DD",
  advancedFilterValidationExtraEndBracket: "\u05D9\u05D5\u05EA\u05E8 \u05DE\u05D3\u05D9 \u05E1\u05D5\u05D2\u05E8\u05D9 \u05E1\u05D9\u05D5\u05DD",
  advancedFilterValidationMessage: "\u05D9\u05E9 \u05E9\u05D2\u05D9\u05D0\u05D4 \u05D1\u05D1\u05D9\u05D8\u05D5\u05D9. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u05D9\u05E9 \u05E9\u05D2\u05D9\u05D0\u05D4 \u05D1\u05D1\u05D9\u05D8\u05D5\u05D9. ${variable} \u05D1\u05E1\u05D5\u05E3 \u05D4\u05D1\u05D9\u05D8\u05D5\u05D9.",
  advancedFilterBuilderTitle: "\u05DE\u05E1\u05E0\u05DF \u05DE\u05EA\u05E7\u05D3\u05DD",
  advancedFilterBuilderApply: "\u05D4\u05D7\u05DC",
  advancedFilterBuilderCancel: "\u05D1\u05D8\u05DC",
  advancedFilterBuilderAddButtonTooltip: "\u05D4\u05D5\u05E1\u05E3 \u05DE\u05E1\u05E0\u05DF \u05D0\u05D5 \u05E7\u05D1\u05D5\u05E6\u05D4",
  advancedFilterBuilderRemoveButtonTooltip: "\u05D4\u05E1\u05E8",
  advancedFilterBuilderMoveUpButtonTooltip: "\u05D4\u05E2\u05D1\u05E8 \u05DE\u05E2\u05DC\u05D4",
  advancedFilterBuilderMoveDownButtonTooltip: "\u05D4\u05E2\u05D1\u05E8 \u05DE\u05D8\u05D4",
  advancedFilterBuilderAddJoin: "\u05D4\u05D5\u05E1\u05E3 \u05E7\u05D1\u05D5\u05E6\u05D4",
  advancedFilterBuilderAddCondition: "\u05D4\u05D5\u05E1\u05E3 \u05DE\u05E1\u05E0\u05DF",
  advancedFilterBuilderSelectColumn: "\u05D1\u05D7\u05E8 \u05E2\u05DE\u05D5\u05D3\u05D4",
  advancedFilterBuilderSelectOption: "\u05D1\u05D7\u05E8 \u05D0\u05E4\u05E9\u05E8\u05D5\u05EA",
  advancedFilterBuilderEnterValue: "\u05D4\u05DB\u05E0\u05E1 \u05E2\u05E8\u05DA...",
  advancedFilterBuilderValidationAlreadyApplied: "\u05D4\u05DE\u05E1\u05E0\u05DF \u05D4\u05E0\u05D5\u05DB\u05D7\u05D9 \u05DB\u05D1\u05E8 \u05D4\u05D5\u05D7\u05DC.",
  advancedFilterBuilderValidationIncomplete: "\u05DC\u05D0 \u05DB\u05DC \u05D4\u05EA\u05E0\u05D0\u05D9\u05DD \u05D4\u05D5\u05E9\u05DC\u05DE\u05D5.",
  advancedFilterBuilderValidationSelectColumn: "\u05D7\u05D5\u05D1\u05D4 \u05DC\u05D1\u05D7\u05D5\u05E8 \u05E2\u05DE\u05D5\u05D3\u05D4.",
  advancedFilterBuilderValidationSelectOption: "\u05D7\u05D5\u05D1\u05D4 \u05DC\u05D1\u05D7\u05D5\u05E8 \u05D0\u05E4\u05E9\u05E8\u05D5\u05EA.",
  advancedFilterBuilderValidationEnterValue: "\u05D7\u05D5\u05D1\u05D4 \u05DC\u05D4\u05D6\u05D9\u05DF \u05E2\u05E8\u05DA.",
  // Side Bar
  columns: "\u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  filters: "\u05DE\u05E1\u05E0\u05E0\u05D9\u05DD",
  // columns tool panel
  pivotMode: "\u05DE\u05E6\u05D1 \u05E1\u05D9\u05DB\u05D5\u05DD",
  groups: "\u05E7\u05D1\u05D5\u05E6\u05D5\u05EA \u05E9\u05D5\u05E8\u05D5\u05EA",
  rowGroupColumnsEmptyMessage: "\u05D2\u05E8\u05D5\u05E8 \u05DC\u05DB\u05D0\u05DF \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D2\u05D3\u05D9\u05E8 \u05E7\u05D1\u05D5\u05E6\u05D5\u05EA \u05E9\u05D5\u05E8\u05D5\u05EA",
  values: "\u05E2\u05E8\u05DB\u05D9\u05DD",
  valueColumnsEmptyMessage: "\u05D2\u05E8\u05D5\u05E8 \u05DC\u05DB\u05D0\u05DF \u05DB\u05D3\u05D9 \u05DC\u05D1\u05E6\u05E2 \u05D7\u05D9\u05E9\u05D5\u05D1",
  pivots: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  pivotColumnsEmptyMessage: "\u05D2\u05E8\u05D5\u05E8 \u05DC\u05DB\u05D0\u05DF \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D2\u05D3\u05D9\u05E8 \u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  // Header of the Default Group Column
  group: "\u05E7\u05D1\u05D5\u05E6\u05D4",
  // Row Drag
  rowDragRow: "\u05E9\u05D5\u05E8\u05D4",
  rowDragRows: "\u05E9\u05D5\u05E8\u05D5\u05EA",
  // Other
  loadingOoo: "\u05D8\u05D5\u05E2\u05DF...",
  loadingError: "\u05E9\u05D2\u05D9\u05D0\u05D4",
  noRowsToShow: "\u05D0\u05D9\u05DF \u05E9\u05D5\u05E8\u05D5\u05EA \u05DC\u05D4\u05E6\u05D9\u05D2",
  enabled: "\u05DE\u05D5\u05E4\u05E2\u05DC",
  // Menu
  pinColumn: "\u05E0\u05E2\u05DC \u05E2\u05DE\u05D5\u05D3\u05D4",
  pinLeft: "\u05E0\u05E2\u05DC \u05DC\u05E9\u05DE\u05D0\u05DC",
  pinRight: "\u05E0\u05E2\u05DC \u05DC\u05D9\u05DE\u05D9\u05DF",
  noPin: "\u05DC\u05DC\u05D0 \u05E0\u05E2\u05D9\u05DC\u05D4",
  valueAggregation: "\u05E6\u05D1\u05D9\u05E8\u05D4 \u05E2\u05E8\u05DB\u05D9\u05EA",
  noAggregation: "\u05DC\u05DC\u05D0",
  autosizeThisColumn: "\u05DB\u05D5\u05D5\u05E0\u05D5\u05DF \u05D0\u05D5\u05D8\u05D5\u05DE\u05D8\u05D9 \u05E9\u05DC \u05E2\u05DE\u05D5\u05D3\u05D4 \u05D6\u05D5",
  autosizeAllColumns: "\u05DB\u05D5\u05D5\u05E0\u05D5\u05DF \u05D0\u05D5\u05D8\u05D5\u05DE\u05D8\u05D9 \u05E9\u05DC \u05DB\u05DC \u05D4\u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  groupBy: "\u05E7\u05D1\u05E5 \u05DC\u05E4\u05D9",
  ungroupBy: "\u05D1\u05D8\u05DC \u05E7\u05D9\u05D1\u05D5\u05E5 \u05DC\u05E4\u05D9",
  ungroupAll: "\u05D1\u05D8\u05DC \u05E7\u05D9\u05D1\u05D5\u05E5 \u05E9\u05DC \u05D4\u05DB\u05D5\u05DC",
  addToValues: "\u05D4\u05D5\u05E1\u05E3 \u05D0\u05EA ${variable} \u05DC\u05E2\u05E8\u05DB\u05D9\u05DD",
  removeFromValues: "\u05D4\u05E1\u05E8 \u05D0\u05EA ${variable} \u05DE\u05D4\u05E2\u05E8\u05DB\u05D9\u05DD",
  addToLabels: "\u05D4\u05D5\u05E1\u05E3 \u05D0\u05EA ${variable} \u05DC\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA",
  removeFromLabels: "\u05D4\u05E1\u05E8 \u05D0\u05EA ${variable} \u05DE\u05D4\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA",
  resetColumns: "\u05D0\u05E4\u05E1 \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  expandAll: "\u05D4\u05E8\u05D7\u05D1 \u05D0\u05EA \u05DB\u05DC \u05E7\u05D1\u05D5\u05E6\u05D5\u05EA \u05D4\u05E9\u05D5\u05E8\u05D5\u05EA",
  collapseAll: "\u05E1\u05D2\u05D5\u05E8 \u05D0\u05EA \u05DB\u05DC \u05E7\u05D1\u05D5\u05E6\u05D5\u05EA \u05D4\u05E9\u05D5\u05E8\u05D5\u05EA",
  copy: "\u05D4\u05E2\u05EA\u05E7",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u05D4\u05E2\u05EA\u05E7 \u05E2\u05DD \u05DB\u05D5\u05EA\u05E8\u05D5\u05EA",
  copyWithGroupHeaders: "\u05D4\u05E2\u05EA\u05E7 \u05E2\u05DD \u05DB\u05D5\u05EA\u05E8\u05D5\u05EA \u05E7\u05D1\u05D5\u05E6\u05D4",
  cut: "\u05D2\u05D6\u05D5\u05E8",
  paste: "\u05D4\u05D3\u05D1\u05E7",
  ctrlV: "Ctrl+V",
  export: "\u05D9\u05D9\u05E6\u05D5\u05D0",
  csvExport: "\u05D9\u05D9\u05E6\u05D5\u05D0 CSV",
  excelExport: "\u05D9\u05D9\u05E6\u05D5\u05D0 Excel",
  columnFilter: "\u05E1\u05D9\u05E0\u05D5\u05DF \u05E2\u05DE\u05D5\u05D3\u05D4",
  columnChooser: "\u05D1\u05D7\u05E8 \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  chooseColumns: "\u05D1\u05D7\u05E8 \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  sortAscending: "\u05DE\u05D9\u05D9\u05DF \u05D1\u05E1\u05D3\u05E8 \u05E2\u05D5\u05DC\u05D4",
  sortDescending: "\u05DE\u05D9\u05D9\u05DF \u05D1\u05E1\u05D3\u05E8 \u05D9\u05D5\u05E8\u05D3",
  sortUnSort: "\u05E0\u05E7\u05D4 \u05DE\u05D9\u05D5\u05DF",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u05E1\u05DB\u05D5\u05DD",
  first: "\u05E8\u05D0\u05E9\u05D5\u05DF",
  last: "\u05D0\u05D7\u05E8\u05D5\u05DF",
  min: "\u05DE\u05D9\u05E0",
  max: "\u05DE\u05E7\u05E1",
  none: "\u05D0\u05D9\u05DF",
  count: "\u05E1\u05E4\u05D9\u05E8\u05D4",
  avg: "\u05DE\u05DE\u05D5\u05E6\u05E2",
  filteredRows: "\u05DE\u05E1\u05D5\u05E0\u05DF",
  selectedRows: "\u05E0\u05D1\u05D7\u05E8",
  totalRows: '\u05E1\u05D4"\u05DB \u05E9\u05D5\u05E8\u05D5\u05EA',
  totalAndFilteredRows: "\u05E9\u05D5\u05E8\u05D5\u05EA",
  more: "\u05E2\u05D5\u05D3",
  to: "\u05D0\u05DC",
  of: "\u05E9\u05DC",
  page: "\u05D3\u05E3",
  pageLastRowUnknown: "?",
  nextPage: "\u05E2\u05DE\u05D5\u05D3 \u05D4\u05D1\u05D0",
  lastPage: "\u05E2\u05DE\u05D5\u05D3 \u05D0\u05D7\u05E8\u05D5\u05DF",
  firstPage: "\u05E2\u05DE\u05D5\u05D3 \u05E8\u05D0\u05E9\u05D5\u05DF",
  previousPage: "\u05E2\u05DE\u05D5\u05D3 \u05E7\u05D5\u05D3\u05DD",
  pageSizeSelectorLabel: "\u05D2\u05D5\u05D3\u05DC \u05E2\u05DE\u05D5\u05D3:",
  footerTotal: '\u05E1\u05D4"\u05DB',
  // Pivoting
  pivotColumnGroupTotals: "\u05E1\u05D4\u05F4\u05DB",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E6\u05D9\u05E8 \u05D5\u05DE\u05E6\u05D1 \u05E6\u05D9\u05E8",
  pivotChart: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E6\u05D9\u05E8",
  chartRange: "\u05D8\u05D5\u05D5\u05D7 \u05EA\u05E8\u05E9\u05D9\u05DD",
  columnChart: "\u05E2\u05DE\u05D5\u05D3\u05D4",
  groupedColumn: "\u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedColumn: "\u05DE\u05D5\u05BC\u05E2\u05E8\u05B8\u05DD",
  normalizedColumn: "100% \u05DE\u05D5\u05E2\u05E8\u05DD",
  barChart: "\u05D1\u05E8",
  groupedBar: "\u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedBar: "\u05DE\u05D5\u05BC\u05E2\u05E8\u05B8\u05DD",
  normalizedBar: "100% \u05DE\u05D5\u05E2\u05E8\u05DD",
  pieChart: "\u05E2\u05D5\u05D2\u05D4",
  pie: "\u05E2\u05D5\u05D2\u05D4",
  donut: "\u05D3\u05D5\u05E0\u05D0\u05D8",
  line: "\u05E7\u05D5",
  xyChart: "X Y (Scatter)",
  scatter: "\u05E4\u05D9\u05D6\u05D5\u05E8",
  bubble: "\u05D1\u05D5\u05E2\u05D4",
  areaChart: "\u05D0\u05D6\u05D5\u05E8",
  area: "\u05D0\u05D6\u05D5\u05E8",
  stackedArea: "\u05DE\u05D5\u05BC\u05E2\u05E8\u05B8\u05DD",
  normalizedArea: "100% \u05DE\u05D5\u05E2\u05E8\u05DD",
  histogramChart: "\u05D4\u05D9\u05E1\u05D8\u05D5\u05D2\u05E8\u05DE\u05D4",
  polarChart: "\u05E4\u05D5\u05DC\u05D0\u05E8\u05D9",
  radarLine: "\u05E7\u05D5 \u05DE\u05DB\u05F4\u05DD",
  radarArea: "\u05D0\u05D6\u05D5\u05E8 \u05DE\u05DB\u05F4\u05DD",
  nightingale: "\u05E0\u05D9\u05D9\u05D8\u05D9\u05E0\u05D2\u05D9\u05D9\u05DC",
  radialColumn: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05E8\u05D3\u05D9\u05D0\u05DC\u05D9\u05EA",
  radialBar: "\u05D1\u05E8 \u05E8\u05D3\u05D9\u05D0\u05DC\u05D9",
  statisticalChart: "\u05E1\u05D8\u05D8\u05D9\u05E1\u05D8\u05D9",
  boxPlot: "\u05E2\u05DC\u05D9\u05DC\u05EA \u05E7\u05D5\u05E4\u05E1\u05D4",
  rangeBar: "\u05D8\u05D5\u05D5\u05D7 \u05D1\u05E8",
  rangeArea: "\u05D8\u05D5\u05D5\u05D7 \u05D0\u05D6\u05D5\u05E8",
  hierarchicalChart: "\u05D4\u05D9\u05E8\u05E8\u05DB\u05D9",
  treemap: "\u05DE\u05E4\u05EA \u05E2\u05E5",
  sunburst: "\u05D4\u05EA\u05E4\u05E8\u05E6\u05D5\u05EA \u05E9\u05DE\u05E9",
  specializedChart: "\u05DE\u05D5\u05DE\u05D7\u05D4",
  waterfall: "\u05DE\u05E4\u05DC",
  heatmap: "\u05DE\u05E4\u05EA \u05D7\u05D5\u05DD",
  combinationChart: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05DE\u05E9\u05D5\u05DC\u05D1",
  columnLineCombo: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05D5\u05E7\u05D5",
  AreaColumnCombo: "\u05D0\u05D6\u05D5\u05E8 \u05D5\u05E2\u05DE\u05D5\u05D3\u05D4",
  // Charts
  pivotChartTitle: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E6\u05D9\u05E8",
  rangeChartTitle: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05D8\u05D5\u05D5\u05D7",
  settings: "\u05EA\u05E8\u05E9\u05D9\u05DD",
  data: "\u05D4\u05D2\u05D3\u05E8\u05D5\u05EA",
  format: "\u05D4\u05EA\u05D0\u05DE\u05D4 \u05D0\u05D9\u05E9\u05D9\u05EA",
  categories: "\u05E7\u05D8\u05D2\u05D5\u05E8\u05D9\u05D5\u05EA",
  defaultCategory: "(\u05DC\u05DC\u05D0)",
  series: "\u05E1\u05D3\u05E8\u05D5\u05EA",
  switchCategorySeries: "\u05D4\u05D7\u05DC\u05E3 \u05E7\u05D8\u05D2\u05D5\u05E8\u05D9\u05D4 / \u05E1\u05D3\u05E8\u05D4",
  categoryValues: "\u05E2\u05E8\u05DB\u05D9 \u05E7\u05D8\u05D2\u05D5\u05E8\u05D9\u05D4",
  seriesLabels: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05E1\u05D3\u05E8\u05D4",
  aggregate: "\u05E7\u05D9\u05D1\u05D5\u05E5 \u05E0\u05EA\u05D5\u05E0\u05D9\u05DD",
  xyValues: "\u05E2\u05E8\u05DB\u05D9 X Y",
  paired: "\u05DE\u05E6\u05D1 \u05D6\u05D9\u05D5\u05D5\u05D2",
  axis: "\u05E6\u05D9\u05E8",
  xAxis: "\u05E6\u05D9\u05E8 \u05D0\u05D5\u05E4\u05E7\u05D9",
  yAxis: "\u05E6\u05D9\u05E8 \u05D0\u05E0\u05DB\u05D9",
  polarAxis: "\u05E6\u05D9\u05E8 \u05E7\u05D5\u05D8\u05D1",
  radiusAxis: "\u05E6\u05D9\u05E8 \u05E8\u05D3\u05D9\u05D5\u05E1",
  navigator: "\u05DE\u05E0\u05D5\u05D5\u05D8",
  zoom: "\u05D6\u05D5\u05DD",
  animation: "\u05D0\u05E0\u05D9\u05DE\u05E6\u05D9\u05D4",
  crosshair: "\u05E7\u05D5 \u05D7\u05D5\u05E6\u05D4",
  color: "\u05E6\u05D1\u05E2",
  thickness: "\u05E2\u05D5\u05D1\u05D9",
  preferredLength: "\u05D0\u05D5\u05E8\u05DA \u05DE\u05D5\u05E2\u05D3\u05E3",
  xType: "\u05E1\u05D5\u05D2 X",
  axisType: "\u05E1\u05D5\u05D2 \u05E6\u05D9\u05E8",
  automatic: "\u05D0\u05D5\u05D8\u05D5\u05DE\u05D8\u05D9",
  category: "\u05E7\u05D8\u05D2\u05D5\u05E8\u05D9\u05D4",
  number: "\u05DE\u05E1\u05E4\u05E8",
  time: "\u05D6\u05DE\u05DF",
  timeFormat: "\u05E4\u05D5\u05E8\u05DE\u05D8 \u05D6\u05DE\u05DF",
  autoRotate: "\u05E1\u05D9\u05D1\u05D5\u05D1 \u05D0\u05D5\u05D8\u05D5\u05DE\u05D8\u05D9",
  labelRotation: "\u05E1\u05D9\u05D1\u05D5\u05D1 \u05EA\u05D5\u05D5\u05D9\u05EA",
  circle: "\u05DE\u05E2\u05D2\u05DC",
  polygon: "\u05DE\u05E6\u05D5\u05DC\u05E2",
  square: "\u05E8\u05D9\u05D1\u05D5\u05E2",
  cross: "\u05E6\u05DC\u05D1",
  diamond: "\u05D9\u05D4\u05DC\u05D5\u05DD",
  plus: "\u05E4\u05DC\u05D5\u05E1",
  triangle: "\u05DE\u05E9\u05D5\u05DC\u05E9",
  heart: "\u05DC\u05D1",
  orientation: "\u05DB\u05D9\u05D5\u05D5\u05DF",
  fixed: "\u05E7\u05D1\u05D5\u05E2",
  parallel: "\u05DE\u05E7\u05D1\u05D9\u05DC",
  perpendicular: "\u05E0\u05D9\u05E6\u05D1",
  radiusAxisPosition: "\u05DE\u05D9\u05E7\u05D5\u05DD",
  ticks: "\u05E1\u05D9\u05DE\u05E0\u05D9 \u05E7\u05D5",
  gridLines: "\u05E7\u05D5\u05D5\u05D9 \u05E8\u05E9\u05EA",
  width: "\u05E8\u05D5\u05D7\u05D1",
  height: "\u05D2\u05D5\u05D1\u05D4",
  length: "\u05D0\u05D5\u05E8\u05DA",
  padding: "\u05E8\u05D9\u05E4\u05D5\u05D3",
  spacing: "\u05E8\u05D5\u05D5\u05D7",
  chartStyle: "\u05E1\u05D2\u05E0\u05D5\u05DF \u05EA\u05E8\u05E9\u05D9\u05DD",
  title: "\u05DB\u05D5\u05EA\u05E8\u05EA",
  chartTitles: "\u05DB\u05D5\u05EA\u05E8\u05D5\u05EA",
  chartTitle: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05EA\u05E8\u05E9\u05D9\u05DD",
  chartSubtitle: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05DE\u05E9\u05E0\u05D4",
  horizontalAxisTitle: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05E6\u05D9\u05E8 \u05D0\u05D5\u05E4\u05E7\u05D9",
  verticalAxisTitle: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05E6\u05D9\u05E8 \u05D0\u05E0\u05DB\u05D9",
  polarAxisTitle: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05E6\u05D9\u05E8 \u05E7\u05D5\u05D8\u05D1",
  titlePlaceholder: "\u05DB\u05D5\u05EA\u05E8\u05EA \u05EA\u05E8\u05E9\u05D9\u05DD",
  background: "\u05E8\u05E7\u05E2",
  font: "\u05D2\u05D5\u05E4\u05DF",
  weight: "\u05E2\u05D5\u05D1\u05D9",
  top: "\u05DC\u05DE\u05E2\u05DC\u05D4",
  right: "\u05D9\u05DE\u05D9\u05DF",
  bottom: "\u05DC\u05DE\u05D8\u05D4",
  left: "\u05E9\u05DE\u05D0\u05DC",
  labels: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA",
  calloutLabels: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05D4\u05E1\u05D1\u05E8",
  sectorLabels: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05DE\u05D2\u05D6\u05E8",
  positionRatio: "\u05D9\u05D7\u05E1 \u05DE\u05D9\u05E7\u05D5\u05DD",
  size: "\u05D2\u05D5\u05D3\u05DC",
  shape: "\u05E6\u05D5\u05E8\u05D4",
  minSize: "\u05D2\u05D5\u05D3\u05DC \u05DE\u05D9\u05E0\u05D9\u05DE\u05DC\u05D9",
  maxSize: "\u05D2\u05D5\u05D3\u05DC \u05DE\u05E7\u05E1\u05D9\u05DE\u05DC\u05D9",
  legend: "\u05DE\u05E7\u05E8\u05D0",
  position: "\u05DE\u05D9\u05E7\u05D5\u05DD",
  markerSize: "\u05D2\u05D5\u05D3\u05DC \u05E1\u05D9\u05DE\u05DF",
  markerStroke: "\u05E7\u05D5\u05D8\u05E8 \u05E1\u05D9\u05DE\u05DF",
  markerPadding: "\u05E8\u05D9\u05E4\u05D5\u05D3 \u05E1\u05D9\u05DE\u05DF",
  itemSpacing: "\u05E8\u05D5\u05D5\u05D7 \u05E4\u05E8\u05D9\u05D8\u05D9\u05DD",
  itemPaddingX: "\u05E8\u05D9\u05E4\u05D5\u05D3 \u05E4\u05E8\u05D9\u05D8\u05D9\u05DD X",
  itemPaddingY: "\u05E8\u05D9\u05E4\u05D5\u05D3 \u05E4\u05E8\u05D9\u05D8\u05D9\u05DD Y",
  layoutHorizontalSpacing: "\u05E8\u05D5\u05D5\u05D7 \u05D0\u05D5\u05E4\u05E7\u05D9",
  layoutVerticalSpacing: "\u05E8\u05D5\u05D5\u05D7 \u05D0\u05E0\u05DB\u05D9",
  strokeWidth: "\u05E2\u05D5\u05D1\u05D9 \u05E7\u05D5",
  offset: "\u05D4\u05D9\u05E1\u05D8",
  offsets: "\u05D4\u05D9\u05E1\u05D8\u05D9\u05DD",
  tooltips: "\u05D4\u05E1\u05D1\u05E8\u05D9\u05DD",
  callout: "\u05D4\u05E1\u05D1\u05E8",
  markers: "\u05E1\u05D9\u05DE\u05E0\u05D9\u05DD",
  shadow: "\u05E6\u05DC",
  blur: "\u05D8\u05E9\u05D8\u05D5\u05E9",
  xOffset: "\u05D4\u05D9\u05E1\u05D8 X",
  yOffset: "\u05D4\u05D9\u05E1\u05D8 Y",
  lineWidth: "\u05E2\u05D5\u05D1\u05D9 \u05E7\u05D5",
  lineDash: "\u05E7\u05D5 \u05DE\u05E7\u05D5\u05D5\u05E7\u05D5",
  lineDashOffset: "\u05E7\u05D9\u05D6\u05D5\u05D6 \u05E7\u05D5 \u05DE\u05E7\u05D5\u05D5\u05E7\u05D5",
  scrollingZoom: "\u05D2\u05DC\u05D9\u05DC\u05D4",
  scrollingStep: "\u05E9\u05DC\u05D1 \u05D2\u05DC\u05D9\u05DC\u05D4",
  selectingZoom: "\u05D1\u05D7\u05D9\u05E8\u05EA \u05D6\u05D5\u05DD",
  durationMillis: "\u05DE\u05E9\u05DA (\u05DE\u05D9\u05DC\u05D9\u05E9\u05E0\u05D9\u05D5\u05EA)",
  crosshairLabel: "\u05EA\u05D5\u05D5\u05D9\u05EA",
  crosshairSnap: "\u05D4\u05E6\u05DE\u05D3 \u05DC\u05E6\u05D5\u05DE\u05EA",
  normal: "\u05E8\u05D2\u05D9\u05DC",
  bold: "\u05DE\u05D5\u05D3\u05D2\u05E9",
  italic: "\u05E0\u05D8\u05D5\u05D9",
  boldItalic: "\u05DE\u05D5\u05D3\u05D2\u05E9 \u05D5\u05E0\u05D8\u05D5\u05D9",
  predefined: "\u05DE\u05D5\u05D2\u05D3\u05E8 \u05DE\u05E8\u05D0\u05E9",
  fillOpacity: "\u05D0\u05D8\u05D9\u05DE\u05D5\u05EA \u05DE\u05D9\u05DC\u05D5\u05D9",
  strokeColor: "\u05E6\u05D1\u05E2 \u05E7\u05D5",
  strokeOpacity: "\u05D0\u05D8\u05D9\u05DE\u05D5\u05EA \u05E7\u05D5",
  miniChart: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E7\u05D8\u05DF",
  histogramBinCount: "\u05DE\u05E1\u05E4\u05E8 \u05D0\u05D6\u05D5\u05E8\u05D9\u05DD",
  connectorLine: "\u05E7\u05D5 \u05D7\u05D9\u05D1\u05D5\u05E8",
  seriesItems: "\u05E4\u05E8\u05D9\u05D8\u05D9 \u05E1\u05D3\u05E8\u05D4",
  seriesItemType: "\u05E1\u05D5\u05D2 \u05E4\u05E8\u05D9\u05D8",
  seriesItemPositive: "\u05D7\u05D9\u05D5\u05D1\u05D9",
  seriesItemNegative: "\u05E9\u05DC\u05D9\u05DC\u05D9",
  seriesItemLabels: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05E4\u05E8\u05D9\u05D8",
  columnGroup: "\u05E2\u05DE\u05D5\u05D3\u05D4",
  barGroup: "\u05E4\u05E1",
  pieGroup: "\u05E2\u05D5\u05D2\u05D4",
  lineGroup: "\u05E7\u05D5",
  scatterGroup: "X Y (\u05E4\u05D9\u05D6\u05D5\u05E8)",
  areaGroup: "\u05E9\u05D8\u05D7",
  polarGroup: "\u05E7\u05D5\u05D8\u05D1",
  statisticalGroup: "\u05E1\u05D8\u05D8\u05D9\u05E1\u05D8\u05D9",
  hierarchicalGroup: "\u05D4\u05D9\u05E8\u05E8\u05DB\u05D9",
  specializedGroup: "\u05DE\u05EA\u05DE\u05D7\u05D4",
  combinationGroup: "\u05E9\u05D9\u05DC\u05D5\u05D1",
  groupedColumnTooltip: "\u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedColumnTooltip: "\u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedColumnTooltip: "\u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  groupedBarTooltip: "\u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedBarTooltip: "\u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedBarTooltip: "\u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  pieTooltip: "\u05E2\u05D5\u05D2\u05D4",
  donutTooltip: "\u05E1\u05D5\u05E4\u05D2\u05E0\u05D9\u05D9\u05D4",
  lineTooltip: "\u05E7\u05D5",
  groupedAreaTooltip: "\u05E9\u05D8\u05D7 \u05DE\u05D7\u05D5\u05DC\u05E7",
  stackedAreaTooltip: "\u05E9\u05D8\u05D7 \u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedAreaTooltip: "\u05E9\u05D8\u05D7 \u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  scatterTooltip: "\u05E4\u05D9\u05D6\u05D5\u05E8",
  bubbleTooltip: "\u05D1\u05D5\u05E2\u05D4",
  histogramTooltip: "\u05D4\u05D9\u05E1\u05D8\u05D5\u05D2\u05E8\u05DE\u05D4",
  radialColumnTooltip: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05E8\u05D3\u05D9\u05D0\u05DC\u05D9\u05EA",
  radialBarTooltip: "\u05E4\u05E1 \u05E8\u05D3\u05D9\u05D0\u05DC\u05D9",
  radarLineTooltip: "\u05E7\u05D5 \u05E8\u05D3\u05D0\u05E8",
  radarAreaTooltip: "\u05E9\u05D8\u05D7 \u05E8\u05D3\u05D0\u05E8",
  nightingaleTooltip: "\u05E0\u05D9\u05D9\u05D8\u05D9\u05E0\u05D2\u05D9\u05D9\u05DC",
  rangeBarTooltip: "\u05D8\u05D5\u05D5\u05D7 \u05E4\u05E1",
  rangeAreaTooltip: "\u05D8\u05D5\u05D5\u05D7 \u05E9\u05D8\u05D7",
  boxPlotTooltip: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E7\u05D5\u05E4\u05E1\u05D4",
  treemapTooltip: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E2\u05E5",
  sunburstTooltip: "\u05D4\u05EA\u05E4\u05E8\u05E6\u05D5\u05EA \u05E9\u05DE\u05E9",
  waterfallTooltip: "\u05DE\u05E4\u05DC",
  heatmapTooltip: "\u05DE\u05E4\u05EA \u05D7\u05D5\u05DD",
  columnLineComboTooltip: "\u05E2\u05DE\u05D5\u05D3\u05D4 & \u05E7\u05D5",
  areaColumnComboTooltip: "\u05E9\u05D8\u05D7 & \u05E2\u05DE\u05D5\u05D3\u05D4",
  customComboTooltip: "\u05E9\u05D9\u05DC\u05D5\u05D1 \u05DE\u05D5\u05EA\u05D0\u05DD \u05D0\u05D9\u05E9\u05D9\u05EA",
  innerRadius: "\u05E8\u05D3\u05D9\u05D5\u05E1 \u05E4\u05E0\u05D9\u05DE\u05D9",
  startAngle: "\u05D6\u05D5\u05D5\u05D9\u05EA \u05D4\u05EA\u05D7\u05DC\u05D4",
  endAngle: "\u05D6\u05D5\u05D5\u05D9\u05EA \u05E1\u05D9\u05D5\u05DD",
  reverseDirection: "\u05DB\u05D9\u05D5\u05D5\u05DF \u05D4\u05E4\u05D5\u05DA",
  groupPadding: "\u05E8\u05D9\u05E4\u05D5\u05D3 \u05E7\u05D1\u05D5\u05E6\u05D4",
  seriesPadding: "\u05E8\u05D9\u05E4\u05D5\u05D3 \u05E1\u05D3\u05E8\u05D4",
  tile: "\u05D0\u05E8\u05D9\u05D7",
  whisker: "\u05D3\u05D5\u05BC\u05D0\u05B7\u05D6\u05B0\u05E8\u05B7\u05EA",
  cap: "\u05DB\u05BC\u05B4\u05E4\u05BC\u05B8\u05D4",
  capLengthRatio: "\u05D9\u05D7\u05E1 \u05D0\u05D5\u05E8\u05DA",
  labelPlacement: "\u05DE\u05D9\u05E7\u05D5\u05DD \u05EA\u05D5\u05D5\u05D9\u05EA",
  inside: "\u05D1\u05E4\u05E0\u05D9\u05DD",
  outside: "\u05D1\u05D7\u05D5\u05E5",
  noDataToChart: "\u05D0\u05D9\u05DF \u05E0\u05EA\u05D5\u05E0\u05D9\u05DD \u05DC\u05D4\u05E6\u05D2\u05D4.",
  pivotChartRequiresPivotMode: "\u05EA\u05E8\u05E9\u05D9\u05DD \u05E6\u05D9\u05E8 \u05D3\u05D5\u05E8\u05E9 \u05D4\u05E4\u05E2\u05DC\u05EA \u05DE\u05E6\u05D1 \u05E6\u05D9\u05E8.",
  chartSettingsToolbarTooltip: "\u05EA\u05E4\u05E8\u05D9\u05D8",
  chartLinkToolbarTooltip: "\u05DE\u05E7\u05D5\u05E9\u05E8 \u05DC\u05D2\u05E8\u05D9\u05D3",
  chartUnlinkToolbarTooltip: "\u05DE\u05E0\u05D5\u05EA\u05E7 \u05DE\u05D4\u05D2\u05E8\u05D9\u05D3",
  chartDownloadToolbarTooltip: "\u05D4\u05D5\u05E8\u05D3 \u05EA\u05E8\u05E9\u05D9\u05DD",
  chartMenuToolbarTooltip: "\u05EA\u05E4\u05E8\u05D9\u05D8",
  chartEdit: "\u05E2\u05E8\u05D5\u05DA \u05EA\u05E8\u05E9\u05D9\u05DD",
  chartAdvancedSettings: "\u05D4\u05D2\u05D3\u05E8\u05D5\u05EA \u05DE\u05EA\u05E7\u05D3\u05DE\u05D5\u05EA",
  chartLink: "\u05E7\u05D9\u05E9\u05D5\u05E8 \u05DC\u05D2\u05E8\u05D9\u05D3",
  chartUnlink: "\u05E0\u05EA\u05E7 \u05DE\u05D4\u05D2\u05E8\u05D9\u05D3",
  chartDownload: "\u05D4\u05D5\u05E8\u05D3 \u05EA\u05E8\u05E9\u05D9\u05DD",
  histogramFrequency: "\u05EA\u05D3\u05D9\u05E8\u05D5\u05EA",
  seriesChartType: "\u05E1\u05D5\u05D2 \u05EA\u05E8\u05E9\u05D9\u05DD \u05E1\u05D3\u05E8\u05D4",
  seriesType: "\u05E1\u05D5\u05D2 \u05E1\u05D3\u05E8\u05D4",
  secondaryAxis: "\u05E6\u05D9\u05E8 \u05DE\u05E9\u05E0\u05D9",
  seriesAdd: "\u05D4\u05D5\u05E1\u05E3 \u05E1\u05D3\u05E8\u05D4",
  categoryAdd: "\u05D4\u05D5\u05E1\u05E3 \u05E7\u05D8\u05D2\u05D5\u05E8\u05D9\u05D4",
  bar: "\u05E4\u05E1",
  column: "\u05E2\u05DE\u05D5\u05D3\u05D4",
  histogram: "\u05D4\u05D9\u05E1\u05D8\u05D5\u05D2\u05E8\u05DE\u05D4",
  advancedSettings: "\u05D4\u05D2\u05D3\u05E8\u05D5\u05EA \u05DE\u05EA\u05E7\u05D3\u05DE\u05D5\u05EA",
  direction: "\u05DB\u05D9\u05D5\u05D5\u05DF",
  horizontal: "\u05D0\u05D5\u05E4\u05E7\u05D9",
  vertical: "\u05D0\u05E0\u05DB\u05D9",
  seriesGroupType: "\u05E1\u05D5\u05D2 \u05E7\u05D1\u05D5\u05E6\u05EA \u05E1\u05D3\u05E8\u05D4",
  groupedSeriesGroupType: "\u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedSeriesGroupType: "\u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedSeriesGroupType: "\u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  legendEnabled: "\u05DE\u05D5\u05E4\u05E2\u05DC",
  invalidColor: "\u05E2\u05E8\u05DA \u05E6\u05D1\u05E2 \u05DC\u05D0 \u05D7\u05D5\u05E7\u05D9",
  groupedColumnFull: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05DE\u05E7\u05D5\u05D1\u05E6\u05EA",
  stackedColumnFull: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05DE\u05D5\u05E2\u05E8\u05DE\u05EA",
  normalizedColumnFull: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05DE\u05D5\u05E2\u05E8\u05DE\u05EA 100%",
  groupedBarFull: "\u05E4\u05E1 \u05DE\u05E7\u05D5\u05D1\u05E5",
  stackedBarFull: "\u05E4\u05E1 \u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedBarFull: "\u05E4\u05E1 \u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  stackedAreaFull: "\u05E9\u05D8\u05D7 \u05DE\u05D5\u05E2\u05E8\u05DD",
  normalizedAreaFull: "\u05E9\u05D8\u05D7 \u05DE\u05D5\u05E2\u05E8\u05DD 100%",
  customCombo: "\u05E9\u05D9\u05DC\u05D5\u05D1 \u05DE\u05D5\u05EA\u05D0\u05DD \u05D0\u05D9\u05E9\u05D9\u05EA",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u05E8\u05DE\u05D4 ${variable}. \u05DC\u05D7\u05E5 \u05E2\u05DC ENTER \u05DB\u05D3\u05D9 \u05DC\u05E2\u05E8\u05D5\u05DA.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u05E8\u05DE\u05D4 ${variable}. ${variable} \u05DC\u05D7\u05E5 \u05E2\u05DC ENTER \u05DB\u05D3\u05D9 \u05DC\u05E2\u05E8\u05D5\u05DA.",
  ariaAdvancedFilterBuilderList: "\u05E8\u05E9\u05D9\u05DE\u05EA \u05D1\u05D5\u05E0\u05D4 \u05E4\u05D9\u05DC\u05D8\u05E8 \u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaAdvancedFilterBuilderFilterItem: "\u05EA\u05E0\u05D0\u05D9 \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaAdvancedFilterBuilderGroupItem: "\u05E7\u05D1\u05D5\u05E6\u05EA \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaAdvancedFilterBuilderColumn: "\u05E2\u05DE\u05D5\u05D3\u05D4",
  ariaAdvancedFilterBuilderOption: "\u05D0\u05E4\u05E9\u05E8\u05D5\u05EA",
  ariaAdvancedFilterBuilderValueP: "\u05E2\u05E8\u05DA",
  ariaAdvancedFilterBuilderJoinOperator: "\u05DE\u05E4\u05E2\u05D9\u05DC \u05D7\u05D9\u05D1\u05D5\u05E8",
  ariaAdvancedFilterInput: "\u05E7\u05DC\u05D8 \u05E1\u05D9\u05E0\u05D5\u05DF \u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaChecked: "\u05DE\u05E1\u05D5\u05DE\u05DF",
  ariaColumn: "\u05E2\u05DE\u05D5\u05D3\u05D4",
  ariaColumnGroup: "\u05E7\u05D1\u05D5\u05E6\u05EA \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  ariaColumnFiltered: "\u05E2\u05DE\u05D5\u05D3\u05D4 \u05DE\u05E1\u05D5\u05E0\u05E0\u05EA",
  ariaColumnSelectAll: "\u05D4\u05D7\u05DC\u05E3 \u05D0\u05EA \u05D1\u05D7\u05D9\u05E8\u05EA \u05DB\u05DC \u05D4\u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  ariaDateFilterInput: "\u05E7\u05DC\u05D8 \u05E1\u05D9\u05E0\u05D5\u05DF \u05EA\u05D0\u05E8\u05D9\u05DA",
  ariaDefaultListName: "\u05E8\u05E9\u05D9\u05DE\u05D4",
  ariaFilterColumnsInput: "\u05E7\u05DC\u05D8 \u05E1\u05D9\u05E0\u05D5\u05DF \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  ariaFilterFromValue: "\u05E1\u05E0\u05DF \u05DE\u05E2\u05E8\u05DA",
  ariaFilterInput: "\u05E7\u05DC\u05D8 \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaFilterList: "\u05E8\u05E9\u05D9\u05DE\u05EA \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaFilterToValue: "\u05E1\u05E0\u05DF \u05DC\u05E2\u05E8\u05DA",
  ariaFilterValue: "\u05E2\u05E8\u05DA \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaFilterMenuOpen: "\u05E4\u05EA\u05D7 \u05EA\u05E4\u05E8\u05D9\u05D8 \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaFilteringOperator: "\u05DE\u05E4\u05E2\u05D9\u05DC \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaHidden: "\u05E0\u05E1\u05EA\u05E8",
  ariaIndeterminate: "\u05D1\u05DC\u05EA\u05D9 \u05E7\u05D1\u05D5\u05E2",
  ariaInputEditor: "\u05E2\u05D5\u05E8\u05DA \u05E7\u05DC\u05D8",
  ariaMenuColumn: "\u05DC\u05D7\u05E5 ALT \u05DC\u05DE\u05D8\u05D4 \u05DB\u05D3\u05D9 \u05DC\u05E4\u05EA\u05D5\u05D7 \u05EA\u05E4\u05E8\u05D9\u05D8 \u05E2\u05DE\u05D5\u05D3\u05D4",
  ariaFilterColumn: "\u05DC\u05D7\u05E5 CTRL ENTER \u05DB\u05D3\u05D9 \u05DC\u05E4\u05EA\u05D5\u05D7 \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaRowDeselect: "\u05DC\u05D7\u05E5 \u05E2\u05DC SPACE \u05DB\u05D3\u05D9 \u05DC\u05D1\u05D8\u05DC \u05D0\u05EA \u05D1\u05D7\u05D9\u05E8\u05EA \u05E9\u05D5\u05E8\u05D4 \u05D6\u05D5",
  ariaRowSelectAll: "\u05DC\u05D7\u05E5 \u05E2\u05DC Space \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D7\u05DC\u05D9\u05E3 \u05D0\u05EA \u05D1\u05D7\u05D9\u05E8\u05EA \u05DB\u05DC \u05D4\u05E9\u05D5\u05E8\u05D5\u05EA",
  ariaRowToggleSelection: "\u05DC\u05D7\u05E5 \u05E2\u05DC Space \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D7\u05DC\u05D9\u05E3 \u05D0\u05EA \u05D1\u05D7\u05D9\u05E8\u05EA \u05D4\u05E9\u05D5\u05E8\u05D4",
  ariaRowSelect: "\u05DC\u05D7\u05E5 \u05E2\u05DC SPACE \u05DB\u05D3\u05D9 \u05DC\u05D1\u05D7\u05D5\u05E8 \u05E9\u05D5\u05E8\u05D4 \u05D6\u05D5",
  ariaRowSelectionDisabled: "\u05D1\u05D7\u05D9\u05E8\u05EA \u05E9\u05D5\u05E8\u05D5\u05EA \u05DE\u05D5\u05E9\u05D1\u05EA\u05EA \u05E2\u05D1\u05D5\u05E8 \u05E9\u05D5\u05E8\u05D4 \u05D6\u05D5",
  ariaSearch: "\u05D7\u05D9\u05E4\u05D5\u05E9",
  ariaSortableColumn: "\u05DC\u05D7\u05E5 \u05E2\u05DC ENTER \u05DC\u05DE\u05D9\u05D5\u05DF",
  ariaToggleVisibility: "\u05DC\u05D7\u05E5 \u05E2\u05DC SPACE \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D7\u05DC\u05D9\u05E3 \u05E0\u05E8\u05D0\u05D5\u05EA",
  ariaToggleCellValue: "\u05DC\u05D7\u05E5 \u05E2\u05DC SPACE \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D7\u05DC\u05D9\u05E3 \u05E2\u05E8\u05DA \u05EA\u05D0",
  ariaUnchecked: "\u05DC\u05D0 \u05DE\u05E1\u05D5\u05DE\u05DF",
  ariaVisible: "\u05D2\u05DC\u05D5\u05D9",
  ariaSearchFilterValues: "\u05E2\u05E8\u05DB\u05D9 \u05D7\u05D9\u05E4\u05D5\u05E9 \u05E1\u05D9\u05E0\u05D5\u05DF",
  ariaPageSizeSelectorLabel: "\u05D2\u05D5\u05D3\u05DC \u05E2\u05DE\u05D5\u05D3",
  ariaChartMenuClose: "\u05E1\u05D2\u05D5\u05E8 \u05EA\u05E4\u05E8\u05D9\u05D8 \u05E2\u05E8\u05D9\u05DB\u05EA \u05EA\u05E8\u05E9\u05D9\u05DD",
  ariaChartSelected: "\u05E0\u05D1\u05D7\u05E8",
  ariaSkeletonCellLoadingFailed: "\u05D4\u05D8\u05E2\u05D9\u05E0\u05EA \u05D4\u05E9\u05D5\u05E8\u05D4 \u05E0\u05DB\u05E9\u05DC\u05D4",
  ariaSkeletonCellLoading: "\u05E0\u05EA\u05D5\u05E0\u05D9 \u05D4\u05E9\u05D5\u05E8\u05D4 \u05E0\u05D8\u05E2\u05E0\u05D9\u05DD",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u05E7\u05D1\u05D5\u05E6\u05D5\u05EA \u05E9\u05D5\u05E8\u05D5\u05EA",
  ariaValuesDropZonePanelLabel: "\u05E2\u05E8\u05DB\u05D9\u05DD",
  ariaPivotDropZonePanelLabel: "\u05EA\u05D5\u05D5\u05D9\u05D5\u05EA \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  ariaDropZoneColumnComponentDescription: "\u05DC\u05D7\u05E5 DELETE \u05DB\u05D3\u05D9 \u05DC\u05D4\u05E1\u05D9\u05E8",
  ariaDropZoneColumnValueItemDescription: "\u05DC\u05D7\u05E5 ENTER \u05DB\u05D3\u05D9 \u05DC\u05E9\u05E0\u05D5\u05EA \u05D0\u05EA \u05E1\u05D5\u05D2 \u05D4\u05D0\u05D2\u05E8\u05D2\u05E6\u05D9\u05D4",
  ariaDropZoneColumnGroupItemDescription: "\u05DC\u05D7\u05E5 ENTER \u05DB\u05D3\u05D9 \u05DC\u05DE\u05D9\u05D9\u05DF",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u05E9\u05DC ",
  ariaDropZoneColumnComponentSortAscending: "\u05E2\u05D5\u05DC\u05D4",
  ariaDropZoneColumnComponentSortDescending: "\u05D9\u05D5\u05E8\u05D3",
  ariaLabelDialog: "\u05D3\u05D9\u05D0\u05DC\u05D5\u05D2",
  ariaLabelColumnMenu: "\u05EA\u05E4\u05E8\u05D9\u05D8 \u05E2\u05DE\u05D5\u05D3\u05D4",
  ariaLabelColumnFilter: "\u05DE\u05E1\u05E0\u05DF \u05E2\u05DE\u05D5\u05D3\u05D4",
  ariaLabelCellEditor: "\u05E2\u05D5\u05E8\u05DA \u05EA\u05D0",
  ariaLabelSelectField: "\u05E9\u05D3\u05D4 \u05D1\u05D7\u05D9\u05E8\u05D4",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u05E9\u05D3\u05D4 \u05D1\u05D7\u05D9\u05E8\u05D4 \u05E2\u05E9\u05D9\u05E8",
  ariaLabelRichSelectToggleSelection: "\u05DC\u05D7\u05E5 \u05E2\u05DC \u05DE\u05E7\u05E9 \u05D4\u05E8\u05D5\u05D5\u05D7 \u05DB\u05D3\u05D9 \u05DC\u05D4\u05D7\u05DC\u05D9\u05E3 \u05D1\u05D7\u05D9\u05E8\u05D4",
  ariaLabelRichSelectDeselectAllItems: "\u05DC\u05D7\u05E5 \u05E2\u05DC \u05DE\u05E7\u05E9 \u05DE\u05D7\u05D9\u05E7\u05D4 \u05DB\u05D3\u05D9 \u05DC\u05D1\u05D8\u05DC \u05D0\u05EA \u05D4\u05D1\u05D7\u05D9\u05E8\u05D4 \u05D1\u05DB\u05DC \u05D4\u05E4\u05E8\u05D9\u05D8\u05D9\u05DD",
  ariaLabelRichSelectDeleteSelection: "\u05DC\u05D7\u05E5 \u05E2\u05DC \u05DE\u05E7\u05E9 \u05DE\u05D7\u05D9\u05E7\u05D4 \u05DB\u05D3\u05D9 \u05DC\u05D1\u05D8\u05DC \u05D0\u05EA \u05D1\u05D7\u05D9\u05E8\u05EA \u05D4\u05E4\u05E8\u05D9\u05D8",
  ariaLabelTooltip: "\u05EA\u05D5\u05D5\u05D9\u05EA",
  ariaLabelContextMenu: "\u05EA\u05E4\u05E8\u05D9\u05D8 \u05D4\u05E7\u05E9\u05E8",
  ariaLabelSubMenu: "\u05EA\u05E4\u05E8\u05D9\u05D8 \u05DE\u05E9\u05E0\u05D4",
  ariaLabelAggregationFunction: "\u05E4\u05D5\u05E0\u05E7\u05E6\u05D9\u05D9\u05EA \u05E6\u05D1\u05D9\u05E8\u05D4",
  ariaLabelAdvancedFilterAutocomplete: "\u05D4\u05E9\u05DC\u05DE\u05D4 \u05D0\u05D5\u05D8\u05D5\u05DE\u05D8\u05D9\u05EA \u05DC\u05E1\u05D9\u05E0\u05D5\u05DF \u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaLabelAdvancedFilterBuilderAddField: "\u05D4\u05D5\u05E1\u05E3 \u05E9\u05D3\u05D4 \u05D1\u05D1\u05D5\u05E0\u05D4 \u05D4\u05E1\u05D9\u05E0\u05D5\u05DF \u05D4\u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u05D1\u05D7\u05E8 \u05E9\u05D3\u05D4 \u05E2\u05DE\u05D5\u05D3\u05D4 \u05D1\u05D1\u05D5\u05E0\u05D4 \u05D4\u05E1\u05D9\u05E0\u05D5\u05DF \u05D4\u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u05D1\u05D7\u05E8 \u05E9\u05D3\u05D4 \u05D0\u05E4\u05E9\u05E8\u05D5\u05EA \u05D1\u05D1\u05D5\u05E0\u05D4 \u05D4\u05E1\u05D9\u05E0\u05D5\u05DF \u05D4\u05DE\u05EA\u05E7\u05D3\u05DD",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u05D1\u05D7\u05E8 \u05E9\u05D3\u05D4 \u05D0\u05D5\u05E4\u05E8\u05D8\u05D5\u05E8 \u05D4\u05E6\u05D8\u05E8\u05E4\u05D5\u05EA \u05D1\u05D1\u05D5\u05E0\u05D4 \u05D4\u05E1\u05D9\u05E0\u05D5\u05DF \u05D4\u05DE\u05EA\u05E7\u05D3\u05DD",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u05E8\u05E9\u05D9\u05DE\u05EA \u05E2\u05DE\u05D5\u05D3\u05D5\u05EA",
  ariaFilterPanelList: "\u05E8\u05E9\u05D9\u05DE\u05EA \u05DE\u05E1\u05E0\u05E0\u05D9\u05DD",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "True",
  false: "False",
  invalidDate: "\u05EA\u05D0\u05E8\u05D9\u05DA \u05DC\u05D0 \u05D7\u05D5\u05E7\u05D9",
  invalidNumber: "\u05DE\u05E1\u05E4\u05E8 \u05DC\u05D0 \u05D7\u05D5\u05E7\u05D9",
  january: "\u05D9\u05E0\u05D5\u05D0\u05E8",
  february: "\u05E4\u05D1\u05E8\u05D5\u05D0\u05E8",
  march: "\u05DE\u05E8\u05E5",
  april: "\u05D0\u05E4\u05E8\u05D9\u05DC",
  may: "\u05DE\u05D0\u05D9",
  june: "\u05D9\u05D5\u05E0\u05D9",
  july: "\u05D9\u05D5\u05DC\u05D9",
  august: "\u05D0\u05D5\u05D2\u05D5\u05E1\u05D8",
  september: "\u05E1\u05E4\u05D8\u05DE\u05D1\u05E8",
  october: "\u05D0\u05D5\u05E7\u05D8\u05D5\u05D1\u05E8",
  november: "\u05E0\u05D5\u05D1\u05DE\u05D1\u05E8",
  december: "\u05D3\u05E6\u05DE\u05D1\u05E8",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "\u05E9\u05E2\u05D4:\u05D3\u05E7\u05D4:\u05E9\u05E0\u05D9\u05D9\u05D4",
  timeFormatHHMMSSAmPm: "\u05E9\u05E2\u05D4:\u05D3\u05E7\u05D4:\u05E9\u05E0\u05D9\u05D9\u05D4 \u05DC\u05E4\u05E0\u05D4\u05F4\u05E6/\u05D0\u05D7\u05D4\u05F4\u05E6"
};

// community-modules/locale/src/hr-HR.ts
var AG_GRID_LOCALE_HR = {
  // Set Filter
  selectAll: "(Odaberi sve)",
  selectAllSearchResults: "(Odaberi sve rezultate pretra\u017Eivanja)",
  addCurrentSelectionToFilter: "Dodaj trenutni odabir u filter",
  searchOoo: "Tra\u017Ei...",
  blanks: "(Praznine)",
  noMatches: "Nema podudaranja",
  // Number Filter & Text Filter
  filterOoo: "Filtriraj...",
  equals: "Jednako",
  notEqual: "Nije jednako",
  blank: "Prazno",
  notBlank: "Nije prazno",
  empty: "Odaberite jedan",
  // Number Filter
  lessThan: "Manje od",
  greaterThan: "Ve\u0107e od",
  lessThanOrEqual: "Manje ili jednako",
  greaterThanOrEqual: "Ve\u0107e ili jednako",
  inRange: "Izme\u0111u",
  inRangeStart: "Od",
  inRangeEnd: "Do",
  // Text Filter
  contains: "Sadr\u017Ei",
  notContains: "Ne sadr\u017Ei",
  startsWith: "Po\u010Dinje s",
  endsWith: "Zavr\u0161ava s",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Prije",
  after: "Poslije",
  // Filter Conditions
  andCondition: "I",
  orCondition: "ILI",
  // Filter Buttons
  applyFilter: "Primijeni",
  resetFilter: "Poni\u0161ti",
  clearFilter: "O\u010Disti",
  cancelFilter: "Odustani",
  // Filter Titles
  textFilter: "Tekstualni filter",
  numberFilter: "Numeri\u010Dki filter",
  dateFilter: "Filtar datuma",
  setFilter: "Filtar skupa",
  // Group Column Filter
  groupFilterSelect: "Odaberite polje:",
  // Advanced Filter
  advancedFilterContains: "sadr\u017Ei",
  advancedFilterNotContains: "ne sadr\u017Ei",
  advancedFilterTextEquals: "jednako",
  advancedFilterTextNotEqual: "nije jednako",
  advancedFilterStartsWith: "zapo\u010Dinje s",
  advancedFilterEndsWith: "zavr\u0161ava s",
  advancedFilterBlank: "je prazno",
  advancedFilterNotBlank: "nije prazno",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "je to\u010Dno",
  advancedFilterFalse: "je neto\u010Dno",
  advancedFilterAnd: "I",
  advancedFilterOr: "ILI",
  advancedFilterApply: "Primijeni",
  advancedFilterBuilder: "Graditelj",
  advancedFilterValidationMissingColumn: "Nedostaje stupac",
  advancedFilterValidationMissingOption: "Nedostaje opcija",
  advancedFilterValidationMissingValue: "Nedostaje vrijednost",
  advancedFilterValidationInvalidColumn: "Stupac nije prona\u0111en",
  advancedFilterValidationInvalidOption: "Opcija nije prona\u0111ena",
  advancedFilterValidationMissingQuote: "Vrijednosti nedostaje zavr\u0161na navodna oznaka",
  advancedFilterValidationNotANumber: "Vrijednost nije broj",
  advancedFilterValidationInvalidDate: "Vrijednost nije valjani datum",
  advancedFilterValidationMissingCondition: "Nedostaje uvjet",
  advancedFilterValidationJoinOperatorMismatch: "Spojni operatori unutar uvjeta moraju biti isti",
  advancedFilterValidationInvalidJoinOperator: "Spojni operator nije prona\u0111en",
  advancedFilterValidationMissingEndBracket: "Nedostaje zavr\u0161na zagrada",
  advancedFilterValidationExtraEndBracket: "Previ\u0161e zavr\u0161nih zagrada",
  advancedFilterValidationMessage: "Izraz ima gre\u0161ku. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Izraz ima gre\u0161ku. ${variable} na kraju izraza.",
  advancedFilterBuilderTitle: "Napredni filter",
  advancedFilterBuilderApply: "Primijeni",
  advancedFilterBuilderCancel: "Odustani",
  advancedFilterBuilderAddButtonTooltip: "Dodaj filter ili grupu",
  advancedFilterBuilderRemoveButtonTooltip: "Ukloni",
  advancedFilterBuilderMoveUpButtonTooltip: "Pomakni gore",
  advancedFilterBuilderMoveDownButtonTooltip: "Pomakni dolje",
  advancedFilterBuilderAddJoin: "Dodaj grupu",
  advancedFilterBuilderAddCondition: "Dodaj filter",
  advancedFilterBuilderSelectColumn: "Odaberi stupac",
  advancedFilterBuilderSelectOption: "Odaberi opciju",
  advancedFilterBuilderEnterValue: "Unesi vrijednost...",
  advancedFilterBuilderValidationAlreadyApplied: "Trenutni filter je ve\u0107 primijenjen.",
  advancedFilterBuilderValidationIncomplete: "Nisu svi uvjeti ispunjeni.",
  advancedFilterBuilderValidationSelectColumn: "Mora\u0161 odabrati stupac.",
  advancedFilterBuilderValidationSelectOption: "Mora\u0161 odabrati opciju.",
  advancedFilterBuilderValidationEnterValue: "Mora\u0161 unijeti vrijednost.",
  // Side Bar
  columns: "Stupci",
  filters: "Filteri",
  // columns tool panel
  pivotMode: "Na\u010Din okretanja",
  groups: "Grupiranja redaka",
  rowGroupColumnsEmptyMessage: "Povucite ovdje za postavljanje grupiranja redaka",
  values: "Vrijednosti",
  valueColumnsEmptyMessage: "Povucite ovdje za izvo\u0111enje agregacije",
  pivots: "Oznake stupaca",
  pivotColumnsEmptyMessage: "Povucite ovdje za postavljanje oznaka stupaca",
  // Header of the Default Group Column
  group: "Grupa",
  // Row Drag
  rowDragRow: "redak",
  rowDragRows: "redci",
  // Other
  loadingOoo: "U\u010Ditavanje...",
  loadingError: "GRE\u0160KA",
  noRowsToShow: "Nema redaka za prikaz",
  enabled: "Omogu\u0107eno",
  // Menu
  pinColumn: "Prikva\u010Di stupac",
  pinLeft: "Prikva\u010Di lijevo",
  pinRight: "Prikva\u010Di desno",
  noPin: "Bez prikva\u010Divanja",
  valueAggregation: "Agregacija vrijednosti",
  noAggregation: "Nema",
  autosizeThisColumn: "Automatski prilagodi ovaj stupac",
  autosizeAllColumns: "Automatski prilagodi sve stupce",
  groupBy: "Grupiraj po",
  ungroupBy: "Razgrupiraj po",
  ungroupAll: "Razgrupiraj sve",
  addToValues: "Dodaj ${variable} u vrijednosti",
  removeFromValues: "Ukloni ${variable} iz vrijednosti",
  addToLabels: "Dodaj ${variable} u oznake",
  removeFromLabels: "Ukloni ${variable} iz oznaka",
  resetColumns: "Resetiraj stupce",
  expandAll: "Pro\u0161iri sve grupe redova",
  collapseAll: "Zatvori sve grupe redova",
  copy: "Kopiraj",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopiraj s zaglavljima",
  copyWithGroupHeaders: "Kopiraj s grupnim zaglavljima",
  cut: "Izre\u017Ei",
  paste: "Zalijepi",
  ctrlV: "Ctrl+V",
  export: "Izvoz",
  csvExport: "Izvoz u CSV",
  excelExport: "Izvoz u Excel",
  columnFilter: "Filter stupca",
  columnChooser: "Izbor stupaca",
  chooseColumns: "Izbor stupaca",
  sortAscending: "Sortiraj uzlazno",
  sortDescending: "Sortiraj silazno",
  sortUnSort: "O\u010Disti sortiranje",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Zbroj",
  first: "Prvi",
  last: "Zadnji",
  min: "Min",
  max: "Max",
  none: "Ni\u0161ta",
  count: "Broj",
  avg: "Prosjek",
  filteredRows: "Filtrirano",
  selectedRows: "Odabrano",
  totalRows: "Ukupno redaka",
  totalAndFilteredRows: "Redaka",
  more: "Vi\u0161e",
  to: "do",
  of: "od",
  page: "Stranica",
  pageLastRowUnknown: "?",
  nextPage: "Sljede\u0107a stranica",
  lastPage: "Zadnja stranica",
  firstPage: "Prva stranica",
  previousPage: "Prethodna stranica",
  pageSizeSelectorLabel: "Veli\u010Dina stranice:",
  footerTotal: "Ukupno",
  // Pivoting
  pivotColumnGroupTotals: "Ukupno",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Zakretna tabela i na\u010Din zakretanja",
  pivotChart: "Zakretna tabela",
  chartRange: "Raspon grafikona",
  columnChart: "Stupac",
  groupedColumn: "Grupirano",
  stackedColumn: "Slo\u017Eeno",
  normalizedColumn: "100% Slo\u017Eeno",
  barChart: "Traka",
  groupedBar: "Grupirano",
  stackedBar: "Slo\u017Eeno",
  normalizedBar: "100% Slo\u017Eeno",
  pieChart: "Pita",
  pie: "Pita",
  donut: "Krafna",
  line: "Linija",
  xyChart: "X Y (Raspr\u0161enost)",
  scatter: "Raspr\u0161enost",
  bubble: "Mjehuri\u0107",
  areaChart: "Podru\u010Dje",
  area: "Podru\u010Dje",
  stackedArea: "Slo\u017Eeno",
  normalizedArea: "100% Slo\u017Eeno",
  histogramChart: "Histogram",
  polarChart: "Polarni",
  radarLine: "Radarska linija",
  radarArea: "Radarsko podru\u010Dje",
  nightingale: "Nightingale",
  radialColumn: "Radijalni stupac",
  radialBar: "Radijalna traka",
  statisticalChart: "Statisti\u010Dki",
  boxPlot: "Kutija graf",
  rangeBar: "Raspon traka",
  rangeArea: "Raspon podru\u010Dje",
  hierarchicalChart: "Hijerarhijski",
  treemap: "Karta stabla",
  sunburst: "Sun\u010Dev prasak",
  specializedChart: "Specijalizirani",
  waterfall: "Vodopad",
  heatmap: "Toplinska karta",
  combinationChart: "Kombinacija",
  columnLineCombo: "Stupac i linija",
  AreaColumnCombo: "Podru\u010Dje i stupac",
  // Charts
  pivotChartTitle: "Pivot Graf",
  rangeChartTitle: "Graf Raspona",
  settings: "Graf",
  data: "Postavke",
  format: "Prilagodba",
  categories: "Kategorije",
  defaultCategory: "(Nijedna)",
  series: "Serije",
  switchCategorySeries: "Promijeni Kategoriju / Serije",
  categoryValues: "Vrijednosti Kategorije",
  seriesLabels: "Oznake Serija",
  aggregate: "Zbir",
  xyValues: "X Y Vrijednosti",
  paired: "Upareni Na\u010Din",
  axis: "Os",
  xAxis: "Horizontalna Os",
  yAxis: "Vertikalna Os",
  polarAxis: "Polarna Os",
  radiusAxis: "Radijus Os",
  navigator: "Navigator",
  zoom: "Zum",
  animation: "Animacija",
  crosshair: "Kri\u017E",
  color: "Boja",
  thickness: "Debljina",
  preferredLength: "Po\u017Eeljna Duljina",
  xType: "X Tip",
  axisType: "Tip Osi",
  automatic: "Automatski",
  category: "Kategorija",
  number: "Broj",
  time: "Vrijeme",
  timeFormat: "Format Vremena",
  autoRotate: "Auto Rotacija",
  labelRotation: "Rotacija",
  circle: "Krug",
  polygon: "Poligon",
  square: "Kvadrat",
  cross: "Kri\u017E",
  diamond: "Dijamant",
  plus: "Plus",
  triangle: "Trokut",
  heart: "Srce",
  orientation: "Orijentacija",
  fixed: "Fiksno",
  parallel: "Paralelno",
  perpendicular: "Okomito",
  radiusAxisPosition: "Pozicija",
  ticks: "Oznake",
  gridLines: "Mre\u017Ene Linije",
  width: "\u0160irina",
  height: "Visina",
  length: "Duljina",
  padding: "Razmak",
  spacing: "Razmak",
  chartStyle: "Stil Grafa",
  title: "Naslov",
  chartTitles: "Naslovi",
  chartTitle: "Naslov Grafa",
  chartSubtitle: "Podnaslov",
  horizontalAxisTitle: "Naslov Horizontalne Osi",
  verticalAxisTitle: "Naslov Vertikalne Osi",
  polarAxisTitle: "Naslov Polarne Osi",
  titlePlaceholder: "Naslov Grafa",
  background: "Pozadina",
  font: "Font",
  weight: "Te\u017Eina",
  top: "Gore",
  right: "Desno",
  bottom: "Dolje",
  left: "Lijevo",
  labels: "Oznake",
  calloutLabels: "Natpisne Oznake",
  sectorLabels: "Oznake Sektora",
  positionRatio: "Omjer Pozicije",
  size: "Veli\u010Dina",
  shape: "Oblik",
  minSize: "Minimalna Veli\u010Dina",
  maxSize: "Maksimalna Veli\u010Dina",
  legend: "Legenda",
  position: "Pozicija",
  markerSize: "Veli\u010Dina Oznake",
  markerStroke: "Obrub Oznake",
  markerPadding: "Razmak Oznake",
  itemSpacing: "Razmak Stavki",
  itemPaddingX: "Razmak Stavki X",
  itemPaddingY: "Razmak Stavki Y",
  layoutHorizontalSpacing: "Horizontalni Razmak",
  layoutVerticalSpacing: "Vertikalni Razmak",
  strokeWidth: "Debljina Obruba",
  offset: "Pomak",
  offsets: "Pomaci",
  tooltips: "Savjeti",
  callout: "Natpis",
  markers: "Oznake",
  shadow: "Sjena",
  blur: "Zamagljenje",
  xOffset: "X Pomak",
  yOffset: "Y Pomak",
  lineWidth: "Debljina Linije",
  lineDash: "Isprekidana Linija",
  lineDashOffset: "Pomak Isprekidanih Linija",
  scrollingZoom: "Pomi\u010Dni Zum",
  scrollingStep: "Korak Pomicanja",
  selectingZoom: "Odabir",
  durationMillis: "Trajanje (ms)",
  crosshairLabel: "Oznaka",
  crosshairSnap: "Snap na \u010Cvor",
  normal: "Normalno",
  bold: "Podebljano",
  italic: "Kurziv",
  boldItalic: "Podebljano Kurziv",
  predefined: "Predefinirano",
  fillOpacity: "Prozirnost Ispune",
  strokeColor: "Boja Linije",
  strokeOpacity: "Prozirnost Linije",
  miniChart: "Mini Graf",
  histogramBinCount: "Broj Kanta",
  connectorLine: "Poveziva\u010Dka Linija",
  seriesItems: "Stavke Serija",
  seriesItemType: "Tip Stavke",
  seriesItemPositive: "Pozitivno",
  seriesItemNegative: "Negativno",
  seriesItemLabels: "Oznake Stavki",
  columnGroup: "Stupac",
  barGroup: "Traka",
  pieGroup: "Pita",
  lineGroup: "Linija",
  scatterGroup: "X Y (Raspr\u0161enje)",
  areaGroup: "Podru\u010Dje",
  polarGroup: "Polarni",
  statisticalGroup: "Statisti\u010Dki",
  hierarchicalGroup: "Hijerarhijski",
  specializedGroup: "Specijalizirani",
  combinationGroup: "Kombinacija",
  groupedColumnTooltip: "Grupirani",
  stackedColumnTooltip: "Naslagani",
  normalizedColumnTooltip: "100% Naslagani",
  groupedBarTooltip: "Grupirani",
  stackedBarTooltip: "Naslagani",
  normalizedBarTooltip: "100% Naslagani",
  pieTooltip: "Pita",
  donutTooltip: "Krafna",
  lineTooltip: "Linija",
  groupedAreaTooltip: "Podru\u010Dje",
  stackedAreaTooltip: "Naslagano",
  normalizedAreaTooltip: "100% Naslagano",
  scatterTooltip: "Raspr\u0161enje",
  bubbleTooltip: "Balon",
  histogramTooltip: "Histograma",
  radialColumnTooltip: "Radijalni Stupac",
  radialBarTooltip: "Radijalna Traka",
  radarLineTooltip: "Radar Linija",
  radarAreaTooltip: "Radar Podru\u010Dje",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Raspon Trake",
  rangeAreaTooltip: "Raspon Podru\u010Dja",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Slap",
  heatmapTooltip: "Toplinska Mapa",
  columnLineComboTooltip: "Stupac i Linija",
  areaColumnComboTooltip: "Podru\u010Dje i Stupac",
  customComboTooltip: "Prilago\u0111ena Kombinacija",
  innerRadius: "Unutarnji Radijus",
  startAngle: "Po\u010Detni Kut",
  endAngle: "Zavr\u0161ni Kut",
  reverseDirection: "Obrnuti Smjer",
  groupPadding: "Razmak Grupe",
  seriesPadding: "Razmak Serija",
  tile: "Plo\u010Dica",
  whisker: "Brk",
  cap: "Poklopac",
  capLengthRatio: "Omjer Duljine",
  labelPlacement: "Postavljanje Oznake",
  inside: "Unutra",
  outside: "Vani",
  noDataToChart: "Nema podataka za prikaz.",
  pivotChartRequiresPivotMode: "Pivot Graf zahtijeva uklju\u010Den Pivot na\u010Din.",
  chartSettingsToolbarTooltip: "Izbornik",
  chartLinkToolbarTooltip: "Povezano s Mre\u017Eom",
  chartUnlinkToolbarTooltip: "Nepovezano od Mre\u017Ee",
  chartDownloadToolbarTooltip: "Preuzmi Graf",
  chartMenuToolbarTooltip: "Izbornik",
  chartEdit: "Uredi Graf",
  chartAdvancedSettings: "Napredne Postavke",
  chartLink: "Pove\u017Ei s Mre\u017Eom",
  chartUnlink: "Odspoji od Mre\u017Ee",
  chartDownload: "Preuzmi Graf",
  histogramFrequency: "Frekvencija",
  seriesChartType: "Tip Serije Grafa",
  seriesType: "Tip Serije",
  secondaryAxis: "Sekundarna Os",
  seriesAdd: "Dodaj seriju",
  categoryAdd: "Dodaj kategoriju",
  bar: "Traka",
  column: "Stupac",
  histogram: "Histograma",
  advancedSettings: "Napredne Postavke",
  direction: "Smjer",
  horizontal: "Horizontalno",
  vertical: "Vertikalno",
  seriesGroupType: "Tip Grupe Serije",
  groupedSeriesGroupType: "Grupirani",
  stackedSeriesGroupType: "Naslagani",
  normalizedSeriesGroupType: "100% Naslagani",
  legendEnabled: "Omogu\u0107eno",
  invalidColor: "Neva\u017Ee\u0107a vrijednost boje",
  groupedColumnFull: "Grupirani Stupac",
  stackedColumnFull: "Naslagani Stupac",
  normalizedColumnFull: "100% Naslagani Stupac",
  groupedBarFull: "Grupirana Traka",
  stackedBarFull: "Naslagana Traka",
  normalizedBarFull: "100% Naslagana Traka",
  stackedAreaFull: "Naslagano Podru\u010Dje",
  normalizedAreaFull: "100% Naslagano Podru\u010Dje",
  customCombo: "Prilago\u0111ena Kombinacija",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Razina ${variable}. Pritisnite ENTER za ure\u0111ivanje.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Razina ${variable}. ${variable} Pritisnite ENTER za ure\u0111ivanje.",
  ariaAdvancedFilterBuilderList: "Popis naprednog graditelja filtera",
  ariaAdvancedFilterBuilderFilterItem: "Uvjet filtera",
  ariaAdvancedFilterBuilderGroupItem: "Grupa filtera",
  ariaAdvancedFilterBuilderColumn: "Stupac",
  ariaAdvancedFilterBuilderOption: "Opcija",
  ariaAdvancedFilterBuilderValueP: "Vrijednost",
  ariaAdvancedFilterBuilderJoinOperator: "Operator spajanja",
  ariaAdvancedFilterInput: "Unos naprednog filtera",
  ariaChecked: "ozna\u010Deno",
  ariaColumn: "Stupac",
  ariaColumnGroup: "Grupa stupaca",
  ariaColumnFiltered: "Stupac filtriran",
  ariaColumnSelectAll: "Prebaci odabir svih stupaca",
  ariaDateFilterInput: "Unos filtera datuma",
  ariaDefaultListName: "Popis",
  ariaFilterColumnsInput: "Unos za filtriranje stupaca",
  ariaFilterFromValue: "Filtriraj od vrijednosti",
  ariaFilterInput: "Unos filtera",
  ariaFilterList: "Popis filtera",
  ariaFilterToValue: "Filtriraj do vrijednosti",
  ariaFilterValue: "Vrijednost filtera",
  ariaFilterMenuOpen: "Otvori izbornik filtera",
  ariaFilteringOperator: "Operator filtriranja",
  ariaHidden: "skriveno",
  ariaIndeterminate: "neodre\u0111eno",
  ariaInputEditor: "Ure\u0111iva\u010D unosa",
  ariaMenuColumn: "Pritisnite ALT DOLJE za otvaranje izbornika stupca",
  ariaFilterColumn: "Pritisnite CTRL ENTER za otvaranje filtera",
  ariaRowDeselect: "Pritisnite SPACE za poni\u0161tavanje odabira ovog retka",
  ariaRowSelectAll: "Pritisnite SPACE za uklju\u010Divanje/isklju\u010Divanje odabira svih redaka",
  ariaRowToggleSelection: "Pritisnite SPACE za uklju\u010Divanje/isklju\u010Divanje odabira retka",
  ariaRowSelect: "Pritisnite SPACE za odabir ovog retka",
  ariaRowSelectionDisabled: "Odabir retka je onemogu\u0107en za ovaj redak",
  ariaSearch: "Pretra\u017Eivanje",
  ariaSortableColumn: "Pritisnite ENTER za sortiranje",
  ariaToggleVisibility: "Pritisnite SPACE za uklju\u010Divanje/isklju\u010Divanje vidljivosti",
  ariaToggleCellValue: "Pritisnite SPACE za uklju\u010Divanje/isklju\u010Divanje vrijednosti \u0107elije",
  ariaUnchecked: "neozna\u010Deno",
  ariaVisible: "vidljivo",
  ariaSearchFilterValues: "Pretra\u017Ei vrijednosti filtera",
  ariaPageSizeSelectorLabel: "Veli\u010Dina stranice",
  ariaChartMenuClose: "Zatvori izbornik ure\u0111ivanja grafikona",
  ariaChartSelected: "Odabrano",
  ariaSkeletonCellLoadingFailed: "Redak nije uspje\u0161no u\u010Ditan",
  ariaSkeletonCellLoading: "Podaci retka se u\u010Ditavaju",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Grupe redaka",
  ariaValuesDropZonePanelLabel: "Vrijednosti",
  ariaPivotDropZonePanelLabel: "Oznake stupaca",
  ariaDropZoneColumnComponentDescription: "Pritisnite DELETE za uklanjanje",
  ariaDropZoneColumnValueItemDescription: "Pritisnite ENTER za promjenu tipa agregacije",
  ariaDropZoneColumnGroupItemDescription: "Pritisnite ENTER za sortiranje",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " od ",
  ariaDropZoneColumnComponentSortAscending: "uzlazno",
  ariaDropZoneColumnComponentSortDescending: "silazno",
  ariaLabelDialog: "Dijalog",
  ariaLabelColumnMenu: "Izbornik stupca",
  ariaLabelColumnFilter: "Filtar stupca",
  ariaLabelCellEditor: "Ure\u0111iva\u010D \u0107elija",
  ariaLabelSelectField: "Odaberi polje",
  // aria labels for rich select
  ariaLabelRichSelectField: "Polje s bogatim izborom",
  ariaLabelRichSelectToggleSelection: "Pritisnite RAZMAKNICU za prebacivanje odabira",
  ariaLabelRichSelectDeselectAllItems: "Pritisnite DELETE za poni\u0161tavanje odabira svih stavki",
  ariaLabelRichSelectDeleteSelection: "Pritisnite DELETE za poni\u0161tavanje odabira stavke",
  ariaLabelTooltip: "Alatni savjet",
  ariaLabelContextMenu: "Kontekstni izbornik",
  ariaLabelSubMenu: "Podizbornik",
  ariaLabelAggregationFunction: "Funkcija agregacije",
  ariaLabelAdvancedFilterAutocomplete: "Napredna funkcija automatskog dovr\u0161avanja filtera",
  ariaLabelAdvancedFilterBuilderAddField: "Napredni graditelj filtera - dodajte polje",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Napredni graditelj filtera - odaberite polje stupca",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Napredni graditelj filtera - odaberite polje opcije",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Napredni graditelj filtera - odaberite polje operatera za spajanje",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Popis stupaca",
  ariaFilterPanelList: "Popis filtara",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Istina",
  false: "La\u017Eno",
  invalidDate: "Neva\u017Ee\u0107i datum",
  invalidNumber: "Neva\u017Ee\u0107i broj",
  january: "Sije\u010Danj",
  february: "Velja\u010Da",
  march: "O\u017Eujak",
  april: "Travanj",
  may: "Svibanj",
  june: "Lipanj",
  july: "Srpanj",
  august: "Kolovoz",
  september: "Rujan",
  october: "Listopad",
  november: "Studeni",
  december: "Prosinac",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/GGGG",
  timeFormatSlashesMMDDYYYY: "MM/DD/GGGG",
  timeFormatSlashesDDMMYY: "DD/MM/GG",
  timeFormatSlashesMMDDYY: "MM/DD/GG",
  timeFormatDotsDDMYY: "DD.M.GG",
  timeFormatDotsMDDYY: "M.DD.GG",
  timeFormatDashesYYYYMMDD: "GGGG-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM GGGG",
  timeFormatHHMMSS: "SS:MM:VV",
  timeFormatHHMMSSAmPm: "SS:MM:VV AM/PM"
};

// community-modules/locale/src/hu-HU.ts
var AG_GRID_LOCALE_HU = {
  // Set Filter
  selectAll: "(Mindet kiv\xE1laszt)",
  selectAllSearchResults: "(\xD6sszes keres\xE9si tal\xE1lat kiv\xE1laszt\xE1sa)",
  addCurrentSelectionToFilter: "Aktu\xE1lis kiv\xE1laszt\xE1s hozz\xE1ad\xE1sa a sz\u0171r\u0151h\xF6z",
  searchOoo: "Keres\xE9s...",
  blanks: "(\xDCres)",
  noMatches: "Nincs tal\xE1lat",
  // Number Filter & Text Filter
  filterOoo: "Sz\u0171r\u0151...",
  equals: "Egyenl\u0151",
  notEqual: "Nem egyenl\u0151",
  blank: "\xDCres",
  notBlank: "Nem \xFCres",
  empty: "V\xE1lasszon egyet",
  // Number Filter
  lessThan: "Kevesebb mint",
  greaterThan: "T\xF6bb mint",
  lessThanOrEqual: "Kevesebb vagy egyenl\u0151",
  greaterThanOrEqual: "T\xF6bb vagy egyenl\u0151",
  inRange: "K\xF6z\xF6tt",
  inRangeStart: "Innen",
  inRangeEnd: "Eddig",
  // Text Filter
  contains: "Tartalmazza",
  notContains: "Nem tartalmazza",
  startsWith: "Ezzel kezd\u0151dik",
  endsWith: "Ezzel v\xE9gz\u0151dik",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "El\u0151tte",
  after: "Ut\xE1na",
  // Filter Conditions
  andCondition: "\xC9S",
  orCondition: "VAGY",
  // Filter Buttons
  applyFilter: "Alkalmaz",
  resetFilter: "Vissza\xE1ll\xEDt\xE1s",
  clearFilter: "T\xF6rl\xE9s",
  cancelFilter: "M\xE9gse",
  // Filter Titles
  textFilter: "Sz\xF6vegsz\u0171r\u0151",
  numberFilter: "Sz\xE1m sz\u0171r\u0151",
  dateFilter: "D\xE1tum sz\u0171r\u0151",
  setFilter: "K\xE9szlet sz\u0171r\u0151",
  // Group Column Filter
  groupFilterSelect: "V\xE1lassza ki a mez\u0151t:",
  // Advanced Filter
  advancedFilterContains: "tartalmazza",
  advancedFilterNotContains: "nem tartalmazza",
  advancedFilterTextEquals: "egyenl\u0151",
  advancedFilterTextNotEqual: "nem egyenl\u0151",
  advancedFilterStartsWith: "ezzel kezd\u0151dik",
  advancedFilterEndsWith: "ezzel v\xE9gz\u0151dik",
  advancedFilterBlank: "\xFCres",
  advancedFilterNotBlank: "nem \xFCres",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "igaz",
  advancedFilterFalse: "hamis",
  advancedFilterAnd: "\xC9S",
  advancedFilterOr: "VAGY",
  advancedFilterApply: "Alkalmaz",
  advancedFilterBuilder: "Sz\u0171r\u0151 K\xE9sz\xEDt\u0151",
  advancedFilterValidationMissingColumn: "Oszlop hi\xE1nyzik",
  advancedFilterValidationMissingOption: "Opci\xF3 hi\xE1nyzik",
  advancedFilterValidationMissingValue: "\xC9rt\xE9k hi\xE1nyzik",
  advancedFilterValidationInvalidColumn: "Oszlop nem tal\xE1lhat\xF3",
  advancedFilterValidationInvalidOption: "Opci\xF3 nem tal\xE1lhat\xF3",
  advancedFilterValidationMissingQuote: "Id\xE9z\u0151jel hi\xE1nyzik az \xE9rt\xE9k v\xE9g\xE9r\u0151l",
  advancedFilterValidationNotANumber: "\xC9rt\xE9k nem sz\xE1m",
  advancedFilterValidationInvalidDate: "\xC9rt\xE9k nem \xE9rv\xE9nyes d\xE1tum",
  advancedFilterValidationMissingCondition: "Felt\xE9tel hi\xE1nyzik",
  advancedFilterValidationJoinOperatorMismatch: "A felt\xE9telek \xF6sszekapcsol\xF3 oper\xE1torai azonosak kell, hogy legyenek",
  advancedFilterValidationInvalidJoinOperator: "\xD6sszekapcsol\xF3 oper\xE1tor nem tal\xE1lhat\xF3",
  advancedFilterValidationMissingEndBracket: "Hi\xE1nyz\xF3 z\xE1r\xF3z\xE1r\xF3jelek",
  advancedFilterValidationExtraEndBracket: "T\xFAl sok z\xE1r\xF3z\xE1r\xF3jel",
  advancedFilterValidationMessage: "A kifejez\xE9s hib\xE1t tartalmaz. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "A kifejez\xE9s hib\xE1t tartalmaz. ${variable} a kifejez\xE9s v\xE9g\xE9n.",
  advancedFilterBuilderTitle: "Speci\xE1lis Sz\u0171r\u0151",
  advancedFilterBuilderApply: "Alkalmaz",
  advancedFilterBuilderCancel: "M\xE9gsem",
  advancedFilterBuilderAddButtonTooltip: "Sz\u0171r\u0151 vagy Csoport hozz\xE1ad\xE1sa",
  advancedFilterBuilderRemoveButtonTooltip: "Elt\xE1vol\xEDt\xE1s",
  advancedFilterBuilderMoveUpButtonTooltip: "Mozgat\xE1s felfel\xE9",
  advancedFilterBuilderMoveDownButtonTooltip: "Mozgat\xE1s lefel\xE9",
  advancedFilterBuilderAddJoin: "Csoport hozz\xE1ad\xE1sa",
  advancedFilterBuilderAddCondition: "Sz\u0171r\u0151 hozz\xE1ad\xE1sa",
  advancedFilterBuilderSelectColumn: "Oszlop kiv\xE1laszt\xE1sa",
  advancedFilterBuilderSelectOption: "Opci\xF3 kiv\xE1laszt\xE1sa",
  advancedFilterBuilderEnterValue: "\xC9rt\xE9k megad\xE1sa...",
  advancedFilterBuilderValidationAlreadyApplied: "Az aktu\xE1lis sz\u0171r\u0151 m\xE1r alkalmazva.",
  advancedFilterBuilderValidationIncomplete: "Nem minden felt\xE9tel teljes.",
  advancedFilterBuilderValidationSelectColumn: "Oszlopot kell v\xE1lasztani.",
  advancedFilterBuilderValidationSelectOption: "Opci\xF3t kell v\xE1lasztani.",
  advancedFilterBuilderValidationEnterValue: "\xC9rt\xE9ket kell megadni.",
  // Side Bar
  columns: "Oszlopok",
  filters: "Sz\u0171r\u0151k",
  // columns tool panel
  pivotMode: "Forgat\xE1si m\xF3d",
  groups: "Sor csoportok",
  rowGroupColumnsEmptyMessage: "H\xFAzza ide a sorcsoportok be\xE1ll\xEDt\xE1s\xE1hoz",
  values: "\xC9rt\xE9kek",
  valueColumnsEmptyMessage: "H\xFAzza ide az \xF6sszes\xEDt\xE9shez",
  pivots: "Oszlop c\xEDmk\xE9k",
  pivotColumnsEmptyMessage: "H\xFAzza ide az oszlopc\xEDmk\xE9k be\xE1ll\xEDt\xE1s\xE1hoz",
  // Header of the Default Group Column
  group: "Csoport",
  // Row Drag
  rowDragRow: "sor",
  rowDragRows: "sorok",
  // Other
  loadingOoo: "Bet\xF6lt\xE9s...",
  loadingError: "HIBA",
  noRowsToShow: "Nincs megjelen\xEDthet\u0151 sor",
  enabled: "Enged\xE9lyezve",
  // Menu
  pinColumn: "Oszlop r\xF6gz\xEDt\xE9se",
  pinLeft: "R\xF6gz\xEDt\xE9s balra",
  pinRight: "R\xF6gz\xEDt\xE9s jobbra",
  noPin: "Nincs r\xF6gz\xEDt\xE9s",
  valueAggregation: "\xC9rt\xE9k \xF6sszes\xEDt\xE9s",
  noAggregation: "Nincs",
  autosizeThisColumn: "Oszlop automatikus \xE1tm\xE9retez\xE9se",
  autosizeAllColumns: "\xD6sszes oszlop automatikus \xE1tm\xE9retez\xE9se",
  groupBy: "Csoportos\xEDt\xE1s",
  ungroupBy: "Csoportbont\xE1s",
  ungroupAll: "\xD6sszes csoport bont\xE1sa",
  addToValues: "${variable} hozz\xE1ad\xE1sa az \xE9rt\xE9kekhez",
  removeFromValues: "${variable} elt\xE1vol\xEDt\xE1sa az \xE9rt\xE9kek k\xF6z\xFCl",
  addToLabels: "${variable} hozz\xE1ad\xE1sa a c\xEDmk\xE9khez",
  removeFromLabels: "${variable} elt\xE1vol\xEDt\xE1sa a c\xEDmk\xE9k k\xF6z\xFCl",
  resetColumns: "Oszlopok vissza\xE1ll\xEDt\xE1sa",
  expandAll: "\xD6sszes sorcsoport kibont\xE1sa",
  collapseAll: "\xD6sszes sorcsoport bez\xE1r\xE1sa",
  copy: "M\xE1sol\xE1s",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "M\xE1sol\xE1s fejl\xE9cekkel",
  copyWithGroupHeaders: "M\xE1sol\xE1s csoportos fejl\xE9cekkel",
  cut: "Kiv\xE1g\xE1s",
  paste: "Beilleszt\xE9s",
  ctrlV: "Ctrl+V",
  export: "Export\xE1l\xE1s",
  csvExport: "CSV export\xE1l\xE1s",
  excelExport: "Excel export\xE1l\xE1s",
  columnFilter: "Oszlopsz\u0171r\xE9s",
  columnChooser: "Oszlopok kiv\xE1laszt\xE1sa",
  chooseColumns: "Oszlopok kiv\xE1laszt\xE1sa",
  sortAscending: "Rendez\xE9s n\xF6vekv\u0151 sorrendben",
  sortDescending: "Rendez\xE9s cs\xF6kken\u0151 sorrendben",
  sortUnSort: "Rendez\xE9s t\xF6rl\xE9se",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\xD6sszeg",
  first: "Els\u0151",
  last: "Utols\xF3",
  min: "Minimum",
  max: "Maximum",
  none: "Nincs",
  count: "Darabsz\xE1m",
  avg: "\xC1tlag",
  filteredRows: "Sz\u0171rt",
  selectedRows: "Kiv\xE1lasztott",
  totalRows: "\xD6sszes sor",
  totalAndFilteredRows: "Sorok",
  more: "T\xF6bb",
  to: "t\xF3l",
  of: " -b\u0151l",
  page: "Oldal",
  pageLastRowUnknown: "?",
  nextPage: "K\xF6vetkez\u0151 oldal",
  lastPage: "Utols\xF3 oldal",
  firstPage: "Els\u0151 oldal",
  previousPage: "El\u0151z\u0151 oldal",
  pageSizeSelectorLabel: "Oldalm\xE9ret:",
  footerTotal: "\xD6sszesen",
  // Pivoting
  pivotColumnGroupTotals: "\xD6sszesen",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Diagram & Pivot M\xF3d",
  pivotChart: "Pivot Diagram",
  chartRange: "Diagram Tartom\xE1ny",
  columnChart: "Oszlopdiagram",
  groupedColumn: "Csoportos\xEDtott",
  stackedColumn: "Halmozott",
  normalizedColumn: "100% Halmozott",
  barChart: "S\xE1vdiagram",
  groupedBar: "Csoportos\xEDtott",
  stackedBar: "Halmozott",
  normalizedBar: "100% Halmozott",
  pieChart: "Tortadiagram",
  pie: "Torta",
  donut: "Donut",
  line: "Vonal",
  xyChart: "X Y (Sz\xF3rt)",
  scatter: "Sz\xF3rt",
  bubble: "Bubor\xE9k",
  areaChart: "Ter\xFCletdiagram",
  area: "Ter\xFClet",
  stackedArea: "Halmozott",
  normalizedArea: "100% Halmozott",
  histogramChart: "Hisztogram",
  polarChart: "Pol\xE1ris",
  radarLine: "Radar Vonal",
  radarArea: "Radar Ter\xFClet",
  nightingale: "Nightingale",
  radialColumn: "Radi\xE1lis Oszlop",
  radialBar: "Radi\xE1lis S\xE1v",
  statisticalChart: "Statisztikai",
  boxPlot: "Box Plot",
  rangeBar: "Tartom\xE1ny S\xE1v",
  rangeArea: "Tartom\xE1ny Ter\xFClet",
  hierarchicalChart: "Hierarchikus",
  treemap: "Fa diagram",
  sunburst: "Napkit\xF6r\xE9s",
  specializedChart: "Speci\xE1lis",
  waterfall: "V\xEDzese\xE9s",
  heatmap: "H\u0151t\xE9rk\xE9p",
  combinationChart: "Kombin\xE1lt Diagram",
  columnLineCombo: "Oszlop & Vonal",
  AreaColumnCombo: "Ter\xFClet & Oszlop",
  // Charts
  pivotChartTitle: "Forgat\xF3diagram",
  rangeChartTitle: "Tartom\xE1nydiagram",
  settings: "Diagram",
  data: "Be\xE1ll\xEDt\xE1sok",
  format: "Testreszab\xE1s",
  categories: "Kateg\xF3ri\xE1k",
  defaultCategory: "(Nincs)",
  series: "Sorozat",
  switchCategorySeries: "Kateg\xF3ria / Sorozat v\xE1lt\xE1sa",
  categoryValues: "Kateg\xF3ria\xE9rt\xE9kek",
  seriesLabels: "Sorozatc\xEDmk\xE9k",
  aggregate: "\xD6sszegz\xE9s",
  xyValues: "X Y \xE9rt\xE9kek",
  paired: "P\xE1ros\xEDtott m\xF3d",
  axis: "Tengely",
  xAxis: "V\xEDzszintes tengely",
  yAxis: "F\xFCgg\u0151leges tengely",
  polarAxis: "Pol\xE1ris tengely",
  radiusAxis: "Sug\xE1rtengely",
  navigator: "Navig\xE1tor",
  zoom: "Nagy\xEDt\xE1s",
  animation: "Anim\xE1ci\xF3",
  crosshair: "C\xE9lkereszt",
  color: "Sz\xEDn",
  thickness: "Vastags\xE1g",
  preferredLength: "El\u0151nyben r\xE9szes\xEDtett hossz",
  xType: "X t\xEDpus",
  axisType: "Tengely t\xEDpusa",
  automatic: "Automatikus",
  category: "Kateg\xF3ria",
  number: "Sz\xE1m",
  time: "Id\u0151",
  timeFormat: "Id\u0151form\xE1tum",
  autoRotate: "Automatikus forgat\xE1s",
  labelRotation: "C\xEDmkeforgat\xE1s",
  circle: "K\xF6r",
  polygon: "Soksz\xF6g",
  square: "N\xE9gyzet",
  cross: "Kereszt",
  diamond: "Rombusz",
  plus: "Plusz",
  triangle: "H\xE1romsz\xF6g",
  heart: "Sz\xEDv",
  orientation: "T\xE1jol\xE1s",
  fixed: "R\xF6gz\xEDtett",
  parallel: "P\xE1rhuzamos",
  perpendicular: "Mer\u0151leges",
  radiusAxisPosition: "Poz\xEDci\xF3",
  ticks: "Jel\xF6l\xE9sek",
  gridLines: "R\xE1csvonalak",
  width: "Sz\xE9less\xE9g",
  height: "Magass\xE1g",
  length: "Hossz",
  padding: "Kihagy\xE1s",
  spacing: "T\xE1vols\xE1g",
  chartStyle: "Diagram st\xEDlus",
  title: "C\xEDm",
  chartTitles: "C\xEDmek",
  chartTitle: "Diagram c\xEDme",
  chartSubtitle: "Alc\xEDm",
  horizontalAxisTitle: "V\xEDzszintes tengely c\xEDme",
  verticalAxisTitle: "F\xFCgg\u0151leges tengely c\xEDme",
  polarAxisTitle: "Pol\xE1ris tengely c\xEDme",
  titlePlaceholder: "Diagram c\xEDme",
  background: "H\xE1tt\xE9r",
  font: "Bet\u0171t\xEDpus",
  weight: "S\xFAly",
  top: "Fels\u0151",
  right: "Jobb",
  bottom: "Als\xF3",
  left: "Bal",
  labels: "C\xEDmk\xE9k",
  calloutLabels: "Figyelmeztet\u0151 c\xEDmk\xE9k",
  sectorLabels: "Szeletc\xEDmk\xE9k",
  positionRatio: "Poz\xEDci\xF3 ar\xE1ny",
  size: "M\xE9ret",
  shape: "Alak",
  minSize: "Minim\xE1lis m\xE9ret",
  maxSize: "Maxim\xE1lis m\xE9ret",
  legend: "Jelmagyar\xE1zat",
  position: "Poz\xEDci\xF3",
  markerSize: "Jelz\u0151 m\xE9ret",
  markerStroke: "Jelz\u0151 vonal",
  markerPadding: "Jelz\u0151 kihagy\xE1s",
  itemSpacing: "Elemk\xF6z",
  itemPaddingX: "Elem kihagy\xE1s X",
  itemPaddingY: "Elem kihagy\xE1s Y",
  layoutHorizontalSpacing: "V\xEDzszintes t\xE1vols\xE1g",
  layoutVerticalSpacing: "F\xFCgg\u0151leges t\xE1vols\xE1g",
  strokeWidth: "Vonalvastags\xE1g",
  offset: "Eltol\xE1s",
  offsets: "Eltol\xE1sok",
  tooltips: "Eszk\xF6ztippek",
  callout: "Figyelmeztet\xE9s",
  markers: "Jelz\u0151k",
  shadow: "\xC1rny\xE9k",
  blur: "Elmos\xE1s",
  xOffset: "X eltol\xE1s",
  yOffset: "Y eltol\xE1s",
  lineWidth: "Vonalvastags\xE1g",
  lineDash: "Szaggatott vonal",
  lineDashOffset: "Szaggatott vonal eltol\xE1s",
  scrollingZoom: "G\xF6rget\xE9s",
  scrollingStep: "G\xF6rget\xE9si l\xE9p\xE9s",
  selectingZoom: "Kiv\xE1laszt\xE1s",
  durationMillis: "Id\u0151tartam (ms)",
  crosshairLabel: "C\xEDmke",
  crosshairSnap: "Csom\xF3ponthoz r\xF6gz\xEDt\xE9s",
  normal: "Norm\xE1l",
  bold: "F\xE9lk\xF6v\xE9r",
  italic: "D\u0151lt",
  boldItalic: "F\xE9lk\xF6v\xE9r d\u0151lt",
  predefined: "El\u0151re defini\xE1lt",
  fillOpacity: "Kit\xF6lt\xE9si \xE1tl\xE1tszatlans\xE1g",
  strokeColor: "Vonal sz\xEDn",
  strokeOpacity: "Vonal \xE1tl\xE1tszatlans\xE1g",
  miniChart: "Mini-diagram",
  histogramBinCount: "Bin sz\xE1m",
  connectorLine: "\xD6sszek\xF6t\u0151 vonal",
  seriesItems: "Sorozat elemek",
  seriesItemType: "Elem t\xEDpusa",
  seriesItemPositive: "Pozit\xEDv",
  seriesItemNegative: "Negat\xEDv",
  seriesItemLabels: "Elemc\xEDmk\xE9k",
  columnGroup: "Oszlop",
  barGroup: "S\xE1v",
  pieGroup: "Torta",
  lineGroup: "Vonal",
  scatterGroup: "X Y (Sz\xF3r\xE1s)",
  areaGroup: "Ter\xFClet",
  polarGroup: "Pol\xE1ris",
  statisticalGroup: "Statisztikai",
  hierarchicalGroup: "Hierarchikus",
  specializedGroup: "Speci\xE1lis",
  combinationGroup: "Kombin\xE1lt",
  groupedColumnTooltip: "Csoportos\xEDtott",
  stackedColumnTooltip: "Halmozott",
  normalizedColumnTooltip: "100% Halmozott",
  groupedBarTooltip: "Csoportos\xEDtott",
  stackedBarTooltip: "Halmozott",
  normalizedBarTooltip: "100% Halmozott",
  pieTooltip: "Torta",
  donutTooltip: "F\xE1nk",
  lineTooltip: "Vonal",
  groupedAreaTooltip: "Ter\xFClet",
  stackedAreaTooltip: "Halmozott",
  normalizedAreaTooltip: "100% Halmozott",
  scatterTooltip: "Sz\xF3r\xE1s",
  bubbleTooltip: "Bubor\xE9k",
  histogramTooltip: "Hisztogram",
  radialColumnTooltip: "Radi\xE1lis oszlop",
  radialBarTooltip: "Radi\xE1lis s\xE1v",
  radarLineTooltip: "Radar vonal",
  radarAreaTooltip: "Radar ter\xFClet",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Tartom\xE1nys\xE1v",
  rangeAreaTooltip: "Tartom\xE1nyter\xFClet",
  boxPlotTooltip: "Box diagram",
  treemapTooltip: "Fastrukt\xFAra",
  sunburstTooltip: "Napkit\xF6r\xE9s",
  waterfallTooltip: "V\xEDzes\xE9s",
  heatmapTooltip: "H\u0151t\xE9rk\xE9p",
  columnLineComboTooltip: "Oszlop \xE9s vonal",
  areaColumnComboTooltip: "Ter\xFClet \xE9s oszlop",
  customComboTooltip: "Egyedi kombin\xE1ci\xF3",
  innerRadius: "Bels\u0151 sug\xE1r",
  startAngle: "Kezd\u0151sz\xF6g",
  endAngle: "V\xE9gsz\xF6g",
  reverseDirection: "Ir\xE1ny megford\xEDt\xE1sa",
  groupPadding: "Csoportkifut\xE1s",
  seriesPadding: "Sorozatkifut\xE1s",
  tile: "Csempe",
  whisker: "Sz\xE1l",
  cap: "Sapka",
  capLengthRatio: "Hosszarany",
  labelPlacement: "C\xEDmkeelhelyez\xE9s",
  inside: "Belsej\xE9ben",
  outside: "K\xEDv\xFCl",
  noDataToChart: "Nincs el\xE9rhet\u0151 adat diagramra.",
  pivotChartRequiresPivotMode: "A forgat\xF3diagramhoz pivot m\xF3d bekapcsol\xE1sa sz\xFCks\xE9ges.",
  chartSettingsToolbarTooltip: "Men\xFC",
  chartLinkToolbarTooltip: "R\xE1cshoz csatolva",
  chartUnlinkToolbarTooltip: "R\xE1cst\xF3l lev\xE1lasztva",
  chartDownloadToolbarTooltip: "Diagram let\xF6lt\xE9se",
  chartMenuToolbarTooltip: "Men\xFC",
  chartEdit: "Diagram szerkeszt\xE9se",
  chartAdvancedSettings: "Speci\xE1lis be\xE1ll\xEDt\xE1sok",
  chartLink: "Csatol\xE1s a r\xE1cshoz",
  chartUnlink: "Lev\xE1laszt\xE1s a r\xE1csr\xF3l",
  chartDownload: "Diagram let\xF6lt\xE9se",
  histogramFrequency: "Gyakoris\xE1g",
  seriesChartType: "Sorozatdiagram t\xEDpusa",
  seriesType: "Sorozat t\xEDpusa",
  secondaryAxis: "M\xE1sodlagos tengely",
  seriesAdd: "Sorozat hozz\xE1ad\xE1sa",
  categoryAdd: "Kateg\xF3ria hozz\xE1ad\xE1sa",
  bar: "S\xE1v",
  column: "Oszlop",
  histogram: "Hisztogram",
  advancedSettings: "Speci\xE1lis be\xE1ll\xEDt\xE1sok",
  direction: "Ir\xE1ny",
  horizontal: "V\xEDzszintes",
  vertical: "F\xFCgg\u0151leges",
  seriesGroupType: "Csoport t\xEDpusa",
  groupedSeriesGroupType: "Csoportos\xEDtott",
  stackedSeriesGroupType: "Halmozott",
  normalizedSeriesGroupType: "100% Halmozott",
  legendEnabled: "Bekapcsolva",
  invalidColor: "Sz\xEDn\xE9rt\xE9k \xE9rv\xE9nytelen",
  groupedColumnFull: "Csoportos\xEDtott oszlop",
  stackedColumnFull: "Halmozott oszlop",
  normalizedColumnFull: "100% Halmozott oszlop",
  groupedBarFull: "Csoportos\xEDtott s\xE1v",
  stackedBarFull: "Halmozott s\xE1v",
  normalizedBarFull: "100% Halmozott s\xE1v",
  stackedAreaFull: "Halmozott ter\xFClet",
  normalizedAreaFull: "100% Halmozott ter\xFClet",
  customCombo: "Egyedi kombin\xE1ci\xF3",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Szint ${variable}. Nyomja meg az ENTER billenty\u0171t a szerkeszt\xE9shez.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Szint ${variable}. ${variable} Nyomja meg az ENTER billenty\u0171t a szerkeszt\xE9shez.",
  ariaAdvancedFilterBuilderList: "Speci\xE1lis sz\u0171r\u0151 k\xE9sz\xEDt\u0151 lista",
  ariaAdvancedFilterBuilderFilterItem: "Sz\u0171r\xE9si felt\xE9tel",
  ariaAdvancedFilterBuilderGroupItem: "Sz\u0171r\xE9si csoport",
  ariaAdvancedFilterBuilderColumn: "Oszlop",
  ariaAdvancedFilterBuilderOption: "Opci\xF3",
  ariaAdvancedFilterBuilderValueP: "\xC9rt\xE9k",
  ariaAdvancedFilterBuilderJoinOperator: "Egyes\xEDt\u0151 oper\xE1tor",
  ariaAdvancedFilterInput: "Speci\xE1lis sz\u0171r\u0151 bevitel",
  ariaChecked: "kiv\xE1lasztva",
  ariaColumn: "Oszlop",
  ariaColumnGroup: "Oszlop csoport",
  ariaColumnFiltered: "Sz\u0171rt oszlop",
  ariaColumnSelectAll: "\xD6sszes oszlop kiv\xE1laszt\xE1sa v\xE1lt\xE1sa",
  ariaDateFilterInput: "D\xE1tum sz\u0171r\u0151 bevitel",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Oszlopok sz\u0171r\xE9se bevitel",
  ariaFilterFromValue: "Sz\u0171r\xE9s \xE9rt\xE9kt\u0151l",
  ariaFilterInput: "Sz\u0171r\u0151 bevitel",
  ariaFilterList: "Sz\u0171r\u0151 lista",
  ariaFilterToValue: "Sz\u0171r\xE9s \xE9rt\xE9kig",
  ariaFilterValue: "Sz\u0171r\u0151 \xE9rt\xE9k",
  ariaFilterMenuOpen: "Sz\u0171r\u0151 men\xFC megnyit\xE1sa",
  ariaFilteringOperator: "Sz\u0171r\u0151 oper\xE1tor",
  ariaHidden: "elrejtve",
  ariaIndeterminate: "hat\xE1rozatlan",
  ariaInputEditor: "Bevitel szerkeszt\u0151",
  ariaMenuColumn: "Nyomja le az ALT + LE billenty\u0171t az oszlop men\xFC megnyit\xE1s\xE1hoz",
  ariaFilterColumn: "Nyomja meg a CTRL + ENTER billenty\u0171ket a sz\u0171r\u0151 megnyit\xE1s\xE1hoz",
  ariaRowDeselect: "Nyomja le a SPACE billenty\u0171t ezen sor kiv\xE1laszt\xE1s\xE1nak megsz\xFCntet\xE9s\xE9hez",
  ariaRowSelectAll: "Nyomja le a SPACE billenty\u0171t az \xF6sszes sor kiv\xE1laszt\xE1s\xE1nak v\xE1lt\xE1s\xE1hoz",
  ariaRowToggleSelection: "Nyomja le a SPACE billenty\u0171t a sor kiv\xE1laszt\xE1s\xE1nak v\xE1lt\xE1s\xE1hoz",
  ariaRowSelect: "Nyomja le a SPACE billenty\u0171t ezen sor kiv\xE1laszt\xE1s\xE1hoz",
  ariaRowSelectionDisabled: "A sor kiv\xE1laszt\xE1sa le van tiltva ehhez a sorhoz",
  ariaSearch: "Keres\xE9s",
  ariaSortableColumn: "Nyomja meg az ENTER billenty\u0171t a rendez\xE9shez",
  ariaToggleVisibility: "Nyomja meg a SPACE billenty\u0171t a l\xE1that\xF3s\xE1g v\xE1lt\xE1s\xE1hoz",
  ariaToggleCellValue: "Nyomja meg a SPACE billenty\u0171t a cella \xE9rt\xE9k\xE9nek v\xE1lt\xE1s\xE1hoz",
  ariaUnchecked: "nincs kiv\xE1lasztva",
  ariaVisible: "l\xE1that\xF3",
  ariaSearchFilterValues: "Keres\xE9s sz\u0171r\u0151 \xE9rt\xE9kek k\xF6z\xF6tt",
  ariaPageSizeSelectorLabel: "Oldal m\xE9ret",
  ariaChartMenuClose: "Diagram szerkeszt\xE9si men\xFC bez\xE1r\xE1sa",
  ariaChartSelected: "Kiv\xE1lasztva",
  ariaSkeletonCellLoadingFailed: "A sor bet\xF6lt\xE9se sikertelen volt",
  ariaSkeletonCellLoading: "A sor adatai bet\xF6lt\u0151dnek",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Sor csoportok",
  ariaValuesDropZonePanelLabel: "\xC9rt\xE9kek",
  ariaPivotDropZonePanelLabel: "Oszlop c\xEDmk\xE9k",
  ariaDropZoneColumnComponentDescription: "Nyomja meg a DELETE-t\xE1, hogy elt\xE1vol\xEDtsa",
  ariaDropZoneColumnValueItemDescription: "Nyomja meg az ENTER-t, hogy megv\xE1ltoztassa az aggreg\xE1ci\xF3 t\xEDpus\xE1t",
  ariaDropZoneColumnGroupItemDescription: "Nyomja meg az ENTER-t, hogy rendezze",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " k\xF6z\xFCl ",
  ariaDropZoneColumnComponentSortAscending: "n\xF6vekv\u0151",
  ariaDropZoneColumnComponentSortDescending: "cs\xF6kken\u0151",
  ariaLabelDialog: "P\xE1rbesz\xE9dpanel",
  ariaLabelColumnMenu: "Oszlop men\xFC",
  ariaLabelColumnFilter: "Oszlop sz\u0171r\u0151",
  ariaLabelCellEditor: "Cella szerkeszt\u0151",
  ariaLabelSelectField: "Mez\u0151 kiv\xE1laszt\xE1sa",
  // aria labels for rich select
  ariaLabelRichSelectField: "Gazdag kiv\xE1laszt\xE1si mez\u0151",
  ariaLabelRichSelectToggleSelection: "Nyomja meg a SPACE-t a kiv\xE1laszt\xE1s v\xE1lt\xE1s\xE1hoz",
  ariaLabelRichSelectDeselectAllItems: "Nyomja meg a DELETE-t minden elem kiv\xE1laszt\xE1s\xE1nak megsz\xFCntet\xE9s\xE9hez",
  ariaLabelRichSelectDeleteSelection: "Nyomja meg a DELETE-t az elem kiv\xE1laszt\xE1s\xE1nak megsz\xFCntet\xE9s\xE9hez",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Kontekstusmen\xFC",
  ariaLabelSubMenu: "Almen\xFC",
  ariaLabelAggregationFunction: "\xD6sszes\xEDt\u0151 f\xFCggv\xE9ny",
  ariaLabelAdvancedFilterAutocomplete: "Fejlett sz\u0171r\u0151 automatikus kieg\xE9sz\xEDt\xE9s",
  ariaLabelAdvancedFilterBuilderAddField: "Fejlett sz\u0171r\u0151 k\xE9sz\xEDt\u0151 mez\u0151 hozz\xE1ad\xE1sa",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Fejlett sz\u0171r\u0151 k\xE9sz\xEDt\u0151 oszlop kiv\xE1laszt\xF3 mez\u0151",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Fejlett sz\u0171r\u0151 k\xE9sz\xEDt\u0151 opci\xF3 kiv\xE1laszt\xF3 mez\u0151",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Fejlett sz\u0171r\u0151 k\xE9sz\xEDt\u0151 \xF6sszekapcsol\xF3 oper\xE1tor mez\u0151",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Oszloplista",
  ariaFilterPanelList: "Sz\u0171r\u0151lista",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Igaz",
  false: "Hamis",
  invalidDate: "\xC9rv\xE9nytelen d\xE1tum",
  invalidNumber: "\xC9rv\xE9nytelen sz\xE1m",
  january: "Janu\xE1r",
  february: "Febru\xE1r",
  march: "M\xE1rcius",
  april: "\xC1prilis",
  may: "M\xE1jus",
  june: "J\xFAnius",
  july: "J\xFAlius",
  august: "Augusztus",
  september: "Szeptember",
  october: "Okt\xF3ber",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "NN/HH/\xC9\xC9\xC9\xC9",
  timeFormatSlashesMMDDYYYY: "HH/NN/\xC9\xC9\xC9\xC9",
  timeFormatSlashesDDMMYY: "NN/HH/\xC9\xC9",
  timeFormatSlashesMMDDYY: "HH/NN/\xC9\xC9",
  timeFormatDotsDDMYY: "NN.H.\xC9\xC9",
  timeFormatDotsMDDYY: "H.NN.\xC9\xC9",
  timeFormatDashesYYYYMMDD: "\xC9\xC9\xC9\xC9-HH-NN",
  timeFormatSpacesDDMMMMYYYY: "NN MMMM \xC9\xC9\xC9\xC9",
  timeFormatHHMMSS: "\xD3\xD3:PP:MP",
  timeFormatHHMMSSAmPm: "\xD3\xD3:PP:MP DE/DU"
};

// community-modules/locale/src/it-IT.ts
var AG_GRID_LOCALE_IT = {
  // Set Filter
  selectAll: "(Seleziona tutto)",
  selectAllSearchResults: "(Seleziona tutti i risultati della ricerca)",
  addCurrentSelectionToFilter: "Aggiungi selezione corrente al filtro",
  searchOoo: "Cerca...",
  blanks: "(Vuoti)",
  noMatches: "Nessuna corrispondenza",
  // Number Filter & Text Filter
  filterOoo: "Filtra...",
  equals: "Uguale",
  notEqual: "Diverso",
  blank: "Vuoto",
  notBlank: "Non vuoto",
  empty: "Scegli uno",
  // Number Filter
  lessThan: "Meno di",
  greaterThan: "Pi\xF9 di",
  lessThanOrEqual: "Minore o uguale a",
  greaterThanOrEqual: "Maggiore o uguale a",
  inRange: "Tra",
  inRangeStart: "Da",
  inRangeEnd: "A",
  // Text Filter
  contains: "Contiene",
  notContains: "Non contiene",
  startsWith: "Inizia con",
  endsWith: "Finisce con",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Prima",
  after: "Dopo",
  // Filter Conditions
  andCondition: "E",
  orCondition: "O",
  // Filter Buttons
  applyFilter: "Applica",
  resetFilter: "Reimposta",
  clearFilter: "Pulisci",
  cancelFilter: "Annulla",
  // Filter Titles
  textFilter: "Filtro di testo",
  numberFilter: "Filtro numerico",
  dateFilter: "Filtro data",
  setFilter: "Filtro set",
  // Group Column Filter
  groupFilterSelect: "Seleziona campo:",
  // Advanced Filter
  advancedFilterContains: "contiene",
  advancedFilterNotContains: "non contiene",
  advancedFilterTextEquals: "\xE8 uguale a",
  advancedFilterTextNotEqual: "non \xE8 uguale a",
  advancedFilterStartsWith: "inizia con",
  advancedFilterEndsWith: "termina con",
  advancedFilterBlank: "\xE8 vuoto",
  advancedFilterNotBlank: "non \xE8 vuoto",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\xE8 vero",
  advancedFilterFalse: "\xE8 falso",
  advancedFilterAnd: "E",
  advancedFilterOr: "O",
  advancedFilterApply: "Applica",
  advancedFilterBuilder: "Costruttore",
  advancedFilterValidationMissingColumn: "Colonna mancante",
  advancedFilterValidationMissingOption: "Opzione mancante",
  advancedFilterValidationMissingValue: "Valore mancante",
  advancedFilterValidationInvalidColumn: "Colonna non trovata",
  advancedFilterValidationInvalidOption: "Opzione non trovata",
  advancedFilterValidationMissingQuote: "Il valore manca di una virgolette finale",
  advancedFilterValidationNotANumber: "Il valore non \xE8 un numero",
  advancedFilterValidationInvalidDate: "Il valore non \xE8 una data valida",
  advancedFilterValidationMissingCondition: "Condizione mancante",
  advancedFilterValidationJoinOperatorMismatch: "Gli operatori di unione all'interno di una condizione devono essere uguali",
  advancedFilterValidationInvalidJoinOperator: "Operatore di unione non trovato",
  advancedFilterValidationMissingEndBracket: "Parentesi di chiusura mancante",
  advancedFilterValidationExtraEndBracket: "Troppe parentesi di chiusura",
  advancedFilterValidationMessage: "L'espressione ha un errore. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "L'espressione ha un errore. ${variable} alla fine dell'espressione.",
  advancedFilterBuilderTitle: "Filtro Avanzato",
  advancedFilterBuilderApply: "Applica",
  advancedFilterBuilderCancel: "Annulla",
  advancedFilterBuilderAddButtonTooltip: "Aggiungi Filtro o Gruppo",
  advancedFilterBuilderRemoveButtonTooltip: "Rimuovi",
  advancedFilterBuilderMoveUpButtonTooltip: "Sposta Su",
  advancedFilterBuilderMoveDownButtonTooltip: "Sposta Gi\xF9",
  advancedFilterBuilderAddJoin: "Aggiungi Gruppo",
  advancedFilterBuilderAddCondition: "Aggiungi Filtro",
  advancedFilterBuilderSelectColumn: "Seleziona una colonna",
  advancedFilterBuilderSelectOption: "Seleziona un'opzione",
  advancedFilterBuilderEnterValue: "Inserisci un valore...",
  advancedFilterBuilderValidationAlreadyApplied: "Filtro corrente gi\xE0 applicato.",
  advancedFilterBuilderValidationIncomplete: "Non tutte le condizioni sono complete.",
  advancedFilterBuilderValidationSelectColumn: "Devi selezionare una colonna.",
  advancedFilterBuilderValidationSelectOption: "Devi selezionare un'opzione.",
  advancedFilterBuilderValidationEnterValue: "Devi inserire un valore.",
  // Side Bar
  columns: "Colonne",
  filters: "Filtri",
  // columns tool panel
  pivotMode: "Modalit\xE0 pivot",
  groups: "Raggruppamenti di righe",
  rowGroupColumnsEmptyMessage: "Trascina qui per impostare i gruppi di righe",
  values: "Valori",
  valueColumnsEmptyMessage: "Trascina qui per aggregare",
  pivots: "Etichette di colonna",
  pivotColumnsEmptyMessage: "Trascina qui per impostare le etichette di colonna",
  // Header of the Default Group Column
  group: "Gruppo",
  // Row Drag
  rowDragRow: "riga",
  rowDragRows: "righe",
  // Other
  loadingOoo: "Caricamento...",
  loadingError: "ERR",
  noRowsToShow: "Nessuna riga da mostrare",
  enabled: "Abilitato",
  // Menu
  pinColumn: "Blocca Colonna",
  pinLeft: "Blocca a Sinistra",
  pinRight: "Blocca a Destra",
  noPin: "Non Bloccare",
  valueAggregation: "Aggregazione Valori",
  noAggregation: "Nessuna",
  autosizeThisColumn: "Dimensiona Automaticamente Questa Colonna",
  autosizeAllColumns: "Dimensiona Automaticamente Tutte le Colonne",
  groupBy: "Raggruppa per",
  ungroupBy: "Separa gruppo per",
  ungroupAll: "Separa Tutti i Gruppi",
  addToValues: "Aggiungi ${variable} ai valori",
  removeFromValues: "Rimuovi ${variable} dai valori",
  addToLabels: "Aggiungi ${variable} alle etichette",
  removeFromLabels: "Rimuovi ${variable} dalle etichette",
  resetColumns: "Reimposta Colonne",
  expandAll: "Espandi Tutti i Gruppi di Righe",
  collapseAll: "Chiudi Tutti i Gruppi di Righe",
  copy: "Copia",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copia con Intestazioni",
  copyWithGroupHeaders: "Copia con Intestazioni di Gruppo",
  cut: "Taglia",
  paste: "Incolla",
  ctrlV: "Ctrl+V",
  export: "Esporta",
  csvExport: "Esporta CSV",
  excelExport: "Esporta Excel",
  columnFilter: "Filtro Colonna",
  columnChooser: "Scegli Colonne",
  chooseColumns: "Scegli Colonne",
  sortAscending: "Ordina in modo Ascendente",
  sortDescending: "Ordina in modo Discendente",
  sortUnSort: "Annulla Ordinamento",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Somma",
  first: "Primo",
  last: "Ultimo",
  min: "Minimo",
  max: "Massimo",
  none: "Nessuno",
  count: "Conteggio",
  avg: "Media",
  filteredRows: "Filtrati",
  selectedRows: "Selezionati",
  totalRows: "Righe Totali",
  totalAndFilteredRows: "Righe",
  more: "Altro",
  to: "a",
  of: "di",
  page: "Pagina",
  pageLastRowUnknown: "?",
  nextPage: "Pagina Successiva",
  lastPage: "Ultima Pagina",
  firstPage: "Prima Pagina",
  previousPage: "Pagina Precedente",
  pageSizeSelectorLabel: "Dimensione Pagina:",
  footerTotal: "Totale",
  // Pivoting
  pivotColumnGroupTotals: "Totale",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Grafico Pivot e Modalit\xE0 Pivot",
  pivotChart: "Grafico Pivot",
  chartRange: "Intervallo Grafico",
  columnChart: "Colonna",
  groupedColumn: "Raggruppato",
  stackedColumn: "Impilato",
  normalizedColumn: "100% Impilato",
  barChart: "Barra",
  groupedBar: "Raggruppato",
  stackedBar: "Impilato",
  normalizedBar: "100% Impilato",
  pieChart: "Torta",
  pie: "Torta",
  donut: "Ciambella",
  line: "Linea",
  xyChart: "X Y (Dispersione)",
  scatter: "Dispersione",
  bubble: "Bolla",
  areaChart: "Area",
  area: "Area",
  stackedArea: "Impilata",
  normalizedArea: "100% Impilata",
  histogramChart: "Istogramma",
  polarChart: "Polare",
  radarLine: "Linea Radar",
  radarArea: "Area Radar",
  nightingale: "Rosa di Nightingale",
  radialColumn: "Colonna Radiale",
  radialBar: "Barra Radiale",
  statisticalChart: "Statistico",
  boxPlot: "Box Plot",
  rangeBar: "Barra Intervallo",
  rangeArea: "Area Intervallo",
  hierarchicalChart: "Gerarchico",
  treemap: "Mappa ad Albero",
  sunburst: "Raggiera",
  specializedChart: "Specializzato",
  waterfall: "Cascata",
  heatmap: "Mappa di Calore",
  combinationChart: "Combinazione",
  columnLineCombo: "Colonna e Linea",
  AreaColumnCombo: "Area e Colonna",
  // Charts
  pivotChartTitle: "Grafico Pivot",
  rangeChartTitle: "Grafico a Intervallo",
  settings: "Grafico",
  data: "Impostazioni",
  format: "Personalizza",
  categories: "Categorie",
  defaultCategory: "(Nessuno)",
  series: "Serie",
  switchCategorySeries: "Scambia Categoria / Serie",
  categoryValues: "Valori Categorie",
  seriesLabels: "Etichette Serie",
  aggregate: "Aggrega",
  xyValues: "Valori X Y",
  paired: "Modalit\xE0 Abbinata",
  axis: "Asse",
  xAxis: "Asse Orizzontale",
  yAxis: "Asse Verticale",
  polarAxis: "Asse Polare",
  radiusAxis: "Asse del Raggio",
  navigator: "Navigatore",
  zoom: "Zoom",
  animation: "Animazione",
  crosshair: "Mirino",
  color: "Colore",
  thickness: "Spessore",
  preferredLength: "Lunghezza Preferita",
  xType: "Tipo X",
  axisType: "Tipo di Asse",
  automatic: "Automatico",
  category: "Categoria",
  number: "Numero",
  time: "Tempo",
  timeFormat: "Formato Ora",
  autoRotate: "Rotazione Automatica",
  labelRotation: "Rotazione",
  circle: "Cerchio",
  polygon: "Poligono",
  square: "Quadrato",
  cross: "Croce",
  diamond: "Diamante",
  plus: "Pi\xF9",
  triangle: "Triangolo",
  heart: "Cuore",
  orientation: "Orientamento",
  fixed: "Fisso",
  parallel: "Parallelo",
  perpendicular: "Perpendicolare",
  radiusAxisPosition: "Posizione",
  ticks: "Ticchettii",
  gridLines: "Linee della Griglia",
  width: "Larghezza",
  height: "Altezza",
  length: "Lunghezza",
  padding: "Margine Interno",
  spacing: "Spaziatura",
  chartStyle: "Stile del Grafico",
  title: "Titolo",
  chartTitles: "Titoli",
  chartTitle: "Titolo del Grafico",
  chartSubtitle: "Sottotitolo",
  horizontalAxisTitle: "Titolo dell'Asse Orizzontale",
  verticalAxisTitle: "Titolo dell'Asse Verticale",
  polarAxisTitle: "Titolo dell'Asse Polare",
  titlePlaceholder: "Titolo del Grafico",
  background: "Sfondo",
  font: "Carattere",
  weight: "Peso",
  top: "Sopra",
  right: "Destra",
  bottom: "Sotto",
  left: "Sinistra",
  labels: "Etichette",
  calloutLabels: "Etichette di Richiamo",
  sectorLabels: "Etichette Settori",
  positionRatio: "Rapporto di Posizione",
  size: "Dimensione",
  shape: "Forma",
  minSize: "Dimensione Minima",
  maxSize: "Dimensione Massima",
  legend: "Legenda",
  position: "Posizione",
  markerSize: "Dimensione Marcatore",
  markerStroke: "Tratto Marcatore",
  markerPadding: "Margine Interno Marcatore",
  itemSpacing: "Spaziatura Elementi",
  itemPaddingX: "Margine Interno Elemento X",
  itemPaddingY: "Margine Interno Elemento Y",
  layoutHorizontalSpacing: "Spaziatura Orizzontale",
  layoutVerticalSpacing: "Spaziatura Verticale",
  strokeWidth: "Spessore Linea",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Suggerimenti",
  callout: "Richiamo",
  markers: "Marcatori",
  shadow: "Ombra",
  blur: "Sfocatura",
  xOffset: "Offset X",
  yOffset: "Offset Y",
  lineWidth: "Spessore Linea",
  lineDash: "Tratteggio Linea",
  lineDashOffset: "Offset Linea Tratteggiata",
  scrollingZoom: "Scorrimento",
  scrollingStep: "Intervallo Scorrimento",
  selectingZoom: "Selezione",
  durationMillis: "Durata (ms)",
  crosshairLabel: "Etichetta",
  crosshairSnap: "Aggancio al Nodo",
  normal: "Normale",
  bold: "Grassetto",
  italic: "Corsivo",
  boldItalic: "Grassetto Corsivo",
  predefined: "Predefinito",
  fillOpacity: "Opacit\xE0 Riempimento",
  strokeColor: "Colore Linea",
  strokeOpacity: "Opacit\xE0 Linea",
  miniChart: "Mini-Grafico",
  histogramBinCount: "Conteggio Bin",
  connectorLine: "Linea di Connessione",
  seriesItems: "Elementi Serie",
  seriesItemType: "Tipo Elemento",
  seriesItemPositive: "Positivo",
  seriesItemNegative: "Negativo",
  seriesItemLabels: "Etichette degli Elementi",
  columnGroup: "Colonna",
  barGroup: "Barra",
  pieGroup: "Torta",
  lineGroup: "Linea",
  scatterGroup: "X Y (Dispersione)",
  areaGroup: "Area",
  polarGroup: "Polare",
  statisticalGroup: "Statistico",
  hierarchicalGroup: "Gerarchico",
  specializedGroup: "Specializzato",
  combinationGroup: "Combinazione",
  groupedColumnTooltip: "Raggruppato",
  stackedColumnTooltip: "Impilato",
  normalizedColumnTooltip: "100% Impilato",
  groupedBarTooltip: "Raggruppato",
  stackedBarTooltip: "Impilato",
  normalizedBarTooltip: "100% Impilato",
  pieTooltip: "Torta",
  donutTooltip: "Ciambella",
  lineTooltip: "Linea",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Impilato",
  normalizedAreaTooltip: "100% Impilato",
  scatterTooltip: "Dispersione",
  bubbleTooltip: "Bolla",
  histogramTooltip: "Istogramma",
  radialColumnTooltip: "Colonna Radiale",
  radialBarTooltip: "Barra Radiale",
  radarLineTooltip: "Linea Radar",
  radarAreaTooltip: "Area Radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Barra di Intervallo",
  rangeAreaTooltip: "Area di Intervallo",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Esplosione Solare",
  waterfallTooltip: "Cascata",
  heatmapTooltip: "Heatmap",
  columnLineComboTooltip: "Colonna & Linea",
  areaColumnComboTooltip: "Area & Colonna",
  customComboTooltip: "Combinazione Personalizzata",
  innerRadius: "Raggio Interno",
  startAngle: "Angolo di Inizio",
  endAngle: "Angolo di Fine",
  reverseDirection: "Inversione Direzione",
  groupPadding: "Margine Interno di Gruppo",
  seriesPadding: "Margine Interno Serie",
  tile: "Mattonella",
  whisker: "Antennule",
  cap: "Tappo",
  capLengthRatio: "Rapporto Lunghezza",
  labelPlacement: "Posizionamento Etichetta",
  inside: "Interno",
  outside: "Esterno",
  noDataToChart: "Nessun dato disponibile per il grafico.",
  pivotChartRequiresPivotMode: "Il Grafico Pivot richiede la Modalit\xE0 Pivot abilitata.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Collegato alla Griglia",
  chartUnlinkToolbarTooltip: "Scollegato dalla Griglia",
  chartDownloadToolbarTooltip: "Scarica Grafico",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Modifica Grafico",
  chartAdvancedSettings: "Impostazioni Avanzate",
  chartLink: "Collega alla Griglia",
  chartUnlink: "Scollega dalla Griglia",
  chartDownload: "Scarica Grafico",
  histogramFrequency: "Frequenza",
  seriesChartType: "Tipo di Grafico Serie",
  seriesType: "Tipo di Serie",
  secondaryAxis: "Asse Secondario",
  seriesAdd: "Aggiungi una serie",
  categoryAdd: "Aggiungi una categoria",
  bar: "Barra",
  column: "Colonna",
  histogram: "Istogramma",
  advancedSettings: "Impostazioni Avanzate",
  direction: "Direzione",
  horizontal: "Orizzontale",
  vertical: "Verticale",
  seriesGroupType: "Tipo di Gruppo",
  groupedSeriesGroupType: "Raggruppato",
  stackedSeriesGroupType: "Impilato",
  normalizedSeriesGroupType: "100% Impilato",
  legendEnabled: "Abilitato",
  invalidColor: "Valore colore non valido",
  groupedColumnFull: "Colonna Raggruppata",
  stackedColumnFull: "Colonna Impilata",
  normalizedColumnFull: "Colonna 100% Impilata",
  groupedBarFull: "Barra Raggruppata",
  stackedBarFull: "Barra Impilata",
  normalizedBarFull: "Barra 100% Impilata",
  stackedAreaFull: "Area Impilata",
  normalizedAreaFull: "Area 100% Impilata",
  customCombo: "Combinazione Personalizzata",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Livello ${variable}. Premi INVIO per modificare.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Livello ${variable}. ${variable} Premi INVIO per modificare.",
  ariaAdvancedFilterBuilderList: "Lista di Costruzione Filtro Avanzato",
  ariaAdvancedFilterBuilderFilterItem: "Condizione del Filtro",
  ariaAdvancedFilterBuilderGroupItem: "Gruppo di Filtri",
  ariaAdvancedFilterBuilderColumn: "Colonna",
  ariaAdvancedFilterBuilderOption: "Opzione",
  ariaAdvancedFilterBuilderValueP: "Valore",
  ariaAdvancedFilterBuilderJoinOperator: "Operatore di Unione",
  ariaAdvancedFilterInput: "Inserimento Filtro Avanzato",
  ariaChecked: "selezionato",
  ariaColumn: "Colonna",
  ariaColumnGroup: "Gruppo di Colonne",
  ariaColumnFiltered: "Colonna Filtrata",
  ariaColumnSelectAll: "Attiva/Disattiva Seleziona Tutte le Colonne",
  ariaDateFilterInput: "Inserimento Filtro Data",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Inserimento Filtro Colonne",
  ariaFilterFromValue: "Filtra dal valore",
  ariaFilterInput: "Inserimento Filtro",
  ariaFilterList: "Lista dei Filtri",
  ariaFilterToValue: "Filtra al valore",
  ariaFilterValue: "Valore del Filtro",
  ariaFilterMenuOpen: "Apri Menu Filtri",
  ariaFilteringOperator: "Operatore di Filtro",
  ariaHidden: "nascosto",
  ariaIndeterminate: "indeterminato",
  ariaInputEditor: "Editor di Inserimento",
  ariaMenuColumn: "Premi ALT GI\xD9 per aprire il menu della colonna",
  ariaFilterColumn: "Premi CTRL INVIO per aprire il filtro",
  ariaRowDeselect: "Premi SPAZIO per deselezionare questa riga",
  ariaRowSelectAll: "Premi SPAZIO per attivare/disattivare la selezione di tutte le righe",
  ariaRowToggleSelection: "Premi SPAZIO per attivare/disattivare la selezione della riga",
  ariaRowSelect: "Premi SPAZIO per selezionare questa riga",
  ariaRowSelectionDisabled: "La selezione della riga \xE8 disabilitata per questa riga",
  ariaSearch: "Cerca",
  ariaSortableColumn: "Premi INVIO per ordinare",
  ariaToggleVisibility: "Premi SPAZIO per attivare/disattivare la visibilit\xE0",
  ariaToggleCellValue: "Premi SPAZIO per attivare/disattivare il valore della cella",
  ariaUnchecked: "non selezionato",
  ariaVisible: "visibile",
  ariaSearchFilterValues: "Cerca valori del filtro",
  ariaPageSizeSelectorLabel: "Dimensione della Pagina",
  ariaChartMenuClose: "Chiudi Menu Modifica Grafico",
  ariaChartSelected: "Selezionato",
  ariaSkeletonCellLoadingFailed: "Caricamento della riga fallito",
  ariaSkeletonCellLoading: "Caricamento dati della riga in corso",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Gruppi di Righe",
  ariaValuesDropZonePanelLabel: "Valori",
  ariaPivotDropZonePanelLabel: "Etichette di Colonna",
  ariaDropZoneColumnComponentDescription: "Premi CANC per rimuovere",
  ariaDropZoneColumnValueItemDescription: "Premi INVIO per cambiare il tipo di aggregazione",
  ariaDropZoneColumnGroupItemDescription: "Premi INVIO per ordinare",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " di ",
  ariaDropZoneColumnComponentSortAscending: "crescendo",
  ariaDropZoneColumnComponentSortDescending: "decrescendo",
  ariaLabelDialog: "Dialogo",
  ariaLabelColumnMenu: "Men\xF9 Colonna",
  ariaLabelColumnFilter: "Filtro Colonna",
  ariaLabelCellEditor: "Editor di Celle",
  ariaLabelSelectField: "Seleziona Campo",
  // aria labels for rich select
  ariaLabelRichSelectField: "Campo di Selezione Ricco",
  ariaLabelRichSelectToggleSelection: "Premi SPAZIO per alternare la selezione",
  ariaLabelRichSelectDeselectAllItems: "Premi CANC per deselezionare tutti gli elementi",
  ariaLabelRichSelectDeleteSelection: "Premi CANC per deselezionare l'elemento",
  ariaLabelTooltip: "Suggerimento",
  ariaLabelContextMenu: "Menu Contestuale",
  ariaLabelSubMenu: "Sottomenu",
  ariaLabelAggregationFunction: "Funzione di Aggregazione",
  ariaLabelAdvancedFilterAutocomplete: "Completamento Automatico Filtro Avanzato",
  ariaLabelAdvancedFilterBuilderAddField: "Aggiungi Campo al Costruttore di Filtri Avanzato",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Seleziona Campo della Colonna nel Costruttore di Filtri Avanzato",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Seleziona Opzione del Campo nel Costruttore di Filtri Avanzato",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Seleziona Operatore di Join nel Costruttore di Filtri Avanzato",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Elenco delle colonne",
  ariaFilterPanelList: "Elenco dei filtri",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Vero",
  false: "Falso",
  invalidDate: "Data non valida",
  invalidNumber: "Numero non valido",
  january: "Gennaio",
  february: "Febbraio",
  march: "Marzo",
  april: "Aprile",
  may: "Maggio",
  june: "Giugno",
  july: "Luglio",
  august: "Agosto",
  september: "Settembre",
  october: "Ottobre",
  november: "Novembre",
  december: "Dicembre",
  // Time formats
  timeFormatSlashesDDMMYYYY: "GG/MM/AAAA",
  timeFormatSlashesMMDDYYYY: "MM/GG/AAAA",
  timeFormatSlashesDDMMYY: "GG/MM/AA",
  timeFormatSlashesMMDDYY: "MM/GG/AA",
  timeFormatDotsDDMYY: "GG.M.AA",
  timeFormatDotsMDDYY: "M.GG.AA",
  timeFormatDashesYYYYMMDD: "AAAA-MM-GG",
  timeFormatSpacesDDMMMMYYYY: "GG MMMM AAAA",
  timeFormatHHMMSS: "OO:MM:SS",
  timeFormatHHMMSSAmPm: "OO:MM:SS AM/PM"
};

// community-modules/locale/src/ja-JP.ts
var AG_GRID_LOCALE_JP = {
  // Set Filter
  selectAll: "(\u3059\u3079\u3066\u9078\u629E)",
  selectAllSearchResults: "(\u691C\u7D22\u7D50\u679C\u3092\u3059\u3079\u3066\u9078\u629E)",
  addCurrentSelectionToFilter: "\u73FE\u5728\u306E\u9078\u629E\u3092\u30D5\u30A3\u30EB\u30BF\u30FC\u306B\u8FFD\u52A0",
  searchOoo: "\u691C\u7D22...",
  blanks: "(\u7A7A\u767D)",
  noMatches: "\u4E00\u81F4\u3059\u308B\u3082\u306E\u304C\u3042\u308A\u307E\u305B\u3093",
  // Number Filter & Text Filter
  filterOoo: "\u30D5\u30A3\u30EB\u30BF\u30FC...",
  equals: "\u7B49\u3057\u3044",
  notEqual: "\u7B49\u3057\u304F\u306A\u3044",
  blank: "\u7A7A\u767D",
  notBlank: "\u7A7A\u767D\u3067\u306F\u306A\u3044",
  empty: "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
  // Number Filter
  lessThan: "\u672A\u6E80",
  greaterThan: "\u3088\u308A\u5927\u304D\u3044",
  lessThanOrEqual: "\u4EE5\u4E0B",
  greaterThanOrEqual: "\u4EE5\u4E0A",
  inRange: "\u9593",
  inRangeStart: "\u304B\u3089",
  inRangeEnd: "\u307E\u3067",
  // Text Filter
  contains: "\u542B\u3080",
  notContains: "\u542B\u307E\u306A\u3044",
  startsWith: "\u3067\u59CB\u307E\u308B",
  endsWith: "\u3067\u7D42\u308F\u308B",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u524D",
  after: "\u5F8C",
  // Filter Conditions
  andCondition: "AND",
  orCondition: "OR",
  // Filter Buttons
  applyFilter: "\u9069\u7528",
  resetFilter: "\u30EA\u30BB\u30C3\u30C8",
  clearFilter: "\u30AF\u30EA\u30A2",
  cancelFilter: "\u30AD\u30E3\u30F3\u30BB\u30EB",
  // Filter Titles
  textFilter: "\u30C6\u30AD\u30B9\u30C8\u30D5\u30A3\u30EB\u30BF\u30FC",
  numberFilter: "\u6570\u5024\u30D5\u30A3\u30EB\u30BF\u30FC",
  dateFilter: "\u65E5\u4ED8\u30D5\u30A3\u30EB\u30BF\u30FC",
  setFilter: "\u30BB\u30C3\u30C8\u30D5\u30A3\u30EB\u30BF\u30FC",
  // Group Column Filter
  groupFilterSelect: "\u30D5\u30A3\u30FC\u30EB\u30C9\u3092\u9078\u629E:",
  // Advanced Filter
  advancedFilterContains: "\u542B\u3080",
  advancedFilterNotContains: "\u542B\u307E\u306A\u3044",
  advancedFilterTextEquals: "\u7B49\u3057\u3044",
  advancedFilterTextNotEqual: "\u7B49\u3057\u304F\u306A\u3044",
  advancedFilterStartsWith: "\u3067\u59CB\u307E\u308B",
  advancedFilterEndsWith: "\u3067\u7D42\u308F\u308B",
  advancedFilterBlank: "\u7A7A\u767D",
  advancedFilterNotBlank: "\u7A7A\u767D\u3067\u306A\u3044",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u771F",
  advancedFilterFalse: "\u507D",
  advancedFilterAnd: "\u304B\u3064",
  advancedFilterOr: "\u307E\u305F\u306F",
  advancedFilterApply: "\u9069\u7528",
  advancedFilterBuilder: "\u30D3\u30EB\u30C0\u30FC",
  advancedFilterValidationMissingColumn: "\u5217\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationMissingOption: "\u30AA\u30D7\u30B7\u30E7\u30F3\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationMissingValue: "\u5024\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationInvalidColumn: "\u5217\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093",
  advancedFilterValidationInvalidOption: "\u30AA\u30D7\u30B7\u30E7\u30F3\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093",
  advancedFilterValidationMissingQuote: "\u5024\u306E\u7D42\u7AEF\u306E\u5F15\u7528\u7B26\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationNotANumber: "\u5024\u304C\u6570\u5024\u3067\u306F\u3042\u308A\u307E\u305B\u3093",
  advancedFilterValidationInvalidDate: "\u5024\u304C\u6709\u52B9\u306A\u65E5\u4ED8\u3067\u306F\u3042\u308A\u307E\u305B\u3093",
  advancedFilterValidationMissingCondition: "\u6761\u4EF6\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationJoinOperatorMismatch: "\u6761\u4EF6\u5185\u306E\u7D50\u5408\u6F14\u7B97\u5B50\u306F\u540C\u3058\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059",
  advancedFilterValidationInvalidJoinOperator: "\u7D50\u5408\u6F14\u7B97\u5B50\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093",
  advancedFilterValidationMissingEndBracket: "\u7D42\u7AEF\u306E\u62EC\u5F27\u304C\u6B20\u843D\u3057\u3066\u3044\u307E\u3059",
  advancedFilterValidationExtraEndBracket: "\u7D42\u7AEF\u306E\u62EC\u5F27\u304C\u591A\u3059\u304E\u307E\u3059",
  advancedFilterValidationMessage: "\u5F0F\u306B\u30A8\u30E9\u30FC\u304C\u3042\u308A\u307E\u3059\u3002${variable} - ${variable}\u3002",
  advancedFilterValidationMessageAtEnd: "\u5F0F\u306B\u30A8\u30E9\u30FC\u304C\u3042\u308A\u307E\u3059\u3002\u5F0F\u306E\u6700\u5F8C\u306B${variable}\u3002",
  advancedFilterBuilderTitle: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC",
  advancedFilterBuilderApply: "\u9069\u7528",
  advancedFilterBuilderCancel: "\u30AD\u30E3\u30F3\u30BB\u30EB",
  advancedFilterBuilderAddButtonTooltip: "\u30D5\u30A3\u30EB\u30BF\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u3092\u8FFD\u52A0",
  advancedFilterBuilderRemoveButtonTooltip: "\u524A\u9664",
  advancedFilterBuilderMoveUpButtonTooltip: "\u4E0A\u3078\u79FB\u52D5",
  advancedFilterBuilderMoveDownButtonTooltip: "\u4E0B\u3078\u79FB\u52D5",
  advancedFilterBuilderAddJoin: "\u30B0\u30EB\u30FC\u30D7\u3092\u8FFD\u52A0",
  advancedFilterBuilderAddCondition: "\u30D5\u30A3\u30EB\u30BF\u30FC\u3092\u8FFD\u52A0",
  advancedFilterBuilderSelectColumn: "\u5217\u3092\u9078\u629E",
  advancedFilterBuilderSelectOption: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3092\u9078\u629E",
  advancedFilterBuilderEnterValue: "\u5024\u3092\u5165\u529B...",
  advancedFilterBuilderValidationAlreadyApplied: "\u73FE\u5728\u306E\u30D5\u30A3\u30EB\u30BF\u30FC\u306F\u65E2\u306B\u9069\u7528\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
  advancedFilterBuilderValidationIncomplete: "\u3059\u3079\u3066\u306E\u6761\u4EF6\u304C\u5B8C\u4E86\u3057\u3066\u3044\u307E\u305B\u3093\u3002",
  advancedFilterBuilderValidationSelectColumn: "\u5217\u3092\u9078\u629E\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
  advancedFilterBuilderValidationSelectOption: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3092\u9078\u629E\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
  advancedFilterBuilderValidationEnterValue: "\u5024\u3092\u5165\u529B\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
  // Side Bar
  columns: "\u5217",
  filters: "\u30D5\u30A3\u30EB\u30BF\u30FC",
  // columns tool panel
  pivotMode: "\u30D4\u30DC\u30C3\u30C8\u30E2\u30FC\u30C9",
  groups: "\u884C\u30B0\u30EB\u30FC\u30D7",
  rowGroupColumnsEmptyMessage: "\u3053\u3053\u306B\u30C9\u30E9\u30C3\u30B0\u3057\u3066\u884C\u30B0\u30EB\u30FC\u30D7\u3092\u8A2D\u5B9A\u3057\u307E\u3059",
  values: "\u5024",
  valueColumnsEmptyMessage: "\u3053\u3053\u306B\u30C9\u30E9\u30C3\u30B0\u3057\u3066\u96C6\u8A08\u3057\u307E\u3059",
  pivots: "\u5217\u30E9\u30D9\u30EB",
  pivotColumnsEmptyMessage: "\u3053\u3053\u306B\u30C9\u30E9\u30C3\u30B0\u3057\u3066\u5217\u30E9\u30D9\u30EB\u3092\u8A2D\u5B9A\u3057\u307E\u3059",
  // Header of the Default Group Column
  group: "\u30B0\u30EB\u30FC\u30D7",
  // Row Drag
  rowDragRow: "\u884C",
  rowDragRows: "\u884C",
  // Other
  loadingOoo: "\u8AAD\u307F\u8FBC\u307F\u4E2D...",
  loadingError: "\u30A8\u30E9\u30FC",
  noRowsToShow: "\u8868\u793A\u3059\u308B\u884C\u304C\u3042\u308A\u307E\u305B\u3093",
  enabled: "\u6709\u52B9",
  // Menu
  pinColumn: "\u5217\u306E\u56FA\u5B9A",
  pinLeft: "\u5DE6\u306B\u56FA\u5B9A",
  pinRight: "\u53F3\u306B\u56FA\u5B9A",
  noPin: "\u56FA\u5B9A\u306A\u3057",
  valueAggregation: "\u5024\u306E\u96C6\u8A08",
  noAggregation: "\u306A\u3057",
  autosizeThisColumn: "\u3053\u306E\u5217\u306E\u81EA\u52D5\u30B5\u30A4\u30BA\u8ABF\u6574",
  autosizeAllColumns: "\u3059\u3079\u3066\u306E\u5217\u306E\u81EA\u52D5\u30B5\u30A4\u30BA\u8ABF\u6574",
  groupBy: "\u30B0\u30EB\u30FC\u30D7\u5316",
  ungroupBy: "\u30B0\u30EB\u30FC\u30D7\u5316\u89E3\u9664",
  ungroupAll: "\u3059\u3079\u3066\u306E\u30B0\u30EB\u30FC\u30D7\u5316\u3092\u89E3\u9664",
  addToValues: "${variable}\u3092\u5024\u306B\u8FFD\u52A0",
  removeFromValues: "${variable}\u3092\u5024\u304B\u3089\u524A\u9664",
  addToLabels: "${variable}\u3092\u30E9\u30D9\u30EB\u306B\u8FFD\u52A0",
  removeFromLabels: "${variable}\u3092\u30E9\u30D9\u30EB\u304B\u3089\u524A\u9664",
  resetColumns: "\u5217\u3092\u30EA\u30BB\u30C3\u30C8",
  expandAll: "\u3059\u3079\u3066\u306E\u884C\u30B0\u30EB\u30FC\u30D7\u3092\u5C55\u958B",
  collapseAll: "\u3059\u3079\u3066\u306E\u884C\u30B0\u30EB\u30FC\u30D7\u3092\u9589\u3058\u308B",
  copy: "\u30B3\u30D4\u30FC",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u30D8\u30C3\u30C0\u30FC\u4ED8\u304D\u3067\u30B3\u30D4\u30FC",
  copyWithGroupHeaders: "\u30B0\u30EB\u30FC\u30D7\u30D8\u30C3\u30C0\u30FC\u4ED8\u304D\u3067\u30B3\u30D4\u30FC",
  cut: "\u30AB\u30C3\u30C8",
  paste: "\u30DA\u30FC\u30B9\u30C8",
  ctrlV: "Ctrl+V",
  export: "\u30A8\u30AF\u30B9\u30DD\u30FC\u30C8",
  csvExport: "CSV\u30A8\u30AF\u30B9\u30DD\u30FC\u30C8",
  excelExport: "Excel\u30A8\u30AF\u30B9\u30DD\u30FC\u30C8",
  columnFilter: "\u5217\u30D5\u30A3\u30EB\u30BF\u30FC",
  columnChooser: "\u5217\u306E\u9078\u629E",
  chooseColumns: "\u5217\u306E\u9078\u629E",
  sortAscending: "\u6607\u9806\u3067\u4E26\u3079\u66FF\u3048",
  sortDescending: "\u964D\u9806\u3067\u4E26\u3079\u66FF\u3048",
  sortUnSort: "\u30BD\u30FC\u30C8\u89E3\u9664",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u5408\u8A08",
  first: "\u6700\u521D",
  last: "\u6700\u5F8C",
  min: "\u6700\u5C0F",
  max: "\u6700\u5927",
  none: "\u306A\u3057",
  count: "\u30AB\u30A6\u30F3\u30C8",
  avg: "\u5E73\u5747",
  filteredRows: "\u30D5\u30A3\u30EB\u30BF\u6E08\u307F",
  selectedRows: "\u9078\u629E\u6E08\u307F",
  totalRows: "\u7DCF\u884C\u6570",
  totalAndFilteredRows: "\u884C",
  more: "\u3082\u3063\u3068",
  to: "\u304B\u3089",
  of: "\u306E",
  page: "\u30DA\u30FC\u30B8",
  pageLastRowUnknown: "?",
  nextPage: "\u6B21\u306E\u30DA\u30FC\u30B8",
  lastPage: "\u6700\u5F8C\u306E\u30DA\u30FC\u30B8",
  firstPage: "\u6700\u521D\u306E\u30DA\u30FC\u30B8",
  previousPage: "\u524D\u306E\u30DA\u30FC\u30B8",
  pageSizeSelectorLabel: "\u30DA\u30FC\u30B8\u30B5\u30A4\u30BA\uFF1A",
  footerTotal: "\u5408\u8A08",
  // Pivoting
  pivotColumnGroupTotals: "\u5408\u8A08",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u30D4\u30DC\u30C3\u30C8\u30C1\u30E3\u30FC\u30C8\u3068\u30D4\u30DC\u30C3\u30C8\u30E2\u30FC\u30C9",
  pivotChart: "\u30D4\u30DC\u30C3\u30C8\u30C1\u30E3\u30FC\u30C8",
  chartRange: "\u30C1\u30E3\u30FC\u30C8\u7BC4\u56F2",
  columnChart: "\u30AB\u30E9\u30E0",
  groupedColumn: "\u30B0\u30EB\u30FC\u30D7\u5316",
  stackedColumn: "\u7A4D\u307F\u91CD\u306D",
  normalizedColumn: "100% \u7A4D\u307F\u91CD\u306D",
  barChart: "\u30D0\u30FC",
  groupedBar: "\u30B0\u30EB\u30FC\u30D7\u5316",
  stackedBar: "\u7A4D\u307F\u91CD\u306D",
  normalizedBar: "100% \u7A4D\u307F\u91CD\u306D",
  pieChart: "\u30D1\u30A4",
  pie: "\u30D1\u30A4",
  donut: "\u30C9\u30FC\u30CA\u30C4",
  line: "\u30E9\u30A4\u30F3",
  xyChart: "X Y (\u6563\u5E03\u56F3)",
  scatter: "\u6563\u5E03\u56F3",
  bubble: "\u30D0\u30D6\u30EB",
  areaChart: "\u30A8\u30EA\u30A2",
  area: "\u30A8\u30EA\u30A2",
  stackedArea: "\u7A4D\u307F\u91CD\u306D",
  normalizedArea: "100% \u7A4D\u307F\u91CD\u306D",
  histogramChart: "\u30D2\u30B9\u30C8\u30B0\u30E9\u30E0",
  polarChart: "\u6975\u5EA7\u6A19",
  radarLine: "\u30EC\u30FC\u30C0\u30FC\u30E9\u30A4\u30F3",
  radarArea: "\u30EC\u30FC\u30C0\u30FC\u30A8\u30EA\u30A2",
  nightingale: "\u30CA\u30A4\u30C1\u30F3\u30B2\u30FC\u30EB",
  radialColumn: "\u653E\u5C04\u72B6\u30AB\u30E9\u30E0",
  radialBar: "\u653E\u5C04\u72B6\u30D0\u30FC",
  statisticalChart: "\u7D71\u8A08",
  boxPlot: "\u30DC\u30C3\u30AF\u30B9\u30D7\u30ED\u30C3\u30C8",
  rangeBar: "\u30EC\u30F3\u30B8\u30D0\u30FC",
  rangeArea: "\u30EC\u30F3\u30B8\u30A8\u30EA\u30A2",
  hierarchicalChart: "\u968E\u5C64",
  treemap: "\u30C4\u30EA\u30FC\u30DE\u30C3\u30D7",
  sunburst: "\u30B5\u30F3\u30D0\u30FC\u30B9\u30C8",
  specializedChart: "\u7279\u6B8A",
  waterfall: "\u30A6\u30A9\u30FC\u30BF\u30FC\u30D5\u30A9\u30FC\u30EB",
  heatmap: "\u30D2\u30FC\u30C8\u30DE\u30C3\u30D7",
  combinationChart: "\u30B3\u30F3\u30D3\u30CD\u30FC\u30B7\u30E7\u30F3",
  columnLineCombo: "\u30AB\u30E9\u30E0\u3068\u30E9\u30A4\u30F3",
  AreaColumnCombo: "\u30A8\u30EA\u30A2\u3068\u30AB\u30E9\u30E0",
  // Charts
  pivotChartTitle: "\u30D4\u30DC\u30C3\u30C8\u30C1\u30E3\u30FC\u30C8",
  rangeChartTitle: "\u7BC4\u56F2\u30C1\u30E3\u30FC\u30C8",
  settings: "\u30C1\u30E3\u30FC\u30C8",
  data: "\u8A2D\u5B9A",
  format: "\u30AB\u30B9\u30BF\u30DE\u30A4\u30BA",
  categories: "\u30AB\u30C6\u30B4\u30EA\u30FC",
  defaultCategory: "(\u306A\u3057)",
  series: "\u30B7\u30EA\u30FC\u30BA",
  switchCategorySeries: "\u30AB\u30C6\u30B4\u30EA\u30FC/\u30B7\u30EA\u30FC\u30BA\u306E\u5207\u308A\u66FF\u3048",
  categoryValues: "\u30AB\u30C6\u30B4\u30EA\u30FC\u5024",
  seriesLabels: "\u30B7\u30EA\u30FC\u30BA\u30E9\u30D9\u30EB",
  aggregate: "\u96C6\u8A08",
  xyValues: "X Y\u5024",
  paired: "\u30DA\u30A2\u30E2\u30FC\u30C9",
  axis: "\u8EF8",
  xAxis: "\u6C34\u5E73\u8EF8",
  yAxis: "\u5782\u76F4\u8EF8",
  polarAxis: "\u6975\u8EF8",
  radiusAxis: "\u534A\u5F84\u8EF8",
  navigator: "\u30CA\u30D3\u30B2\u30FC\u30BF\u30FC",
  zoom: "\u30BA\u30FC\u30E0",
  animation: "\u30A2\u30CB\u30E1\u30FC\u30B7\u30E7\u30F3",
  crosshair: "\u30AF\u30ED\u30B9\u30D8\u30A2",
  color: "\u8272",
  thickness: "\u539A\u3055",
  preferredLength: "\u63A8\u5968\u9577\u3055",
  xType: "X\u30BF\u30A4\u30D7",
  axisType: "\u8EF8\u30BF\u30A4\u30D7",
  automatic: "\u81EA\u52D5",
  category: "\u30AB\u30C6\u30B4\u30EA\u30FC",
  number: "\u6570\u5024",
  time: "\u6642\u9593",
  timeFormat: "\u6642\u9593\u5F62\u5F0F",
  autoRotate: "\u81EA\u52D5\u56DE\u8EE2",
  labelRotation: "\u56DE\u8EE2",
  circle: "\u5186",
  polygon: "\u591A\u89D2\u5F62",
  square: "\u56DB\u89D2\u5F62",
  cross: "\u30AF\u30ED\u30B9",
  diamond: "\u30C0\u30A4\u30E4\u30E2\u30F3\u30C9",
  plus: "\u30D7\u30E9\u30B9",
  triangle: "\u4E09\u89D2\u5F62",
  heart: "\u30CF\u30FC\u30C8",
  orientation: "\u65B9\u5411",
  fixed: "\u56FA\u5B9A",
  parallel: "\u5E73\u884C",
  perpendicular: "\u5782\u76F4",
  radiusAxisPosition: "\u4F4D\u7F6E",
  ticks: "\u76EE\u76DB",
  gridLines: "\u30B0\u30EA\u30C3\u30C9\u7DDA",
  width: "\u5E45",
  height: "\u9AD8\u3055",
  length: "\u9577\u3055",
  padding: "\u30D1\u30C7\u30A3\u30F3\u30B0",
  spacing: "\u9593\u9694",
  chartStyle: "\u30C1\u30E3\u30FC\u30C8\u30B9\u30BF\u30A4\u30EB",
  title: "\u30BF\u30A4\u30C8\u30EB",
  chartTitles: "\u30BF\u30A4\u30C8\u30EB",
  chartTitle: "\u30C1\u30E3\u30FC\u30C8\u30BF\u30A4\u30C8\u30EB",
  chartSubtitle: "\u30B5\u30D6\u30BF\u30A4\u30C8\u30EB",
  horizontalAxisTitle: "\u6C34\u5E73\u8EF8\u30BF\u30A4\u30C8\u30EB",
  verticalAxisTitle: "\u5782\u76F4\u8EF8\u30BF\u30A4\u30C8\u30EB",
  polarAxisTitle: "\u6975\u8EF8\u30BF\u30A4\u30C8\u30EB",
  titlePlaceholder: "\u30C1\u30E3\u30FC\u30C8\u30BF\u30A4\u30C8\u30EB",
  background: "\u80CC\u666F",
  font: "\u30D5\u30A9\u30F3\u30C8",
  weight: "\u592A\u3055",
  top: "\u4E0A",
  right: "\u53F3",
  bottom: "\u4E0B",
  left: "\u5DE6",
  labels: "\u30E9\u30D9\u30EB",
  calloutLabels: "\u30B3\u30FC\u30EB\u30A2\u30A6\u30C8\u30E9\u30D9\u30EB",
  sectorLabels: "\u30BB\u30AF\u30BF\u30FC\u30E9\u30D9\u30EB",
  positionRatio: "\u4F4D\u7F6E\u6BD4\u7387",
  size: "\u30B5\u30A4\u30BA",
  shape: "\u5F62\u72B6",
  minSize: "\u6700\u5C0F\u30B5\u30A4\u30BA",
  maxSize: "\u6700\u5927\u30B5\u30A4\u30BA",
  legend: "\u51E1\u4F8B",
  position: "\u4F4D\u7F6E",
  markerSize: "\u30DE\u30FC\u30AB\u30FC\u30B5\u30A4\u30BA",
  markerStroke: "\u30DE\u30FC\u30AB\u30FC\u30B9\u30C8\u30ED\u30FC\u30AF",
  markerPadding: "\u30DE\u30FC\u30AB\u30FC\u30D1\u30C7\u30A3\u30F3\u30B0",
  itemSpacing: "\u9805\u76EE\u9593\u9694",
  itemPaddingX: "\u9805\u76EE\u30D1\u30C7\u30A3\u30F3\u30B0X",
  itemPaddingY: "\u9805\u76EE\u30D1\u30C7\u30A3\u30F3\u30B0Y",
  layoutHorizontalSpacing: "\u6C34\u5E73\u9593\u9694",
  layoutVerticalSpacing: "\u5782\u76F4\u9593\u9694",
  strokeWidth: "\u30B9\u30C8\u30ED\u30FC\u30AF\u5E45",
  offset: "\u30AA\u30D5\u30BB\u30C3\u30C8",
  offsets: "\u30AA\u30D5\u30BB\u30C3\u30C8",
  tooltips: "\u30C4\u30FC\u30EB\u30C1\u30C3\u30D7",
  callout: "\u30B3\u30FC\u30EB\u30A2\u30A6\u30C8",
  markers: "\u30DE\u30FC\u30AB\u30FC",
  shadow: "\u5F71",
  blur: "\u307C\u304B\u3057",
  xOffset: "X\u30AA\u30D5\u30BB\u30C3\u30C8",
  yOffset: "Y\u30AA\u30D5\u30BB\u30C3\u30C8",
  lineWidth: "\u7DDA\u306E\u5E45",
  lineDash: "\u7DDA\u306E\u7834\u7DDA",
  lineDashOffset: "\u7834\u7DDA\u30AA\u30D5\u30BB\u30C3\u30C8",
  scrollingZoom: "\u30B9\u30AF\u30ED\u30FC\u30EB",
  scrollingStep: "\u30B9\u30AF\u30ED\u30FC\u30EB\u30B9\u30C6\u30C3\u30D7",
  selectingZoom: "\u9078\u629E",
  durationMillis: "\u671F\u9593 (ms)",
  crosshairLabel: "\u30E9\u30D9\u30EB",
  crosshairSnap: "\u30CE\u30FC\u30C9\u306B\u30B9\u30CA\u30C3\u30D7",
  normal: "\u666E\u901A",
  bold: "\u592A\u5B57",
  italic: "\u30A4\u30BF\u30EA\u30C3\u30AF",
  boldItalic: "\u592A\u5B57\u30A4\u30BF\u30EA\u30C3\u30AF",
  predefined: "\u5B9A\u7FA9\u6E08\u307F",
  fillOpacity: "\u5857\u308A\u306E\u4E0D\u900F\u660E\u5EA6",
  strokeColor: "\u7DDA\u306E\u8272",
  strokeOpacity: "\u7DDA\u306E\u4E0D\u900F\u660E\u5EA6",
  miniChart: "\u30DF\u30CB\u30C1\u30E3\u30FC\u30C8",
  histogramBinCount: "\u30D3\u30F3\u306E\u6570",
  connectorLine: "\u30B3\u30CD\u30AF\u30BF\u30FC\u7DDA",
  seriesItems: "\u30B7\u30EA\u30FC\u30BA\u30A2\u30A4\u30C6\u30E0",
  seriesItemType: "\u30A2\u30A4\u30C6\u30E0\u30BF\u30A4\u30D7",
  seriesItemPositive: "\u30DD\u30B8\u30C6\u30A3\u30D6",
  seriesItemNegative: "\u30CD\u30AC\u30C6\u30A3\u30D6",
  seriesItemLabels: "\u30A2\u30A4\u30C6\u30E0\u30E9\u30D9\u30EB",
  columnGroup: "\u30AB\u30E9\u30E0",
  barGroup: "\u30D0\u30FC",
  pieGroup: "\u30D1\u30A4",
  lineGroup: "\u30E9\u30A4\u30F3",
  scatterGroup: "X Y (\u6563\u5E03\u56F3)",
  areaGroup: "\u30A8\u30EA\u30A2",
  polarGroup: "\u6975",
  statisticalGroup: "\u7D71\u8A08",
  hierarchicalGroup: "\u968E\u5C64",
  specializedGroup: "\u5C02\u9580",
  combinationGroup: "\u30B3\u30F3\u30D3\u30CD\u30FC\u30B7\u30E7\u30F3",
  groupedColumnTooltip: "\u30B0\u30EB\u30FC\u30D7\u5316",
  stackedColumnTooltip: "\u7A4D\u307F\u4E0A\u3052",
  normalizedColumnTooltip: "100%\u7A4D\u307F\u4E0A\u3052",
  groupedBarTooltip: "\u30B0\u30EB\u30FC\u30D7\u5316",
  stackedBarTooltip: "\u7A4D\u307F\u4E0A\u3052",
  normalizedBarTooltip: "100%\u7A4D\u307F\u4E0A\u3052",
  pieTooltip: "\u30D1\u30A4",
  donutTooltip: "\u30C9\u30FC\u30CA\u30C4",
  lineTooltip: "\u30E9\u30A4\u30F3",
  groupedAreaTooltip: "\u30A8\u30EA\u30A2",
  stackedAreaTooltip: "\u7A4D\u307F\u4E0A\u3052",
  normalizedAreaTooltip: "100%\u7A4D\u307F\u4E0A\u3052",
  scatterTooltip: "\u6563\u5E03\u56F3",
  bubbleTooltip: "\u30D0\u30D6\u30EB",
  histogramTooltip: "\u30D2\u30B9\u30C8\u30B0\u30E9\u30E0",
  radialColumnTooltip: "\u30E9\u30B8\u30A2\u30EB\u30AB\u30E9\u30E0",
  radialBarTooltip: "\u30E9\u30B8\u30A2\u30EB\u30D0\u30FC",
  radarLineTooltip: "\u30EC\u30FC\u30C0\u30FC\u30E9\u30A4\u30F3",
  radarAreaTooltip: "\u30EC\u30FC\u30C0\u30FC\u30A8\u30EA\u30A2",
  nightingaleTooltip: "\u30CA\u30A4\u30C1\u30F3\u30B2\u30FC\u30EB",
  rangeBarTooltip: "\u7BC4\u56F2\u30D0\u30FC",
  rangeAreaTooltip: "\u7BC4\u56F2\u30A8\u30EA\u30A2",
  boxPlotTooltip: "\u30DC\u30C3\u30AF\u30B9\u30D7\u30ED\u30C3\u30C8",
  treemapTooltip: "\u30C4\u30EA\u30FC\u30DE\u30C3\u30D7",
  sunburstTooltip: "\u30B5\u30F3\u30D0\u30FC\u30B9\u30C8",
  waterfallTooltip: "\u30A6\u30A9\u30FC\u30BF\u30FC\u30D5\u30A9\u30FC\u30EB",
  heatmapTooltip: "\u30D2\u30FC\u30C8\u30DE\u30C3\u30D7",
  columnLineComboTooltip: "\u30AB\u30E9\u30E0\uFF06\u30E9\u30A4\u30F3",
  areaColumnComboTooltip: "\u30A8\u30EA\u30A2\uFF06\u30AB\u30E9\u30E0",
  customComboTooltip: "\u30AB\u30B9\u30BF\u30E0\u30B3\u30F3\u30D3\u30CD\u30FC\u30B7\u30E7\u30F3",
  innerRadius: "\u5185\u5074\u534A\u5F84",
  startAngle: "\u958B\u59CB\u89D2\u5EA6",
  endAngle: "\u7D42\u4E86\u89D2\u5EA6",
  reverseDirection: "\u9006\u65B9\u5411",
  groupPadding: "\u30B0\u30EB\u30FC\u30D7\u30D1\u30C7\u30A3\u30F3\u30B0",
  seriesPadding: "\u30B7\u30EA\u30FC\u30BA\u30D1\u30C7\u30A3\u30F3\u30B0",
  tile: "\u30BF\u30A4\u30EB",
  whisker: "\u30A6\u30A3\u30B9\u30AB\u30FC",
  cap: "\u30AD\u30E3\u30C3\u30D7",
  capLengthRatio: "\u9577\u3055\u6BD4\u7387",
  labelPlacement: "\u914D\u7F6E",
  inside: "\u5185\u5074",
  outside: "\u5916\u5074",
  noDataToChart: "\u30C1\u30E3\u30FC\u30C8\u306B\u3059\u308B\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093\u3002",
  pivotChartRequiresPivotMode: "\u30D4\u30DC\u30C3\u30C8\u30C1\u30E3\u30FC\u30C8\u306B\u306F\u30D4\u30DC\u30C3\u30C8\u30E2\u30FC\u30C9\u304C\u5FC5\u8981\u3067\u3059\u3002",
  chartSettingsToolbarTooltip: "\u30E1\u30CB\u30E5\u30FC",
  chartLinkToolbarTooltip: "\u30B0\u30EA\u30C3\u30C9\u306B\u30EA\u30F3\u30AF",
  chartUnlinkToolbarTooltip: "\u30B0\u30EA\u30C3\u30C9\u304B\u3089\u30EA\u30F3\u30AF\u89E3\u9664",
  chartDownloadToolbarTooltip: "\u30C1\u30E3\u30FC\u30C8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
  chartMenuToolbarTooltip: "\u30E1\u30CB\u30E5\u30FC",
  chartEdit: "\u30C1\u30E3\u30FC\u30C8\u3092\u7DE8\u96C6",
  chartAdvancedSettings: "\u8A73\u7D30\u8A2D\u5B9A",
  chartLink: "\u30B0\u30EA\u30C3\u30C9\u306B\u30EA\u30F3\u30AF",
  chartUnlink: "\u30B0\u30EA\u30C3\u30C9\u304B\u3089\u30EA\u30F3\u30AF\u89E3\u9664",
  chartDownload: "\u30C1\u30E3\u30FC\u30C8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
  histogramFrequency: "\u983B\u5EA6",
  seriesChartType: "\u30B7\u30EA\u30FC\u30BA\u30C1\u30E3\u30FC\u30C8\u30BF\u30A4\u30D7",
  seriesType: "\u30B7\u30EA\u30FC\u30BA\u30BF\u30A4\u30D7",
  secondaryAxis: "\u7B2C\u4E8C\u8EF8",
  seriesAdd: "\u30B7\u30EA\u30FC\u30BA\u3092\u8FFD\u52A0",
  categoryAdd: "\u30AB\u30C6\u30B4\u30EA\u3092\u8FFD\u52A0",
  bar: "\u30D0\u30FC",
  column: "\u30AB\u30E9\u30E0",
  histogram: "\u30D2\u30B9\u30C8\u30B0\u30E9\u30E0",
  advancedSettings: "\u8A73\u7D30\u8A2D\u5B9A",
  direction: "\u65B9\u5411",
  horizontal: "\u6C34\u5E73",
  vertical: "\u5782\u76F4",
  seriesGroupType: "\u30B0\u30EB\u30FC\u30D7\u30BF\u30A4\u30D7",
  groupedSeriesGroupType: "\u30B0\u30EB\u30FC\u30D7\u5316",
  stackedSeriesGroupType: "\u7A4D\u307F\u4E0A\u3052",
  normalizedSeriesGroupType: "100%\u7A4D\u307F\u4E0A\u3052",
  legendEnabled: "\u6709\u52B9",
  invalidColor: "\u8272\u306E\u5024\u304C\u7121\u52B9\u3067\u3059",
  groupedColumnFull: "\u30B0\u30EB\u30FC\u30D7\u5316\u30AB\u30E9\u30E0",
  stackedColumnFull: "\u7A4D\u307F\u4E0A\u3052\u30AB\u30E9\u30E0",
  normalizedColumnFull: "100%\u7A4D\u307F\u4E0A\u3052\u30AB\u30E9\u30E0",
  groupedBarFull: "\u30B0\u30EB\u30FC\u30D7\u5316\u30D0\u30FC",
  stackedBarFull: "\u7A4D\u307F\u4E0A\u3052\u30D0\u30FC",
  normalizedBarFull: "100%\u7A4D\u307F\u4E0A\u3052\u30D0\u30FC",
  stackedAreaFull: "\u7A4D\u307F\u4E0A\u3052\u30A8\u30EA\u30A2",
  normalizedAreaFull: "100%\u7A4D\u307F\u4E0A\u3052\u30A8\u30EA\u30A2",
  customCombo: "\u30AB\u30B9\u30BF\u30E0\u30B3\u30F3\u30D3\u30CD\u30FC\u30B7\u30E7\u30F3",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}\u3002 \u30EC\u30D9\u30EB ${variable}\u3002 \u7DE8\u96C6\u3059\u308B\u306B\u306FENTER\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
  ariaAdvancedFilterBuilderItemValidation: "${variable}\u3002 \u30EC\u30D9\u30EB ${variable}\u3002 ${variable} \u7DE8\u96C6\u3059\u308B\u306B\u306FENTER\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
  ariaAdvancedFilterBuilderList: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30D3\u30EB\u30C0\u30EA\u30B9\u30C8",
  ariaAdvancedFilterBuilderFilterItem: "\u30D5\u30A3\u30EB\u30BF\u30FC\u6761\u4EF6",
  ariaAdvancedFilterBuilderGroupItem: "\u30D5\u30A3\u30EB\u30BF\u30FC\u30B0\u30EB\u30FC\u30D7",
  ariaAdvancedFilterBuilderColumn: "\u5217",
  ariaAdvancedFilterBuilderOption: "\u30AA\u30D7\u30B7\u30E7\u30F3",
  ariaAdvancedFilterBuilderValueP: "\u5024",
  ariaAdvancedFilterBuilderJoinOperator: "\u7D50\u5408\u6F14\u7B97\u5B50",
  ariaAdvancedFilterInput: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u5165\u529B",
  ariaChecked: "\u30C1\u30A7\u30C3\u30AF\u6E08\u307F",
  ariaColumn: "\u5217",
  ariaColumnGroup: "\u5217\u30B0\u30EB\u30FC\u30D7",
  ariaColumnFiltered: "\u30D5\u30A3\u30EB\u30BF\u30FC\u304C\u9069\u7528\u3055\u308C\u305F\u5217",
  ariaColumnSelectAll: "\u3059\u3079\u3066\u306E\u5217\u3092\u9078\u629E/\u9078\u629E\u89E3\u9664",
  ariaDateFilterInput: "\u65E5\u4ED8\u30D5\u30A3\u30EB\u30BF\u30FC\u5165\u529B",
  ariaDefaultListName: "\u30EA\u30B9\u30C8",
  ariaFilterColumnsInput: "\u30D5\u30A3\u30EB\u30BF\u30FC\u5217\u5165\u529B",
  ariaFilterFromValue: "\u5024\u304B\u3089\u30D5\u30A3\u30EB\u30BF\u30FC",
  ariaFilterInput: "\u30D5\u30A3\u30EB\u30BF\u30FC\u5165\u529B",
  ariaFilterList: "\u30D5\u30A3\u30EB\u30BF\u30FC\u30EA\u30B9\u30C8",
  ariaFilterToValue: "\u5024\u307E\u3067\u30D5\u30A3\u30EB\u30BF\u30FC",
  ariaFilterValue: "\u30D5\u30A3\u30EB\u30BF\u30FC\u5024",
  ariaFilterMenuOpen: "\u30D5\u30A3\u30EB\u30BF\u30FC\u30E1\u30CB\u30E5\u30FC\u3092\u958B\u304F",
  ariaFilteringOperator: "\u30D5\u30A3\u30EB\u30BF\u30FC\u6F14\u7B97\u5B50",
  ariaHidden: "\u975E\u8868\u793A",
  ariaIndeterminate: "\u4E0D\u78BA\u5B9A",
  ariaInputEditor: "\u5165\u529B\u30A8\u30C7\u30A3\u30BF\u30FC",
  ariaMenuColumn: "\u5217\u30E1\u30CB\u30E5\u30FC\u3092\u958B\u304F\u306B\u306FALT+\u2193\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaFilterColumn: "\u30D5\u30A3\u30EB\u30BF\u30FC\u3092\u958B\u304F\u306B\u306FCTRL+ENTER\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaRowDeselect: "\u3053\u306E\u884C\u306E\u9078\u629E\u3092\u89E3\u9664\u3059\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaRowSelectAll: "\u3059\u3079\u3066\u306E\u884C\u306E\u9078\u629E\u3092\u5207\u308A\u66FF\u3048\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaRowToggleSelection: "\u884C\u306E\u9078\u629E\u3092\u5207\u308A\u66FF\u3048\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaRowSelect: "\u3053\u306E\u884C\u3092\u9078\u629E\u3059\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaRowSelectionDisabled: "\u3053\u306E\u884C\u306E\u9078\u629E\u306F\u7121\u52B9\u3067\u3059",
  ariaSearch: "\u691C\u7D22",
  ariaSortableColumn: "\u4E26\u3079\u66FF\u3048\u308B\u306B\u306FENTER\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaToggleVisibility: "\u8868\u793A\u3092\u5207\u308A\u66FF\u3048\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaToggleCellValue: "\u30BB\u30EB\u306E\u5024\u3092\u5207\u308A\u66FF\u3048\u308B\u306B\u306FSPACE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaUnchecked: "\u672A\u30C1\u30A7\u30C3\u30AF",
  ariaVisible: "\u8868\u793A",
  ariaSearchFilterValues: "\u30D5\u30A3\u30EB\u30BF\u30FC\u5024\u3092\u691C\u7D22",
  ariaPageSizeSelectorLabel: "\u30DA\u30FC\u30B8\u30B5\u30A4\u30BA",
  ariaChartMenuClose: "\u30C1\u30E3\u30FC\u30C8\u7DE8\u96C6\u30E1\u30CB\u30E5\u30FC\u3092\u9589\u3058\u308B",
  ariaChartSelected: "\u9078\u629E\u6E08\u307F",
  ariaSkeletonCellLoadingFailed: "\u884C\u306E\u8AAD\u307F\u8FBC\u307F\u306B\u5931\u6557\u3057\u307E\u3057\u305F",
  ariaSkeletonCellLoading: "\u884C\u30C7\u30FC\u30BF\u3092\u8AAD\u307F\u8FBC\u307F\u4E2D",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u884C\u30B0\u30EB\u30FC\u30D7",
  ariaValuesDropZonePanelLabel: "\u5024",
  ariaPivotDropZonePanelLabel: "\u5217\u30E9\u30D9\u30EB",
  ariaDropZoneColumnComponentDescription: "\u524A\u9664\u3059\u308B\u306B\u306F DELETE \u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaDropZoneColumnValueItemDescription: "\u96C6\u8A08\u30BF\u30A4\u30D7\u3092\u5909\u66F4\u3059\u308B\u306B\u306F ENTER \u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaDropZoneColumnGroupItemDescription: "\u30BD\u30FC\u30C8\u3059\u308B\u306B\u306F ENTER \u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u306E ",
  ariaDropZoneColumnComponentSortAscending: "\u6607\u9806",
  ariaDropZoneColumnComponentSortDescending: "\u964D\u9806",
  ariaLabelDialog: "\u30C0\u30A4\u30A2\u30ED\u30B0",
  ariaLabelColumnMenu: "\u5217\u30E1\u30CB\u30E5\u30FC",
  ariaLabelColumnFilter: "\u5217\u30D5\u30A3\u30EB\u30BF\u30FC",
  ariaLabelCellEditor: "\u30BB\u30EB\u30A8\u30C7\u30A3\u30BF\u30FC",
  ariaLabelSelectField: "\u30D5\u30A3\u30FC\u30EB\u30C9\u3092\u9078\u629E",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u30EA\u30C3\u30C1\u30BB\u30EC\u30AF\u30C8\u30D5\u30A3\u30FC\u30EB\u30C9",
  ariaLabelRichSelectToggleSelection: "\u9078\u629E\u3092\u5207\u308A\u66FF\u3048\u308B\u306B\u306F\u30B9\u30DA\u30FC\u30B9\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaLabelRichSelectDeselectAllItems: "\u3059\u3079\u3066\u306E\u30A2\u30A4\u30C6\u30E0\u306E\u9078\u629E\u3092\u89E3\u9664\u3059\u308B\u306B\u306FDELETE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaLabelRichSelectDeleteSelection: "\u30A2\u30A4\u30C6\u30E0\u306E\u9078\u629E\u3092\u89E3\u9664\u3059\u308B\u306B\u306FDELETE\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044",
  ariaLabelTooltip: "\u30C4\u30FC\u30EB\u30C1\u30C3\u30D7",
  ariaLabelContextMenu: "\u30B3\u30F3\u30C6\u30AD\u30B9\u30C8\u30E1\u30CB\u30E5\u30FC",
  ariaLabelSubMenu: "\u30B5\u30D6\u30E1\u30CB\u30E5\u30FC",
  ariaLabelAggregationFunction: "\u96C6\u8A08\u95A2\u6570",
  ariaLabelAdvancedFilterAutocomplete: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30AA\u30FC\u30C8\u30B3\u30F3\u30D7\u30EA\u30FC\u30C8",
  ariaLabelAdvancedFilterBuilderAddField: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30D3\u30EB\u30C0\u30FC\u306B\u30D5\u30A3\u30FC\u30EB\u30C9\u3092\u8FFD\u52A0",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30D3\u30EB\u30C0\u30FC\u306E\u5217\u9078\u629E\u30D5\u30A3\u30FC\u30EB\u30C9",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30D3\u30EB\u30C0\u30FC\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u9078\u629E\u30D5\u30A3\u30FC\u30EB\u30C9",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u9AD8\u5EA6\u306A\u30D5\u30A3\u30EB\u30BF\u30FC\u30D3\u30EB\u30C0\u30FC\u306E\u7D50\u5408\u6F14\u7B97\u5B50\u9078\u629E\u30D5\u30A3\u30FC\u30EB\u30C9",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u5217\u30EA\u30B9\u30C8",
  ariaFilterPanelList: "\u30D5\u30A3\u30EB\u30BF\u30FC\u30EA\u30B9\u30C8",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "\u3001",
  decimalSeparator: "\u3002",
  // Data types
  true: "\u771F",
  false: "\u507D",
  invalidDate: "\u7121\u52B9\u306A\u65E5\u4ED8",
  invalidNumber: "\u7121\u52B9\u306A\u6570\u5024",
  january: "1\u6708",
  february: "2\u6708",
  march: "3\u6708",
  april: "4\u6708",
  may: "5\u6708",
  june: "6\u6708",
  july: "7\u6708",
  august: "8\u6708",
  september: "9\u6708",
  october: "10\u6708",
  november: "11\u6708",
  december: "12\u6708",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS \u5348\u524D/\u5348\u5F8C"
};

// community-modules/locale/src/ko-KR.ts
var AG_GRID_LOCALE_KR = {
  // Set Filter
  selectAll: "(\uC804\uCCB4 \uC120\uD0DD)",
  selectAllSearchResults: "(\uAC80\uC0C9 \uACB0\uACFC \uBAA8\uB450 \uC120\uD0DD)",
  addCurrentSelectionToFilter: "\uD604\uC7AC \uC120\uD0DD\uC744 \uD544\uD130\uC5D0 \uCD94\uAC00",
  searchOoo: "\uAC80\uC0C9...",
  blanks: "(\uBE48\uCE78)",
  noMatches: "\uC77C\uCE58\uD558\uB294 \uD56D\uBAA9\uC774 \uC5C6\uC2B5\uB2C8\uB2E4",
  // Number Filter & Text Filter
  filterOoo: "\uD544\uD130...",
  equals: "\uAC19\uC74C",
  notEqual: "\uAC19\uC9C0 \uC54A\uC74C",
  blank: "\uBE48\uCE78",
  notBlank: "\uBE44\uC5B4 \uC788\uC9C0 \uC54A\uC74C",
  empty: "\uC120\uD0DD\uD558\uC138\uC694",
  // Number Filter
  lessThan: "\uBCF4\uB2E4 \uC791\uC74C",
  greaterThan: "\uBCF4\uB2E4 \uD07C",
  lessThanOrEqual: "\uC774\uD558",
  greaterThanOrEqual: "\uC774\uC0C1",
  inRange: "\uC0AC\uC774",
  inRangeStart: "\uBD80\uD130",
  inRangeEnd: "\uAE4C\uC9C0",
  // Text Filter
  contains: "\uD3EC\uD568",
  notContains: "\uD3EC\uD568\uD558\uC9C0 \uC54A\uC74C",
  startsWith: "\uC2DC\uC791",
  endsWith: "\uB05D",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\uC774\uC804",
  after: "\uC774\uD6C4",
  // Filter Conditions
  andCondition: "AND",
  orCondition: "OR",
  // Filter Buttons
  applyFilter: "\uC801\uC6A9",
  resetFilter: "\uC7AC\uC124\uC815",
  clearFilter: "\uC9C0\uC6B0\uAE30",
  cancelFilter: "\uCDE8\uC18C",
  // Filter Titles
  textFilter: "\uD14D\uC2A4\uD2B8 \uD544\uD130",
  numberFilter: "\uC22B\uC790 \uD544\uD130",
  dateFilter: "\uB0A0\uC9DC \uD544\uD130",
  setFilter: "\uC138\uD2B8 \uD544\uD130",
  // Group Column Filter
  groupFilterSelect: "\uD544\uB4DC\uB97C \uC120\uD0DD\uD558\uC138\uC694:",
  // Advanced Filter
  advancedFilterContains: "\uD3EC\uD568",
  advancedFilterNotContains: "\uD3EC\uD568\uD558\uC9C0 \uC54A\uC74C",
  advancedFilterTextEquals: "\uAC19\uC74C",
  advancedFilterTextNotEqual: "\uAC19\uC9C0 \uC54A\uC74C",
  advancedFilterStartsWith: "\uC2DC\uC791\uC774",
  advancedFilterEndsWith: "\uB05D\uC774",
  advancedFilterBlank: "\uBE44\uC5B4 \uC788\uC74C",
  advancedFilterNotBlank: "\uBE44\uC5B4 \uC788\uC9C0 \uC54A\uC74C",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\uCC38\uC784",
  advancedFilterFalse: "\uAC70\uC9D3\uC784",
  advancedFilterAnd: "AND",
  advancedFilterOr: "OR",
  advancedFilterApply: "\uC801\uC6A9",
  advancedFilterBuilder: "\uC0DD\uC131\uAE30",
  advancedFilterValidationMissingColumn: "\uC5F4\uC774 \uB204\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationMissingOption: "\uC635\uC158\uC774 \uB204\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationMissingValue: "\uAC12\uC774 \uB204\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationInvalidColumn: "\uC5F4\uC744 \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationInvalidOption: "\uC635\uC158\uC744 \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationMissingQuote: "\uAC12\uC5D0 \uB05D\uB098\uB294 \uC778\uC6A9\uBD80\uD638\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationNotANumber: "\uAC12\uC774 \uC22B\uC790\uAC00 \uC544\uB2D9\uB2C8\uB2E4",
  advancedFilterValidationInvalidDate: "\uAC12\uC774 \uC720\uD6A8\uD55C \uB0A0\uC9DC\uAC00 \uC544\uB2D9\uB2C8\uB2E4",
  advancedFilterValidationMissingCondition: "\uC870\uAC74\uC774 \uB204\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationJoinOperatorMismatch: "\uC870\uAC74 \uB0B4\uC758 \uC5F0\uACB0 \uC5F0\uC0B0\uC790\uB294 \uB3D9\uC77C\uD574\uC57C \uD569\uB2C8\uB2E4",
  advancedFilterValidationInvalidJoinOperator: "\uC5F0\uACB0 \uC5F0\uC0B0\uC790\uB97C \uCC3E\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationMissingEndBracket: "\uB05D \uAD04\uD638\uAC00 \uB204\uB77D\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationExtraEndBracket: "\uC885\uACB0 \uAD04\uD638\uAC00 \uB108\uBB34 \uB9CE\uC2B5\uB2C8\uB2E4",
  advancedFilterValidationMessage: "\uC2DD\uC5D0 \uC624\uB958\uAC00 \uC788\uC2B5\uB2C8\uB2E4. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\uC2DD\uC5D0 \uC624\uB958\uAC00 \uC788\uC2B5\uB2C8\uB2E4. ${variable} \uC2DD\uC758 \uB05D\uC5D0 \uC788\uC2B5\uB2C8\uB2E4.",
  advancedFilterBuilderTitle: "\uACE0\uAE09 \uD544\uD130",
  advancedFilterBuilderApply: "\uC801\uC6A9",
  advancedFilterBuilderCancel: "\uCDE8\uC18C",
  advancedFilterBuilderAddButtonTooltip: "\uD544\uD130 \uB610\uB294 \uADF8\uB8F9 \uCD94\uAC00",
  advancedFilterBuilderRemoveButtonTooltip: "\uC81C\uAC70",
  advancedFilterBuilderMoveUpButtonTooltip: "\uC704\uB85C \uC774\uB3D9",
  advancedFilterBuilderMoveDownButtonTooltip: "\uC544\uB798\uB85C \uC774\uB3D9",
  advancedFilterBuilderAddJoin: "\uADF8\uB8F9 \uCD94\uAC00",
  advancedFilterBuilderAddCondition: "\uD544\uD130 \uCD94\uAC00",
  advancedFilterBuilderSelectColumn: "\uC5F4 \uC120\uD0DD",
  advancedFilterBuilderSelectOption: "\uC635\uC158 \uC120\uD0DD",
  advancedFilterBuilderEnterValue: "\uAC12\uC744 \uC785\uB825\uD558\uC138\uC694...",
  advancedFilterBuilderValidationAlreadyApplied: "\uD604\uC7AC \uD544\uD130\uAC00 \uC774\uBBF8 \uC801\uC6A9\uB418\uC5C8\uC2B5\uB2C8\uB2E4.",
  advancedFilterBuilderValidationIncomplete: "\uBAA8\uB4E0 \uC870\uAC74\uC774 \uC644\uB8CC\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4.",
  advancedFilterBuilderValidationSelectColumn: "\uC5F4\uC744 \uC120\uD0DD\uD574\uC57C \uD569\uB2C8\uB2E4.",
  advancedFilterBuilderValidationSelectOption: "\uC635\uC158\uC744 \uC120\uD0DD\uD574\uC57C \uD569\uB2C8\uB2E4.",
  advancedFilterBuilderValidationEnterValue: "\uAC12\uC744 \uC785\uB825\uD574\uC57C \uD569\uB2C8\uB2E4.",
  // Side Bar
  columns: "\uC5F4",
  filters: "\uD544\uD130",
  // columns tool panel
  pivotMode: "\uD53C\uBC97 \uBAA8\uB4DC",
  groups: "\uD589 \uADF8\uB8F9",
  rowGroupColumnsEmptyMessage: "\uC5EC\uAE30\uB85C \uB4DC\uB798\uADF8\uD558\uC5EC \uD589 \uADF8\uB8F9 \uC124\uC815",
  values: "\uAC12",
  valueColumnsEmptyMessage: "\uC5EC\uAE30\uB85C \uB4DC\uB798\uADF8\uD558\uC5EC \uC9D1\uACC4",
  pivots: "\uC5F4 \uB808\uC774\uBE14",
  pivotColumnsEmptyMessage: "\uC5EC\uAE30\uB85C \uB4DC\uB798\uADF8\uD558\uC5EC \uC5F4 \uB808\uC774\uBE14 \uC124\uC815",
  // Header of the Default Group Column
  group: "\uADF8\uB8F9",
  // Row Drag
  rowDragRow: "\uD589",
  rowDragRows: "\uD589\uB4E4",
  // Other
  loadingOoo: "\uB85C\uB529 \uC911...",
  loadingError: "\uC624\uB958",
  noRowsToShow: "\uD45C\uC2DC\uD560 \uD589\uC774 \uC5C6\uC2B5\uB2C8\uB2E4",
  enabled: "\uC0AC\uC6A9 \uC911",
  // Menu
  pinColumn: "\uC5F4 \uACE0\uC815",
  pinLeft: "\uC67C\uCABD\uC5D0 \uACE0\uC815",
  pinRight: "\uC624\uB978\uCABD\uC5D0 \uACE0\uC815",
  noPin: "\uACE0\uC815 \uC5C6\uC74C",
  valueAggregation: "\uAC12 \uC9D1\uACC4",
  noAggregation: "\uC5C6\uC74C",
  autosizeThisColumn: "\uC774 \uC5F4 \uC790\uB3D9 \uD06C\uAE30 \uC870\uC815",
  autosizeAllColumns: "\uBAA8\uB4E0 \uC5F4 \uC790\uB3D9 \uD06C\uAE30 \uC870\uC815",
  groupBy: "\uADF8\uB8F9\uD654 \uAE30\uC900",
  ungroupBy: "\uADF8\uB8F9 \uD574\uC81C \uAE30\uC900",
  ungroupAll: "\uBAA8\uB4E0 \uADF8\uB8F9 \uD574\uC81C",
  addToValues: "${variable} \uAC12\uC744 \uCD94\uAC00",
  removeFromValues: "${variable} \uAC12\uC744 \uC81C\uAC70",
  addToLabels: "${variable} \uB808\uC774\uBE14\uC5D0 \uCD94\uAC00",
  removeFromLabels: "${variable} \uB808\uC774\uBE14\uC5D0\uC11C \uC81C\uAC70",
  resetColumns: "\uC5F4 \uC7AC\uC124\uC815",
  expandAll: "\uBAA8\uB4E0 \uD589 \uADF8\uB8F9 \uD655\uC7A5",
  collapseAll: "\uBAA8\uB4E0 \uD589 \uADF8\uB8F9 \uB2EB\uAE30",
  copy: "\uBCF5\uC0AC",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\uBA38\uB9AC\uAE00\uACFC \uD568\uAED8 \uBCF5\uC0AC",
  copyWithGroupHeaders: "\uADF8\uB8F9 \uBA38\uB9AC\uAE00\uACFC \uD568\uAED8 \uBCF5\uC0AC",
  cut: "\uC798\uB77C\uB0B4\uAE30",
  paste: "\uBD99\uC5EC\uB123\uAE30",
  ctrlV: "Ctrl+V",
  export: "\uB0B4\uBCF4\uB0B4\uAE30",
  csvExport: "CSV \uB0B4\uBCF4\uB0B4\uAE30",
  excelExport: "Excel \uB0B4\uBCF4\uB0B4\uAE30",
  columnFilter: "\uC5F4 \uD544\uD130",
  columnChooser: "\uC5F4 \uC120\uD0DD",
  chooseColumns: "\uC5F4 \uC120\uD0DD",
  sortAscending: "\uC624\uB984\uCC28\uC21C \uC815\uB82C",
  sortDescending: "\uB0B4\uB9BC\uCC28\uC21C \uC815\uB82C",
  sortUnSort: "\uC815\uB82C \uD574\uC81C",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\uD569\uACC4",
  first: "\uCCAB \uBC88\uC9F8",
  last: "\uB9C8\uC9C0\uB9C9",
  min: "\uCD5C\uC18C",
  max: "\uCD5C\uB300",
  none: "\uC5C6\uC74C",
  count: "\uAC1C\uC218",
  avg: "\uD3C9\uADE0",
  filteredRows: "\uD544\uD130\uB41C \uD589",
  selectedRows: "\uC120\uD0DD\uB41C \uD589",
  totalRows: "\uCD1D \uD589 \uC218",
  totalAndFilteredRows: "\uD589",
  more: "\uB354 \uBCF4\uAE30",
  to: "\uC5D0\uC11C",
  of: "\uC758",
  page: "\uD398\uC774\uC9C0",
  pageLastRowUnknown: "?",
  nextPage: "\uB2E4\uC74C \uD398\uC774\uC9C0",
  lastPage: "\uB9C8\uC9C0\uB9C9 \uD398\uC774\uC9C0",
  firstPage: "\uCCAB \uD398\uC774\uC9C0",
  previousPage: "\uC774\uC804 \uD398\uC774\uC9C0",
  pageSizeSelectorLabel: "\uD398\uC774\uC9C0 \uD06C\uAE30:",
  footerTotal: "\uCD1D\uACC4",
  // Pivoting
  pivotColumnGroupTotals: "\uCD1D\uD569\uACC4",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\uD53C\uBC97 \uCC28\uD2B8 & \uD53C\uBC97 \uBAA8\uB4DC",
  pivotChart: "\uD53C\uBC97 \uCC28\uD2B8",
  chartRange: "\uCC28\uD2B8 \uBC94\uC704",
  columnChart: "\uCEEC\uB7FC",
  groupedColumn: "\uADF8\uB8F9\uD654",
  stackedColumn: "\uC2A4\uD0DD",
  normalizedColumn: "100% \uC2A4\uD0DD",
  barChart: "\uB9C9\uB300",
  groupedBar: "\uADF8\uB8F9\uD654",
  stackedBar: "\uC2A4\uD0DD",
  normalizedBar: "100% \uC2A4\uD0DD",
  pieChart: "\uD30C\uC774",
  pie: "\uD30C\uC774",
  donut: "\uB3C4\uB11B",
  line: "\uB77C\uC778",
  xyChart: "X Y (\uC0B0\uC810\uB3C4)",
  scatter: "\uC0B0\uC810\uB3C4",
  bubble: "\uBC84\uBE14",
  areaChart: "\uC601\uC5ED",
  area: "\uC601\uC5ED",
  stackedArea: "\uC2A4\uD0DD \uC601\uC5ED",
  normalizedArea: "100% \uC2A4\uD0DD \uC601\uC5ED",
  histogramChart: "\uD788\uC2A4\uD1A0\uADF8\uB7A8",
  polarChart: "\uD3F4\uB77C",
  radarLine: "\uB808\uC774\uB354 \uB77C\uC778",
  radarArea: "\uB808\uC774\uB354 \uC601\uC5ED",
  nightingale: "\uB098\uC774\uD305\uAC8C\uC77C",
  radialColumn: "\uBC29\uC0AC\uD615 \uCEEC\uB7FC",
  radialBar: "\uBC29\uC0AC\uD615 \uB9C9\uB300",
  statisticalChart: "\uD1B5\uACC4",
  boxPlot: "\uC0C1\uC790 \uADF8\uB9BC",
  rangeBar: "\uBC94\uC704 \uB9C9\uB300",
  rangeArea: "\uBC94\uC704 \uC601\uC5ED",
  hierarchicalChart: "\uACC4\uCE35\uC801",
  treemap: "\uD2B8\uB9AC\uB9F5",
  sunburst: "\uC120\uBC84\uC2A4\uD2B8",
  specializedChart: "\uD2B9\uC218",
  waterfall: "\uD3ED\uD3EC",
  heatmap: "\uD788\uD2B8\uB9F5",
  combinationChart: "\uBCF5\uD569",
  columnLineCombo: "\uCEEC\uB7FC & \uB77C\uC778",
  AreaColumnCombo: "\uC601\uC5ED & \uCEEC\uB7FC",
  // Charts
  pivotChartTitle: "\uD53C\uBC97 \uCC28\uD2B8",
  rangeChartTitle: "\uBC94\uC704 \uCC28\uD2B8",
  settings: "\uCC28\uD2B8",
  data: "\uC124\uC815",
  format: "\uB9DE\uCDA4 \uC124\uC815",
  categories: "\uCE74\uD14C\uACE0\uB9AC",
  defaultCategory: "(\uC5C6\uC74C)",
  series: "\uC2DC\uB9AC\uC988",
  switchCategorySeries: "\uCE74\uD14C\uACE0\uB9AC / \uC2DC\uB9AC\uC988 \uC804\uD658",
  categoryValues: "\uCE74\uD14C\uACE0\uB9AC \uAC12",
  seriesLabels: "\uC2DC\uB9AC\uC988 \uB77C\uBCA8",
  aggregate: "\uD569\uC0B0",
  xyValues: "X Y \uAC12\uB4E4",
  paired: "\uC30D \uBAA8\uB4DC",
  axis: "\uCD95",
  xAxis: "\uC218\uD3C9 \uCD95",
  yAxis: "\uC218\uC9C1 \uCD95",
  polarAxis: "\uD3F4\uB77C \uCD95",
  radiusAxis: "\uBC18\uACBD \uCD95",
  navigator: "\uB124\uBE44\uAC8C\uC774\uD130",
  zoom: "\uC90C",
  animation: "\uC560\uB2C8\uBA54\uC774\uC158",
  crosshair: "\uD06C\uB85C\uC2A4\uD5E4\uC5B4",
  color: "\uC0C9\uC0C1",
  thickness: "\uB450\uAED8",
  preferredLength: "\uC120\uD638 \uAE38\uC774",
  xType: "X \uC720\uD615",
  axisType: "\uCD95 \uC720\uD615",
  automatic: "\uC790\uB3D9",
  category: "\uCE74\uD14C\uACE0\uB9AC",
  number: "\uC22B\uC790",
  time: "\uC2DC\uAC04",
  timeFormat: "\uC2DC\uAC04 \uD615\uC2DD",
  autoRotate: "\uC790\uB3D9 \uD68C\uC804",
  labelRotation: "\uD68C\uC804",
  circle: "\uC6D0",
  polygon: "\uB2E4\uAC01\uD615",
  square: "\uC0AC\uAC01\uD615",
  cross: "\uC2ED\uC790",
  diamond: "\uB2E4\uC774\uC544\uBAAC\uB4DC",
  plus: "\uD50C\uB7EC\uC2A4",
  triangle: "\uC0BC\uAC01\uD615",
  heart: "\uD558\uD2B8",
  orientation: "\uBC29\uD5A5",
  fixed: "\uACE0\uC815",
  parallel: "\uBCD1\uB82C",
  perpendicular: "\uC218\uC9C1",
  radiusAxisPosition: "\uC704\uCE58",
  ticks: "\uD2F1",
  gridLines: "\uACA9\uC790 \uB77C\uC778",
  width: "\uB108\uBE44",
  height: "\uB192\uC774",
  length: "\uAE38\uC774",
  padding: "\uD328\uB529",
  spacing: "\uAC04\uACA9",
  chartStyle: "\uCC28\uD2B8 \uC2A4\uD0C0\uC77C",
  title: "\uC81C\uBAA9",
  chartTitles: "\uC81C\uBAA9\uB4E4",
  chartTitle: "\uCC28\uD2B8 \uC81C\uBAA9",
  chartSubtitle: "\uBD80\uC81C\uBAA9",
  horizontalAxisTitle: "\uC218\uD3C9 \uCD95 \uC81C\uBAA9",
  verticalAxisTitle: "\uC218\uC9C1 \uCD95 \uC81C\uBAA9",
  polarAxisTitle: "\uD3F4\uB77C \uCD95 \uC81C\uBAA9",
  titlePlaceholder: "\uCC28\uD2B8 \uC81C\uBAA9",
  background: "\uBC30\uACBD",
  font: "\uAE00\uAF34",
  weight: "\uAD75\uAE30",
  top: "\uC704",
  right: "\uC624\uB978\uCABD",
  bottom: "\uC544\uB798",
  left: "\uC67C\uCABD",
  labels: "\uB77C\uBCA8",
  calloutLabels: "\uCF5C\uC544\uC6C3 \uB77C\uBCA8",
  sectorLabels: "\uC139\uD130 \uB77C\uBCA8",
  positionRatio: "\uC704\uCE58 \uBE44\uC728",
  size: "\uD06C\uAE30",
  shape: "\uD615\uD0DC",
  minSize: "\uCD5C\uC18C \uD06C\uAE30",
  maxSize: "\uCD5C\uB300 \uD06C\uAE30",
  legend: "\uBC94\uB840",
  position: "\uC704\uCE58",
  markerSize: "\uB9C8\uCEE4 \uD06C\uAE30",
  markerStroke: "\uB9C8\uCEE4 \uC2A4\uD2B8\uB85C\uD06C",
  markerPadding: "\uB9C8\uCEE4 \uD328\uB529",
  itemSpacing: "\uC544\uC774\uD15C \uAC04\uACA9",
  itemPaddingX: "\uC544\uC774\uD15C \uD328\uB529 X",
  itemPaddingY: "\uC544\uC774\uD15C \uD328\uB529 Y",
  layoutHorizontalSpacing: "\uC218\uD3C9 \uAC04\uACA9",
  layoutVerticalSpacing: "\uC218\uC9C1 \uAC04\uACA9",
  strokeWidth: "\uC2A4\uD2B8\uB85C\uD06C \uB450\uAED8",
  offset: "\uC624\uD504\uC14B",
  offsets: "\uC624\uD504\uC14B\uB4E4",
  tooltips: "\uD234\uD301",
  callout: "\uCF5C\uC544\uC6C3",
  markers: "\uB9C8\uCEE4",
  shadow: "\uADF8\uB9BC\uC790",
  blur: "\uBE14\uB7EC",
  xOffset: "X \uC624\uD504\uC14B",
  yOffset: "Y \uC624\uD504\uC14B",
  lineWidth: "\uB77C\uC778 \uB108\uBE44",
  lineDash: "\uB77C\uC778 \uB300\uC2DC",
  lineDashOffset: "\uB300\uC2DC \uC624\uD504\uC14B",
  scrollingZoom: "\uC2A4\uD06C\uB864",
  scrollingStep: "\uC2A4\uD06C\uB864 \uC2A4\uD15D",
  selectingZoom: "\uC120\uD0DD",
  durationMillis: "\uC9C0\uC18D \uC2DC\uAC04 (ms)",
  crosshairLabel: "\uB77C\uBCA8",
  crosshairSnap: "\uB178\uB4DC\uC5D0 \uC2A4\uB0C5",
  normal: "\uBCF4\uD1B5",
  bold: "\uAD75\uAC8C",
  italic: "\uAE30\uC6B8\uC784\uAF34",
  boldItalic: "\uAD75\uAC8C \uAE30\uC6B8\uC784\uAF34",
  predefined: "\uC0AC\uC804 \uC815\uC758\uB41C",
  fillOpacity: "\uCC44\uC6B0\uAE30 \uBD88\uD22C\uBA85\uB3C4",
  strokeColor: "\uB77C\uC778 \uC0C9\uC0C1",
  strokeOpacity: "\uB77C\uC778 \uBD88\uD22C\uBA85\uB3C4",
  miniChart: "\uBBF8\uB2C8 \uCC28\uD2B8",
  histogramBinCount: "\uBE48 \uC218",
  connectorLine: "\uC5F0\uACB0 \uB77C\uC778",
  seriesItems: "\uC2DC\uB9AC\uC988 \uD56D\uBAA9",
  seriesItemType: "\uD56D\uBAA9 \uC720\uD615",
  seriesItemPositive: "\uC591\uC218",
  seriesItemNegative: "\uC74C\uC218",
  seriesItemLabels: "\uD56D\uBAA9 \uB77C\uBCA8",
  columnGroup: "\uC5F4",
  barGroup: "\uB9C9\uB300",
  pieGroup: "\uD30C\uC774",
  lineGroup: "\uB77C\uC778",
  scatterGroup: "X Y (\uC0B0\uC810\uB3C4)",
  areaGroup: "\uBA74\uC801",
  polarGroup: "\uD3F4\uB77C",
  statisticalGroup: "\uD1B5\uACC4",
  hierarchicalGroup: "\uACC4\uCE35",
  specializedGroup: "\uC804\uBB38",
  combinationGroup: "\uACB0\uD569",
  groupedColumnTooltip: "\uADF8\uB8F9\uD654",
  stackedColumnTooltip: "\uC2A4\uD0DD",
  normalizedColumnTooltip: "100% \uC2A4\uD0DD",
  groupedBarTooltip: "\uADF8\uB8F9\uD654",
  stackedBarTooltip: "\uC2A4\uD0DD",
  normalizedBarTooltip: "100% \uC2A4\uD0DD",
  pieTooltip: "\uD30C\uC774",
  donutTooltip: "\uB3C4\uB11B",
  lineTooltip: "\uB77C\uC778",
  groupedAreaTooltip: "\uBA74\uC801",
  stackedAreaTooltip: "\uC2A4\uD0DD",
  normalizedAreaTooltip: "100% \uC2A4\uD0DD",
  scatterTooltip: "\uC0B0\uC810\uB3C4",
  bubbleTooltip: "\uBC84\uBE14",
  histogramTooltip: "\uD788\uC2A4\uD1A0\uADF8\uB7A8",
  radialColumnTooltip: "\uBC18\uACBD \uC5F4",
  radialBarTooltip: "\uBC18\uACBD \uB9C9\uB300",
  radarLineTooltip: "\uB808\uC774\uB354 \uB77C\uC778",
  radarAreaTooltip: "\uB808\uC774\uB354 \uBA74\uC801",
  nightingaleTooltip: "\uB098\uC774\uD305\uAC8C\uC77C",
  rangeBarTooltip: "\uBC94\uC704 \uB9C9\uB300",
  rangeAreaTooltip: "\uBC94\uC704 \uBA74\uC801",
  boxPlotTooltip: "\uBC15\uC2A4 \uD50C\uB86F",
  treemapTooltip: "\uD2B8\uB9AC\uB9F5",
  sunburstTooltip: "\uC120\uBC84\uC2A4\uD2B8",
  waterfallTooltip: "\uD3ED\uD3EC",
  heatmapTooltip: "\uD788\uD2B8\uB9F5",
  columnLineComboTooltip: "\uC5F4 & \uB77C\uC778",
  areaColumnComboTooltip: "\uBA74\uC801 & \uC5F4",
  customComboTooltip: "\uC0AC\uC6A9\uC790 \uC815\uC758 \uACB0\uD569",
  innerRadius: "\uB0B4\uBD80 \uBC18\uACBD",
  startAngle: "\uC2DC\uC791 \uAC01\uB3C4",
  endAngle: "\uB05D \uAC01\uB3C4",
  reverseDirection: "\uBC18\uB300 \uBC29\uD5A5",
  groupPadding: "\uADF8\uB8F9 \uD328\uB529",
  seriesPadding: "\uC2DC\uB9AC\uC988 \uD328\uB529",
  tile: "\uD0C0\uC77C",
  whisker: "\uC218\uC5FC",
  cap: "\uBAA8\uC790",
  capLengthRatio: "\uAE38\uC774 \uBE44\uC728",
  labelPlacement: "\uB77C\uBCA8 \uBC30\uCE58",
  inside: "\uB0B4\uBD80",
  outside: "\uC678\uBD80",
  noDataToChart: "\uCC28\uD2B8\uD654 \uD560 \uC218 \uC788\uB294 \uB370\uC774\uD130\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.",
  pivotChartRequiresPivotMode: "\uD53C\uBC97 \uCC28\uD2B8\uB294 \uD53C\uBC97 \uBAA8\uB4DC\uAC00 \uD65C\uC131\uD654 \uB418\uC5B4\uC57C \uD569\uB2C8\uB2E4.",
  chartSettingsToolbarTooltip: "\uBA54\uB274",
  chartLinkToolbarTooltip: "\uADF8\uB9AC\uB4DC\uC5D0 \uB9C1\uD06C\uB428",
  chartUnlinkToolbarTooltip: "\uADF8\uB9AC\uB4DC\uC5D0\uC11C \uB9C1\uD06C \uD574\uC81C\uB428",
  chartDownloadToolbarTooltip: "\uCC28\uD2B8 \uB2E4\uC6B4\uB85C\uB4DC",
  chartMenuToolbarTooltip: "\uBA54\uB274",
  chartEdit: "\uCC28\uD2B8 \uD3B8\uC9D1",
  chartAdvancedSettings: "\uACE0\uAE09 \uC124\uC815",
  chartLink: "\uADF8\uB9AC\uB4DC\uC5D0 \uB9C1\uD06C",
  chartUnlink: "\uADF8\uB9AC\uB4DC\uC5D0\uC11C \uB9C1\uD06C \uD574\uC81C",
  chartDownload: "\uCC28\uD2B8 \uB2E4\uC6B4\uB85C\uB4DC",
  histogramFrequency: "\uBE48\uB3C4",
  seriesChartType: "\uC2DC\uB9AC\uC988 \uCC28\uD2B8 \uC720\uD615",
  seriesType: "\uC2DC\uB9AC\uC988 \uC720\uD615",
  secondaryAxis: "\uBCF4\uC870 \uCD95",
  seriesAdd: "\uC2DC\uB9AC\uC988 \uCD94\uAC00",
  categoryAdd: "\uCE74\uD14C\uACE0\uB9AC \uCD94\uAC00",
  bar: "\uB9C9\uB300",
  column: "\uC5F4",
  histogram: "\uD788\uC2A4\uD1A0\uADF8\uB7A8",
  advancedSettings: "\uACE0\uAE09 \uC124\uC815",
  direction: "\uBC29\uD5A5",
  horizontal: "\uC218\uD3C9",
  vertical: "\uC218\uC9C1",
  seriesGroupType: "\uADF8\uB8F9 \uC720\uD615",
  groupedSeriesGroupType: "\uADF8\uB8F9\uD654",
  stackedSeriesGroupType: "\uC2A4\uD0DD",
  normalizedSeriesGroupType: "100% \uC2A4\uD0DD",
  legendEnabled: "\uD65C\uC131\uD654\uB428",
  invalidColor: "\uC0C9\uC0C1 \uAC12\uC774 \uC720\uD6A8\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4",
  groupedColumnFull: "\uADF8\uB8F9\uD654\uB41C \uC5F4",
  stackedColumnFull: "\uC2A4\uD0DD\uB41C \uC5F4",
  normalizedColumnFull: "100% \uC2A4\uD0DD\uB41C \uC5F4",
  groupedBarFull: "\uADF8\uB8F9\uD654\uB41C \uB9C9\uB300",
  stackedBarFull: "\uC2A4\uD0DD\uB41C \uB9C9\uB300",
  normalizedBarFull: "100% \uC2A4\uD0DD\uB41C \uB9C9\uB300",
  stackedAreaFull: "\uC2A4\uD0DD\uB41C \uBA74\uC801",
  normalizedAreaFull: "100% \uC2A4\uD0DD\uB41C \uBA74\uC801",
  customCombo: "\uC0AC\uC6A9\uC790 \uC815\uC758 \uACB0\uD569",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \uB808\uBCA8 ${variable}. \uD3B8\uC9D1\uD558\uB824\uBA74 ENTER\uB97C \uB204\uB974\uC138\uC694",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \uB808\uBCA8 ${variable}. ${variable} \uD3B8\uC9D1\uD558\uB824\uBA74 ENTER\uB97C \uB204\uB974\uC138\uC694",
  ariaAdvancedFilterBuilderList: "\uACE0\uAE09 \uD544\uD130 \uBE4C\uB354 \uBAA9\uB85D",
  ariaAdvancedFilterBuilderFilterItem: "\uD544\uD130 \uC870\uAC74",
  ariaAdvancedFilterBuilderGroupItem: "\uD544\uD130 \uADF8\uB8F9",
  ariaAdvancedFilterBuilderColumn: "\uC5F4",
  ariaAdvancedFilterBuilderOption: "\uC635\uC158",
  ariaAdvancedFilterBuilderValueP: "\uAC12",
  ariaAdvancedFilterBuilderJoinOperator: "\uC870\uD569 \uC5F0\uC0B0\uC790",
  ariaAdvancedFilterInput: "\uACE0\uAE09 \uD544\uD130 \uC785\uB825",
  ariaChecked: "\uC120\uD0DD\uB428",
  ariaColumn: "\uC5F4",
  ariaColumnGroup: "\uC5F4 \uADF8\uB8F9",
  ariaColumnFiltered: "\uC5F4 \uD544\uD130\uB9C1\uB428",
  ariaColumnSelectAll: "\uBAA8\uB4E0 \uC5F4 \uC120\uD0DD \uC804\uD658",
  ariaDateFilterInput: "\uB0A0\uC9DC \uD544\uD130 \uC785\uB825",
  ariaDefaultListName: "\uBAA9\uB85D",
  ariaFilterColumnsInput: "\uC5F4 \uD544\uD130 \uC785\uB825",
  ariaFilterFromValue: "\uAC12\uC5D0\uC11C \uD544\uD130",
  ariaFilterInput: "\uD544\uD130 \uC785\uB825",
  ariaFilterList: "\uD544\uD130 \uBAA9\uB85D",
  ariaFilterToValue: "\uAC12\uAE4C\uC9C0 \uD544\uD130",
  ariaFilterValue: "\uD544\uD130 \uAC12",
  ariaFilterMenuOpen: "\uD544\uD130 \uBA54\uB274 \uC5F4\uAE30",
  ariaFilteringOperator: "\uD544\uD130\uB9C1 \uC5F0\uC0B0\uC790",
  ariaHidden: "\uC228\uAE40",
  ariaIndeterminate: "\uBD88\uD655\uC815",
  ariaInputEditor: "\uC785\uB825 \uC5D0\uB514\uD130",
  ariaMenuColumn: "\uC5F4 \uBA54\uB274\uB97C \uC5F4\uB824\uBA74 ALT \uC544\uB798\uB97C \uB204\uB974\uC138\uC694",
  ariaFilterColumn: "\uD544\uD130\uB97C \uC5F4\uB824\uBA74 CTRL ENTER\uB97C \uB204\uB974\uC138\uC694",
  ariaRowDeselect: "\uC774 \uD589\uC744 \uC120\uD0DD \uD574\uC81C\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaRowSelectAll: "\uBAA8\uB4E0 \uD589 \uC120\uD0DD \uC804\uD658\uC744 \uC704\uD574 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaRowToggleSelection: "\uD589 \uC120\uD0DD\uC744 \uC804\uD658\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaRowSelect: "\uC774 \uD589\uC744 \uC120\uD0DD\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaRowSelectionDisabled: "\uC774 \uD589\uC758 \uD589 \uC120\uD0DD\uC774 \uBE44\uD65C\uC131\uD654\uB418\uC5C8\uC2B5\uB2C8\uB2E4",
  ariaSearch: "\uAC80\uC0C9",
  ariaSortableColumn: "\uC815\uB82C\uD558\uB824\uBA74 ENTER\uB97C \uB204\uB974\uC138\uC694",
  ariaToggleVisibility: "\uAC00\uC2DC\uC131\uC744 \uC804\uD658\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaToggleCellValue: "\uC140 \uAC12\uC744 \uC804\uD658\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaUnchecked: "\uC120\uD0DD \uD574\uC81C\uB428",
  ariaVisible: "\uBCF4\uC774\uB294",
  ariaSearchFilterValues: "\uD544\uD130 \uAC12 \uAC80\uC0C9",
  ariaPageSizeSelectorLabel: "\uD398\uC774\uC9C0 \uD06C\uAE30",
  ariaChartMenuClose: "\uCC28\uD2B8 \uD3B8\uC9D1 \uBA54\uB274 \uB2EB\uAE30",
  ariaChartSelected: "\uC120\uD0DD\uB428",
  ariaSkeletonCellLoadingFailed: "\uD589 \uB85C\uB4DC \uC2E4\uD328",
  ariaSkeletonCellLoading: "\uD589 \uB370\uC774\uD130 \uB85C\uB4DC \uC911",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\uD589 \uADF8\uB8F9",
  ariaValuesDropZonePanelLabel: "\uAC12",
  ariaPivotDropZonePanelLabel: "\uC5F4 \uB808\uC774\uBE14",
  ariaDropZoneColumnComponentDescription: "\uC81C\uAC70\uD558\uB824\uBA74 DELETE\uB97C \uB204\uB974\uC138\uC694",
  ariaDropZoneColumnValueItemDescription: "\uC9D1\uACC4 \uC720\uD615\uC744 \uBCC0\uACBD\uD558\uB824\uBA74 ENTER\uB97C \uB204\uB974\uC138\uC694",
  ariaDropZoneColumnGroupItemDescription: "\uC815\uB82C\uD558\uB824\uBA74 ENTER\uB97C \uB204\uB974\uC138\uC694",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \uC758 ",
  ariaDropZoneColumnComponentSortAscending: "\uC624\uB984\uCC28\uC21C",
  ariaDropZoneColumnComponentSortDescending: "\uB0B4\uB9BC\uCC28\uC21C",
  ariaLabelDialog: "\uB300\uD654\uC0C1\uC790",
  ariaLabelColumnMenu: "\uCEEC\uB7FC \uBA54\uB274",
  ariaLabelColumnFilter: "\uCEEC\uB7FC \uD544\uD130",
  ariaLabelCellEditor: "\uC140 \uD3B8\uC9D1\uAE30",
  ariaLabelSelectField: "\uD544\uB4DC \uC120\uD0DD",
  // aria labels for rich select
  ariaLabelRichSelectField: "\uB9AC\uCE58 \uC120\uD0DD \uD544\uB4DC",
  ariaLabelRichSelectToggleSelection: "\uC120\uD0DD\uC744 \uD1A0\uAE00\uD558\uB824\uBA74 SPACE\uB97C \uB204\uB974\uC138\uC694",
  ariaLabelRichSelectDeselectAllItems: "\uBAA8\uB4E0 \uD56D\uBAA9 \uC120\uD0DD \uD574\uC81C\uB97C \uC704\uD574 DELETE\uB97C \uB204\uB974\uC138\uC694",
  ariaLabelRichSelectDeleteSelection: "\uD56D\uBAA9 \uC120\uD0DD \uD574\uC81C\uB97C \uC704\uD574 DELETE\uB97C \uB204\uB974\uC138\uC694",
  ariaLabelTooltip: "\uD234\uD301",
  ariaLabelContextMenu: "\uCEE8\uD14D\uC2A4\uD2B8 \uBA54\uB274",
  ariaLabelSubMenu: "\uD558\uC704 \uBA54\uB274",
  ariaLabelAggregationFunction: "\uC9D1\uACC4 \uD568\uC218",
  ariaLabelAdvancedFilterAutocomplete: "\uACE0\uAE09 \uD544\uD130 \uC790\uB3D9 \uC644\uC131",
  ariaLabelAdvancedFilterBuilderAddField: "\uACE0\uAE09 \uD544\uD130 \uBE4C\uB354 \uD544\uB4DC \uCD94\uAC00",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\uACE0\uAE09 \uD544\uD130 \uBE4C\uB354 \uCE7C\uB7FC \uC120\uD0DD \uD544\uB4DC",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\uACE0\uAE09 \uD544\uD130 \uBE4C\uB354 \uC635\uC158 \uC120\uD0DD \uD544\uB4DC",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\uACE0\uAE09 \uD544\uD130 \uBE4C\uB354 \uC870\uC778 \uC5F0\uC0B0\uC790 \uC120\uD0DD \uD544\uB4DC",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\uC5F4 \uBAA9\uB85D",
  ariaFilterPanelList: "\uD544\uD130 \uBAA9\uB85D",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "True",
  false: "False",
  invalidDate: "\uC720\uD6A8\uD558\uC9C0 \uC54A\uC740 \uB0A0\uC9DC",
  invalidNumber: "\uC720\uD6A8\uD558\uC9C0 \uC54A\uC740 \uC22B\uC790",
  january: "1\uC6D4",
  february: "2\uC6D4",
  march: "3\uC6D4",
  april: "4\uC6D4",
  may: "5\uC6D4",
  june: "6\uC6D4",
  july: "7\uC6D4",
  august: "8\uC6D4",
  september: "9\uC6D4",
  october: "10\uC6D4",
  november: "11\uC6D4",
  december: "12\uC6D4",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.MM.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS \uC624\uC804/\uC624\uD6C4"
};

// community-modules/locale/src/nb-NO.ts
var AG_GRID_LOCALE_NO = {
  // Set Filter
  selectAll: "(Velg alle)",
  selectAllSearchResults: "(Velg alle s\xF8keresultater)",
  addCurrentSelectionToFilter: "Legg n\xE5v\xE6rende valg til filter",
  searchOoo: "S\xF8k...",
  blanks: "(Tomme)",
  noMatches: "Ingen treff",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Er lik",
  notEqual: "Er ikke lik",
  blank: "Tom",
  notBlank: "Ikke tom",
  empty: "Velg en",
  // Number Filter
  lessThan: "Mindre enn",
  greaterThan: "St\xF8rre enn",
  lessThanOrEqual: "Mindre enn eller lik",
  greaterThanOrEqual: "St\xF8rre enn eller lik",
  inRange: "Mellom",
  inRangeStart: "Fra",
  inRangeEnd: "Til",
  // Text Filter
  contains: "Inneholder",
  notContains: "Inneholder ikke",
  startsWith: "Begynner med",
  endsWith: "Slutter med",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "F\xF8r",
  after: "Etter",
  // Filter Conditions
  andCondition: "OG",
  orCondition: "ELLER",
  // Filter Buttons
  applyFilter: "Bruk",
  resetFilter: "Tilbakestill",
  clearFilter: "T\xF8m",
  cancelFilter: "Avbryt",
  // Filter Titles
  textFilter: "Tekstfilter",
  numberFilter: "Tallfilter",
  dateFilter: "Dato filter",
  setFilter: "Sett filter",
  // Group Column Filter
  groupFilterSelect: "Velg felt:",
  // Advanced Filter
  advancedFilterContains: "inneholder",
  advancedFilterNotContains: "inneholder ikke",
  advancedFilterTextEquals: "er lik",
  advancedFilterTextNotEqual: "er ikke lik",
  advancedFilterStartsWith: "begynner med",
  advancedFilterEndsWith: "slutter med",
  advancedFilterBlank: "er tom",
  advancedFilterNotBlank: "er ikke tom",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "er sann",
  advancedFilterFalse: "er falsk",
  advancedFilterAnd: "OG",
  advancedFilterOr: "ELLER",
  advancedFilterApply: "Bruk",
  advancedFilterBuilder: "Bygger",
  advancedFilterValidationMissingColumn: "Kolonne mangler",
  advancedFilterValidationMissingOption: "Alternativ mangler",
  advancedFilterValidationMissingValue: "Verdi mangler",
  advancedFilterValidationInvalidColumn: "Kolonne ikke funnet",
  advancedFilterValidationInvalidOption: "Alternativ ikke funnet",
  advancedFilterValidationMissingQuote: "Verdi mangler en avsluttende anf\xF8rselstegn",
  advancedFilterValidationNotANumber: "Verdi er ikke et tall",
  advancedFilterValidationInvalidDate: "Verdi er ikke en gyldig dato",
  advancedFilterValidationMissingCondition: "Betingelse mangler",
  advancedFilterValidationJoinOperatorMismatch: "Sammenkoblingsoperatorer innen en betingelse m\xE5 v\xE6re de samme",
  advancedFilterValidationInvalidJoinOperator: "Sammenkoblingsoperator ikke funnet",
  advancedFilterValidationMissingEndBracket: "Mangler avsluttende parentes",
  advancedFilterValidationExtraEndBracket: "For mange avsluttende parenteser",
  advancedFilterValidationMessage: "Uttrykket har en feil. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Uttrykket har en feil. ${variable} p\xE5 slutten av uttrykket.",
  advancedFilterBuilderTitle: "Avansert Filter",
  advancedFilterBuilderApply: "Bruk",
  advancedFilterBuilderCancel: "Avbryt",
  advancedFilterBuilderAddButtonTooltip: "Legg til filter eller gruppe",
  advancedFilterBuilderRemoveButtonTooltip: "Fjern",
  advancedFilterBuilderMoveUpButtonTooltip: "Flytt opp",
  advancedFilterBuilderMoveDownButtonTooltip: "Flytt ned",
  advancedFilterBuilderAddJoin: "Legg til gruppe",
  advancedFilterBuilderAddCondition: "Legg til filter",
  advancedFilterBuilderSelectColumn: "Velg en kolonne",
  advancedFilterBuilderSelectOption: "Velg et alternativ",
  advancedFilterBuilderEnterValue: "Skriv inn en verdi...",
  advancedFilterBuilderValidationAlreadyApplied: "Gjeldende filter allerede brukt.",
  advancedFilterBuilderValidationIncomplete: "Ikke alle betingelser er komplette.",
  advancedFilterBuilderValidationSelectColumn: "M\xE5 velge en kolonne.",
  advancedFilterBuilderValidationSelectOption: "M\xE5 velge et alternativ.",
  advancedFilterBuilderValidationEnterValue: "M\xE5 skrive inn en verdi.",
  // Side Bar
  columns: "Kolonner",
  filters: "Filtre",
  // columns tool panel
  pivotMode: "Pivot modus",
  groups: "Radgrupper",
  rowGroupColumnsEmptyMessage: "Dra hit for \xE5 sette radgrupper",
  values: "Verdier",
  valueColumnsEmptyMessage: "Dra hit for \xE5 aggregere",
  pivots: "Kolonneetiketter",
  pivotColumnsEmptyMessage: "Dra hit for \xE5 sette kolonneetiketter",
  // Header of the Default Group Column
  group: "Gruppe",
  // Row Drag
  rowDragRow: "rad",
  rowDragRows: "rader",
  // Other
  loadingOoo: "Laster...",
  loadingError: "FEIL",
  noRowsToShow: "Ingen rader \xE5 vise",
  enabled: "Aktivert",
  // Menu
  pinColumn: "Fest Kolonne",
  pinLeft: "Fest til Venstre",
  pinRight: "Fest til H\xF8yre",
  noPin: "Ingen Festing",
  valueAggregation: "Verdi Aggregering",
  noAggregation: "Ingen",
  autosizeThisColumn: "Tilpass Denne Kolonnen",
  autosizeAllColumns: "Tilpass Alle Kolonner",
  groupBy: "Grupper Etter",
  ungroupBy: "Fjern Gruppering Etter",
  ungroupAll: "Fjern All Gruppering",
  addToValues: "Legg til ${variable} til verdier",
  removeFromValues: "Fjern ${variable} fra verdier",
  addToLabels: "Legg til ${variable} til etiketter",
  removeFromLabels: "Fjern ${variable} fra etiketter",
  resetColumns: "Tilbakestill Kolonner",
  expandAll: "Utvid Alle Rad Grupper",
  collapseAll: "Lukk Alle Rad Grupper",
  copy: "Kopier",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopier med Overskrifter",
  copyWithGroupHeaders: "Kopier med Gruppeoverskrifter",
  cut: "Klipp",
  paste: "Lim inn",
  ctrlV: "Ctrl+V",
  export: "Eksporter",
  csvExport: "CSV Eksport",
  excelExport: "Excel Eksport",
  columnFilter: "Kolonnefilter",
  columnChooser: "Velg Kolonner",
  chooseColumns: "Velg Kolonner",
  sortAscending: "Sorter Stigende",
  sortDescending: "Sorter Synkende",
  sortUnSort: "Fjern Sortering",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Sum",
  first: "F\xF8rste",
  last: "Siste",
  min: "Min",
  max: "Maks",
  none: "Ingen",
  count: "Antall",
  avg: "Gjennomsnitt",
  filteredRows: "Filtrert",
  selectedRows: "Valgt",
  totalRows: "Totale rader",
  totalAndFilteredRows: "Rader",
  more: "Mer",
  to: "til",
  of: "av",
  page: "Side",
  pageLastRowUnknown: "?",
  nextPage: "Neste side",
  lastPage: "Siste side",
  firstPage: "F\xF8rste side",
  previousPage: "Forrige side",
  pageSizeSelectorLabel: "Sidest\xF8rrelse:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivotskjema og pivotmodus",
  pivotChart: "Pivotskjema",
  chartRange: "Skjemautvalg",
  columnChart: "Kolonne",
  groupedColumn: "Gruppert",
  stackedColumn: "Stablet",
  normalizedColumn: "100% stablet",
  barChart: "S\xF8yle",
  groupedBar: "Gruppert",
  stackedBar: "Stablet",
  normalizedBar: "100% stablet",
  pieChart: "Kakestykke",
  pie: "Kakestykke",
  donut: "Smultring",
  line: "Linje",
  xyChart: "X Y (Spredning)",
  scatter: "Spredning",
  bubble: "Boble",
  areaChart: "Omr\xE5de",
  area: "Omr\xE5de",
  stackedArea: "Stablet",
  normalizedArea: "100% stablet",
  histogramChart: "Histogram",
  polarChart: "Polar",
  radarLine: "Radarlinje",
  radarArea: "Radaromr\xE5de",
  nightingale: "Nightingale",
  radialColumn: "Radial kolonne",
  radialBar: "Radial s\xF8yle",
  statisticalChart: "Statistisk",
  boxPlot: "Boksdiagram",
  rangeBar: "Rekke s\xF8yle",
  rangeArea: "Rekke omr\xE5de",
  hierarchicalChart: "Hierarkisk",
  treemap: "Treemap",
  sunburst: "Solstr\xE5le",
  specializedChart: "Spesialisert",
  waterfall: "Vannfall",
  heatmap: "Varmekart",
  combinationChart: "Kombinasjon",
  columnLineCombo: "Kolonne og linje",
  AreaColumnCombo: "Omr\xE5de og kolonne",
  // Charts
  pivotChartTitle: "Pivoteringsdiagram",
  rangeChartTitle: "Omr\xE5dekart",
  settings: "Diagram",
  data: "Oppsett",
  format: "Tilpass",
  categories: "Kategorier",
  defaultCategory: "(Ingen)",
  series: "Serier",
  switchCategorySeries: "Bytt kategori / serie",
  categoryValues: "Kategoriverdier",
  seriesLabels: "Seriemerker",
  aggregate: "Aggreger",
  xyValues: "X Y Verdier",
  paired: "Parret modus",
  axis: "Akse",
  xAxis: "Horisontal akse",
  yAxis: "Vertikal akse",
  polarAxis: "Polar akse",
  radiusAxis: "Radius akse",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animasjon",
  crosshair: "H\xE5rkors",
  color: "Farge",
  thickness: "Tykkelse",
  preferredLength: "Foretrukket lengde",
  xType: "X Type",
  axisType: "Aksetype",
  automatic: "Automatisk",
  category: "Kategori",
  number: "Nummer",
  time: "Tid",
  timeFormat: "Tidsformat",
  autoRotate: "Auto rot\xE9r",
  labelRotation: "Rotasjon",
  circle: "Sirkel",
  polygon: "Polygon",
  square: "Firkant",
  cross: "Kryss",
  diamond: "Diamant",
  plus: "Pluss",
  triangle: "Trekant",
  heart: "Hjerte",
  orientation: "Orientering",
  fixed: "Fast",
  parallel: "Parallell",
  perpendicular: "Vinkelrett",
  radiusAxisPosition: "Posisjon",
  ticks: "Merker",
  gridLines: "Rutenett",
  width: "Bredde",
  height: "H\xF8yde",
  length: "Lengde",
  padding: "Polstring",
  spacing: "Avstand",
  chartStyle: "Diagramstil",
  title: "Tittel",
  chartTitles: "Titler",
  chartTitle: "Diagramtittel",
  chartSubtitle: "Undertekst",
  horizontalAxisTitle: "Horisontal aksetittel",
  verticalAxisTitle: "Vertikal aksetittel",
  polarAxisTitle: "Polar aksetittel",
  titlePlaceholder: "Diagramtittel",
  background: "Bakgrunn",
  font: "Skrift",
  weight: "Vekt",
  top: "Topp",
  right: "H\xF8yre",
  bottom: "Bunn",
  left: "Venstre",
  labels: "Etiketter",
  calloutLabels: "Utkallingsetiketter",
  sectorLabels: "Sektoretiketter",
  positionRatio: "Posisjonsforhold",
  size: "St\xF8rrelse",
  shape: "Form",
  minSize: "Minimumsst\xF8rrelse",
  maxSize: "Maksimumsst\xF8rrelse",
  legend: "Forklaring",
  position: "Posisjon",
  markerSize: "Mark\xF8rst\xF8rrelse",
  markerStroke: "Mark\xF8rslag",
  markerPadding: "Mark\xF8rpolstring",
  itemSpacing: "Gjenstandsavstand",
  itemPaddingX: "Gjenstand polstring X",
  itemPaddingY: "Gjenstand polstring Y",
  layoutHorizontalSpacing: "Horisontal avstand",
  layoutVerticalSpacing: "Vertikal avstand",
  strokeWidth: "Slagbredde",
  offset: "Forskyvning",
  offsets: "Forskyvninger",
  tooltips: "Verkt\xF8ytips",
  callout: "Utkalling",
  markers: "Mark\xF8rer",
  shadow: "Skygge",
  blur: "Uklart",
  xOffset: "X forskyvning",
  yOffset: "Y forskyvning",
  lineWidth: "Linje bredde",
  lineDash: "Linjem\xF8nster",
  lineDashOffset: "M\xF8nster forskyvning",
  scrollingZoom: "Rulling",
  scrollingStep: "Rulletrinn",
  selectingZoom: "Velger",
  durationMillis: "Varighet (ms)",
  crosshairLabel: "Etikett",
  crosshairSnap: "Fest til node",
  normal: "Normal",
  bold: "Fet",
  italic: "Kursiv",
  boldItalic: "Fet kursiv",
  predefined: "Forh\xE5ndsdefinert",
  fillOpacity: "Fyll opasitet",
  strokeColor: "Linjefarge",
  strokeOpacity: "Linje opasitet",
  miniChart: "Mini-diagram",
  histogramBinCount: "BinkCount",
  connectorLine: "Koblingslinje",
  seriesItems: "Serieobjekter",
  seriesItemType: "Objekttype",
  seriesItemPositive: "Positiv",
  seriesItemNegative: "Negativ",
  seriesItemLabels: "Objektetiketter",
  columnGroup: "Kolonne",
  barGroup: "S\xF8yle",
  pieGroup: "Kake",
  lineGroup: "Linje",
  scatterGroup: "X Y (Punkter)",
  areaGroup: "Omr\xE5de",
  polarGroup: "Polar",
  statisticalGroup: "Statistisk",
  hierarchicalGroup: "Hierarkisk",
  specializedGroup: "Spesialisert",
  combinationGroup: "Kombinasjon",
  groupedColumnTooltip: "Gruppert",
  stackedColumnTooltip: "Stablet",
  normalizedColumnTooltip: "100% Stablet",
  groupedBarTooltip: "Gruppert",
  stackedBarTooltip: "Stablet",
  normalizedBarTooltip: "100% Stablet",
  pieTooltip: "Kake",
  donutTooltip: "Smultring",
  lineTooltip: "Linje",
  groupedAreaTooltip: "Omr\xE5de",
  stackedAreaTooltip: "Stablet",
  normalizedAreaTooltip: "100% Stablet",
  scatterTooltip: "Punkter",
  bubbleTooltip: "Boble",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radiell kolonne",
  radialBarTooltip: "Radiell s\xF8yle",
  radarLineTooltip: "Radard linje",
  radarAreaTooltip: "Radard omr\xE5de",
  nightingaleTooltip: "Nattergal",
  rangeBarTooltip: "Omr\xE5des\xF8yle",
  rangeAreaTooltip: "Omr\xE5de",
  boxPlotTooltip: "Boksplott",
  treemapTooltip: "Trekart",
  sunburstTooltip: "Solglimt",
  waterfallTooltip: "Fossefall",
  heatmapTooltip: "Varmekart",
  columnLineComboTooltip: "Kolonne & Linje",
  areaColumnComboTooltip: "Omr\xE5de & Kolonne",
  customComboTooltip: "Tilpasset kombinert",
  innerRadius: "Indre radius",
  startAngle: "Startvinkel",
  endAngle: "Sluttvinkel",
  reverseDirection: "Omvendt retning",
  groupPadding: "Gruppolstring",
  seriesPadding: "Seriepolstring",
  tile: "Flis",
  whisker: "H\xE5r",
  cap: "Kappe",
  capLengthRatio: "Lengdeforhold",
  labelPlacement: "Plassering",
  inside: "Innsiden",
  outside: "Utsiden",
  noDataToChart: "Ingen data tilgjengelig for \xE5 lage diagram.",
  pivotChartRequiresPivotMode: "Pivoteringsdiagram krever Pivoteringsmodus aktivert.",
  chartSettingsToolbarTooltip: "Meny",
  chartLinkToolbarTooltip: "Knytet til rutenettet",
  chartUnlinkToolbarTooltip: "Frakoblet fra rutenettet",
  chartDownloadToolbarTooltip: "Last ned diagram",
  chartMenuToolbarTooltip: "Meny",
  chartEdit: "Rediger diagram",
  chartAdvancedSettings: "Avanserte innstillinger",
  chartLink: "Link til rutenettet",
  chartUnlink: "Frakoble fra rutenettet",
  chartDownload: "Last ned diagram",
  histogramFrequency: "Frekvens",
  seriesChartType: "Seriediagramtype",
  seriesType: "Serietype",
  secondaryAxis: "Sekund\xE6r akse",
  seriesAdd: "Legg til en serie",
  categoryAdd: "Legg til en kategori",
  bar: "S\xF8yle",
  column: "Kolonne",
  histogram: "Histogram",
  advancedSettings: "Avanserte innstillinger",
  direction: "Retning",
  horizontal: "Horisontal",
  vertical: "Vertikal",
  seriesGroupType: "Gruppetype",
  groupedSeriesGroupType: "Gruppert",
  stackedSeriesGroupType: "Stablet",
  normalizedSeriesGroupType: "100% Stablet",
  legendEnabled: "Aktivert",
  invalidColor: "Fargeverdi er ugyldig",
  groupedColumnFull: "Gruppert kolonne",
  stackedColumnFull: "Stablet kolonne",
  normalizedColumnFull: "100% stablet kolonne",
  groupedBarFull: "Gruppert s\xF8yle",
  stackedBarFull: "Stablet s\xF8yle",
  normalizedBarFull: "100% stablet s\xF8yle",
  stackedAreaFull: "Stablet omr\xE5de",
  normalizedAreaFull: "100% stablet omr\xE5de",
  customCombo: "Tilpasset kombinasjon",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Niv\xE5 ${variable}. Trykk ENTER for \xE5 redigere.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Niv\xE5 ${variable}. ${variable} Trykk ENTER for \xE5 redigere.",
  ariaAdvancedFilterBuilderList: "Avansert filterbyggerliste",
  ariaAdvancedFilterBuilderFilterItem: "Filterbetingelse",
  ariaAdvancedFilterBuilderGroupItem: "Filtergruppe",
  ariaAdvancedFilterBuilderColumn: "Kolonne",
  ariaAdvancedFilterBuilderOption: "Alternativ",
  ariaAdvancedFilterBuilderValueP: "Verdi",
  ariaAdvancedFilterBuilderJoinOperator: "Koblingsoperator",
  ariaAdvancedFilterInput: "Inndata for avansert filter",
  ariaChecked: "avmerket",
  ariaColumn: "Kolonne",
  ariaColumnGroup: "Kolonnegruppe",
  ariaColumnFiltered: "Kolonne filtrert",
  ariaColumnSelectAll: "Aktiver/deaktiver valg av alle kolonner",
  ariaDateFilterInput: "Inndata for datofilter",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "Filtrer kolonner inndata",
  ariaFilterFromValue: "Filtrer fra verdi",
  ariaFilterInput: "Filterinndata",
  ariaFilterList: "Filterliste",
  ariaFilterToValue: "Filtrer til verdi",
  ariaFilterValue: "Filterverdi",
  ariaFilterMenuOpen: "\xC5pne filtermeny",
  ariaFilteringOperator: "Filtreringsoperator",
  ariaHidden: "skjult",
  ariaIndeterminate: "ubestemt",
  ariaInputEditor: "Inndataredigerer",
  ariaMenuColumn: "Trykk ALT NED for \xE5 \xE5pne kolonnemeny",
  ariaFilterColumn: "Trykk CTRL ENTER for \xE5 \xE5pne filter",
  ariaRowDeselect: "Trykk SPACE for \xE5 fjerne markeringen av denne raden",
  ariaRowSelectAll: "Trykk SPACE for \xE5 veksle alle radvalgene",
  ariaRowToggleSelection: "Trykk SPACE for \xE5 veksle radvalg",
  ariaRowSelect: "Trykk SPACE for \xE5 velge denne raden",
  ariaRowSelectionDisabled: "Radvalg er deaktivert for denne raden",
  ariaSearch: "S\xF8k",
  ariaSortableColumn: "Trykk ENTER for \xE5 sortere",
  ariaToggleVisibility: "Trykk SPACE for \xE5 veksle synlighet",
  ariaToggleCellValue: "Trykk SPACE for \xE5 veksle celleverdi",
  ariaUnchecked: "uavmerket",
  ariaVisible: "synlig",
  ariaSearchFilterValues: "S\xF8k filterverdier",
  ariaPageSizeSelectorLabel: "Sidest\xF8rrelse",
  ariaChartMenuClose: "Lukk diagramredigeringsmeny",
  ariaChartSelected: "Valgt",
  ariaSkeletonCellLoadingFailed: "Raden kunne ikke lastes",
  ariaSkeletonCellLoading: "Raddata lastes",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Radgrupper",
  ariaValuesDropZonePanelLabel: "Verdier",
  ariaPivotDropZonePanelLabel: "Kolonneetiketter",
  ariaDropZoneColumnComponentDescription: "Trykk DELETE for \xE5 fjerne",
  ariaDropZoneColumnValueItemDescription: "Trykk ENTER for \xE5 endre aggregeringstype",
  ariaDropZoneColumnGroupItemDescription: "Trykk ENTER for \xE5 sortere",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " av ",
  ariaDropZoneColumnComponentSortAscending: "stigende",
  ariaDropZoneColumnComponentSortDescending: "synkende",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Kolonnemeny",
  ariaLabelColumnFilter: "Kolonnefilter",
  ariaLabelCellEditor: "Celleditor",
  ariaLabelSelectField: "Velg Felt",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rik velg felt",
  ariaLabelRichSelectToggleSelection: "Trykk SPACE for \xE5 veksle valg",
  ariaLabelRichSelectDeselectAllItems: "Trykk DELETE for \xE5 fjerne alle valg",
  ariaLabelRichSelectDeleteSelection: "Trykk DELETE for \xE5 fjerne valget",
  ariaLabelTooltip: "Verkt\xF8ytips",
  ariaLabelContextMenu: "Kontekstmeny",
  ariaLabelSubMenu: "Undermeny",
  ariaLabelAggregationFunction: "Aggregeringsfunksjon",
  ariaLabelAdvancedFilterAutocomplete: "Avansert filter autfullstendig",
  ariaLabelAdvancedFilterBuilderAddField: "Byggeverkt\xF8y for avansert filter legg til felt",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Byggeverkt\xF8y for avansert filter kolonnevelger felt",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Byggeverkt\xF8y for avansert filter alternativvelger felt",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Byggeverkt\xF8y for avansert filter sammenf\xF8yingsoperator velger felt",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Kolonneliste",
  ariaFilterPanelList: "Filterliste",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Sant",
  false: "Falsk",
  invalidDate: "Ugyldig dato",
  invalidNumber: "Ugyldig nummer",
  january: "Januar",
  february: "Februar",
  march: "Mars",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Desember",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "TT:MM:SS",
  timeFormatHHMMSSAmPm: "TT:MM:SS AM/PM"
};

// community-modules/locale/src/nl-NL.ts
var AG_GRID_LOCALE_NL = {
  // Set Filter
  selectAll: "(Alles selecteren)",
  selectAllSearchResults: "(Alle zoekresultaten selecteren)",
  addCurrentSelectionToFilter: "Huidige selectie toevoegen aan filter",
  searchOoo: "Zoeken...",
  blanks: "(Leegten)",
  noMatches: "Geen overeenkomsten",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Gelijk aan",
  notEqual: "Niet gelijk aan",
  blank: "Leeg",
  notBlank: "Niet leeg",
  empty: "Kies een",
  // Number Filter
  lessThan: "Minder dan",
  greaterThan: "Groter dan",
  lessThanOrEqual: "Minder dan of gelijk aan",
  greaterThanOrEqual: "Groter dan of gelijk aan",
  inRange: "Tussen",
  inRangeStart: "Van",
  inRangeEnd: "Tot",
  // Text Filter
  contains: "Bevat",
  notContains: "Bevat niet",
  startsWith: "Begint met",
  endsWith: "Eindigt met",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Voor",
  after: "Na",
  // Filter Conditions
  andCondition: "EN",
  orCondition: "OF",
  // Filter Buttons
  applyFilter: "Toepassen",
  resetFilter: "Resetten",
  clearFilter: "Wissen",
  cancelFilter: "Annuleren",
  // Filter Titles
  textFilter: "Tekstfilter",
  numberFilter: "Getallenfilter",
  dateFilter: "Datumfilter",
  setFilter: "Setfilter",
  // Group Column Filter
  groupFilterSelect: "Selecteer veld:",
  // Advanced Filter
  advancedFilterContains: "bevat",
  advancedFilterNotContains: "bevat niet",
  advancedFilterTextEquals: "is gelijk aan",
  advancedFilterTextNotEqual: "is niet gelijk aan",
  advancedFilterStartsWith: "begint met",
  advancedFilterEndsWith: "eindigt met",
  advancedFilterBlank: "is leeg",
  advancedFilterNotBlank: "is niet leeg",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "is waar",
  advancedFilterFalse: "is niet waar",
  advancedFilterAnd: "EN",
  advancedFilterOr: "OF",
  advancedFilterApply: "Toepassen",
  advancedFilterBuilder: "Bouwer",
  advancedFilterValidationMissingColumn: "Kolom ontbreekt",
  advancedFilterValidationMissingOption: "Optie ontbreekt",
  advancedFilterValidationMissingValue: "Waarde ontbreekt",
  advancedFilterValidationInvalidColumn: "Kolom niet gevonden",
  advancedFilterValidationInvalidOption: "Optie niet gevonden",
  advancedFilterValidationMissingQuote: "Waarde mist een aanhalingsteken aan het einde",
  advancedFilterValidationNotANumber: "Waarde is geen getal",
  advancedFilterValidationInvalidDate: "Waarde is geen geldige datum",
  advancedFilterValidationMissingCondition: "Voorwaarde ontbreekt",
  advancedFilterValidationJoinOperatorMismatch: "Logische operatoren binnen een voorwaarde moeten gelijk zijn",
  advancedFilterValidationInvalidJoinOperator: "Logische operator niet gevonden",
  advancedFilterValidationMissingEndBracket: "Ontbrekende eindhaak",
  advancedFilterValidationExtraEndBracket: "Te veel eindhaken",
  advancedFilterValidationMessage: "Uitdrukking bevat een fout. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Uitdrukking bevat een fout. ${variable} aan het einde van de uitdrukking.",
  advancedFilterBuilderTitle: "Geavanceerd filter",
  advancedFilterBuilderApply: "Toepassen",
  advancedFilterBuilderCancel: "Annuleren",
  advancedFilterBuilderAddButtonTooltip: "Filter of groep toevoegen",
  advancedFilterBuilderRemoveButtonTooltip: "Verwijderen",
  advancedFilterBuilderMoveUpButtonTooltip: "Omhoog verplaatsen",
  advancedFilterBuilderMoveDownButtonTooltip: "Omlaag verplaatsen",
  advancedFilterBuilderAddJoin: "Groep toevoegen",
  advancedFilterBuilderAddCondition: "Filter toevoegen",
  advancedFilterBuilderSelectColumn: "Selecteer een kolom",
  advancedFilterBuilderSelectOption: "Selecteer een optie",
  advancedFilterBuilderEnterValue: "Voer een waarde in...",
  advancedFilterBuilderValidationAlreadyApplied: "Huidige filter al toegepast.",
  advancedFilterBuilderValidationIncomplete: "Niet alle voorwaarden zijn voltooid.",
  advancedFilterBuilderValidationSelectColumn: "Moet een kolom selecteren.",
  advancedFilterBuilderValidationSelectOption: "Moet een optie selecteren.",
  advancedFilterBuilderValidationEnterValue: "Moet een waarde invoeren.",
  // Side Bar
  columns: "Kolommen",
  filters: "Filters",
  // columns tool panel
  pivotMode: "Draaimodus",
  groups: "Rijgroepen",
  rowGroupColumnsEmptyMessage: "Sleep hierheen om rijen te groeperen",
  values: "Waarden",
  valueColumnsEmptyMessage: "Sleep hierheen om te aggregeren",
  pivots: "Kolomlabels",
  pivotColumnsEmptyMessage: "Sleep hierheen om kolomlabels te instellen",
  // Header of the Default Group Column
  group: "Groep",
  // Row Drag
  rowDragRow: "rij",
  rowDragRows: "rijen",
  // Other
  loadingOoo: "Laden...",
  loadingError: "FOUT",
  noRowsToShow: "Geen rijen om weer te geven",
  enabled: "Ingeschakeld",
  // Menu
  pinColumn: "Kolom vastzetten",
  pinLeft: "Links vastzetten",
  pinRight: "Rechts vastzetten",
  noPin: "Niet vastzetten",
  valueAggregation: "Waarde-aggregering",
  noAggregation: "Geen",
  autosizeThisColumn: "Deze kolom automatisch aanpassen",
  autosizeAllColumns: "Alle kolommen automatisch aanpassen",
  groupBy: "Groeperen op",
  ungroupBy: "Degroeperen op",
  ungroupAll: "Alle groepen opheffen",
  addToValues: "Voeg ${variable} toe aan waarden",
  removeFromValues: "Verwijder ${variable} van waarden",
  addToLabels: "Voeg ${variable} toe aan labels",
  removeFromLabels: "Verwijder ${variable} van labels",
  resetColumns: "Kolommen resetten",
  expandAll: "Alle rijen groepen uitvouwen",
  collapseAll: "Alle rijen groepen inklappen",
  copy: "Kopi\xEBren",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopi\xEBren met kopteksten",
  copyWithGroupHeaders: "Kopi\xEBren met groep kopteksten",
  cut: "Knippen",
  paste: "Plakken",
  ctrlV: "Ctrl+V",
  export: "Exporteren",
  csvExport: "CSV Export",
  excelExport: "Excel Export",
  columnFilter: "Kolomfilter",
  columnChooser: "Kolommen kiezen",
  chooseColumns: "Kolommen kiezen",
  sortAscending: "Oplopend sorteren",
  sortDescending: "Aflopend sorteren",
  sortUnSort: "Sortering wissen",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Som",
  first: "Eerste",
  last: "Laatste",
  min: "Min",
  max: "Max",
  none: "Geen",
  count: "Aantal",
  avg: "Gemiddelde",
  filteredRows: "Gefilterd",
  selectedRows: "Geselecteerd",
  totalRows: "Totaal Aantal Rijen",
  totalAndFilteredRows: "Rijen",
  more: "Meer",
  to: "tot",
  of: "van",
  page: "Pagina",
  pageLastRowUnknown: "?",
  nextPage: "Volgende Pagina",
  lastPage: "Laatste Pagina",
  firstPage: "Eerste Pagina",
  previousPage: "Vorige Pagina",
  pageSizeSelectorLabel: "Pagina Grootte:",
  footerTotal: "Totaal",
  // Pivoting
  pivotColumnGroupTotals: "Totaal",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Draaidiagram & Draaistand",
  pivotChart: "Draaidiagram",
  chartRange: "Diagrambereik",
  columnChart: "Kolom",
  groupedColumn: "Gegroepeerd",
  stackedColumn: "Gestapeld",
  normalizedColumn: "100% Gestapeld",
  barChart: "Staaf",
  groupedBar: "Gegroepeerd",
  stackedBar: "Gestapeld",
  normalizedBar: "100% Gestapeld",
  pieChart: "Taart",
  pie: "Taart",
  donut: "Donut",
  line: "Lijn",
  xyChart: "X Y (Spreiding)",
  scatter: "Spreiding",
  bubble: "Bubble",
  areaChart: "Gebied",
  area: "Gebied",
  stackedArea: "Gestapeld",
  normalizedArea: "100% Gestapeld",
  histogramChart: "Histogram",
  polarChart: "Polair",
  radarLine: "Radar Lijn",
  radarArea: "Radar Gebied",
  nightingale: "Nightingale",
  radialColumn: "Radiale Kolom",
  radialBar: "Radiale Staaf",
  statisticalChart: "Statistisch",
  boxPlot: "Boxplot",
  rangeBar: "Reeks Staaf",
  rangeArea: "Reeks Gebied",
  hierarchicalChart: "Hi\xEBrarchisch",
  treemap: "Boomkaart",
  sunburst: "Zonnevlam",
  specializedChart: "Gespecialiseerd",
  waterfall: "Waterval",
  heatmap: "Warmtekaart",
  combinationChart: "Combinatie",
  columnLineCombo: "Kolom & Lijn",
  AreaColumnCombo: "Gebied & Kolom",
  // Charts
  pivotChartTitle: "Draai Grafiek",
  rangeChartTitle: "Bereik Grafiek",
  settings: "Grafiek",
  data: "Instellen",
  format: "Aanpassen",
  categories: "Categorie\xEBn",
  defaultCategory: "(Geen)",
  series: "Reeksen",
  switchCategorySeries: "Wissel Categorie / Reeks",
  categoryValues: "Categorie Waarden",
  seriesLabels: "Reeks Labels",
  aggregate: "Aggregeren",
  xyValues: "X Y Waarden",
  paired: "Gekoppelde Modus",
  axis: "As",
  xAxis: "Horizontale As",
  yAxis: "Verticale As",
  polarAxis: "Polar As",
  radiusAxis: "Radius As",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animatie",
  crosshair: "Kruisdraad",
  color: "Kleur",
  thickness: "Dikte",
  preferredLength: "Voorkeurslengte",
  xType: "X Type",
  axisType: "As Type",
  automatic: "Automatisch",
  category: "Categorie",
  number: "Nummer",
  time: "Tijd",
  timeFormat: "Tijdformaat",
  autoRotate: "Auto Rotatie",
  labelRotation: "Rotatie",
  circle: "Cirkel",
  polygon: "Polygoon",
  square: "Vierkant",
  cross: "Kruis",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Driehoek",
  heart: "Hart",
  orientation: "Ori\xEBntatie",
  fixed: "Vast",
  parallel: "Parallel",
  perpendicular: "Loodrecht",
  radiusAxisPosition: "Positie",
  ticks: "Tikken",
  gridLines: "Rasterlijnen",
  width: "Breedte",
  height: "Hoogte",
  length: "Lengte",
  padding: "Opvulling",
  spacing: "Afstand",
  chartStyle: "Grafiekstijl",
  title: "Titel",
  chartTitles: "Titels",
  chartTitle: "Grafiektitel",
  chartSubtitle: "Ondertitel",
  horizontalAxisTitle: "Horizontale As Titel",
  verticalAxisTitle: "Verticale As Titel",
  polarAxisTitle: "Polar As Titel",
  titlePlaceholder: "Grafiektitel",
  background: "Achtergrond",
  font: "Lettertype",
  weight: "Gewicht",
  top: "Boven",
  right: "Rechts",
  bottom: "Onder",
  left: "Links",
  labels: "Labels",
  calloutLabels: "Uitroep Labels",
  sectorLabels: "Sector Labels",
  positionRatio: "Positie Verhouding",
  size: "Grootte",
  shape: "Vorm",
  minSize: "Minimale Grootte",
  maxSize: "Maximale Grootte",
  legend: "Legenda",
  position: "Positie",
  markerSize: "Markergrootte",
  markerStroke: "Markeromtrek",
  markerPadding: "Markeropvulling",
  itemSpacing: "Itemafstand",
  itemPaddingX: "Item Opvulling X",
  itemPaddingY: "Item Opvulling Y",
  layoutHorizontalSpacing: "Horizontale Afstand",
  layoutVerticalSpacing: "Verticale Afstand",
  strokeWidth: "Omtrekbreedte",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Toelichtingen",
  callout: "Uitroep",
  markers: "Markeringen",
  shadow: "Schaduw",
  blur: "Vervagen",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Lijnbreedte",
  lineDash: "Lijnstreep",
  lineDashOffset: "Streep Offset",
  scrollingZoom: "Scrollen",
  scrollingStep: "Scrollstap",
  selectingZoom: "Selecteren",
  durationMillis: "Duur (ms)",
  crosshairLabel: "Label",
  crosshairSnap: "Vastklikken op Knoop",
  normal: "Normaal",
  bold: "Vetgedrukt",
  italic: "Cursief",
  boldItalic: "Vet Cursief",
  predefined: "Voorgedefinieerd",
  fillOpacity: "Vuldoorzichtigheid",
  strokeColor: "Lijnkleur",
  strokeOpacity: "Lijndoorzichtigheid",
  miniChart: "Mini-Grafiek",
  histogramBinCount: "Bak aantal",
  connectorLine: "Connector Lijn",
  seriesItems: "Reeks Items",
  seriesItemType: "Item Type",
  seriesItemPositive: "Positief",
  seriesItemNegative: "Negatief",
  seriesItemLabels: "Item Labels",
  columnGroup: "Kolom",
  barGroup: "Staaf",
  pieGroup: "Taart",
  lineGroup: "Lijn",
  scatterGroup: "X Y (Spreiding)",
  areaGroup: "Gebied",
  polarGroup: "Polar",
  statisticalGroup: "Statistisch",
  hierarchicalGroup: "Hi\xEBrarchisch",
  specializedGroup: "Gespecialiseerd",
  combinationGroup: "Combinatie",
  groupedColumnTooltip: "Gegroepeerd",
  stackedColumnTooltip: "Gestapeld",
  normalizedColumnTooltip: "100% Gestapeld",
  groupedBarTooltip: "Gegroepeerd",
  stackedBarTooltip: "Gestapeld",
  normalizedBarTooltip: "100% Gestapeld",
  pieTooltip: "Taart",
  donutTooltip: "Donut",
  lineTooltip: "Lijn",
  groupedAreaTooltip: "Gebied",
  stackedAreaTooltip: "Gestapeld",
  normalizedAreaTooltip: "100% Gestapeld",
  scatterTooltip: "Spreiding",
  bubbleTooltip: "Bel",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radiale Kolom",
  radialBarTooltip: "Radiale Staaf",
  radarLineTooltip: "Radar Lijn",
  radarAreaTooltip: "Radar Gebied",
  nightingaleTooltip: "Nachtegaal",
  rangeBarTooltip: "Bereik Staaf",
  rangeAreaTooltip: "Bereik Gebied",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Boomdiagram",
  sunburstTooltip: "Zonnestraal",
  waterfallTooltip: "Waterval",
  heatmapTooltip: "Warmtekaart",
  columnLineComboTooltip: "Kolom & Lijn",
  areaColumnComboTooltip: "Gebied & Kolom",
  customComboTooltip: "Aangepaste Combinatie",
  innerRadius: "Binnenstraal",
  startAngle: "Start Hoek",
  endAngle: "Eind Hoek",
  reverseDirection: "Omkeren",
  groupPadding: "Groep Opvulling",
  seriesPadding: "Reeks Opvulling",
  tile: "Tegel",
  whisker: "Snavel",
  cap: "Kap",
  capLengthRatio: "Lengte Verhouding",
  labelPlacement: "Label Plaatsing",
  inside: "Binnen",
  outside: "Buiten",
  noDataToChart: "Geen gegevens beschikbaar om in een grafiek weer te geven.",
  pivotChartRequiresPivotMode: "Draai Grafiek vereist Draai Modus ingeschakeld.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Gekoppeld aan Raster",
  chartUnlinkToolbarTooltip: "Ontkoppeld van Raster",
  chartDownloadToolbarTooltip: "Download Grafiek",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Bewerk Grafiek",
  chartAdvancedSettings: "Geavanceerde Instellingen",
  chartLink: "Koppelen aan Raster",
  chartUnlink: "Ontkoppelen van Raster",
  chartDownload: "Download Grafiek",
  histogramFrequency: "Frequentie",
  seriesChartType: "Reeks Grafiek Type",
  seriesType: "Reeks Type",
  secondaryAxis: "Secundaire As",
  seriesAdd: "Voeg een reeks toe",
  categoryAdd: "Voeg een categorie toe",
  bar: "Staaf",
  column: "Kolom",
  histogram: "Histogram",
  advancedSettings: "Geavanceerde Instellingen",
  direction: "Richting",
  horizontal: "Horizontaal",
  vertical: "Verticaal",
  seriesGroupType: "Groep Type",
  groupedSeriesGroupType: "Gegroepeerd",
  stackedSeriesGroupType: "Gestapeld",
  normalizedSeriesGroupType: "100% Gestapeld",
  legendEnabled: "Ingeschakeld",
  invalidColor: "Kleurwaarde is ongeldig",
  groupedColumnFull: "Gegroepeerde Kolom",
  stackedColumnFull: "Gestapelde Kolom",
  normalizedColumnFull: "100% Gestapelde Kolom",
  groupedBarFull: "Gegroepeerde Staaf",
  stackedBarFull: "Gestapelde Staaf",
  normalizedBarFull: "100% Gestapelde Staaf",
  stackedAreaFull: "Gestapeld Gebied",
  normalizedAreaFull: "100% Gestapeld Gebied",
  customCombo: "Aangepaste Combinatie",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Niveau ${variable}. Druk op ENTER om te bewerken",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Niveau ${variable}. ${variable} Druk op ENTER om te bewerken.",
  ariaAdvancedFilterBuilderList: "Geavanceerde Filter Builder Lijst",
  ariaAdvancedFilterBuilderFilterItem: "Filter Voorwaarde",
  ariaAdvancedFilterBuilderGroupItem: "Filter Groep",
  ariaAdvancedFilterBuilderColumn: "Kolom",
  ariaAdvancedFilterBuilderOption: "Optie",
  ariaAdvancedFilterBuilderValueP: "Waarde",
  ariaAdvancedFilterBuilderJoinOperator: "Koppeloperator",
  ariaAdvancedFilterInput: "Geavanceerde Filter Invoer",
  ariaChecked: "geselecteerd",
  ariaColumn: "Kolom",
  ariaColumnGroup: "Kolom Groep",
  ariaColumnFiltered: "Kolom Gefilterd",
  ariaColumnSelectAll: "Selecteer Alle Kolommen",
  ariaDateFilterInput: "Datum Filter Invoer",
  ariaDefaultListName: "Lijst",
  ariaFilterColumnsInput: "Filter Kolommen Invoer",
  ariaFilterFromValue: "Filter vanuit waarde",
  ariaFilterInput: "Filter Invoer",
  ariaFilterList: "Filter Lijst",
  ariaFilterToValue: "Filter naar waarde",
  ariaFilterValue: "Filter Waarde",
  ariaFilterMenuOpen: "Open Filtermenu",
  ariaFilteringOperator: "Filter Operator",
  ariaHidden: "verborgen",
  ariaIndeterminate: "onbepaald",
  ariaInputEditor: "Invoer Editor",
  ariaMenuColumn: "Druk op ALT DOWN om het kolommenmenu te openen",
  ariaFilterColumn: "Druk op CTRL ENTER om te filteren",
  ariaRowDeselect: "Druk op SPATIE om deze rij te deselecteren",
  ariaRowSelectAll: "Druk op SPATIE om alle rijen te selecteren/deselecteren",
  ariaRowToggleSelection: "Druk op SPATIE om rijselectie te schakelen",
  ariaRowSelect: "Druk op SPATIE om deze rij te selecteren",
  ariaRowSelectionDisabled: "Rijselectie is uitgeschakeld voor deze rij",
  ariaSearch: "Zoeken",
  ariaSortableColumn: "Druk op ENTER om te sorteren",
  ariaToggleVisibility: "Druk op SPATIE om zichtbaarheid te schakelen",
  ariaToggleCellValue: "Druk op SPATIE om celwaarde te schakelen",
  ariaUnchecked: "niet geselecteerd",
  ariaVisible: "zichtbaar",
  ariaSearchFilterValues: "Zoek filterwaarden",
  ariaPageSizeSelectorLabel: "Pagina Grootte",
  ariaChartMenuClose: "Sluit Grafiek Bewerkmenu",
  ariaChartSelected: "Geselecteerd",
  ariaSkeletonCellLoadingFailed: "Rij laden is mislukt",
  ariaSkeletonCellLoading: "Rijgegevens worden geladen",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Rijgroepen",
  ariaValuesDropZonePanelLabel: "Waarden",
  ariaPivotDropZonePanelLabel: "Kolomlabels",
  ariaDropZoneColumnComponentDescription: "Druk op DELETE om te verwijderen",
  ariaDropZoneColumnValueItemDescription: "Druk op ENTER om het aggregatietype te wijzigen",
  ariaDropZoneColumnGroupItemDescription: "Druk op ENTER om te sorteren",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " of ",
  ariaDropZoneColumnComponentSortAscending: "oplopend",
  ariaDropZoneColumnComponentSortDescending: "aflopend",
  ariaLabelDialog: "Dialoog",
  ariaLabelColumnMenu: "Kolommenu",
  ariaLabelColumnFilter: "Kolomfilter",
  ariaLabelCellEditor: "Celbewerker",
  ariaLabelSelectField: "Selectievak",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rijk Selectieveld",
  ariaLabelRichSelectToggleSelection: "Druk op SPATIE om selectie te wisselen",
  ariaLabelRichSelectDeselectAllItems: "Druk op VERWIJDEREN om alle items te deselecteren",
  ariaLabelRichSelectDeleteSelection: "Druk op VERWIJDEREN om item te deselecteren",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Contextmenu",
  ariaLabelSubMenu: "Submenu",
  ariaLabelAggregationFunction: "Aggregatiefunctie",
  ariaLabelAdvancedFilterAutocomplete: "Geavanceerde Filter Autocompleteren",
  ariaLabelAdvancedFilterBuilderAddField: "Geavanceerde Filter Builder Veld Toevoegen",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Geavanceerde Filter Builder Kolom Selecteer Veld",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Geavanceerde Filter Builder Optie Selecteer Veld",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Geavanceerde Filter Builder Koppeloperator Selecteer Veld",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Kolom Lijst",
  ariaFilterPanelList: "Filterlijst",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Waar",
  false: "Onwaar",
  invalidDate: "Ongeldige Datum",
  invalidNumber: "Ongeldig Nummer",
  january: "Januari",
  february: "Februari",
  march: "Maart",
  april: "April",
  may: "Mei",
  june: "Juni",
  july: "Juli",
  august: "Augustus",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/JJJJ",
  timeFormatSlashesMMDDYYYY: "MM/DD/JJJJ",
  timeFormatSlashesDDMMYY: "DD/MM/JJ",
  timeFormatSlashesMMDDYY: "MM/DD/JJ",
  timeFormatDotsDDMYY: "DD.M.JJ",
  timeFormatDotsMDDYY: "M.DD.JJ",
  timeFormatDashesYYYYMMDD: "JJJJ-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM JJJJ",
  timeFormatHHMMSS: "UU:MM:SS",
  timeFormatHHMMSSAmPm: "UU:MM:SS AM/PM"
};

// community-modules/locale/src/zh-CN.ts
var AG_GRID_LOCALE_CN = {
  // Set Filter
  selectAll: "(\u5168\u9009)",
  selectAllSearchResults: "(\u5168\u9009\u641C\u7D22\u7ED3\u679C)",
  addCurrentSelectionToFilter: "\u5C06\u5F53\u524D\u9009\u62E9\u6DFB\u52A0\u5230\u7B5B\u9009\u5668",
  searchOoo: "\u641C\u7D22...",
  blanks: "(\u7A7A\u767D)",
  noMatches: "\u65E0\u5339\u914D\u9879",
  // Number Filter & Text Filter
  filterOoo: "\u8FC7\u6EE4...",
  equals: "\u7B49\u4E8E",
  notEqual: "\u4E0D\u7B49\u4E8E",
  blank: "\u7A7A\u767D",
  notBlank: "\u975E\u7A7A",
  empty: "\u9009\u62E9\u4E00\u4E2A",
  // Number Filter
  lessThan: "\u5C0F\u4E8E",
  greaterThan: "\u5927\u4E8E",
  lessThanOrEqual: "\u5C0F\u4E8E\u7B49\u4E8E",
  greaterThanOrEqual: "\u5927\u4E8E\u7B49\u4E8E",
  inRange: "\u4ECB\u4E8E",
  inRangeStart: "\u4ECE",
  inRangeEnd: "\u5230",
  // Text Filter
  contains: "\u5305\u542B",
  notContains: "\u4E0D\u5305\u542B",
  startsWith: "\u5F00\u59CB\u4E8E",
  endsWith: "\u7ED3\u675F\u4E8E",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u4E4B\u524D",
  after: "\u4E4B\u540E",
  // Filter Conditions
  andCondition: "\u548C",
  orCondition: "\u6216",
  // Filter Buttons
  applyFilter: "\u5E94\u7528",
  resetFilter: "\u91CD\u7F6E",
  clearFilter: "\u6E05\u9664",
  cancelFilter: "\u53D6\u6D88",
  // Filter Titles
  textFilter: "\u6587\u672C\u8FC7\u6EE4\u5668",
  numberFilter: "\u6570\u5B57\u8FC7\u6EE4\u5668",
  dateFilter: "\u65E5\u671F\u8FC7\u6EE4\u5668",
  setFilter: "\u96C6\u5408\u8FC7\u6EE4\u5668",
  // Group Column Filter
  groupFilterSelect: "\u9009\u62E9\u5B57\u6BB5:",
  // Advanced Filter
  advancedFilterContains: "\u5305\u542B",
  advancedFilterNotContains: "\u4E0D\u5305\u542B",
  advancedFilterTextEquals: "\u7B49\u4E8E",
  advancedFilterTextNotEqual: "\u4E0D\u7B49\u4E8E",
  advancedFilterStartsWith: "\u5F00\u59CB\u4E8E",
  advancedFilterEndsWith: "\u7ED3\u675F\u4E8E",
  advancedFilterBlank: "\u4E3A\u7A7A",
  advancedFilterNotBlank: "\u4E0D\u4E3A\u7A7A",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u4E3A\u771F",
  advancedFilterFalse: "\u4E3A\u5047",
  advancedFilterAnd: "\u4E14",
  advancedFilterOr: "\u6216",
  advancedFilterApply: "\u5E94\u7528",
  advancedFilterBuilder: "\u6784\u5EFA\u5668",
  advancedFilterValidationMissingColumn: "\u7F3A\u5C11\u5217",
  advancedFilterValidationMissingOption: "\u7F3A\u5C11\u9009\u9879",
  advancedFilterValidationMissingValue: "\u7F3A\u5C11\u503C",
  advancedFilterValidationInvalidColumn: "\u627E\u4E0D\u5230\u5217",
  advancedFilterValidationInvalidOption: "\u627E\u4E0D\u5230\u9009\u9879",
  advancedFilterValidationMissingQuote: "\u503C\u7F3A\u5C11\u7ED3\u675F\u5F15\u53F7",
  advancedFilterValidationNotANumber: "\u503C\u4E0D\u662F\u4E00\u4E2A\u6570\u5B57",
  advancedFilterValidationInvalidDate: "\u503C\u4E0D\u662F\u4E00\u4E2A\u6709\u6548\u65E5\u671F",
  advancedFilterValidationMissingCondition: "\u7F3A\u5C11\u6761\u4EF6",
  advancedFilterValidationJoinOperatorMismatch: "\u4E00\u4E2A\u6761\u4EF6\u5185\u7684\u8FDE\u63A5\u64CD\u4F5C\u7B26\u5FC5\u987B\u76F8\u540C",
  advancedFilterValidationInvalidJoinOperator: "\u627E\u4E0D\u5230\u8FDE\u63A5\u64CD\u4F5C\u7B26",
  advancedFilterValidationMissingEndBracket: "\u7F3A\u5C11\u7ED3\u675F\u62EC\u53F7",
  advancedFilterValidationExtraEndBracket: "\u7ED3\u675F\u62EC\u53F7\u8FC7\u591A",
  advancedFilterValidationMessage: "\u8868\u8FBE\u5F0F\u6709\u9519\u8BEF\u3002${variable} - ${variable}\u3002",
  advancedFilterValidationMessageAtEnd: "\u8868\u8FBE\u5F0F\u6709\u9519\u8BEF\u3002\u8868\u8FBE\u5F0F\u672B\u5C3E\u7684${variable}\u3002",
  advancedFilterBuilderTitle: "\u9AD8\u7EA7\u7B5B\u9009",
  advancedFilterBuilderApply: "\u5E94\u7528",
  advancedFilterBuilderCancel: "\u53D6\u6D88",
  advancedFilterBuilderAddButtonTooltip: "\u6DFB\u52A0\u7B5B\u9009\u6216\u7EC4",
  advancedFilterBuilderRemoveButtonTooltip: "\u79FB\u9664",
  advancedFilterBuilderMoveUpButtonTooltip: "\u4E0A\u79FB",
  advancedFilterBuilderMoveDownButtonTooltip: "\u4E0B\u79FB",
  advancedFilterBuilderAddJoin: "\u6DFB\u52A0\u7EC4",
  advancedFilterBuilderAddCondition: "\u6DFB\u52A0\u7B5B\u9009",
  advancedFilterBuilderSelectColumn: "\u9009\u62E9\u4E00\u4E2A\u5217",
  advancedFilterBuilderSelectOption: "\u9009\u62E9\u4E00\u4E2A\u9009\u9879",
  advancedFilterBuilderEnterValue: "\u8F93\u5165\u4E00\u4E2A\u503C...",
  advancedFilterBuilderValidationAlreadyApplied: "\u5F53\u524D\u7B5B\u9009\u5DF2\u5E94\u7528\u3002",
  advancedFilterBuilderValidationIncomplete: "\u5E76\u975E\u6240\u6709\u6761\u4EF6\u90FD\u5DF2\u5B8C\u6210\u3002",
  advancedFilterBuilderValidationSelectColumn: "\u5FC5\u987B\u9009\u62E9\u4E00\u4E2A\u5217\u3002",
  advancedFilterBuilderValidationSelectOption: "\u5FC5\u987B\u9009\u62E9\u4E00\u4E2A\u9009\u9879\u3002",
  advancedFilterBuilderValidationEnterValue: "\u5FC5\u987B\u8F93\u5165\u4E00\u4E2A\u503C\u3002",
  // Side Bar
  columns: "\u5217",
  filters: "\u8FC7\u6EE4\u5668",
  // columns tool panel
  pivotMode: "\u900F\u89C6\u6A21\u5F0F",
  groups: "\u884C\u7EC4",
  rowGroupColumnsEmptyMessage: "\u62D6\u52A8\u5230\u6B64\u5904\u8BBE\u7F6E\u884C\u7EC4",
  values: "\u503C",
  valueColumnsEmptyMessage: "\u62D6\u52A8\u5230\u6B64\u5904\u805A\u5408",
  pivots: "\u5217\u6807\u7B7E",
  pivotColumnsEmptyMessage: "\u62D6\u52A8\u5230\u6B64\u5904\u8BBE\u7F6E\u5217\u6807\u7B7E",
  // Header of the Default Group Column
  group: "\u7EC4",
  // Row Drag
  rowDragRow: "\u884C",
  rowDragRows: "\u884C",
  // Other
  loadingOoo: "\u52A0\u8F7D\u4E2D...",
  loadingError: "\u9519\u8BEF",
  noRowsToShow: "\u65E0\u663E\u793A\u884C",
  enabled: "\u542F\u7528",
  // Menu
  pinColumn: "\u56FA\u5B9A\u5217",
  pinLeft: "\u56FA\u5B9A\u5728\u5DE6\u4FA7",
  pinRight: "\u56FA\u5B9A\u5728\u53F3\u4FA7",
  noPin: "\u53D6\u6D88\u56FA\u5B9A",
  valueAggregation: "\u503C\u6C47\u603B",
  noAggregation: "\u65E0",
  autosizeThisColumn: "\u81EA\u52A8\u8C03\u6574\u8BE5\u5217",
  autosizeAllColumns: "\u81EA\u52A8\u8C03\u6574\u6240\u6709\u5217",
  groupBy: "\u6309\u6B64\u5206\u7EC4",
  ungroupBy: "\u53D6\u6D88\u6309\u6B64\u5206\u7EC4",
  ungroupAll: "\u53D6\u6D88\u5168\u90E8\u5206\u7EC4",
  addToValues: "\u5C06${variable}\u6DFB\u52A0\u5230\u503C",
  removeFromValues: "\u5C06${variable}\u4ECE\u503C\u4E2D\u79FB\u9664",
  addToLabels: "\u5C06${variable}\u6DFB\u52A0\u5230\u6807\u7B7E",
  removeFromLabels: "\u5C06${variable}\u4ECE\u6807\u7B7E\u4E2D\u79FB\u9664",
  resetColumns: "\u91CD\u7F6E\u5217",
  expandAll: "\u5C55\u5F00\u6240\u6709\u884C\u7EC4",
  collapseAll: "\u5173\u95ED\u6240\u6709\u884C\u7EC4",
  copy: "\u590D\u5236",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u590D\u5236\u5305\u542B\u6807\u9898",
  copyWithGroupHeaders: "\u590D\u5236\u5305\u542B\u7EC4\u6807\u9898",
  cut: "\u526A\u5207",
  paste: "\u7C98\u8D34",
  ctrlV: "Ctrl+V",
  export: "\u5BFC\u51FA",
  csvExport: "\u5BFC\u51FA\u4E3ACSV",
  excelExport: "\u5BFC\u51FA\u4E3AExcel",
  columnFilter: "\u5217\u8FC7\u6EE4",
  columnChooser: "\u9009\u62E9\u5217",
  chooseColumns: "\u9009\u62E9\u5217",
  sortAscending: "\u5347\u5E8F\u6392\u5217",
  sortDescending: "\u964D\u5E8F\u6392\u5217",
  sortUnSort: "\u6E05\u9664\u6392\u5E8F",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u603B\u548C",
  first: "\u7B2C\u4E00\u4E2A",
  last: "\u6700\u540E\u4E00\u4E2A",
  min: "\u6700\u5C0F\u503C",
  max: "\u6700\u5927\u503C",
  none: "\u65E0",
  count: "\u8BA1\u6570",
  avg: "\u5E73\u5747",
  filteredRows: "\u5DF2\u7B5B\u9009",
  selectedRows: "\u5DF2\u9009\u4E2D",
  totalRows: "\u603B\u884C\u6570",
  totalAndFilteredRows: "\u884C",
  more: "\u66F4\u591A",
  to: "\u81F3",
  of: "\u7684",
  page: "\u9875",
  pageLastRowUnknown: "?",
  nextPage: "\u4E0B\u4E00\u9875",
  lastPage: "\u6700\u540E\u4E00\u9875",
  firstPage: "\u7B2C\u4E00\u9875",
  previousPage: "\u4E0A\u4E00\u9875",
  pageSizeSelectorLabel: "\u6BCF\u9875\u5927\u5C0F\uFF1A",
  footerTotal: "\u5408\u8BA1",
  // Pivoting
  pivotColumnGroupTotals: "\u603B\u8BA1",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u6570\u636E\u900F\u89C6\u56FE\u548C\u6570\u636E\u900F\u89C6\u6A21\u5F0F",
  pivotChart: "\u6570\u636E\u900F\u89C6\u56FE",
  chartRange: "\u56FE\u8868\u8303\u56F4",
  columnChart: "\u67F1\u72B6\u56FE",
  groupedColumn: "\u5206\u7EC4",
  stackedColumn: "\u5806\u79EF",
  normalizedColumn: "100% \u5806\u79EF",
  barChart: "\u6761\u5F62\u56FE",
  groupedBar: "\u5206\u7EC4",
  stackedBar: "\u5806\u79EF",
  normalizedBar: "100% \u5806\u79EF",
  pieChart: "\u997C\u56FE",
  pie: "\u997C\u56FE",
  donut: "\u73AF\u5F62\u56FE",
  line: "\u6298\u7EBF\u56FE",
  xyChart: "X Y (\u6563\u70B9\u56FE)",
  scatter: "\u6563\u70B9\u56FE",
  bubble: "\u6C14\u6CE1\u56FE",
  areaChart: "\u9762\u79EF\u56FE",
  area: "\u9762\u79EF",
  stackedArea: "\u5806\u79EF",
  normalizedArea: "100% \u5806\u79EF",
  histogramChart: "\u76F4\u65B9\u56FE",
  polarChart: "\u6781\u5730\u56FE",
  radarLine: "\u96F7\u8FBE\u7EBF",
  radarArea: "\u96F7\u8FBE\u9762\u79EF",
  nightingale: "\u591C\u83BA\u56FE",
  radialColumn: "\u5F84\u5411\u67F1\u72B6\u56FE",
  radialBar: "\u5F84\u5411\u6761\u5F62\u56FE",
  statisticalChart: "\u7EDF\u8BA1\u56FE",
  boxPlot: "\u7BB1\u7EBF\u56FE",
  rangeBar: "\u533A\u95F4\u6761\u5F62\u56FE",
  rangeArea: "\u533A\u95F4\u9762\u79EF\u56FE",
  hierarchicalChart: "\u5C42\u6B21\u56FE",
  treemap: "\u6811\u56FE",
  sunburst: "\u65ED\u65E5\u56FE",
  specializedChart: "\u4E13\u9879\u56FE",
  waterfall: "\u7011\u5E03\u56FE",
  heatmap: "\u70ED\u529B\u56FE",
  combinationChart: "\u7EC4\u5408\u56FE",
  columnLineCombo: "\u67F1\u72B6\u56FE\u548C\u6298\u7EBF\u56FE\u7EC4\u5408",
  AreaColumnCombo: "\u9762\u79EF\u56FE\u548C\u67F1\u72B6\u56FE\u7EC4\u5408",
  // Charts
  pivotChartTitle: "\u6570\u636E\u900F\u89C6\u56FE",
  rangeChartTitle: "\u8303\u56F4\u56FE",
  settings: "\u56FE\u8868",
  data: "\u8BBE\u7F6E",
  format: "\u81EA\u5B9A\u4E49",
  categories: "\u7C7B\u522B",
  defaultCategory: "(\u65E0)",
  series: "\u7CFB\u5217",
  switchCategorySeries: "\u5207\u6362\u7C7B\u522B / \u7CFB\u5217",
  categoryValues: "\u7C7B\u522B\u503C",
  seriesLabels: "\u7CFB\u5217\u6807\u7B7E",
  aggregate: "\u6C47\u603B",
  xyValues: "XY \u503C",
  paired: "\u914D\u5BF9\u6A21\u5F0F",
  axis: "\u8F74",
  xAxis: "\u6C34\u5E73\u8F74",
  yAxis: "\u5782\u76F4\u8F74",
  polarAxis: "\u6781\u5750\u6807\u8F74",
  radiusAxis: "\u534A\u5F84\u8F74",
  navigator: "\u5BFC\u822A\u5668",
  zoom: "\u7F29\u653E",
  animation: "\u52A8\u753B",
  crosshair: "\u51C6\u661F",
  color: "\u989C\u8272",
  thickness: "\u539A\u5EA6",
  preferredLength: "\u9996\u9009\u957F\u5EA6",
  xType: "X \u7C7B\u578B",
  axisType: "\u8F74\u7C7B\u578B",
  automatic: "\u81EA\u52A8",
  category: "\u7C7B\u522B",
  number: "\u6570\u503C",
  time: "\u65F6\u95F4",
  timeFormat: "\u65F6\u95F4\u683C\u5F0F",
  autoRotate: "\u81EA\u52A8\u65CB\u8F6C",
  labelRotation: "\u65CB\u8F6C",
  circle: "\u5706\u5F62",
  polygon: "\u591A\u8FB9\u5F62",
  square: "\u65B9\u5F62",
  cross: "\u5341\u5B57\u7B26",
  diamond: "\u83F1\u5F62",
  plus: "\u52A0\u53F7",
  triangle: "\u4E09\u89D2\u5F62",
  heart: "\u7231\u5FC3",
  orientation: "\u65B9\u5411",
  fixed: "\u56FA\u5B9A",
  parallel: "\u5E73\u884C",
  perpendicular: "\u5782\u76F4",
  radiusAxisPosition: "\u4F4D\u7F6E",
  ticks: "\u523B\u5EA6",
  gridLines: "\u7F51\u683C\u7EBF",
  width: "\u5BBD\u5EA6",
  height: "\u9AD8\u5EA6",
  length: "\u957F\u5EA6",
  padding: "\u5185\u8FB9\u8DDD",
  spacing: "\u95F4\u8DDD",
  chartStyle: "\u56FE\u8868\u6837\u5F0F",
  title: "\u6807\u9898",
  chartTitles: "\u6807\u9898",
  chartTitle: "\u56FE\u8868\u6807\u9898",
  chartSubtitle: "\u526F\u6807\u9898",
  horizontalAxisTitle: "\u6C34\u5E73\u8F74\u6807\u9898",
  verticalAxisTitle: "\u5782\u76F4\u8F74\u6807\u9898",
  polarAxisTitle: "\u6781\u5750\u6807\u8F74\u6807\u9898",
  titlePlaceholder: "\u56FE\u8868\u6807\u9898",
  background: "\u80CC\u666F",
  font: "\u5B57\u4F53",
  weight: "\u7C97\u7EC6",
  top: "\u9876\u90E8",
  right: "\u53F3\u8FB9",
  bottom: "\u5E95\u90E8",
  left: "\u5DE6\u8FB9",
  labels: "\u6807\u7B7E",
  calloutLabels: "\u6807\u6CE8\u6807\u7B7E",
  sectorLabels: "\u6247\u533A\u6807\u7B7E",
  positionRatio: "\u4F4D\u7F6E\u6BD4\u4F8B",
  size: "\u5927\u5C0F",
  shape: "\u5F62\u72B6",
  minSize: "\u6700\u5C0F\u5927\u5C0F",
  maxSize: "\u6700\u5927\u5927\u5C0F",
  legend: "\u56FE\u4F8B",
  position: "\u4F4D\u7F6E",
  markerSize: "\u6807\u8BB0\u5927\u5C0F",
  markerStroke: "\u6807\u8BB0\u63CF\u8FB9",
  markerPadding: "\u6807\u8BB0\u5185\u8FB9\u8DDD",
  itemSpacing: "\u9879\u76EE\u95F4\u8DDD",
  itemPaddingX: "\u9879\u76EE\u5185\u8FB9\u8DDD X",
  itemPaddingY: "\u9879\u76EE\u5185\u8FB9\u8DDD Y",
  layoutHorizontalSpacing: "\u6A2A\u5411\u95F4\u8DDD",
  layoutVerticalSpacing: "\u7EB5\u5411\u95F4\u8DDD",
  strokeWidth: "\u63CF\u8FB9\u5BBD\u5EA6",
  offset: "\u504F\u79FB",
  offsets: "\u504F\u79FB",
  tooltips: "\u5DE5\u5177\u63D0\u793A",
  callout: "\u6807\u6CE8",
  markers: "\u6807\u8BB0",
  shadow: "\u9634\u5F71",
  blur: "\u6A21\u7CCA",
  xOffset: "X \u504F\u79FB",
  yOffset: "Y \u504F\u79FB",
  lineWidth: "\u7EBF\u5BBD",
  lineDash: "\u7EBF\u6761\u865A\u7EBF",
  lineDashOffset: "\u865A\u7EBF\u504F\u79FB",
  scrollingZoom: "\u6EDA\u52A8",
  scrollingStep: "\u6EDA\u52A8\u6B65\u9AA4",
  selectingZoom: "\u9009\u62E9",
  durationMillis: "\u6301\u7EED\u65F6\u95F4 (\u6BEB\u79D2)",
  crosshairLabel: "\u6807\u7B7E",
  crosshairSnap: "\u5BF9\u8282\u70B9\u5BF9\u9F50",
  normal: "\u5E38\u89C4",
  bold: "\u52A0\u7C97",
  italic: "\u659C\u4F53",
  boldItalic: "\u52A0\u7C97\u659C\u4F53",
  predefined: "\u9884\u5B9A\u4E49",
  fillOpacity: "\u586B\u5145\u4E0D\u900F\u660E\u5EA6",
  strokeColor: "\u7EBF\u6761\u989C\u8272",
  strokeOpacity: "\u7EBF\u6761\u4E0D\u900F\u660E\u5EA6",
  miniChart: "\u8FF7\u4F60\u56FE\u8868",
  histogramBinCount: "\u7BB1\u6570",
  connectorLine: "\u8FDE\u63A5\u7EBF",
  seriesItems: "\u7CFB\u5217\u9879\u76EE",
  seriesItemType: "\u9879\u76EE\u7C7B\u578B",
  seriesItemPositive: "\u6B63\u9762",
  seriesItemNegative: "\u8D1F\u9762",
  seriesItemLabels: "\u9879\u76EE\u6807\u7B7E",
  columnGroup: "\u67F1\u5F62\u56FE",
  barGroup: "\u6761\u5F62\u56FE",
  pieGroup: "\u997C\u56FE",
  lineGroup: "\u6298\u7EBF\u56FE",
  scatterGroup: "\u6563\u70B9\u56FE",
  areaGroup: "\u9762\u79EF\u56FE",
  polarGroup: "\u6781\u5750\u6807\u56FE",
  statisticalGroup: "\u7EDF\u8BA1\u56FE",
  hierarchicalGroup: "\u5C42\u6B21\u56FE",
  specializedGroup: "\u4E13\u7528\u56FE",
  combinationGroup: "\u7EC4\u5408\u56FE",
  groupedColumnTooltip: "\u5206\u7EC4",
  stackedColumnTooltip: "\u5806\u79EF",
  normalizedColumnTooltip: "100% \u5806\u79EF",
  groupedBarTooltip: "\u5206\u7EC4",
  stackedBarTooltip: "\u5806\u79EF",
  normalizedBarTooltip: "100% \u5806\u79EF",
  pieTooltip: "\u997C\u56FE",
  donutTooltip: "\u73AF\u5F62\u56FE",
  lineTooltip: "\u6298\u7EBF\u56FE",
  groupedAreaTooltip: "\u9762\u79EF\u56FE",
  stackedAreaTooltip: "\u5806\u79EF",
  normalizedAreaTooltip: "100% \u5806\u79EF",
  scatterTooltip: "\u6563\u70B9\u56FE",
  bubbleTooltip: "\u6C14\u6CE1\u56FE",
  histogramTooltip: "\u76F4\u65B9\u56FE",
  radialColumnTooltip: "\u5F84\u5411\u67F1\u56FE",
  radialBarTooltip: "\u5F84\u5411\u6761\u56FE",
  radarLineTooltip: "\u96F7\u8FBE\u7EBF\u56FE",
  radarAreaTooltip: "\u96F7\u8FBE\u9762\u79EF\u56FE",
  nightingaleTooltip: "\u73AB\u7470\u56FE",
  rangeBarTooltip: "\u8303\u56F4\u6761\u56FE",
  rangeAreaTooltip: "\u8303\u56F4\u9762\u79EF\u56FE",
  boxPlotTooltip: "\u7BB1\u7EBF\u56FE",
  treemapTooltip: "\u6811\u72B6\u56FE",
  sunburstTooltip: "\u65ED\u65E5\u56FE",
  waterfallTooltip: "\u7011\u5E03\u56FE",
  heatmapTooltip: "\u70ED\u529B\u56FE",
  columnLineComboTooltip: "\u67F1\u56FE\u4E0E\u6298\u7EBF\u56FE",
  areaColumnComboTooltip: "\u9762\u79EF\u56FE\u4E0E\u67F1\u56FE",
  customComboTooltip: "\u81EA\u5B9A\u4E49\u7EC4\u5408",
  innerRadius: "\u5185\u534A\u5F84",
  startAngle: "\u8D77\u59CB\u89D2\u5EA6",
  endAngle: "\u7EC8\u6B62\u89D2\u5EA6",
  reverseDirection: "\u53CD\u5411",
  groupPadding: "\u7EC4\u95F4\u8DDD",
  seriesPadding: "\u7CFB\u5217\u95F4\u8DDD",
  tile: "\u74E6\u7247",
  whisker: "\u987B",
  cap: "\u9876\u90E8",
  capLengthRatio: "\u9876\u90E8\u957F\u5EA6\u6BD4",
  labelPlacement: "\u6807\u7B7E\u4F4D\u7F6E",
  inside: "\u5185\u90E8",
  outside: "\u5916\u90E8",
  noDataToChart: "\u65E0\u53EF\u7ED8\u5236\u7684\u6570\u636E\u3002",
  pivotChartRequiresPivotMode: "\u6570\u636E\u900F\u89C6\u56FE\u9700\u8981\u542F\u7528\u6570\u636E\u900F\u89C6\u6A21\u5F0F\u3002",
  chartSettingsToolbarTooltip: "\u83DC\u5355",
  chartLinkToolbarTooltip: "\u94FE\u63A5\u5230\u7F51\u683C",
  chartUnlinkToolbarTooltip: "\u4ECE\u7F51\u683C\u4E2D\u53D6\u6D88\u94FE\u63A5",
  chartDownloadToolbarTooltip: "\u4E0B\u8F7D\u56FE\u8868",
  chartMenuToolbarTooltip: "\u83DC\u5355",
  chartEdit: "\u7F16\u8F91\u56FE\u8868",
  chartAdvancedSettings: "\u9AD8\u7EA7\u8BBE\u7F6E",
  chartLink: "\u94FE\u63A5\u5230\u7F51\u683C",
  chartUnlink: "\u4ECE\u7F51\u683C\u4E2D\u53D6\u6D88\u94FE\u63A5",
  chartDownload: "\u4E0B\u8F7D\u56FE\u8868",
  histogramFrequency: "\u9891\u7387",
  seriesChartType: "\u7CFB\u5217\u56FE\u8868\u7C7B\u578B",
  seriesType: "\u7CFB\u5217\u7C7B\u578B",
  secondaryAxis: "\u6B21\u8F74",
  seriesAdd: "\u6DFB\u52A0\u7CFB\u5217",
  categoryAdd: "\u6DFB\u52A0\u7C7B\u522B",
  bar: "\u6761\u5F62\u56FE",
  column: "\u67F1\u5F62\u56FE",
  histogram: "\u76F4\u65B9\u56FE",
  advancedSettings: "\u9AD8\u7EA7\u8BBE\u7F6E",
  direction: "\u65B9\u5411",
  horizontal: "\u6C34\u5E73",
  vertical: "\u5782\u76F4",
  seriesGroupType: "\u5206\u7EC4\u7C7B\u578B",
  groupedSeriesGroupType: "\u5206\u7EC4",
  stackedSeriesGroupType: "\u5806\u79EF",
  normalizedSeriesGroupType: "100% \u5806\u79EF",
  legendEnabled: "\u542F\u7528",
  invalidColor: "\u65E0\u6548\u7684\u989C\u8272\u503C",
  groupedColumnFull: "\u5206\u7EC4\u67F1\u5F62\u56FE",
  stackedColumnFull: "\u5806\u79EF\u67F1\u5F62\u56FE",
  normalizedColumnFull: "100% \u5806\u79EF\u67F1\u5F62\u56FE",
  groupedBarFull: "\u5206\u7EC4\u6761\u5F62\u56FE",
  stackedBarFull: "\u5806\u79EF\u6761\u5F62\u56FE",
  normalizedBarFull: "100% \u5806\u79EF\u6761\u5F62\u56FE",
  stackedAreaFull: "\u5806\u79EF\u9762\u79EF\u56FE",
  normalizedAreaFull: "100% \u5806\u79EF\u9762\u79EF\u56FE",
  customCombo: "\u81EA\u5B9A\u4E49\u7EC4\u5408",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u7EA7\u522B ${variable}. \u6309 ENTER \u8FDB\u884C\u7F16\u8F91\u3002",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u7EA7\u522B ${variable}. ${variable} \u6309 ENTER \u8FDB\u884C\u7F16\u8F91\u3002",
  ariaAdvancedFilterBuilderList: "\u9AD8\u7EA7\u8FC7\u6EE4\u5668\u6784\u5EFA\u5668\u5217\u8868",
  ariaAdvancedFilterBuilderFilterItem: "\u8FC7\u6EE4\u6761\u4EF6",
  ariaAdvancedFilterBuilderGroupItem: "\u8FC7\u6EE4\u7EC4",
  ariaAdvancedFilterBuilderColumn: "\u5217",
  ariaAdvancedFilterBuilderOption: "\u9009\u9879",
  ariaAdvancedFilterBuilderValueP: "\u503C",
  ariaAdvancedFilterBuilderJoinOperator: "\u8FDE\u63A5\u8FD0\u7B97\u7B26",
  ariaAdvancedFilterInput: "\u9AD8\u7EA7\u8FC7\u6EE4\u5668\u8F93\u5165",
  ariaChecked: "\u5DF2\u9009\u4E2D",
  ariaColumn: "\u5217",
  ariaColumnGroup: "\u5217\u7EC4",
  ariaColumnFiltered: "\u5217\u5DF2\u8FC7\u6EE4",
  ariaColumnSelectAll: "\u5207\u6362\u9009\u62E9\u6240\u6709\u5217",
  ariaDateFilterInput: "\u65E5\u671F\u8FC7\u6EE4\u5668\u8F93\u5165",
  ariaDefaultListName: "\u5217\u8868",
  ariaFilterColumnsInput: "\u8FC7\u6EE4\u5217\u8F93\u5165",
  ariaFilterFromValue: "\u8FC7\u6EE4\u4ECE\u503C",
  ariaFilterInput: "\u8FC7\u6EE4\u5668\u8F93\u5165",
  ariaFilterList: "\u8FC7\u6EE4\u5668\u5217\u8868",
  ariaFilterToValue: "\u8FC7\u6EE4\u81F3\u503C",
  ariaFilterValue: "\u8FC7\u6EE4\u503C",
  ariaFilterMenuOpen: "\u6253\u5F00\u8FC7\u6EE4\u5668\u83DC\u5355",
  ariaFilteringOperator: "\u8FC7\u6EE4\u8FD0\u7B97\u7B26",
  ariaHidden: "\u9690\u85CF",
  ariaIndeterminate: "\u4E0D\u786E\u5B9A",
  ariaInputEditor: "\u8F93\u5165\u7F16\u8F91\u5668",
  ariaMenuColumn: "\u6309 ALT \u5411\u4E0B \u6253\u5F00\u5217\u83DC\u5355",
  ariaFilterColumn: "\u6309 CTRL ENTER \u6253\u5F00\u8FC7\u6EE4\u5668",
  ariaRowDeselect: "\u6309 SPACE \u53D6\u6D88\u9009\u62E9\u6B64\u884C",
  ariaRowSelectAll: "\u6309 Space \u5207\u6362\u6240\u6709\u884C\u9009\u62E9",
  ariaRowToggleSelection: "\u6309 Space \u5207\u6362\u884C\u9009\u62E9",
  ariaRowSelect: "\u6309 SPACE \u9009\u62E9\u6B64\u884C",
  ariaRowSelectionDisabled: "\u6B64\u884C\u7684\u884C\u9009\u62E9\u529F\u80FD\u88AB\u7981\u7528",
  ariaSearch: "\u641C\u7D22",
  ariaSortableColumn: "\u6309 ENTER \u6392\u5E8F",
  ariaToggleVisibility: "\u6309 Space \u5207\u6362\u53EF\u89C1\u6027",
  ariaToggleCellValue: "\u6309 Space \u5207\u6362\u5355\u5143\u683C\u503C",
  ariaUnchecked: "\u672A\u9009\u4E2D",
  ariaVisible: "\u53EF\u89C1",
  ariaSearchFilterValues: "\u641C\u7D22\u8FC7\u6EE4\u503C",
  ariaPageSizeSelectorLabel: "\u9875\u9762\u5927\u5C0F",
  ariaChartMenuClose: "\u5173\u95ED\u56FE\u8868\u7F16\u8F91\u83DC\u5355",
  ariaChartSelected: "\u5DF2\u9009\u62E9",
  ariaSkeletonCellLoadingFailed: "\u884C\u52A0\u8F7D\u5931\u8D25",
  ariaSkeletonCellLoading: "\u884C\u6570\u636E\u52A0\u8F7D\u4E2D",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u884C\u5206\u7EC4",
  ariaValuesDropZonePanelLabel: "\u503C",
  ariaPivotDropZonePanelLabel: "\u5217\u6807\u7B7E",
  ariaDropZoneColumnComponentDescription: "\u6309 DELETE \u952E\u79FB\u9664",
  ariaDropZoneColumnValueItemDescription: "\u6309 ENTER \u952E\u66F4\u6539\u805A\u5408\u7C7B\u578B",
  ariaDropZoneColumnGroupItemDescription: "\u6309 ENTER \u952E\u6392\u5E8F",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u7684 ",
  ariaDropZoneColumnComponentSortAscending: "\u5347\u5E8F",
  ariaDropZoneColumnComponentSortDescending: "\u964D\u5E8F",
  ariaLabelDialog: "\u5BF9\u8BDD\u6846",
  ariaLabelColumnMenu: "\u5217\u83DC\u5355",
  ariaLabelColumnFilter: "\u5217\u8FC7\u6EE4\u5668",
  ariaLabelCellEditor: "\u5355\u5143\u683C\u7F16\u8F91\u5668",
  ariaLabelSelectField: "\u9009\u62E9\u5B57\u6BB5",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u4E30\u5BCC\u9009\u62E9\u5B57\u6BB5",
  ariaLabelRichSelectToggleSelection: "\u6309\u4E0B\u7A7A\u683C\u952E\u4EE5\u5207\u6362\u9009\u62E9",
  ariaLabelRichSelectDeselectAllItems: "\u6309\u4E0B\u5220\u9664\u952E\u6765\u53D6\u6D88\u9009\u62E9\u6240\u6709\u9879\u76EE",
  ariaLabelRichSelectDeleteSelection: "\u6309\u4E0B\u5220\u9664\u952E\u6765\u53D6\u6D88\u9009\u62E9\u9879\u76EE",
  ariaLabelTooltip: "\u5DE5\u5177\u63D0\u793A",
  ariaLabelContextMenu: "\u4E0A\u4E0B\u6587\u83DC\u5355",
  ariaLabelSubMenu: "\u5B50\u83DC\u5355",
  ariaLabelAggregationFunction: "\u805A\u5408\u51FD\u6570",
  ariaLabelAdvancedFilterAutocomplete: "\u9AD8\u7EA7\u7B5B\u9009\u81EA\u52A8\u5B8C\u6210",
  ariaLabelAdvancedFilterBuilderAddField: "\u9AD8\u7EA7\u7B5B\u9009\u751F\u6210\u5668\u6DFB\u52A0\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u9AD8\u7EA7\u7B5B\u9009\u751F\u6210\u5668\u5217\u9009\u62E9\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u9AD8\u7EA7\u7B5B\u9009\u751F\u6210\u5668\u9009\u9879\u9009\u62E9\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u9AD8\u7EA7\u7B5B\u9009\u751F\u6210\u5668\u8FDE\u63A5\u64CD\u4F5C\u7B26\u9009\u62E9\u5B57\u6BB5",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u5217\u5217\u8868",
  ariaFilterPanelList: "\u8FC7\u6EE4\u5217\u8868",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u771F",
  false: "\u5047",
  invalidDate: "\u65E0\u6548\u65E5\u671F",
  invalidNumber: "\u65E0\u6548\u6570\u5B57",
  january: "\u4E00\u6708",
  february: "\u4E8C\u6708",
  march: "\u4E09\u6708",
  april: "\u56DB\u6708",
  may: "\u4E94\u6708",
  june: "\u516D\u6708",
  july: "\u4E03\u6708",
  august: "\u516B\u6708",
  september: "\u4E5D\u6708",
  october: "\u5341\u6708",
  november: "\u5341\u4E00\u6708",
  december: "\u5341\u4E8C\u6708",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS \u4E0A\u5348/\u4E0B\u5348"
};

// community-modules/locale/src/zh-HK.ts
var AG_GRID_LOCALE_HK = {
  // Set Filter
  selectAll: "(\u5168\u9078)",
  selectAllSearchResults: "(\u9078\u64C7\u6240\u6709\u641C\u7D22\u7D50\u679C)",
  addCurrentSelectionToFilter: "\u5C07\u7576\u524D\u9078\u64C7\u6DFB\u52A0\u5230\u904E\u6FFE\u5668",
  searchOoo: "\u641C\u7D22...",
  blanks: "(\u7A7A\u767D)",
  noMatches: "\u672A\u627E\u5230\u5339\u914D\u9805",
  // Number Filter & Text Filter
  filterOoo: "\u7BE9\u9078...",
  equals: "\u7B49\u65BC",
  notEqual: "\u4E0D\u7B49\u65BC",
  blank: "\u7A7A\u767D",
  notBlank: "\u975E\u7A7A\u767D",
  empty: "\u9078\u64C7\u4E00\u500B",
  // Number Filter
  lessThan: "\u5C11\u65BC",
  greaterThan: "\u5927\u65BC",
  lessThanOrEqual: "\u5C11\u65BC\u6216\u7B49\u65BC",
  greaterThanOrEqual: "\u5927\u65BC\u6216\u7B49\u65BC",
  inRange: "\u4ECB\u4E4E",
  inRangeStart: "\u7531",
  inRangeEnd: "\u81F3",
  // Text Filter
  contains: "\u5305\u542B",
  notContains: "\u4E0D\u5305\u542B",
  startsWith: "\u958B\u59CB\u65BC",
  endsWith: "\u7D50\u675F\u65BC",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u4E4B\u524D",
  after: "\u4E4B\u5F8C",
  // Filter Conditions
  andCondition: "\u548C",
  orCondition: "\u6216",
  // Filter Buttons
  applyFilter: "\u61C9\u7528",
  resetFilter: "\u91CD\u7F6E",
  clearFilter: "\u6E05\u9664",
  cancelFilter: "\u53D6\u6D88",
  // Filter Titles
  textFilter: "\u6587\u672C\u7BE9\u9078",
  numberFilter: "\u6578\u5B57\u7BE9\u9078",
  dateFilter: "\u65E5\u671F\u7BE9\u9078",
  setFilter: "\u96C6\u5408\u7BE9\u9078",
  // Group Column Filter
  groupFilterSelect: "\u9078\u64C7\u5B57\u6BB5\uFF1A",
  // Advanced Filter
  advancedFilterContains: "\u5305\u542B",
  advancedFilterNotContains: "\u4E0D\u5305\u542B",
  advancedFilterTextEquals: "\u7B49\u65BC",
  advancedFilterTextNotEqual: "\u4E0D\u7B49\u65BC",
  advancedFilterStartsWith: "\u958B\u59CB\u65BC",
  advancedFilterEndsWith: "\u7D50\u675F\u65BC",
  advancedFilterBlank: "\u70BA\u7A7A",
  advancedFilterNotBlank: "\u4E0D\u70BA\u7A7A",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u70BA\u771F",
  advancedFilterFalse: "\u70BA\u5047",
  advancedFilterAnd: "\u548C",
  advancedFilterOr: "\u6216",
  advancedFilterApply: "\u61C9\u7528",
  advancedFilterBuilder: "\u5EFA\u9020\u8005",
  advancedFilterValidationMissingColumn: "\u7F3A\u5C11\u5217",
  advancedFilterValidationMissingOption: "\u7F3A\u5C11\u9078\u9805",
  advancedFilterValidationMissingValue: "\u7F3A\u5C11\u503C",
  advancedFilterValidationInvalidColumn: "\u672A\u627E\u5230\u5217",
  advancedFilterValidationInvalidOption: "\u672A\u627E\u5230\u9078\u9805",
  advancedFilterValidationMissingQuote: "\u503C\u7F3A\u5C11\u7D50\u5C3E\u5F15\u865F",
  advancedFilterValidationNotANumber: "\u503C\u4E0D\u662F\u4E00\u500B\u6578\u5B57",
  advancedFilterValidationInvalidDate: "\u503C\u4E0D\u662F\u6709\u6548\u65E5\u671F",
  advancedFilterValidationMissingCondition: "\u7F3A\u5C11\u689D\u4EF6",
  advancedFilterValidationJoinOperatorMismatch: "\u689D\u4EF6\u5167\u7684\u9023\u63A5\u904B\u7B97\u7B26\u5FC5\u9808\u76F8\u540C",
  advancedFilterValidationInvalidJoinOperator: "\u672A\u627E\u5230\u9023\u63A5\u904B\u7B97\u7B26",
  advancedFilterValidationMissingEndBracket: "\u7F3A\u5C11\u7D50\u5C3E\u62EC\u865F",
  advancedFilterValidationExtraEndBracket: "\u7D50\u5C3E\u62EC\u865F\u904E\u591A",
  advancedFilterValidationMessage: "\u8868\u9054\u5F0F\u6709\u932F\u8AA4\u3002${variable} - ${variable}\u3002",
  advancedFilterValidationMessageAtEnd: "\u8868\u9054\u5F0F\u6709\u932F\u8AA4\u3002${variable} \u5728\u8868\u9054\u5F0F\u7684\u7D50\u5C3E\u3002",
  advancedFilterBuilderTitle: "\u9AD8\u7D1A\u904E\u6FFE\u5668",
  advancedFilterBuilderApply: "\u61C9\u7528",
  advancedFilterBuilderCancel: "\u53D6\u6D88",
  advancedFilterBuilderAddButtonTooltip: "\u6DFB\u52A0\u904E\u6FFE\u5668\u6216\u5206\u7D44",
  advancedFilterBuilderRemoveButtonTooltip: "\u79FB\u9664",
  advancedFilterBuilderMoveUpButtonTooltip: "\u5411\u4E0A\u79FB\u52D5",
  advancedFilterBuilderMoveDownButtonTooltip: "\u5411\u4E0B\u79FB\u52D5",
  advancedFilterBuilderAddJoin: "\u6DFB\u52A0\u5206\u7D44",
  advancedFilterBuilderAddCondition: "\u6DFB\u52A0\u904E\u6FFE\u5668",
  advancedFilterBuilderSelectColumn: "\u9078\u64C7\u4E00\u5217",
  advancedFilterBuilderSelectOption: "\u9078\u64C7\u4E00\u500B\u9078\u9805",
  advancedFilterBuilderEnterValue: "\u8F38\u5165\u4E00\u500B\u503C...",
  advancedFilterBuilderValidationAlreadyApplied: "\u7576\u524D\u904E\u6FFE\u5668\u5DF2\u61C9\u7528\u3002",
  advancedFilterBuilderValidationIncomplete: "\u689D\u4EF6\u672A\u5B8C\u5168\u3002",
  advancedFilterBuilderValidationSelectColumn: "\u5FC5\u9808\u9078\u64C7\u4E00\u5217\u3002",
  advancedFilterBuilderValidationSelectOption: "\u5FC5\u9808\u9078\u64C7\u4E00\u500B\u9078\u9805\u3002",
  advancedFilterBuilderValidationEnterValue: "\u5FC5\u9808\u8F38\u5165\u4E00\u500B\u503C\u3002",
  // Side Bar
  columns: "\u6B04",
  filters: "\u7BE9\u9078",
  // columns tool panel
  pivotMode: "\u6A1E\u7D10\u6A21\u5F0F",
  groups: "\u884C\u7D44",
  rowGroupColumnsEmptyMessage: "\u62D6\u52D5\u5230\u9019\u88E1\u8A2D\u7F6E\u884C\u7D44",
  values: "\u503C",
  valueColumnsEmptyMessage: "\u62D6\u52D5\u5230\u9019\u88E1\u9032\u884C\u532F\u7E3D",
  pivots: "\u5217\u6A19\u7C64",
  pivotColumnsEmptyMessage: "\u62D6\u52D5\u5230\u9019\u88E1\u8A2D\u7F6E\u5217\u6A19\u7C64",
  // Header of the Default Group Column
  group: "\u7D44",
  // Row Drag
  rowDragRow: "\u884C",
  rowDragRows: "\u884C",
  // Other
  loadingOoo: "\u8F09\u5165\u4E2D...",
  loadingError: "\u932F\u8AA4",
  noRowsToShow: "\u6C92\u6709\u884C\u986F\u793A",
  enabled: "\u5DF2\u555F\u7528",
  // Menu
  pinColumn: "\u56FA\u5B9A\u5217",
  pinLeft: "\u56FA\u5B9A\u5230\u5DE6\u908A",
  pinRight: "\u56FA\u5B9A\u5230\u53F3\u908A",
  noPin: "\u4E0D\u56FA\u5B9A",
  valueAggregation: "\u503C\u805A\u5408",
  noAggregation: "\u7121",
  autosizeThisColumn: "\u81EA\u52D5\u8ABF\u6574\u6B64\u5217\u7684\u5BEC\u5EA6",
  autosizeAllColumns: "\u81EA\u52D5\u8ABF\u6574\u6240\u6709\u5217\u7684\u5BEC\u5EA6",
  groupBy: "\u5206\u7D44",
  ungroupBy: "\u53D6\u6D88\u5206\u7D44",
  ungroupAll: "\u53D6\u6D88\u6240\u6709\u5206\u7D44",
  addToValues: "\u6DFB\u52A0 ${variable} \u5230\u6578\u503C",
  removeFromValues: "\u5F9E\u6578\u503C\u4E2D\u79FB\u9664 ${variable}",
  addToLabels: "\u6DFB\u52A0 ${variable} \u5230\u6A19\u7C64",
  removeFromLabels: "\u5F9E\u6A19\u7C64\u4E2D\u79FB\u9664 ${variable}",
  resetColumns: "\u91CD\u7F6E\u5217",
  expandAll: "\u5C55\u958B\u6240\u6709\u884C\u7D44",
  collapseAll: "\u95DC\u9589\u6240\u6709\u884C\u7D44",
  copy: "\u8907\u88FD",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u9023\u540C\u6A19\u982D\u4E00\u8D77\u8907\u88FD",
  copyWithGroupHeaders: "\u9023\u540C\u5206\u7D44\u6A19\u982D\u4E00\u8D77\u8907\u88FD",
  cut: "\u526A\u5207",
  paste: "\u7C98\u8CBC",
  ctrlV: "Ctrl+V",
  export: "\u532F\u51FA",
  csvExport: "\u532F\u51FA\u70BA CSV",
  excelExport: "\u532F\u51FA\u70BA Excel",
  columnFilter: "\u5217\u7BE9\u9078\u5668",
  columnChooser: "\u9078\u64C7\u5217",
  chooseColumns: "\u9078\u64C7\u5217",
  sortAscending: "\u5347\u5E8F\u6392\u5E8F",
  sortDescending: "\u964D\u5E8F\u6392\u5E8F",
  sortUnSort: "\u6E05\u9664\u6392\u5E8F",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u7E3D\u548C",
  first: "\u7B2C\u4E00",
  last: "\u6700\u5F8C",
  min: "\u6700\u5C0F",
  max: "\u6700\u5927",
  none: "\u7121",
  count: "\u8A08\u6578",
  avg: "\u5E73\u5747",
  filteredRows: "\u5DF2\u7BE9\u9078",
  selectedRows: "\u5DF2\u9078\u64C7",
  totalRows: "\u7E3D\u884C\u6578",
  totalAndFilteredRows: "\u884C\u6578",
  more: "\u66F4\u591A",
  to: "\u81F3",
  of: "\u7684",
  page: "\u9801",
  pageLastRowUnknown: "?",
  nextPage: "\u4E0B\u4E00\u9801",
  lastPage: "\u6700\u5F8C\u4E00\u9801",
  firstPage: "\u7B2C\u4E00\u9801",
  previousPage: "\u4E0A\u4E00\u9801",
  pageSizeSelectorLabel: "\u9801\u9762\u5927\u5C0F:",
  footerTotal: "\u7E3D\u8A08",
  // Pivoting
  pivotColumnGroupTotals: "\u7E3D\u8A08",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u6A1E\u7D10\u5716\u8868\u53CA\u6A1E\u7D10\u6A21\u5F0F",
  pivotChart: "\u6A1E\u7D10\u5716\u8868",
  chartRange: "\u5716\u8868\u7BC4\u570D",
  columnChart: "\u67F1\u72C0\u5716",
  groupedColumn: "\u5206\u7D44",
  stackedColumn: "\u5806\u758A",
  normalizedColumn: "100% \u5806\u758A",
  barChart: "\u6A6B\u689D\u5716",
  groupedBar: "\u5206\u7D44",
  stackedBar: "\u5806\u758A",
  normalizedBar: "100% \u5806\u758A",
  pieChart: "\u9905\u5716",
  pie: "\u9905\u5716",
  donut: "\u5713\u74B0\u5716",
  line: "\u6298\u7DDA\u5716",
  xyChart: "X Y (\u6563\u9EDE\u5716)",
  scatter: "\u6563\u9EDE\u5716",
  bubble: "\u6C23\u6CE1\u5716",
  areaChart: "\u5340\u57DF\u5716",
  area: "\u5340\u57DF",
  stackedArea: "\u5806\u758A",
  normalizedArea: "100% \u5806\u758A",
  histogramChart: "\u76F4\u65B9\u5716",
  polarChart: "\u6975\u5EA7\u6A19\u5716",
  radarLine: "\u96F7\u9054\u7DDA\u5716",
  radarArea: "\u96F7\u9054\u5340\u57DF\u5716",
  nightingale: "\u591C\u9DAF\u5716",
  radialColumn: "\u5F91\u5411\u67F1\u72C0\u5716",
  radialBar: "\u5F91\u5411\u689D\u5F62\u5716",
  statisticalChart: "\u7D71\u8A08\u5716",
  boxPlot: "\u76D2\u9B1A\u5716",
  rangeBar: "\u7BC4\u570D\u689D\u5F62\u5716",
  rangeArea: "\u7BC4\u570D\u5340\u57DF\u5716",
  hierarchicalChart: "\u968E\u5C64\u5716",
  treemap: "\u6A39\u72C0\u5716",
  sunburst: "\u65ED\u65E5\u5716",
  specializedChart: "\u5C08\u696D\u5716\u8868",
  waterfall: "\u7011\u5E03\u5716",
  heatmap: "\u71B1\u5716",
  combinationChart: "\u7D44\u5408\u5716",
  columnLineCombo: "\u67F1\u72C0\u5716\u548C\u6298\u7DDA\u5716",
  AreaColumnCombo: "\u5340\u57DF\u5716\u548C\u67F1\u72C0\u5716",
  // Charts
  pivotChartTitle: "\u6A1E\u7D10\u5716",
  rangeChartTitle: "\u7BC4\u570D\u5716",
  settings: "\u5716\u8868",
  data: "\u8A2D\u7F6E",
  format: "\u81EA\u8A02",
  categories: "\u985E\u5225",
  defaultCategory: "(\u7121)",
  series: "\u7CFB\u5217",
  switchCategorySeries: "\u5207\u63DB\u985E\u5225 / \u7CFB\u5217",
  categoryValues: "\u985E\u5225\u503C",
  seriesLabels: "\u7CFB\u5217\u6A19\u7C64",
  aggregate: "\u532F\u7E3D",
  xyValues: "X Y \u503C",
  paired: "\u914D\u5C0D\u6A21\u5F0F",
  axis: "\u8EF8",
  xAxis: "\u6C34\u5E73\u8EF8",
  yAxis: "\u5782\u76F4\u8EF8",
  polarAxis: "\u6975\u5EA7\u6A19\u8EF8",
  radiusAxis: "\u534A\u5F91\u8EF8",
  navigator: "\u5C0E\u822A\u5668",
  zoom: "\u7E2E\u653E",
  animation: "\u52D5\u756B",
  crosshair: "\u6E96\u5FC3",
  color: "\u984F\u8272",
  thickness: "\u539A\u5EA6",
  preferredLength: "\u9996\u9078\u9577\u5EA6",
  xType: "X \u985E\u578B",
  axisType: "\u8EF8\u985E\u578B",
  automatic: "\u81EA\u52D5",
  category: "\u985E\u5225",
  number: "\u6578\u5B57",
  time: "\u6642\u9593",
  timeFormat: "\u6642\u9593\u683C\u5F0F",
  autoRotate: "\u81EA\u52D5\u65CB\u8F49",
  labelRotation: "\u65CB\u8F49",
  circle: "\u5713\u5F62",
  polygon: "\u591A\u908A\u5F62",
  square: "\u6B63\u65B9\u5F62",
  cross: "\u53C9\u865F",
  diamond: "\u83F1\u5F62",
  plus: "\u52A0\u865F",
  triangle: "\u4E09\u89D2\u5F62",
  heart: "\u5FC3\u5F62",
  orientation: "\u65B9\u5411",
  fixed: "\u56FA\u5B9A",
  parallel: "\u5E73\u884C",
  perpendicular: "\u5782\u76F4",
  radiusAxisPosition: "\u4F4D\u7F6E",
  ticks: "\u523B\u5EA6",
  gridLines: "\u7DB2\u683C\u7DDA",
  width: "\u5BEC\u5EA6",
  height: "\u9AD8\u5EA6",
  length: "\u9577\u5EA6",
  padding: "\u5167\u8DDD",
  spacing: "\u9593\u8DDD",
  chartStyle: "\u5716\u8868\u6A23\u5F0F",
  title: "\u6A19\u984C",
  chartTitles: "\u6A19\u984C",
  chartTitle: "\u5716\u8868\u6A19\u984C",
  chartSubtitle: "\u526F\u6A19\u984C",
  horizontalAxisTitle: "\u6C34\u5E73\u8EF8\u6A19\u984C",
  verticalAxisTitle: "\u5782\u76F4\u8EF8\u6A19\u984C",
  polarAxisTitle: "\u6975\u5EA7\u6A19\u8EF8\u6A19\u984C",
  titlePlaceholder: "\u5716\u8868\u6A19\u984C",
  background: "\u80CC\u666F",
  font: "\u5B57\u9AD4",
  weight: "\u5B57\u91CD",
  top: "\u9802\u90E8",
  right: "\u53F3\u908A",
  bottom: "\u5E95\u90E8",
  left: "\u5DE6\u908A",
  labels: "\u6A19\u7C64",
  calloutLabels: "\u6A19\u8A3B\u6A19\u7C64",
  sectorLabels: "\u6247\u5340\u6A19\u7C64",
  positionRatio: "\u4F4D\u7F6E\u6BD4\u4F8B",
  size: "\u5927\u5C0F",
  shape: "\u5F62\u72C0",
  minSize: "\u6700\u5C0F\u5927\u5C0F",
  maxSize: "\u6700\u5927\u5927\u5C0F",
  legend: "\u5716\u4F8B",
  position: "\u4F4D\u7F6E",
  markerSize: "\u6A19\u8A18\u5927\u5C0F",
  markerStroke: "\u6A19\u8A18\u908A\u6846",
  markerPadding: "\u6A19\u8A18\u5167\u8DDD",
  itemSpacing: "\u9805\u76EE\u9593\u8DDD",
  itemPaddingX: "\u9805\u76EE\u5167\u8DDD X",
  itemPaddingY: "\u9805\u76EE\u5167\u8DDD Y",
  layoutHorizontalSpacing: "\u6C34\u5E73\u9593\u8DDD",
  layoutVerticalSpacing: "\u5782\u76F4\u9593\u8DDD",
  strokeWidth: "\u908A\u6846\u5BEC\u5EA6",
  offset: "\u504F\u79FB",
  offsets: "\u504F\u79FB\u91CF",
  tooltips: "\u5DE5\u5177\u63D0\u793A",
  callout: "\u6A19\u8A3B",
  markers: "\u6A19\u8A18",
  shadow: "\u9670\u5F71",
  blur: "\u6A21\u7CCA",
  xOffset: "X \u504F\u79FB",
  yOffset: "Y \u504F\u79FB",
  lineWidth: "\u7DDA\u5BEC",
  lineDash: "\u865B\u7DDA",
  lineDashOffset: "\u865B\u7DDA\u504F\u79FB",
  scrollingZoom: "\u6EFE\u52D5",
  scrollingStep: "\u6EFE\u52D5\u6B65\u9A5F",
  selectingZoom: "\u9078\u64C7",
  durationMillis: "\u6301\u7E8C\u6642\u9593 (\u6BEB\u79D2)",
  crosshairLabel: "\u6A19\u7C64",
  crosshairSnap: "\u5438\u9644\u5230\u7BC0\u9EDE",
  normal: "\u6B63\u5E38",
  bold: "\u52A0\u7C97",
  italic: "\u659C\u9AD4",
  boldItalic: "\u7C97\u659C\u9AD4",
  predefined: "\u9810\u5B9A\u7FA9",
  fillOpacity: "\u586B\u5145\u4E0D\u900F\u660E\u5EA6",
  strokeColor: "\u7DDA\u984F\u8272",
  strokeOpacity: "\u7DDA\u4E0D\u900F\u660E\u5EA6",
  miniChart: "\u8FF7\u4F60\u5716\u8868",
  histogramBinCount: "\u76F4\u65B9\u5716\u7BB1\u6578\u91CF",
  connectorLine: "\u9023\u63A5\u7DDA",
  seriesItems: "\u7CFB\u5217\u9805\u76EE",
  seriesItemType: "\u9805\u76EE\u985E\u578B",
  seriesItemPositive: "\u6B63\u9762",
  seriesItemNegative: "\u8CA0\u9762",
  seriesItemLabels: "\u9805\u76EE\u6A19\u7C64",
  columnGroup: "\u67F1\u72C0\u5716",
  barGroup: "\u689D\u5F62\u5716",
  pieGroup: "\u9905\u5716",
  lineGroup: "\u7DDA\u5716",
  scatterGroup: "X Y (\u6563\u9EDE\u5716)",
  areaGroup: "\u5340\u57DF\u5716",
  polarGroup: "\u6975\u5730\u5716",
  statisticalGroup: "\u7D71\u8A08\u5716",
  hierarchicalGroup: "\u5C64\u6B21\u7D50\u69CB\u5716",
  specializedGroup: "\u5C08\u9580\u5716",
  combinationGroup: "\u7D44\u5408\u5716",
  groupedColumnTooltip: "\u5206\u7D44",
  stackedColumnTooltip: "\u5806\u758A",
  normalizedColumnTooltip: "100% \u5806\u758A",
  groupedBarTooltip: "\u5206\u7D44",
  stackedBarTooltip: "\u5806\u758A",
  normalizedBarTooltip: "100% \u5806\u758A",
  pieTooltip: "\u9905\u5716",
  donutTooltip: "\u751C\u751C\u5708\u5716",
  lineTooltip: "\u7DDA\u5716",
  groupedAreaTooltip: "\u5340\u57DF\u5716",
  stackedAreaTooltip: "\u5806\u758A\u5340\u57DF\u5716",
  normalizedAreaTooltip: "100% \u5806\u758A\u5340\u57DF\u5716",
  scatterTooltip: "\u6563\u9EDE\u5716",
  bubbleTooltip: "\u6C23\u6CE1\u5716",
  histogramTooltip: "\u76F4\u65B9\u5716",
  radialColumnTooltip: "\u5F91\u5411\u67F1\u72C0\u5716",
  radialBarTooltip: "\u5F91\u5411\u689D\u5F62\u5716",
  radarLineTooltip: "\u96F7\u9054\u7DDA\u5716",
  radarAreaTooltip: "\u96F7\u9054\u5340\u57DF\u5716",
  nightingaleTooltip: "\u591C\u9DAF\u5716",
  rangeBarTooltip: "\u7BC4\u570D\u689D\u5F62\u5716",
  rangeAreaTooltip: "\u7BC4\u570D\u5340\u57DF\u5716",
  boxPlotTooltip: "\u7BB1\u578B\u5716",
  treemapTooltip: "\u6A39\u5716",
  sunburstTooltip: "\u65ED\u65E5\u5716",
  waterfallTooltip: "\u7011\u5E03\u5716",
  heatmapTooltip: "\u71B1\u5716",
  columnLineComboTooltip: "\u67F1\u72C0\u5716\u548C\u6298\u7DDA\u5716",
  areaColumnComboTooltip: "\u5340\u57DF\u5716\u548C\u67F1\u72C0\u5716",
  customComboTooltip: "\u81EA\u5B9A\u7FA9\u7D44\u5408",
  innerRadius: "\u5167\u5F91",
  startAngle: "\u958B\u59CB\u89D2\u5EA6",
  endAngle: "\u7D50\u675F\u89D2\u5EA6",
  reverseDirection: "\u53CD\u65B9\u5411",
  groupPadding: "\u7D44\u9593\u8DDD",
  seriesPadding: "\u7CFB\u5217\u9593\u8DDD",
  tile: "\u5716\u584A",
  whisker: "\u89F8\u89D2",
  cap: "\u5E3D\u5B50",
  capLengthRatio: "\u9577\u5EA6\u6BD4\u4F8B",
  labelPlacement: "\u6A19\u7C64\u653E\u7F6E",
  inside: "\u5167\u90E8",
  outside: "\u5916\u90E8",
  noDataToChart: "\u6C92\u6709\u53EF\u7528\u4F86\u7E6A\u88FD\u7684\u6578\u64DA\u3002",
  pivotChartRequiresPivotMode: "\u6A1E\u7D10\u5716\u9700\u8981\u555F\u7528\u6A1E\u7D10\u6A21\u5F0F\u3002",
  chartSettingsToolbarTooltip: "\u83DC\u55AE",
  chartLinkToolbarTooltip: "\u8207\u7DB2\u683C\u93C8\u63A5",
  chartUnlinkToolbarTooltip: "\u8207\u7DB2\u683C\u89E3\u93C8\u63A5",
  chartDownloadToolbarTooltip: "\u4E0B\u8F09\u5716\u8868",
  chartMenuToolbarTooltip: "\u83DC\u55AE",
  chartEdit: "\u7DE8\u8F2F\u5716\u8868",
  chartAdvancedSettings: "\u9AD8\u7D1A\u8A2D\u7F6E",
  chartLink: "\u93C8\u63A5\u5230\u7DB2\u683C",
  chartUnlink: "\u8207\u7DB2\u683C\u89E3\u93C8\u63A5",
  chartDownload: "\u4E0B\u8F09\u5716\u8868",
  histogramFrequency: "\u983B\u7387",
  seriesChartType: "\u7CFB\u5217\u5716\u8868\u985E\u578B",
  seriesType: "\u7CFB\u5217\u985E\u578B",
  secondaryAxis: "\u6B21\u8981\u8EF8",
  seriesAdd: "\u6DFB\u52A0\u7CFB\u5217",
  categoryAdd: "\u6DFB\u52A0\u985E\u5225",
  bar: "\u689D\u5F62\u5716",
  column: "\u67F1\u72C0\u5716",
  histogram: "\u76F4\u65B9\u5716",
  advancedSettings: "\u9AD8\u7D1A\u8A2D\u7F6E",
  direction: "\u65B9\u5411",
  horizontal: "\u6C34\u5E73",
  vertical: "\u5782\u76F4",
  seriesGroupType: "\u7D44\u985E\u578B",
  groupedSeriesGroupType: "\u5206\u7D44",
  stackedSeriesGroupType: "\u5806\u758A",
  normalizedSeriesGroupType: "100% \u5806\u758A",
  legendEnabled: "\u5DF2\u555F\u7528",
  invalidColor: "\u984F\u8272\u503C\u7121\u6548",
  groupedColumnFull: "\u5206\u7D44\u67F1\u72C0\u5716",
  stackedColumnFull: "\u5806\u758A\u67F1\u72C0\u5716",
  normalizedColumnFull: "100% \u5806\u758A\u67F1\u72C0\u5716",
  groupedBarFull: "\u5206\u7D44\u689D\u5F62\u5716",
  stackedBarFull: "\u5806\u758A\u689D\u5F62\u5716",
  normalizedBarFull: "100% \u5806\u758A\u689D\u5F62\u5716",
  stackedAreaFull: "\u5806\u758A\u5340\u57DF\u5716",
  normalizedAreaFull: "100% \u5806\u758A\u5340\u57DF\u5716",
  customCombo: "\u81EA\u5B9A\u7FA9\u7D44\u5408",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}\u3002\u5C64\u6B21 ${variable}\u3002\u6309 ENTER \u9032\u884C\u7DE8\u8F2F\u3002",
  ariaAdvancedFilterBuilderItemValidation: "${variable}\u3002\u5C64\u6B21 ${variable}\u3002${variable} \u6309 ENTER \u9032\u884C\u7DE8\u8F2F\u3002",
  ariaAdvancedFilterBuilderList: "\u9AD8\u7D1A\u7BE9\u9078\u5668\u69CB\u5EFA\u5217\u8868",
  ariaAdvancedFilterBuilderFilterItem: "\u7BE9\u9078\u689D\u4EF6",
  ariaAdvancedFilterBuilderGroupItem: "\u7BE9\u9078\u7D44",
  ariaAdvancedFilterBuilderColumn: "\u5217",
  ariaAdvancedFilterBuilderOption: "\u9078\u9805",
  ariaAdvancedFilterBuilderValueP: "\u503C",
  ariaAdvancedFilterBuilderJoinOperator: "\u9023\u63A5\u904B\u7B97\u7B26",
  ariaAdvancedFilterInput: "\u9AD8\u7D1A\u7BE9\u9078\u5668\u8F38\u5165",
  ariaChecked: "\u5DF2\u9078\u4E2D",
  ariaColumn: "\u5217",
  ariaColumnGroup: "\u5217\u7D44",
  ariaColumnFiltered: "\u5217\u88AB\u7BE9\u9078",
  ariaColumnSelectAll: "\u5207\u63DB\u9078\u64C7\u6240\u6709\u5217",
  ariaDateFilterInput: "\u65E5\u671F\u7BE9\u9078\u5668\u8F38\u5165",
  ariaDefaultListName: "\u5217\u8868",
  ariaFilterColumnsInput: "\u7BE9\u9078\u5217\u8F38\u5165",
  ariaFilterFromValue: "\u5F9E\u503C\u7BE9\u9078",
  ariaFilterInput: "\u7BE9\u9078\u5668\u8F38\u5165",
  ariaFilterList: "\u7BE9\u9078\u5217\u8868",
  ariaFilterToValue: "\u7BE9\u9078\u5230\u503C",
  ariaFilterValue: "\u7BE9\u9078\u503C",
  ariaFilterMenuOpen: "\u6253\u958B\u7BE9\u9078\u83DC\u55AE",
  ariaFilteringOperator: "\u7BE9\u9078\u904B\u7B97\u7B26",
  ariaHidden: "\u5DF2\u96B1\u85CF",
  ariaIndeterminate: "\u4E0D\u78BA\u5B9A",
  ariaInputEditor: "\u8F38\u5165\u7DE8\u8F2F\u5668",
  ariaMenuColumn: "\u6309 ALT \u4E0B\u7BAD\u982D \u6253\u958B\u5217\u83DC\u55AE",
  ariaFilterColumn: "\u6309 CTRL ENTER \u6253\u958B\u7BE9\u9078\u5668",
  ariaRowDeselect: "\u6309 SPACE \u53D6\u6D88\u9078\u64C7\u6B64\u884C",
  ariaRowSelectAll: "\u6309\u7A7A\u683C\u5207\u63DB\u6240\u6709\u884C\u7684\u9078\u64C7",
  ariaRowToggleSelection: "\u6309\u7A7A\u683C\u5207\u63DB\u884C\u9078\u64C7",
  ariaRowSelect: "\u6309 SPACE \u9078\u64C7\u6B64\u884C",
  ariaRowSelectionDisabled: "\u6B64\u884C\u7684\u884C\u9078\u64C7\u5DF2\u7981\u7528",
  ariaSearch: "\u641C\u7D22",
  ariaSortableColumn: "\u6309 ENTER \u6392\u5E8F",
  ariaToggleVisibility: "\u6309 SPACE \u5207\u63DB\u53EF\u898B\u6027",
  ariaToggleCellValue: "\u6309 SPACE \u5207\u63DB\u55AE\u5143\u683C\u503C",
  ariaUnchecked: "\u672A\u9078\u4E2D",
  ariaVisible: "\u53EF\u898B",
  ariaSearchFilterValues: "\u641C\u7D22\u7BE9\u9078\u503C",
  ariaPageSizeSelectorLabel: "\u9801\u9762\u5927\u5C0F",
  ariaChartMenuClose: "\u95DC\u9589\u5716\u8868\u7DE8\u8F2F\u83DC\u55AE",
  ariaChartSelected: "\u5DF2\u9078\u64C7",
  ariaSkeletonCellLoadingFailed: "\u884C\u52A0\u8F09\u5931\u6557",
  ariaSkeletonCellLoading: "\u884C\u6578\u64DA\u52A0\u8F09\u4E2D",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u884C\u7FA4\u7D44",
  ariaValuesDropZonePanelLabel: "\u503C",
  ariaPivotDropZonePanelLabel: "\u5217\u6A19\u7C64",
  ariaDropZoneColumnComponentDescription: "\u6309 DELETE \u4EE5\u522A\u9664",
  ariaDropZoneColumnValueItemDescription: "\u6309 ENTER \u4EE5\u66F4\u6539\u805A\u5408\u985E\u578B",
  ariaDropZoneColumnGroupItemDescription: "\u6309 ENTER \u4EE5\u6392\u5E8F",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u7684 ",
  ariaDropZoneColumnComponentSortAscending: "\u5347\u5E8F",
  ariaDropZoneColumnComponentSortDescending: "\u964D\u5E8F",
  ariaLabelDialog: "\u5C0D\u8A71\u6846",
  ariaLabelColumnMenu: "\u5217\u9078\u55AE",
  ariaLabelColumnFilter: "\u5217\u7BE9\u9078",
  ariaLabelCellEditor: "\u5132\u5B58\u683C\u7DE8\u8F2F\u5668",
  ariaLabelSelectField: "\u9078\u64C7\u6B04\u4F4D",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u8C50\u5BCC\u9078\u64C7\u6B04\u4F4D",
  ariaLabelRichSelectToggleSelection: "\u6309\u7A7A\u683C\u5207\u63DB\u9078\u64C7",
  ariaLabelRichSelectDeselectAllItems: "\u6309Delete\u9375\u53D6\u6D88\u9078\u64C7\u6240\u6709\u9805\u76EE",
  ariaLabelRichSelectDeleteSelection: "\u6309Delete\u9375\u53D6\u6D88\u9078\u64C7\u9805\u76EE",
  ariaLabelTooltip: "\u5DE5\u5177\u63D0\u793A",
  ariaLabelContextMenu: "\u4E0A\u4E0B\u6587\u9078\u55AE",
  ariaLabelSubMenu: "\u5B50\u9078\u55AE",
  ariaLabelAggregationFunction: "\u805A\u5408\u51FD\u6578",
  ariaLabelAdvancedFilterAutocomplete: "\u9AD8\u7D1A\u904E\u6FFE\u81EA\u52D5\u5B8C\u6210",
  ariaLabelAdvancedFilterBuilderAddField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u6DFB\u52A0\u6B04\u4F4D",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u5217\u9078\u64C7\u6B04\u4F4D",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u9078\u9805\u9078\u64C7\u6B04\u4F4D",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u9023\u63A5\u64CD\u4F5C\u7B26\u9078\u64C7\u6B04\u4F4D",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u6B04\u4F4D\u6E05\u55AE",
  ariaFilterPanelList: "\u7BE9\u9078\u6E05\u55AE",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u771F",
  false: "\u5047",
  invalidDate: "\u7121\u6548\u65E5\u671F",
  invalidNumber: "\u7121\u6548\u6578\u5B57",
  january: "\u4E00\u6708",
  february: "\u4E8C\u6708",
  march: "\u4E09\u6708",
  april: "\u56DB\u6708",
  may: "\u4E94\u6708",
  june: "\u516D\u6708",
  july: "\u4E03\u6708",
  august: "\u516B\u6708",
  september: "\u4E5D\u6708",
  october: "\u5341\u6708",
  november: "\u5341\u4E00\u6708",
  december: "\u5341\u4E8C\u6708",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS \u4E0A\u5348/\u4E0B\u5348"
};

// community-modules/locale/src/zh-TW.ts
var AG_GRID_LOCALE_TW = {
  // Set Filter
  selectAll: "(\u5168\u9078)",
  selectAllSearchResults: "(\u5168\u9078\u641C\u5C0B\u7D50\u679C)",
  addCurrentSelectionToFilter: "\u5C07\u7576\u524D\u9078\u64C7\u65B0\u589E\u5230\u7BE9\u9078\u689D\u4EF6\u4E2D",
  searchOoo: "\u641C\u5C0B...",
  blanks: "(\u7A7A\u767D)",
  noMatches: "\u7121\u5339\u914D\u9805",
  // Number Filter & Text Filter
  filterOoo: "\u7BE9\u9078...",
  equals: "\u7B49\u65BC",
  notEqual: "\u4E0D\u7B49\u65BC",
  blank: "\u7A7A\u767D",
  notBlank: "\u975E\u7A7A\u767D",
  empty: "\u9078\u64C7\u4E00\u500B",
  // Number Filter
  lessThan: "\u5C0F\u65BC",
  greaterThan: "\u5927\u65BC",
  lessThanOrEqual: "\u5C0F\u65BC\u6216\u7B49\u65BC",
  greaterThanOrEqual: "\u5927\u65BC\u6216\u7B49\u65BC",
  inRange: "\u4ECB\u65BC",
  inRangeStart: "\u5F9E",
  inRangeEnd: "\u5230",
  // Text Filter
  contains: "\u5305\u542B",
  notContains: "\u4E0D\u5305\u542B",
  startsWith: "\u958B\u59CB\u65BC",
  endsWith: "\u7D50\u675F\u65BC",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u4E4B\u524D",
  after: "\u4E4B\u5F8C",
  // Filter Conditions
  andCondition: "\u548C",
  orCondition: "\u6216",
  // Filter Buttons
  applyFilter: "\u61C9\u7528",
  resetFilter: "\u91CD\u7F6E",
  clearFilter: "\u6E05\u9664",
  cancelFilter: "\u53D6\u6D88",
  // Filter Titles
  textFilter: "\u6587\u5B57\u7BE9\u9078",
  numberFilter: "\u6578\u5B57\u7BE9\u9078",
  dateFilter: "\u65E5\u671F\u7BE9\u9078",
  setFilter: "\u96C6\u5408\u7BE9\u9078",
  // Group Column Filter
  groupFilterSelect: "\u9078\u64C7\u5B57\u6BB5\uFF1A",
  // Advanced Filter
  advancedFilterContains: "\u5305\u542B",
  advancedFilterNotContains: "\u4E0D\u5305\u542B",
  advancedFilterTextEquals: "\u7B49\u65BC",
  advancedFilterTextNotEqual: "\u4E0D\u7B49\u65BC",
  advancedFilterStartsWith: "\u958B\u982D\u662F",
  advancedFilterEndsWith: "\u7D50\u5C3E\u662F",
  advancedFilterBlank: "\u70BA\u7A7A\u767D",
  advancedFilterNotBlank: "\u4E0D\u70BA\u7A7A\u767D",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u70BA\u771F",
  advancedFilterFalse: "\u70BA\u5047",
  advancedFilterAnd: "\u548C",
  advancedFilterOr: "\u6216",
  advancedFilterApply: "\u61C9\u7528",
  advancedFilterBuilder: "\u69CB\u5EFA\u5668",
  advancedFilterValidationMissingColumn: "\u7F3A\u5C11\u6B04\u4F4D",
  advancedFilterValidationMissingOption: "\u7F3A\u5C11\u9078\u9805",
  advancedFilterValidationMissingValue: "\u7F3A\u5C11\u503C",
  advancedFilterValidationInvalidColumn: "\u627E\u4E0D\u5230\u6B04\u4F4D",
  advancedFilterValidationInvalidOption: "\u627E\u4E0D\u5230\u9078\u9805",
  advancedFilterValidationMissingQuote: "\u503C\u7F3A\u5C11\u7D50\u5C3E\u5F15\u865F",
  advancedFilterValidationNotANumber: "\u503C\u4E0D\u662F\u6578\u5B57",
  advancedFilterValidationInvalidDate: "\u503C\u4E0D\u662F\u6709\u6548\u7684\u65E5\u671F",
  advancedFilterValidationMissingCondition: "\u7F3A\u5C11\u689D\u4EF6",
  advancedFilterValidationJoinOperatorMismatch: "\u689D\u4EF6\u5167\u7684\u9023\u63A5\u64CD\u4F5C\u7B26\u5FC5\u9808\u4E00\u81F4",
  advancedFilterValidationInvalidJoinOperator: "\u627E\u4E0D\u5230\u9023\u63A5\u64CD\u4F5C\u7B26",
  advancedFilterValidationMissingEndBracket: "\u7F3A\u5C11\u7D50\u5C3E\u62EC\u865F",
  advancedFilterValidationExtraEndBracket: "\u7D50\u5C3E\u62EC\u865F\u904E\u591A",
  advancedFilterValidationMessage: "\u8868\u9054\u5F0F\u6709\u932F\u8AA4\u3002${variable} - ${variable}\u3002",
  advancedFilterValidationMessageAtEnd: "\u8868\u9054\u5F0F\u6709\u932F\u8AA4\u3002${variable} \u5728\u8868\u9054\u5F0F\u7D50\u5C3E\u3002",
  advancedFilterBuilderTitle: "\u9032\u968E\u7BE9\u9078",
  advancedFilterBuilderApply: "\u61C9\u7528",
  advancedFilterBuilderCancel: "\u53D6\u6D88",
  advancedFilterBuilderAddButtonTooltip: "\u6DFB\u52A0\u7BE9\u9078\u6216\u7FA4\u7D44",
  advancedFilterBuilderRemoveButtonTooltip: "\u79FB\u9664",
  advancedFilterBuilderMoveUpButtonTooltip: "\u4E0A\u79FB",
  advancedFilterBuilderMoveDownButtonTooltip: "\u4E0B\u79FB",
  advancedFilterBuilderAddJoin: "\u6DFB\u52A0\u7FA4\u7D44",
  advancedFilterBuilderAddCondition: "\u6DFB\u52A0\u7BE9\u9078",
  advancedFilterBuilderSelectColumn: "\u9078\u64C7\u6B04\u4F4D",
  advancedFilterBuilderSelectOption: "\u9078\u64C7\u9078\u9805",
  advancedFilterBuilderEnterValue: "\u8F38\u5165\u503C...",
  advancedFilterBuilderValidationAlreadyApplied: "\u7576\u524D\u7BE9\u9078\u5DF2\u61C9\u7528\u3002",
  advancedFilterBuilderValidationIncomplete: "\u4E26\u975E\u6240\u6709\u689D\u4EF6\u90FD\u5DF2\u5B8C\u6210\u3002",
  advancedFilterBuilderValidationSelectColumn: "\u5FC5\u9808\u9078\u64C7\u4E00\u500B\u6B04\u4F4D\u3002",
  advancedFilterBuilderValidationSelectOption: "\u5FC5\u9808\u9078\u64C7\u4E00\u500B\u9078\u9805\u3002",
  advancedFilterBuilderValidationEnterValue: "\u5FC5\u9808\u8F38\u5165\u4E00\u500B\u503C\u3002",
  // Side Bar
  columns: "\u6B04\u4F4D",
  filters: "\u7BE9\u9078\u5668",
  // columns tool panel
  pivotMode: "\u6A1E\u7D10\u5206\u6790\u6A21\u5F0F",
  groups: "\u884C\u5206\u7D44",
  rowGroupColumnsEmptyMessage: "\u62D6\u52D5\u5230\u6B64\u8655\u8A2D\u7F6E\u884C\u5206\u7D44",
  values: "\u6578\u503C",
  valueColumnsEmptyMessage: "\u62D6\u52D5\u5230\u6B64\u8655\u805A\u5408",
  pivots: "\u6B04\u4F4D\u6A19\u7C64",
  pivotColumnsEmptyMessage: "\u62D6\u52D5\u5230\u6B64\u8655\u8A2D\u7F6E\u6B04\u4F4D\u6A19\u7C64",
  // Header of the Default Group Column
  group: "\u7FA4\u7D44",
  // Row Drag
  rowDragRow: "\u5217",
  rowDragRows: "\u5217",
  // Other
  loadingOoo: "\u52A0\u8F09\u4E2D...",
  loadingError: "\u932F\u8AA4",
  noRowsToShow: "\u7121\u986F\u793A\u884C",
  enabled: "\u5DF2\u555F\u7528",
  // Menu
  pinColumn: "\u56FA\u5B9A\u6B04\u4F4D",
  pinLeft: "\u56FA\u5B9A\u5728\u5DE6\u5074",
  pinRight: "\u56FA\u5B9A\u5728\u53F3\u5074",
  noPin: "\u53D6\u6D88\u56FA\u5B9A",
  valueAggregation: "\u503C\u805A\u5408",
  noAggregation: "\u7121",
  autosizeThisColumn: "\u81EA\u9069\u61C9\u5217\u5BEC",
  autosizeAllColumns: "\u81EA\u9069\u61C9\u6240\u6709\u5217\u5BEC",
  groupBy: "\u4F9D\u6B64\u5206\u7D44",
  ungroupBy: "\u53D6\u6D88\u5206\u7D44",
  ungroupAll: "\u53D6\u6D88\u6240\u6709\u5206\u7D44",
  addToValues: "\u6DFB\u52A0 ${variable} \u5230\u503C",
  removeFromValues: "\u5F9E\u503C\u4E2D\u79FB\u9664 ${variable}",
  addToLabels: "\u6DFB\u52A0 ${variable} \u5230\u6A19\u7C64",
  removeFromLabels: "\u5F9E\u6A19\u7C64\u4E2D\u79FB\u9664 ${variable}",
  resetColumns: "\u91CD\u8A2D\u5217",
  expandAll: "\u5C55\u958B\u6240\u6709\u5206\u7D44",
  collapseAll: "\u95DC\u9589\u6240\u6709\u5206\u7D44",
  copy: "\u8907\u88FD",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u8907\u88FD\u5305\u62EC\u6A19\u984C",
  copyWithGroupHeaders: "\u8907\u88FD\u5305\u62EC\u5206\u7D44\u6A19\u984C",
  cut: "\u526A\u4E0B",
  paste: "\u8CBC\u4E0A",
  ctrlV: "Ctrl+V",
  export: "\u532F\u51FA",
  csvExport: "\u532F\u51FA CSV",
  excelExport: "\u532F\u51FA Excel",
  columnFilter: "\u5217\u904E\u6FFE\u5668",
  columnChooser: "\u9078\u64C7\u5217",
  chooseColumns: "\u9078\u64C7\u5217",
  sortAscending: "\u5347\u5E8F\u6392\u5217",
  sortDescending: "\u964D\u5E8F\u6392\u5217",
  sortUnSort: "\u6E05\u9664\u6392\u5217",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u7E3D\u548C",
  first: "\u7B2C\u4E00\u500B",
  last: "\u6700\u5F8C\u4E00\u500B",
  min: "\u6700\u5C0F\u503C",
  max: "\u6700\u5927\u503C",
  none: "\u7121",
  count: "\u6578\u91CF",
  avg: "\u5E73\u5747\u503C",
  filteredRows: "\u5DF2\u7BE9\u9078",
  selectedRows: "\u5DF2\u9078\u64C7",
  totalRows: "\u7E3D\u884C\u6578",
  totalAndFilteredRows: "\u884C",
  more: "\u66F4\u591A",
  to: "\u81F3",
  of: "\u7684",
  page: "\u9801",
  pageLastRowUnknown: "?",
  nextPage: "\u4E0B\u4E00\u9801",
  lastPage: "\u6700\u5F8C\u4E00\u9801",
  firstPage: "\u7B2C\u4E00\u9801",
  previousPage: "\u4E0A\u4E00\u9801",
  pageSizeSelectorLabel: "\u9801\u5927\u5C0F\uFF1A",
  footerTotal: "\u7E3D\u6578",
  // Pivoting
  pivotColumnGroupTotals: "\u7E3D\u8A08",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u6A1E\u8EF8\u5716\u8207\u6A1E\u8EF8\u6A21\u5F0F",
  pivotChart: "\u6A1E\u8EF8\u5716",
  chartRange: "\u5716\u8868\u7BC4\u570D",
  columnChart: "\u5217",
  groupedColumn: "\u5206\u7D44",
  stackedColumn: "\u5806\u758A",
  normalizedColumn: "100% \u5806\u758A",
  barChart: "\u689D\u5F62",
  groupedBar: "\u5206\u7D44",
  stackedBar: "\u5806\u758A",
  normalizedBar: "100% \u5806\u758A",
  pieChart: "\u5713\u9905\u5716",
  pie: "\u5713\u9905\u5716",
  donut: "\u751C\u751C\u5708\u5716",
  line: "\u6298\u7DDA\u5716",
  xyChart: "X Y (\u6563\u9EDE\u5716)",
  scatter: "\u6563\u9EDE\u5716",
  bubble: "\u6C23\u6CE1\u5716",
  areaChart: "\u5340\u57DF\u5716",
  area: "\u5340\u57DF\u5716",
  stackedArea: "\u5806\u758A",
  normalizedArea: "100% \u5806\u758A",
  histogramChart: "\u76F4\u65B9\u5716",
  polarChart: "\u6975\u5730\u5716",
  radarLine: "\u96F7\u9054\u7DDA",
  radarArea: "\u96F7\u9054\u9762",
  nightingale: "\u5357\u4E01\u683C\u723E\u73AB\u7470\u5716",
  radialColumn: "\u8F3B\u5C04\u5217\u67F1",
  radialBar: "\u8F3B\u5C04\u689D\u5F62",
  statisticalChart: "\u7D71\u8A08\u5716",
  boxPlot: "\u7BB1\u578B\u5716",
  rangeBar: "\u7BC4\u570D\u689D\u5F62\u5716",
  rangeArea: "\u7BC4\u570D\u5340\u57DF\u5716",
  hierarchicalChart: "\u5C64\u6B21\u5716",
  treemap: "\u6A39\u72C0\u5716",
  sunburst: "\u65ED\u65E5\u5716",
  specializedChart: "\u5C08\u9580\u5716\u8868",
  waterfall: "\u7011\u5E03\u5716",
  heatmap: "\u71B1\u5716",
  combinationChart: "\u7D44\u5408\u5716",
  columnLineCombo: "\u5217\u8207\u6298\u7DDA",
  AreaColumnCombo: "\u5340\u57DF\u8207\u5217",
  // Charts
  pivotChartTitle: "\u6A1E\u7D10\u5716",
  rangeChartTitle: "\u7BC4\u570D\u5716",
  settings: "\u5716\u8868",
  data: "\u8A2D\u5B9A",
  format: "\u81EA\u8A02",
  categories: "\u985E\u5225",
  defaultCategory: "(\u7121)",
  series: "\u7CFB\u5217",
  switchCategorySeries: "\u5207\u63DB\u985E\u5225/\u7CFB\u5217",
  categoryValues: "\u985E\u5225\u503C",
  seriesLabels: "\u7CFB\u5217\u6A19\u7C64",
  aggregate: "\u5F59\u7E3D",
  xyValues: "X Y \u503C",
  paired: "\u914D\u5C0D\u6A21\u5F0F",
  axis: "\u8EF8",
  xAxis: "\u6C34\u5E73\u8EF8",
  yAxis: "\u5782\u76F4\u8EF8",
  polarAxis: "\u6975\u8EF8",
  radiusAxis: "\u534A\u5F91\u8EF8",
  navigator: "\u5C0E\u89BD\u5668",
  zoom: "\u7E2E\u653E",
  animation: "\u52D5\u756B",
  crosshair: "\u6E96\u661F",
  color: "\u984F\u8272",
  thickness: "\u539A\u5EA6",
  preferredLength: "\u9996\u9078\u9577\u5EA6",
  xType: "X \u985E\u578B",
  axisType: "\u8EF8\u985E\u578B",
  automatic: "\u81EA\u52D5",
  category: "\u985E\u5225",
  number: "\u6578\u5B57",
  time: "\u6642\u9593",
  timeFormat: "\u6642\u9593\u683C\u5F0F",
  autoRotate: "\u81EA\u52D5\u65CB\u8F49",
  labelRotation: "\u65CB\u8F49",
  circle: "\u5713\u5F62",
  polygon: "\u591A\u908A\u5F62",
  square: "\u65B9\u5F62",
  cross: "\u5341\u5B57\u5F62",
  diamond: "\u83F1\u5F62",
  plus: "\u52A0\u865F",
  triangle: "\u4E09\u89D2\u5F62",
  heart: "\u5FC3\u5F62",
  orientation: "\u65B9\u5411",
  fixed: "\u56FA\u5B9A",
  parallel: "\u5E73\u884C",
  perpendicular: "\u5782\u76F4",
  radiusAxisPosition: "\u4F4D\u7F6E",
  ticks: "\u523B\u5EA6",
  gridLines: "\u7DB2\u683C\u7DDA",
  width: "\u5BEC\u5EA6",
  height: "\u9AD8\u5EA6",
  length: "\u9577\u5EA6",
  padding: "\u586B\u5145",
  spacing: "\u9593\u8DDD",
  chartStyle: "\u5716\u8868\u6A23\u5F0F",
  title: "\u6A19\u984C",
  chartTitles: "\u6A19\u984C",
  chartTitle: "\u5716\u8868\u6A19\u984C",
  chartSubtitle: "\u526F\u6A19\u984C",
  horizontalAxisTitle: "\u6C34\u5E73\u8EF8\u6A19\u984C",
  verticalAxisTitle: "\u5782\u76F4\u8EF8\u6A19\u984C",
  polarAxisTitle: "\u6975\u8EF8\u6A19\u984C",
  titlePlaceholder: "\u5716\u8868\u6A19\u984C",
  background: "\u80CC\u666F",
  font: "\u5B57\u9AD4",
  weight: "\u7C97\u7D30",
  top: "\u4E0A",
  right: "\u53F3",
  bottom: "\u4E0B",
  left: "\u5DE6",
  labels: "\u6A19\u7C64",
  calloutLabels: "\u7A81\u986F\u6A19\u7C64",
  sectorLabels: "\u5340\u6BB5\u6A19\u7C64",
  positionRatio: "\u4F4D\u7F6E\u6BD4\u4F8B",
  size: "\u5927\u5C0F",
  shape: "\u5F62\u72C0",
  minSize: "\u6700\u5C0F\u5927\u5C0F",
  maxSize: "\u6700\u5927\u5927\u5C0F",
  legend: "\u5716\u4F8B",
  position: "\u4F4D\u7F6E",
  markerSize: "\u6A19\u8A18\u5927\u5C0F",
  markerStroke: "\u6A19\u8A18\u63CF\u908A",
  markerPadding: "\u6A19\u8A18\u586B\u5145",
  itemSpacing: "\u9805\u76EE\u9593\u8DDD",
  itemPaddingX: "\u9805\u76EE\u586B\u5145 X",
  itemPaddingY: "\u9805\u76EE\u586B\u5145 Y",
  layoutHorizontalSpacing: "\u6C34\u5E73\u9593\u8DDD",
  layoutVerticalSpacing: "\u5782\u76F4\u9593\u8DDD",
  strokeWidth: "\u63CF\u908A\u5BEC\u5EA6",
  offset: "\u504F\u79FB",
  offsets: "\u504F\u79FB\u503C",
  tooltips: "\u5DE5\u5177\u63D0\u793A",
  callout: "\u7A81\u986F",
  markers: "\u6A19\u8A18",
  shadow: "\u9670\u5F71",
  blur: "\u6A21\u7CCA",
  xOffset: "X \u504F\u79FB",
  yOffset: "Y \u504F\u79FB",
  lineWidth: "\u7DDA\u5BEC",
  lineDash: "\u865B\u7DDA",
  lineDashOffset: "\u865B\u7DDA\u504F\u79FB",
  scrollingZoom: "\u6EFE\u52D5",
  scrollingStep: "\u6EFE\u52D5\u6B65\u4F10",
  selectingZoom: "\u9078\u64C7",
  durationMillis: "\u6301\u7E8C\u6642\u9593 (\u6BEB\u79D2)",
  crosshairLabel: "\u6A19\u7C64",
  crosshairSnap: "\u5C0D\u9F4A\u7BC0\u9EDE",
  normal: "\u6B63\u5E38",
  bold: "\u7C97\u9AD4",
  italic: "\u659C\u9AD4",
  boldItalic: "\u7C97\u659C\u9AD4",
  predefined: "\u9810\u8A2D",
  fillOpacity: "\u586B\u5145\u4E0D\u900F\u660E\u5EA6",
  strokeColor: "\u7DDA\u689D\u984F\u8272",
  strokeOpacity: "\u7DDA\u689D\u900F\u660E\u5EA6",
  miniChart: "\u8FF7\u4F60\u5716\u8868",
  histogramBinCount: "\u67F1\u6578",
  connectorLine: "\u9023\u63A5\u7DDA",
  seriesItems: "\u7CFB\u5217\u9805\u76EE",
  seriesItemType: "\u9805\u76EE\u985E\u578B",
  seriesItemPositive: "\u6B63\u6578",
  seriesItemNegative: "\u8CA0\u6578",
  seriesItemLabels: "\u9805\u76EE\u6A19\u7C64",
  columnGroup: "\u67F1\u5F62\u5716",
  barGroup: "\u689D\u5F62\u5716",
  pieGroup: "\u5713\u9905\u5716",
  lineGroup: "\u6298\u7DDA\u5716",
  scatterGroup: "X Y (\u6563\u9EDE\u5716)",
  areaGroup: "\u5340\u57DF\u5716",
  polarGroup: "\u6975\u5730\u5716",
  statisticalGroup: "\u7D71\u8A08\u5716",
  hierarchicalGroup: "\u5C64\u6B21\u5716",
  specializedGroup: "\u5C08\u696D\u5716",
  combinationGroup: "\u7D44\u5408\u5716",
  groupedColumnTooltip: "\u5206\u7D44",
  stackedColumnTooltip: "\u5806\u758A",
  normalizedColumnTooltip: "100% \u5806\u758A",
  groupedBarTooltip: "\u5206\u7D44",
  stackedBarTooltip: "\u5806\u758A",
  normalizedBarTooltip: "100% \u5806\u758A",
  pieTooltip: "\u5713\u9905\u5716",
  donutTooltip: "\u751C\u751C\u5708\u5716",
  lineTooltip: "\u6298\u7DDA\u5716",
  groupedAreaTooltip: "\u9762\u7A4D\u5716",
  stackedAreaTooltip: "\u5806\u7A4D\u9762\u7A4D\u5716",
  normalizedAreaTooltip: "100% \u5806\u7A4D\u9762\u7A4D\u5716",
  scatterTooltip: "\u6563\u9EDE\u5716",
  bubbleTooltip: "\u6C23\u6CE1\u5716",
  histogramTooltip: "\u76F4\u65B9\u5716",
  radialColumnTooltip: "\u96F7\u9054\u67F1\u5F62\u5716",
  radialBarTooltip: "\u96F7\u9054\u689D\u5F62\u5716",
  radarLineTooltip: "\u96F7\u9054\u6298\u7DDA\u5716",
  radarAreaTooltip: "\u96F7\u9054\u9762\u7A4D\u5716",
  nightingaleTooltip: "\u591C\u9DAF\u5716",
  rangeBarTooltip: "\u7BC4\u570D\u689D\u5F62\u5716",
  rangeAreaTooltip: "\u7BC4\u570D\u5340\u57DF\u5716",
  boxPlotTooltip: "\u7BB1\u7DDA\u5716",
  treemapTooltip: "\u6A39\u72C0\u5716",
  sunburstTooltip: "\u65ED\u65E5\u5716",
  waterfallTooltip: "\u7011\u5E03\u5716",
  heatmapTooltip: "\u71B1\u5716",
  columnLineComboTooltip: "\u67F1\u5F62 & \u6298\u7DDA\u5716",
  areaColumnComboTooltip: "\u5340\u57DF & \u67F1\u5F62\u5716",
  customComboTooltip: "\u81EA\u8A02\u7D44\u5408\u5716",
  innerRadius: "\u5167\u534A\u5F91",
  startAngle: "\u8D77\u59CB\u89D2\u5EA6",
  endAngle: "\u7D42\u6B62\u89D2\u5EA6",
  reverseDirection: "\u9006\u5411",
  groupPadding: "\u7D44\u586B\u5145\u91CF",
  seriesPadding: "\u7CFB\u5217\u586B\u5145\u91CF",
  tile: "\u5E73\u92EA",
  whisker: "\u9B1A\u72C0\u5716",
  cap: "\u5E3D\u7AEF",
  capLengthRatio: "\u5E3D\u7AEF\u9577\u5EA6\u6BD4",
  labelPlacement: "\u6A19\u7C64\u653E\u7F6E",
  inside: "\u5167\u90E8",
  outside: "\u5916\u90E8",
  noDataToChart: "\u6C92\u6709\u53EF\u4F5C\u5716\u7684\u6578\u64DA\u3002",
  pivotChartRequiresPivotMode: "\u6A1E\u7D10\u5716\u9700\u8981\u555F\u7528\u6A1E\u7D10\u6A21\u5F0F\u3002",
  chartSettingsToolbarTooltip: "\u83DC\u55AE",
  chartLinkToolbarTooltip: "\u93C8\u63A5\u5230\u683C\u7DDA",
  chartUnlinkToolbarTooltip: "\u5F9E\u683C\u7DDA\u53D6\u6D88\u93C8\u63A5",
  chartDownloadToolbarTooltip: "\u4E0B\u8F09\u5716\u8868",
  chartMenuToolbarTooltip: "\u83DC\u55AE",
  chartEdit: "\u7DE8\u8F2F\u5716\u8868",
  chartAdvancedSettings: "\u9AD8\u7D1A\u8A2D\u7F6E",
  chartLink: "\u93C8\u63A5\u5230\u683C\u7DDA",
  chartUnlink: "\u5F9E\u683C\u7DDA\u53D6\u6D88\u93C8\u63A5",
  chartDownload: "\u4E0B\u8F09\u5716\u8868",
  histogramFrequency: "\u983B\u7387",
  seriesChartType: "\u7CFB\u5217\u5716\u8868\u985E\u578B",
  seriesType: "\u7CFB\u5217\u985E\u578B",
  secondaryAxis: "\u6B21\u8EF8",
  seriesAdd: "\u6DFB\u52A0\u7CFB\u5217",
  categoryAdd: "\u6DFB\u52A0\u985E\u5225",
  bar: "\u689D\u5F62\u5716",
  column: "\u67F1\u5F62\u5716",
  histogram: "\u76F4\u65B9\u5716",
  advancedSettings: "\u9AD8\u7D1A\u8A2D\u7F6E",
  direction: "\u65B9\u5411",
  horizontal: "\u6C34\u5E73",
  vertical: "\u5782\u76F4",
  seriesGroupType: "\u7D44\u985E\u578B",
  groupedSeriesGroupType: "\u5206\u7D44",
  stackedSeriesGroupType: "\u5806\u758A",
  normalizedSeriesGroupType: "100% \u5806\u758A",
  legendEnabled: "\u555F\u7528",
  invalidColor: "\u984F\u8272\u503C\u7121\u6548",
  groupedColumnFull: "\u5206\u7D44\u67F1\u5F62\u5716",
  stackedColumnFull: "\u5806\u758A\u67F1\u5F62\u5716",
  normalizedColumnFull: "100% \u5806\u758A\u67F1\u5F62\u5716",
  groupedBarFull: "\u5206\u7D44\u689D\u5F62\u5716",
  stackedBarFull: "\u5806\u758A\u689D\u5F62\u5716",
  normalizedBarFull: "100% \u5806\u758A\u689D\u5F62\u5716",
  stackedAreaFull: "\u5806\u7A4D\u9762\u7A4D\u5716",
  normalizedAreaFull: "100% \u5806\u7A4D\u9762\u7A4D\u5716",
  customCombo: "\u81EA\u8A02\u7D44\u5408\u5716",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}\u3002\u7D1A\u5225 ${variable}\u3002\u6309 ENTER \u9375\u7DE8\u8F2F\u3002",
  ariaAdvancedFilterBuilderItemValidation: "${variable}\u3002\u7D1A\u5225 ${variable}\u3002${variable} \u6309 ENTER \u9375\u7DE8\u8F2F\u3002",
  ariaAdvancedFilterBuilderList: "\u9032\u968E\u7BE9\u9078\u5668\u69CB\u5EFA\u5668\u5217\u8868",
  ariaAdvancedFilterBuilderFilterItem: "\u7BE9\u9078\u689D\u4EF6",
  ariaAdvancedFilterBuilderGroupItem: "\u7BE9\u9078\u7D44",
  ariaAdvancedFilterBuilderColumn: "\u6B04",
  ariaAdvancedFilterBuilderOption: "\u9078\u9805",
  ariaAdvancedFilterBuilderValueP: "\u503C",
  ariaAdvancedFilterBuilderJoinOperator: "\u9023\u63A5\u904B\u7B97\u5B50",
  ariaAdvancedFilterInput: "\u9032\u968E\u7BE9\u9078\u8F38\u5165",
  ariaChecked: "\u5DF2\u52FE\u9078",
  ariaColumn: "\u6B04",
  ariaColumnGroup: "\u6B04\u7D44",
  ariaColumnFiltered: "\u6B04\u5DF2\u7BE9\u9078",
  ariaColumnSelectAll: "\u5207\u63DB\u9078\u53D6\u6240\u6709\u6B04",
  ariaDateFilterInput: "\u65E5\u671F\u7BE9\u9078\u8F38\u5165",
  ariaDefaultListName: "\u5217\u8868",
  ariaFilterColumnsInput: "\u7BE9\u9078\u6B04\u8F38\u5165",
  ariaFilterFromValue: "\u5F9E\u503C\u7BE9\u9078",
  ariaFilterInput: "\u7BE9\u9078\u8F38\u5165",
  ariaFilterList: "\u7BE9\u9078\u5217\u8868",
  ariaFilterToValue: "\u7BE9\u9078\u5230\u503C",
  ariaFilterValue: "\u7BE9\u9078\u503C",
  ariaFilterMenuOpen: "\u6253\u958B\u7BE9\u9078\u5668\u9078\u55AE",
  ariaFilteringOperator: "\u7BE9\u9078\u904B\u7B97\u5B50",
  ariaHidden: "\u96B1\u85CF",
  ariaIndeterminate: "\u4E0D\u78BA\u5B9A",
  ariaInputEditor: "\u8F38\u5165\u7DE8\u8F2F\u5668",
  ariaMenuColumn: "\u6309 ALT + DOWN \u9375\u6253\u958B\u6B04\u9078\u55AE",
  ariaFilterColumn: "\u6309 CTRL + ENTER \u9375\u6253\u958B\u7BE9\u9078",
  ariaRowDeselect: "\u6309 SPACE \u9375\u53D6\u6D88\u9078\u53D6\u6B64\u884C",
  ariaRowSelectAll: "\u6309 SPACE \u9375\u5207\u63DB\u6240\u6709\u884C\u7684\u9078\u64C7",
  ariaRowToggleSelection: "\u6309 SPACE \u9375\u5207\u63DB\u884C\u7684\u9078\u64C7",
  ariaRowSelect: "\u6309 SPACE \u9375\u9078\u53D6\u6B64\u884C",
  ariaRowSelectionDisabled: "\u6B64\u884C\u5DF2\u7981\u7528\u884C\u9078\u64C7",
  ariaSearch: "\u641C\u5C0B",
  ariaSortableColumn: "\u6309 ENTER \u9375\u6392\u5E8F",
  ariaToggleVisibility: "\u6309 SPACE \u9375\u5207\u63DB\u53EF\u898B\u6027",
  ariaToggleCellValue: "\u6309 SPACE \u9375\u5207\u63DB\u55AE\u5143\u683C\u503C",
  ariaUnchecked: "\u672A\u52FE\u9078",
  ariaVisible: "\u53EF\u898B",
  ariaSearchFilterValues: "\u641C\u5C0B\u7BE9\u9078\u503C",
  ariaPageSizeSelectorLabel: "\u6BCF\u9801\u5927\u5C0F",
  ariaChartMenuClose: "\u95DC\u9589\u5716\u8868\u7DE8\u8F2F\u9078\u55AE",
  ariaChartSelected: "\u5DF2\u9078\u64C7",
  ariaSkeletonCellLoadingFailed: "\u884C\u52A0\u8F09\u5931\u6557",
  ariaSkeletonCellLoading: "\u884C\u6578\u64DA\u52A0\u8F09\u4E2D",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u884C\u7FA4\u7D44",
  ariaValuesDropZonePanelLabel: "\u6578\u503C",
  ariaPivotDropZonePanelLabel: "\u5217\u6A19\u7C64",
  ariaDropZoneColumnComponentDescription: "\u6309 DELETE \u522A\u9664",
  ariaDropZoneColumnValueItemDescription: "\u6309 ENTER \u66F4\u6539\u805A\u5408\u985E\u578B",
  ariaDropZoneColumnGroupItemDescription: "\u6309 ENTER \u6392\u5E8F",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u7684 ",
  ariaDropZoneColumnComponentSortAscending: "\u5347\u5E8F",
  ariaDropZoneColumnComponentSortDescending: "\u964D\u5E8F",
  ariaLabelDialog: "\u5C0D\u8A71\u6846",
  ariaLabelColumnMenu: "\u6B04\u4F4D\u9078\u55AE",
  ariaLabelColumnFilter: "\u6B04\u4F4D\u7BE9\u9078\u5668",
  ariaLabelCellEditor: "\u5132\u5B58\u683C\u7DE8\u8F2F\u5668",
  ariaLabelSelectField: "\u9078\u64C7\u6B04\u4F4D",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u8C50\u5BCC\u9078\u64C7\u5B57\u6BB5",
  ariaLabelRichSelectToggleSelection: "\u6309\u4E0B\u7A7A\u683C\u9375\u4EE5\u5207\u63DB\u9078\u64C7",
  ariaLabelRichSelectDeselectAllItems: "\u6309\u4E0B\u522A\u9664\u9375\u4EE5\u53D6\u6D88\u9078\u64C7\u6240\u6709\u9805\u76EE",
  ariaLabelRichSelectDeleteSelection: "\u6309\u4E0B\u522A\u9664\u9375\u4EE5\u53D6\u6D88\u9078\u64C7\u9805\u76EE",
  ariaLabelTooltip: "\u5DE5\u5177\u63D0\u793A",
  ariaLabelContextMenu: "\u4E0A\u4E0B\u6587\u83DC\u55AE",
  ariaLabelSubMenu: "\u5B50\u83DC\u55AE",
  ariaLabelAggregationFunction: "\u805A\u5408\u51FD\u6578",
  ariaLabelAdvancedFilterAutocomplete: "\u9AD8\u7D1A\u904E\u6FFE\u81EA\u52D5\u5B8C\u6210",
  ariaLabelAdvancedFilterBuilderAddField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u6DFB\u52A0\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u5217\u9078\u64C7\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u9078\u9805\u9078\u64C7\u5B57\u6BB5",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u9AD8\u7D1A\u904E\u6FFE\u69CB\u5EFA\u5668\u806F\u63A5\u904B\u7B97\u7B26\u9078\u64C7\u5B57\u6BB5",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u6B04\u4F4D\u5217\u8868",
  ariaFilterPanelList: "\u7BE9\u9078\u5217\u8868",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u771F",
  false: "\u5047",
  invalidDate: "\u7121\u6548\u65E5\u671F",
  invalidNumber: "\u7121\u6548\u6578\u5B57",
  january: "\u4E00\u6708",
  february: "\u4E8C\u6708",
  march: "\u4E09\u6708",
  april: "\u56DB\u6708",
  may: "\u4E94\u6708",
  june: "\u516D\u6708",
  july: "\u4E03\u6708",
  august: "\u516B\u6708",
  september: "\u4E5D\u6708",
  october: "\u5341\u6708",
  november: "\u5341\u4E00\u6708",
  december: "\u5341\u4E8C\u6708",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/es-ES.ts
var AG_GRID_LOCALE_ES = {
  // Set Filter
  selectAll: "(Seleccionar todo)",
  selectAllSearchResults: "(Seleccionar todos los resultados de la b\xFAsqueda)",
  addCurrentSelectionToFilter: "Agregar selecci\xF3n actual al filtro",
  searchOoo: "Buscar...",
  blanks: "(Vac\xEDos)",
  noMatches: "Sin coincidencias",
  // Number Filter & Text Filter
  filterOoo: "Filtro...",
  equals: "Igual a",
  notEqual: "No igual a",
  blank: "En blanco",
  notBlank: "No en blanco",
  empty: "Elige uno",
  // Number Filter
  lessThan: "Menos que",
  greaterThan: "M\xE1s que",
  lessThanOrEqual: "Menos o igual que",
  greaterThanOrEqual: "M\xE1s o igual que",
  inRange: "Entre",
  inRangeStart: "Desde",
  inRangeEnd: "Hasta",
  // Text Filter
  contains: "Contiene",
  notContains: "No contiene",
  startsWith: "Empieza con",
  endsWith: "Termina con",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Antes",
  after: "Despu\xE9s",
  // Filter Conditions
  andCondition: "Y",
  orCondition: "O",
  // Filter Buttons
  applyFilter: "Aplicar",
  resetFilter: "Reiniciar",
  clearFilter: "Borrar",
  cancelFilter: "Cancelar",
  // Filter Titles
  textFilter: "Filtro de Texto",
  numberFilter: "Filtro de N\xFAmeros",
  dateFilter: "Filtro de Fecha",
  setFilter: "Filtro de Conjunto",
  // Group Column Filter
  groupFilterSelect: "Seleccionar campo:",
  // Advanced Filter
  advancedFilterContains: "contiene",
  advancedFilterNotContains: "no contiene",
  advancedFilterTextEquals: "es igual a",
  advancedFilterTextNotEqual: "no es igual a",
  advancedFilterStartsWith: "comienza con",
  advancedFilterEndsWith: "termina con",
  advancedFilterBlank: "est\xE1 en blanco",
  advancedFilterNotBlank: "no est\xE1 en blanco",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "es verdadero",
  advancedFilterFalse: "es falso",
  advancedFilterAnd: "Y",
  advancedFilterOr: "O",
  advancedFilterApply: "Aplicar",
  advancedFilterBuilder: "Constructor",
  advancedFilterValidationMissingColumn: "Falta la columna",
  advancedFilterValidationMissingOption: "Falta la opci\xF3n",
  advancedFilterValidationMissingValue: "Falta el valor",
  advancedFilterValidationInvalidColumn: "Columna no encontrada",
  advancedFilterValidationInvalidOption: "Opci\xF3n no encontrada",
  advancedFilterValidationMissingQuote: "Falta una comilla de cierre",
  advancedFilterValidationNotANumber: "El valor no es un n\xFAmero",
  advancedFilterValidationInvalidDate: "El valor no es una fecha v\xE1lida",
  advancedFilterValidationMissingCondition: "Falta la condici\xF3n",
  advancedFilterValidationJoinOperatorMismatch: "Los operadores de uni\xF3n dentro de una condici\xF3n deben ser los mismos",
  advancedFilterValidationInvalidJoinOperator: "Operador de uni\xF3n no encontrado",
  advancedFilterValidationMissingEndBracket: "Falta par\xE9ntesis de cierre",
  advancedFilterValidationExtraEndBracket: "Demasiados par\xE9ntesis de cierre",
  advancedFilterValidationMessage: "La expresi\xF3n contiene un error. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "La expresi\xF3n contiene un error. ${variable} al final de la expresi\xF3n.",
  advancedFilterBuilderTitle: "Filtro Avanzado",
  advancedFilterBuilderApply: "Aplicar",
  advancedFilterBuilderCancel: "Cancelar",
  advancedFilterBuilderAddButtonTooltip: "A\xF1adir filtro o grupo",
  advancedFilterBuilderRemoveButtonTooltip: "Eliminar",
  advancedFilterBuilderMoveUpButtonTooltip: "Mover arriba",
  advancedFilterBuilderMoveDownButtonTooltip: "Mover abajo",
  advancedFilterBuilderAddJoin: "A\xF1adir Grupo",
  advancedFilterBuilderAddCondition: "A\xF1adir Filtro",
  advancedFilterBuilderSelectColumn: "Selecciona una columna",
  advancedFilterBuilderSelectOption: "Selecciona una opci\xF3n",
  advancedFilterBuilderEnterValue: "Introduce un valor...",
  advancedFilterBuilderValidationAlreadyApplied: "Filtro actual ya aplicado.",
  advancedFilterBuilderValidationIncomplete: "No todas las condiciones est\xE1n completas.",
  advancedFilterBuilderValidationSelectColumn: "Debe seleccionar una columna.",
  advancedFilterBuilderValidationSelectOption: "Debe seleccionar una opci\xF3n.",
  advancedFilterBuilderValidationEnterValue: "Debe introducir un valor.",
  // Side Bar
  columns: "Columnas",
  filters: "Filtros",
  // columns tool panel
  pivotMode: "Modo Pivot",
  groups: "Grupos de Filas",
  rowGroupColumnsEmptyMessage: "Arrastre aqu\xED para establecer los grupos de filas",
  values: "Valores",
  valueColumnsEmptyMessage: "Arrastre aqu\xED para agregar",
  pivots: "Etiquetas de Columna",
  pivotColumnsEmptyMessage: "Arrastre aqu\xED para establecer las etiquetas de columna",
  // Header of the Default Group Column
  group: "Grupo",
  // Row Drag
  rowDragRow: "fila",
  rowDragRows: "filas",
  // Other
  loadingOoo: "Cargando...",
  loadingError: "ERR",
  noRowsToShow: "No hay filas para mostrar",
  enabled: "Habilitado",
  // Menu
  pinColumn: "Fijar Columna",
  pinLeft: "Fijar a la Izquierda",
  pinRight: "Fijar a la Derecha",
  noPin: "No Fijar",
  valueAggregation: "Agregaci\xF3n de Valores",
  noAggregation: "Ninguna",
  autosizeThisColumn: "Autoajustar Esta Columna",
  autosizeAllColumns: "Autoajustar Todas las Columnas",
  groupBy: "Agrupar por",
  ungroupBy: "Desagrupar por",
  ungroupAll: "Desagrupar Todo",
  addToValues: "A\xF1adir ${variable} a valores",
  removeFromValues: "Quitar ${variable} de valores",
  addToLabels: "A\xF1adir ${variable} a etiquetas",
  removeFromLabels: "Quitar ${variable} de etiquetas",
  resetColumns: "Restablecer Columnas",
  expandAll: "Expandir Todos los Grupos de Filas",
  collapseAll: "Cerrar Todos los Grupos de Filas",
  copy: "Copiar",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copiar Con Encabezados",
  copyWithGroupHeaders: "Copiar Con Encabezados de Grupo",
  cut: "Cortar",
  paste: "Pegar",
  ctrlV: "Ctrl+V",
  export: "Exportar",
  csvExport: "Exportar CSV",
  excelExport: "Exportar Excel",
  columnFilter: "Filtrar Columna",
  columnChooser: "Elegir Columnas",
  chooseColumns: "Elegir Columnas",
  sortAscending: "Ordenar Ascendente",
  sortDescending: "Ordenar Descendente",
  sortUnSort: "Limpiar Orden",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Suma",
  first: "Primero",
  last: "\xDAltimo",
  min: "M\xEDnimo",
  max: "M\xE1ximo",
  none: "Ninguno",
  count: "Cuenta",
  avg: "Promedio",
  filteredRows: "Filtrados",
  selectedRows: "Seleccionados",
  totalRows: "Filas Totales",
  totalAndFilteredRows: "Filas",
  more: "M\xE1s",
  to: "a",
  of: "de",
  page: "P\xE1gina",
  pageLastRowUnknown: "?",
  nextPage: "Siguiente P\xE1gina",
  lastPage: "\xDAltima P\xE1gina",
  firstPage: "Primera P\xE1gina",
  previousPage: "P\xE1gina Anterior",
  pageSizeSelectorLabel: "Tama\xF1o de P\xE1gina:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Gr\xE1fico din\xE1mico y modo din\xE1mico",
  pivotChart: "Gr\xE1fico din\xE1mico",
  chartRange: "Rango de gr\xE1fico",
  columnChart: "Columna",
  groupedColumn: "Agrupada",
  stackedColumn: "Apilada",
  normalizedColumn: "100% Apilada",
  barChart: "Barra",
  groupedBar: "Agrupada",
  stackedBar: "Apilada",
  normalizedBar: "100% Apilada",
  pieChart: "Circular",
  pie: "Circular",
  donut: "Rosquilla",
  line: "L\xEDnea",
  xyChart: "X Y (Dispersi\xF3n)",
  scatter: "Dispersi\xF3n",
  bubble: "Burbuja",
  areaChart: "\xC1rea",
  area: "\xC1rea",
  stackedArea: "Apilada",
  normalizedArea: "100% Apilada",
  histogramChart: "Histograma",
  polarChart: "Polar",
  radarLine: "L\xEDnea de radar",
  radarArea: "\xC1rea de radar",
  nightingale: "Nightingale",
  radialColumn: "Columna radial",
  radialBar: "Barra radial",
  statisticalChart: "Estad\xEDstico",
  boxPlot: "Gr\xE1fico de caja",
  rangeBar: "Barra de rango",
  rangeArea: "\xC1rea de rango",
  hierarchicalChart: "Jer\xE1rquico",
  treemap: "Mapa de \xE1rbol",
  sunburst: "Explosi\xF3n solar",
  specializedChart: "Especializado",
  waterfall: "Cascada",
  heatmap: "Mapa de calor",
  combinationChart: "Combinaci\xF3n",
  columnLineCombo: "Columna y l\xEDnea",
  AreaColumnCombo: "\xC1rea y columna",
  // Charts
  pivotChartTitle: "Gr\xE1fico de Pivote",
  rangeChartTitle: "Gr\xE1fico de Intervalo",
  settings: "Gr\xE1fico",
  data: "Configuraci\xF3n",
  format: "Personalizar",
  categories: "Categor\xEDas",
  defaultCategory: "(Ninguna)",
  series: "Series",
  switchCategorySeries: "Cambiar Categor\xEDa / Series",
  categoryValues: "Valores de Categor\xEDa",
  seriesLabels: "Etiquetas de Series",
  aggregate: "Agregado",
  xyValues: "Valores XY",
  paired: "Modo Pareado",
  axis: "Eje",
  xAxis: "Eje Horizontal",
  yAxis: "Eje Vertical",
  polarAxis: "Eje Polar",
  radiusAxis: "Eje de Radio",
  navigator: "Navegador",
  zoom: "Zoom",
  animation: "Animaci\xF3n",
  crosshair: "Ret\xEDcula",
  color: "Color",
  thickness: "Espesor",
  preferredLength: "Longitud Preferida",
  xType: "Tipo X",
  axisType: "Tipo de Eje",
  automatic: "Autom\xE1tico",
  category: "Categor\xEDa",
  number: "N\xFAmero",
  time: "Tiempo",
  timeFormat: "Formato de Tiempo",
  autoRotate: "Rotaci\xF3n Autom\xE1tica",
  labelRotation: "Rotaci\xF3n",
  circle: "C\xEDrculo",
  polygon: "Pol\xEDgono",
  square: "Cuadrado",
  cross: "Cruz",
  diamond: "Diamante",
  plus: "M\xE1s",
  triangle: "Tri\xE1ngulo",
  heart: "Coraz\xF3n",
  orientation: "Orientaci\xF3n",
  fixed: "Fijo",
  parallel: "Paralelo",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Posici\xF3n",
  ticks: "Marcas",
  gridLines: "L\xEDneas de Cuadr\xEDcula",
  width: "Ancho",
  height: "Altura",
  length: "Longitud",
  padding: "Relleno",
  spacing: "Espaciado",
  chartStyle: "Estilo de Gr\xE1fico",
  title: "T\xEDtulo",
  chartTitles: "T\xEDtulos",
  chartTitle: "T\xEDtulo del Gr\xE1fico",
  chartSubtitle: "Subt\xEDtulo",
  horizontalAxisTitle: "T\xEDtulo del Eje Horizontal",
  verticalAxisTitle: "T\xEDtulo del Eje Vertical",
  polarAxisTitle: "T\xEDtulo del Eje Polar",
  titlePlaceholder: "T\xEDtulo del Gr\xE1fico",
  background: "Fondo",
  font: "Fuente",
  weight: "Peso",
  top: "Superior",
  right: "Derecha",
  bottom: "Inferior",
  left: "Izquierda",
  labels: "Etiquetas",
  calloutLabels: "Etiquetas de Llamada",
  sectorLabels: "Etiquetas de Sector",
  positionRatio: "Relaci\xF3n de Posici\xF3n",
  size: "Tama\xF1o",
  shape: "Forma",
  minSize: "Tama\xF1o M\xEDnimo",
  maxSize: "Tama\xF1o M\xE1ximo",
  legend: "Leyenda",
  position: "Posici\xF3n",
  markerSize: "Tama\xF1o del Marcador",
  markerStroke: "Trazo del Marcador",
  markerPadding: "Relleno del Marcador",
  itemSpacing: "Espaciado de \xCDtems",
  itemPaddingX: "Relleno X de \xCDtems",
  itemPaddingY: "Relleno Y de \xCDtems",
  layoutHorizontalSpacing: "Espaciado Horizontal",
  layoutVerticalSpacing: "Espaciado Vertical",
  strokeWidth: "Grosor del Trazo",
  offset: "Desplazamiento",
  offsets: "Desplazamientos",
  tooltips: "Informaci\xF3n sobre herramientas",
  callout: "Llamada",
  markers: "Marcadores",
  shadow: "Sombra",
  blur: "Desenfoque",
  xOffset: "Desplazamiento X",
  yOffset: "Desplazamiento Y",
  lineWidth: "Ancho de L\xEDnea",
  lineDash: "L\xEDnea Discontinua",
  lineDashOffset: "Desplazamiento de Discontinuidad",
  scrollingZoom: "Desplazamiento",
  scrollingStep: "Paso de Desplazamiento",
  selectingZoom: "Seleccionando",
  durationMillis: "Duraci\xF3n (ms)",
  crosshairLabel: "Etiqueta",
  crosshairSnap: "Ajustar al Nodo",
  normal: "Normal",
  bold: "Negrita",
  italic: "It\xE1lica",
  boldItalic: "Negrita It\xE1lica",
  predefined: "Predefinido",
  fillOpacity: "Opacidad de Relleno",
  strokeColor: "Color de L\xEDnea",
  strokeOpacity: "Opacidad de L\xEDnea",
  miniChart: "Mini-Gr\xE1fico",
  histogramBinCount: "Conteo de Intervalos",
  connectorLine: "L\xEDnea Conectora",
  seriesItems: "\xCDtems de Series",
  seriesItemType: "Tipo de \xCDtem",
  seriesItemPositive: "Positivo",
  seriesItemNegative: "Negativo",
  seriesItemLabels: "Etiquetas de \xCDtems",
  columnGroup: "Columna",
  barGroup: "Barra",
  pieGroup: "Torta",
  lineGroup: "L\xEDnea",
  scatterGroup: "XY (Dispersi\xF3n)",
  areaGroup: "\xC1rea",
  polarGroup: "Polar",
  statisticalGroup: "Estad\xEDstico",
  hierarchicalGroup: "Jer\xE1rquico",
  specializedGroup: "Especializado",
  combinationGroup: "Combinaci\xF3n",
  groupedColumnTooltip: "Agrupada",
  stackedColumnTooltip: "Apilada",
  normalizedColumnTooltip: "100% Apilada",
  groupedBarTooltip: "Agrupada",
  stackedBarTooltip: "Apilada",
  normalizedBarTooltip: "100% Apilada",
  pieTooltip: "Torta",
  donutTooltip: "Donut",
  lineTooltip: "L\xEDnea",
  groupedAreaTooltip: "\xC1rea",
  stackedAreaTooltip: "Apilada",
  normalizedAreaTooltip: "100% Apilada",
  scatterTooltip: "Dispersi\xF3n",
  bubbleTooltip: "Burbuja",
  histogramTooltip: "Histograma",
  radialColumnTooltip: "Columna Radial",
  radialBarTooltip: "Barra Radial",
  radarLineTooltip: "L\xEDnea de Radar",
  radarAreaTooltip: "\xC1rea de Radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Barra de Intervalo",
  rangeAreaTooltip: "\xC1rea de Intervalo",
  boxPlotTooltip: "Diagrama de Cajas",
  treemapTooltip: "Mapa de \xC1reas",
  sunburstTooltip: "Estallido Solar",
  waterfallTooltip: "Cascada",
  heatmapTooltip: "Mapa de Calor",
  columnLineComboTooltip: "Columna y L\xEDnea",
  areaColumnComboTooltip: "\xC1rea y Columna",
  customComboTooltip: "Combinaci\xF3n Personalizada",
  innerRadius: "Radio Interno",
  startAngle: "\xC1ngulo de Inicio",
  endAngle: "\xC1ngulo Final",
  reverseDirection: "Direcci\xF3n Inversa",
  groupPadding: "Relleno de Grupo",
  seriesPadding: "Relleno de Series",
  tile: "Mosaico",
  whisker: "Bigote",
  cap: "Tap\xF3n",
  capLengthRatio: "Relaci\xF3n de Longitud",
  labelPlacement: "Ubicaci\xF3n de Etiqueta",
  inside: "Dentro",
  outside: "Fuera",
  noDataToChart: "No hay datos disponibles para graficar.",
  pivotChartRequiresPivotMode: "El Gr\xE1fico de Pivote requiere el Modo de Pivote habilitado.",
  chartSettingsToolbarTooltip: "Men\xFA",
  chartLinkToolbarTooltip: "Vinculado a la Cuadr\xEDcula",
  chartUnlinkToolbarTooltip: "Desvinculado de la Cuadr\xEDcula",
  chartDownloadToolbarTooltip: "Descargar Gr\xE1fico",
  chartMenuToolbarTooltip: "Men\xFA",
  chartEdit: "Editar Gr\xE1fico",
  chartAdvancedSettings: "Configuraci\xF3n Avanzada",
  chartLink: "Vincular a la Cuadr\xEDcula",
  chartUnlink: "Desvincular de la Cuadr\xEDcula",
  chartDownload: "Descargar Gr\xE1fico",
  histogramFrequency: "Frecuencia",
  seriesChartType: "Tipo de Gr\xE1fico de Series",
  seriesType: "Tipo de Series",
  secondaryAxis: "Eje Secundario",
  seriesAdd: "Agregar una serie",
  categoryAdd: "Agregar una categor\xEDa",
  bar: "Barra",
  column: "Columna",
  histogram: "Histograma",
  advancedSettings: "Configuraci\xF3n Avanzada",
  direction: "Direcci\xF3n",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Tipo de Grupo",
  groupedSeriesGroupType: "Agrupadas",
  stackedSeriesGroupType: "Apiladas",
  normalizedSeriesGroupType: "100% Apiladas",
  legendEnabled: "Habilitado",
  invalidColor: "El valor del color es inv\xE1lido",
  groupedColumnFull: "Columna Agrupada",
  stackedColumnFull: "Columna Apilada",
  normalizedColumnFull: "Columna 100% Apilada",
  groupedBarFull: "Barra Agrupada",
  stackedBarFull: "Barra Apilada",
  normalizedBarFull: "Barra 100% Apilada",
  stackedAreaFull: "\xC1rea Apilada",
  normalizedAreaFull: "\xC1rea 100% Apilada",
  customCombo: "Combinaci\xF3n Personalizada",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Nivel ${variable}. Presiona ENTER para editar.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Nivel ${variable}. ${variable} Presiona ENTER para editar.",
  ariaAdvancedFilterBuilderList: "Lista de Constructor de Filtros Avanzados",
  ariaAdvancedFilterBuilderFilterItem: "Condici\xF3n del Filtro",
  ariaAdvancedFilterBuilderGroupItem: "Grupo de Filtros",
  ariaAdvancedFilterBuilderColumn: "Columna",
  ariaAdvancedFilterBuilderOption: "Opci\xF3n",
  ariaAdvancedFilterBuilderValueP: "Valor",
  ariaAdvancedFilterBuilderJoinOperator: "Operador de Uni\xF3n",
  ariaAdvancedFilterInput: "Entrada de Filtro Avanzado",
  ariaChecked: "seleccionado",
  ariaColumn: "Columna",
  ariaColumnGroup: "Grupo de Columnas",
  ariaColumnFiltered: "Columna Filtrada",
  ariaColumnSelectAll: "Alternar Selecci\xF3n de Todas las Columnas",
  ariaDateFilterInput: "Entrada de Filtro de Fecha",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Entrada de Filtrado de Columnas",
  ariaFilterFromValue: "Filtrar desde valor",
  ariaFilterInput: "Entrada de Filtro",
  ariaFilterList: "Lista de Filtros",
  ariaFilterToValue: "Filtrar hasta valor",
  ariaFilterValue: "Valor del Filtro",
  ariaFilterMenuOpen: "Abrir Men\xFA de Filtros",
  ariaFilteringOperator: "Operador de Filtrado",
  ariaHidden: "oculto",
  ariaIndeterminate: "indeterminado",
  ariaInputEditor: "Editor de Entrada",
  ariaMenuColumn: "Presiona ALT ABAJO para abrir el men\xFA de la columna",
  ariaFilterColumn: "Presiona CTRL ENTER para abrir el filtro",
  ariaRowDeselect: "Presiona SPACE para anular la selecci\xF3n de esta fila",
  ariaRowSelectAll: "Presiona SPACE para alternar la selecci\xF3n de todas las filas",
  ariaRowToggleSelection: "Presiona SPACE para alternar la selecci\xF3n de la fila",
  ariaRowSelect: "Presiona SPACE para seleccionar esta fila",
  ariaRowSelectionDisabled: "La selecci\xF3n de filas est\xE1 deshabilitada para esta fila",
  ariaSearch: "Buscar",
  ariaSortableColumn: "Presiona ENTER para ordenar",
  ariaToggleVisibility: "Presiona SPACE para alternar la visibilidad",
  ariaToggleCellValue: "Presiona SPACE para alternar el valor de la celda",
  ariaUnchecked: "no seleccionado",
  ariaVisible: "visible",
  ariaSearchFilterValues: "Buscar valores de filtro",
  ariaPageSizeSelectorLabel: "Tama\xF1o de P\xE1gina",
  ariaChartMenuClose: "Cerrar Men\xFA de Edici\xF3n de Gr\xE1fica",
  ariaChartSelected: "Seleccionado",
  ariaSkeletonCellLoadingFailed: "Carga de fila fallida",
  ariaSkeletonCellLoading: "Cargando datos de la fila",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Grupos de filas",
  ariaValuesDropZonePanelLabel: "Valores",
  ariaPivotDropZonePanelLabel: "Etiquetas de columna",
  ariaDropZoneColumnComponentDescription: "Presione DELETE para eliminar",
  ariaDropZoneColumnValueItemDescription: "Presione ENTER para cambiar el tipo de agregaci\xF3n",
  ariaDropZoneColumnGroupItemDescription: "Presione ENTER para ordenar",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " de ",
  ariaDropZoneColumnComponentSortAscending: "ascendente",
  ariaDropZoneColumnComponentSortDescending: "descendente",
  ariaLabelDialog: "Di\xE1logo",
  ariaLabelColumnMenu: "Men\xFA de Columna",
  ariaLabelColumnFilter: "Filtro de Columna",
  ariaLabelCellEditor: "Editor de Celda",
  ariaLabelSelectField: "Seleccionar Campo",
  // aria labels for rich select
  ariaLabelRichSelectField: "Campo de selecci\xF3n enriquecido",
  ariaLabelRichSelectToggleSelection: "Presiona ESPACIO para alternar la selecci\xF3n",
  ariaLabelRichSelectDeselectAllItems: "Presiona SUPRIMIR para deseleccionar todos los elementos",
  ariaLabelRichSelectDeleteSelection: "Presiona SUPRIMIR para deseleccionar el elemento",
  ariaLabelTooltip: "Informaci\xF3n sobre herramientas",
  ariaLabelContextMenu: "Men\xFA contextual",
  ariaLabelSubMenu: "Submen\xFA",
  ariaLabelAggregationFunction: "Funci\xF3n de agregaci\xF3n",
  ariaLabelAdvancedFilterAutocomplete: "Autocompletar filtro avanzado",
  ariaLabelAdvancedFilterBuilderAddField: "Agregar campo al generador de filtro avanzado",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Campo de selecci\xF3n de columna del generador de filtro avanzado",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Campo de selecci\xF3n de opci\xF3n del generador de filtro avanzado",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Campo de selecci\xF3n de operador de uni\xF3n del generador de filtro avanzado",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Lista de columnas",
  ariaFilterPanelList: "Lista de filtros",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Verdadero",
  false: "Falso",
  invalidDate: "Fecha inv\xE1lida",
  invalidNumber: "N\xFAmero inv\xE1lido",
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/AAAA",
  timeFormatSlashesMMDDYYYY: "MM/DD/AAAA",
  timeFormatSlashesDDMMYY: "DD/MM/AA",
  timeFormatSlashesMMDDYY: "MM/DD/AA",
  timeFormatDotsDDMYY: "DD.M.AA",
  timeFormatDotsMDDYY: "M.DD.AA",
  timeFormatDashesYYYYMMDD: "AAAA-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM AAAA",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/fa-IR.ts
var AG_GRID_LOCALE_IR = {
  // Set Filter
  selectAll: "(\u0627\u0646\u062A\u062E\u0627\u0628 \u0647\u0645\u0647)",
  selectAllSearchResults: "(\u0627\u0646\u062A\u062E\u0627\u0628 \u0647\u0645\u0647 \u0646\u062A\u0627\u06CC\u062C \u062C\u0633\u062A\u062C\u0648)",
  addCurrentSelectionToFilter: "\u0627\u0641\u0632\u0648\u062F\u0646 \u0627\u0646\u062A\u062E\u0627\u0628 \u0641\u0639\u0644\u06CC \u0628\u0647 \u0641\u06CC\u0644\u062A\u0631",
  searchOoo: "\u062C\u0633\u062A\u062C\u0648...",
  blanks: "(\u062E\u0627\u0644\u06CC)",
  noMatches: "\u0645\u0648\u0631\u062F\u06CC \u06CC\u0627\u0641\u062A \u0646\u0634\u062F",
  // Number Filter & Text Filter
  filterOoo: "\u0641\u06CC\u0644\u062A\u0631...",
  equals: "\u0628\u0631\u0627\u0628\u0631 \u0627\u0633\u062A \u0628\u0627",
  notEqual: "\u0628\u0631\u0627\u0628\u0631 \u0646\u06CC\u0633\u062A \u0628\u0627",
  blank: "\u062E\u0627\u0644\u06CC",
  notBlank: "\u062E\u0627\u0644\u06CC \u0646\u06CC\u0633\u062A",
  empty: "\u06CC\u06A9\u06CC \u0631\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u06A9\u0646\u06CC\u062F",
  // Number Filter
  lessThan: "\u06A9\u0645\u062A\u0631 \u0627\u0632",
  greaterThan: "\u0628\u06CC\u0634\u062A\u0631 \u0627\u0632",
  lessThanOrEqual: "\u06A9\u0645\u062A\u0631 \u06CC\u0627 \u0645\u0633\u0627\u0648\u06CC \u0628\u0627",
  greaterThanOrEqual: "\u0628\u06CC\u0634\u062A\u0631 \u06CC\u0627 \u0645\u0633\u0627\u0648\u06CC \u0628\u0627",
  inRange: "\u0628\u06CC\u0646",
  inRangeStart: "\u0627\u0632",
  inRangeEnd: "\u062A\u0627",
  // Text Filter
  contains: "\u0634\u0627\u0645\u0644 \u0627\u0633\u062A",
  notContains: "\u0634\u0627\u0645\u0644 \u0646\u0645\u06CC\u200C\u0634\u0648\u062F",
  startsWith: "\u0634\u0631\u0648\u0639 \u0645\u06CC\u200C\u0634\u0648\u062F \u0628\u0627",
  endsWith: "\u067E\u0627\u06CC\u0627\u0646 \u0645\u06CC\u200C\u06CC\u0627\u0628\u062F \u0628\u0627",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u0642\u0628\u0644",
  after: "\u0628\u0639\u062F",
  // Filter Conditions
  andCondition: "\u0648",
  orCondition: "\u06CC\u0627",
  // Filter Buttons
  applyFilter: "\u0627\u0639\u0645\u0627\u0644",
  resetFilter: "\u0628\u0627\u0632\u0646\u0634\u0627\u0646\u06CC",
  clearFilter: "\u067E\u0627\u06A9 \u06A9\u0631\u062F\u0646",
  cancelFilter: "\u0644\u063A\u0648",
  // Filter Titles
  textFilter: "\u0641\u06CC\u0644\u062A\u0631 \u0645\u062A\u0646\u06CC",
  numberFilter: "\u0641\u06CC\u0644\u062A\u0631 \u0639\u062F\u062F\u06CC",
  dateFilter: "\u0641\u06CC\u0644\u062A\u0631 \u062A\u0627\u0631\u06CC\u062E",
  setFilter: "\u0641\u06CC\u0644\u062A\u0631 \u0645\u062C\u0645\u0648\u0639\u0647",
  // Group Column Filter
  groupFilterSelect: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0641\u06CC\u0644\u062F:",
  // Advanced Filter
  advancedFilterContains: "\u0634\u0627\u0645\u0644 \u0645\u06CC\u200C\u0634\u0648\u062F",
  advancedFilterNotContains: "\u0634\u0627\u0645\u0644 \u0646\u0645\u06CC\u200C\u0634\u0648\u062F",
  advancedFilterTextEquals: "\u0645\u0633\u0627\u0648\u06CC \u0627\u0633\u062A \u0628\u0627",
  advancedFilterTextNotEqual: "\u0645\u0633\u0627\u0648\u06CC \u0646\u06CC\u0633\u062A \u0628\u0627",
  advancedFilterStartsWith: "\u0634\u0631\u0648\u0639 \u0645\u06CC\u200C\u0634\u0648\u062F \u0628\u0627",
  advancedFilterEndsWith: "\u067E\u0627\u06CC\u0627\u0646 \u0645\u06CC\u200C\u06CC\u0627\u0628\u062F \u0628\u0627",
  advancedFilterBlank: "\u062E\u0627\u0644\u06CC \u0627\u0633\u062A",
  advancedFilterNotBlank: "\u062E\u0627\u0644\u06CC \u0646\u06CC\u0633\u062A",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u062F\u0631\u0633\u062A \u0627\u0633\u062A",
  advancedFilterFalse: "\u0646\u0627\u062F\u0631\u0633\u062A \u0627\u0633\u062A",
  advancedFilterAnd: "\u0648",
  advancedFilterOr: "\u06CC\u0627",
  advancedFilterApply: "\u0627\u0639\u0645\u0627\u0644",
  advancedFilterBuilder: "\u0633\u0627\u0632\u0646\u062F\u0647",
  advancedFilterValidationMissingColumn: "\u0633\u062A\u0648\u0646 \u06AF\u0645\u0634\u062F\u0647 \u0627\u0633\u062A",
  advancedFilterValidationMissingOption: "\u06AF\u0632\u06CC\u0646\u0647 \u06AF\u0645\u0634\u062F\u0647 \u0627\u0633\u062A",
  advancedFilterValidationMissingValue: "\u0645\u0642\u062F\u0627\u0631 \u06AF\u0645\u0634\u062F\u0647 \u0627\u0633\u062A",
  advancedFilterValidationInvalidColumn: "\u0633\u062A\u0648\u0646 \u06CC\u0627\u0641\u062A \u0646\u0634\u062F",
  advancedFilterValidationInvalidOption: "\u06AF\u0632\u06CC\u0646\u0647 \u06CC\u0627\u0641\u062A \u0646\u0634\u062F",
  advancedFilterValidationMissingQuote: "\u0645\u0642\u062F\u0627\u0631 \u0628\u062F\u0648\u0646 \u0646\u0642\u0644 \u0642\u0648\u0644 \u0627\u0646\u062A\u0647\u0627\u06CC\u06CC \u0627\u0633\u062A",
  advancedFilterValidationNotANumber: "\u0645\u0642\u062F\u0627\u0631 \u0639\u062F\u062F\u06CC \u0646\u06CC\u0633\u062A",
  advancedFilterValidationInvalidDate: "\u0645\u0642\u062F\u0627\u0631 \u062A\u0627\u0631\u06CC\u062E \u0645\u0639\u062A\u0628\u0631 \u0646\u06CC\u0633\u062A",
  advancedFilterValidationMissingCondition: "\u0634\u0631\u0637 \u06AF\u0645\u0634\u062F\u0647 \u0627\u0633\u062A",
  advancedFilterValidationJoinOperatorMismatch: "\u0627\u067E\u0631\u0627\u062A\u0648\u0631\u0647\u0627\u06CC \u067E\u06CC\u0648\u0633\u062A\u0647 \u062F\u0631 \u06CC\u06A9 \u0634\u0631\u0637 \u0628\u0627\u06CC\u062F \u06CC\u06A9\u0633\u0627\u0646 \u0628\u0627\u0634\u0646\u062F",
  advancedFilterValidationInvalidJoinOperator: "\u0627\u067E\u0631\u0627\u062A\u0648\u0631 \u067E\u06CC\u0648\u0633\u062A \u06CC\u0627\u0641\u062A \u0646\u0634\u062F",
  advancedFilterValidationMissingEndBracket: "\u0628\u0631\u0627\u06A9\u062A \u0627\u0646\u062A\u0647\u0627\u06CC\u06CC \u06AF\u0645\u0634\u062F\u0647 \u0627\u0633\u062A",
  advancedFilterValidationExtraEndBracket: "\u0628\u0631\u0627\u06A9\u062A \u0627\u0646\u062A\u0647\u0627\u06CC\u06CC \u0632\u06CC\u0627\u062F\u06CC \u0648\u062C\u0648\u062F \u062F\u0627\u0631\u062F",
  advancedFilterValidationMessage: "\u0639\u0628\u0627\u0631\u062A \u062D\u0627\u0648\u06CC \u06CC\u06A9 \u062E\u0637\u0627\u0633\u062A. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0639\u0628\u0627\u0631\u062A \u062D\u0627\u0648\u06CC \u06CC\u06A9 \u062E\u0637\u0627\u0633\u062A. ${variable} \u062F\u0631 \u067E\u0627\u06CC\u0627\u0646 \u0639\u0628\u0627\u0631\u062A.",
  advancedFilterBuilderTitle: "\u0639\u0646\u0648\u0627\u0646",
  advancedFilterBuilderApply: "\u0627\u0639\u0645\u0627\u0644",
  advancedFilterBuilderCancel: "\u0644\u063A\u0648",
  advancedFilterBuilderAddButtonTooltip: "\u0627\u0641\u0632\u0648\u062F\u0646 \u0641\u06CC\u0644\u062A\u0631 \u06CC\u0627 \u06AF\u0631\u0648\u0647",
  advancedFilterBuilderRemoveButtonTooltip: "\u062D\u0630\u0641",
  advancedFilterBuilderMoveUpButtonTooltip: "\u062D\u0631\u06A9\u062A \u0628\u0647 \u0628\u0627\u0644\u0627",
  advancedFilterBuilderMoveDownButtonTooltip: "\u062D\u0631\u06A9\u062A \u0628\u0647 \u067E\u0627\u06CC\u06CC\u0646",
  advancedFilterBuilderAddJoin: "\u0627\u0641\u0632\u0648\u062F\u0646 \u06AF\u0631\u0648\u0647",
  advancedFilterBuilderAddCondition: "\u0627\u0641\u0632\u0648\u062F\u0646 \u0641\u06CC\u0644\u062A\u0631",
  advancedFilterBuilderSelectColumn: "\u0627\u0646\u062A\u062E\u0627\u0628 \u06CC\u06A9 \u0633\u062A\u0648\u0646",
  advancedFilterBuilderSelectOption: "\u0627\u0646\u062A\u062E\u0627\u0628 \u06CC\u06A9 \u06AF\u0632\u06CC\u0646\u0647",
  advancedFilterBuilderEnterValue: "\u0648\u0627\u0631\u062F \u06A9\u0631\u062F\u0646 \u0645\u0642\u062F\u0627\u0631...",
  advancedFilterBuilderValidationAlreadyApplied: "\u0641\u06CC\u0644\u062A\u0631 \u0641\u0639\u0644\u06CC \u0642\u0628\u0644\u0627\u064B \u0627\u0639\u0645\u0627\u0644 \u0634\u062F\u0647 \u0627\u0633\u062A.",
  advancedFilterBuilderValidationIncomplete: "\u0647\u0645\u0647 \u0634\u0631\u0627\u06CC\u0637 \u06A9\u0627\u0645\u0644 \u0646\u06CC\u0633\u062A\u0646\u062F.",
  advancedFilterBuilderValidationSelectColumn: "\u0628\u0627\u06CC\u062F \u06CC\u06A9 \u0633\u062A\u0648\u0646 \u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u0648\u062F.",
  advancedFilterBuilderValidationSelectOption: "\u0628\u0627\u06CC\u062F \u06CC\u06A9 \u06AF\u0632\u06CC\u0646\u0647 \u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u0648\u062F.",
  advancedFilterBuilderValidationEnterValue: "\u0628\u0627\u06CC\u062F \u06CC\u06A9 \u0645\u0642\u062F\u0627\u0631 \u0648\u0627\u0631\u062F \u0634\u0648\u062F.",
  // Side Bar
  columns: "\u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  filters: "\u0641\u06CC\u0644\u062A\u0631\u0647\u0627",
  // columns tool panel
  pivotMode: "\u062D\u0627\u0644\u062A Pivot",
  groups: "\u06AF\u0631\u0648\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641",
  rowGroupColumnsEmptyMessage: "\u0627\u06CC\u0646\u062C\u0627 \u0628\u06A9\u0634\u06CC\u062F \u062A\u0627 \u06AF\u0631\u0648\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641 \u062A\u0646\u0638\u06CC\u0645 \u0634\u0648\u062F",
  values: "\u0645\u0642\u0627\u062F\u06CC\u0631",
  valueColumnsEmptyMessage: "\u0627\u06CC\u0646\u062C\u0627 \u0628\u06A9\u0634\u06CC\u062F \u062A\u0627 \u062C\u0645\u0639\u200C\u0622\u0648\u0631\u06CC \u0634\u0648\u062F",
  pivots: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0633\u062A\u0648\u0646",
  pivotColumnsEmptyMessage: "\u0627\u06CC\u0646\u062C\u0627 \u0628\u06A9\u0634\u06CC\u062F \u062A\u0627 \u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0633\u062A\u0648\u0646 \u062A\u0646\u0638\u06CC\u0645 \u0634\u0648\u062F",
  // Header of the Default Group Column
  group: "\u06AF\u0631\u0648\u0647",
  // Row Drag
  rowDragRow: "\u0631\u062F\u06CC\u0641",
  rowDragRows: "\u0631\u062F\u06CC\u0641\u200C\u0647\u0627",
  // Other
  loadingOoo: "\u062F\u0631 \u062D\u0627\u0644 \u0628\u0627\u0631\u06AF\u0630\u0627\u0631\u06CC...",
  loadingError: "\u062E\u0637\u0627",
  noRowsToShow: "\u0631\u062F\u06CC\u0641\u06CC \u0628\u0631\u0627\u06CC \u0646\u0645\u0627\u06CC\u0634 \u0648\u062C\u0648\u062F \u0646\u062F\u0627\u0631\u062F",
  enabled: "\u0641\u0639\u0627\u0644",
  // Menu
  pinColumn: "\u0633\u0646\u062C\u0627\u0642 \u06A9\u0631\u062F\u0646 \u0633\u062A\u0648\u0646",
  pinLeft: "\u0633\u0646\u062C\u0627\u0642 \u06A9\u0631\u062F\u0646 \u0628\u0647 \u0686\u067E",
  pinRight: "\u0633\u0646\u062C\u0627\u0642 \u06A9\u0631\u062F\u0646 \u0628\u0647 \u0631\u0627\u0633\u062A",
  noPin: "\u0628\u062F\u0648\u0646 \u0633\u0646\u062C\u0627\u0642",
  valueAggregation: "\u062A\u062C\u0645\u0639 \u0627\u0631\u0632\u0634",
  noAggregation: "\u0647\u06CC\u0686",
  autosizeThisColumn: "\u0627\u0646\u062F\u0627\u0632\u0647 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0627\u06CC\u0646 \u0633\u062A\u0648\u0646",
  autosizeAllColumns: "\u0627\u0646\u062F\u0627\u0632\u0647 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0647\u0645\u0647 \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  groupBy: "\u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC \u0628\u0627",
  ungroupBy: "\u062D\u0630\u0641 \u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC \u0628\u0627",
  ungroupAll: "\u062D\u0630\u0641 \u0647\u0645\u0647 \u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC\u200C\u0647\u0627",
  addToValues: "${variable} \u0631\u0627 \u0628\u0647 \u0645\u0642\u0627\u062F\u06CC\u0631 \u0627\u0636\u0627\u0641\u0647 \u06A9\u0646\u06CC\u062F",
  removeFromValues: "${variable} \u0631\u0627 \u0627\u0632 \u0645\u0642\u0627\u062F\u06CC\u0631 \u062D\u0630\u0641 \u06A9\u0646\u06CC\u062F",
  addToLabels: "${variable} \u0631\u0627 \u0628\u0647 \u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627 \u0627\u0636\u0627\u0641\u0647 \u06A9\u0646\u06CC\u062F",
  removeFromLabels: "${variable} \u0631\u0627 \u0627\u0632 \u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627 \u062D\u0630\u0641 \u06A9\u0646\u06CC\u062F",
  resetColumns: "\u0628\u0627\u0632\u0646\u0634\u0627\u0646\u06CC \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  expandAll: "\u0628\u0627\u0632 \u06A9\u0631\u062F\u0646 \u0647\u0645\u0647 \u06AF\u0631\u0648\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641",
  collapseAll: "\u0628\u0633\u062A\u0646 \u0647\u0645\u0647 \u06AF\u0631\u0648\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641",
  copy: "\u06A9\u067E\u06CC",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u06A9\u067E\u06CC \u0628\u0627 \u0633\u0631\u0628\u0631\u06AF\u200C\u0647\u0627",
  copyWithGroupHeaders: "\u06A9\u067E\u06CC \u0628\u0627 \u0633\u0631\u0628\u0631\u06AF\u200C\u0647\u0627\u06CC \u06AF\u0631\u0648\u0647",
  cut: "\u0628\u0631\u06CC\u062F\u0646",
  paste: "\u0686\u0633\u0628\u0627\u0646\u062F\u0646",
  ctrlV: "Ctrl+V",
  export: "\u0635\u0627\u062F\u0631\u0627\u062A",
  csvExport: "\u0635\u0627\u062F\u0631\u0627\u062A CSV",
  excelExport: "\u0635\u0627\u062F\u0631\u0627\u062A \u0627\u06A9\u0633\u0644",
  columnFilter: "\u0641\u06CC\u0644\u062A\u0631 \u0633\u062A\u0648\u0646",
  columnChooser: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  chooseColumns: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  sortAscending: "\u0645\u0631\u062A\u0628\u200C\u0633\u0627\u0632\u06CC \u0635\u0639\u0648\u062F\u06CC",
  sortDescending: "\u0645\u0631\u062A\u0628\u200C\u0633\u0627\u0632\u06CC \u0646\u0632\u0648\u0644\u06CC",
  sortUnSort: "\u067E\u0627\u06A9 \u06A9\u0631\u062F\u0646 \u0645\u0631\u062A\u0628\u200C\u0633\u0627\u0632\u06CC",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u062C\u0645\u0639",
  first: "\u0627\u0648\u0644",
  last: "\u0622\u062E\u0631",
  min: "\u062D\u062F\u0627\u0642\u0644",
  max: "\u062D\u062F\u0627\u06A9\u062B\u0631",
  none: "\u0647\u06CC\u0686\u200C\u06A9\u062F\u0627\u0645",
  count: "\u062A\u0639\u062F\u0627\u062F",
  avg: "\u0645\u06CC\u0627\u0646\u06AF\u06CC\u0646",
  filteredRows: "\u0641\u06CC\u0644\u062A\u0631 \u0634\u062F\u0647",
  selectedRows: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u062F\u0647",
  totalRows: "\u06A9\u0644 \u0631\u062F\u06CC\u0641\u200C\u0647\u0627",
  totalAndFilteredRows: "\u0631\u062F\u06CC\u0641\u200C\u0647\u0627",
  more: "\u0628\u06CC\u0634\u062A\u0631",
  to: "\u0628\u0647",
  of: "\u0627\u0632",
  page: "\u0635\u0641\u062D\u0647",
  pageLastRowUnknown: "?",
  nextPage: "\u0635\u0641\u062D\u0647 \u0628\u0639\u062F",
  lastPage: "\u0622\u062E\u0631\u06CC\u0646 \u0635\u0641\u062D\u0647",
  firstPage: "\u0627\u0648\u0644\u06CC\u0646 \u0635\u0641\u062D\u0647",
  previousPage: "\u0635\u0641\u062D\u0647 \u0642\u0628\u0644\u06CC",
  pageSizeSelectorLabel: "\u0627\u0646\u062F\u0627\u0632\u0647 \u0635\u0641\u062D\u0647:",
  footerTotal: "\u06A9\u0644",
  // Pivoting
  pivotColumnGroupTotals: "\u0645\u062C\u0645\u0648\u0639",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u0686\u0627\u0631\u062A \u0645\u062D\u0648\u0631\u06CC \u0648 \u062D\u0627\u0644\u062A \u0645\u062D\u0648\u0631\u06CC",
  pivotChart: "\u0686\u0627\u0631\u062A \u0645\u062D\u0648\u0631\u06CC",
  chartRange: "\u062F\u0627\u0645\u0646\u0647 \u0686\u0627\u0631\u062A",
  columnChart: "\u0633\u062A\u0648\u0646",
  groupedColumn: "\u06AF\u0631\u0648\u0647 \u0628\u0646\u062F\u06CC \u0634\u062F\u0647",
  stackedColumn: "\u067E\u0634\u062A\u0647 \u0627\u06CC",
  normalizedColumn: "\u06F1\u06F0\u06F0\u066A \u067E\u0634\u062A\u0647 \u0627\u06CC",
  barChart: "\u0645\u06CC\u0644\u0647 \u0627\u06CC",
  groupedBar: "\u06AF\u0631\u0648\u0647 \u0628\u0646\u062F\u06CC \u0634\u062F\u0647",
  stackedBar: "\u067E\u0634\u062A\u0647 \u0627\u06CC",
  normalizedBar: "\u06F1\u06F0\u06F0\u066A \u067E\u0634\u062A\u0647 \u0627\u06CC",
  pieChart: "\u062F\u0627\u06CC\u0631\u0647 \u0627\u06CC",
  pie: "\u062F\u0627\u06CC\u0631\u0647 \u0627\u06CC",
  donut: "\u062F\u0648\u0646\u0627\u062A",
  line: "\u062E\u0637\u06CC",
  xyChart: "\u0627\u06CC\u06A9\u0633 \u0648\u0627\u06CC (\u067E\u0631\u0627\u06A9\u0646\u062F\u0647)",
  scatter: "\u067E\u0631\u0627\u06A9\u0646\u062F\u0647",
  bubble: "\u062D\u0628\u0627\u0628\u06CC",
  areaChart: "\u0645\u0633\u0627\u062D\u062A\u06CC",
  area: "\u0645\u0633\u0627\u062D\u062A\u06CC",
  stackedArea: "\u067E\u0634\u062A\u0647 \u0627\u06CC",
  normalizedArea: "\u06F1\u06F0\u06F0\u066A \u067E\u0634\u062A\u0647 \u0627\u06CC",
  histogramChart: "\u0647\u06CC\u0633\u062A\u0648\u06AF\u0631\u0627\u0645",
  polarChart: "\u0642\u0637\u0628\u06CC",
  radarLine: "\u0631\u0627\u062F\u0627\u0631\u06CC \u062E\u0637\u06CC",
  radarArea: "\u0631\u0627\u062F\u0627\u0631\u06CC \u0645\u0633\u0627\u062D\u062A\u06CC",
  nightingale: "\u0646\u0627\u06CC\u062A\u06CC\u0646\u06AF\u0644",
  radialColumn: "\u0633\u062A\u0648\u0646 \u0634\u0639\u0627\u0639\u06CC",
  radialBar: "\u0645\u06CC\u0644\u0647 \u0634\u0639\u0627\u0639\u06CC",
  statisticalChart: "\u0622\u0645\u0627\u0631\u06CC",
  boxPlot: "\u0628\u0627\u06A9\u0633 \u067E\u0644\u0627\u062A",
  rangeBar: "\u0645\u06CC\u0644\u0647 \u0628\u0627\u0632\u0647 \u0627\u06CC",
  rangeArea: "\u0645\u0633\u0627\u062D\u062A \u0628\u0627\u0632\u0647 \u0627\u06CC",
  hierarchicalChart: "\u0633\u0644\u0633\u0644\u0647 \u0645\u0631\u0627\u062A\u0628\u06CC",
  treemap: "\u062F\u0631\u062E\u062A \u0646\u0642\u0634\u0647",
  sunburst: "\u0627\u0646\u0641\u062C\u0627\u0631 \u062E\u0648\u0631\u0634\u06CC\u062F\u06CC",
  specializedChart: "\u062A\u062E\u0635\u0635\u06CC",
  waterfall: "\u0622\u0628\u0634\u0627\u0631",
  heatmap: "\u0646\u0642\u0634\u0647 \u062D\u0631\u0627\u0631\u062A\u06CC",
  combinationChart: "\u062A\u0631\u06A9\u06CC\u0628\u06CC",
  columnLineCombo: "\u0633\u062A\u0648\u0646 \u0648 \u062E\u0637",
  AreaColumnCombo: "\u0645\u0633\u0627\u062D\u062A \u0648 \u0633\u062A\u0648\u0646",
  // Charts
  pivotChartTitle: "\u0646\u0645\u0648\u062F\u0627\u0631 \u0645\u062D\u0648\u0631\u06CC",
  rangeChartTitle: "\u0646\u0645\u0648\u062F\u0627\u0631 \u0645\u062D\u062F\u0648\u062F\u0647",
  settings: "\u0646\u0645\u0648\u062F\u0627\u0631",
  data: "\u0631\u0627\u0647 \u0627\u0646\u062F\u0627\u0632\u06CC",
  format: "\u0633\u0641\u0627\u0631\u0634\u06CC \u0633\u0627\u0632\u06CC",
  categories: "\u062F\u0633\u062A\u0647 \u0628\u0646\u062F\u06CC\u200C\u0647\u0627",
  defaultCategory: "(\u0647\u06CC\u0686)",
  series: "\u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647\u200C\u0647\u0627",
  switchCategorySeries: "\u062A\u063A\u06CC\u06CC\u0631 \u062F\u0633\u062A\u0647 / \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647",
  categoryValues: "\u0645\u0642\u0627\u062F\u06CC\u0631 \u062F\u0633\u062A\u0647",
  seriesLabels: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647",
  aggregate: "\u062A\u062C\u0645\u0639",
  xyValues: "\u0645\u0642\u0627\u062F\u06CC\u0631 X Y",
  paired: "\u062D\u0627\u0644\u062A \u062C\u0641\u062A",
  axis: "\u0645\u062D\u0648\u0631",
  xAxis: "\u0645\u062D\u0648\u0631 \u0627\u0641\u0642\u06CC",
  yAxis: "\u0645\u062D\u0648\u0631 \u0639\u0645\u0648\u062F\u06CC",
  polarAxis: "\u0645\u062D\u0648\u0631 \u0642\u0637\u0628\u06CC",
  radiusAxis: "\u0645\u062D\u0648\u0631 \u0634\u0639\u0627\u0639\u06CC",
  navigator: "\u0631\u0627\u0647\u0628\u0631\u062F",
  zoom: "\u0628\u0632\u0631\u06AF\u0646\u0645\u0627\u06CC\u06CC",
  animation: "\u0627\u0646\u06CC\u0645\u06CC\u0634\u0646",
  crosshair: "\u0645\u0648\u0634\u06A9\u0627\u0628",
  color: "\u0631\u0646\u06AF",
  thickness: "\u0636\u062E\u0627\u0645\u062A",
  preferredLength: "\u0637\u0648\u0644 \u0645\u0648\u0631\u062F \u0646\u0638\u0631",
  xType: "\u0646\u0648\u0639 X",
  axisType: "\u0646\u0648\u0639 \u0645\u062D\u0648\u0631",
  automatic: "\u062E\u0648\u062F\u06A9\u0627\u0631",
  category: "\u062F\u0633\u062A\u0647",
  number: "\u0639\u062F\u062F",
  time: "\u0632\u0645\u0627\u0646",
  timeFormat: "\u0641\u0631\u0645\u062A \u0632\u0645\u0627\u0646",
  autoRotate: "\u0686\u0631\u062E\u0634 \u062E\u0648\u062F\u06A9\u0627\u0631",
  labelRotation: "\u0686\u0631\u062E\u0634 \u0628\u0631\u0686\u0633\u0628",
  circle: "\u062F\u0627\u06CC\u0631\u0647",
  polygon: "\u0686\u0646\u062F \u0636\u0644\u0639\u06CC",
  square: "\u0645\u0631\u0628\u0639",
  cross: "\u0686\u0644\u06CC\u067E\u0627",
  diamond: "\u0644\u0648\u0632\u06CC",
  plus: "\u0627\u0636\u0627\u0641\u0647",
  triangle: "\u0645\u062B\u0644\u062B",
  heart: "\u0642\u0644\u0628",
  orientation: "\u062C\u0647\u062A",
  fixed: "\u062B\u0627\u0628\u062A",
  parallel: "\u0645\u0648\u0627\u0632\u06CC",
  perpendicular: "\u0639\u0645\u0648\u062F",
  radiusAxisPosition: "\u0645\u0648\u0642\u0639\u06CC\u062A",
  ticks: "\u0639\u0644\u0627\u0645\u062A\u200C\u0647\u0627",
  gridLines: "\u062E\u0637\u0648\u0637 \u0634\u0628\u06A9\u0647",
  width: "\u0639\u0631\u0636",
  height: "\u0627\u0631\u062A\u0641\u0627\u0639",
  length: "\u0637\u0648\u0644",
  padding: "\u062D\u0627\u0634\u06CC\u0647 \u062F\u0627\u062E\u0644\u06CC",
  spacing: "\u0641\u0627\u0635\u0644\u0647",
  chartStyle: "\u0633\u0628\u06A9 \u0646\u0645\u0648\u062F\u0627\u0631",
  title: "\u0639\u0646\u0648\u0627\u0646",
  chartTitles: "\u0639\u0646\u0627\u0648\u06CC\u0646",
  chartTitle: "\u0639\u0646\u0648\u0627\u0646 \u0646\u0645\u0648\u062F\u0627\u0631",
  chartSubtitle: "\u0632\u06CC\u0631\u0639\u0646\u0648\u0627\u0646",
  horizontalAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0645\u062D\u0648\u0631 \u0627\u0641\u0642\u06CC",
  verticalAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0645\u062D\u0648\u0631 \u0639\u0645\u0648\u062F\u06CC",
  polarAxisTitle: "\u0639\u0646\u0648\u0627\u0646 \u0645\u062D\u0648\u0631 \u0642\u0637\u0628\u06CC",
  titlePlaceholder: "\u0639\u0646\u0648\u0627\u0646 \u0646\u0645\u0648\u062F\u0627\u0631",
  background: "\u067E\u0633\u200C\u0632\u0645\u06CC\u0646\u0647",
  font: "\u0641\u0648\u0646\u062A",
  weight: "\u0648\u0632\u0646",
  top: "\u0628\u0627\u0644\u0627",
  right: "\u0631\u0627\u0633\u062A",
  bottom: "\u067E\u0627\u06CC\u06CC\u0646",
  left: "\u0686\u067E",
  labels: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627",
  calloutLabels: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u062A\u0648\u0636\u06CC\u062D\u06CC",
  sectorLabels: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0628\u062E\u0634",
  positionRatio: "\u0646\u0633\u0628\u062A \u0645\u0648\u0642\u0639\u06CC\u062A",
  size: "\u0627\u0646\u062F\u0627\u0632\u0647",
  shape: "\u0634\u06A9\u0644",
  minSize: "\u062D\u062F\u0627\u0642\u0644 \u0627\u0646\u062F\u0627\u0632\u0647",
  maxSize: "\u062D\u062F\u0627\u06A9\u062B\u0631 \u0627\u0646\u062F\u0627\u0632\u0647",
  legend: "\u0639\u0644\u0627\u0626\u0645 \u0631\u0627\u0647\u0646\u0645\u0627",
  position: "\u0645\u0648\u0642\u0639\u06CC\u062A",
  markerSize: "\u0627\u0646\u062F\u0627\u0632\u0647 \u0646\u0634\u0627\u0646\u06AF\u0631",
  markerStroke: "\u0636\u0631\u0628\u0647 \u0646\u0634\u0627\u0646\u06AF\u0631",
  markerPadding: "\u062D\u0627\u0634\u06CC\u0647 \u062F\u0627\u062E\u0644\u06CC \u0646\u0634\u0627\u0646\u06AF\u0631",
  itemSpacing: "\u0641\u0627\u0635\u0644\u0647 \u0628\u06CC\u0646 \u0645\u0648\u0627\u0631\u062F",
  itemPaddingX: "\u062D\u0627\u0634\u06CC\u0647 \u062F\u0627\u062E\u0644\u06CC \u0627\u0641\u0642\u06CC \u0645\u0648\u0627\u0631\u062F",
  itemPaddingY: "\u062D\u0627\u0634\u06CC\u0647 \u062F\u0627\u062E\u0644\u06CC \u0639\u0645\u0648\u062F\u06CC \u0645\u0648\u0627\u0631\u062F",
  layoutHorizontalSpacing: "\u0641\u0627\u0635\u0644\u0647 \u0627\u0641\u0642\u06CC",
  layoutVerticalSpacing: "\u0641\u0627\u0635\u0644\u0647 \u0639\u0645\u0648\u062F\u06CC",
  strokeWidth: "\u0639\u0631\u0636 \u062E\u0637",
  offset: "\u0627\u0641\u0633\u062A",
  offsets: "\u0627\u0641\u0633\u062A\u200C\u0647\u0627",
  tooltips: "\u062A\u0648\u0636\u06CC\u062D\u0627\u062A",
  callout: "\u062A\u0648\u0636\u06CC\u062D",
  markers: "\u0646\u0634\u0627\u0646\u06AF\u0631\u0647\u0627",
  shadow: "\u0633\u0627\u06CC\u0647",
  blur: "\u062A\u0627\u0631\u06CC",
  xOffset: "\u0627\u0641\u0633\u062A X",
  yOffset: "\u0627\u0641\u0633\u062A Y",
  lineWidth: "\u0639\u0631\u0636 \u062E\u0637",
  lineDash: "\u062E\u0637 \u062A\u06CC\u0631\u0647",
  lineDashOffset: "\u0627\u0641\u0633\u062A \u062E\u0637 \u062A\u06CC\u0631\u0647",
  scrollingZoom: "\u067E\u06CC\u0645\u0627\u06CC\u0634",
  scrollingStep: "\u0645\u0631\u062D\u0644\u0647 \u067E\u06CC\u0645\u0627\u06CC\u0634",
  selectingZoom: "\u0627\u0646\u062A\u062E\u0627\u0628",
  durationMillis: "\u0645\u062F\u062A (\u0645\u06CC\u0644\u06CC\u200C\u062B\u0627\u0646\u06CC\u0647)",
  crosshairLabel: "\u0628\u0631\u0686\u0633\u0628",
  crosshairSnap: "\u0646\u0642\u0637\u0647 \u0686\u0633\u0628\u0646\u062F\u0647",
  normal: "\u0639\u0627\u062F\u06CC",
  bold: "\u067E\u0631\u0631\u0646\u06AF",
  italic: "\u06A9\u062C",
  boldItalic: "\u067E\u0631\u0631\u0646\u06AF \u06A9\u062C",
  predefined: "\u0627\u0632 \u067E\u06CC\u0634 \u062A\u0639\u06CC\u06CC\u0646 \u0634\u062F\u0647",
  fillOpacity: "\u0634\u0641\u0627\u0641\u06CC\u062A \u067E\u0631 \u06A9\u0631\u062F\u0646",
  strokeColor: "\u0631\u0646\u06AF \u062E\u0637",
  strokeOpacity: "\u0634\u0641\u0627\u0641\u06CC\u062A \u062E\u0637",
  miniChart: "\u0645\u06CC\u0646\u06CC \u0646\u0645\u0648\u062F\u0627\u0631",
  histogramBinCount: "\u062A\u0639\u062F\u0627\u062F \u062F\u0633\u062A\u0647\u200C\u0647\u0627",
  connectorLine: "\u062E\u0637 \u0627\u062A\u0635\u0627\u0644",
  seriesItems: "\u0645\u0648\u0627\u0631\u062F \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647\u200C\u0647\u0627",
  seriesItemType: "\u0646\u0648\u0639 \u0645\u0648\u0631\u062F",
  seriesItemPositive: "\u0645\u062B\u0628\u062A",
  seriesItemNegative: "\u0645\u0646\u0641\u06CC",
  seriesItemLabels: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0645\u0648\u0627\u0631\u062F",
  columnGroup: "\u0633\u062A\u0648\u0646",
  barGroup: "\u0646\u0648\u0627\u0631",
  pieGroup: "\u062F\u0627\u06CC\u0631\u0647\u200C\u0627\u06CC",
  lineGroup: "\u062E\u0637",
  scatterGroup: "X Y (\u067E\u0631\u0627\u06A9\u0646\u062F\u0647)",
  areaGroup: "\u0645\u0646\u0637\u0642\u0647",
  polarGroup: "\u0642\u0637\u0628\u06CC",
  statisticalGroup: "\u0622\u0645\u0627\u0631\u06CC",
  hierarchicalGroup: "\u0633\u0644\u0633\u0644\u0647 \u0645\u0631\u0627\u062A\u0628\u06CC",
  specializedGroup: "\u062A\u062E\u0635\u0635\u06CC",
  combinationGroup: "\u062A\u0631\u06A9\u06CC\u0628\u06CC",
  groupedColumnTooltip: "\u06AF\u0631\u0648\u0647\u06CC",
  stackedColumnTooltip: "\u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedColumnTooltip: "\u06F1\u06F0\u06F0\u066A \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  groupedBarTooltip: "\u06AF\u0631\u0648\u0647\u06CC",
  stackedBarTooltip: "\u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedBarTooltip: "\u06F1\u06F0\u06F0\u066A \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  pieTooltip: "\u062F\u0627\u06CC\u0631\u0647\u200C\u0627\u06CC",
  donutTooltip: "\u062F\u0648\u0646\u0627\u062A",
  lineTooltip: "\u062E\u0637",
  groupedAreaTooltip: "\u0645\u0646\u0637\u0642\u0647 \u06AF\u0631\u0648\u0647\u06CC",
  stackedAreaTooltip: "\u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedAreaTooltip: "\u06F1\u06F0\u06F0\u066A \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  scatterTooltip: "\u067E\u0631\u0627\u06A9\u0646\u062F\u0647",
  bubbleTooltip: "\u062D\u0628\u0627\u0628",
  histogramTooltip: "\u0647\u06CC\u0633\u062A\u0648\u06AF\u0631\u0627\u0645",
  radialColumnTooltip: "\u0633\u062A\u0648\u0646 \u0634\u0639\u0627\u0639\u06CC",
  radialBarTooltip: "\u0646\u0648\u0627\u0631 \u0634\u0639\u0627\u0639\u06CC",
  radarLineTooltip: "\u062E\u0637 \u0631\u0627\u062F\u0627\u0631\u06CC",
  radarAreaTooltip: "\u0645\u0646\u0637\u0642\u0647 \u0631\u0627\u062F\u0627\u0631\u06CC",
  nightingaleTooltip: "\u0646\u0627\u06CC\u062A\u200C\u0627\u0646\u06AF\u0644",
  rangeBarTooltip: "\u0646\u0648\u0627\u0631 \u0645\u062D\u062F\u0648\u062F\u0647",
  rangeAreaTooltip: "\u0645\u0646\u0637\u0642\u0647 \u0645\u062D\u062F\u0648\u062F\u0647",
  boxPlotTooltip: "\u0646\u0645\u0648\u062F\u0627\u0631 \u062C\u0639\u0628\u0647\u200C\u0627\u06CC",
  treemapTooltip: "\u0646\u0642\u0634\u0647 \u062F\u0631\u062E\u062A\u06CC",
  sunburstTooltip: "\u062D\u0644\u0642\u0647\u200C\u0647\u0627\u06CC \u062E\u0648\u0631\u0634\u06CC\u062F",
  waterfallTooltip: "\u0622\u0628\u0634\u0627\u0631",
  heatmapTooltip: "\u0646\u0642\u0634\u0647 \u062D\u0631\u0627\u0631\u062A\u06CC",
  columnLineComboTooltip: "\u0633\u062A\u0648\u0646 \u0648 \u062E\u0637",
  areaColumnComboTooltip: "\u0645\u0646\u0637\u0642\u0647 \u0648 \u0633\u062A\u0648\u0646",
  customComboTooltip: "\u062A\u0631\u06A9\u06CC\u0628 \u0633\u0641\u0627\u0631\u0634\u06CC",
  innerRadius: "\u0634\u0639\u0627\u0639 \u062F\u0627\u062E\u0644\u06CC",
  startAngle: "\u0632\u0627\u0648\u06CC\u0647 \u0634\u0631\u0648\u0639",
  endAngle: "\u0632\u0627\u0648\u06CC\u0647 \u067E\u0627\u06CC\u0627\u0646",
  reverseDirection: "\u0628\u0631\u06AF\u0634\u062A \u062C\u0647\u062A",
  groupPadding: "\u062D\u0627\u0634\u06CC\u0647 \u06AF\u0631\u0648\u0647",
  seriesPadding: "\u062D\u0627\u0634\u06CC\u0647 \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647\u200C\u0647\u0627",
  tile: "\u06A9\u0627\u0634\u06CC",
  whisker: "\u0633\u0628\u06CC\u0644",
  cap: "\u067E\u0648\u0634\u0634",
  capLengthRatio: "\u0646\u0633\u0628\u062A \u0637\u0648\u0644 \u067E\u0648\u0634\u0634",
  labelPlacement: "\u0645\u062D\u0644 \u0642\u0631\u0627\u0631\u06AF\u06CC\u0631\u06CC \u0628\u0631\u0686\u0633\u0628",
  inside: "\u062F\u0627\u062E\u0644",
  outside: "\u062E\u0627\u0631\u062C",
  noDataToChart: "\u062F\u0627\u062F\u0647\u200C\u0647\u0627\u06CC \u0645\u0648\u062C\u0648\u062F \u0628\u0631\u0627\u06CC \u062A\u0631\u0633\u06CC\u0645 \u0646\u0645\u0648\u062F\u0627\u0631 \u0646\u06CC\u0633\u062A.",
  pivotChartRequiresPivotMode: "\u0646\u0645\u0648\u062F\u0627\u0631 \u0645\u062D\u0648\u0631 \u0646\u06CC\u0627\u0632 \u0628\u0647 \u062D\u0627\u0644\u062A \u0645\u062D\u0648\u0631\u06CC \u0641\u0639\u0627\u0644 \u062F\u0627\u0631\u062F.",
  chartSettingsToolbarTooltip: "\u0645\u0646\u0648",
  chartLinkToolbarTooltip: "\u067E\u06CC\u0648\u0646\u062F \u0628\u0627 \u0634\u0628\u06A9\u0647",
  chartUnlinkToolbarTooltip: "\u0628\u062F\u0648\u0646 \u067E\u06CC\u0648\u0646\u062F \u0627\u0632 \u0634\u0628\u06A9\u0647",
  chartDownloadToolbarTooltip: "\u062F\u0627\u0646\u0644\u0648\u062F \u0646\u0645\u0648\u062F\u0627\u0631",
  chartMenuToolbarTooltip: "\u0645\u0646\u0648",
  chartEdit: "\u0648\u06CC\u0631\u0627\u06CC\u0634 \u0646\u0645\u0648\u062F\u0627\u0631",
  chartAdvancedSettings: "\u062A\u0646\u0638\u06CC\u0645\u0627\u062A \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  chartLink: "\u067E\u06CC\u0648\u0646\u062F \u0628\u0627 \u0634\u0628\u06A9\u0647",
  chartUnlink: "\u0628\u062F\u0648\u0646 \u067E\u06CC\u0648\u0646\u062F \u0627\u0632 \u0634\u0628\u06A9\u0647",
  chartDownload: "\u062F\u0627\u0646\u0644\u0648\u062F \u0646\u0645\u0648\u062F\u0627\u0631",
  histogramFrequency: "\u0641\u0631\u06A9\u0627\u0646\u0633",
  seriesChartType: "\u0646\u0648\u0639 \u0646\u0645\u0648\u062F\u0627\u0631 \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647\u200C\u0647\u0627",
  seriesType: "\u0646\u0648\u0639 \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647",
  secondaryAxis: "\u0645\u062D\u0648\u0631 \u0641\u0631\u0639\u06CC",
  seriesAdd: "\u0627\u0641\u0632\u0648\u062F\u0646 \u06CC\u06A9 \u0645\u062C\u0645\u0648\u0639\u0647 \u062F\u0627\u062F\u0647",
  categoryAdd: "\u0627\u0641\u0632\u0648\u062F\u0646 \u06CC\u06A9 \u062F\u0633\u062A\u0647",
  bar: "\u0646\u0648\u0627\u0631",
  column: "\u0633\u062A\u0648\u0646",
  histogram: "\u0647\u06CC\u0633\u062A\u0648\u06AF\u0631\u0627\u0645",
  advancedSettings: "\u062A\u0646\u0638\u06CC\u0645\u0627\u062A \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  direction: "\u062C\u0647\u062A",
  horizontal: "\u0627\u0641\u0642\u06CC",
  vertical: "\u0639\u0645\u0648\u062F\u06CC",
  seriesGroupType: "\u0646\u0648\u0639 \u06AF\u0631\u0648\u0647",
  groupedSeriesGroupType: "\u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC\u200C\u0634\u062F\u0647",
  stackedSeriesGroupType: "\u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedSeriesGroupType: "\u06F1\u06F0\u06F0\u066A \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  legendEnabled: "\u0641\u0639\u0627\u0644",
  invalidColor: "\u0645\u0642\u062F\u0627\u0631 \u0631\u0646\u06AF \u0646\u0627\u0645\u0639\u062A\u0628\u0631 \u0627\u0633\u062A",
  groupedColumnFull: "\u0633\u062A\u0648\u0646 \u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC\u200C\u0634\u062F\u0647",
  stackedColumnFull: "\u0633\u062A\u0648\u0646 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedColumnFull: "\u06F1\u06F0\u06F0\u066A \u0633\u062A\u0648\u0646 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  groupedBarFull: "\u0646\u0648\u0627\u0631 \u06AF\u0631\u0648\u0647\u200C\u0628\u0646\u062F\u06CC\u200C\u0634\u062F\u0647",
  stackedBarFull: "\u0646\u0648\u0627\u0631 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedBarFull: "\u06F1\u06F0\u06F0\u066A \u0646\u0648\u0627\u0631 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  stackedAreaFull: "\u0645\u0646\u0637\u0642\u0647 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  normalizedAreaFull: "\u06F1\u06F0\u06F0\u066A \u0645\u0646\u0637\u0642\u0647 \u0627\u0646\u0628\u0627\u0634\u062A\u0647",
  customCombo: "\u062A\u0631\u06A9\u06CC\u0628 \u0633\u0641\u0627\u0631\u0634\u06CC",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u0633\u0637\u062D ${variable}. \u06A9\u0644\u06CC\u062F ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0648\u06CC\u0631\u0627\u06CC\u0634 \u0634\u0648\u062F.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u0633\u0637\u062D ${variable}. ${variable} \u06A9\u0644\u06CC\u062F ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0648\u06CC\u0631\u0627\u06CC\u0634 \u0634\u0648\u062F.",
  ariaAdvancedFilterBuilderList: "\u0644\u06CC\u0633\u062A \u0633\u0627\u0632\u0646\u062F\u0647 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaAdvancedFilterBuilderFilterItem: "\u0634\u0631\u0637 \u0641\u06CC\u0644\u062A\u0631",
  ariaAdvancedFilterBuilderGroupItem: "\u06AF\u0631\u0648\u0647 \u0641\u06CC\u0644\u062A\u0631",
  ariaAdvancedFilterBuilderColumn: "\u0633\u062A\u0648\u0646",
  ariaAdvancedFilterBuilderOption: "\u06AF\u0632\u06CC\u0646\u0647",
  ariaAdvancedFilterBuilderValueP: "\u0627\u0631\u0632\u0634",
  ariaAdvancedFilterBuilderJoinOperator: "\u0639\u0645\u0644\u06AF\u0631 \u0627\u062A\u0635\u0627\u0644",
  ariaAdvancedFilterInput: "\u0648\u0631\u0648\u062F\u06CC \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaChecked: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u062F\u0647",
  ariaColumn: "\u0633\u062A\u0648\u0646",
  ariaColumnGroup: "\u06AF\u0631\u0648\u0647 \u0633\u062A\u0648\u0646",
  ariaColumnFiltered: "\u0633\u062A\u0648\u0646 \u0641\u06CC\u0644\u062A\u0631 \u0634\u062F\u0647",
  ariaColumnSelectAll: "\u062A\u063A\u06CC\u06CC\u0631 \u0627\u0646\u062A\u062E\u0627\u0628 \u0647\u0645\u0647 \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  ariaDateFilterInput: "\u0648\u0631\u0648\u062F\u06CC \u0641\u06CC\u0644\u062A\u0631 \u062A\u0627\u0631\u06CC\u062E",
  ariaDefaultListName: "\u0644\u06CC\u0633\u062A",
  ariaFilterColumnsInput: "\u0648\u0631\u0648\u062F\u06CC \u0641\u06CC\u0644\u062A\u0631 \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  ariaFilterFromValue: "\u0641\u06CC\u0644\u062A\u0631 \u0627\u0632 \u0645\u0642\u062F\u0627\u0631",
  ariaFilterInput: "\u0648\u0631\u0648\u062F\u06CC \u0641\u06CC\u0644\u062A\u0631",
  ariaFilterList: "\u0644\u06CC\u0633\u062A \u0641\u06CC\u0644\u062A\u0631",
  ariaFilterToValue: "\u0641\u06CC\u0644\u062A\u0631 \u062A\u0627 \u0645\u0642\u062F\u0627\u0631",
  ariaFilterValue: "\u0645\u0642\u062F\u0627\u0631 \u0641\u06CC\u0644\u062A\u0631",
  ariaFilterMenuOpen: "\u0628\u0627\u0632 \u06A9\u0631\u062F\u0646 \u0645\u0646\u0648\u06CC \u0641\u06CC\u0644\u062A\u0631",
  ariaFilteringOperator: "\u0639\u0645\u0644\u06AF\u0631 \u0641\u06CC\u0644\u062A\u0631 \u06A9\u0631\u062F\u0646",
  ariaHidden: "\u0645\u062E\u0641\u06CC",
  ariaIndeterminate: "\u0646\u0627\u0645\u0634\u062E\u0635",
  ariaInputEditor: "\u0648\u06CC\u0631\u0627\u06CC\u0634\u06AF\u0631 \u0648\u0631\u0648\u062F\u06CC",
  ariaMenuColumn: "ALT+\u067E\u0627\u06CC\u06CC\u0646 \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0645\u0646\u0648\u06CC \u0633\u062A\u0648\u0646 \u0628\u0627\u0632 \u0634\u0648\u062F",
  ariaFilterColumn: "CTRL+ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0641\u06CC\u0644\u062A\u0631 \u0631\u0627 \u0628\u0627\u0632 \u06A9\u0646\u06CC\u062F",
  ariaRowDeselect: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0627\u06CC\u0646 \u0633\u0637\u0631 \u0627\u0632 \u0627\u0646\u062A\u062E\u0627\u0628 \u062E\u0627\u0631\u062C \u0634\u0648\u062F",
  ariaRowSelectAll: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0647\u0645\u0647 \u0633\u0637\u0631\u0647\u0627 \u0627\u0646\u062A\u062E\u0627\u0628/\u063A\u06CC\u0631\u0641\u0639\u0627\u0644 \u0634\u0648\u0646\u062F",
  ariaRowToggleSelection: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u0637\u0631 \u062A\u063A\u06CC\u06CC\u0631 \u06CC\u0627\u0628\u062F",
  ariaRowSelect: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0627\u06CC\u0646 \u0633\u0637\u0631 \u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u0648\u062F",
  ariaRowSelectionDisabled: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u0637\u0631 \u0628\u0631\u0627\u06CC \u0627\u06CC\u0646 \u0633\u0637\u0631 \u063A\u06CC\u0631\u0641\u0639\u0627\u0644 \u0627\u0633\u062A",
  ariaSearch: "\u062C\u0633\u062A\u062C\u0648",
  ariaSortableColumn: "ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0645\u0631\u062A\u0628 \u0634\u0648\u062F",
  ariaToggleVisibility: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0642\u0627\u0628\u0644\u06CC\u062A \u0631\u0648\u06CC\u062A \u062A\u063A\u06CC\u06CC\u0631 \u06CC\u0627\u0628\u062F",
  ariaToggleCellValue: "SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F \u062A\u0627 \u0645\u0642\u062F\u0627\u0631 \u0633\u0644\u0648\u0644 \u062A\u063A\u06CC\u06CC\u0631 \u06CC\u0627\u0628\u062F",
  ariaUnchecked: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0646\u0634\u062F\u0647",
  ariaVisible: "\u0642\u0627\u0628\u0644 \u0645\u0634\u0627\u0647\u062F\u0647",
  ariaSearchFilterValues: "\u0645\u0642\u0627\u062F\u06CC\u0631 \u0641\u06CC\u0644\u062A\u0631 \u062C\u0633\u062A\u062C\u0648",
  ariaPageSizeSelectorLabel: "\u0627\u0646\u062F\u0627\u0632\u0647 \u0635\u0641\u062D\u0647",
  ariaChartMenuClose: "\u0628\u0633\u062A\u0646 \u0645\u0646\u0648\u06CC \u0648\u06CC\u0631\u0627\u06CC\u0634 \u0646\u0645\u0648\u062F\u0627\u0631",
  ariaChartSelected: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0634\u062F\u0647",
  ariaSkeletonCellLoadingFailed: "\u0628\u0627\u0631\u06AF\u06CC\u0631\u06CC \u0631\u062F\u06CC\u0641 \u0646\u0627\u0645\u0648\u0641\u0642 \u0628\u0648\u062F",
  ariaSkeletonCellLoading: "\u062F\u0627\u062F\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641 \u062F\u0631 \u062D\u0627\u0644 \u0628\u0627\u0631\u06AF\u06CC\u0631\u06CC \u0627\u0633\u062A",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u06AF\u0631\u0648\u0647\u200C\u0647\u0627\u06CC \u0631\u062F\u06CC\u0641\u06CC",
  ariaValuesDropZonePanelLabel: "\u0645\u0642\u0627\u062F\u06CC\u0631",
  ariaPivotDropZonePanelLabel: "\u0628\u0631\u0686\u0633\u0628\u200C\u0647\u0627\u06CC \u0633\u062A\u0648\u0646",
  ariaDropZoneColumnComponentDescription: "\u0628\u0631\u0627\u06CC \u062D\u0630\u0641 \u06A9\u0644\u06CC\u062F DELETE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  ariaDropZoneColumnValueItemDescription: "\u0628\u0631\u0627\u06CC \u062A\u063A\u06CC\u06CC\u0631 \u0646\u0648\u0639 \u062A\u062C\u0645\u0639 \u06A9\u0644\u06CC\u062F ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  ariaDropZoneColumnGroupItemDescription: "\u0628\u0631\u0627\u06CC \u0645\u0631\u062A\u0628\u200C\u0633\u0627\u0632\u06CC \u06A9\u0644\u06CC\u062F ENTER \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u0627\u0632 ",
  ariaDropZoneColumnComponentSortAscending: "\u0635\u0639\u0648\u062F\u06CC",
  ariaDropZoneColumnComponentSortDescending: "\u0646\u0632\u0648\u0644\u06CC",
  ariaLabelDialog: "\u06AF\u0641\u062A\u06AF\u0648",
  ariaLabelColumnMenu: "\u0645\u0646\u0648\u06CC \u0633\u062A\u0648\u0646",
  ariaLabelColumnFilter: "\u0641\u06CC\u0644\u062A\u0631 \u0633\u062A\u0648\u0646",
  ariaLabelCellEditor: "\u0648\u06CC\u0631\u0627\u06CC\u0634\u06AF\u0631 \u0633\u0644\u0648\u0644",
  ariaLabelSelectField: "\u0627\u0646\u062A\u062E\u0627\u0628 \u0641\u06CC\u0644\u062F",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u0641\u06CC\u0644\u062F \u0627\u0646\u062A\u062E\u0627\u0628 \u063A\u0646\u06CC",
  ariaLabelRichSelectToggleSelection: "\u0628\u0631\u0627\u06CC \u062A\u063A\u06CC\u06CC\u0631 \u0627\u0646\u062A\u062E\u0627\u0628 SPACE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  ariaLabelRichSelectDeselectAllItems: "\u0628\u0631\u0627\u06CC \u0644\u063A\u0648 \u0627\u0646\u062A\u062E\u0627\u0628 \u0647\u0645\u0647 \u0645\u0648\u0627\u0631\u062F DELETE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  ariaLabelRichSelectDeleteSelection: "\u0628\u0631\u0627\u06CC \u0644\u063A\u0648 \u0627\u0646\u062A\u062E\u0627\u0628 \u0645\u0648\u0631\u062F DELETE \u0631\u0627 \u0641\u0634\u0627\u0631 \u062F\u0647\u06CC\u062F",
  ariaLabelTooltip: "\u0627\u0628\u0632\u0627\u0631\u06A9",
  ariaLabelContextMenu: "\u0645\u0646\u0648\u06CC \u0632\u0645\u06CC\u0646\u0647\u200C\u0627\u06CC",
  ariaLabelSubMenu: "\u0632\u06CC\u0631\u0645\u0646\u0648",
  ariaLabelAggregationFunction: "\u062A\u0627\u0628\u0639 \u062A\u062C\u0645\u0639",
  ariaLabelAdvancedFilterAutocomplete: "\u062A\u06A9\u0645\u06CC\u0644 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaLabelAdvancedFilterBuilderAddField: "\u0627\u0641\u0632\u0648\u062F\u0646 \u0641\u06CC\u0644\u062F \u062C\u062F\u06CC\u062F \u0628\u0647 \u0633\u0627\u0632\u0646\u062F\u0647 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u0641\u06CC\u0644\u062F \u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u062A\u0648\u0646 \u0633\u0627\u0632\u0646\u062F\u0647 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u0641\u06CC\u0644\u062F \u0627\u0646\u062A\u062E\u0627\u0628 \u06AF\u0632\u06CC\u0646\u0647 \u0633\u0627\u0632\u0646\u062F\u0647 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u0641\u06CC\u0644\u062F \u0627\u0646\u062A\u062E\u0627\u0628 \u0639\u0645\u0644\u06AF\u0631 \u067E\u06CC\u0648\u0633\u062A\u0646 \u0633\u0627\u0632\u0646\u062F\u0647 \u0641\u06CC\u0644\u062A\u0631 \u067E\u06CC\u0634\u0631\u0641\u062A\u0647",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u0641\u0647\u0631\u0633\u062A \u0633\u062A\u0648\u0646\u200C\u0647\u0627",
  ariaFilterPanelList: "\u0641\u0647\u0631\u0633\u062A \u0641\u06CC\u0644\u062A\u0631\u0647\u0627",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "\u060C",
  decimalSeparator: ".",
  // Data types
  true: "\u062F\u0631\u0633\u062A",
  false: "\u0646\u0627\u062F\u0631\u0633\u062A",
  invalidDate: "\u062A\u0627\u0631\u06CC\u062E \u0646\u0627\u0645\u0639\u062A\u0628\u0631",
  invalidNumber: "\u0639\u062F\u062F \u0646\u0627\u0645\u0639\u062A\u0628\u0631",
  january: "\u0698\u0627\u0646\u0648\u06CC\u0647",
  february: "\u0641\u0648\u0631\u06CC\u0647",
  march: "\u0645\u0627\u0631\u0633",
  april: "\u0622\u0648\u0631\u06CC\u0644",
  may: "\u0645\u0647",
  june: "\u0698\u0648\u0626\u0646",
  july: "\u0698\u0648\u0626\u06CC\u0647",
  august: "\u0627\u0648\u062A",
  september: "\u0633\u067E\u062A\u0627\u0645\u0628\u0631",
  october: "\u0627\u06A9\u062A\u0628\u0631",
  november: "\u0646\u0648\u0627\u0645\u0628\u0631",
  december: "\u062F\u0633\u0627\u0645\u0628\u0631",
  // Time formats
  timeFormatSlashesDDMMYYYY: "\u0631\u0648\u0632/\u0645\u0627\u0647/\u0633\u0627\u0644",
  timeFormatSlashesMMDDYYYY: "\u0645\u0627\u0647/\u0631\u0648\u0632/\u0633\u0627\u0644",
  timeFormatSlashesDDMMYY: "\u0631\u0648\u0632/\u0645\u0627\u0647/\u0633\u0627\u0644",
  timeFormatSlashesMMDDYY: "\u0645\u0627\u0647/\u0631\u0648\u0632/\u0633\u0627\u0644",
  timeFormatDotsDDMYY: "\u0631\u0648\u0632.\u0645\u0627\u0647.\u0633\u0627\u0644",
  timeFormatDotsMDDYY: "\u0645\u0627\u0647.\u0631\u0648\u0632.\u0633\u0627\u0644",
  timeFormatDashesYYYYMMDD: "\u0633\u0627\u0644-\u0645\u0627\u0647-\u0631\u0648\u0632",
  timeFormatSpacesDDMMMMYYYY: "\u0631\u0648\u0632 \u0645\u0627\u0647 \u0633\u0627\u0644",
  timeFormatHHMMSS: "\u0633\u0627\u0639\u062A:\u062F\u0642\u06CC\u0642\u0647:\u062B\u0627\u0646\u06CC\u0647",
  timeFormatHHMMSSAmPm: "\u0633\u0627\u0639\u062A:\u062F\u0642\u06CC\u0642\u0647:\u062B\u0627\u0646\u06CC\u0647 \u0642\u0628\u0644/\u0628\u0639\u062F \u0627\u0632 \u0638\u0647\u0631"
};

// community-modules/locale/src/pl-PL.ts
var AG_GRID_LOCALE_PL = {
  // Set Filter
  selectAll: "(Zaznacz wszystko)",
  selectAllSearchResults: "(Zaznacz wszystkie wyniki wyszukiwania)",
  addCurrentSelectionToFilter: "Dodaj bie\u017C\u0105cy wyb\xF3r do filtra",
  searchOoo: "Szukaj...",
  blanks: "(Puste)",
  noMatches: "Brak wynik\xF3w",
  // Number Filter & Text Filter
  filterOoo: "Filtruj...",
  equals: "R\xF3wna si\u0119",
  notEqual: "Nie r\xF3wna si\u0119",
  blank: "Puste",
  notBlank: "Niepuste",
  empty: "Wybierz jeden",
  // Number Filter
  lessThan: "Mniej ni\u017C",
  greaterThan: "Wi\u0119cej ni\u017C",
  lessThanOrEqual: "Mniej lub r\xF3wne",
  greaterThanOrEqual: "Wi\u0119cej lub r\xF3wne",
  inRange: "Pomi\u0119dzy",
  inRangeStart: "Od",
  inRangeEnd: "Do",
  // Text Filter
  contains: "Zawiera",
  notContains: "Nie zawiera",
  startsWith: "Zaczyna si\u0119 od",
  endsWith: "Ko\u0144czy si\u0119 na",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Przed",
  after: "Po",
  // Filter Conditions
  andCondition: "I",
  orCondition: "LUB",
  // Filter Buttons
  applyFilter: "Zastosuj",
  resetFilter: "Resetuj",
  clearFilter: "Wyczy\u015B\u0107",
  cancelFilter: "Anuluj",
  // Filter Titles
  textFilter: "Filtr tekstu",
  numberFilter: "Filtr liczb",
  dateFilter: "Filtr dat",
  setFilter: "Filtr zbioru",
  // Group Column Filter
  groupFilterSelect: "Wybierz pole:",
  // Advanced Filter
  advancedFilterContains: "zawiera",
  advancedFilterNotContains: "nie zawiera",
  advancedFilterTextEquals: "r\xF3wna si\u0119",
  advancedFilterTextNotEqual: "nie r\xF3wna si\u0119",
  advancedFilterStartsWith: "zaczyna si\u0119 od",
  advancedFilterEndsWith: "ko\u0144czy si\u0119 na",
  advancedFilterBlank: "jest puste",
  advancedFilterNotBlank: "nie jest puste",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "jest prawd\u0105",
  advancedFilterFalse: "jest fa\u0142szem",
  advancedFilterAnd: "AND",
  advancedFilterOr: "OR",
  advancedFilterApply: "Zastosuj",
  advancedFilterBuilder: "Kreator",
  advancedFilterValidationMissingColumn: "Brak kolumny",
  advancedFilterValidationMissingOption: "Brak opcji",
  advancedFilterValidationMissingValue: "Brak warto\u015Bci",
  advancedFilterValidationInvalidColumn: "Kolumna nie istnieje",
  advancedFilterValidationInvalidOption: "Opcja nie istnieje",
  advancedFilterValidationMissingQuote: "Brak ko\u0144cowego cudzys\u0142owu",
  advancedFilterValidationNotANumber: "Warto\u015B\u0107 nie jest liczb\u0105",
  advancedFilterValidationInvalidDate: "Warto\u015B\u0107 nie jest prawid\u0142ow\u0105 dat\u0105",
  advancedFilterValidationMissingCondition: "Brak warunku",
  advancedFilterValidationJoinOperatorMismatch: "Operatory \u0142\u0105czenia w ramach warunku musz\u0105 by\u0107 takie same",
  advancedFilterValidationInvalidJoinOperator: "Operator \u0142\u0105czenia nie istnieje",
  advancedFilterValidationMissingEndBracket: "Brak ko\u0144cowego nawiasu",
  advancedFilterValidationExtraEndBracket: "Za du\u017Co ko\u0144cowych nawias\xF3w",
  advancedFilterValidationMessage: "Wyra\u017Cenie zawiera b\u0142\u0105d. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Wyra\u017Cenie zawiera b\u0142\u0105d. ${variable} na ko\u0144cu wyra\u017Cenia.",
  advancedFilterBuilderTitle: "Zaawansowany filtr",
  advancedFilterBuilderApply: "Zastosuj",
  advancedFilterBuilderCancel: "Anuluj",
  advancedFilterBuilderAddButtonTooltip: "Dodaj filtr lub grup\u0119",
  advancedFilterBuilderRemoveButtonTooltip: "Usu\u0144",
  advancedFilterBuilderMoveUpButtonTooltip: "Przenie\u015B w g\xF3r\u0119",
  advancedFilterBuilderMoveDownButtonTooltip: "Przenie\u015B w d\xF3\u0142",
  advancedFilterBuilderAddJoin: "Dodaj grup\u0119",
  advancedFilterBuilderAddCondition: "Dodaj filtr",
  advancedFilterBuilderSelectColumn: "Wybierz kolumn\u0119",
  advancedFilterBuilderSelectOption: "Wybierz opcj\u0119",
  advancedFilterBuilderEnterValue: "Wpisz warto\u015B\u0107...",
  advancedFilterBuilderValidationAlreadyApplied: "Obecny filtr ju\u017C zastosowany.",
  advancedFilterBuilderValidationIncomplete: "Nie wszystkie warunki s\u0105 kompletne.",
  advancedFilterBuilderValidationSelectColumn: "Musisz wybra\u0107 kolumn\u0119.",
  advancedFilterBuilderValidationSelectOption: "Musisz wybra\u0107 opcj\u0119.",
  advancedFilterBuilderValidationEnterValue: "Musisz wpisa\u0107 warto\u015B\u0107.",
  // Side Bar
  columns: "Kolumny",
  filters: "Filtry",
  // columns tool panel
  pivotMode: "Tryb przestawny",
  groups: "Grupy wierszy",
  rowGroupColumnsEmptyMessage: "Przeci\u0105gnij tutaj, aby ustawi\u0107 grupy wierszy",
  values: "Warto\u015Bci",
  valueColumnsEmptyMessage: "Przeci\u0105gnij tutaj, aby agregowa\u0107",
  pivots: "Etykiety kolumn",
  pivotColumnsEmptyMessage: "Przeci\u0105gnij tutaj, aby ustawi\u0107 etykiety kolumn",
  // Header of the Default Group Column
  group: "Grupa",
  // Row Drag
  rowDragRow: "wiersz",
  rowDragRows: "wiersze",
  // Other
  loadingOoo: "\u0141adowanie...",
  loadingError: "B\u0141\u0104D",
  noRowsToShow: "Brak wierszy do wy\u015Bwietlenia",
  enabled: "W\u0142\u0105czone",
  // Menu
  pinColumn: "Przypnij Kolumn\u0119",
  pinLeft: "Przypnij z Lewa",
  pinRight: "Przypnij z Prawa",
  noPin: "Nie Przypinaj",
  valueAggregation: "Agregacja Warto\u015Bci",
  noAggregation: "Brak",
  autosizeThisColumn: "Autozmiar tej Kolumny",
  autosizeAllColumns: "Autozmiar wszystkich Kolumn",
  groupBy: "Grupuj wed\u0142ug",
  ungroupBy: "Rozgrupuj wed\u0142ug",
  ungroupAll: "Rozgrupuj Wszystko",
  addToValues: "Dodaj ${variable} do warto\u015Bci",
  removeFromValues: "Usu\u0144 ${variable} z warto\u015Bci",
  addToLabels: "Dodaj ${variable} do etykiet",
  removeFromLabels: "Usu\u0144 ${variable} z etykiet",
  resetColumns: "Zresetuj Kolumny",
  expandAll: "Rozwi\u0144 Wszystkie Grupy Wierszy",
  collapseAll: "Zwi\u0144 Wszystkie Grupy Wierszy",
  copy: "Kopiuj",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopiuj z Nag\u0142\xF3wkami",
  copyWithGroupHeaders: "Kopiuj z Nag\u0142\xF3wkami Grup",
  cut: "Wytnij",
  paste: "Wklej",
  ctrlV: "Ctrl+V",
  export: "Eksportuj",
  csvExport: "Eksport CSV",
  excelExport: "Eksport Excel",
  columnFilter: "Filtr Kolumny",
  columnChooser: "Wybierz Kolumny",
  chooseColumns: "Wybierz Kolumny",
  sortAscending: "Sortuj Rosn\u0105co",
  sortDescending: "Sortuj Malej\u0105co",
  sortUnSort: "Usu\u0144 Sortowanie",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Suma",
  first: "Pierwszy",
  last: "Ostatni",
  min: "Min",
  max: "Maks",
  none: "Brak",
  count: "Liczba",
  avg: "\u015Arednia",
  filteredRows: "Filtrowane",
  selectedRows: "Wybrane",
  totalRows: "Ca\u0142kowita liczba wierszy",
  totalAndFilteredRows: "Wiersze",
  more: "Wi\u0119cej",
  to: "do",
  of: "z",
  page: "Strona",
  pageLastRowUnknown: "?",
  nextPage: "Nast\u0119pna Strona",
  lastPage: "Ostatnia Strona",
  firstPage: "Pierwsza Strona",
  previousPage: "Poprzednia Strona",
  pageSizeSelectorLabel: "Rozmiar strony:",
  footerTotal: "Razem",
  // Pivoting
  pivotColumnGroupTotals: "Suma",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivotowy wykres i tryb przestawny",
  pivotChart: "Wykres przestawny",
  chartRange: "Zakres danych wykresu",
  columnChart: "Kolumnowy",
  groupedColumn: "Grupowany",
  stackedColumn: "Skumulowany",
  normalizedColumn: "100% Skumulowany",
  barChart: "S\u0142upkowy",
  groupedBar: "Grupowany",
  stackedBar: "Skumulowany",
  normalizedBar: "100% Skumulowany",
  pieChart: "Ko\u0142owy",
  pie: "Ko\u0142owy",
  donut: "Pier\u015Bcieniowy",
  line: "Liniowy",
  xyChart: "XY (Punktowy)",
  scatter: "Punktowy",
  bubble: "B\u0105belkowy",
  areaChart: "Obszarowy",
  area: "Obszarowy",
  stackedArea: "Skumulowany",
  normalizedArea: "100% Skumulowany",
  histogramChart: "Histogram",
  polarChart: "Polarny",
  radarLine: "Liniowy radarowy",
  radarArea: "Obszarowy radarowy",
  nightingale: "Nightingale",
  radialColumn: "Kolumnowy radialny",
  radialBar: "S\u0142upkowy radialny",
  statisticalChart: "Statystyczny",
  boxPlot: "Wykres ramkowy",
  rangeBar: "Zakres s\u0142upkowy",
  rangeArea: "Zakres obszarowy",
  hierarchicalChart: "Hierarchiczny",
  treemap: "Mapa drzewa",
  sunburst: "S\u0142oneczny",
  specializedChart: "Specjalizowany",
  waterfall: "Kaskadowy",
  heatmap: "Mapa cieplna",
  combinationChart: "Po\u0142\u0105czony",
  columnLineCombo: "Kolumna i linia",
  AreaColumnCombo: "Obszar i kolumna",
  // Charts
  pivotChartTitle: "Wykres przestawny",
  rangeChartTitle: "Wykres zakresu",
  settings: "Wykres",
  data: "Ustawienia",
  format: "Dostosuj",
  categories: "Kategorie",
  defaultCategory: "(Brak)",
  series: "Serie",
  switchCategorySeries: "Prze\u0142\u0105cz kategori\u0119 / seri\u0119",
  categoryValues: "Warto\u015Bci kategorii",
  seriesLabels: "Etykiety serii",
  aggregate: "Agreguj",
  xyValues: "Warto\u015Bci XY",
  paired: "Tryb parowania",
  axis: "O\u015B",
  xAxis: "O\u015B pozioma",
  yAxis: "O\u015B pionowa",
  polarAxis: "O\u015B polarna",
  radiusAxis: "O\u015B promieniowa",
  navigator: "Nawigator",
  zoom: "Powi\u0119kszenie",
  animation: "Animacja",
  crosshair: "Krzy\u017Cyk",
  color: "Kolor",
  thickness: "Grubo\u015B\u0107",
  preferredLength: "Preferowana d\u0142ugo\u015B\u0107",
  xType: "Typ X",
  axisType: "Typ osi",
  automatic: "Automatyczne",
  category: "Kategoria",
  number: "Liczba",
  time: "Czas",
  timeFormat: "Format czasu",
  autoRotate: "Auto obr\xF3t",
  labelRotation: "Obr\xF3t etykiety",
  circle: "Okr\u0105g",
  polygon: "Wielok\u0105t",
  square: "Kwadrat",
  cross: "Krzy\u017Cyk",
  diamond: "Romb",
  plus: "Plus",
  triangle: "Tr\xF3jk\u0105t",
  heart: "Serce",
  orientation: "Orientacja",
  fixed: "Sta\u0142a",
  parallel: "R\xF3wnoleg\u0142a",
  perpendicular: "Prostopad\u0142a",
  radiusAxisPosition: "Pozycja",
  ticks: "Znaczniki",
  gridLines: "Linie siatki",
  width: "Szeroko\u015B\u0107",
  height: "Wysoko\u015B\u0107",
  length: "D\u0142ugo\u015B\u0107",
  padding: "Margines",
  spacing: "Odst\u0119py",
  chartStyle: "Styl wykresu",
  title: "Tytu\u0142",
  chartTitles: "Tytu\u0142y",
  chartTitle: "Tytu\u0142 wykresu",
  chartSubtitle: "Podtytu\u0142",
  horizontalAxisTitle: "Tytu\u0142 osi poziomej",
  verticalAxisTitle: "Tytu\u0142 osi pionowej",
  polarAxisTitle: "Tytu\u0142 osi polarnej",
  titlePlaceholder: "Tytu\u0142 wykresu",
  background: "T\u0142o",
  font: "Czcionka",
  weight: "Waga",
  top: "G\xF3ra",
  right: "Prawo",
  bottom: "D\xF3\u0142",
  left: "Lewo",
  labels: "Etykiety",
  calloutLabels: "Etykiety wywo\u0142a\u0144",
  sectorLabels: "Etykiety sektorowe",
  positionRatio: "Stosunek pozycji",
  size: "Rozmiar",
  shape: "Kszta\u0142t",
  minSize: "Minimalny rozmiar",
  maxSize: "Maksymalny rozmiar",
  legend: "Legenda",
  position: "Pozycja",
  markerSize: "Rozmiar znacznika",
  markerStroke: "Obrys znacznika",
  markerPadding: "Margines znacznika",
  itemSpacing: "Odst\u0119p przedmiotu",
  itemPaddingX: "Margines przedmiotu X",
  itemPaddingY: "Margines przedmiotu Y",
  layoutHorizontalSpacing: "Odst\u0119p poziomy",
  layoutVerticalSpacing: "Odst\u0119p pionowy",
  strokeWidth: "Szeroko\u015B\u0107 linii",
  offset: "Przesuni\u0119cie",
  offsets: "Przesuni\u0119cia",
  tooltips: "Podpowiedzi",
  callout: "Wywo\u0142anie",
  markers: "Znaczniki",
  shadow: "Cie\u0144",
  blur: "Rozmycie",
  xOffset: "Przesuni\u0119cie X",
  yOffset: "Przesuni\u0119cie Y",
  lineWidth: "Szeroko\u015B\u0107 linii",
  lineDash: "Kreskowanie",
  lineDashOffset: "Przesuni\u0119cie kreskowania",
  scrollingZoom: "Powi\u0119kszenie przewijania",
  scrollingStep: "Krok przewijania",
  selectingZoom: "Powi\u0119kszenie wybrania",
  durationMillis: "Czas trwania (ms)",
  crosshairLabel: "Etykieta krzy\u017Cyka",
  crosshairSnap: "Przyci\u0105ganie do w\u0119z\u0142a",
  normal: "Normalny",
  bold: "Pogrubiony",
  italic: "Kursywa",
  boldItalic: "Pogrubiona kursywa",
  predefined: "Zdefiniowane",
  fillOpacity: "Przezroczysto\u015B\u0107 wype\u0142nienia",
  strokeColor: "Kolor linii",
  strokeOpacity: "Przezroczysto\u015B\u0107 linii",
  miniChart: "Mini-wykres",
  histogramBinCount: "Liczba koszy",
  connectorLine: "Linia \u0142\u0105cz\u0105ca",
  seriesItems: "Elementy serii",
  seriesItemType: "Typ elementu",
  seriesItemPositive: "Pozytywne",
  seriesItemNegative: "Negatywne",
  seriesItemLabels: "Etykiety element\xF3w",
  columnGroup: "Kolumna",
  barGroup: "S\u0142upek",
  pieGroup: "Wykres ko\u0142owy",
  lineGroup: "Linia",
  scatterGroup: "XY (Punktowy)",
  areaGroup: "Powierzchnia",
  polarGroup: "Polarny",
  statisticalGroup: "Statystyczny",
  hierarchicalGroup: "Hierarchiczny",
  specializedGroup: "Specjalistyczny",
  combinationGroup: "Kombinacja",
  groupedColumnTooltip: "Grupowane",
  stackedColumnTooltip: "Skumulowane",
  normalizedColumnTooltip: "100% skumulowane",
  groupedBarTooltip: "Grupowane",
  stackedBarTooltip: "Skumulowane",
  normalizedBarTooltip: "100% skumulowane",
  pieTooltip: "Wykres ko\u0142owy",
  donutTooltip: "Pier\u015Bcieniowy",
  lineTooltip: "Linia",
  groupedAreaTooltip: "Powierzchnia",
  stackedAreaTooltip: "Skumulowana",
  normalizedAreaTooltip: "100% skumulowana",
  scatterTooltip: "Punktowy",
  bubbleTooltip: "B\u0105belkowy",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Kolumna radialna",
  radialBarTooltip: "S\u0142upek radialny",
  radarLineTooltip: "Linia radarowa",
  radarAreaTooltip: "Powierzchnia radarowa",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "S\u0142upek zakresu",
  rangeAreaTooltip: "Powierzchnia zakresu",
  boxPlotTooltip: "Wykres ramkowy",
  treemapTooltip: "Mapa drzewa",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Kaskadowy",
  heatmapTooltip: "Mapa cieplna",
  columnLineComboTooltip: "Kolumna i linia",
  areaColumnComboTooltip: "Powierzchnia i kolumna",
  customComboTooltip: "W\u0142asna kombinacja",
  innerRadius: "Promie\u0144 wewn\u0119trzny",
  startAngle: "K\u0105t pocz\u0105tkowy",
  endAngle: "K\u0105t ko\u0144cowy",
  reverseDirection: "Odwr\xF3\u0107 kierunek",
  groupPadding: "Margines grupy",
  seriesPadding: "Margines serii",
  tile: "Kafel",
  whisker: "W\u0105s",
  cap: "Kapturek",
  capLengthRatio: "Stosunek d\u0142ugo\u015Bci",
  labelPlacement: "Umiejscowienie etykiety",
  inside: "Wewn\u0105trz",
  outside: "Na zewn\u0105trz",
  noDataToChart: "Brak danych do narysowania.",
  pivotChartRequiresPivotMode: "Wykres przestawny wymaga w\u0142\u0105czenia trybu przestawnego.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Po\u0142\u0105czony z siatk\u0105",
  chartUnlinkToolbarTooltip: "Od\u0142\u0105czony od siatki",
  chartDownloadToolbarTooltip: "Pobierz wykres",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Edytuj wykres",
  chartAdvancedSettings: "Zaawansowane ustawienia",
  chartLink: "Po\u0142\u0105cz z siatk\u0105",
  chartUnlink: "Od\u0142\u0105cz od siatki",
  chartDownload: "Pobierz wykres",
  histogramFrequency: "Cz\u0119stotliwo\u015B\u0107",
  seriesChartType: "Typ wykresu serii",
  seriesType: "Typ serii",
  secondaryAxis: "Druga o\u015B",
  seriesAdd: "Dodaj seri\u0119",
  categoryAdd: "Dodaj kategori\u0119",
  bar: "S\u0142upek",
  column: "Kolumna",
  histogram: "Histogram",
  advancedSettings: "Zaawansowane ustawienia",
  direction: "Kierunek",
  horizontal: "Poziomy",
  vertical: "Pionowy",
  seriesGroupType: "Typ grupy",
  groupedSeriesGroupType: "Grupowane",
  stackedSeriesGroupType: "Skumulowane",
  normalizedSeriesGroupType: "100% skumulowane",
  legendEnabled: "W\u0142\u0105czone",
  invalidColor: "B\u0142\u0119dna warto\u015B\u0107 koloru",
  groupedColumnFull: "Grupowana kolumna",
  stackedColumnFull: "Skumulowana kolumna",
  normalizedColumnFull: "100% skumulowana kolumna",
  groupedBarFull: "Grupowany s\u0142upek",
  stackedBarFull: "Skumulowany s\u0142upek",
  normalizedBarFull: "100% skumulowany s\u0142upek",
  stackedAreaFull: "Skumulowana powierzchnia",
  normalizedAreaFull: "100% skumulowana powierzchnia",
  customCombo: "W\u0142asna kombinacja",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Poziom ${variable}. Naci\u015Bnij ENTER, aby edytowa\u0107",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Poziom ${variable}. ${variable} Naci\u015Bnij ENTER, aby edytowa\u0107.",
  ariaAdvancedFilterBuilderList: "Zaawansowana Lista Kreatora Filtr\xF3w",
  ariaAdvancedFilterBuilderFilterItem: "Warunek Filtra",
  ariaAdvancedFilterBuilderGroupItem: "Grupa Filtr\xF3w",
  ariaAdvancedFilterBuilderColumn: "Kolumna",
  ariaAdvancedFilterBuilderOption: "Opcja",
  ariaAdvancedFilterBuilderValueP: "Warto\u015B\u0107",
  ariaAdvancedFilterBuilderJoinOperator: "Operator Po\u0142\u0105czenia",
  ariaAdvancedFilterInput: "Zaawansowane Wej\u015Bcie Filtra",
  ariaChecked: "zaznaczone",
  ariaColumn: "Kolumna",
  ariaColumnGroup: "Grupa Kolumn",
  ariaColumnFiltered: "Kolumna Filtrowana",
  ariaColumnSelectAll: "Prze\u0142\u0105cz Zaznaczenie Wszystkich Kolumn",
  ariaDateFilterInput: "Wej\u015Bcie Filtra Daty",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Wej\u015Bcie Filtrowania Kolumn",
  ariaFilterFromValue: "Filtr od warto\u015Bci",
  ariaFilterInput: "Wej\u015Bcie Filtra",
  ariaFilterList: "Lista Filtrowania",
  ariaFilterToValue: "Filtr do warto\u015Bci",
  ariaFilterValue: "Warto\u015B\u0107 Filtra",
  ariaFilterMenuOpen: "Otw\xF3rz Menu Filtr\xF3w",
  ariaFilteringOperator: "Operator Filtrowania",
  ariaHidden: "ukryte",
  ariaIndeterminate: "nieokre\u015Blone",
  ariaInputEditor: "Edytor Wej\u015Bcia",
  ariaMenuColumn: "Naci\u015Bnij ALT w d\xF3\u0142, aby otworzy\u0107 menu kolumny",
  ariaFilterColumn: "Naci\u015Bnij CTRL ENTER, aby otworzy\u0107 filtr",
  ariaRowDeselect: "Naci\u015Bnij SPACJ\u0118, aby odznaczy\u0107 ten wiersz",
  ariaRowSelectAll: "Naci\u015Bnij SPACJ\u0118, aby prze\u0142\u0105czy\u0107 zaznaczenie wszystkich wierszy",
  ariaRowToggleSelection: "Naci\u015Bnij SPACJ\u0118, aby prze\u0142\u0105czy\u0107 zaznaczenie wiersza",
  ariaRowSelect: "Naci\u015Bnij SPACJ\u0118, aby zaznaczy\u0107 ten wiersz",
  ariaRowSelectionDisabled: "Zaznaczenie wiersza jest wy\u0142\u0105czone dla tego wiersza",
  ariaSearch: "Szukaj",
  ariaSortableColumn: "Naci\u015Bnij ENTER, aby sortowa\u0107",
  ariaToggleVisibility: "Naci\u015Bnij SPACJ\u0118, aby prze\u0142\u0105czy\u0107 widoczno\u015B\u0107",
  ariaToggleCellValue: "Naci\u015Bnij SPACJ\u0118, aby prze\u0142\u0105czy\u0107 warto\u015B\u0107 kom\xF3rki",
  ariaUnchecked: "niezaznaczone",
  ariaVisible: "widoczny",
  ariaSearchFilterValues: "Szukaj warto\u015Bci filtr\xF3w",
  ariaPageSizeSelectorLabel: "Rozmiar Strony",
  ariaChartMenuClose: "Zamknij Menu Edycji Wykresu",
  ariaChartSelected: "Wybrane",
  ariaSkeletonCellLoadingFailed: "Wiersz nie uda\u0142o si\u0119 za\u0142adowa\u0107",
  ariaSkeletonCellLoading: "Dane wiersza \u0142aduj\u0105 si\u0119",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Grupy wierszy",
  ariaValuesDropZonePanelLabel: "Warto\u015Bci",
  ariaPivotDropZonePanelLabel: "Etykiety kolumn",
  ariaDropZoneColumnComponentDescription: "Naci\u015Bnij DELETE, aby usun\u0105\u0107",
  ariaDropZoneColumnValueItemDescription: "Naci\u015Bnij ENTER, aby zmieni\u0107 typ agregacji",
  ariaDropZoneColumnGroupItemDescription: "Naci\u015Bnij ENTER, aby sortowa\u0107",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " z ",
  ariaDropZoneColumnComponentSortAscending: "rosn\u0105co",
  ariaDropZoneColumnComponentSortDescending: "malej\u0105co",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Menu kolumny",
  ariaLabelColumnFilter: "Filtr kolumny",
  ariaLabelCellEditor: "Edytor kom\xF3rek",
  ariaLabelSelectField: "Wybierz pole",
  // aria labels for rich select
  ariaLabelRichSelectField: "Pole z wyborem zaawansowanym",
  ariaLabelRichSelectToggleSelection: "Naci\u015Bnij SPACJ\u0118, aby prze\u0142\u0105czy\u0107 wyb\xF3r",
  ariaLabelRichSelectDeselectAllItems: "Naci\u015Bnij DELETE, aby odznaczy\u0107 wszystkie elementy",
  ariaLabelRichSelectDeleteSelection: "Naci\u015Bnij DELETE, aby odznaczy\u0107 element",
  ariaLabelTooltip: "Etykieta",
  ariaLabelContextMenu: "Menu kontekstowe",
  ariaLabelSubMenu: "Podmenu",
  ariaLabelAggregationFunction: "Funkcja agregacji",
  ariaLabelAdvancedFilterAutocomplete: "Zaawansowane Autouzupe\u0142nianie Filtr\xF3w",
  ariaLabelAdvancedFilterBuilderAddField: "Zaawansowany Kreator Filtr\xF3w Dodaj Pole",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Zaawansowany Kreator Filtr\xF3w Wyb\xF3r Kolumny",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Zaawansowany Kreator Filtr\xF3w Wyb\xF3r Opcji",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Zaawansowany Kreator Filtr\xF3w Wyb\xF3r Operatora \u0141\u0105czenia",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Lista kolumn",
  ariaFilterPanelList: "Lista filtr\xF3w",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Prawda",
  false: "Fa\u0142sz",
  invalidDate: "Nieprawid\u0142owa Data",
  invalidNumber: "Nieprawid\u0142owy Numer",
  january: "Stycze\u0144",
  february: "Luty",
  march: "Marzec",
  april: "Kwiecie\u0144",
  may: "Maj",
  june: "Czerwiec",
  july: "Lipiec",
  august: "Sierpie\u0144",
  september: "Wrzesie\u0144",
  october: "Pa\u017Adziernik",
  november: "Listopad",
  december: "Grudzie\u0144",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/RRRR",
  timeFormatSlashesMMDDYYYY: "MM/DD/RRRR",
  timeFormatSlashesDDMMYY: "DD/MM/RR",
  timeFormatSlashesMMDDYY: "MM/DD/RR",
  timeFormatDotsDDMYY: "DD.M.RR",
  timeFormatDotsMDDYY: "M.DD.RR",
  timeFormatDashesYYYYMMDD: "RRRR-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM RRRR",
  timeFormatHHMMSS: "GG:MM:SS",
  timeFormatHHMMSSAmPm: "GG:MM:SS AM/PM"
};

// community-modules/locale/src/pt-BR.ts
var AG_GRID_LOCALE_BR = {
  // Set Filter
  selectAll: "(Selecionar Todos)",
  selectAllSearchResults: "(Selecionar Todos os Resultados da Pesquisa)",
  addCurrentSelectionToFilter: "Adicionar sele\xE7\xE3o atual ao filtro",
  searchOoo: "Pesquisar...",
  blanks: "(Em Branco)",
  noMatches: "Sem correspond\xEAncias",
  // Number Filter & Text Filter
  filterOoo: "Filtrar...",
  equals: "Igual",
  notEqual: "Diferente de",
  blank: "Em branco",
  notBlank: "N\xE3o est\xE1 em branco",
  empty: "Escolha um",
  // Number Filter
  lessThan: "Menor que",
  greaterThan: "Maior que",
  lessThanOrEqual: "Menor ou igual a",
  greaterThanOrEqual: "Maior ou igual a",
  inRange: "Entre",
  inRangeStart: "De",
  inRangeEnd: "Para",
  // Text Filter
  contains: "Cont\xE9m",
  notContains: "N\xE3o cont\xE9m",
  startsWith: "Come\xE7a com",
  endsWith: "Termina com",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Antes",
  after: "Depois",
  // Filter Conditions
  andCondition: "E",
  orCondition: "OU",
  // Filter Buttons
  applyFilter: "Aplicar",
  resetFilter: "Redefinir",
  clearFilter: "Limpar",
  cancelFilter: "Cancelar",
  // Filter Titles
  textFilter: "Filtro de Texto",
  numberFilter: "Filtro Num\xE9rico",
  dateFilter: "Filtro de Data",
  setFilter: "Filtro de Conjunto",
  // Group Column Filter
  groupFilterSelect: "Selecionar campo:",
  // Advanced Filter
  advancedFilterContains: "cont\xE9m",
  advancedFilterNotContains: "n\xE3o cont\xE9m",
  advancedFilterTextEquals: "\xE9 igual a",
  advancedFilterTextNotEqual: "n\xE3o \xE9 igual a",
  advancedFilterStartsWith: "come\xE7a com",
  advancedFilterEndsWith: "termina com",
  advancedFilterBlank: "est\xE1 em branco",
  advancedFilterNotBlank: "n\xE3o est\xE1 em branco",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\xE9 verdadeiro",
  advancedFilterFalse: "\xE9 falso",
  advancedFilterAnd: "E",
  advancedFilterOr: "OU",
  advancedFilterApply: "Aplicar",
  advancedFilterBuilder: "Construtor",
  advancedFilterValidationMissingColumn: "Coluna est\xE1 faltando",
  advancedFilterValidationMissingOption: "Op\xE7\xE3o est\xE1 faltando",
  advancedFilterValidationMissingValue: "Valor est\xE1 faltando",
  advancedFilterValidationInvalidColumn: "Coluna n\xE3o encontrada",
  advancedFilterValidationInvalidOption: "Op\xE7\xE3o n\xE3o encontrada",
  advancedFilterValidationMissingQuote: "Valor est\xE1 faltando uma aspa final",
  advancedFilterValidationNotANumber: "Valor n\xE3o \xE9 um n\xFAmero",
  advancedFilterValidationInvalidDate: "Valor n\xE3o \xE9 uma data v\xE1lida",
  advancedFilterValidationMissingCondition: "Condi\xE7\xE3o est\xE1 faltando",
  advancedFilterValidationJoinOperatorMismatch: "Operadores de jun\xE7\xE3o dentro de uma condi\xE7\xE3o devem ser os mesmos",
  advancedFilterValidationInvalidJoinOperator: "Operador de jun\xE7\xE3o n\xE3o encontrado",
  advancedFilterValidationMissingEndBracket: "Faltando colchete final",
  advancedFilterValidationExtraEndBracket: "Colchetes finais em excesso",
  advancedFilterValidationMessage: "Express\xE3o tem um erro. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Express\xE3o tem um erro. ${variable} no final da express\xE3o.",
  advancedFilterBuilderTitle: "Filtro Avan\xE7ado",
  advancedFilterBuilderApply: "Aplicar",
  advancedFilterBuilderCancel: "Cancelar",
  advancedFilterBuilderAddButtonTooltip: "Adicionar Filtro ou Grupo",
  advancedFilterBuilderRemoveButtonTooltip: "Remover",
  advancedFilterBuilderMoveUpButtonTooltip: "Mover para Cima",
  advancedFilterBuilderMoveDownButtonTooltip: "Mover para Baixo",
  advancedFilterBuilderAddJoin: "Adicionar Grupo",
  advancedFilterBuilderAddCondition: "Adicionar Filtro",
  advancedFilterBuilderSelectColumn: "Selecionar uma coluna",
  advancedFilterBuilderSelectOption: "Selecionar uma op\xE7\xE3o",
  advancedFilterBuilderEnterValue: "Digite um valor...",
  advancedFilterBuilderValidationAlreadyApplied: "Filtro atual j\xE1 aplicado.",
  advancedFilterBuilderValidationIncomplete: "Nem todas as condi\xE7\xF5es est\xE3o completas.",
  advancedFilterBuilderValidationSelectColumn: "Deve selecionar uma coluna.",
  advancedFilterBuilderValidationSelectOption: "Deve selecionar uma op\xE7\xE3o.",
  advancedFilterBuilderValidationEnterValue: "Deve digitar um valor.",
  // Side Bar
  columns: "Colunas",
  filters: "Filtros",
  // columns tool panel
  pivotMode: "Modo Pivot",
  groups: "Agrupamentos de Linhas",
  rowGroupColumnsEmptyMessage: "Arraste aqui para definir os grupos de linhas",
  values: "Valores",
  valueColumnsEmptyMessage: "Arraste aqui para agregar",
  pivots: "R\xF3tulos de Colunas",
  pivotColumnsEmptyMessage: "Arraste aqui para definir os r\xF3tulos das colunas",
  // Header of the Default Group Column
  group: "Grupo",
  // Row Drag
  rowDragRow: "linha",
  rowDragRows: "linhas",
  // Other
  loadingOoo: "Carregando...",
  loadingError: "ERR",
  noRowsToShow: "Sem linhas para mostrar",
  enabled: "Ativado",
  // Menu
  pinColumn: "Fixar Coluna",
  pinLeft: "Fixar \xE0 Esquerda",
  pinRight: "Fixar \xE0 Direita",
  noPin: "Sem Fixa\xE7\xE3o",
  valueAggregation: "Agrega\xE7\xE3o de Valor",
  noAggregation: "Nenhum",
  autosizeThisColumn: "Autoajustar Esta Coluna",
  autosizeAllColumns: "Autoajustar Todas as Colunas",
  groupBy: "Agrupar por",
  ungroupBy: "Desagrupar por",
  ungroupAll: "Desagrupar Todos",
  addToValues: "Adicionar ${variable} aos valores",
  removeFromValues: "Remover ${variable} dos valores",
  addToLabels: "Adicionar ${variable} aos r\xF3tulos",
  removeFromLabels: "Remover ${variable} dos r\xF3tulos",
  resetColumns: "Redefinir Colunas",
  expandAll: "Expandir Todos os Grupos de Linhas",
  collapseAll: "Fechar Todos os Grupos de Linhas",
  copy: "Copiar",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copiar com Cabe\xE7alhos",
  copyWithGroupHeaders: "Copiar com Cabe\xE7alhos de Grupo",
  cut: "Cortar",
  paste: "Colar",
  ctrlV: "Ctrl+V",
  export: "Exportar",
  csvExport: "Exportar CSV",
  excelExport: "Exportar Excel",
  columnFilter: "Filtro de Coluna",
  columnChooser: "Escolher Colunas",
  chooseColumns: "Escolher Colunas",
  sortAscending: "Ordenar Crescente",
  sortDescending: "Ordenar Decrescente",
  sortUnSort: "Limpar Ordena\xE7\xE3o",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Soma",
  first: "Primeiro",
  last: "\xDAltimo",
  min: "M\xEDnimo",
  max: "M\xE1ximo",
  none: "Nenhum",
  count: "Contar",
  avg: "M\xE9dia",
  filteredRows: "Filtradas",
  selectedRows: "Selecionadas",
  totalRows: "Total de Linhas",
  totalAndFilteredRows: "Linhas",
  more: "Mais",
  to: "at\xE9",
  of: "de",
  page: "P\xE1gina",
  pageLastRowUnknown: "?",
  nextPage: "Pr\xF3xima P\xE1gina",
  lastPage: "\xDAltima P\xE1gina",
  firstPage: "Primeira P\xE1gina",
  previousPage: "P\xE1gina Anterior",
  pageSizeSelectorLabel: "Tamanho da P\xE1gina:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Gr\xE1fico de Pivot e Modo Pivot",
  pivotChart: "Gr\xE1fico de Pivot",
  chartRange: "Intervalo do Gr\xE1fico",
  columnChart: "Coluna",
  groupedColumn: "Agrupada",
  stackedColumn: "Empilhada",
  normalizedColumn: "100% Empilhada",
  barChart: "Barra",
  groupedBar: "Agrupada",
  stackedBar: "Empilhada",
  normalizedBar: "100% Empilhada",
  pieChart: "Pizza",
  pie: "Pizza",
  donut: "Rosca",
  line: "Linha",
  xyChart: "X Y (Dispers\xE3o)",
  scatter: "Dispers\xE3o",
  bubble: "Bolha",
  areaChart: "\xC1rea",
  area: "\xC1rea",
  stackedArea: "Empilhada",
  normalizedArea: "100% Empilhada",
  histogramChart: "Histograma",
  polarChart: "Polar",
  radarLine: "Linha de Radar",
  radarArea: "\xC1rea de Radar",
  nightingale: "Nightingale",
  radialColumn: "Coluna Radial",
  radialBar: "Barra Radial",
  statisticalChart: "Estat\xEDstico",
  boxPlot: "Gr\xE1fico de Caixa",
  rangeBar: "Barra de Intervalo",
  rangeArea: "\xC1rea de Intervalo",
  hierarchicalChart: "Hier\xE1rquico",
  treemap: "Mapa de \xC1rvore",
  sunburst: "Explos\xE3o Solar",
  specializedChart: "Especializado",
  waterfall: "Cascata",
  heatmap: "Mapa de Calor",
  combinationChart: "Combina\xE7\xE3o",
  columnLineCombo: "Coluna & Linha",
  AreaColumnCombo: "\xC1rea & Coluna",
  // Charts
  pivotChartTitle: "Gr\xE1fico Din\xE2mico",
  rangeChartTitle: "Gr\xE1fico de Intervalo",
  settings: "Gr\xE1fico",
  data: "Configurar",
  format: "Personalizar",
  categories: "Categorias",
  defaultCategory: "(Nenhuma)",
  series: "S\xE9ries",
  switchCategorySeries: "Trocar Categoria / S\xE9rie",
  categoryValues: "Valores de Categoria",
  seriesLabels: "R\xF3tulos das S\xE9ries",
  aggregate: "Agregado",
  xyValues: "Valores XY",
  paired: "Modo Pareado",
  axis: "Eixo",
  xAxis: "Eixo Horizontal",
  yAxis: "Eixo Vertical",
  polarAxis: "Eixo Polar",
  radiusAxis: "Eixo do Raio",
  navigator: "Navegador",
  zoom: "Zoom",
  animation: "Anima\xE7\xE3o",
  crosshair: "Mira",
  color: "Cor",
  thickness: "Espessura",
  preferredLength: "Comprimento Preferido",
  xType: "Tipo X",
  axisType: "Tipo de Eixo",
  automatic: "Autom\xE1tico",
  category: "Categoria",
  number: "N\xFAmero",
  time: "Tempo",
  timeFormat: "Formato de Tempo",
  autoRotate: "Rota\xE7\xE3o Autom\xE1tica",
  labelRotation: "Rota\xE7\xE3o",
  circle: "C\xEDrculo",
  polygon: "Pol\xEDgono",
  square: "Quadrado",
  cross: "Cruz",
  diamond: "Diamante",
  plus: "Mais",
  triangle: "Tri\xE2ngulo",
  heart: "Cora\xE7\xE3o",
  orientation: "Orienta\xE7\xE3o",
  fixed: "Fixo",
  parallel: "Paralelo",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Posi\xE7\xE3o",
  ticks: "Marcas",
  gridLines: "Linhas de Grade",
  width: "Largura",
  height: "Altura",
  length: "Comprimento",
  padding: "Preenchimento",
  spacing: "Espa\xE7amento",
  chartStyle: "Estilo do Gr\xE1fico",
  title: "T\xEDtulo",
  chartTitles: "T\xEDtulos",
  chartTitle: "T\xEDtulo do Gr\xE1fico",
  chartSubtitle: "Subt\xEDtulo",
  horizontalAxisTitle: "T\xEDtulo do Eixo Horizontal",
  verticalAxisTitle: "T\xEDtulo do Eixo Vertical",
  polarAxisTitle: "T\xEDtulo do Eixo Polar",
  titlePlaceholder: "T\xEDtulo do Gr\xE1fico",
  background: "Fundo",
  font: "Fonte",
  weight: "Peso",
  top: "Topo",
  right: "Direita",
  bottom: "Fundo",
  left: "Esquerda",
  labels: "R\xF3tulos",
  calloutLabels: "R\xF3tulos de Chamada",
  sectorLabels: "R\xF3tulos de Setor",
  positionRatio: "Raz\xE3o de Posi\xE7\xE3o",
  size: "Tamanho",
  shape: "Forma",
  minSize: "Tamanho M\xEDnimo",
  maxSize: "Tamanho M\xE1ximo",
  legend: "Legenda",
  position: "Posi\xE7\xE3o",
  markerSize: "Tamanho do Marcador",
  markerStroke: "Contorno do Marcador",
  markerPadding: "Preenchimento do Marcador",
  itemSpacing: "Espa\xE7amento do Item",
  itemPaddingX: "Preenchimento do Item X",
  itemPaddingY: "Preenchimento do Item Y",
  layoutHorizontalSpacing: "Espa\xE7amento Horizontal",
  layoutVerticalSpacing: "Espa\xE7amento Vertical",
  strokeWidth: "Largura do Tra\xE7o",
  offset: "Deslocamento",
  offsets: "Deslocamentos",
  tooltips: "Dicas de Ferramenta",
  callout: "Chamada",
  markers: "Marcadores",
  shadow: "Sombra",
  blur: "Desfoque",
  xOffset: "Deslocamento X",
  yOffset: "Deslocamento Y",
  lineWidth: "Largura da Linha",
  lineDash: "Tracejado da Linha",
  lineDashOffset: "Deslocamento do Tracejado",
  scrollingZoom: "Rolagem",
  scrollingStep: "Passo da Rolagem",
  selectingZoom: "Selecionando",
  durationMillis: "Dura\xE7\xE3o (ms)",
  crosshairLabel: "R\xF3tulo",
  crosshairSnap: "Ajustar ao N\xF3",
  normal: "Normal",
  bold: "Negrito",
  italic: "It\xE1lico",
  boldItalic: "Negrito It\xE1lico",
  predefined: "Pr\xE9-definido",
  fillOpacity: "Opacidade do Preenchimento",
  strokeColor: "Cor da Linha",
  strokeOpacity: "Opacidade da Linha",
  miniChart: "Mini-Gr\xE1fico",
  histogramBinCount: "Contagem de Classes",
  connectorLine: "Linha de Conex\xE3o",
  seriesItems: "Itens da S\xE9rie",
  seriesItemType: "Tipo de Item",
  seriesItemPositive: "Positivo",
  seriesItemNegative: "Negativo",
  seriesItemLabels: "R\xF3tulos dos Itens",
  columnGroup: "Coluna",
  barGroup: "Barra",
  pieGroup: "Pizza",
  lineGroup: "Linha",
  scatterGroup: "XY (Dispers\xE3o)",
  areaGroup: "\xC1rea",
  polarGroup: "Polar",
  statisticalGroup: "Estat\xEDstico",
  hierarchicalGroup: "Hier\xE1rquico",
  specializedGroup: "Especializado",
  combinationGroup: "Combina\xE7\xE3o",
  groupedColumnTooltip: "Agrupado",
  stackedColumnTooltip: "Empilhado",
  normalizedColumnTooltip: "Empilhado 100%",
  groupedBarTooltip: "Agrupado",
  stackedBarTooltip: "Empilhado",
  normalizedBarTooltip: "Empilhado 100%",
  pieTooltip: "Pizza",
  donutTooltip: "Rosquinha",
  lineTooltip: "Linha",
  groupedAreaTooltip: "\xC1rea",
  stackedAreaTooltip: "Empilhado",
  normalizedAreaTooltip: "Empilhado 100%",
  scatterTooltip: "Dispers\xE3o",
  bubbleTooltip: "Bolha",
  histogramTooltip: "Histograma",
  radialColumnTooltip: "Coluna Radial",
  radialBarTooltip: "Barra Radial",
  radarLineTooltip: "Linha de Radar",
  radarAreaTooltip: "\xC1rea de Radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Barra de Intervalo",
  rangeAreaTooltip: "\xC1rea de Intervalo",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Mapa de \xC1rvore",
  sunburstTooltip: "Raios de Sol",
  waterfallTooltip: "Cascata",
  heatmapTooltip: "Mapa de Calor",
  columnLineComboTooltip: "Coluna & Linha",
  areaColumnComboTooltip: "\xC1rea & Coluna",
  customComboTooltip: "Combina\xE7\xE3o Personalizada",
  innerRadius: "Raio Interno",
  startAngle: "\xC2ngulo Inicial",
  endAngle: "\xC2ngulo Final",
  reverseDirection: "Inverter Dire\xE7\xE3o",
  groupPadding: "Preenchimento do Grupo",
  seriesPadding: "Preenchimento da S\xE9rie",
  tile: "Azulejo",
  whisker: "Bigode",
  cap: "Tampa",
  capLengthRatio: "Raz\xE3o do Comprimento",
  labelPlacement: "Posicionamento",
  inside: "Dentro",
  outside: "Fora",
  noDataToChart: "N\xE3o h\xE1 dados dispon\xEDveis para serem gr\xE1ficos.",
  pivotChartRequiresPivotMode: "Gr\xE1fico Din\xE2mico requer o Modo Din\xE2mico ativado.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Vinculado \xE0 Grade",
  chartUnlinkToolbarTooltip: "Desvinculado da Grade",
  chartDownloadToolbarTooltip: "Baixar Gr\xE1fico",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Editar Gr\xE1fico",
  chartAdvancedSettings: "Configura\xE7\xF5es Avan\xE7adas",
  chartLink: "Vincular \xE0 Grade",
  chartUnlink: "Desvincular da Grade",
  chartDownload: "Baixar Gr\xE1fico",
  histogramFrequency: "Frequ\xEAncia",
  seriesChartType: "Tipo de Gr\xE1fico da S\xE9rie",
  seriesType: "Tipo de S\xE9rie",
  secondaryAxis: "Eixo Secund\xE1rio",
  seriesAdd: "Adicionar uma s\xE9rie",
  categoryAdd: "Adicionar uma categoria",
  bar: "Barra",
  column: "Coluna",
  histogram: "Histograma",
  advancedSettings: "Configura\xE7\xF5es Avan\xE7adas",
  direction: "Dire\xE7\xE3o",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Tipo de Grupo",
  groupedSeriesGroupType: "Agrupado",
  stackedSeriesGroupType: "Empilhado",
  normalizedSeriesGroupType: "Empilhado 100%",
  legendEnabled: "Habilitado",
  invalidColor: "Valor de cor inv\xE1lido",
  groupedColumnFull: "Coluna Agrupada",
  stackedColumnFull: "Coluna Empilhada",
  normalizedColumnFull: "Coluna Empilhada 100%",
  groupedBarFull: "Barra Agrupada",
  stackedBarFull: "Barra Empilhada",
  normalizedBarFull: "Barra Empilhada 100%",
  stackedAreaFull: "\xC1rea Empilhada",
  normalizedAreaFull: "\xC1rea Empilhada 100%",
  customCombo: "Combina\xE7\xE3o Personalizada",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. N\xEDvel ${variable}. Pressione ENTER para editar.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. N\xEDvel ${variable}. ${variable} Pressione ENTER para editar.",
  ariaAdvancedFilterBuilderList: "Lista de Construtores de Filtros Avan\xE7ados",
  ariaAdvancedFilterBuilderFilterItem: "Condi\xE7\xE3o do Filtro",
  ariaAdvancedFilterBuilderGroupItem: "Grupo de Filtros",
  ariaAdvancedFilterBuilderColumn: "Coluna",
  ariaAdvancedFilterBuilderOption: "Op\xE7\xE3o",
  ariaAdvancedFilterBuilderValueP: "Valor",
  ariaAdvancedFilterBuilderJoinOperator: "Operador de Jun\xE7\xE3o",
  ariaAdvancedFilterInput: "Entrada de Filtro Avan\xE7ado",
  ariaChecked: "marcado",
  ariaColumn: "Coluna",
  ariaColumnGroup: "Grupo de Colunas",
  ariaColumnFiltered: "Coluna Filtrada",
  ariaColumnSelectAll: "Alternar Selecionar Todas as Colunas",
  ariaDateFilterInput: "Entrada de Filtro de Data",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Entrada de Colunas de Filtro",
  ariaFilterFromValue: "Filtrar do valor",
  ariaFilterInput: "Entrada de Filtro",
  ariaFilterList: "Lista de Filtros",
  ariaFilterToValue: "Filtrar at\xE9 o valor",
  ariaFilterValue: "Valor do Filtro",
  ariaFilterMenuOpen: "Abrir Menu de Filtro",
  ariaFilteringOperator: "Operador de Filtragem",
  ariaHidden: "oculto",
  ariaIndeterminate: "indeterminado",
  ariaInputEditor: "Editor de Entrada",
  ariaMenuColumn: "Pressione ALT PARA BAIXO para abrir o menu da coluna",
  ariaFilterColumn: "Pressione CTRL ENTER para abrir o filtro",
  ariaRowDeselect: "Pressione SPACE para desselecionar esta linha",
  ariaRowSelectAll: "Pressione SPACE para alternar a sele\xE7\xE3o de todas as linhas",
  ariaRowToggleSelection: "Pressione SPACE para alternar a sele\xE7\xE3o da linha",
  ariaRowSelect: "Pressione SPACE para selecionar esta linha",
  ariaRowSelectionDisabled: "A sele\xE7\xE3o de linha est\xE1 desativada para esta linha",
  ariaSearch: "Pesquisar",
  ariaSortableColumn: "Pressione ENTER para classificar",
  ariaToggleVisibility: "Pressione SPACE para alternar a visibilidade",
  ariaToggleCellValue: "Pressione SPACE para alternar o valor da c\xE9lula",
  ariaUnchecked: "desmarcado",
  ariaVisible: "vis\xEDvel",
  ariaSearchFilterValues: "Pesquisar valores de filtro",
  ariaPageSizeSelectorLabel: "Tamanho da P\xE1gina",
  ariaChartMenuClose: "Fechar Menu de Edi\xE7\xE3o do Gr\xE1fico",
  ariaChartSelected: "Selecionado",
  ariaSkeletonCellLoadingFailed: "Falha no carregamento da linha",
  ariaSkeletonCellLoading: "Carregando dados da linha",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Agrupamento de Linhas",
  ariaValuesDropZonePanelLabel: "Valores",
  ariaPivotDropZonePanelLabel: "R\xF3tulos de Colunas",
  ariaDropZoneColumnComponentDescription: "Pressione DELETE para remover",
  ariaDropZoneColumnValueItemDescription: "Pressione ENTER para alterar o tipo de agrega\xE7\xE3o",
  ariaDropZoneColumnGroupItemDescription: "Pressione ENTER para ordenar",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " de ",
  ariaDropZoneColumnComponentSortAscending: "crescente",
  ariaDropZoneColumnComponentSortDescending: "decrescente",
  ariaLabelDialog: "Di\xE1logo",
  ariaLabelColumnMenu: "Menu de Coluna",
  ariaLabelColumnFilter: "Filtro de Coluna",
  ariaLabelCellEditor: "Editor de C\xE9lula",
  ariaLabelSelectField: "Selecionar Campo",
  // aria labels for rich select
  ariaLabelRichSelectField: "Campo de Sele\xE7\xE3o Rica",
  ariaLabelRichSelectToggleSelection: "Pressione ESPA\xC7O para alternar a sele\xE7\xE3o",
  ariaLabelRichSelectDeselectAllItems: "Pressione DELETE para desmarcar todos os itens",
  ariaLabelRichSelectDeleteSelection: "Pressione DELETE para desmarcar o item",
  ariaLabelTooltip: "Dica de Ferramenta",
  ariaLabelContextMenu: "Menu Contextual",
  ariaLabelSubMenu: "Submenu",
  ariaLabelAggregationFunction: "Fun\xE7\xE3o de Agrega\xE7\xE3o",
  ariaLabelAdvancedFilterAutocomplete: "Autocompletar Filtro Avan\xE7ado",
  ariaLabelAdvancedFilterBuilderAddField: "Adicionar Campo no Construtor de Filtro Avan\xE7ado",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Selecionar Campo da Coluna no Construtor de Filtro Avan\xE7ado",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Selecionar Campo de Op\xE7\xE3o no Construtor de Filtro Avan\xE7ado",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Selecionar Campo do Operador de Jun\xE7\xE3o no Construtor de Filtro Avan\xE7ado",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Lista de Colunas",
  ariaFilterPanelList: "Lista de Filtros",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Verdadeiro",
  false: "Falso",
  invalidDate: "Data Inv\xE1lida",
  invalidNumber: "N\xFAmero Inv\xE1lido",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Mar\xE7o",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.AA",
  timeFormatDotsMDDYY: "M.DD.AA",
  timeFormatDashesYYYYMMDD: "AAAA-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM AAAA",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/pt-PT.ts
var AG_GRID_LOCALE_PT = {
  // Set Filter
  selectAll: "(Selecionar Todos)",
  selectAllSearchResults: "(Selecionar Todos os Resultados da Pesquisa)",
  addCurrentSelectionToFilter: "Adicionar sele\xE7\xE3o atual ao filtro",
  searchOoo: "Pesquisar...",
  blanks: "(Em Branco)",
  noMatches: "Sem correspond\xEAncias",
  // Number Filter & Text Filter
  filterOoo: "Filtrar...",
  equals: "Igual",
  notEqual: "Diferente",
  blank: "Vazio",
  notBlank: "N\xE3o vazio",
  empty: "Escolher um",
  // Number Filter
  lessThan: "Menor que",
  greaterThan: "Maior que",
  lessThanOrEqual: "Menor ou igual a",
  greaterThanOrEqual: "Maior ou igual a",
  inRange: "Entre",
  inRangeStart: "De",
  inRangeEnd: "At\xE9",
  // Text Filter
  contains: "Cont\xE9m",
  notContains: "N\xE3o cont\xE9m",
  startsWith: "Come\xE7a com",
  endsWith: "Termina com",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Antes",
  after: "Depois",
  // Filter Conditions
  andCondition: "E",
  orCondition: "OU",
  // Filter Buttons
  applyFilter: "Aplicar",
  resetFilter: "Redefinir",
  clearFilter: "Limpar",
  cancelFilter: "Cancelar",
  // Filter Titles
  textFilter: "Filtro de Texto",
  numberFilter: "Filtro de N\xFAmero",
  dateFilter: "Filtro de Data",
  setFilter: "Filtro de Conjunto",
  // Group Column Filter
  groupFilterSelect: "Selecionar campo:",
  // Advanced Filter
  advancedFilterContains: "cont\xE9m",
  advancedFilterNotContains: "n\xE3o cont\xE9m",
  advancedFilterTextEquals: "igual a",
  advancedFilterTextNotEqual: "diferente de",
  advancedFilterStartsWith: "come\xE7a com",
  advancedFilterEndsWith: "termina com",
  advancedFilterBlank: "est\xE1 em branco",
  advancedFilterNotBlank: "n\xE3o est\xE1 em branco",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\xE9 verdadeiro",
  advancedFilterFalse: "\xE9 falso",
  advancedFilterAnd: "E",
  advancedFilterOr: "OU",
  advancedFilterApply: "Aplicar",
  advancedFilterBuilder: "Construtor",
  advancedFilterValidationMissingColumn: "Coluna ausente",
  advancedFilterValidationMissingOption: "Op\xE7\xE3o ausente",
  advancedFilterValidationMissingValue: "Valor ausente",
  advancedFilterValidationInvalidColumn: "Coluna n\xE3o encontrada",
  advancedFilterValidationInvalidOption: "Op\xE7\xE3o n\xE3o encontrada",
  advancedFilterValidationMissingQuote: "Valor est\xE1 sem a aspa final",
  advancedFilterValidationNotANumber: "Valor n\xE3o \xE9 um n\xFAmero",
  advancedFilterValidationInvalidDate: "Valor n\xE3o \xE9 uma data v\xE1lida",
  advancedFilterValidationMissingCondition: "Condi\xE7\xE3o ausente",
  advancedFilterValidationJoinOperatorMismatch: "Operadores de jun\xE7\xE3o dentro de uma condi\xE7\xE3o devem ser iguais",
  advancedFilterValidationInvalidJoinOperator: "Operador de jun\xE7\xE3o n\xE3o encontrado",
  advancedFilterValidationMissingEndBracket: "Falta par\xEAntese de fechamento",
  advancedFilterValidationExtraEndBracket: "Par\xEAnteses de fechamento a mais",
  advancedFilterValidationMessage: "Express\xE3o cont\xE9m um erro. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Express\xE3o cont\xE9m um erro. ${variable} no final da express\xE3o.",
  advancedFilterBuilderTitle: "Filtro Avan\xE7ado",
  advancedFilterBuilderApply: "Aplicar",
  advancedFilterBuilderCancel: "Cancelar",
  advancedFilterBuilderAddButtonTooltip: "Adicionar Filtro ou Grupo",
  advancedFilterBuilderRemoveButtonTooltip: "Remover",
  advancedFilterBuilderMoveUpButtonTooltip: "Mover para Cima",
  advancedFilterBuilderMoveDownButtonTooltip: "Mover para Baixo",
  advancedFilterBuilderAddJoin: "Adicionar Grupo",
  advancedFilterBuilderAddCondition: "Adicionar Filtro",
  advancedFilterBuilderSelectColumn: "Selecione uma coluna",
  advancedFilterBuilderSelectOption: "Selecione uma op\xE7\xE3o",
  advancedFilterBuilderEnterValue: "Insira um valor...",
  advancedFilterBuilderValidationAlreadyApplied: "Filtro atual j\xE1 aplicado.",
  advancedFilterBuilderValidationIncomplete: "Nem todas as condi\xE7\xF5es est\xE3o completas.",
  advancedFilterBuilderValidationSelectColumn: "Deve selecionar uma coluna.",
  advancedFilterBuilderValidationSelectOption: "Deve selecionar uma op\xE7\xE3o.",
  advancedFilterBuilderValidationEnterValue: "Deve inserir um valor.",
  // Side Bar
  columns: "Colunas",
  filters: "Filtros",
  // columns tool panel
  pivotMode: "Modo de Pivot",
  groups: "Grupos de Linhas",
  rowGroupColumnsEmptyMessage: "Arraste aqui para definir grupos de linhas",
  values: "Valores",
  valueColumnsEmptyMessage: "Arraste aqui para agregar",
  pivots: "R\xF3tulos de Coluna",
  pivotColumnsEmptyMessage: "Arraste aqui para definir r\xF3tulos de coluna",
  // Header of the Default Group Column
  group: "Grupo",
  // Row Drag
  rowDragRow: "linha",
  rowDragRows: "linhas",
  // Other
  loadingOoo: "Carregando...",
  loadingError: "ERR",
  noRowsToShow: "Sem linhas para mostrar",
  enabled: "Ativado",
  // Menu
  pinColumn: "Fixar Coluna",
  pinLeft: "Fixar \xE0 Esquerda",
  pinRight: "Fixar \xE0 Direita",
  noPin: "N\xE3o Fixar",
  valueAggregation: "Agrega\xE7\xE3o de Valores",
  noAggregation: "Nenhum",
  autosizeThisColumn: "Ajustar Tamanho desta Coluna",
  autosizeAllColumns: "Ajustar Tamanho de Todas as Colunas",
  groupBy: "Agrupar por",
  ungroupBy: "Desagrupar por",
  ungroupAll: "Desagrupar Tudo",
  addToValues: "Adicionar ${variable} aos valores",
  removeFromValues: "Remover ${variable} dos valores",
  addToLabels: "Adicionar ${variable} aos r\xF3tulos",
  removeFromLabels: "Remover ${variable} dos r\xF3tulos",
  resetColumns: "Redefinir Colunas",
  expandAll: "Expandir Todos os Grupos de Linhas",
  collapseAll: "Fechar Todos os Grupos de Linhas",
  copy: "Copiar",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copiar Com Cabe\xE7alhos",
  copyWithGroupHeaders: "Copiar com Cabe\xE7alhos de Grupo",
  cut: "Cortar",
  paste: "Colar",
  ctrlV: "Ctrl+V",
  export: "Exportar",
  csvExport: "Exporta\xE7\xE3o CSV",
  excelExport: "Exporta\xE7\xE3o Excel",
  columnFilter: "Filtro de Coluna",
  columnChooser: "Escolher Colunas",
  chooseColumns: "Escolher Colunas",
  sortAscending: "Ordenar Ascendente",
  sortDescending: "Ordenar Descendente",
  sortUnSort: "Limpar Ordena\xE7\xE3o",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Soma",
  first: "Primeiro",
  last: "\xDAltimo",
  min: "Min",
  max: "M\xE1x",
  none: "Nenhum",
  count: "Contagem",
  avg: "M\xE9dia",
  filteredRows: "Filtrados",
  selectedRows: "Selecionados",
  totalRows: "Total de Linhas",
  totalAndFilteredRows: "Linhas",
  more: "Mais",
  to: "para",
  of: "de",
  page: "P\xE1gina",
  pageLastRowUnknown: "?",
  nextPage: "Pr\xF3xima P\xE1gina",
  lastPage: "\xDAltima P\xE1gina",
  firstPage: "Primeira P\xE1gina",
  previousPage: "P\xE1gina Anterior",
  pageSizeSelectorLabel: "Tamanho da P\xE1gina:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Gr\xE1fico de Piv\xF4 e Modo Piv\xF4",
  pivotChart: "Gr\xE1fico de Piv\xF4",
  chartRange: "Intervalo do Gr\xE1fico",
  columnChart: "Coluna",
  groupedColumn: "Agrupadas",
  stackedColumn: "Empilhadas",
  normalizedColumn: "100% Empilhadas",
  barChart: "Barra",
  groupedBar: "Agrupadas",
  stackedBar: "Empilhadas",
  normalizedBar: "100% Empilhadas",
  pieChart: "Pizza",
  pie: "Pizza",
  donut: "Rosca",
  line: "Linha",
  xyChart: "X Y (Dispers\xE3o)",
  scatter: "Dispers\xE3o",
  bubble: "Bolhas",
  areaChart: "\xC1rea",
  area: "\xC1rea",
  stackedArea: "Empilhadas",
  normalizedArea: "100% Empilhadas",
  histogramChart: "Histograma",
  polarChart: "Polar",
  radarLine: "Linha de Radar",
  radarArea: "\xC1rea de Radar",
  nightingale: "Nightingale",
  radialColumn: "Coluna Radial",
  radialBar: "Barra Radial",
  statisticalChart: "Estat\xEDstico",
  boxPlot: "Gr\xE1fico de Caixa",
  rangeBar: "Barra de Intervalo",
  rangeArea: "\xC1rea de Intervalo",
  hierarchicalChart: "Hier\xE1rquico",
  treemap: "Mapa de \xC1rvore",
  sunburst: "Explos\xE3o Solar",
  specializedChart: "Especializado",
  waterfall: "Cascata",
  heatmap: "Mapa de Calor",
  combinationChart: "Combina\xE7\xE3o",
  columnLineCombo: "Coluna e Linha",
  AreaColumnCombo: "\xC1rea e Coluna",
  // Charts
  pivotChartTitle: "Gr\xE1fico Piv\xF4",
  rangeChartTitle: "Gr\xE1fico de Intervalo",
  settings: "Gr\xE1fico",
  data: "Configura\xE7\xE3o",
  format: "Personalizar",
  categories: "Categorias",
  defaultCategory: "(Nenhum)",
  series: "S\xE9ries",
  switchCategorySeries: "Alternar Categoria / S\xE9rie",
  categoryValues: "Valores de Categoria",
  seriesLabels: "R\xF3tulos de S\xE9rie",
  aggregate: "Agrupar",
  xyValues: "Valores X Y",
  paired: "Modo Pareado",
  axis: "Eixo",
  xAxis: "Eixo Horizontal",
  yAxis: "Eixo Vertical",
  polarAxis: "Eixo Polar",
  radiusAxis: "Eixo de Raio",
  navigator: "Navegador",
  zoom: "Zoom",
  animation: "Anima\xE7\xE3o",
  crosshair: "Mira",
  color: "Cor",
  thickness: "Espessura",
  preferredLength: "Comprimento Preferido",
  xType: "Tipo X",
  axisType: "Tipo de Eixo",
  automatic: "Autom\xE1tico",
  category: "Categoria",
  number: "N\xFAmero",
  time: "Tempo",
  timeFormat: "Formato de Tempo",
  autoRotate: "Rota\xE7\xE3o Autom\xE1tica",
  labelRotation: "Rota\xE7\xE3o",
  circle: "C\xEDrculo",
  polygon: "Pol\xEDgono",
  square: "Quadrado",
  cross: "Cruz",
  diamond: "Diamante",
  plus: "Mais",
  triangle: "Tri\xE2ngulo",
  heart: "Cora\xE7\xE3o",
  orientation: "Orienta\xE7\xE3o",
  fixed: "Fixo",
  parallel: "Paralelo",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Posi\xE7\xE3o",
  ticks: "Marcas",
  gridLines: "Linhas de Grade",
  width: "Largura",
  height: "Altura",
  length: "Comprimento",
  padding: "Preenchimento",
  spacing: "Espa\xE7amento",
  chartStyle: "Estilo do Gr\xE1fico",
  title: "T\xEDtulo",
  chartTitles: "T\xEDtulos",
  chartTitle: "T\xEDtulo do Gr\xE1fico",
  chartSubtitle: "Subt\xEDtulo",
  horizontalAxisTitle: "T\xEDtulo do Eixo Horizontal",
  verticalAxisTitle: "T\xEDtulo do Eixo Vertical",
  polarAxisTitle: "T\xEDtulo do Eixo Polar",
  titlePlaceholder: "T\xEDtulo do Gr\xE1fico",
  background: "Fundo",
  font: "Fonte",
  weight: "Espessura",
  top: "Topo",
  right: "Direita",
  bottom: "Inferior",
  left: "Esquerda",
  labels: "R\xF3tulos",
  calloutLabels: "R\xF3tulos de Destaque",
  sectorLabels: "R\xF3tulos de Setor",
  positionRatio: "Rela\xE7\xE3o de Posi\xE7\xE3o",
  size: "Tamanho",
  shape: "Forma",
  minSize: "Tamanho M\xEDnimo",
  maxSize: "Tamanho M\xE1ximo",
  legend: "Legenda",
  position: "Posi\xE7\xE3o",
  markerSize: "Tamanho do Marcador",
  markerStroke: "Contorno do Marcador",
  markerPadding: "Preenchimento do Marcador",
  itemSpacing: "Espa\xE7amento de Itens",
  itemPaddingX: "Preenchimento do Item X",
  itemPaddingY: "Preenchimento do Item Y",
  layoutHorizontalSpacing: "Espa\xE7amento Horizontal",
  layoutVerticalSpacing: "Espa\xE7amento Vertical",
  strokeWidth: "Espessura da Linha",
  offset: "Deslocamento",
  offsets: "Deslocamentos",
  tooltips: "Dicas de Ferramenta",
  callout: "Destaque",
  markers: "Marcadores",
  shadow: "Sombra",
  blur: "Desfoque",
  xOffset: "Deslocamento X",
  yOffset: "Deslocamento Y",
  lineWidth: "Largura da Linha",
  lineDash: "Tracejado da Linha",
  lineDashOffset: "Deslocamento do Tracejado",
  scrollingZoom: "Rolagem",
  scrollingStep: "Passo da Rolagem",
  selectingZoom: "Selecionando",
  durationMillis: "Dura\xE7\xE3o (ms)",
  crosshairLabel: "R\xF3tulo",
  crosshairSnap: "Ajustar ao N\xF3",
  normal: "Normal",
  bold: "Negrito",
  italic: "It\xE1lico",
  boldItalic: "Negrito It\xE1lico",
  predefined: "Predefinido",
  fillOpacity: "Opacidade do Preenchimento",
  strokeColor: "Cor da Linha",
  strokeOpacity: "Opacidade da Linha",
  miniChart: "Mini Gr\xE1fico",
  histogramBinCount: "Contagem de Blocos",
  connectorLine: "Linha Conectora",
  seriesItems: "Itens da S\xE9rie",
  seriesItemType: "Tipo de Item",
  seriesItemPositive: "Positivo",
  seriesItemNegative: "Negativo",
  seriesItemLabels: "R\xF3tulos de Itens",
  columnGroup: "Coluna",
  barGroup: "Barra",
  pieGroup: "Pizza",
  lineGroup: "Linha",
  scatterGroup: "X Y (Dispers\xE3o)",
  areaGroup: "\xC1rea",
  polarGroup: "Polar",
  statisticalGroup: "Estat\xEDstico",
  hierarchicalGroup: "Hier\xE1rquico",
  specializedGroup: "Especializado",
  combinationGroup: "Combina\xE7\xE3o",
  groupedColumnTooltip: "Agrupado",
  stackedColumnTooltip: "Empilhado",
  normalizedColumnTooltip: "100% Empilhado",
  groupedBarTooltip: "Agrupado",
  stackedBarTooltip: "Empilhado",
  normalizedBarTooltip: "100% Empilhado",
  pieTooltip: "Pizza",
  donutTooltip: "Rosquinha",
  lineTooltip: "Linha",
  groupedAreaTooltip: "\xC1rea",
  stackedAreaTooltip: "Empilhada",
  normalizedAreaTooltip: "100% Empilhada",
  scatterTooltip: "Dispers\xE3o",
  bubbleTooltip: "Bolha",
  histogramTooltip: "Histograma",
  radialColumnTooltip: "Coluna Radial",
  radialBarTooltip: "Barra Radial",
  radarLineTooltip: "Linha de Radar",
  radarAreaTooltip: "\xC1rea de Radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Barra de Intervalo",
  rangeAreaTooltip: "\xC1rea de Intervalo",
  boxPlotTooltip: "Diagrama de Caixa",
  treemapTooltip: "Mapa de \xC1rvore",
  sunburstTooltip: "Explos\xE3o Solar",
  waterfallTooltip: "Cascata",
  heatmapTooltip: "Mapa de Calor",
  columnLineComboTooltip: "Coluna & Linha",
  areaColumnComboTooltip: "\xC1rea & Coluna",
  customComboTooltip: "Combina\xE7\xE3o Personalizada",
  innerRadius: "Raio Interno",
  startAngle: "\xC2ngulo Inicial",
  endAngle: "\xC2ngulo Final",
  reverseDirection: "Reverter Dire\xE7\xE3o",
  groupPadding: "Preenchimento do Grupo",
  seriesPadding: "Preenchimento da S\xE9rie",
  tile: "Ladrilho",
  whisker: "Bigode",
  cap: "Tampa",
  capLengthRatio: "Rela\xE7\xE3o de Comprimento",
  labelPlacement: "Posicionamento",
  inside: "Dentro",
  outside: "Fora",
  noDataToChart: "Sem dados dispon\xEDveis para gr\xE1fico.",
  pivotChartRequiresPivotMode: "Gr\xE1fico Piv\xF4 requer o Modo Piv\xF4 habilitado.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Vinculado \xE0 Grade",
  chartUnlinkToolbarTooltip: "Desvinculado da Grade",
  chartDownloadToolbarTooltip: "Baixar Gr\xE1fico",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Editar Gr\xE1fico",
  chartAdvancedSettings: "Configura\xE7\xF5es Avan\xE7adas",
  chartLink: "Vincular \xE0 Grade",
  chartUnlink: "Desvincular da Grade",
  chartDownload: "Baixar Gr\xE1fico",
  histogramFrequency: "Frequ\xEAncia",
  seriesChartType: "Tipo de Gr\xE1fico da S\xE9rie",
  seriesType: "Tipo de S\xE9rie",
  secondaryAxis: "Eixo Secund\xE1rio",
  seriesAdd: "Adicionar uma s\xE9rie",
  categoryAdd: "Adicionar uma categoria",
  bar: "Barra",
  column: "Coluna",
  histogram: "Histograma",
  advancedSettings: "Configura\xE7\xF5es Avan\xE7adas",
  direction: "Dire\xE7\xE3o",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Tipo de Grupo",
  groupedSeriesGroupType: "Agrupado",
  stackedSeriesGroupType: "Empilhado",
  normalizedSeriesGroupType: "100% Empilhado",
  legendEnabled: "Habilitado",
  invalidColor: "O valor da cor \xE9 inv\xE1lido",
  groupedColumnFull: "Coluna Agrupada",
  stackedColumnFull: "Coluna Empilhada",
  normalizedColumnFull: "Coluna 100% Empilhada",
  groupedBarFull: "Barra Agrupada",
  stackedBarFull: "Barra Empilhada",
  normalizedBarFull: "Barra 100% Empilhada",
  stackedAreaFull: "\xC1rea Empilhada",
  normalizedAreaFull: "\xC1rea 100% Empilhada",
  customCombo: "Combina\xE7\xE3o Personalizada",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. N\xEDvel ${variable}. Pressione ENTER para editar.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. N\xEDvel ${variable}. ${variable} Pressione ENTER para editar.",
  ariaAdvancedFilterBuilderList: "Lista de Construtores de Filtro Avan\xE7ado",
  ariaAdvancedFilterBuilderFilterItem: "Condi\xE7\xE3o do Filtro",
  ariaAdvancedFilterBuilderGroupItem: "Grupo de Filtro",
  ariaAdvancedFilterBuilderColumn: "Coluna",
  ariaAdvancedFilterBuilderOption: "Op\xE7\xE3o",
  ariaAdvancedFilterBuilderValueP: "Valor",
  ariaAdvancedFilterBuilderJoinOperator: "Operador de Jun\xE7\xE3o",
  ariaAdvancedFilterInput: "Entrada de Filtro Avan\xE7ado",
  ariaChecked: "marcado",
  ariaColumn: "Coluna",
  ariaColumnGroup: "Grupo de Colunas",
  ariaColumnFiltered: "Coluna Filtrada",
  ariaColumnSelectAll: "Alternar Sele\xE7\xE3o de Todas as Colunas",
  ariaDateFilterInput: "Entrada de Filtro de Data",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Entrada de Filtro de Colunas",
  ariaFilterFromValue: "Filtrar a partir do valor",
  ariaFilterInput: "Entrada de Filtro",
  ariaFilterList: "Lista de Filtros",
  ariaFilterToValue: "Filtrar at\xE9 o valor",
  ariaFilterValue: "Valor do Filtro",
  ariaFilterMenuOpen: "Abrir Menu de Filtro",
  ariaFilteringOperator: "Operador de Filtragem",
  ariaHidden: "oculto",
  ariaIndeterminate: "indeterminado",
  ariaInputEditor: "Editor de Entrada",
  ariaMenuColumn: "Pressione ALT para baixo para abrir o menu da coluna",
  ariaFilterColumn: "Pressione CTRL ENTER para abrir o filtro",
  ariaRowDeselect: "Pressione ESPA\xC7O para desmarcar esta linha",
  ariaRowSelectAll: "Pressione Espa\xE7o para alternar a sele\xE7\xE3o de todas as linhas",
  ariaRowToggleSelection: "Pressione Espa\xE7o para alternar a sele\xE7\xE3o da linha",
  ariaRowSelect: "Pressione ESPA\xC7O para selecionar esta linha",
  ariaRowSelectionDisabled: "A sele\xE7\xE3o de linhas est\xE1 desativada para esta linha",
  ariaSearch: "Buscar",
  ariaSortableColumn: "Pressione ENTER para ordenar",
  ariaToggleVisibility: "Pressione ESPA\xC7O para alternar visibilidade",
  ariaToggleCellValue: "Pressione ESPA\xC7O para alternar o valor da c\xE9lula",
  ariaUnchecked: "desmarcado",
  ariaVisible: "vis\xEDvel",
  ariaSearchFilterValues: "Buscar valores do filtro",
  ariaPageSizeSelectorLabel: "Tamanho da P\xE1gina",
  ariaChartMenuClose: "Fechar Menu de Edi\xE7\xE3o de Gr\xE1fico",
  ariaChartSelected: "Selecionado",
  ariaSkeletonCellLoadingFailed: "Falha ao carregar a linha",
  ariaSkeletonCellLoading: "Os dados da linha est\xE3o carregando",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Grupos de Linhas",
  ariaValuesDropZonePanelLabel: "Valores",
  ariaPivotDropZonePanelLabel: "R\xF3tulos de Colunas",
  ariaDropZoneColumnComponentDescription: "Pressione DELETE para remover",
  ariaDropZoneColumnValueItemDescription: "Pressione ENTER para mudar o tipo de agrega\xE7\xE3o",
  ariaDropZoneColumnGroupItemDescription: "Pressione ENTER para classificar",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " de ",
  ariaDropZoneColumnComponentSortAscending: "ascendente",
  ariaDropZoneColumnComponentSortDescending: "descendente",
  ariaLabelDialog: "Di\xE1logo",
  ariaLabelColumnMenu: "Menu da Coluna",
  ariaLabelColumnFilter: "Filtro da Coluna",
  ariaLabelCellEditor: "Editor de C\xE9lula",
  ariaLabelSelectField: "Selecionar Campo",
  // aria labels for rich select
  ariaLabelRichSelectField: "Campo de Sele\xE7\xE3o Rico",
  ariaLabelRichSelectToggleSelection: "Pressione ESPA\xC7O para alternar a sele\xE7\xE3o",
  ariaLabelRichSelectDeselectAllItems: "Pressione DELETE para desmarcar todos os itens",
  ariaLabelRichSelectDeleteSelection: "Pressione DELETE para desmarcar item",
  ariaLabelTooltip: "Dica",
  ariaLabelContextMenu: "Menu de Contexto",
  ariaLabelSubMenu: "Submenu",
  ariaLabelAggregationFunction: "Fun\xE7\xE3o de Agrega\xE7\xE3o",
  ariaLabelAdvancedFilterAutocomplete: "Autocompletar Filtro Avan\xE7ado",
  ariaLabelAdvancedFilterBuilderAddField: "Construtor de Filtro Avan\xE7ado Adicionar Campo",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Construtor de Filtro Avan\xE7ado Selecionar Campo da Coluna",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Construtor de Filtro Avan\xE7ado Selecionar Campo da Op\xE7\xE3o",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Construtor de Filtro Avan\xE7ado Selecionar Operador de Jun\xE7\xE3o",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Lista de Colunas",
  ariaFilterPanelList: "Lista de Filtros",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Verdadeiro",
  false: "Falso",
  invalidDate: "Data Inv\xE1lida",
  invalidNumber: "N\xFAmero Inv\xE1lido",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Mar\xE7o",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.AA",
  timeFormatDotsMDDYY: "M.DD.AA",
  timeFormatDashesYYYYMMDD: "AAAA-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM AAAA",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/ro-RO.ts
var AG_GRID_LOCALE_RO = {
  // Set Filter
  selectAll: "(Selecteaz\u0103 tot)",
  selectAllSearchResults: "(Selecteaz\u0103 toate rezultatele c\u0103ut\u0103rii)",
  addCurrentSelectionToFilter: "Adaug\u0103 selec\u021Bia curent\u0103 la filtru",
  searchOoo: "C\u0103utare...",
  blanks: "(Goluri)",
  noMatches: "F\u0103r\u0103 potriviri",
  // Number Filter & Text Filter
  filterOoo: "Filtreaz\u0103...",
  equals: "Egal",
  notEqual: "Nu este egal",
  blank: "Gol",
  notBlank: "Nu este gol",
  empty: "Alege una",
  // Number Filter
  lessThan: "Mai pu\u021Bin dec\xE2t",
  greaterThan: "Mai mult dec\xE2t",
  lessThanOrEqual: "Mai pu\u021Bin sau egal cu",
  greaterThanOrEqual: "Mai mult sau egal cu",
  inRange: "\xCEntre",
  inRangeStart: "De la",
  inRangeEnd: "P\xE2n\u0103 la",
  // Text Filter
  contains: "Con\u021Bine",
  notContains: "Nu con\u021Bine",
  startsWith: "\xCEncepe cu",
  endsWith: "Se termin\u0103 cu",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\xCEnainte",
  after: "Dup\u0103",
  // Filter Conditions
  andCondition: "\u0218I",
  orCondition: "SAU",
  // Filter Buttons
  applyFilter: "Aplic\u0103",
  resetFilter: "Reseteaz\u0103",
  clearFilter: "Cur\u0103\u021B\u0103",
  cancelFilter: "Anuleaz\u0103",
  // Filter Titles
  textFilter: "Filtru text",
  numberFilter: "Filtru num\u0103r",
  dateFilter: "Filtru dat\u0103",
  setFilter: "Filtru set",
  // Group Column Filter
  groupFilterSelect: "Selecteaz\u0103 c\xE2mp:",
  // Advanced Filter
  advancedFilterContains: "con\u021Bine",
  advancedFilterNotContains: "nu con\u021Bine",
  advancedFilterTextEquals: "egal",
  advancedFilterTextNotEqual: "nu este egal",
  advancedFilterStartsWith: "\xEEncepe cu",
  advancedFilterEndsWith: "se termin\u0103 cu",
  advancedFilterBlank: "este gol",
  advancedFilterNotBlank: "nu este gol",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "este adev\u0103rat",
  advancedFilterFalse: "este fals",
  advancedFilterAnd: "\u0218I",
  advancedFilterOr: "SAU",
  advancedFilterApply: "Aplic\u0103",
  advancedFilterBuilder: "Constructor",
  advancedFilterValidationMissingColumn: "Lips\u0103 coloan\u0103",
  advancedFilterValidationMissingOption: "Lips\u0103 op\u021Biune",
  advancedFilterValidationMissingValue: "Lips\u0103 valoare",
  advancedFilterValidationInvalidColumn: "Coloan\u0103 neg\u0103sit\u0103",
  advancedFilterValidationInvalidOption: "Op\u021Biune neg\u0103sit\u0103",
  advancedFilterValidationMissingQuote: "Valoarea nu are o ghilimele de \xEEncheiere",
  advancedFilterValidationNotANumber: "Valoarea nu este un num\u0103r",
  advancedFilterValidationInvalidDate: "Valoarea nu este o dat\u0103 valid\u0103",
  advancedFilterValidationMissingCondition: "Lips\u0103 condi\u021Bie",
  advancedFilterValidationJoinOperatorMismatch: "Operatorii de unire din cadrul unei condi\u021Bii trebuie s\u0103 fie aceea\u0219i",
  advancedFilterValidationInvalidJoinOperator: "Operator de unire neg\u0103sit",
  advancedFilterValidationMissingEndBracket: "Lips\u0103 parantez\u0103 de \xEEncheiere",
  advancedFilterValidationExtraEndBracket: "Prea multe paranteze de \xEEncheiere",
  advancedFilterValidationMessage: "Expresia are o eroare. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Expresia are o eroare. ${variable} la sf\xE2r\u0219itul expresiei.",
  advancedFilterBuilderTitle: "Filtru Avansat",
  advancedFilterBuilderApply: "Aplic\u0103",
  advancedFilterBuilderCancel: "Anuleaz\u0103",
  advancedFilterBuilderAddButtonTooltip: "Adaug\u0103 Filtru sau Grup",
  advancedFilterBuilderRemoveButtonTooltip: "Elimin\u0103",
  advancedFilterBuilderMoveUpButtonTooltip: "Mut\u0103 \xCEn Sus",
  advancedFilterBuilderMoveDownButtonTooltip: "Mut\u0103 \xCEn Jos",
  advancedFilterBuilderAddJoin: "Adaug\u0103 Grup",
  advancedFilterBuilderAddCondition: "Adaug\u0103 Filtru",
  advancedFilterBuilderSelectColumn: "Selecteaz\u0103 o coloan\u0103",
  advancedFilterBuilderSelectOption: "Selecteaz\u0103 o op\u021Biune",
  advancedFilterBuilderEnterValue: "Introdu o valoare...",
  advancedFilterBuilderValidationAlreadyApplied: "Filtrul curent a fost deja aplicat.",
  advancedFilterBuilderValidationIncomplete: "Nu toate condi\u021Biile sunt complete.",
  advancedFilterBuilderValidationSelectColumn: "Trebuie s\u0103 selecta\u021Bi o coloan\u0103.",
  advancedFilterBuilderValidationSelectOption: "Trebuie s\u0103 selecta\u021Bi o op\u021Biune.",
  advancedFilterBuilderValidationEnterValue: "Trebuie s\u0103 introduce\u021Bi o valoare.",
  // Side Bar
  columns: "Coloane",
  filters: "Filtre",
  // columns tool panel
  pivotMode: "Mod Pivot",
  groups: "Grupuri de R\xE2nduri",
  rowGroupColumnsEmptyMessage: "Trage\u021Bi aici pentru a seta grupuri de r\xE2nduri",
  values: "Valori",
  valueColumnsEmptyMessage: "Trage\u021Bi aici pentru a agrega",
  pivots: "Etichete de Coloane",
  pivotColumnsEmptyMessage: "Trage\u021Bi aici pentru a seta etichete de coloane",
  // Header of the Default Group Column
  group: "Grup",
  // Row Drag
  rowDragRow: "r\xE2nd",
  rowDragRows: "r\xE2nduri",
  // Other
  loadingOoo: "Se \xEEncarc\u0103...",
  loadingError: "EROARE",
  noRowsToShow: "Nicio r\xE2nduri de afi\u0219at",
  enabled: "Activat",
  // Menu
  pinColumn: "Fixeaz\u0103 Coloana",
  pinLeft: "Fixeaz\u0103 la St\xE2nga",
  pinRight: "Fixeaz\u0103 la Dreapta",
  noPin: "Nu Fixa",
  valueAggregation: "Agregare Valori",
  noAggregation: "Niciuna",
  autosizeThisColumn: "Auto redimensiona aceast\u0103 coloan\u0103",
  autosizeAllColumns: "Auto redimensiona toate coloanele",
  groupBy: "Grupare dup\u0103",
  ungroupBy: "Degrupa dup\u0103",
  ungroupAll: "Degrupa toate",
  addToValues: "Adaug\u0103 ${variable} la valori",
  removeFromValues: "Elimin\u0103 ${variable} din valori",
  addToLabels: "Adaug\u0103 ${variable} la etichete",
  removeFromLabels: "Elimin\u0103 ${variable} din etichete",
  resetColumns: "Reseteaz\u0103 coloanele",
  expandAll: "Extinde toate grupurile de r\xE2nduri",
  collapseAll: "\xCEnchide toate grupurile de r\xE2nduri",
  copy: "Copie",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copiaz\u0103 cu Antete",
  copyWithGroupHeaders: "Copiaz\u0103 cu Antete de Grup",
  cut: "Taie",
  paste: "Lipe\u0219te",
  ctrlV: "Ctrl+V",
  export: "Export\u0103",
  csvExport: "Export\u0103 CSV",
  excelExport: "Export\u0103 Excel",
  columnFilter: "Filtru de Coloan\u0103",
  columnChooser: "Alege Coloane",
  chooseColumns: "Alege Coloane",
  sortAscending: "Sortare Ascendent\u0103",
  sortDescending: "Sortare Descendent\u0103",
  sortUnSort: "\u0218terge Sortarea",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Sum\u0103",
  first: "Primul",
  last: "Ultimul",
  min: "Minim",
  max: "Maxim",
  none: "Niciunul",
  count: "Num\u0103r\u0103toare",
  avg: "Medie",
  filteredRows: "Filtrate",
  selectedRows: "Selectate",
  totalRows: "Total R\xE2nduri",
  totalAndFilteredRows: "R\xE2nduri",
  more: "Mai mult",
  to: "la",
  of: "din",
  page: "Pagin\u0103",
  pageLastRowUnknown: "?",
  nextPage: "Pagina Urm\u0103toare",
  lastPage: "Ultima Pagin\u0103",
  firstPage: "Prima Pagin\u0103",
  previousPage: "Pagina Anterioar\u0103",
  pageSizeSelectorLabel: "M\u0103rime Pagin\u0103:",
  footerTotal: "Total",
  // Pivoting
  pivotColumnGroupTotals: "Total",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Chart \u0219i Modul Pivot",
  pivotChart: "Pivot Chart",
  chartRange: "Interval Diagram\u0103",
  columnChart: "Coloan\u0103",
  groupedColumn: "Grupat",
  stackedColumn: "Stacked",
  normalizedColumn: "100% Stacked",
  barChart: "Bar\u0103",
  groupedBar: "Grupat",
  stackedBar: "Stacked",
  normalizedBar: "100% Stacked",
  pieChart: "Pl\u0103cint\u0103",
  pie: "Pl\u0103cint\u0103",
  donut: "Gogoa\u0219\u0103",
  line: "Linie",
  xyChart: "X Y (Puncte dispersate)",
  scatter: "Puncte dispersate",
  bubble: "Bule",
  areaChart: "Zon\u0103",
  area: "Zon\u0103",
  stackedArea: "Stacked",
  normalizedArea: "100% Stacked",
  histogramChart: "Histogram\u0103",
  polarChart: "Polar",
  radarLine: "Linie Radar",
  radarArea: "Zon\u0103 Radar",
  nightingale: "Nightingale",
  radialColumn: "Coloan\u0103 Radial\u0103",
  radialBar: "Bar\u0103 Radial\u0103",
  statisticalChart: "Statistic",
  boxPlot: "Grafic Box",
  rangeBar: "Bar\u0103 Interval",
  rangeArea: "Zon\u0103 Interval",
  hierarchicalChart: "Ierarhic",
  treemap: "Hart\u0103 Arbore",
  sunburst: "Explozie Solar\u0103",
  specializedChart: "Specializat",
  waterfall: "Cascad\u0103",
  heatmap: "Hart\u0103 Termic\u0103",
  combinationChart: "Combinat\u0103",
  columnLineCombo: "Coloan\u0103 \u0219i Linie",
  AreaColumnCombo: "Zon\u0103 \u0219i Coloan\u0103",
  // Charts
  pivotChartTitle: "Grafic Pivot",
  rangeChartTitle: "Grafic Gama",
  settings: "Grafic",
  data: "Set\u0103ri",
  format: "Personalizare",
  categories: "Categorii",
  defaultCategory: "(Nici unul)",
  series: "Serii",
  switchCategorySeries: "Schimbare Categorie / Serii",
  categoryValues: "Valori Categorie",
  seriesLabels: "Etichete Serii",
  aggregate: "Agregat",
  xyValues: "Valori X Y",
  paired: "Mod Pereche",
  axis: "Ax\u0103",
  xAxis: "Ax\u0103 Orizontal\u0103",
  yAxis: "Ax\u0103 Vertical\u0103",
  polarAxis: "Ax\u0103 Polar\u0103",
  radiusAxis: "Ax\u0103 Radius",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Anima\u021Bie",
  crosshair: "Cercetare",
  color: "Culoare",
  thickness: "Grosime",
  preferredLength: "Lungime preferat\u0103",
  xType: "Tip X",
  axisType: "Tip Ax\u0103",
  automatic: "Automat",
  category: "Categorie",
  number: "Num\u0103r",
  time: "Timp",
  timeFormat: "Format Timp",
  autoRotate: "Rota\u021Bie automat\u0103",
  labelRotation: "Rota\u021Bie",
  circle: "Cerc",
  polygon: "Poligon",
  square: "P\u0103trat",
  cross: "Cruce",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Triunghi",
  heart: "Inim\u0103",
  orientation: "Orientare",
  fixed: "Fix",
  parallel: "Paralel",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Pozi\u021Bie",
  ticks: "Marcaje",
  gridLines: "Linii Gril\u0103",
  width: "L\u0103\u021Bime",
  height: "\xCEn\u0103l\u021Bime",
  length: "Lungime",
  padding: "Padding",
  spacing: "Spa\u021Biere",
  chartStyle: "Stil Grafic",
  title: "Titlu",
  chartTitles: "Titluri",
  chartTitle: "Titlu Grafic",
  chartSubtitle: "Subtitlu",
  horizontalAxisTitle: "Titlu Ax\u0103 Orizontal\u0103",
  verticalAxisTitle: "Titlu Ax\u0103 Vertical\u0103",
  polarAxisTitle: "Titlu Ax\u0103 Polar\u0103",
  titlePlaceholder: "Titlu Grafic",
  background: "Fundal",
  font: "Font",
  weight: "Greutate",
  top: "Deasupra",
  right: "Dreapta",
  bottom: "Dedesubt",
  left: "St\xE2nga",
  labels: "Etichete",
  calloutLabels: "Etichete Apel",
  sectorLabels: "Etichete Sector",
  positionRatio: "Raport Pozi\u021Bie",
  size: "M\u0103rime",
  shape: "Form\u0103",
  minSize: "M\u0103rime Minim\u0103",
  maxSize: "M\u0103rime Maxim\u0103",
  legend: "Legend\u0103",
  position: "Pozi\u021Bie",
  markerSize: "M\u0103rime Marcaj",
  markerStroke: "Tu\u0219e Marcaj",
  markerPadding: "Padding Marcaj",
  itemSpacing: "Spa\u021Biere Elemente",
  itemPaddingX: "Padding Element X",
  itemPaddingY: "Padding Element Y",
  layoutHorizontalSpacing: "Spa\u021Biere Orizontal\u0103",
  layoutVerticalSpacing: "Spa\u021Biere Vertical\u0103",
  strokeWidth: "Grosime Linie",
  offset: "Offset",
  offsets: "Offset-uri",
  tooltips: "Tooltip-uri",
  callout: "Apel",
  markers: "Marcaje",
  shadow: "Umbr\u0103",
  blur: "Estompare",
  xOffset: "Offset X",
  yOffset: "Offset Y",
  lineWidth: "Grosime Linie",
  lineDash: "Linie Punctat\u0103",
  lineDashOffset: "Offset Linie Punctat\u0103",
  scrollingZoom: "Defilare",
  scrollingStep: "Pas Defilare",
  selectingZoom: "Selectare",
  durationMillis: "Durat\u0103 (ms)",
  crosshairLabel: "Etichet\u0103",
  crosshairSnap: "Fixare pe Nod",
  normal: "Normal",
  bold: "Aldin",
  italic: "Italic",
  boldItalic: "Aldin Italic",
  predefined: "Predefinit",
  fillOpacity: "Opacitate Umplere",
  strokeColor: "Culoare Linie",
  strokeOpacity: "Opacitate Linie",
  miniChart: "Mini-Grafic",
  histogramBinCount: "Num\u0103r Benzi",
  connectorLine: "Linie Conectare",
  seriesItems: "Elemente Serii",
  seriesItemType: "Tip Element",
  seriesItemPositive: "Pozitiv",
  seriesItemNegative: "Negativ",
  seriesItemLabels: "Etichete Elemente",
  columnGroup: "Coloan\u0103",
  barGroup: "Bar\u0103",
  pieGroup: "Pl\u0103cint\u0103",
  lineGroup: "Linie",
  scatterGroup: "X Y (Dispersie)",
  areaGroup: "Zon\u0103",
  polarGroup: "Polar",
  statisticalGroup: "Statistic",
  hierarchicalGroup: "Ierarhic",
  specializedGroup: "Specializat",
  combinationGroup: "Combina\u021Bie",
  groupedColumnTooltip: "Grupat",
  stackedColumnTooltip: "Stivuit",
  normalizedColumnTooltip: "100% Stivuit",
  groupedBarTooltip: "Grupat",
  stackedBarTooltip: "Stivuit",
  normalizedBarTooltip: "100% Stivuit",
  pieTooltip: "Pl\u0103cint\u0103",
  donutTooltip: "Gogoas\u0103",
  lineTooltip: "Linie",
  groupedAreaTooltip: "Ariat",
  stackedAreaTooltip: "Stivuit",
  normalizedAreaTooltip: "100% Stivuit",
  scatterTooltip: "Dispersie",
  bubbleTooltip: "Bul\u0103",
  histogramTooltip: "Histogram\u0103",
  radialColumnTooltip: "Coloan\u0103 Radial\u0103",
  radialBarTooltip: "Bar\u0103 Radial\u0103",
  radarLineTooltip: "Linie Radar",
  radarAreaTooltip: "Zon\u0103 Radar",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Bar\u0103 Gama",
  rangeAreaTooltip: "Zon\u0103 Gama",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Hart\u0103 Arbori",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Cascad\u0103",
  heatmapTooltip: "Hart\u0103 C\u0103ldur\u0103",
  columnLineComboTooltip: "Coloan\u0103 & Linie",
  areaColumnComboTooltip: "Zon\u0103 & Coloan\u0103",
  customComboTooltip: "Combina\u021Bie Personalizat\u0103",
  innerRadius: "Raza Interioar\u0103",
  startAngle: "Unghi de Start",
  endAngle: "Unghi de Sf\xE2r\u0219it",
  reverseDirection: "Direc\u021Bie Invers\u0103",
  groupPadding: "Padding Grup",
  seriesPadding: "Padding Serii",
  tile: "Plac\u0103",
  whisker: "Codi\u021B\u0103",
  cap: "Capac",
  capLengthRatio: "Raport Lungime Capac",
  labelPlacement: "Plasament Etichet\u0103",
  inside: "\xCEn Interior",
  outside: "\xCEn Exterior",
  noDataToChart: "Nu sunt date disponibile pentru grafic.",
  pivotChartRequiresPivotMode: "Graficul Pivot necesit\u0103 activarea Modului Pivot.",
  chartSettingsToolbarTooltip: "Meniu",
  chartLinkToolbarTooltip: "Legat la Grila",
  chartUnlinkToolbarTooltip: "Nelegat de Gril\u0103",
  chartDownloadToolbarTooltip: "Desc\u0103rcare Grafic",
  chartMenuToolbarTooltip: "Meniu",
  chartEdit: "Editare Grafic",
  chartAdvancedSettings: "Set\u0103ri Avansate",
  chartLink: "Legare la Gril\u0103",
  chartUnlink: "Dezlegare de Gril\u0103",
  chartDownload: "Desc\u0103rcare Grafic",
  histogramFrequency: "Frecven\u021B\u0103",
  seriesChartType: "Tip Grafic Serii",
  seriesType: "Tip Serii",
  secondaryAxis: "Ax\u0103 Secundar\u0103",
  seriesAdd: "Ad\u0103uga\u021Bi o serie",
  categoryAdd: "Ad\u0103uga\u021Bi o categorie",
  bar: "Bar\u0103",
  column: "Coloan\u0103",
  histogram: "Histogram\u0103",
  advancedSettings: "Set\u0103ri Avansate",
  direction: "Direc\u021Bie",
  horizontal: "Orizontal",
  vertical: "Vertical",
  seriesGroupType: "Tip Grup",
  groupedSeriesGroupType: "Grupat",
  stackedSeriesGroupType: "Stivuit",
  normalizedSeriesGroupType: "100% Stivuit",
  legendEnabled: "Activat",
  invalidColor: "Valoarea culorii este invalid\u0103",
  groupedColumnFull: "Coloan\u0103 Grupat\u0103",
  stackedColumnFull: "Coloan\u0103 Stivuit\u0103",
  normalizedColumnFull: "Coloan\u0103 100% Stivuit\u0103",
  groupedBarFull: "Bar\u0103 Grupat\u0103",
  stackedBarFull: "Bar\u0103 Stivuit\u0103",
  normalizedBarFull: "Bar\u0103 100% Stivuit\u0103",
  stackedAreaFull: "Zon\u0103 Stivuit\u0103",
  normalizedAreaFull: "Zon\u0103 100% Stivuit\u0103",
  customCombo: "Combina\u021Bie Personalizat\u0103",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Nivel ${variable}. Ap\u0103sa\u021Bi ENTER pentru a edita.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Nivel ${variable}. ${variable} Ap\u0103sa\u021Bi ENTER pentru a edita.",
  ariaAdvancedFilterBuilderList: "Lista Constructorului de Filtre Avansate",
  ariaAdvancedFilterBuilderFilterItem: "Condi\u021Bie de Filtrare",
  ariaAdvancedFilterBuilderGroupItem: "Grup de Filtre",
  ariaAdvancedFilterBuilderColumn: "Coloan\u0103",
  ariaAdvancedFilterBuilderOption: "Op\u021Biune",
  ariaAdvancedFilterBuilderValueP: "Valoare",
  ariaAdvancedFilterBuilderJoinOperator: "Operator de \xCEmbinare",
  ariaAdvancedFilterInput: "Intrare Filtru Avansat",
  ariaChecked: "bifat",
  ariaColumn: "Coloan\u0103",
  ariaColumnGroup: "Grup de Coloane",
  ariaColumnFiltered: "Coloan\u0103 Filtrat\u0103",
  ariaColumnSelectAll: "Comut\u0103 Selectarea Tuturor Coloanelor",
  ariaDateFilterInput: "Intrare Filtru de Dat\u0103",
  ariaDefaultListName: "List\u0103",
  ariaFilterColumnsInput: "Intrare Filtrare Coloane",
  ariaFilterFromValue: "Filtreaz\u0103 de la valoare",
  ariaFilterInput: "Intrare Filtru",
  ariaFilterList: "List\u0103 de Filtre",
  ariaFilterToValue: "Filtreaz\u0103 p\xE2n\u0103 la valoare",
  ariaFilterValue: "Valoare Filtrat\u0103",
  ariaFilterMenuOpen: "Deschide Meniul Filtrului",
  ariaFilteringOperator: "Operator de Filtrare",
  ariaHidden: "ascuns",
  ariaIndeterminate: "nedeterminat",
  ariaInputEditor: "Editor Intrare",
  ariaMenuColumn: "Ap\u0103sa\u021Bi ALT \xEEn jos pentru a deschide meniul coloanei",
  ariaFilterColumn: "Ap\u0103sa\u021Bi CTRL ENTER pentru a deschide filtrul",
  ariaRowDeselect: "Ap\u0103sa\u021Bi SPACE pentru a deselecta acest r\xE2nd",
  ariaRowSelectAll: "Ap\u0103sa\u021Bi Space pentru a comuta selec\u021Bia tuturor r\xE2ndurilor",
  ariaRowToggleSelection: "Ap\u0103sa\u021Bi Space pentru a comuta selec\u021Bia r\xE2ndului",
  ariaRowSelect: "Ap\u0103sa\u021Bi SPACE pentru a selecta acest r\xE2nd",
  ariaRowSelectionDisabled: "Selec\u021Bia r\xE2ndului este dezactivat\u0103 pentru acest r\xE2nd",
  ariaSearch: "C\u0103utare",
  ariaSortableColumn: "Ap\u0103sa\u021Bi ENTER pentru a sorta",
  ariaToggleVisibility: "Ap\u0103sa\u021Bi SPACE pentru a comuta vizibilitatea",
  ariaToggleCellValue: "Ap\u0103sa\u021Bi SPACE pentru a comuta valoarea celulei",
  ariaUnchecked: "nebifat",
  ariaVisible: "vizibil",
  ariaSearchFilterValues: "Caut\u0103 valori ale filtrului",
  ariaPageSizeSelectorLabel: "Dimensiunea Paginii",
  ariaChartMenuClose: "\xCEnchide meniul de editare a graficului",
  ariaChartSelected: "Selectat",
  ariaSkeletonCellLoadingFailed: "\xCEnc\u0103rcarea r\xE2ndului a e\u0219uat",
  ariaSkeletonCellLoading: "Datele r\xE2ndului se \xEEncarc\u0103",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Grupuri de r\xE2nduri",
  ariaValuesDropZonePanelLabel: "Valori",
  ariaPivotDropZonePanelLabel: "Etichete de coloan\u0103",
  ariaDropZoneColumnComponentDescription: "Ap\u0103sa\u021Bi DELETE pentru a elimina",
  ariaDropZoneColumnValueItemDescription: "Ap\u0103sa\u021Bi ENTER pentru a schimba tipul de agregare",
  ariaDropZoneColumnGroupItemDescription: "Ap\u0103sa\u021Bi ENTER pentru a sorta",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " din ",
  ariaDropZoneColumnComponentSortAscending: "cresc\u0103tor",
  ariaDropZoneColumnComponentSortDescending: "descresc\u0103tor",
  ariaLabelDialog: "Dialog",
  ariaLabelColumnMenu: "Meniu Coloan\u0103",
  ariaLabelColumnFilter: "Filtru Coloan\u0103",
  ariaLabelCellEditor: "Editor de Celule",
  ariaLabelSelectField: "Selecteaz\u0103 C\xE2mp",
  // aria labels for rich select
  ariaLabelRichSelectField: "C\xE2mp Selectare Avansat\u0103",
  ariaLabelRichSelectToggleSelection: "Ap\u0103sa\u021Bi SPACE pentru a comuta selec\u021Bia",
  ariaLabelRichSelectDeselectAllItems: "Ap\u0103sa\u021Bi DELETE pentru a deselecta toate elementele",
  ariaLabelRichSelectDeleteSelection: "Ap\u0103sa\u021Bi DELETE pentru a deselecta elementul",
  ariaLabelTooltip: "Sugestie",
  ariaLabelContextMenu: "Meniu Contextual",
  ariaLabelSubMenu: "Submeniu",
  ariaLabelAggregationFunction: "Func\u021Bia de Agregare",
  ariaLabelAdvancedFilterAutocomplete: "Autocompletare Filtru Avansat",
  ariaLabelAdvancedFilterBuilderAddField: "Constructor Filtru Avansat - Ad\u0103ugare C\xE2mp",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Constructor Filtru Avansat - Selectare Coloan\u0103 C\xE2mp",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Constructor Filtru Avansat - Selectare Op\u021Biune C\xE2mp",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Constructor Filtru Avansat - Selectare Operator De Unire C\xE2mp",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "List\u0103 de coloane",
  ariaFilterPanelList: "List\u0103 de filtre",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Adev\u0103rat",
  false: "Fals",
  invalidDate: "Dat\u0103 invalid\u0103",
  invalidNumber: "Num\u0103r invalid",
  january: "Ianuarie",
  february: "Februarie",
  march: "Martie",
  april: "Aprilie",
  may: "Mai",
  june: "Iunie",
  july: "Iulie",
  august: "August",
  september: "Septembrie",
  october: "Octombrie",
  november: "Noiembrie",
  december: "Decembrie",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/sk-SK.ts
var AG_GRID_LOCALE_SK = {
  // Set Filter
  selectAll: "(Vybra\u0165 v\u0161etko)",
  selectAllSearchResults: "(Vybra\u0165 v\u0161etky v\xFDsledky vyh\u013Ead\xE1vania)",
  addCurrentSelectionToFilter: "Prida\u0165 aktu\xE1lny v\xFDber do filtra",
  searchOoo: "Vyh\u013Ead\xE1vanie...",
  blanks: "(Pr\xE1zdne)",
  noMatches: "\u017Diadne zhody",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Rovn\xE1 sa",
  notEqual: "Nerovn\xE1 sa",
  blank: "Pr\xE1zdny",
  notBlank: "Nie je pr\xE1zdny",
  empty: "Vyberte jednu mo\u017Enos\u0165",
  // Number Filter
  lessThan: "Menej ako",
  greaterThan: "Viac ako",
  lessThanOrEqual: "Menej alebo rovn\xE9",
  greaterThanOrEqual: "Viac alebo rovn\xE9",
  inRange: "Medzi",
  inRangeStart: "Od",
  inRangeEnd: "Do",
  // Text Filter
  contains: "Obsahuje",
  notContains: "Neobsahuje",
  startsWith: "Za\u010D\xEDna s",
  endsWith: "Kon\u010D\xED s",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Pred",
  after: "Po",
  // Filter Conditions
  andCondition: "A",
  orCondition: "ALEBO",
  // Filter Buttons
  applyFilter: "Pou\u017Ei\u0165",
  resetFilter: "Resetova\u0165",
  clearFilter: "Vy\u010Disti\u0165",
  cancelFilter: "Zru\u0161i\u0165",
  // Filter Titles
  textFilter: "Textov\xFD filter",
  numberFilter: "\u010C\xEDseln\xFD filter",
  dateFilter: "D\xE1tumov\xFD filter",
  setFilter: "Nastaven\xFD filter",
  // Group Column Filter
  groupFilterSelect: "Vyberte pole:",
  // Advanced Filter
  advancedFilterContains: "obsahuje",
  advancedFilterNotContains: "neobsahuje",
  advancedFilterTextEquals: "rovn\xE1 sa",
  advancedFilterTextNotEqual: "nerovn\xE1 sa",
  advancedFilterStartsWith: "za\u010D\xEDna na",
  advancedFilterEndsWith: "kon\u010D\xED na",
  advancedFilterBlank: "je pr\xE1zdne",
  advancedFilterNotBlank: "nie je pr\xE1zdne",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "je pravdiv\xE9",
  advancedFilterFalse: "je nepravdiv\xE9",
  advancedFilterAnd: "A",
  advancedFilterOr: "ALEBO",
  advancedFilterApply: "Pou\u017Ei\u0165",
  advancedFilterBuilder: "Tvorca",
  advancedFilterValidationMissingColumn: "Ch\xFDba st\u013Apec",
  advancedFilterValidationMissingOption: "Ch\xFDba mo\u017Enos\u0165",
  advancedFilterValidationMissingValue: "Ch\xFDba hodnota",
  advancedFilterValidationInvalidColumn: "St\u013Apec sa nena\u0161iel",
  advancedFilterValidationInvalidOption: "Mo\u017Enos\u0165 sa nena\u0161la",
  advancedFilterValidationMissingQuote: "Hodnota ch\xFDba koncov\xE1 \xFAvodzovka",
  advancedFilterValidationNotANumber: "Hodnota nie je \u010D\xEDslo",
  advancedFilterValidationInvalidDate: "Hodnota nie je platn\xFD d\xE1tum",
  advancedFilterValidationMissingCondition: "Ch\xFDba podmienka",
  advancedFilterValidationJoinOperatorMismatch: "Spojovacie oper\xE1tory v r\xE1mci podmienky musia by\u0165 rovnak\xE9",
  advancedFilterValidationInvalidJoinOperator: "Spojovac\xED oper\xE1tor sa nena\u0161iel",
  advancedFilterValidationMissingEndBracket: "Ch\xFDba koncov\xE1 z\xE1tvorka",
  advancedFilterValidationExtraEndBracket: "Pr\xEDli\u0161 ve\u013Ea koncov\xFDch z\xE1tvoriek",
  advancedFilterValidationMessage: "V\xFDraz obsahuje chybu. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "V\xFDraz obsahuje chybu. ${variable} na konci v\xFDrazu.",
  advancedFilterBuilderTitle: "Pokro\u010Dil\xFD filter",
  advancedFilterBuilderApply: "Pou\u017Ei\u0165",
  advancedFilterBuilderCancel: "Zru\u0161i\u0165",
  advancedFilterBuilderAddButtonTooltip: "Prida\u0165 filter alebo skupinu",
  advancedFilterBuilderRemoveButtonTooltip: "Odstr\xE1ni\u0165",
  advancedFilterBuilderMoveUpButtonTooltip: "Presun\xFA\u0165 hore",
  advancedFilterBuilderMoveDownButtonTooltip: "Presun\xFA\u0165 dole",
  advancedFilterBuilderAddJoin: "Prida\u0165 skupinu",
  advancedFilterBuilderAddCondition: "Prida\u0165 filter",
  advancedFilterBuilderSelectColumn: "Vybra\u0165 st\u013Apec",
  advancedFilterBuilderSelectOption: "Vybra\u0165 mo\u017Enos\u0165",
  advancedFilterBuilderEnterValue: "Zadajte hodnotu...",
  advancedFilterBuilderValidationAlreadyApplied: "Aktu\xE1lny filter je u\u017E pou\u017Eit\xFD.",
  advancedFilterBuilderValidationIncomplete: "Nie v\u0161etky podmienky s\xFA kompletn\xE9.",
  advancedFilterBuilderValidationSelectColumn: "Mus\xED by\u0165 vybran\xFD st\u013Apec.",
  advancedFilterBuilderValidationSelectOption: "Mus\xED by\u0165 vybran\xE1 mo\u017Enos\u0165.",
  advancedFilterBuilderValidationEnterValue: "Mus\xED by\u0165 zadan\xE1 hodnota.",
  // Side Bar
  columns: "St\u013Apce",
  filters: "Filtre",
  // columns tool panel
  pivotMode: "Re\u017Eim Pivot",
  groups: "Skupiny riadkov",
  rowGroupColumnsEmptyMessage: "Presu\u0148te sem pre nastavenie skupinov\xFDch riadkov",
  values: "Hodnoty",
  valueColumnsEmptyMessage: "Presu\u0148te sem pre agreg\xE1ciu",
  pivots: "Ozna\u010Denia st\u013Apcov",
  pivotColumnsEmptyMessage: "Presu\u0148te sem pre nastavenie ozna\u010Den\xED st\u013Apcov",
  // Header of the Default Group Column
  group: "Skupina",
  // Row Drag
  rowDragRow: "riadok",
  rowDragRows: "riadky",
  // Other
  loadingOoo: "Na\u010D\xEDtavam...",
  loadingError: "CHYBA",
  noRowsToShow: "\u017Diadne riadky na zobrazenie",
  enabled: "Povolen\xE9",
  // Menu
  pinColumn: "Pripn\xFA\u0165 st\u013Apec",
  pinLeft: "Pripn\xFA\u0165 v\u013Eavo",
  pinRight: "Pripn\xFA\u0165 vpravo",
  noPin: "Neprep\xEDna\u0165",
  valueAggregation: "S\xFAhrn hodn\xF4t",
  noAggregation: "\u017Diadny",
  autosizeThisColumn: "Automaticky nastavi\u0165 ve\u013Ekos\u0165 tohto st\u013Apca",
  autosizeAllColumns: "Automaticky nastavi\u0165 ve\u013Ekos\u0165 v\u0161etk\xFDch st\u013Apcov",
  groupBy: "Zoskupi\u0165 pod\u013Ea",
  ungroupBy: "Odskupi\u0165 pod\u013Ea",
  ungroupAll: "Odskupi\u0165 v\u0161etko",
  addToValues: "Prida\u0165 ${variable} do hodn\xF4t",
  removeFromValues: "Odstr\xE1ni\u0165 ${variable} z hodn\xF4t",
  addToLabels: "Prida\u0165 ${variable} do ozna\u010Den\xED",
  removeFromLabels: "Odstr\xE1ni\u0165 ${variable} z ozna\u010Den\xED",
  resetColumns: "Resetova\u0165 st\u013Apce",
  expandAll: "Rozbali\u0165 v\u0161etky skupiny riadkov",
  collapseAll: "Zbali\u0165 v\u0161etky skupiny riadkov",
  copy: "Kop\xEDrova\u0165",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kop\xEDrova\u0165 s hlavi\u010Dkami",
  copyWithGroupHeaders: "Kop\xEDrova\u0165 s hlavi\u010Dkami skup\xEDn",
  cut: "Vystrihn\xFA\u0165",
  paste: "Vlo\u017Ei\u0165",
  ctrlV: "Ctrl+V",
  export: "Export",
  csvExport: "Export do CSV",
  excelExport: "Export do Excelu",
  columnFilter: "Filter st\u013Apca",
  columnChooser: "V\xFDber st\u013Apcov",
  chooseColumns: "V\xFDber st\u013Apcov",
  sortAscending: "Zoradi\u0165 vzostupne",
  sortDescending: "Zoradi\u0165 zostupne",
  sortUnSort: "Zru\u0161i\u0165 zoradenie",
  // Enterprise Menu Aggregation and Status Bar
  sum: "S\xFA\u010Det",
  first: "Prv\xFD",
  last: "Posledn\xFD",
  min: "Minim\xE1lny",
  max: "Maxim\xE1lny",
  none: "\u017Diadny",
  count: "Po\u010Det",
  avg: "Priemer",
  filteredRows: "Filtrovan\xE9",
  selectedRows: "Vybran\xE9",
  totalRows: "Celkovo riadkov",
  totalAndFilteredRows: "Riadky",
  more: "Viac",
  to: "do",
  of: "z",
  page: "Strana",
  pageLastRowUnknown: "?",
  nextPage: "\u010Eal\u0161ia strana",
  lastPage: "Posledn\xE1 strana",
  firstPage: "Prv\xE1 strana",
  previousPage: "Predch\xE1dzaj\xFAca strana",
  pageSizeSelectorLabel: "Ve\u013Ekos\u0165 strany:",
  footerTotal: "Celkom",
  // Pivoting
  pivotColumnGroupTotals: "Celkom",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Kontingen\u010Dn\xFD graf a kontingen\u010Dn\xFD re\u017Eim",
  pivotChart: "Kontingen\u010Dn\xFD graf",
  chartRange: "Rozsah grafu",
  columnChart: "St\u013Apcov\xFD graf",
  groupedColumn: "Zoskupen\xE9",
  stackedColumn: "Skladan\xE9",
  normalizedColumn: "100% skladen\xE9",
  barChart: "Pruhov\xFD graf",
  groupedBar: "Zoskupen\xE9",
  stackedBar: "Skladan\xE9",
  normalizedBar: "100% skladen\xE9",
  pieChart: "Kol\xE1\u010Dov\xFD graf",
  pie: "Kol\xE1\u010D",
  donut: "Donut",
  line: "\u010Ciara",
  xyChart: "X Y (Rozptylov\xFD)",
  scatter: "Rozptyl",
  bubble: "Bublina",
  areaChart: "Oblas\u0165ov\xFD graf",
  area: "Oblas\u0165",
  stackedArea: "Skladan\xE9",
  normalizedArea: "100% skladen\xE9",
  histogramChart: "Histogram",
  polarChart: "Pol\xE1rny graf",
  radarLine: "Radarov\xE1 \u010Diara",
  radarArea: "Radarov\xE1 oblas\u0165",
  nightingale: "Nightingale",
  radialColumn: "Radi\xE1lny st\u013Apcov\xFD",
  radialBar: "Radi\xE1lny pruhov\xFD",
  statisticalChart: "\u0160tatistick\xFD graf",
  boxPlot: "Krabicov\xFD graf",
  rangeBar: "Pruhov\xFD graf rozp\xE4tia",
  rangeArea: "Graf rozp\xE4tia oblasti",
  hierarchicalChart: "Hierarchick\xFD graf",
  treemap: "Mapa stromov",
  sunburst: "Sunburst",
  specializedChart: "\u0160pecializovan\xFD graf",
  waterfall: "Vodop\xE1d",
  heatmap: "Tepeln\xE1 mapa",
  combinationChart: "Kombinovan\xFD graf",
  columnLineCombo: "St\u013Apcov\xFD a \u010Diarov\xFD",
  AreaColumnCombo: "Oblas\u0165ov\xFD a st\u013Apcov\xFD",
  // Charts
  pivotChartTitle: "Kontingen\u010Dn\xFD graf",
  rangeChartTitle: "Rozsahov\xFD graf",
  settings: "Graf",
  data: "Nastavenie",
  format: "Prisp\xF4sobenie",
  categories: "Kateg\xF3rie",
  defaultCategory: "(\u017Diadna)",
  series: "S\xE9rie",
  switchCategorySeries: "Prepn\xFA\u0165 kateg\xF3riu / s\xE9riu",
  categoryValues: "Hodnoty kateg\xF3rie",
  seriesLabels: "\u0160t\xEDtky s\xE9ri\xED",
  aggregate: "Agreg\xE1t",
  xyValues: "X Y hodnoty",
  paired: "P\xE1rov\xFD re\u017Eim",
  axis: "Os",
  xAxis: "Horizont\xE1lna os",
  yAxis: "Vertik\xE1lna os",
  polarAxis: "Pol\xE1rna os",
  radiusAxis: "Polomerov\xE1 os",
  navigator: "Navig\xE1tor",
  zoom: "Pribl\xED\u017Eenie",
  animation: "Anim\xE1cia",
  crosshair: "Kr\xED\u017Eov\xE9 vl\xE1sko",
  color: "Farba",
  thickness: "Hr\xFAbka",
  preferredLength: "Preferovan\xE1 d\u013A\u017Eka",
  xType: "X typ",
  axisType: "Typ osy",
  automatic: "Automatick\xFD",
  category: "Kateg\xF3ria",
  number: "\u010C\xEDslo",
  time: "\u010Cas",
  timeFormat: "Form\xE1t \u010Dasu",
  autoRotate: "Automatick\xE9 oto\u010Denie",
  labelRotation: "Oto\u010Denie",
  circle: "Kruh",
  polygon: "Mnohouholn\xEDk",
  square: "\u0160tvorec",
  cross: "Kr\xED\u017E",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Trojuholn\xEDk",
  heart: "Srdce",
  orientation: "Orient\xE1cia",
  fixed: "Fixn\xFD",
  parallel: "Paraleln\xFD",
  perpendicular: "Kolm\xFD",
  radiusAxisPosition: "Poz\xEDcia",
  ticks: "Zna\u010Dky",
  gridLines: "Mrie\u017Ekov\xE9 \u010Diary",
  width: "\u0160\xEDrka",
  height: "V\xFD\u0161ka",
  length: "D\u013A\u017Eka",
  padding: "Odsadenie",
  spacing: "Rozostup",
  chartStyle: "\u0160t\xFDl grafu",
  title: "N\xE1zov",
  chartTitles: "N\xE1zvy",
  chartTitle: "N\xE1zov grafu",
  chartSubtitle: "Podn\xE1zov",
  horizontalAxisTitle: "N\xE1zov horizont\xE1lnej osi",
  verticalAxisTitle: "N\xE1zov vertik\xE1lnej osi",
  polarAxisTitle: "N\xE1zov pol\xE1rnej osi",
  titlePlaceholder: "N\xE1zov grafu",
  background: "Pozadie",
  font: "P\xEDsmo",
  weight: "Hmotnos\u0165",
  top: "Hore",
  right: "Pravo",
  bottom: "Dole",
  left: "V\u013Eavo",
  labels: "\u0160t\xEDtky",
  calloutLabels: "\u0160t\xEDtky v\xFDzvy",
  sectorLabels: "\u0160t\xEDtky sektorov",
  positionRatio: "Pomer poz\xEDcie",
  size: "Ve\u013Ekos\u0165",
  shape: "Tvar",
  minSize: "Minim\xE1lna ve\u013Ekos\u0165",
  maxSize: "Maxim\xE1lna ve\u013Ekos\u0165",
  legend: "Legenda",
  position: "Poz\xEDcia",
  markerSize: "Ve\u013Ekos\u0165 zna\u010Dky",
  markerStroke: "Obrys zna\u010Dky",
  markerPadding: "Odsadenie zna\u010Dky",
  itemSpacing: "Rozostup polo\u017Eiek",
  itemPaddingX: "Odsadenie polo\u017Eky X",
  itemPaddingY: "Odsadenie polo\u017Eky Y",
  layoutHorizontalSpacing: "Horizont\xE1lny rozostup",
  layoutVerticalSpacing: "Vertik\xE1lny rozostup",
  strokeWidth: "\u0160\xEDrka obrysu",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltipy",
  callout: "V\xFDzva",
  markers: "Zna\u010Dky",
  shadow: "Tie\u0148",
  blur: "Rozmazanie",
  xOffset: "X offset",
  yOffset: "Y offset",
  lineWidth: "\u0160\xEDrka l\xEDnie",
  lineDash: "Preru\u0161ovan\xE1 \u010Diara",
  lineDashOffset: "Offset preru\u0161ovanej \u010Diary",
  scrollingZoom: "Rolovanie",
  scrollingStep: "Krok rolovania",
  selectingZoom: "V\xFDber",
  durationMillis: "Doba trvania (ms)",
  crosshairLabel: "\u0160t\xEDtok",
  crosshairSnap: "Prichyti\u0165 k uzlu",
  normal: "Norm\xE1lny",
  bold: "Tu\u010Dn\xFD",
  italic: "Kurz\xEDva",
  boldItalic: "Tu\u010Dn\xE1 kurz\xEDva",
  predefined: "Preddefinovan\xFD",
  fillOpacity: "Neprieh\u013Eadnos\u0165 v\xFDplne",
  strokeColor: "Farba obrysu",
  strokeOpacity: "Neprieh\u013Eadnos\u0165 obrysu",
  miniChart: "Mini-graf",
  histogramBinCount: "Po\u010Det ko\u0161ov",
  connectorLine: "Spojovacia \u010Diara",
  seriesItems: "Polo\u017Eky s\xE9ri\xED",
  seriesItemType: "Typ polo\u017Eky",
  seriesItemPositive: "Pozit\xEDvne",
  seriesItemNegative: "Negat\xEDvne",
  seriesItemLabels: "\u0160t\xEDtky polo\u017Eiek",
  columnGroup: "St\u013Apec",
  barGroup: "St\u013Apec",
  pieGroup: "Kol\xE1\u010D",
  lineGroup: "L\xEDnia",
  scatterGroup: "X Y (Rozptyl)",
  areaGroup: "Oblas\u0165",
  polarGroup: "Pol\xE1rny",
  statisticalGroup: "\u0160tatistick\xFD",
  hierarchicalGroup: "Hierarchick\xFD",
  specializedGroup: "\u0160pecializovan\xFD",
  combinationGroup: "Kombinovan\xFD",
  groupedColumnTooltip: "Zoskupen\xE9",
  stackedColumnTooltip: "Na sebe",
  normalizedColumnTooltip: "100% na sebe",
  groupedBarTooltip: "Zoskupen\xE9",
  stackedBarTooltip: "Na sebe",
  normalizedBarTooltip: "100% na sebe",
  pieTooltip: "Kol\xE1\u010D",
  donutTooltip: "Donut",
  lineTooltip: "L\xEDnia",
  groupedAreaTooltip: "Oblas\u0165",
  stackedAreaTooltip: "Na sebe",
  normalizedAreaTooltip: "100% na sebe",
  scatterTooltip: "Rozptyl",
  bubbleTooltip: "Bublina",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radi\xE1lny st\u013Apec",
  radialBarTooltip: "Radi\xE1lny st\u013Apec",
  radarLineTooltip: "Radarov\xE1 l\xEDnia",
  radarAreaTooltip: "Radarov\xE1 oblas\u0165",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Rozsahov\xFD st\u013Apec",
  rangeAreaTooltip: "Rozsahov\xE1 oblas\u0165",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Sunburst",
  waterfallTooltip: "Vodop\xE1dov\xFD graf",
  heatmapTooltip: "Heatmap",
  columnLineComboTooltip: "St\u013Apec a l\xEDnia",
  areaColumnComboTooltip: "Oblas\u0165 a st\u013Apec",
  customComboTooltip: "Vlastn\xE1 kombin\xE1cia",
  innerRadius: "Vn\xFAtorn\xFD polomer",
  startAngle: "Po\u010Diato\u010Dn\xFD uhol",
  endAngle: "Koncov\xFD uhol",
  reverseDirection: "Obr\xE1ti\u0165 smer",
  groupPadding: "Odsadenie skupiny",
  seriesPadding: "Odsadenie s\xE9rie",
  tile: "Dla\u017Edica",
  whisker: "F\xFAz",
  cap: "\u010Ciapka",
  capLengthRatio: "Pomer d\u013A\u017Eky",
  labelPlacement: "Umiestnenie",
  inside: "Vo vn\xFAtri",
  outside: "Vonku",
  noDataToChart: "Nie s\xFA k dispoz\xEDcii \u017Eiadne \xFAdaje na graf.",
  pivotChartRequiresPivotMode: "Kontingen\u010Dn\xFD graf vy\u017Eaduje zapnut\xFD kontingen\u010Dn\xFD re\u017Eim.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Prepojen\xE9 s mrie\u017Ekou",
  chartUnlinkToolbarTooltip: "Odpojen\xE9 od mrie\u017Eky",
  chartDownloadToolbarTooltip: "Stiahnu\u0165 graf",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Upravi\u0165 graf",
  chartAdvancedSettings: "Pokro\u010Dil\xE9 nastavenia",
  chartLink: "Prepoji\u0165 s mrie\u017Ekou",
  chartUnlink: "Odpoji\u0165 od mrie\u017Eky",
  chartDownload: "Stiahnu\u0165 graf",
  histogramFrequency: "Frekvencia",
  seriesChartType: "Typ grafu s\xE9rie",
  seriesType: "Typ s\xE9rie",
  secondaryAxis: "Sekund\xE1rna os",
  seriesAdd: "Prida\u0165 s\xE9riu",
  categoryAdd: "Prida\u0165 kateg\xF3riu",
  bar: "Bar",
  column: "St\u013Apec",
  histogram: "Histogram",
  advancedSettings: "Pokro\u010Dil\xE9 nastavenia",
  direction: "Smer",
  horizontal: "Horizont\xE1lny",
  vertical: "Vertik\xE1lny",
  seriesGroupType: "Typ skupiny s\xE9rie",
  groupedSeriesGroupType: "Zoskupen\xE9",
  stackedSeriesGroupType: "Na sebe",
  normalizedSeriesGroupType: "100% na sebe",
  legendEnabled: "Povolen\xE9",
  invalidColor: "Hodnota farby je neplatn\xE1",
  groupedColumnFull: "Zoskupen\xFD st\u013Apec",
  stackedColumnFull: "Na sebe st\u013Apec",
  normalizedColumnFull: "100% na sebe st\u013Apec",
  groupedBarFull: "Zoskupen\xFD bar",
  stackedBarFull: "Na sebe bar",
  normalizedBarFull: "100% na sebe bar",
  stackedAreaFull: "Na sebe oblas\u0165",
  normalizedAreaFull: "100% na sebe oblas\u0165",
  customCombo: "Vlastn\xE1 kombin\xE1cia",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \xDArove\u0148 ${variable}. Stla\u010Dte ENTER na \xFApravu.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \xDArove\u0148 ${variable}. ${variable} Stla\u010Dte ENTER na \xFApravu.",
  ariaAdvancedFilterBuilderList: "Zoznam Pokro\u010Dil\xFDch Filtrov",
  ariaAdvancedFilterBuilderFilterItem: "Podmienka Filtra",
  ariaAdvancedFilterBuilderGroupItem: "Skupina Filtrov",
  ariaAdvancedFilterBuilderColumn: "St\u013Apec",
  ariaAdvancedFilterBuilderOption: "Mo\u017Enos\u0165",
  ariaAdvancedFilterBuilderValueP: "Hodnota",
  ariaAdvancedFilterBuilderJoinOperator: "Spojovac\xED Oper\xE1tor",
  ariaAdvancedFilterInput: "Vstup Pokro\u010Dil\xE9ho Filtra",
  ariaChecked: "ozna\u010Den\xE9",
  ariaColumn: "St\u013Apec",
  ariaColumnGroup: "Skupina St\u013Apcov",
  ariaColumnFiltered: "St\u013Apec Filtrovan\xFD",
  ariaColumnSelectAll: "Prep\xEDna\u010D Ozna\u010Denia V\u0161etk\xFDch St\u013Apcov",
  ariaDateFilterInput: "Vstup D\xE1tumov\xE9ho Filtra",
  ariaDefaultListName: "Zoznam",
  ariaFilterColumnsInput: "Vstup Filtra pre St\u013Apce",
  ariaFilterFromValue: "Filtrova\u0165 od hodnoty",
  ariaFilterInput: "Vstup Filtra",
  ariaFilterList: "Zoznam Filtrov",
  ariaFilterToValue: "Filtrova\u0165 do hodnoty",
  ariaFilterValue: "Hodnota Filtra",
  ariaFilterMenuOpen: "Otvori\u0165 Menu Filtra",
  ariaFilteringOperator: "Filtra\u010Dn\xFD Oper\xE1tor",
  ariaHidden: "skryt\xE9",
  ariaIndeterminate: "neur\u010Den\xE9",
  ariaInputEditor: "Editor Vstupu",
  ariaMenuColumn: "Stla\u010Dte ALT DOWN na otvorenie menu st\u013Apcov",
  ariaFilterColumn: "Stla\u010Dte CTRL ENTER na otvorenie filtra",
  ariaRowDeselect: "Stla\u010Dte SPACE na zru\u0161enie v\xFDberu tohto riadku",
  ariaRowSelectAll: "Stla\u010Dte SPACE na prep\xEDnanie v\xFDberu v\u0161etk\xFDch riadkov",
  ariaRowToggleSelection: "Stla\u010Dte SPACE na prep\xEDnanie v\xFDberu riadku",
  ariaRowSelect: "Stla\u010Dte SPACE na v\xFDber tohto riadku",
  ariaRowSelectionDisabled: "V\xFDber riadku je pre tento riadok zak\xE1zan\xFD",
  ariaSearch: "H\u013Eada\u0165",
  ariaSortableColumn: "Stla\u010Dte ENTER na zoradenie",
  ariaToggleVisibility: "Stla\u010Dte SPACE na prep\xEDnanie vidite\u013Enosti",
  ariaToggleCellValue: "Stla\u010Dte SPACE na prep\xEDnanie hodnoty bunky",
  ariaUnchecked: "neozna\u010Den\xE9",
  ariaVisible: "vidite\u013En\xE9",
  ariaSearchFilterValues: "H\u013Eada\u0165 hodnoty filtra",
  ariaPageSizeSelectorLabel: "Ve\u013Ekos\u0165 Strany",
  ariaChartMenuClose: "Zatvori\u0165 Menu \xDApravy Grafu",
  ariaChartSelected: "Vybran\xE9",
  ariaSkeletonCellLoadingFailed: "Nahr\xE1vanie riadku zlyhalo",
  ariaSkeletonCellLoading: "\xDAdaje riadku sa nahr\xE1vaj\xFA",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Skupiny riadkov",
  ariaValuesDropZonePanelLabel: "Hodnoty",
  ariaPivotDropZonePanelLabel: "\u0160t\xEDtky st\u013Apcov",
  ariaDropZoneColumnComponentDescription: "Stla\u010Dte DELETE pre odstr\xE1nenie",
  ariaDropZoneColumnValueItemDescription: "Stla\u010Dte ENTER pre zmenu typu agreg\xE1cie",
  ariaDropZoneColumnGroupItemDescription: "Stla\u010Dte ENTER pre zoradenie",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " z ",
  ariaDropZoneColumnComponentSortAscending: "vzostupne",
  ariaDropZoneColumnComponentSortDescending: "zostupne",
  ariaLabelDialog: "Dial\xF3g",
  ariaLabelColumnMenu: "Ponuka st\u013Apcov",
  ariaLabelColumnFilter: "Filter st\u013Apcov",
  ariaLabelCellEditor: "Editor bunky",
  ariaLabelSelectField: "Vyberte pole",
  // aria labels for rich select
  ariaLabelRichSelectField: "Pole Rich Select",
  ariaLabelRichSelectToggleSelection: "Stla\u010Dte MEDZERN\xCDK na prepnutie v\xFDberu",
  ariaLabelRichSelectDeselectAllItems: "Stla\u010Dte DELETE na odzna\u010Denie v\u0161etk\xFDch polo\u017Eiek",
  ariaLabelRichSelectDeleteSelection: "Stla\u010Dte DELETE na odzna\u010Denie polo\u017Eky",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Kontextov\xE9 menu",
  ariaLabelSubMenu: "SubMenu",
  ariaLabelAggregationFunction: "Funkcia agreg\xE1cie",
  ariaLabelAdvancedFilterAutocomplete: "Pokro\u010Dil\xE9 filtrovanie Autocomplete",
  ariaLabelAdvancedFilterBuilderAddField: "Prida\u0165 pole v Pokro\u010Dilom filtrovan\xED",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Vybra\u0165 st\u013Apec v Pokro\u010Dilom filtrovan\xED",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Vybra\u0165 mo\u017Enos\u0165 v Pokro\u010Dilom filtrovan\xED",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Vybra\u0165 oper\xE1tor spojenia v Pokro\u010Dilom filtrovan\xED",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Zoznam st\u013Apcov",
  ariaFilterPanelList: "Zoznam filtrov",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Pravda",
  false: "Nepravda",
  invalidDate: "Neplatn\xFD d\xE1tum",
  invalidNumber: "Neplatn\xE9 \u010D\xEDslo",
  january: "Janu\xE1r",
  february: "Febru\xE1r",
  march: "Marec",
  april: "Apr\xEDl",
  may: "M\xE1j",
  june: "J\xFAn",
  july: "J\xFAl",
  august: "August",
  september: "September",
  october: "Okt\xF3ber",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.RR",
  timeFormatDotsMDDYY: "M.DD.RR",
  timeFormatDashesYYYYMMDD: "RRRR-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM RRRR",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM"
};

// community-modules/locale/src/sv-SE.ts
var AG_GRID_LOCALE_SE = {
  // Set Filter
  selectAll: "(V\xE4lj alla)",
  selectAllSearchResults: "(V\xE4lj alla s\xF6kresultat)",
  addCurrentSelectionToFilter: "L\xE4gg till nuvarande val till filter",
  searchOoo: "S\xF6k...",
  blanks: "(Tomma)",
  noMatches: "Inga matchningar",
  // Number Filter & Text Filter
  filterOoo: "Filter...",
  equals: "Lika med",
  notEqual: "Inte lika med",
  blank: "Tom",
  notBlank: "Inte tom",
  empty: "V\xE4lj en",
  // Number Filter
  lessThan: "Mindre \xE4n",
  greaterThan: "St\xF6rre \xE4n",
  lessThanOrEqual: "Mindre \xE4n eller lika med",
  greaterThanOrEqual: "St\xF6rre \xE4n eller lika med",
  inRange: "Mellan",
  inRangeStart: "Fr\xE5n",
  inRangeEnd: "Till",
  // Text Filter
  contains: "Inneh\xE5ller",
  notContains: "Inneh\xE5ller inte",
  startsWith: "B\xF6rjar med",
  endsWith: "Slutar med",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "F\xF6re",
  after: "Efter",
  // Filter Conditions
  andCondition: "OCH",
  orCondition: "ELLER",
  // Filter Buttons
  applyFilter: "Verkst\xE4ll",
  resetFilter: "\xC5terst\xE4ll",
  clearFilter: "Rensa",
  cancelFilter: "Avbryt",
  // Filter Titles
  textFilter: "Textfilter",
  numberFilter: "Nummerfilter",
  dateFilter: "Datumfilter",
  setFilter: "Setfilter",
  // Group Column Filter
  groupFilterSelect: "V\xE4lj f\xE4lt:",
  // Advanced Filter
  advancedFilterContains: "inneh\xE5ller",
  advancedFilterNotContains: "inneh\xE5ller inte",
  advancedFilterTextEquals: "\xE4r lika med",
  advancedFilterTextNotEqual: "\xE4r inte lika med",
  advancedFilterStartsWith: "b\xF6rjar med",
  advancedFilterEndsWith: "slutar med",
  advancedFilterBlank: "\xE4r tom",
  advancedFilterNotBlank: "\xE4r inte tom",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\xE4r sann",
  advancedFilterFalse: "\xE4r falsk",
  advancedFilterAnd: "OCH",
  advancedFilterOr: "ELLER",
  advancedFilterApply: "Till\xE4mpa",
  advancedFilterBuilder: "Byggare",
  advancedFilterValidationMissingColumn: "Kolumn saknas",
  advancedFilterValidationMissingOption: "Alternativ saknas",
  advancedFilterValidationMissingValue: "V\xE4rde saknas",
  advancedFilterValidationInvalidColumn: "Kolumn hittades inte",
  advancedFilterValidationInvalidOption: "Alternativ hittades inte",
  advancedFilterValidationMissingQuote: "V\xE4rdet saknar slutcitat",
  advancedFilterValidationNotANumber: "V\xE4rdet \xE4r inte ett nummer",
  advancedFilterValidationInvalidDate: "V\xE4rdet \xE4r inte ett giltigt datum",
  advancedFilterValidationMissingCondition: "Villkor saknas",
  advancedFilterValidationJoinOperatorMismatch: "Kombinatorer inom ett villkor m\xE5ste vara desamma",
  advancedFilterValidationInvalidJoinOperator: "Kombinator hittades inte",
  advancedFilterValidationMissingEndBracket: "Saknar slutparentes",
  advancedFilterValidationExtraEndBracket: "F\xF6r m\xE5nga slutparenteser",
  advancedFilterValidationMessage: "Uttrycket har ett fel. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Uttrycket har ett fel. ${variable} i slutet av uttrycket.",
  advancedFilterBuilderTitle: "Avancerad Filter",
  advancedFilterBuilderApply: "Till\xE4mpa",
  advancedFilterBuilderCancel: "Avbryt",
  advancedFilterBuilderAddButtonTooltip: "L\xE4gg till Filter eller Grupp",
  advancedFilterBuilderRemoveButtonTooltip: "Ta bort",
  advancedFilterBuilderMoveUpButtonTooltip: "Flytta upp",
  advancedFilterBuilderMoveDownButtonTooltip: "Flytta ner",
  advancedFilterBuilderAddJoin: "L\xE4gg till Grupp",
  advancedFilterBuilderAddCondition: "L\xE4gg till Filter",
  advancedFilterBuilderSelectColumn: "V\xE4lj en kolumn",
  advancedFilterBuilderSelectOption: "V\xE4lj ett alternativ",
  advancedFilterBuilderEnterValue: "Ange ett v\xE4rde...",
  advancedFilterBuilderValidationAlreadyApplied: "Nuvarande filter redan till\xE4mpat.",
  advancedFilterBuilderValidationIncomplete: "Inte alla villkor \xE4r fullst\xE4ndiga.",
  advancedFilterBuilderValidationSelectColumn: "M\xE5ste v\xE4lja en kolumn.",
  advancedFilterBuilderValidationSelectOption: "M\xE5ste v\xE4lja ett alternativ.",
  advancedFilterBuilderValidationEnterValue: "M\xE5ste ange ett v\xE4rde.",
  // Side Bar
  columns: "Kolumner",
  filters: "Filter",
  // columns tool panel
  pivotMode: "Pivotl\xE4ge",
  groups: "Radgrupper",
  rowGroupColumnsEmptyMessage: "Dra hit f\xF6r att st\xE4lla in radgrupper",
  values: "V\xE4rden",
  valueColumnsEmptyMessage: "Dra hit f\xF6r att aggregera",
  pivots: "Kolumnetiketter",
  pivotColumnsEmptyMessage: "Dra hit f\xF6r att st\xE4lla in kolumnetiketter",
  // Header of the Default Group Column
  group: "Grupp",
  // Row Drag
  rowDragRow: "raden",
  rowDragRows: "rader",
  // Other
  loadingOoo: "Laddar...",
  loadingError: "FEL",
  noRowsToShow: "Inga rader att visa",
  enabled: "Aktiverad",
  // Menu
  pinColumn: "F\xE4st Kolumn",
  pinLeft: "F\xE4st V\xE4nster",
  pinRight: "F\xE4st H\xF6ger",
  noPin: "Ingen F\xE4stning",
  valueAggregation: "V\xE4rdeaggregering",
  noAggregation: "Ingen",
  autosizeThisColumn: "Autosize Denna Kolumn",
  autosizeAllColumns: "Autosize Alla Kolumner",
  groupBy: "Gruppera efter",
  ungroupBy: "Avgruppera efter",
  ungroupAll: "Avgruppera Alla",
  addToValues: "L\xE4gg till ${variable} till v\xE4rden",
  removeFromValues: "Ta bort ${variable} fr\xE5n v\xE4rden",
  addToLabels: "L\xE4gg till ${variable} till etiketter",
  removeFromLabels: "Ta bort ${variable} fr\xE5n etiketter",
  resetColumns: "\xC5terst\xE4ll Kolumner",
  expandAll: "Expandera Alla Grupp-Rader",
  collapseAll: "St\xE4ng Alla Grupp-Rader",
  copy: "Kopiera",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Kopiera Med Rubriker",
  copyWithGroupHeaders: "Kopiera med Grupp-Rubriker",
  cut: "Klipp ut",
  paste: "Klistra in",
  ctrlV: "Ctrl+V",
  export: "Exportera",
  csvExport: "CSV Export",
  excelExport: "Excel Export",
  columnFilter: "Kolumnfilter",
  columnChooser: "V\xE4lj Kolumner",
  chooseColumns: "V\xE4lj Kolumner",
  sortAscending: "Sortera Stigande",
  sortDescending: "Sortera Fallande",
  sortUnSort: "Rensa Sortering",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Summa",
  first: "F\xF6rsta",
  last: "Sista",
  min: "Min",
  max: "Max",
  none: "Ingen",
  count: "Antal",
  avg: "Genomsnitt",
  filteredRows: "Filtrerade",
  selectedRows: "Valda",
  totalRows: "Totala Rader",
  totalAndFilteredRows: "Rader",
  more: "Mer",
  to: "till",
  of: "av",
  page: "Sida",
  pageLastRowUnknown: "?",
  nextPage: "N\xE4sta Sida",
  lastPage: "Sista Sida",
  firstPage: "F\xF6rsta Sida",
  previousPage: "F\xF6reg\xE5ende Sida",
  pageSizeSelectorLabel: "Sidstorlek:",
  footerTotal: "Totalt",
  // Pivoting
  pivotColumnGroupTotals: "Summa",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Diagram & Pivotl\xE4ge",
  pivotChart: "Pivot Diagram",
  chartRange: "Diagramomr\xE5de",
  columnChart: "Kolumn",
  groupedColumn: "Grupperad",
  stackedColumn: "Staplad",
  normalizedColumn: "100% Staplad",
  barChart: "Staplad",
  groupedBar: "Grupperad",
  stackedBar: "Staplad",
  normalizedBar: "100% Staplad",
  pieChart: "Cirkeldiagram",
  pie: "Cirkeldiagram",
  donut: "Ringdiagram",
  line: "Linjediagram",
  xyChart: "X Y (Spridning)",
  scatter: "Spridningsdiagram",
  bubble: "Bubbeldiagram",
  areaChart: "Ytdiagram",
  area: "Ytdiagram",
  stackedArea: "Staplad",
  normalizedArea: "100% Staplad",
  histogramChart: "Histogram",
  polarChart: "Polardiagram",
  radarLine: "Radarlinje",
  radarArea: "Radaryta",
  nightingale: "Nightingale",
  radialColumn: "Radiell Kolumn",
  radialBar: "Radiell Stapel",
  statisticalChart: "Statistisk",
  boxPlot: "L\xE5ddiagram",
  rangeBar: "Omr\xE5desstapel",
  rangeArea: "Omr\xE5desyta",
  hierarchicalChart: "Hierarkisk",
  treemap: "Tr\xE4ddiagram",
  sunburst: "Solburst Diagram",
  specializedChart: "Specialiserad",
  waterfall: "Vattenfall",
  heatmap: "V\xE4rmekarta",
  combinationChart: "Kombinationsdiagram",
  columnLineCombo: "Kolumn & Linje",
  AreaColumnCombo: "Yta & Kolumn",
  // Charts
  pivotChartTitle: "Pivotdiagram",
  rangeChartTitle: "Omf\xE5ngsdiagram",
  settings: "Diagram",
  data: "Inst\xE4llningar",
  format: "Anpassa",
  categories: "Kategorier",
  defaultCategory: "(Ingen)",
  series: "Serier",
  switchCategorySeries: "V\xE4xla Kategori / Serie",
  categoryValues: "Kategori V\xE4rden",
  seriesLabels: "Serieetiketter",
  aggregate: "Sammanfoga",
  xyValues: "X Y V\xE4rden",
  paired: "Parat L\xE4ge",
  axis: "Axel",
  xAxis: "Horisontell Axel",
  yAxis: "Vertikal Axel",
  polarAxis: "Polar Axel",
  radiusAxis: "Radie Axel",
  navigator: "Navigering",
  zoom: "Zooma",
  animation: "Animation",
  crosshair: "H\xE5rkors",
  color: "F\xE4rg",
  thickness: "Tjocklek",
  preferredLength: "F\xF6redragen L\xE4ngd",
  xType: "X Typ",
  axisType: "Axel Typ",
  automatic: "Automatisk",
  category: "Kategori",
  number: "Nummer",
  time: "Tid",
  timeFormat: "Tidsformat",
  autoRotate: "Auto Rotera",
  labelRotation: "Rotation",
  circle: "Cirkel",
  polygon: "Polygon",
  square: "Fyrkant",
  cross: "Kors",
  diamond: "Diamant",
  plus: "Plus",
  triangle: "Triangel",
  heart: "Hj\xE4rta",
  orientation: "Orientering",
  fixed: "Fast",
  parallel: "Parallell",
  perpendicular: "Vinkelr\xE4t",
  radiusAxisPosition: "Position",
  ticks: "Tidsintervall",
  gridLines: "Rutn\xE4t",
  width: "Bredd",
  height: "H\xF6jd",
  length: "L\xE4ngd",
  padding: "Utfyllnad",
  spacing: "Avst\xE5nd",
  chartStyle: "Diagramstil",
  title: "Titel",
  chartTitles: "Titlar",
  chartTitle: "Diagramtitel",
  chartSubtitle: "Undertitel",
  horizontalAxisTitle: "Titel Horisontell Axel",
  verticalAxisTitle: "Titel Vertikal Axel",
  polarAxisTitle: "Polar Axel Titel",
  titlePlaceholder: "Diagramtitel",
  background: "Bakgrund",
  font: "Teckensnitt",
  weight: "Vikt",
  top: "Topp",
  right: "H\xF6ger",
  bottom: "Botten",
  left: "V\xE4nster",
  labels: "Etiketter",
  calloutLabels: "Upplysningsetiketter",
  sectorLabels: "Sektoretiketter",
  positionRatio: "Positionskvot",
  size: "Storlek",
  shape: "Form",
  minSize: "Minsta Storlek",
  maxSize: "St\xF6rsta Storlek",
  legend: "F\xF6rklaring",
  position: "Position",
  markerSize: "Mark\xF6rstorlek",
  markerStroke: "Mark\xF6rlinje",
  markerPadding: "Mark\xF6rutfyllnad",
  itemSpacing: "Avst\xE5nd mellan objekt",
  itemPaddingX: "Objektutfyllnad X",
  itemPaddingY: "Objektutfyllnad Y",
  layoutHorizontalSpacing: "Horisontellt Avst\xE5nd",
  layoutVerticalSpacing: "Vertikalt Avst\xE5nd",
  strokeWidth: "Linjetjocklek",
  offset: "F\xF6rskjutning",
  offsets: "F\xF6rskjutningar",
  tooltips: "Verktygstips",
  callout: "Upplysning",
  markers: "Mark\xF6rer",
  shadow: "Skugga",
  blur: "Osk\xE4rpa",
  xOffset: "X F\xF6rskjutning",
  yOffset: "Y F\xF6rskjutning",
  lineWidth: "Linjebredd",
  lineDash: "Linjedasningar",
  lineDashOffset: "Das f\xF6rskjutning",
  scrollingZoom: "Scrolla",
  scrollingStep: "Scrollsteg",
  selectingZoom: "Markera",
  durationMillis: "Varaktighet (ms)",
  crosshairLabel: "Etikett",
  crosshairSnap: "F\xE4st vid Nod",
  normal: "Normal",
  bold: "Fet",
  italic: "Kursiv",
  boldItalic: "Fet Kursiv",
  predefined: "F\xF6rdefinierad",
  fillOpacity: "Fyllnadsopacitet",
  strokeColor: "Linjef\xE4rg",
  strokeOpacity: "Linjeopacitet",
  miniChart: "Mini-Diagram",
  histogramBinCount: "Binantal",
  connectorLine: "Kopplingslinje",
  seriesItems: "Serieobjekt",
  seriesItemType: "Objekttyp",
  seriesItemPositive: "Positiv",
  seriesItemNegative: "Negativ",
  seriesItemLabels: "Objektetiketter",
  columnGroup: "Kolumn",
  barGroup: "Stapeldiagram",
  pieGroup: "Cirkeldiagram",
  lineGroup: "Linjediagram",
  scatterGroup: "X Y (Spridning)",
  areaGroup: "Yta",
  polarGroup: "Pol\xE4r",
  statisticalGroup: "Statistisk",
  hierarchicalGroup: "Hierarkisk",
  specializedGroup: "Specialiserad",
  combinationGroup: "Kombinerad",
  groupedColumnTooltip: "Grupperade",
  stackedColumnTooltip: "Staplade",
  normalizedColumnTooltip: "100% Staplade",
  groupedBarTooltip: "Grupperade",
  stackedBarTooltip: "Staplade",
  normalizedBarTooltip: "100% Staplade",
  pieTooltip: "Cirkeldiagram",
  donutTooltip: "Donut",
  lineTooltip: "Linjediagram",
  groupedAreaTooltip: "Yta",
  stackedAreaTooltip: "Staplad Yta",
  normalizedAreaTooltip: "100% Staplad Yta",
  scatterTooltip: "Spridning",
  bubbleTooltip: "Bubblor",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radiell Kolumn",
  radialBarTooltip: "Radiell Stapel",
  radarLineTooltip: "Radardiagram",
  radarAreaTooltip: "Radardiagramyta",
  nightingaleTooltip: "N\xE4ktergal",
  rangeBarTooltip: "Omf\xE5ng Stapel",
  rangeAreaTooltip: "Omf\xE5ng Yta",
  boxPlotTooltip: "L\xE5ddiagram",
  treemapTooltip: "Tr\xE4ddiagram",
  sunburstTooltip: "Solstr\xE5lediagram",
  waterfallTooltip: "Vattenfall",
  heatmapTooltip: "V\xE4rmekarta",
  columnLineComboTooltip: "Kolumn & Linje",
  areaColumnComboTooltip: "Yta & Kolumn",
  customComboTooltip: "Anpassad Kombination",
  innerRadius: "Inre Radie",
  startAngle: "Startvinkel",
  endAngle: "Slutvinkel",
  reverseDirection: "Omv\xE4nd Riktning",
  groupPadding: "Grupp Padding",
  seriesPadding: "Serie Padding",
  tile: "Platta",
  whisker: "Visp",
  cap: "Topp",
  capLengthRatio: "L\xE4ngdf\xF6rh\xE5llande",
  labelPlacement: "Etikettplacering",
  inside: "Inuti",
  outside: "Utanf\xF6r",
  noDataToChart: "Ingen data tillg\xE4nglig f\xF6r diagram.",
  pivotChartRequiresPivotMode: "Pivotdiagram kr\xE4ver Piv\xE5l\xE4ge aktiverat.",
  chartSettingsToolbarTooltip: "Meny",
  chartLinkToolbarTooltip: "L\xE4nkad till Rutn\xE4t",
  chartUnlinkToolbarTooltip: "Olinkad fr\xE5n Rutn\xE4t",
  chartDownloadToolbarTooltip: "Ladda Ned Diagram",
  chartMenuToolbarTooltip: "Meny",
  chartEdit: "Redigera Diagram",
  chartAdvancedSettings: "Avancerade inst\xE4llningar",
  chartLink: "L\xE4nk till Rutn\xE4t",
  chartUnlink: "Avl\xE4nk fr\xE5n Rutn\xE4t",
  chartDownload: "Ladda Ned Diagram",
  histogramFrequency: "Frekvens",
  seriesChartType: "Seriediagram Typ",
  seriesType: "Serietyp",
  secondaryAxis: "Sekund\xE4r Axel",
  seriesAdd: "L\xE4gg till en serie",
  categoryAdd: "L\xE4gg till en kategori",
  bar: "Stapeldiagram",
  column: "Kolumn",
  histogram: "Histogram",
  advancedSettings: "Avancerade inst\xE4llningar",
  direction: "Riktning",
  horizontal: "Horisontell",
  vertical: "Vertikal",
  seriesGroupType: "Grupptyp",
  groupedSeriesGroupType: "Grupperad",
  stackedSeriesGroupType: "Staplad",
  normalizedSeriesGroupType: "100% Staplad",
  legendEnabled: "Aktiverad",
  invalidColor: "F\xE4rgkoden \xE4r ogiltig",
  groupedColumnFull: "Grupperad Kolumn",
  stackedColumnFull: "Staplad Kolumn",
  normalizedColumnFull: "100% Staplad Kolumn",
  groupedBarFull: "Grupperad Stapel",
  stackedBarFull: "Staplad Stapel",
  normalizedBarFull: "100% Staplad Stapel",
  stackedAreaFull: "Staplad Yta",
  normalizedAreaFull: "100% Staplad Yta",
  customCombo: "Anpassad Kombination",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Niv\xE5 ${variable}. Tryck ENTER f\xF6r att redigera.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Niv\xE5 ${variable}. ${variable} Tryck ENTER f\xF6r att redigera.",
  ariaAdvancedFilterBuilderList: "Avancerad filterbyggarlista",
  ariaAdvancedFilterBuilderFilterItem: "Filtervillkor",
  ariaAdvancedFilterBuilderGroupItem: "Filtergrupp",
  ariaAdvancedFilterBuilderColumn: "Kolumn",
  ariaAdvancedFilterBuilderOption: "Alternativ",
  ariaAdvancedFilterBuilderValueP: "V\xE4rde",
  ariaAdvancedFilterBuilderJoinOperator: "Kombinationsoperator",
  ariaAdvancedFilterInput: "Avancerad filterinmatning",
  ariaChecked: "markerad",
  ariaColumn: "Kolumn",
  ariaColumnGroup: "Kolumngrupp",
  ariaColumnFiltered: "Kolumn filtrerad",
  ariaColumnSelectAll: "V\xE4xla markering av alla kolumner",
  ariaDateFilterInput: "Datumfilterinmatning",
  ariaDefaultListName: "Lista",
  ariaFilterColumnsInput: "Filterkolumnsinmatning",
  ariaFilterFromValue: "Filtrera fr\xE5n v\xE4rde",
  ariaFilterInput: "Filterinmatning",
  ariaFilterList: "Filterlista",
  ariaFilterToValue: "Filtrera till v\xE4rde",
  ariaFilterValue: "Filterv\xE4rde",
  ariaFilterMenuOpen: "\xD6ppna filtermeny",
  ariaFilteringOperator: "Filtreringsoperator",
  ariaHidden: "dold",
  ariaIndeterminate: "obest\xE4md",
  ariaInputEditor: "Inmatningsredigerare",
  ariaMenuColumn: "Tryck ALT NED\xC5T f\xF6r att \xF6ppna kolumnmeny",
  ariaFilterColumn: "Tryck CTRL ENTER f\xF6r att \xF6ppna filter",
  ariaRowDeselect: "Tryck SPACE f\xF6r att avmarkera denna rad",
  ariaRowSelectAll: "Tryck SPACE f\xF6r att v\xE4xla markering av alla rader",
  ariaRowToggleSelection: "Tryck SPACE f\xF6r att v\xE4xla radmarkering",
  ariaRowSelect: "Tryck SPACE f\xF6r att markera denna rad",
  ariaRowSelectionDisabled: "Radmarkering \xE4r inaktiverad f\xF6r denna rad",
  ariaSearch: "S\xF6k",
  ariaSortableColumn: "Tryck ENTER f\xF6r att sortera",
  ariaToggleVisibility: "Tryck SPACE f\xF6r att v\xE4xla synlighet",
  ariaToggleCellValue: "Tryck SPACE f\xF6r att v\xE4xla cellv\xE4rde",
  ariaUnchecked: "omarkerad",
  ariaVisible: "synlig",
  ariaSearchFilterValues: "S\xF6k filterv\xE4rden",
  ariaPageSizeSelectorLabel: "Sidstorlek",
  ariaChartMenuClose: "St\xE4ng diagramredigeringsmeny",
  ariaChartSelected: "Vald",
  ariaSkeletonCellLoadingFailed: "Raden kunde inte laddas",
  ariaSkeletonCellLoading: "Raddata laddas",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Radgrupper",
  ariaValuesDropZonePanelLabel: "V\xE4rden",
  ariaPivotDropZonePanelLabel: "Kolumnetiketter",
  ariaDropZoneColumnComponentDescription: "Tryck p\xE5 DELETE f\xF6r att ta bort",
  ariaDropZoneColumnValueItemDescription: "Tryck p\xE5 ENTER f\xF6r att \xE4ndra aggregeringstyp",
  ariaDropZoneColumnGroupItemDescription: "Tryck p\xE5 ENTER f\xF6r att sortera",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " av ",
  ariaDropZoneColumnComponentSortAscending: "stigande",
  ariaDropZoneColumnComponentSortDescending: "fallande",
  ariaLabelDialog: "Dialogruta",
  ariaLabelColumnMenu: "Kolumnmeny",
  ariaLabelColumnFilter: "Kolumnfilter",
  ariaLabelCellEditor: "Celleditor",
  ariaLabelSelectField: "V\xE4lj f\xE4lt",
  // aria labels for rich select
  ariaLabelRichSelectField: "Rich Selectf\xE4lt",
  ariaLabelRichSelectToggleSelection: "Tryck p\xE5 MELLANSLAG f\xF6r att v\xE4xla markering",
  ariaLabelRichSelectDeselectAllItems: "Tryck p\xE5 DELETE f\xF6r att avmarkera alla objekt",
  ariaLabelRichSelectDeleteSelection: "Tryck p\xE5 DELETE f\xF6r att avmarkera objekt",
  ariaLabelTooltip: "Verktygstips",
  ariaLabelContextMenu: "Kontekstmeny",
  ariaLabelSubMenu: "Undermeny",
  ariaLabelAggregationFunction: "Aggregeringsfunktion",
  ariaLabelAdvancedFilterAutocomplete: "Avancerad Filter Autocomplete",
  ariaLabelAdvancedFilterBuilderAddField: "Avancerad Filter Builder L\xE4gg till f\xE4lt",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Avancerad Filter Builder Kolumn V\xE4lj f\xE4lt",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Avancerad Filter Builder Alternativ V\xE4lj f\xE4lt",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Avancerad Filter Builder Join Operator V\xE4lj f\xE4lt",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Kolumnlista",
  ariaFilterPanelList: "Filterlista",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "Sann",
  false: "Falsk",
  invalidDate: "Ogiltigt datum",
  invalidNumber: "Ogiltigt nummer",
  january: "Januari",
  february: "Februari",
  march: "Mars",
  april: "April",
  may: "Maj",
  june: "Juni",
  july: "Juli",
  august: "Augusti",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "December",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS FM/EM"
};

// community-modules/locale/src/tr-TR.ts
var AG_GRID_LOCALE_TR = {
  // Set Filter
  selectAll: "(T\xFCm\xFCn\xFC Se\xE7)",
  selectAllSearchResults: "(T\xFCm Arama Sonu\xE7lar\u0131n\u0131 Se\xE7)",
  addCurrentSelectionToFilter: "Mevcut se\xE7imi filtreye ekle",
  searchOoo: "Ara...",
  blanks: "(Bo\u015F)",
  noMatches: "E\u015Fle\u015Fme yok",
  // Number Filter & Text Filter
  filterOoo: "Filtre...",
  equals: "E\u015Fittir",
  notEqual: "E\u015Fit de\u011Fil",
  blank: "Bo\u015F",
  notBlank: "Bo\u015F de\u011Fil",
  empty: "Birini se\xE7in",
  // Number Filter
  lessThan: "Daha az",
  greaterThan: "Daha fazla",
  lessThanOrEqual: "K\xFC\xE7\xFCk veya e\u015Fit",
  greaterThanOrEqual: "B\xFCy\xFCk veya e\u015Fit",
  inRange: "Aras\u0131nda",
  inRangeStart: "Ba\u015Flang\u0131\xE7",
  inRangeEnd: "Biti\u015F",
  // Text Filter
  contains: "\u0130\xE7erir",
  notContains: "\u0130\xE7ermez",
  startsWith: "\u0130le ba\u015Flar",
  endsWith: "\u0130le biter",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\xD6nce",
  after: "Sonra",
  // Filter Conditions
  andCondition: "VE",
  orCondition: "VEYA",
  // Filter Buttons
  applyFilter: "Uygula",
  resetFilter: "S\u0131f\u0131rla",
  clearFilter: "Temizle",
  cancelFilter: "\u0130ptal",
  // Filter Titles
  textFilter: "Metin Filtresi",
  numberFilter: "Say\u0131 Filtresi",
  dateFilter: "Tarih Filtresi",
  setFilter: "Ayarlama Filtresi",
  // Group Column Filter
  groupFilterSelect: "Alan se\xE7iniz:",
  // Advanced Filter
  advancedFilterContains: "i\xE7erir",
  advancedFilterNotContains: "i\xE7ermez",
  advancedFilterTextEquals: "e\u015Fittir",
  advancedFilterTextNotEqual: "e\u015Fit de\u011Fildir",
  advancedFilterStartsWith: "ile ba\u015Flar",
  advancedFilterEndsWith: "ile biter",
  advancedFilterBlank: "bo\u015F",
  advancedFilterNotBlank: "bo\u015F de\u011Fil",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "do\u011Fru",
  advancedFilterFalse: "yanl\u0131\u015F",
  advancedFilterAnd: "VE",
  advancedFilterOr: "VEYA",
  advancedFilterApply: "Uygula",
  advancedFilterBuilder: "Olu\u015Fturucu",
  advancedFilterValidationMissingColumn: "S\xFCtun eksik",
  advancedFilterValidationMissingOption: "Se\xE7enek eksik",
  advancedFilterValidationMissingValue: "De\u011Fer eksik",
  advancedFilterValidationInvalidColumn: "S\xFCtun bulunamad\u0131",
  advancedFilterValidationInvalidOption: "Se\xE7enek bulunamad\u0131",
  advancedFilterValidationMissingQuote: "De\u011Ferin kapan\u0131\u015F t\u0131rna\u011F\u0131 eksik",
  advancedFilterValidationNotANumber: "De\u011Fer bir say\u0131 de\u011Fil",
  advancedFilterValidationInvalidDate: "De\u011Fer ge\xE7erli bir tarih de\u011Fil",
  advancedFilterValidationMissingCondition: "Ko\u015Ful eksik",
  advancedFilterValidationJoinOperatorMismatch: "Bir ko\u015Ful i\xE7indeki birle\u015Ftirici operat\xF6rlerin ayn\u0131 olmas\u0131 gerekir",
  advancedFilterValidationInvalidJoinOperator: "Birle\u015Ftirici operat\xF6r bulunamad\u0131",
  advancedFilterValidationMissingEndBracket: "Kapan\u0131\u015F parantezi eksik",
  advancedFilterValidationExtraEndBracket: "Fazla kapan\u0131\u015F parantezi",
  advancedFilterValidationMessage: "\u0130fade bir hata i\xE7eriyor. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0130fade bir hata i\xE7eriyor. ${variable} ifadenin sonunda.",
  advancedFilterBuilderTitle: "Geli\u015Fmi\u015F Filtre",
  advancedFilterBuilderApply: "Uygula",
  advancedFilterBuilderCancel: "\u0130ptal",
  advancedFilterBuilderAddButtonTooltip: "Filtre veya Grup Ekle",
  advancedFilterBuilderRemoveButtonTooltip: "Kald\u0131r",
  advancedFilterBuilderMoveUpButtonTooltip: "Yukar\u0131 Ta\u015F\u0131",
  advancedFilterBuilderMoveDownButtonTooltip: "A\u015Fa\u011F\u0131 Ta\u015F\u0131",
  advancedFilterBuilderAddJoin: "Grup Ekle",
  advancedFilterBuilderAddCondition: "Filtre Ekle",
  advancedFilterBuilderSelectColumn: "Bir s\xFCtun se\xE7in",
  advancedFilterBuilderSelectOption: "Bir se\xE7enek se\xE7in",
  advancedFilterBuilderEnterValue: "Bir de\u011Fer girin...",
  advancedFilterBuilderValidationAlreadyApplied: "Mevcut filtre zaten uygulanm\u0131\u015F.",
  advancedFilterBuilderValidationIncomplete: "T\xFCm ko\u015Fullar tamamlanmam\u0131\u015F.",
  advancedFilterBuilderValidationSelectColumn: "Bir s\xFCtun se\xE7melisiniz.",
  advancedFilterBuilderValidationSelectOption: "Bir se\xE7enek se\xE7melisiniz.",
  advancedFilterBuilderValidationEnterValue: "Bir de\u011Fer girmelisiniz.",
  // Side Bar
  columns: "S\xFCtunlar",
  filters: "Filtreler",
  // columns tool panel
  pivotMode: "Pivot Modu",
  groups: "Sat\u0131r Gruplar\u0131",
  rowGroupColumnsEmptyMessage: "Sat\u0131r gruplar\u0131n\u0131 ayarlamak i\xE7in buraya s\xFCr\xFCkleyin",
  values: "De\u011Ferler",
  valueColumnsEmptyMessage: "Toplamak i\xE7in buraya s\xFCr\xFCkleyin",
  pivots: "S\xFCtun Etiketleri",
  pivotColumnsEmptyMessage: "S\xFCtun etiketlerini ayarlamak i\xE7in buraya s\xFCr\xFCkleyin",
  // Header of the Default Group Column
  group: "Grup",
  // Row Drag
  rowDragRow: "sat\u0131r",
  rowDragRows: "sat\u0131rlar",
  // Other
  loadingOoo: "Y\xFCkleniyor...",
  loadingError: "HATA",
  noRowsToShow: "G\xF6sterilecek Sat\u0131r Yok",
  enabled: "Etkin",
  // Menu
  pinColumn: "S\xFCtunu Sabitle",
  pinLeft: "Sola Sabitle",
  pinRight: "Sa\u011Fa Sabitle",
  noPin: "Sabitleme Yok",
  valueAggregation: "De\u011Fer Toplama",
  noAggregation: "Hi\xE7biri",
  autosizeThisColumn: "Bu S\xFCtunu Otomatik Boyutland\u0131r",
  autosizeAllColumns: "T\xFCm S\xFCtunlar\u0131 Otomatik Boyutland\u0131r",
  groupBy: "Grupla",
  ungroupBy: "Grubu \xC7\u0131kar",
  ungroupAll: "T\xFCm Gruplar\u0131 \xC7\u0131kar",
  addToValues: "${variable} de\u011Ferlerine ekle",
  removeFromValues: "${variable} de\u011Ferlerinden \xE7\u0131kar",
  addToLabels: "${variable} etiketlerine ekle",
  removeFromLabels: "${variable} etiketlerinden \xE7\u0131kar",
  resetColumns: "S\xFCtunlar\u0131 S\u0131f\u0131rla",
  expandAll: "T\xFCm Sat\u0131r Gruplar\u0131n\u0131 Geni\u015Flet",
  collapseAll: "T\xFCm Sat\u0131r Gruplar\u0131n\u0131 Daralt",
  copy: "Kopyala",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Ba\u015Fl\u0131klarla Kopyala",
  copyWithGroupHeaders: "Grup Ba\u015Fl\u0131klar\u0131yla Kopyala",
  cut: "Kes",
  paste: "Yap\u0131\u015Ft\u0131r",
  ctrlV: "Ctrl+V",
  export: "D\u0131\u015Fa Aktar",
  csvExport: "CSV Olarak D\u0131\u015Fa Aktar",
  excelExport: "Excel Olarak D\u0131\u015Fa Aktar",
  columnFilter: "S\xFCtun Filtresi",
  columnChooser: "S\xFCtunlar\u0131 Se\xE7",
  chooseColumns: "S\xFCtunlar\u0131 Se\xE7",
  sortAscending: "Artan S\u0131ral\u0131",
  sortDescending: "Azalan S\u0131ral\u0131",
  sortUnSort: "S\u0131ralamay\u0131 Temizle",
  // Enterprise Menu Aggregation and Status Bar
  sum: "Toplam",
  first: "\u0130lk",
  last: "Son",
  min: "Min",
  max: "Maks",
  none: "Yok",
  count: "Say\u0131m",
  avg: "Ortalama",
  filteredRows: "Filtrelenmi\u015F",
  selectedRows: "Se\xE7ili",
  totalRows: "Toplam Sat\u0131rlar",
  totalAndFilteredRows: "Sat\u0131rlar",
  more: "Daha fazla",
  to: "ile",
  of: "\xFCzerinden",
  page: "Sayfa",
  pageLastRowUnknown: "?",
  nextPage: "Sonraki Sayfa",
  lastPage: "Son Sayfa",
  firstPage: "\u0130lk Sayfa",
  previousPage: "\xD6nceki Sayfa",
  pageSizeSelectorLabel: "Sayfa Boyutu:",
  footerTotal: "Toplam",
  // Pivoting
  pivotColumnGroupTotals: "Toplam",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Grafi\u011Fi ve Pivot Modu",
  pivotChart: "Pivot Grafi\u011Fi",
  chartRange: "Grafik Aral\u0131\u011F\u0131",
  columnChart: "S\xFCtun",
  groupedColumn: "Gruplanm\u0131\u015F",
  stackedColumn: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedColumn: "%100 Y\u0131\u011F\u0131lm\u0131\u015F",
  barChart: "\xC7ubuk",
  groupedBar: "Gruplanm\u0131\u015F",
  stackedBar: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedBar: "%100 Y\u0131\u011F\u0131lm\u0131\u015F",
  pieChart: "Pasta",
  pie: "Pasta",
  donut: "Halka",
  line: "\xC7izgi",
  xyChart: "X Y (Serpme)",
  scatter: "Serpme",
  bubble: "Baloncuk",
  areaChart: "Alan",
  area: "Alan",
  stackedArea: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedArea: "%100 Y\u0131\u011F\u0131lm\u0131\u015F",
  histogramChart: "Histogram",
  polarChart: "Polar",
  radarLine: "Radar \xC7izgisi",
  radarArea: "Radar Alan\u0131",
  nightingale: "Nightingale",
  radialColumn: "Radyal S\xFCtun",
  radialBar: "Radyal \xC7ubuk",
  statisticalChart: "\u0130statistiksel",
  boxPlot: "Kutu Grafi\u011Fi",
  rangeBar: "Aral\u0131k \xC7ubu\u011Fu",
  rangeArea: "Aral\u0131k Alan\u0131",
  hierarchicalChart: "Hiyerar\u015Fik",
  treemap: "A\u011Fa\xE7 Haritas\u0131",
  sunburst: "G\xFCne\u015F\u0131\u015F\u0131\u011F\u0131",
  specializedChart: "\xD6zel",
  waterfall: "\u015Eelale",
  heatmap: "Is\u0131 Haritas\u0131",
  combinationChart: "Kombinasyon",
  columnLineCombo: "S\xFCtun ve \xC7izgi",
  AreaColumnCombo: "Alan ve S\xFCtun",
  // Charts
  pivotChartTitle: "\xD6zet Grafik",
  rangeChartTitle: "Aral\u0131k Grafi\u011Fi",
  settings: "Grafik",
  data: "Kurulum",
  format: "\xD6zelle\u015Ftir",
  categories: "Kategoriler",
  defaultCategory: "(Hi\xE7biri)",
  series: "Seriler",
  switchCategorySeries: "Kategori/Seri De\u011Fi\u015Ftir",
  categoryValues: "Kategori De\u011Ferleri",
  seriesLabels: "Seri Etiketleri",
  aggregate: "Topla",
  xyValues: "X Y De\u011Ferleri",
  paired: "E\u015Fle\u015Ftirilmi\u015F Mod",
  axis: "Eksen",
  xAxis: "Yatay Eksen",
  yAxis: "Dikey Eksen",
  polarAxis: "Kutup Ekseni",
  radiusAxis: "Yar\u0131\xE7ap Ekseni",
  navigator: "Gezgin",
  zoom: "Yak\u0131nla\u015Ft\u0131r",
  animation: "Animasyon",
  crosshair: "Kesi\u015Fme Noktas\u0131",
  color: "Renk",
  thickness: "Kal\u0131nl\u0131k",
  preferredLength: "Tercih Edilen Uzunluk",
  xType: "X Tipi",
  axisType: "Eksen Tipi",
  automatic: "Otomatik",
  category: "Kategori",
  number: "Say\u0131",
  time: "Zaman",
  timeFormat: "Zaman Format\u0131",
  autoRotate: "Otomatik D\xF6nd\xFCr",
  labelRotation: "D\xF6nd\xFCrme",
  circle: "Daire",
  polygon: "\xC7okgen",
  square: "Kare",
  cross: "\xC7arp\u0131",
  diamond: "Elmas",
  plus: "Art\u0131",
  triangle: "\xDC\xE7gen",
  heart: "Kalp",
  orientation: "Y\xF6n",
  fixed: "Sabit",
  parallel: "Paralel",
  perpendicular: "Dik",
  radiusAxisPosition: "Pozisyon",
  ticks: "Kene",
  gridLines: "Izgara \xC7izgileri",
  width: "Geni\u015Flik",
  height: "Y\xFCkseklik",
  length: "Uzunluk",
  padding: "Dolgu",
  spacing: "Aral\u0131k",
  chartStyle: "Grafik Stili",
  title: "Ba\u015Fl\u0131k",
  chartTitles: "Ba\u015Fl\u0131klar",
  chartTitle: "Grafik Ba\u015Fl\u0131\u011F\u0131",
  chartSubtitle: "Alt Ba\u015Fl\u0131k",
  horizontalAxisTitle: "Yatay Eksen Ba\u015Fl\u0131\u011F\u0131",
  verticalAxisTitle: "Dikey Eksen Ba\u015Fl\u0131\u011F\u0131",
  polarAxisTitle: "Kutup Ekseni Ba\u015Fl\u0131\u011F\u0131",
  titlePlaceholder: "Grafik Ba\u015Fl\u0131\u011F\u0131",
  background: "Arka Plan",
  font: "Yaz\u0131 Tipi",
  weight: "A\u011F\u0131rl\u0131k",
  top: "\xDCst",
  right: "Sa\u011F",
  bottom: "Alt",
  left: "Sol",
  labels: "Etiketler",
  calloutLabels: "\xC7a\u011Fr\u0131 Etiketleri",
  sectorLabels: "Sekt\xF6r Etiketleri",
  positionRatio: "Pozisyon Oran\u0131",
  size: "Boyut",
  shape: "\u015Eekil",
  minSize: "Minimum Boyut",
  maxSize: "Maksimum Boyut",
  legend: "A\xE7\u0131klama",
  position: "Pozisyon",
  markerSize: "\u0130\u015Faret\xE7i Boyutu",
  markerStroke: "\u0130\u015Faret\xE7i \xC7izgisi",
  markerPadding: "\u0130\u015Faret\xE7i Dolgusu",
  itemSpacing: "\xD6\u011Fe Aral\u0131\u011F\u0131",
  itemPaddingX: "\xD6\u011Fe Yatay Doldurma",
  itemPaddingY: "\xD6\u011Fe Dikey Doldurma",
  layoutHorizontalSpacing: "Yatay Aral\u0131k",
  layoutVerticalSpacing: "Dikey Aral\u0131k",
  strokeWidth: "\xC7izgi Kal\u0131nl\u0131\u011F\u0131",
  offset: "Sapma",
  offsets: "Sapmalar",
  tooltips: "Ara\xE7 \u0130pu\xE7lar\u0131",
  callout: "\xC7a\u011Fr\u0131",
  markers: "\u0130\u015Faret\xE7iler",
  shadow: "G\xF6lge",
  blur: "Bulan\u0131kl\u0131k",
  xOffset: "X Sapma",
  yOffset: "Y Sapma",
  lineWidth: "\xC7izgi Geni\u015Fli\u011Fi",
  lineDash: "\xC7izgi Kesikli",
  lineDashOffset: "Kesik Sapma",
  scrollingZoom: "Kayd\u0131rma",
  scrollingStep: "Kayd\u0131rma Ad\u0131m\u0131",
  selectingZoom: "Se\xE7im",
  durationMillis: "S\xFCre (ms)",
  crosshairLabel: "Etiket",
  crosshairSnap: "D\xFC\u011F\xFCm Noktas\u0131na Yakla\u015F",
  normal: "Normal",
  bold: "Kal\u0131n",
  italic: "\u0130talik",
  boldItalic: "Kal\u0131n \u0130talik",
  predefined: "\xD6nceden Tan\u0131mlanm\u0131\u015F",
  fillOpacity: "Doldurma Opakl\u0131\u011F\u0131",
  strokeColor: "\xC7izgi Rengi",
  strokeOpacity: "\xC7izgi Opakl\u0131\u011F\u0131",
  miniChart: "Mini Grafik",
  histogramBinCount: "Bin say\u0131s\u0131",
  connectorLine: "Ba\u011Flay\u0131c\u0131 \xC7izgi",
  seriesItems: "Seri \xD6\u011Feleri",
  seriesItemType: "\xD6\u011Fe Tipi",
  seriesItemPositive: "Pozitif",
  seriesItemNegative: "Negatif",
  seriesItemLabels: "\xD6\u011Fe Etiketleri",
  columnGroup: "S\xFCtun",
  barGroup: "\xC7ubuk",
  pieGroup: "Pasta",
  lineGroup: "\xC7izgi",
  scatterGroup: "X Y (Da\u011F\u0131l\u0131m)",
  areaGroup: "Alan",
  polarGroup: "Kutup",
  statisticalGroup: "\u0130statistiksel",
  hierarchicalGroup: "Hiyerar\u015Fik",
  specializedGroup: "Uzmanla\u015Fm\u0131\u015F",
  combinationGroup: "Kombinasyon",
  groupedColumnTooltip: "Grupland\u0131r\u0131lm\u0131\u015F",
  stackedColumnTooltip: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedColumnTooltip: "100% Y\u0131\u011F\u0131lm\u0131\u015F",
  groupedBarTooltip: "Grupland\u0131r\u0131lm\u0131\u015F",
  stackedBarTooltip: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedBarTooltip: "100% Y\u0131\u011F\u0131lm\u0131\u015F",
  pieTooltip: "Pasta",
  donutTooltip: "Donut",
  lineTooltip: "\xC7izgi",
  groupedAreaTooltip: "Alan",
  stackedAreaTooltip: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedAreaTooltip: "100% Y\u0131\u011F\u0131lm\u0131\u015F",
  scatterTooltip: "Da\u011F\u0131l\u0131m",
  bubbleTooltip: "Balon",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radyal S\xFCtun",
  radialBarTooltip: "Radyal \xC7ubuk",
  radarLineTooltip: "Radar \xC7izgisi",
  radarAreaTooltip: "Radar Alan\u0131",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Aral\u0131k \xC7ubu\u011Fu",
  rangeAreaTooltip: "Aral\u0131k Alan\u0131",
  boxPlotTooltip: "Kutu Grafi\u011Fi",
  treemapTooltip: "A\u011Fa\xE7 Haritas\u0131",
  sunburstTooltip: "Patlama",
  waterfallTooltip: "\u015Eelale",
  heatmapTooltip: "Is\u0131 Haritas\u0131",
  columnLineComboTooltip: "S\xFCtun & \xC7izgi",
  areaColumnComboTooltip: "Alan & S\xFCtun",
  customComboTooltip: "\xD6zel Kombinasyon",
  innerRadius: "\u0130\xE7 Yar\u0131\xE7ap",
  startAngle: "Ba\u015Flang\u0131\xE7 A\xE7\u0131s\u0131",
  endAngle: "Biti\u015F A\xE7\u0131s\u0131",
  reverseDirection: "Y\xF6n\xFC Ters \xC7evir",
  groupPadding: "Grup Doldurma",
  seriesPadding: "Seri Doldurma",
  tile: "D\xF6\u015Feme",
  whisker: "B\u0131y\u0131k",
  cap: "\u015Eapka",
  capLengthRatio: "Uzunluk Oran\u0131",
  labelPlacement: "Etiket Yerle\u015Fimi",
  inside: "\u0130\xE7inde",
  outside: "D\u0131\u015F\u0131nda",
  noDataToChart: "Grafik i\xE7in veri yok.",
  pivotChartRequiresPivotMode: "\xD6zet Grafik, Pivot Modun etkin olmas\u0131n\u0131 gerektirir.",
  chartSettingsToolbarTooltip: "Men\xFC",
  chartLinkToolbarTooltip: "Grida Ba\u011Fl\u0131",
  chartUnlinkToolbarTooltip: "Gridden Ayr\u0131k",
  chartDownloadToolbarTooltip: "Grafi\u011Fi \u0130ndir",
  chartMenuToolbarTooltip: "Men\xFC",
  chartEdit: "Grafi\u011Fi D\xFCzenle",
  chartAdvancedSettings: "Geli\u015Fmi\u015F Ayarlar",
  chartLink: "Grida Ba\u011Fla",
  chartUnlink: "Gridden Ay\u0131r",
  chartDownload: "Grafi\u011Fi \u0130ndir",
  histogramFrequency: "Frekans",
  seriesChartType: "Seri Grafik Tipi",
  seriesType: "Seri Tipi",
  secondaryAxis: "\u0130kincil Eksen",
  seriesAdd: "Bir seri ekle",
  categoryAdd: "Bir kategori ekle",
  bar: "\xC7ubuk",
  column: "S\xFCtun",
  histogram: "Histogram",
  advancedSettings: "Geli\u015Fmi\u015F Ayarlar",
  direction: "Y\xF6n",
  horizontal: "Yatay",
  vertical: "Dikey",
  seriesGroupType: "Grup Tipi",
  groupedSeriesGroupType: "Grupland\u0131r\u0131lm\u0131\u015F",
  stackedSeriesGroupType: "Y\u0131\u011F\u0131lm\u0131\u015F",
  normalizedSeriesGroupType: "100% Y\u0131\u011F\u0131lm\u0131\u015F",
  legendEnabled: "Etkin",
  invalidColor: "Renk de\u011Feri ge\xE7ersiz",
  groupedColumnFull: "Grupland\u0131r\u0131lm\u0131\u015F S\xFCtun",
  stackedColumnFull: "Y\u0131\u011F\u0131lm\u0131\u015F S\xFCtun",
  normalizedColumnFull: "100% Y\u0131\u011F\u0131lm\u0131\u015F S\xFCtun",
  groupedBarFull: "Grupland\u0131r\u0131lm\u0131\u015F \xC7ubuk",
  stackedBarFull: "Y\u0131\u011F\u0131lm\u0131\u015F \xC7ubuk",
  normalizedBarFull: "100% Y\u0131\u011F\u0131lm\u0131\u015F \xC7ubuk",
  stackedAreaFull: "Y\u0131\u011F\u0131lm\u0131\u015F Alan",
  normalizedAreaFull: "100% Y\u0131\u011F\u0131lm\u0131\u015F Alan",
  customCombo: "\xD6zel Kombinasyon",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. Seviye ${variable}. D\xFCzenlemek i\xE7in ENTER tu\u015Funa bas\u0131n",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. Seviye ${variable}. ${variable} D\xFCzenlemek i\xE7in ENTER tu\u015Funa bas\u0131n.",
  ariaAdvancedFilterBuilderList: "Geli\u015Fmi\u015F Filtre Olu\u015Fturucu Listesi",
  ariaAdvancedFilterBuilderFilterItem: "Filtre Ko\u015Fulu",
  ariaAdvancedFilterBuilderGroupItem: "Filtre Grubu",
  ariaAdvancedFilterBuilderColumn: "S\xFCtun",
  ariaAdvancedFilterBuilderOption: "Se\xE7enek",
  ariaAdvancedFilterBuilderValueP: "De\u011Fer",
  ariaAdvancedFilterBuilderJoinOperator: "Ba\u011Flant\u0131 Operat\xF6r\xFC",
  ariaAdvancedFilterInput: "Geli\u015Fmi\u015F Filtre Giri\u015Fi",
  ariaChecked: "i\u015Faretli",
  ariaColumn: "S\xFCtun",
  ariaColumnGroup: "S\xFCtun Grubu",
  ariaColumnFiltered: "S\xFCtun Filtrelendi",
  ariaColumnSelectAll: "T\xFCm S\xFCtunlar\u0131 Se\xE7meyi De\u011Fi\u015Ftir",
  ariaDateFilterInput: "Tarih Filtre Giri\u015Fi",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "S\xFCtunlar\u0131 Filtrele Giri\u015Fi",
  ariaFilterFromValue: "De\u011Ferden filtrele",
  ariaFilterInput: "Filtre Giri\u015Fi",
  ariaFilterList: "Filtre Listesi",
  ariaFilterToValue: "De\u011Fere filtrele",
  ariaFilterValue: "Filtre De\u011Feri",
  ariaFilterMenuOpen: "Filtre Men\xFCs\xFCn\xFC A\xE7",
  ariaFilteringOperator: "Filtreleme Operat\xF6r\xFC",
  ariaHidden: "gizli",
  ariaIndeterminate: "belirsiz",
  ariaInputEditor: "Giri\u015F D\xFCzenleyicisi",
  ariaMenuColumn: "S\xFCtun men\xFCs\xFCn\xFC a\xE7mak i\xE7in ALT A\u015EA\u011EI tu\u015Flar\u0131na bas\u0131n",
  ariaFilterColumn: "Filtreyi a\xE7mak i\xE7in CTRL ENTER tu\u015Flar\u0131na bas\u0131n",
  ariaRowDeselect: "Bu sat\u0131r\u0131n se\xE7imini kald\u0131rmak i\xE7in SPACE tu\u015Funa bas\u0131n",
  ariaRowSelectAll: "T\xFCm sat\u0131rlar\u0131n se\xE7imini de\u011Fi\u015Ftirmek i\xE7in Bo\u015Fluk tu\u015Funa bas\u0131n",
  ariaRowToggleSelection: "Sat\u0131r se\xE7imini de\u011Fi\u015Ftirmek i\xE7in Bo\u015Fluk tu\u015Funa bas\u0131n",
  ariaRowSelect: "Bu sat\u0131r\u0131 se\xE7mek i\xE7in SPACE tu\u015Funa bas\u0131n",
  ariaRowSelectionDisabled: "Bu sat\u0131r i\xE7in Sat\u0131r Se\xE7imi devre d\u0131\u015F\u0131 b\u0131rak\u0131lm\u0131\u015Ft\u0131r",
  ariaSearch: "Ara",
  ariaSortableColumn: "S\u0131ralamak i\xE7in ENTER tu\u015Funa bas\u0131n",
  ariaToggleVisibility: "G\xF6r\xFCn\xFCrl\xFC\u011F\xFC de\u011Fi\u015Ftirmek i\xE7in SPACE tu\u015Funa bas\u0131n",
  ariaToggleCellValue: "H\xFCcre de\u011Ferini de\u011Fi\u015Ftirmek i\xE7in SPACE tu\u015Funa bas\u0131n",
  ariaUnchecked: "i\u015Faretli de\u011Fil",
  ariaVisible: "g\xF6r\xFCn\xFCr",
  ariaSearchFilterValues: "Filtre de\u011Ferlerini ara",
  ariaPageSizeSelectorLabel: "Sayfa Boyutu",
  ariaChartMenuClose: "Grafik D\xFCzenleme Men\xFCs\xFCn\xFC Kapat",
  ariaChartSelected: "Se\xE7ildi",
  ariaSkeletonCellLoadingFailed: "Sat\u0131r y\xFCklenemedi",
  ariaSkeletonCellLoading: "Sat\u0131r verisi y\xFCkleniyor",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Sat\u0131r Gruplar\u0131",
  ariaValuesDropZonePanelLabel: "De\u011Ferler",
  ariaPivotDropZonePanelLabel: "S\xFCtun Etiketleri",
  ariaDropZoneColumnComponentDescription: "Kald\u0131rmak i\xE7in DELETE tu\u015Funa bas\u0131n",
  ariaDropZoneColumnValueItemDescription: "Toplama t\xFCr\xFCn\xFC de\u011Fi\u015Ftirmek i\xE7in ENTER tu\u015Funa bas\u0131n",
  ariaDropZoneColumnGroupItemDescription: "S\u0131ralamak i\xE7in ENTER tu\u015Funa bas\u0131n",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " of ",
  ariaDropZoneColumnComponentSortAscending: "artan",
  ariaDropZoneColumnComponentSortDescending: "azalan",
  ariaLabelDialog: "Diyalog",
  ariaLabelColumnMenu: "S\xFCtun Men\xFCs\xFC",
  ariaLabelColumnFilter: "S\xFCtun Filtresi",
  ariaLabelCellEditor: "H\xFCcre D\xFCzenleyici",
  ariaLabelSelectField: "Alan Se\xE7",
  // aria labels for rich select
  ariaLabelRichSelectField: "Zengin Se\xE7im Alan\u0131",
  ariaLabelRichSelectToggleSelection: "Se\xE7imi de\u011Fi\u015Ftirmek i\xE7in BO\u015ELUK tu\u015Funa bas\u0131n",
  ariaLabelRichSelectDeselectAllItems: "T\xFCm \xF6\u011Felerin se\xE7imini kald\u0131rmak i\xE7in S\u0130L tu\u015Funa bas\u0131n",
  ariaLabelRichSelectDeleteSelection: "\xD6\u011Fenin se\xE7imini kald\u0131rmak i\xE7in S\u0130L tu\u015Funa bas\u0131n",
  ariaLabelTooltip: "\u0130pucu",
  ariaLabelContextMenu: "Ba\u011Flam Men\xFCs\xFC",
  ariaLabelSubMenu: "Alt Men\xFC",
  ariaLabelAggregationFunction: "Toplama Fonksiyonu",
  ariaLabelAdvancedFilterAutocomplete: "Geli\u015Fmi\u015F Filtre Otomatik Tamamlama",
  ariaLabelAdvancedFilterBuilderAddField: "Geli\u015Fmi\u015F Filtre Olu\u015Fturucu Alan Ekle",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "Geli\u015Fmi\u015F Filtre Olu\u015Fturucu S\xFCtun Se\xE7im Alan\u0131",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "Geli\u015Fmi\u015F Filtre Olu\u015Fturucu Se\xE7enek Se\xE7im Alan\u0131",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "Geli\u015Fmi\u015F Filtre Olu\u015Fturucu Birle\u015Ftirme Operat\xF6r\xFC Se\xE7im Alan\u0131",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Kolon Listesi",
  ariaFilterPanelList: "Filtre Listesi",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ".",
  decimalSeparator: ",",
  // Data types
  true: "Do\u011Fru",
  false: "Yanl\u0131\u015F",
  invalidDate: "Ge\xE7ersiz Tarih",
  invalidNumber: "Ge\xE7ersiz Say\u0131",
  january: "Ocak",
  february: "\u015Eubat",
  march: "Mart",
  april: "Nisan",
  may: "May\u0131s",
  june: "Haziran",
  july: "Temmuz",
  august: "A\u011Fustos",
  september: "Eyl\xFCl",
  october: "Ekim",
  november: "Kas\u0131m",
  december: "Aral\u0131k",
  // Time formats
  timeFormatSlashesDDMMYYYY: "GG/AA/YYYY",
  timeFormatSlashesMMDDYYYY: "AA/GG/YYYY",
  timeFormatSlashesDDMMYY: "GG/AA/YY",
  timeFormatSlashesMMDDYY: "AA/GG/YY",
  timeFormatDotsDDMYY: "GG.A.YY",
  timeFormatDotsMDDYY: "A.GG.YY",
  timeFormatDashesYYYYMMDD: "YYYY-AA-GG",
  timeFormatSpacesDDMMMMYYYY: "GG AAAA YYYY",
  timeFormatHHMMSS: "SS:DD:SS",
  timeFormatHHMMSSAmPm: "SS:DD:SS \xD6\xD6/\xD6S"
};

// community-modules/locale/src/uk-UA.ts
var AG_GRID_LOCALE_UA = {
  // Set Filter
  selectAll: "(\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u0432\u0441\u0435)",
  selectAllSearchResults: "(\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u0432\u0441\u0456 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u0438 \u043F\u043E\u0448\u0443\u043A\u0443)",
  addCurrentSelectionToFilter: "\u0414\u043E\u0434\u0430\u0442\u0438 \u043F\u043E\u0442\u043E\u0447\u043D\u0438\u0439 \u0432\u0438\u0431\u0456\u0440 \u0434\u043E \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  searchOoo: "\u041F\u043E\u0448\u0443\u043A...",
  blanks: "(\u041F\u0443\u0441\u0442\u0456)",
  noMatches: "\u0421\u043F\u0456\u0432\u043F\u0430\u0434\u0456\u043D\u044C \u043D\u0435 \u0437\u043D\u0430\u0439\u0434\u0435\u043D\u043E",
  // Number Filter & Text Filter
  filterOoo: "\u0424\u0456\u043B\u044C\u0442\u0440...",
  equals: "\u0414\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  notEqual: "\u041D\u0435 \u0434\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  blank: "\u041F\u043E\u0440\u043E\u0436\u043D\u0456\u0439",
  notBlank: "\u041D\u0435 \u043F\u043E\u0440\u043E\u0436\u043D\u0456\u0439",
  empty: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u043E\u0434\u0438\u043D",
  // Number Filter
  lessThan: "\u041C\u0435\u043D\u0448\u0435 \u043D\u0456\u0436",
  greaterThan: "\u0411\u0456\u043B\u044C\u0448\u0435 \u043D\u0456\u0436",
  lessThanOrEqual: "\u041C\u0435\u043D\u0448\u0435 \u0430\u0431\u043E \u0434\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  greaterThanOrEqual: "\u0411\u0456\u043B\u044C\u0448\u0435 \u0430\u0431\u043E \u0434\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  inRange: "\u041C\u0456\u0436",
  inRangeStart: "\u0412\u0456\u0434",
  inRangeEnd: "\u0414\u043E",
  // Text Filter
  contains: "\u041C\u0456\u0441\u0442\u0438\u0442\u044C",
  notContains: "\u041D\u0435 \u043C\u0456\u0441\u0442\u0438\u0442\u044C",
  startsWith: "\u041F\u043E\u0447\u0438\u043D\u0430\u0454\u0442\u044C\u0441\u044F \u0437",
  endsWith: "\u0417\u0430\u043A\u0456\u043D\u0447\u0443\u0454\u0442\u044C\u0441\u044F \u043D\u0430",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u0414\u043E",
  after: "\u041F\u0456\u0441\u043B\u044F",
  // Filter Conditions
  andCondition: "\u0406",
  orCondition: "\u0410\u0411\u041E",
  // Filter Buttons
  applyFilter: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  resetFilter: "\u0421\u043A\u0438\u043D\u0443\u0442\u0438",
  clearFilter: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u0438",
  cancelFilter: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  // Filter Titles
  textFilter: "\u0422\u0435\u043A\u0441\u0442\u043E\u0432\u0438\u0439 \u0444\u0456\u043B\u044C\u0442\u0440",
  numberFilter: "\u0427\u0438\u0441\u043B\u043E\u0432\u0438\u0439 \u0444\u0456\u043B\u044C\u0442\u0440",
  dateFilter: "\u0424\u0456\u043B\u044C\u0442\u0440 \u0437\u0430 \u0434\u0430\u0442\u043E\u044E",
  setFilter: "\u0424\u0456\u043B\u044C\u0442\u0440 \u0437\u0430 \u043C\u043D\u043E\u0436\u0438\u043D\u043E\u044E",
  // Group Column Filter
  groupFilterSelect: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u043F\u043E\u043B\u0435:",
  // Advanced Filter
  advancedFilterContains: "\u043C\u0456\u0441\u0442\u0438\u0442\u044C",
  advancedFilterNotContains: "\u043D\u0435 \u043C\u0456\u0441\u0442\u0438\u0442\u044C",
  advancedFilterTextEquals: "\u0434\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  advancedFilterTextNotEqual: "\u043D\u0435 \u0434\u043E\u0440\u0456\u0432\u043D\u044E\u0454",
  advancedFilterStartsWith: "\u043F\u043E\u0447\u0438\u043D\u0430\u0454\u0442\u044C\u0441\u044F \u0437",
  advancedFilterEndsWith: "\u0437\u0430\u043A\u0456\u043D\u0447\u0443\u0454\u0442\u044C\u0441\u044F \u043D\u0430",
  advancedFilterBlank: "\u0454 \u043F\u0443\u0441\u0442\u0438\u043C",
  advancedFilterNotBlank: "\u043D\u0435 \u0454 \u043F\u0443\u0441\u0442\u0438\u043C",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u0454 \u0456\u0441\u0442\u0438\u043D\u043D\u0438\u043C",
  advancedFilterFalse: "\u0454 \u0445\u0438\u0431\u043D\u0438\u043C",
  advancedFilterAnd: "\u0406",
  advancedFilterOr: "\u0410\u0411\u041E",
  advancedFilterApply: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  advancedFilterBuilder: "\u041F\u043E\u0431\u0443\u0434\u043E\u0432\u0430",
  advancedFilterValidationMissingColumn: "\u0412\u0456\u0434\u0441\u0443\u0442\u043D\u044F \u043A\u043E\u043B\u043E\u043D\u043A\u0430",
  advancedFilterValidationMissingOption: "\u0412\u0456\u0434\u0441\u0443\u0442\u043D\u0456\u0439 \u0432\u0430\u0440\u0456\u0430\u043D\u0442",
  advancedFilterValidationMissingValue: "\u0412\u0456\u0434\u0441\u0443\u0442\u043D\u0454 \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  advancedFilterValidationInvalidColumn: "\u041A\u043E\u043B\u043E\u043D\u043A\u0443 \u043D\u0435 \u0437\u043D\u0430\u0439\u0434\u0435\u043D\u043E",
  advancedFilterValidationInvalidOption: "\u0412\u0430\u0440\u0456\u0430\u043D\u0442 \u043D\u0435 \u0437\u043D\u0430\u0439\u0434\u0435\u043D\u043E",
  advancedFilterValidationMissingQuote: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u0431\u0435\u0437 \u043A\u0456\u043D\u0446\u0435\u0432\u043E\u0457 \u043B\u0430\u043F\u043A\u0438",
  advancedFilterValidationNotANumber: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u043D\u0435 \u0454 \u0447\u0438\u0441\u043B\u043E\u043C",
  advancedFilterValidationInvalidDate: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u043D\u0435 \u0454 \u0434\u0456\u0439\u0441\u043D\u043E\u044E \u0434\u0430\u0442\u043E\u044E",
  advancedFilterValidationMissingCondition: "\u0412\u0456\u0434\u0441\u0443\u0442\u043D\u044F \u0443\u043C\u043E\u0432\u0430",
  advancedFilterValidationJoinOperatorMismatch: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u0438 \u043E\u0431'\u0454\u0434\u043D\u0430\u043D\u043D\u044F \u0432 \u043C\u0435\u0436\u0430\u0445 \u0443\u043C\u043E\u0432\u0438 \u043C\u0430\u044E\u0442\u044C \u0431\u0443\u0442\u0438 \u043E\u0434\u043D\u0430\u043A\u043E\u0432\u0438\u043C\u0438",
  advancedFilterValidationInvalidJoinOperator: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u043E\u0431'\u0454\u0434\u043D\u0430\u043D\u043D\u044F \u043D\u0435 \u0437\u043D\u0430\u0439\u0434\u0435\u043D\u043E",
  advancedFilterValidationMissingEndBracket: "\u0412\u0456\u0434\u0441\u0443\u0442\u043D\u044F \u043A\u0456\u043D\u0446\u0435\u0432\u0430 \u0434\u0443\u0436\u043A\u0430",
  advancedFilterValidationExtraEndBracket: "\u0417\u0430\u0431\u0430\u0433\u0430\u0442\u043E \u043A\u0456\u043D\u0446\u0435\u0432\u0438\u0445 \u0434\u0443\u0436\u043E\u043A",
  advancedFilterValidationMessage: "\u0423 \u0432\u0438\u0440\u0430\u0437\u0456 \u0454 \u043F\u043E\u043C\u0438\u043B\u043A\u0430. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0423 \u0432\u0438\u0440\u0430\u0437\u0456 \u0454 \u043F\u043E\u043C\u0438\u043B\u043A\u0430. ${variable} \u0432 \u043A\u0456\u043D\u0446\u0456 \u0432\u0438\u0440\u0430\u0437\u0443.",
  advancedFilterBuilderTitle: "\u0420\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u0438\u0439 \u0444\u0456\u043B\u044C\u0442\u0440",
  advancedFilterBuilderApply: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  advancedFilterBuilderCancel: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  advancedFilterBuilderAddButtonTooltip: "\u0414\u043E\u0434\u0430\u0442\u0438 \u0444\u0456\u043B\u044C\u0442\u0440 \u0430\u0431\u043E \u0433\u0440\u0443\u043F\u0443",
  advancedFilterBuilderRemoveButtonTooltip: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  advancedFilterBuilderMoveUpButtonTooltip: "\u041F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438 \u0432\u0433\u043E\u0440\u0443",
  advancedFilterBuilderMoveDownButtonTooltip: "\u041F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438 \u0432\u043D\u0438\u0437",
  advancedFilterBuilderAddJoin: "\u0414\u043E\u0434\u0430\u0442\u0438 \u0433\u0440\u0443\u043F\u0443",
  advancedFilterBuilderAddCondition: "\u0414\u043E\u0434\u0430\u0442\u0438 \u0444\u0456\u043B\u044C\u0442\u0440",
  advancedFilterBuilderSelectColumn: "\u041E\u0431\u0435\u0440\u0456\u0442\u044C \u043A\u043E\u043B\u043E\u043D\u043A\u0443",
  advancedFilterBuilderSelectOption: "\u041E\u0431\u0435\u0440\u0456\u0442\u044C \u0432\u0430\u0440\u0456\u0430\u043D\u0442",
  advancedFilterBuilderEnterValue: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F...",
  advancedFilterBuilderValidationAlreadyApplied: "\u041F\u043E\u0442\u043E\u0447\u043D\u0438\u0439 \u0444\u0456\u043B\u044C\u0442\u0440 \u0432\u0436\u0435 \u0437\u0430\u0441\u0442\u043E\u0441\u043E\u0432\u0430\u043D\u0438\u0439.",
  advancedFilterBuilderValidationIncomplete: "\u041D\u0435 \u0432\u0441\u0456 \u0443\u043C\u043E\u0432\u0438 \u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u0456.",
  advancedFilterBuilderValidationSelectColumn: "\u041F\u043E\u0442\u0440\u0456\u0431\u043D\u043E \u043E\u0431\u0440\u0430\u0442\u0438 \u043A\u043E\u043B\u043E\u043D\u043A\u0443.",
  advancedFilterBuilderValidationSelectOption: "\u041F\u043E\u0442\u0440\u0456\u0431\u043D\u043E \u043E\u0431\u0440\u0430\u0442\u0438 \u0432\u0430\u0440\u0456\u0430\u043D\u0442.",
  advancedFilterBuilderValidationEnterValue: "\u041F\u043E\u0442\u0440\u0456\u0431\u043D\u043E \u0432\u0432\u0435\u0441\u0442\u0438 \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F.",
  // Side Bar
  columns: "\u0421\u0442\u043E\u0432\u043F\u0446\u0456",
  filters: "\u0424\u0456\u043B\u044C\u0442\u0440\u0438",
  // columns tool panel
  pivotMode: "\u0420\u0435\u0436\u0438\u043C \u0437\u0432\u0435\u0434\u0435\u043D\u043D\u044F",
  groups: "\u0413\u0440\u0443\u043F\u0438 \u0440\u044F\u0434\u043A\u0456\u0432",
  rowGroupColumnsEmptyMessage: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C \u0441\u044E\u0434\u0438 \u0434\u043B\u044F \u0432\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u0433\u0440\u0443\u043F \u0440\u044F\u0434\u043A\u0456\u0432",
  values: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  valueColumnsEmptyMessage: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C \u0441\u044E\u0434\u0438 \u0434\u043B\u044F \u0430\u0433\u0440\u0435\u0433\u0443\u0432\u0430\u043D\u043D\u044F",
  pivots: "\u041C\u0456\u0442\u043A\u0438 \u0441\u0442\u043E\u0432\u043F\u0446\u044F",
  pivotColumnsEmptyMessage: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C \u0441\u044E\u0434\u0438 \u0434\u043B\u044F \u0432\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F \u043C\u0456\u0442\u043E\u043A \u0441\u0442\u043E\u0432\u043F\u0446\u044F",
  // Header of the Default Group Column
  group: "\u0413\u0440\u0443\u043F\u0430",
  // Row Drag
  rowDragRow: "\u0440\u044F\u0434\u043E\u043A",
  rowDragRows: "\u0440\u044F\u0434\u0438",
  // Other
  loadingOoo: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F...",
  loadingError: "\u041F\u041E\u041C\u0418\u041B\u041A\u0410",
  noRowsToShow: "\u041D\u0435\u043C\u0430\u0454 \u0440\u044F\u0434\u043A\u0456\u0432 \u0434\u043B\u044F \u0432\u0456\u0434\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F",
  enabled: "\u0423\u0432\u0456\u043C\u043A\u043D\u0435\u043D\u043E",
  // Menu
  pinColumn: "\u0417\u0430\u043A\u0440\u0456\u043F\u0438\u0442\u0438 \u0421\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  pinLeft: "\u0417\u0430\u043A\u0440\u0456\u043F\u0438\u0442\u0438 \u041B\u0456\u0432\u043E\u0440\u0443\u0447",
  pinRight: "\u0417\u0430\u043A\u0440\u0456\u043F\u0438\u0442\u0438 \u041F\u0440\u0430\u0432\u043E\u0440\u0443\u0447",
  noPin: "\u041D\u0435 \u0417\u0430\u043A\u0440\u0456\u043F\u043B\u044F\u0442\u0438",
  valueAggregation: "\u0410\u0433\u0440\u0435\u0433\u0430\u0446\u0456\u044F \u0417\u043D\u0430\u0447\u0435\u043D\u044C",
  noAggregation: "\u0416\u043E\u0434\u043D\u0430",
  autosizeThisColumn: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u0417\u043C\u0456\u043D\u0438\u0442\u0438 \u0420\u043E\u0437\u043C\u0456\u0440 \u0426\u044C\u043E\u0433\u043E \u0421\u0442\u043E\u0432\u043F\u0446\u044F",
  autosizeAllColumns: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E \u0417\u043C\u0456\u043D\u0438\u0442\u0438 \u0420\u043E\u0437\u043C\u0456\u0440 \u0423\u0441\u0456\u0445 \u0421\u0442\u043E\u0432\u043F\u0446\u0456\u0432",
  groupBy: "\u0413\u0440\u0443\u043F\u0443\u0432\u0430\u0442\u0438 \u0437\u0430",
  ungroupBy: "\u0420\u043E\u0437\u0433\u0440\u0443\u043F\u0443\u0432\u0430\u0442\u0438 \u0437\u0430",
  ungroupAll: "\u0420\u043E\u0437\u0433\u0440\u0443\u043F\u0443\u0432\u0430\u0442\u0438 \u0412\u0441\u0435",
  addToValues: "\u0414\u043E\u0434\u0430\u0442\u0438 ${variable} \u0434\u043E \u0437\u043D\u0430\u0447\u0435\u043D\u044C",
  removeFromValues: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438 ${variable} \u0437\u0456 \u0437\u043D\u0430\u0447\u0435\u043D\u044C",
  addToLabels: "\u0414\u043E\u0434\u0430\u0442\u0438 ${variable} \u0434\u043E \u043C\u0456\u0442\u043E\u043A",
  removeFromLabels: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438 ${variable} \u0437 \u043C\u0456\u0442\u043E\u043A",
  resetColumns: "\u0421\u043A\u0438\u043D\u0443\u0442\u0438 \u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F \u0421\u0442\u043E\u0432\u043F\u0446\u0456\u0432",
  expandAll: "\u0420\u043E\u0437\u0433\u043E\u0440\u043D\u0443\u0442\u0438 \u0412\u0441\u0456 \u0413\u0440\u0443\u043F\u0438 \u0420\u044F\u0434\u043A\u0456\u0432",
  collapseAll: "\u0417\u0433\u043E\u0440\u043D\u0443\u0442\u0438 \u0412\u0441\u0456 \u0413\u0440\u0443\u043F\u0438 \u0420\u044F\u0434\u043A\u0456\u0432",
  copy: "\u041A\u043E\u043F\u0456\u044E\u0432\u0430\u0442\u0438",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u041A\u043E\u043F\u0456\u044E\u0432\u0430\u0442\u0438 \u0437 \u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043A\u0430\u043C\u0438",
  copyWithGroupHeaders: "\u041A\u043E\u043F\u0456\u044E\u0432\u0430\u0442\u0438 \u0437 \u0413\u0440\u0443\u043F\u043E\u0432\u0438\u043C\u0438 \u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043A\u0430\u043C\u0438",
  cut: "\u0412\u0438\u0440\u0456\u0437\u0430\u0442\u0438",
  paste: "\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u0438",
  ctrlV: "Ctrl+V",
  export: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442",
  csvExport: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442 CSV",
  excelExport: "\u0415\u043A\u0441\u043F\u043E\u0440\u0442 Excel",
  columnFilter: "\u0424\u0456\u043B\u044C\u0442\u0440 \u0421\u0442\u043E\u0432\u043F\u0446\u0456\u0432",
  columnChooser: "\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u0421\u0442\u043E\u0432\u043F\u0446\u0456",
  chooseColumns: "\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u0421\u0442\u043E\u0432\u043F\u0446\u0456",
  sortAscending: "\u0421\u043E\u0440\u0442\u0443\u0432\u0430\u0442\u0438 \u0437\u0430 \u0417\u0440\u043E\u0441\u0442\u0430\u043D\u043D\u044F\u043C",
  sortDescending: "\u0421\u043E\u0440\u0442\u0443\u0432\u0430\u0442\u0438 \u0437\u0430 \u0421\u043F\u0430\u0434\u0430\u043D\u043D\u044F\u043C",
  sortUnSort: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u0438 \u0421\u043E\u0440\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u0421\u0443\u043C\u0430",
  first: "\u041F\u0435\u0440\u0448\u0438\u0439",
  last: "\u041E\u0441\u0442\u0430\u043D\u043D\u0456\u0439",
  min: "\u041C\u0456\u043D",
  max: "\u041C\u0430\u043A\u0441",
  none: "\u041D\u0435\u043C\u0430\u0454",
  count: "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C",
  avg: "\u0421\u0435\u0440\u0435\u0434\u043D\u0454",
  filteredRows: "\u0412\u0456\u0434\u0444\u0456\u043B\u044C\u0442\u0440\u043E\u0432\u0430\u043D\u043E",
  selectedRows: "\u0412\u0438\u0431\u0440\u0430\u043D\u043E",
  totalRows: "\u0423\u0441\u044C\u043E\u0433\u043E \u0440\u044F\u0434\u043A\u0456\u0432",
  totalAndFilteredRows: "\u0420\u044F\u0434\u043A\u0438",
  more: "\u0411\u0456\u043B\u044C\u0448\u0435",
  to: "\u0434\u043E",
  of: "\u0437",
  page: "\u0421\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  pageLastRowUnknown: "?",
  nextPage: "\u041D\u0430\u0441\u0442\u0443\u043F\u043D\u0430 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  lastPage: "\u041E\u0441\u0442\u0430\u043D\u043D\u044F \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  firstPage: "\u041F\u0435\u0440\u0448\u0430 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  previousPage: "\u041F\u043E\u043F\u0435\u0440\u0435\u0434\u043D\u044F \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0430",
  pageSizeSelectorLabel: "\u0420\u043E\u0437\u043C\u0456\u0440 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438:",
  footerTotal: "\u0423\u0441\u044C\u043E\u0433\u043E",
  // Pivoting
  pivotColumnGroupTotals: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0430 \u0441\u0443\u043C\u0430",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u0417\u0432\u0435\u0434\u0435\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430 \u0442\u0430 \u0440\u0435\u0436\u0438\u043C \u0437\u0432\u0435\u0434\u0435\u043D\u043D\u044F",
  pivotChart: "\u0417\u0432\u0435\u0434\u0435\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  chartRange: "\u0414\u0456\u0430\u043F\u0430\u0437\u043E\u043D \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0438",
  columnChart: "\u0421\u0442\u043E\u0432\u043F\u0447\u0430\u0441\u0442\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  groupedColumn: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0438\u0439",
  stackedColumn: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439",
  normalizedColumn: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439 \u043D\u0430 100%",
  barChart: "\u0413\u0456\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  groupedBar: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0438\u0439",
  stackedBar: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439",
  normalizedBar: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439 \u043D\u0430 100%",
  pieChart: "\u041A\u0440\u0443\u0433\u043E\u0432\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  pie: "\u041A\u0440\u0443\u0433\u043E\u0432\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  donut: "\u041A\u0456\u043B\u044C\u0446\u0435\u0432\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  line: "\u041B\u0456\u043D\u0456\u044F",
  xyChart: "X Y (\u0420\u043E\u0437\u0441\u0456\u044F\u043D\u0430)",
  scatter: "\u0420\u043E\u0437\u0441\u0456\u044F\u043D\u0430",
  bubble: "\u0411\u0443\u043B\u044C\u0431\u0430\u0448\u043A\u043E\u0432\u0430",
  areaChart: "\u041F\u043B\u043E\u0449\u0430",
  area: "\u041F\u043B\u043E\u0449\u0430",
  stackedArea: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439",
  normalizedArea: "\u0428\u0430\u0440\u0443\u0432\u0430\u0442\u0438\u0439 \u043D\u0430 100%",
  histogramChart: "\u0413\u0456\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  polarChart: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  radarLine: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043B\u0456\u043D\u0456\u044F",
  radarArea: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043F\u043B\u043E\u0449\u0430",
  nightingale: "\u0414\u0456\u0430\u0433\u0440\u0430\u043C\u0430 \u041D\u0430\u0439\u0442\u0456\u043D\u0433\u0435\u0439\u043B\u0430",
  radialColumn: "\u0420\u0430\u0434\u0456\u0430\u043B\u044C\u043D\u0438\u0439 \u0441\u0442\u043E\u0432\u043F\u0447\u0438\u043A",
  radialBar: "\u0420\u0430\u0434\u0456\u0430\u043B\u044C\u043D\u0430 \u0433\u0456\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  statisticalChart: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u0447\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  boxPlot: "\u0421\u0445\u0435\u043C\u0430 \u043A\u043E\u0440\u043E\u0431\u0447\u0430\u0442\u043E\u0433\u043E \u0432\u0438\u0433\u043B\u044F\u0434\u0443",
  rangeBar: "\u0414\u0456\u0430\u0433\u0440\u0430\u043C\u0430 \u0456\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u0456\u0432",
  rangeArea: "\u041E\u0431\u043B\u0430\u0441\u0442\u044C \u0456\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u0456\u0432",
  hierarchicalChart: "\u0406\u0454\u0440\u0430\u0440\u0445\u0456\u0447\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  treemap: "\u0414\u0435\u0440\u0435\u0432\u043E\u043F\u043E\u0434\u0456\u0431\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  sunburst: "\u0421\u043E\u043D\u044F\u0447\u043D\u0438\u0439 \u0433\u0440\u0430\u0444\u0456\u043A",
  specializedChart: "\u0421\u043F\u0435\u0446\u0456\u0430\u043B\u0456\u0437\u043E\u0432\u0430\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  waterfall: "\u0412\u043E\u0434\u043E\u0441\u043F\u0430\u0434\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  heatmap: "\u0422\u0435\u043F\u043B\u043E\u0432\u0430 \u043A\u0430\u0440\u0442\u0430",
  combinationChart: "\u041A\u043E\u043C\u0431\u0456\u043D\u043E\u0432\u0430\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  columnLineCombo: "\u0421\u0442\u043E\u0432\u043F\u0447\u0438\u043A \u0442\u0430 \u043B\u0456\u043D\u0456\u044F",
  AreaColumnCombo: "\u041F\u043B\u043E\u0449\u0430 \u0442\u0430 \u0441\u0442\u043E\u0432\u043F\u0447\u0438\u043A",
  // Charts
  pivotChartTitle: "\u0417\u0432\u0435\u0434\u0435\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  rangeChartTitle: "\u0414\u0456\u0430\u043F\u0430\u0437\u043E\u043D \u0434\u0456\u0430\u0433\u0440\u0430\u043C",
  settings: "\u0413\u0440\u0430\u0444\u0456\u043A",
  data: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  format: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u0442\u0438",
  categories: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u0457",
  defaultCategory: "(\u041D\u0435\u043C\u0430\u0454)",
  series: "\u0420\u044F\u0434\u0438",
  switchCategorySeries: "\u041F\u0435\u0440\u0435\u043C\u043A\u043D\u0443\u0442\u0438 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u044E / \u0441\u0435\u0440\u0456\u044E",
  categoryValues: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u0457",
  seriesLabels: "\u041F\u043E\u0437\u043D\u0430\u0447\u043A\u0438 \u0441\u0435\u0440\u0456\u0439",
  aggregate: "\u0410\u0433\u0440\u0435\u0433\u0430\u0442",
  xyValues: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F X Y",
  paired: "\u041F\u0430\u0440\u043D\u0438\u0439 \u0440\u0435\u0436\u0438\u043C",
  axis: "\u0412\u0456\u0441\u044C",
  xAxis: "\u0413\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u044C\u043D\u0430 \u0432\u0456\u0441\u044C",
  yAxis: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u044C\u043D\u0430 \u0432\u0456\u0441\u044C",
  polarAxis: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430 \u0432\u0456\u0441\u044C",
  radiusAxis: "\u0412\u0456\u0441\u044C \u0440\u0430\u0434\u0456\u0443\u0441\u0430",
  navigator: "\u041D\u0430\u0432\u0456\u0433\u0430\u0442\u043E\u0440",
  zoom: "\u0417\u0431\u0456\u043B\u044C\u0448\u0438\u0442\u0438",
  animation: "\u0410\u043D\u0456\u043C\u0430\u0446\u0456\u044F",
  crosshair: "\u041F\u0435\u0440\u0435\u0445\u0440\u0435\u0441\u0442\u044F",
  color: "\u041A\u043E\u043B\u0456\u0440",
  thickness: "\u0422\u043E\u0432\u0449\u0438\u043D\u0430",
  preferredLength: "\u0411\u0430\u0436\u0430\u043D\u0430 \u0434\u043E\u0432\u0436\u0438\u043D\u0430",
  xType: "\u0422\u0438\u043F X",
  axisType: "\u0422\u0438\u043F \u043E\u0441\u0456",
  automatic: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u043E",
  category: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u044F",
  number: "\u0427\u0438\u0441\u043B\u043E",
  time: "\u0427\u0430\u0441",
  timeFormat: "\u0424\u043E\u0440\u043C\u0430\u0442 \u0447\u0430\u0441\u0443",
  autoRotate: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u043D\u0435 \u043E\u0431\u0435\u0440\u0442\u0430\u043D\u043D\u044F",
  labelRotation: "\u041E\u0431\u0435\u0440\u0442\u0430\u043D\u043D\u044F \u044F\u0440\u043B\u0438\u043A\u0430",
  circle: "\u041A\u043E\u043B\u043E",
  polygon: "\u0411\u0430\u0433\u0430\u0442\u043E\u043A\u0443\u0442\u043D\u0438\u043A",
  square: "\u041A\u0432\u0430\u0434\u0440\u0430\u0442",
  cross: "\u0425\u0440\u0435\u0441\u0442",
  diamond: "\u0420\u043E\u043C\u0431",
  plus: "\u041F\u043B\u044E\u0441",
  triangle: "\u0422\u0440\u0438\u043A\u0443\u0442\u043D\u0438\u043A",
  heart: "\u0421\u0435\u0440\u0446\u0435",
  orientation: "\u041E\u0440\u0456\u0454\u043D\u0442\u0430\u0446\u0456\u044F",
  fixed: "\u0424\u0456\u043A\u0441\u043E\u0432\u0430\u043D\u0438\u0439",
  parallel: "\u041F\u0430\u0440\u0430\u043B\u0435\u043B\u044C\u043D\u043E",
  perpendicular: "\u041F\u0435\u0440\u043F\u0435\u043D\u0434\u0438\u043A\u0443\u043B\u044F\u0440",
  radiusAxisPosition: "\u041F\u043E\u0437\u0438\u0446\u0456\u044F",
  ticks: "\u041C\u0456\u0442\u043A\u0438",
  gridLines: "\u041B\u0456\u043D\u0456\u0457 \u0441\u0456\u0442\u043A\u0438",
  width: "\u0428\u0438\u0440\u0438\u043D\u0430",
  height: "\u0412\u0438\u0441\u043E\u0442\u0430",
  length: "\u0414\u043E\u0432\u0436\u0438\u043D\u0430",
  padding: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F",
  spacing: "\u0412\u0456\u0434\u0441\u0442\u0430\u0432\u0430\u043D\u043D\u044F",
  chartStyle: "\u0421\u0442\u0438\u043B\u044C \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0438",
  title: "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A",
  chartTitles: "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043A\u0438",
  chartTitle: "\u041D\u0430\u0437\u0432\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0438",
  chartSubtitle: "\u041F\u0456\u0434\u0437\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A",
  horizontalAxisTitle: "\u041D\u0430\u0437\u0432\u0430 \u0433\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u044C\u043D\u043E\u0457 \u043E\u0441\u0456",
  verticalAxisTitle: "\u041D\u0430\u0437\u0432\u0430 \u0432\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u044C\u043D\u043E\u0457 \u043E\u0441\u0456",
  polarAxisTitle: "\u041D\u0430\u0437\u0432\u0430 \u043F\u043E\u043B\u044F\u0440\u043D\u043E\u0457 \u043E\u0441\u0456",
  titlePlaceholder: "\u041D\u0430\u0437\u0432\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0438",
  background: "\u0424\u043E\u043D",
  font: "\u0428\u0440\u0438\u0444\u0442",
  weight: "\u0412\u0430\u0433\u0430",
  top: "\u0412\u0435\u0440\u0445",
  right: "\u041F\u0440\u0430\u0432\u043E\u0440\u0443\u0447",
  bottom: "\u041D\u0438\u0437",
  left: "\u041B\u0456\u0432\u043E\u0440\u0443\u0447",
  labels: "\u042F\u0440\u043B\u0438\u043A\u0438",
  calloutLabels: "\u042F\u0440\u043B\u0438\u043A\u0438 \u0432\u0438\u043D\u043E\u0441\u043A\u0438",
  sectorLabels: "\u042F\u0440\u043B\u0438\u043A\u0438 \u0441\u0435\u043A\u0442\u043E\u0440\u0443",
  positionRatio: "\u0421\u043F\u0456\u0432\u0432\u0456\u0434\u043D\u043E\u0448\u0435\u043D\u043D\u044F \u043F\u043E\u0437\u0438\u0446\u0456\u0457",
  size: "\u0420\u043E\u0437\u043C\u0456\u0440",
  shape: "\u0424\u043E\u0440\u043C\u0430",
  minSize: "\u041C\u0456\u043D\u0456\u043C\u0430\u043B\u044C\u043D\u0438\u0439 \u0440\u043E\u0437\u043C\u0456\u0440",
  maxSize: "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0438\u0439 \u0440\u043E\u0437\u043C\u0456\u0440",
  legend: "\u041B\u0435\u0433\u0435\u043D\u0434\u0430",
  position: "\u041F\u043E\u0437\u0438\u0446\u0456\u044F",
  markerSize: "\u0420\u043E\u0437\u043C\u0456\u0440 \u043C\u0430\u0440\u043A\u0435\u0440\u0430",
  markerStroke: "\u041E\u0431\u0432\u0435\u0434\u0435\u043D\u043D\u044F \u043C\u0430\u0440\u043A\u0435\u0440\u0430",
  markerPadding: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F \u043C\u0430\u0440\u043A\u0435\u0440\u0430",
  itemSpacing: "\u0412\u0456\u0434\u0441\u0442\u0430\u0432\u0430\u043D\u043D\u044F \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0430",
  itemPaddingX: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0430 X",
  itemPaddingY: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0430 Y",
  layoutHorizontalSpacing: "\u0413\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u044C\u043D\u0435 \u0432\u0456\u0434\u0441\u0442\u0430\u0432\u0430\u043D\u043D\u044F",
  layoutVerticalSpacing: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u044C\u043D\u0435 \u0432\u0456\u0434\u0441\u0442\u0430\u0432\u0430\u043D\u043D\u044F",
  strokeWidth: "\u0422\u043E\u0432\u0449\u0438\u043D\u0430 \u043B\u0456\u043D\u0456\u0457",
  offset: "\u0417\u0441\u0443\u0432",
  offsets: "\u0417\u0441\u0443\u0432\u0438",
  tooltips: "\u041F\u0456\u0434\u043A\u0430\u0437\u043A\u0438",
  callout: "\u0412\u0438\u043D\u043E\u0441\u043A\u0430",
  markers: "\u041C\u0430\u0440\u043A\u0435\u0440\u0438",
  shadow: "\u0422\u0456\u043D\u044C",
  blur: "\u0420\u043E\u0437\u043C\u0438\u0442\u0442\u044F",
  xOffset: "\u0417\u0441\u0443\u0432 X",
  yOffset: "\u0417\u0441\u0443\u0432 Y",
  lineWidth: "\u0422\u043E\u0432\u0449\u0438\u043D\u0430 \u043B\u0456\u043D\u0456\u0457",
  lineDash: "\u0428\u0442\u0440\u0438\u0445\u0443\u0432\u0430\u043D\u043D\u044F \u043B\u0456\u043D\u0456\u0457",
  lineDashOffset: "\u0417\u0441\u0443\u0432 \u0448\u0442\u0440\u0438\u0445\u0443\u0432\u0430\u043D\u043D\u044F",
  scrollingZoom: "\u041F\u0440\u043E\u043A\u0440\u0443\u0442\u043A\u0430",
  scrollingStep: "\u041A\u0440\u043E\u043A \u043F\u0440\u043E\u043A\u0440\u0443\u0442\u043A\u0438",
  selectingZoom: "\u0412\u0438\u0431\u0456\u0440",
  durationMillis: "\u0422\u0440\u0438\u0432\u0430\u043B\u0456\u0441\u0442\u044C (\u043C\u0441)",
  crosshairLabel: "\u042F\u0440\u043B\u0438\u043A",
  crosshairSnap: "\u041F\u0440\u0438\u0432'\u044F\u0437\u043A\u0430 \u0434\u043E \u0432\u0443\u0437\u043B\u0430",
  normal: "\u0417\u0432\u0438\u0447\u0430\u0439\u043D\u0438\u0439",
  bold: "\u041D\u0430\u043F\u0456\u0432\u0436\u0438\u0440\u043D\u0438\u0439",
  italic: "\u041A\u0443\u0440\u0441\u0438\u0432",
  boldItalic: "\u041D\u0430\u043F\u0456\u0432\u0436\u0438\u0440\u043D\u0438\u0439 \u043A\u0443\u0440\u0441\u0438\u0432",
  predefined: "\u0412\u0438\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u043D\u0430\u043F\u0435\u0440\u0435\u0434",
  fillOpacity: "\u041D\u0435\u043F\u0440\u043E\u0437\u043E\u0440\u0456\u0441\u0442\u044C \u0437\u0430\u043B\u0438\u0432\u043A\u0438",
  strokeColor: "\u041A\u043E\u043B\u0456\u0440 \u043B\u0456\u043D\u0456\u0457",
  strokeOpacity: "\u041D\u0435\u043F\u0440\u043E\u0437\u043E\u0440\u0456\u0441\u0442\u044C \u043B\u0456\u043D\u0456\u0457",
  miniChart: "\u041C\u0456\u043D\u0456-\u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430",
  histogramBinCount: "\u041A\u0456\u043B\u044C\u043A\u0456\u0441\u0442\u044C \u0431\u0456\u043D\u0456\u0432",
  connectorLine: "\u0417'\u0454\u0434\u043D\u0443\u0432\u0430\u043B\u044C\u043D\u0430 \u043B\u0456\u043D\u0456\u044F",
  seriesItems: "\u0415\u043B\u0435\u043C\u0435\u043D\u0442\u0438 \u0441\u0435\u0440\u0456\u0439",
  seriesItemType: "\u0422\u0438\u043F \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0430",
  seriesItemPositive: "\u041F\u043E\u0437\u0438\u0442\u0438\u0432\u043D\u0438\u0439",
  seriesItemNegative: "\u041D\u0435\u0433\u0430\u0442\u0438\u0432\u043D\u0438\u0439",
  seriesItemLabels: "\u042F\u0440\u043B\u0438\u043A\u0438 \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0456\u0432",
  columnGroup: "\u0421\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  barGroup: "\u0421\u043C\u0443\u0433\u0430",
  pieGroup: "\u041A\u0440\u0443\u0433\u043E\u0432\u0430",
  lineGroup: "\u041B\u0456\u043D\u0456\u044F",
  scatterGroup: "X Y (\u0420\u043E\u0437\u043A\u0438\u0434\u0430\u043D\u043D\u044F)",
  areaGroup: "\u041E\u0431\u043B\u0430\u0441\u0442\u044C",
  polarGroup: "\u041F\u043E\u043B\u044F\u0440\u043D\u0430",
  statisticalGroup: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u0447\u043D\u0430",
  hierarchicalGroup: "\u0406\u0454\u0440\u0430\u0440\u0445\u0456\u0447\u043D\u0430",
  specializedGroup: "\u0421\u043F\u0435\u0446\u0456\u0430\u043B\u0456\u0437\u043E\u0432\u0430\u043D\u0430",
  combinationGroup: "\u041A\u043E\u043C\u0431\u0456\u043D\u0430\u0446\u0456\u044F",
  groupedColumnTooltip: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0430",
  stackedColumnTooltip: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  normalizedColumnTooltip: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  groupedBarTooltip: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0430",
  stackedBarTooltip: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  normalizedBarTooltip: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  pieTooltip: "\u041A\u0440\u0443\u0433\u043E\u0432\u0430",
  donutTooltip: "\u041A\u0456\u043B\u044C\u0446\u0435\u0432\u0430",
  lineTooltip: "\u041B\u0456\u043D\u0456\u044F",
  groupedAreaTooltip: "\u041E\u0431\u043B\u0430\u0441\u0442\u044C",
  stackedAreaTooltip: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  normalizedAreaTooltip: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  scatterTooltip: "\u0420\u043E\u0437\u043A\u0438\u0434\u0430\u043D\u043D\u044F",
  bubbleTooltip: "\u0411\u0443\u043B\u044C\u0431\u0430\u0448\u043A\u0430",
  histogramTooltip: "\u0413\u0456\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  radialColumnTooltip: "\u0420\u0430\u0434\u0456\u0430\u043B\u044C\u043D\u0438\u0439 \u0441\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  radialBarTooltip: "\u0420\u0430\u0434\u0456\u0430\u043B\u044C\u043D\u0430 \u0441\u043C\u0443\u0433\u0430",
  radarLineTooltip: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043B\u0456\u043D\u0456\u044F",
  radarAreaTooltip: "\u0420\u0430\u0434\u0430\u0440\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442\u044C",
  nightingaleTooltip: "\u0414\u0456\u0430\u0433\u0440\u0430\u043C\u0430 \u041D\u0430\u0439\u0442\u0456\u043D\u0433\u0435\u0439\u043B\u0456\u0432",
  rangeBarTooltip: "\u0414\u0456\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u0441\u043C\u0443\u0433\u0430",
  rangeAreaTooltip: "\u0414\u0456\u0430\u043F\u0430\u0437\u043E\u043D\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442\u044C",
  boxPlotTooltip: "\u042F\u0449\u0438\u043A\u043E\u0432\u0438\u0439 \u0433\u0440\u0430\u0444\u0456\u043A",
  treemapTooltip: "\u0422\u0440\u0456\u043C\u0435\u043F",
  sunburstTooltip: "\u0421\u043E\u043D\u044F\u0447\u043D\u0438\u0439 \u0441\u043F\u043B\u0435\u0441\u043A",
  waterfallTooltip: "\u0412\u043E\u0434\u043E\u0441\u043F\u0430\u0434",
  heatmapTooltip: "\u0422\u0435\u043F\u043B\u043E\u0432\u0430 \u043A\u0430\u0440\u0442\u0430",
  columnLineComboTooltip: "\u0421\u0442\u043E\u0432\u043F\u0435\u0446\u044C \u0456 \u043B\u0456\u043D\u0456\u044F",
  areaColumnComboTooltip: "\u041E\u0431\u043B\u0430\u0441\u0442\u044C \u0456 \u0441\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  customComboTooltip: "\u0421\u043F\u0435\u0446\u0456\u0430\u043B\u044C\u043D\u0430 \u043A\u043E\u043C\u0431\u0456\u043D\u0430\u0446\u0456\u044F",
  innerRadius: "\u0412\u043D\u0443\u0442\u0440\u0456\u0448\u043D\u0456\u0439 \u0440\u0430\u0434\u0456\u0443\u0441",
  startAngle: "\u041A\u0443\u0442 \u043F\u043E\u0447\u0430\u0442\u043A\u0443",
  endAngle: "\u041A\u0443\u0442 \u0437\u0430\u043A\u0456\u043D\u0447\u0435\u043D\u043D\u044F",
  reverseDirection: "\u0417\u0432\u043E\u0440\u043E\u0442\u043D\u0438\u0439 \u043D\u0430\u043F\u0440\u044F\u043C\u043E\u043A",
  groupPadding: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F \u0433\u0440\u0443\u043F\u0438",
  seriesPadding: "\u0412\u0456\u0434\u0441\u0442\u0443\u043F \u0441\u0435\u0440\u0456\u0439",
  tile: "\u041F\u043B\u0438\u0442\u043A\u0430",
  whisker: "\u0412\u0443\u0441\u0438\u043A\u0438",
  cap: "\u041A\u043E\u0432\u043F\u0430\u0447\u043E\u043A",
  capLengthRatio: "\u0421\u043F\u0456\u0432\u0432\u0456\u0434\u043D\u043E\u0448\u0435\u043D\u043D\u044F \u0434\u043E\u0432\u0436\u0438\u043D\u0438 \u043A\u043E\u0432\u043F\u0430\u0447\u043A\u0430",
  labelPlacement: "\u0420\u043E\u0437\u0442\u0430\u0448\u0443\u0432\u0430\u043D\u043D\u044F \u044F\u0440\u043B\u0438\u043A\u0430",
  inside: "\u0412\u0441\u0435\u0440\u0435\u0434\u0438\u043D\u0456",
  outside: "\u0417\u0437\u043E\u0432\u043D\u0456",
  noDataToChart: "\u041D\u0435\u043C\u0430\u0454 \u0434\u0430\u043D\u0438\u0445 \u0434\u043B\u044F \u043F\u043E\u0431\u0443\u0434\u043E\u0432\u0438 \u0433\u0440\u0430\u0444\u0456\u043A\u0430.",
  pivotChartRequiresPivotMode: "\u0417\u0432\u0435\u0434\u0435\u043D\u0430 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0430 \u0432\u0438\u043C\u0430\u0433\u0430\u0454 \u0443\u0432\u0456\u043C\u043A\u043D\u0435\u043D\u043D\u044F \u0440\u0435\u0436\u0438\u043C\u0443 \u0437\u0432\u0435\u0434\u0435\u043D\u043D\u044F.",
  chartSettingsToolbarTooltip: "\u041C\u0435\u043D\u044E",
  chartLinkToolbarTooltip: "\u041F\u043E\u0432'\u044F\u0437\u0430\u043D\u043E \u0437 \u0441\u0456\u0442\u043A\u043E\u044E",
  chartUnlinkToolbarTooltip: "\u0412\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043E \u0432\u0456\u0434 \u0441\u0456\u0442\u043A\u0438",
  chartDownloadToolbarTooltip: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0443",
  chartMenuToolbarTooltip: "\u041C\u0435\u043D\u044E",
  chartEdit: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0443",
  chartAdvancedSettings: "\u0420\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u0456 \u043D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  chartLink: "\u0417\u0432'\u044F\u0437\u0430\u0442\u0438 \u0437 \u0441\u0456\u0442\u043A\u043E\u044E",
  chartUnlink: "\u0412\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438 \u0432\u0456\u0434 \u0441\u0456\u0442\u043A\u0438",
  chartDownload: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438 \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0443",
  histogramFrequency: "\u0427\u0430\u0441\u0442\u043E\u0442\u0430",
  seriesChartType: "\u0422\u0438\u043F \u0441\u0435\u0440\u0456\u0457 \u0433\u0440\u0430\u0444\u0456\u043A\u0456\u0432",
  seriesType: "\u0422\u0438\u043F \u0441\u0435\u0440\u0456\u0457",
  secondaryAxis: "\u0412\u0442\u043E\u0440\u0438\u043D\u043D\u0430 \u0432\u0456\u0441\u044C",
  seriesAdd: "\u0414\u043E\u0434\u0430\u0442\u0438 \u0441\u0435\u0440\u0456\u044E",
  categoryAdd: "\u0414\u043E\u0434\u0430\u0442\u0438 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0456\u044E",
  bar: "\u0421\u043C\u0443\u0433\u0430",
  column: "\u0421\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  histogram: "\u0413\u0456\u0441\u0442\u043E\u0433\u0440\u0430\u043C\u0430",
  advancedSettings: "\u0420\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u0456 \u043D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  direction: "\u041D\u0430\u043F\u0440\u044F\u043C\u043E\u043A",
  horizontal: "\u0413\u043E\u0440\u0438\u0437\u043E\u043D\u0442\u0430\u043B\u044C\u043D\u0438\u0439",
  vertical: "\u0412\u0435\u0440\u0442\u0438\u043A\u0430\u043B\u044C\u043D\u0438\u0439",
  seriesGroupType: "\u0422\u0438\u043F \u0433\u0440\u0443\u043F\u0438 \u0441\u0435\u0440\u0456\u0439",
  groupedSeriesGroupType: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0430",
  stackedSeriesGroupType: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  normalizedSeriesGroupType: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430",
  legendEnabled: "\u0423\u0432\u0456\u043C\u043A\u043D\u0435\u043D\u043E",
  invalidColor: "\u041D\u0435\u0434\u0456\u0439\u0441\u043D\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u043A\u043E\u043B\u044C\u043E\u0440\u0443",
  groupedColumnFull: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0438\u0439 \u0441\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  stackedColumnFull: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0438\u0439 \u0441\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  normalizedColumnFull: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0438\u0439 \u0441\u0442\u043E\u0432\u043F\u0435\u0446\u044C",
  groupedBarFull: "\u0413\u0440\u0443\u043F\u043E\u0432\u0430\u043D\u0430 \u0441\u043C\u0443\u0433\u0430",
  stackedBarFull: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430 \u0441\u043C\u0443\u0433\u0430",
  normalizedBarFull: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430 \u0441\u043C\u0443\u0433\u0430",
  stackedAreaFull: "\u0428\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442\u044C",
  normalizedAreaFull: "100% \u0448\u0442\u0430\u0431\u0435\u043B\u044C\u043E\u0432\u0430\u043D\u0430 \u043E\u0431\u043B\u0430\u0441\u0442\u044C",
  customCombo: "\u0421\u043F\u0435\u0446\u0456\u0430\u043B\u044C\u043D\u0430 \u043A\u043E\u043C\u0431\u0456\u043D\u0430\u0446\u0456\u044F",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u0420\u0456\u0432\u0435\u043D\u044C ${variable}. \u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ENTER \u0434\u043B\u044F \u0440\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u043D\u043D\u044F.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u0420\u0456\u0432\u0435\u043D\u044C ${variable}. ${variable} \u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ENTER \u0434\u043B\u044F \u0440\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u043D\u043D\u044F.",
  ariaAdvancedFilterBuilderList: "\u0421\u043F\u0438\u0441\u043E\u043A \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u0433\u043E \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaAdvancedFilterBuilderFilterItem: "\u0423\u043C\u043E\u0432\u0430 \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaAdvancedFilterBuilderGroupItem: "\u0413\u0440\u0443\u043F\u0430 \u0444\u0456\u043B\u044C\u0442\u0440\u0456\u0432",
  ariaAdvancedFilterBuilderColumn: "\u041A\u043E\u043B\u043E\u043D\u043A\u0430",
  ariaAdvancedFilterBuilderOption: "\u041E\u043F\u0446\u0456\u044F",
  ariaAdvancedFilterBuilderValueP: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  ariaAdvancedFilterBuilderJoinOperator: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0437'\u0454\u0434\u043D\u0430\u043D\u043D\u044F",
  ariaAdvancedFilterInput: "\u0412\u0432\u0456\u0434 \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u0433\u043E \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaChecked: "\u0432\u0456\u0434\u043C\u0456\u0447\u0435\u043D\u043E",
  ariaColumn: "\u041A\u043E\u043B\u043E\u043D\u043A\u0430",
  ariaColumnGroup: "\u0413\u0440\u0443\u043F\u0430 \u043A\u043E\u043B\u043E\u043D\u043E\u043A",
  ariaColumnFiltered: "\u041A\u043E\u043B\u043E\u043D\u043A\u0430 \u0432\u0456\u0434\u0444\u0456\u043B\u044C\u0442\u0440\u043E\u0432\u0430\u043D\u0430",
  ariaColumnSelectAll: "\u041F\u0435\u0440\u0435\u043A\u043B\u044E\u0447\u0438\u0442\u0438 \u0432\u0438\u0431\u0456\u0440 \u0443\u0441\u0456\u0445 \u043A\u043E\u043B\u043E\u043D\u043E\u043A",
  ariaDateFilterInput: "\u0412\u0432\u0456\u0434 \u0444\u0456\u043B\u044C\u0442\u0440\u0443 \u0437\u0430 \u0434\u0430\u0442\u043E\u044E",
  ariaDefaultListName: "\u0421\u043F\u0438\u0441\u043E\u043A",
  ariaFilterColumnsInput: "\u0412\u0432\u0456\u0434 \u043A\u043E\u043B\u043E\u043D\u043E\u043A \u0434\u043B\u044F \u0444\u0456\u043B\u044C\u0442\u0440\u0443\u0432\u0430\u043D\u043D\u044F",
  ariaFilterFromValue: "\u0424\u0456\u043B\u044C\u0442\u0440\u0443\u0432\u0430\u0442\u0438 \u0432\u0456\u0434 \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  ariaFilterInput: "\u0412\u0432\u0456\u0434 \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaFilterList: "\u0421\u043F\u0438\u0441\u043E\u043A \u0444\u0456\u043B\u044C\u0442\u0440\u0456\u0432",
  ariaFilterToValue: "\u0424\u0456\u043B\u044C\u0442\u0440\u0443\u0432\u0430\u0442\u0438 \u0434\u043E \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  ariaFilterValue: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaFilterMenuOpen: "\u0412\u0456\u0434\u043A\u0440\u0438\u0442\u0438 \u043C\u0435\u043D\u044E \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaFilteringOperator: "\u041E\u043F\u0435\u0440\u0430\u0442\u043E\u0440 \u0444\u0456\u043B\u044C\u0442\u0440\u0443\u0432\u0430\u043D\u043D\u044F",
  ariaHidden: "\u043F\u0440\u0438\u0445\u043E\u0432\u0430\u043D\u043E",
  ariaIndeterminate: "\u043D\u0435\u0432\u0438\u0437\u043D\u0430\u0447\u0435\u043D\u043E",
  ariaInputEditor: "\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440 \u0432\u0432\u043E\u0434\u0443",
  ariaMenuColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ALT DOWN \u0434\u043B\u044F \u0432\u0456\u0434\u043A\u0440\u0438\u0442\u0442\u044F \u043C\u0435\u043D\u044E \u043A\u043E\u043B\u043E\u043D\u043E\u043A",
  ariaFilterColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C CTRL ENTER \u0434\u043B\u044F \u0432\u0456\u0434\u043A\u0440\u0438\u0442\u0442\u044F \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaRowDeselect: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C SPACE \u0434\u043B\u044F \u0437\u043D\u044F\u0442\u0442\u044F \u0432\u0438\u0434\u0456\u043B\u0435\u043D\u043D\u044F \u0437 \u0446\u044C\u043E\u0433\u043E \u0440\u044F\u0434\u043A\u0430",
  ariaRowSelectAll: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C Space \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043C\u0438\u043A\u0430\u043D\u043D\u044F \u0432\u0438\u0431\u043E\u0440\u0443 \u0432\u0441\u0456\u0445 \u0440\u044F\u0434\u043A\u0456\u0432",
  ariaRowToggleSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C Space \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043C\u0438\u043A\u0430\u043D\u043D\u044F \u0432\u0438\u0431\u043E\u0440\u0443 \u0440\u044F\u0434\u043A\u0430",
  ariaRowSelect: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C SPACE \u0434\u043B\u044F \u0432\u0438\u0431\u043E\u0440\u0443 \u0446\u044C\u043E\u0433\u043E \u0440\u044F\u0434\u043A\u0430",
  ariaRowSelectionDisabled: "\u0412\u0438\u0431\u0456\u0440 \u0440\u044F\u0434\u043A\u0430 \u0432\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043E \u0434\u043B\u044F \u0446\u044C\u043E\u0433\u043E \u0440\u044F\u0434\u043A\u0430",
  ariaSearch: "\u041F\u043E\u0448\u0443\u043A",
  ariaSortableColumn: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ENTER \u0434\u043B\u044F \u0441\u043E\u0440\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  ariaToggleVisibility: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C SPACE \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043C\u0438\u043A\u0430\u043D\u043D\u044F \u0432\u0438\u0434\u0438\u043C\u043E\u0441\u0442\u0456",
  ariaToggleCellValue: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C SPACE \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043C\u0438\u043A\u0430\u043D\u043D\u044F \u0437\u043D\u0430\u0447\u0435\u043D\u043D\u044F \u043A\u043E\u043C\u0456\u0440\u043A\u0438",
  ariaUnchecked: "\u043D\u0435 \u0432\u0456\u0434\u043C\u0456\u0447\u0435\u043D\u043E",
  ariaVisible: "\u0432\u0438\u0434\u0438\u043C\u043E",
  ariaSearchFilterValues: "\u041F\u043E\u0448\u0443\u043A \u0437\u043D\u0430\u0447\u0435\u043D\u044C \u0444\u0456\u043B\u044C\u0442\u0440\u0443",
  ariaPageSizeSelectorLabel: "\u0420\u043E\u0437\u043C\u0456\u0440 \u0441\u0442\u043E\u0440\u0456\u043D\u043A\u0438",
  ariaChartMenuClose: "\u0417\u0430\u043A\u0440\u0438\u0442\u0438 \u043C\u0435\u043D\u044E \u0440\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u043D\u043D\u044F \u0434\u0456\u0430\u0433\u0440\u0430\u043C\u0438",
  ariaChartSelected: "\u0412\u0438\u0431\u0440\u0430\u043D\u043E",
  ariaSkeletonCellLoadingFailed: "\u041F\u043E\u043C\u0438\u043B\u043A\u0430 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F \u0440\u044F\u0434\u043A\u0430",
  ariaSkeletonCellLoading: "\u0414\u0430\u043D\u0456 \u0440\u044F\u0434\u043A\u0430 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0443\u044E\u0442\u044C\u0441\u044F",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u0413\u0440\u0443\u043F\u0438 \u0440\u044F\u0434\u043A\u0456\u0432",
  ariaValuesDropZonePanelLabel: "\u0417\u043D\u0430\u0447\u0435\u043D\u043D\u044F",
  ariaPivotDropZonePanelLabel: "\u041C\u0456\u0442\u043A\u0438 \u0441\u0442\u043E\u0432\u043F\u0446\u0456\u0432",
  ariaDropZoneColumnComponentDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C DELETE, \u0449\u043E\u0431 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  ariaDropZoneColumnValueItemDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ENTER, \u0449\u043E\u0431 \u0437\u043C\u0456\u043D\u0438\u0442\u0438 \u0442\u0438\u043F \u0430\u0433\u0440\u0435\u0433\u0430\u0446\u0456\u0457",
  ariaDropZoneColumnGroupItemDescription: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C ENTER, \u0449\u043E\u0431 \u0441\u043E\u0440\u0442\u0443\u0432\u0430\u0442\u0438",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u0437 ",
  ariaDropZoneColumnComponentSortAscending: "\u0441\u043F\u0430\u0434\u0430\u043D\u043D\u044F",
  ariaDropZoneColumnComponentSortDescending: "\u0437\u0440\u043E\u0441\u0442\u0430\u043D\u043D\u044F",
  ariaLabelDialog: "\u0414\u0456\u0430\u043B\u043E\u0433",
  ariaLabelColumnMenu: "\u041C\u0435\u043D\u044E \u0441\u0442\u043E\u0432\u043F\u0446\u044F",
  ariaLabelColumnFilter: "\u0424\u0456\u043B\u044C\u0442\u0440 \u0441\u0442\u043E\u0432\u043F\u0446\u044F",
  ariaLabelCellEditor: "\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440 \u043A\u043B\u0456\u0442\u0438\u043D\u043E\u043A",
  ariaLabelSelectField: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u043F\u043E\u043B\u0435",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u041F\u043E\u043B\u0435 \u0432\u0438\u0431\u043E\u0440\u0443",
  ariaLabelRichSelectToggleSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C \u041F\u0420\u041E\u041F\u0423\u0421\u041A \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044F \u0432\u0438\u0431\u043E\u0440\u0443",
  ariaLabelRichSelectDeselectAllItems: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C DELETE \u0434\u043B\u044F \u0441\u043A\u0430\u0441\u0443\u0432\u0430\u043D\u043D\u044F \u0432\u0438\u0431\u043E\u0440\u0443 \u0432\u0441\u0456\u0445 \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0456\u0432",
  ariaLabelRichSelectDeleteSelection: "\u041D\u0430\u0442\u0438\u0441\u043D\u0456\u0442\u044C DELETE \u0434\u043B\u044F \u0441\u043A\u0430\u0441\u0443\u0432\u0430\u043D\u043D\u044F \u0432\u0438\u0431\u043E\u0440\u0443 \u0435\u043B\u0435\u043C\u0435\u043D\u0442\u0430",
  ariaLabelTooltip: "\u041F\u0456\u0434\u043A\u0430\u0437\u043A\u0430",
  ariaLabelContextMenu: "\u041A\u043E\u043D\u0442\u0435\u043A\u0441\u0442\u043D\u0435 \u043C\u0435\u043D\u044E",
  ariaLabelSubMenu: "\u041F\u0456\u0434\u043C\u0435\u043D\u044E",
  ariaLabelAggregationFunction: "\u0424\u0443\u043D\u043A\u0446\u0456\u044F \u0430\u0433\u0440\u0435\u0433\u0430\u0446\u0456\u0457",
  ariaLabelAdvancedFilterAutocomplete: "\u0410\u0432\u0442\u043E\u0437\u0430\u043F\u043E\u0432\u043D\u0435\u043D\u043D\u044F \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u0433\u043E \u0444\u0456\u043B\u044C\u0442\u0440\u0430",
  ariaLabelAdvancedFilterBuilderAddField: "\u0414\u043E\u0434\u0430\u0442\u0438 \u043F\u043E\u043B\u0435 \u0432 \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u0438\u0439 \u0444\u0456\u043B\u044C\u0442\u0440",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u0412\u0438\u0431\u0456\u0440 \u043A\u043E\u043B\u043E\u043D\u043A\u0438 \u0432 \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u043C\u0443 \u0444\u0456\u043B\u044C\u0442\u0440\u0456",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u0412\u0438\u0431\u0440\u0430\u0442\u0438 \u043E\u043F\u0446\u0456\u044E \u0432 \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u043C\u0443 \u0444\u0456\u043B\u044C\u0442\u0440\u0456",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u0412\u0438\u0431\u0456\u0440 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u0430 \u043F\u0440\u0438\u0454\u0434\u043D\u0430\u043D\u043D\u044F \u0432 \u0440\u043E\u0437\u0448\u0438\u0440\u0435\u043D\u043E\u043C\u0443 \u0444\u0456\u043B\u044C\u0442\u0440\u0456",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u0421\u043F\u0438\u0441\u043E\u043A \u043A\u043E\u043B\u043E\u043D\u043E\u043A",
  ariaFilterPanelList: "\u0421\u043F\u0438\u0441\u043E\u043A \u0444\u0456\u043B\u044C\u0442\u0440\u0456\u0432",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: "\xA0",
  decimalSeparator: ",",
  // Data types
  true: "\u041F\u0440\u0430\u0432\u0434\u0430",
  false: "\u041D\u0435\u043F\u0440\u0430\u0432\u0434\u0430",
  invalidDate: "\u041D\u0435\u0432\u0456\u0440\u043D\u0430 \u0434\u0430\u0442\u0430",
  invalidNumber: "\u041D\u0435\u0432\u0456\u0440\u043D\u0438\u0439 \u043D\u043E\u043C\u0435\u0440",
  january: "\u0421\u0456\u0447\u0435\u043D\u044C",
  february: "\u041B\u044E\u0442\u0438\u0439",
  march: "\u0411\u0435\u0440\u0435\u0437\u0435\u043D\u044C",
  april: "\u041A\u0432\u0456\u0442\u0435\u043D\u044C",
  may: "\u0422\u0440\u0430\u0432\u0435\u043D\u044C",
  june: "\u0427\u0435\u0440\u0432\u0435\u043D\u044C",
  july: "\u041B\u0438\u043F\u0435\u043D\u044C",
  august: "\u0421\u0435\u0440\u043F\u0435\u043D\u044C",
  september: "\u0412\u0435\u0440\u0435\u0441\u0435\u043D\u044C",
  october: "\u0416\u043E\u0432\u0442\u0435\u043D\u044C",
  november: "\u041B\u0438\u0441\u0442\u043E\u043F\u0430\u0434",
  december: "\u0413\u0440\u0443\u0434\u0435\u043D\u044C",
  // Time formats
  timeFormatSlashesDDMMYYYY: "\u0414\u0414/\u041C\u041C/\u0420\u0420\u0420\u0420",
  timeFormatSlashesMMDDYYYY: "\u041C\u041C/\u0414\u0414/\u0420\u0420\u0420\u0420",
  timeFormatSlashesDDMMYY: "\u0414\u0414/\u041C\u041C/\u0420\u0420",
  timeFormatSlashesMMDDYY: "\u041C\u041C/\u0414\u0414/\u0420\u0420",
  timeFormatDotsDDMYY: "\u0414\u0414.\u041C.\u0420\u0420",
  timeFormatDotsMDDYY: "\u041C.\u0414\u0414.\u0420\u0420",
  timeFormatDashesYYYYMMDD: "\u0420\u0420\u0420\u0420-\u041C\u041C-\u0414\u0414",
  timeFormatSpacesDDMMMMYYYY: "\u0414\u0414 \u041C\u041C\u041C\u041C \u0420\u0420\u0420\u0420",
  timeFormatHHMMSS: "\u0413\u0413:\u0425\u0425:\u0421\u0421",
  timeFormatHHMMSSAmPm: "\u0413\u0413:\u0425\u0425:\u0421\u0421 \u0414\u041F/\u041F\u041F"
};

// community-modules/locale/src/ur-PK.ts
var AG_GRID_LOCALE_PK = {
  // Set Filter
  selectAll: "(\u0633\u0628 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA)",
  selectAllSearchResults: "(\u062A\u0645\u0627\u0645 \u062A\u0644\u0627\u0634 \u06A9\u06D2 \u0646\u062A\u0627\u0626\u062C \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA)",
  addCurrentSelectionToFilter: "\u0645\u0648\u062C\u0648\u062F\u06C1 \u0627\u0646\u062A\u062E\u0627\u0628 \u06A9\u0648 \u0641\u0644\u0679\u0631 \u0645\u06CC\u06BA \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  searchOoo: "\u062A\u0644\u0627\u0634 \u06A9\u0631\u06CC\u06BA...",
  blanks: "(\u062E\u0627\u0644\u06CC)",
  noMatches: "\u06A9\u0648\u0626\u06CC \u0645\u0645\u0627\u062B\u0644\u062A \u0646\u06C1\u06CC\u06BA",
  // Number Filter & Text Filter
  filterOoo: "\u0641\u0644\u0679\u0631...",
  equals: "\u0628\u0631\u0627\u0628\u0631",
  notEqual: "\u0628\u0631\u0627\u0628\u0631 \u0646\u06C1\u06CC\u06BA",
  blank: "\u062E\u0627\u0644\u06CC",
  notBlank: "\u062E\u0627\u0644\u06CC \u0646\u06C1\u06CC\u06BA",
  empty: "\u0627\u06CC\u06A9 \u06A9\u0648 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA",
  // Number Filter
  lessThan: "\u0633\u06D2 \u06A9\u0645",
  greaterThan: "\u0633\u06D2 \u0632\u06CC\u0627\u062F\u06C1",
  lessThanOrEqual: "\u0633\u06D2 \u06A9\u0645 \u06CC\u0627 \u0628\u0631\u0627\u0628\u0631",
  greaterThanOrEqual: "\u0633\u06D2 \u0632\u06CC\u0627\u062F\u06C1 \u06CC\u0627 \u0628\u0631\u0627\u0628\u0631",
  inRange: "\u062F\u0631\u0645\u06CC\u0627\u0646",
  inRangeStart: "\u0633\u06D2",
  inRangeEnd: "\u062A\u06A9",
  // Text Filter
  contains: "\u0634\u0627\u0645\u0644 \u06C1\u06D2",
  notContains: "\u0634\u0627\u0645\u0644 \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  startsWith: "\u0634\u0631\u0648\u0639 \u06C1\u0648\u062A\u0627 \u06C1\u06D2",
  endsWith: "\u062E\u062A\u0645 \u06C1\u0648\u062A\u0627 \u06C1\u06D2",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "\u067E\u06C1\u0644\u06D2",
  after: "\u0628\u0639\u062F",
  // Filter Conditions
  andCondition: "\u0627\u0648\u0631",
  orCondition: "\u06CC\u0627",
  // Filter Buttons
  applyFilter: "\u0644\u0627\u06AF\u0648 \u06A9\u0631\u06CC\u06BA",
  resetFilter: "\u0631\u06CC \u0633\u06CC\u0679 \u06A9\u0631\u06CC\u06BA",
  clearFilter: "\u0635\u0627\u0641 \u06A9\u0631\u06CC\u06BA",
  cancelFilter: "\u0645\u0646\u0633\u0648\u062E \u06A9\u0631\u06CC\u06BA",
  // Filter Titles
  textFilter: "\u0645\u062A\u0646 \u0641\u0644\u0679\u0631",
  numberFilter: "\u0646\u0645\u0628\u0631 \u0641\u0644\u0679\u0631",
  dateFilter: "\u062A\u0627\u0631\u06CC\u062E \u0641\u0644\u0679\u0631",
  setFilter: "\u0633\u06CC\u0679 \u0641\u0644\u0679\u0631",
  // Group Column Filter
  groupFilterSelect: "\u0641\u06CC\u0644\u0688 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA:",
  // Advanced Filter
  advancedFilterContains: "\u0634\u0627\u0645\u0644 \u06C1\u06D2",
  advancedFilterNotContains: "\u0634\u0627\u0645\u0644 \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  advancedFilterTextEquals: "\u0628\u0631\u0627\u0628\u0631 \u06C1\u06D2",
  advancedFilterTextNotEqual: "\u0628\u0631\u0627\u0628\u0631 \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  advancedFilterStartsWith: "\u0634\u0631\u0648\u0639 \u06C1\u0648\u062A\u0627 \u06C1\u06D2",
  advancedFilterEndsWith: "\u062E\u062A\u0645 \u06C1\u0648\u062A\u0627 \u06C1\u06D2",
  advancedFilterBlank: "\u062E\u0627\u0644\u06CC \u06C1\u06D2",
  advancedFilterNotBlank: "\u062E\u0627\u0644\u06CC \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u062F\u0631\u0633\u062A \u06C1\u06D2",
  advancedFilterFalse: "\u063A\u0644\u0637 \u06C1\u06D2",
  advancedFilterAnd: "\u0627\u0648\u0631",
  advancedFilterOr: "\u06CC\u0627",
  advancedFilterApply: "\u0644\u0627\u06AF\u0648 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilder: "\u0628\u0644\u0688\u0631",
  advancedFilterValidationMissingColumn: "\u06A9\u0627\u0644\u0645 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationMissingOption: "\u0622\u067E\u0634\u0646 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationMissingValue: "\u0642\u062F\u0631 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationInvalidColumn: "\u06A9\u0627\u0644\u0645 \u0646\u06C1\u06CC\u06BA \u0645\u0644\u0627",
  advancedFilterValidationInvalidOption: "\u0622\u067E\u0634\u0646 \u0646\u06C1\u06CC\u06BA \u0645\u0644\u0627",
  advancedFilterValidationMissingQuote: "\u0642\u062F\u0631 \u06A9\u06D2 \u0622\u062E\u0631 \u0645\u06CC\u06BA \u0627\u0642\u062A\u0628\u0627\u0633 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationNotANumber: "\u0642\u062F\u0631 \u0627\u06CC\u06A9 \u0646\u0645\u0628\u0631 \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  advancedFilterValidationInvalidDate: "\u0642\u062F\u0631 \u0627\u06CC\u06A9 \u062C\u0627\u0626\u0632 \u062A\u0627\u0631\u06CC\u062E \u0646\u06C1\u06CC\u06BA \u06C1\u06D2",
  advancedFilterValidationMissingCondition: "\u0634\u0631\u0637 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationJoinOperatorMismatch: "\u0627\u06CC\u06A9 \u0634\u0631\u0637 \u0645\u06CC\u06BA \u062C\u0648\u0627\u0626\u0646 \u0622\u067E\u0631\u06CC\u0679\u0631\u0632 \u0627\u06CC\u06A9 \u062C\u06CC\u0633\u06D2 \u06C1\u0648\u0646\u06D2 \u0686\u0627\u06C1\u06CC\u06D2",
  advancedFilterValidationInvalidJoinOperator: "\u062C\u0648\u0627\u0626\u0646 \u0622\u067E\u0631\u06CC\u0679\u0631 \u0646\u06C1\u06CC\u06BA \u0645\u0644\u0627",
  advancedFilterValidationMissingEndBracket: "\u0627\u062E\u062A\u062A\u0627\u0645\u06CC \u0628\u0631\u06CC\u06A9\u0679 \u063A\u0627\u0626\u0628 \u06C1\u06D2",
  advancedFilterValidationExtraEndBracket: "\u0632\u06CC\u0627\u062F\u06C1 \u0627\u062E\u062A\u062A\u0627\u0645\u06CC \u0628\u0631\u06CC\u06A9\u0679\u0633",
  advancedFilterValidationMessage: "\u0627\u0638\u06C1\u0627\u0631 \u0645\u06CC\u06BA \u0627\u06CC\u06A9 \u0646\u0642\u0635 \u06C1\u06D2. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "\u0627\u0638\u06C1\u0627\u0631 \u0645\u06CC\u06BA \u0627\u06CC\u06A9 \u0646\u0642\u0635 \u06C1\u06D2. ${variable} \u0627\u0638\u06C1\u0627\u0631 \u06A9\u06D2 \u0622\u062E\u0631 \u0645\u06CC\u06BA.",
  advancedFilterBuilderTitle: "\u0627\u06CC\u0688\u0648\u0627\u0646\u0633\u0688 \u0641\u0644\u0679\u0631",
  advancedFilterBuilderApply: "\u0644\u0627\u06AF\u0648 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderCancel: "\u0645\u0646\u0633\u0648\u062E \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderAddButtonTooltip: "\u0641\u0644\u0679\u0631 \u06CC\u0627 \u06AF\u0631\u0648\u067E \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderRemoveButtonTooltip: "\u06C1\u0679\u0627 \u062F\u06CC\u06BA",
  advancedFilterBuilderMoveUpButtonTooltip: "\u0627\u0648\u067E\u0631 \u0645\u0646\u062A\u0642\u0644 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderMoveDownButtonTooltip: "\u0646\u06CC\u0686\u06D2 \u0645\u0646\u062A\u0642\u0644 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderAddJoin: "\u06AF\u0631\u0648\u067E \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderAddCondition: "\u0641\u0644\u0679\u0631 \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderSelectColumn: "\u0627\u06CC\u06A9 \u06A9\u0627\u0644\u0645 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderSelectOption: "\u0627\u06CC\u06A9 \u0622\u067E\u0634\u0646 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA",
  advancedFilterBuilderEnterValue: "\u0627\u06CC\u06A9 \u0642\u062F\u0631 \u062F\u0631\u062C \u06A9\u0631\u06CC\u06BA...",
  advancedFilterBuilderValidationAlreadyApplied: "\u0645\u0648\u062C\u0648\u062F\u06C1 \u0641\u0644\u0679\u0631 \u067E\u06C1\u0644\u06D2 \u0633\u06D2 \u0644\u0627\u06AF\u0648 \u06C1\u06D2.",
  advancedFilterBuilderValidationIncomplete: "\u062A\u0645\u0627\u0645 \u0634\u0631\u0627\u0626\u0637 \u067E\u0648\u0631\u06CC \u0646\u06C1\u06CC\u06BA \u06C1\u0648\u0626\u06CC\u06BA.",
  advancedFilterBuilderValidationSelectColumn: "\u0627\u06CC\u06A9 \u06A9\u0627\u0644\u0645 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u0627 \u0636\u0631\u0648\u0631\u06CC \u06C1\u06D2.",
  advancedFilterBuilderValidationSelectOption: "\u0627\u06CC\u06A9 \u0622\u067E\u0634\u0646 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u0627 \u0636\u0631\u0648\u0631\u06CC \u06C1\u06D2.",
  advancedFilterBuilderValidationEnterValue: "\u0627\u06CC\u06A9 \u0642\u062F\u0631 \u062F\u0631\u062C \u06A9\u0631\u0646\u0627 \u0636\u0631\u0648\u0631\u06CC \u06C1\u06D2.",
  // Side Bar
  columns: "\u06A9\u0627\u0644\u0645\u0632",
  filters: "\u0641\u0644\u0679\u0631\u0632",
  // columns tool panel
  pivotMode: "\u067E\u06CC\u0648\u06CC\u0679 \u0645\u0648\u0688",
  groups: "\u0631\u0648 \u06AF\u0631\u0648\u067E",
  rowGroupColumnsEmptyMessage: "\u0631\u0648 \u06AF\u0631\u0648\u067E \u0633\u06CC\u0679 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 \u06CC\u06C1\u0627\u06BA \u06AF\u06BE\u0633\u06CC\u0679\u06CC\u06BA",
  values: "\u0642\u062F\u0631\u06CC\u06BA",
  valueColumnsEmptyMessage: "\u062C\u0645\u0639 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 \u06CC\u06C1\u0627\u06BA \u06AF\u06BE\u0633\u06CC\u0679\u06CC\u06BA",
  pivots: "\u06A9\u0627\u0644\u0645 \u0644\u06CC\u0628\u0644",
  pivotColumnsEmptyMessage: "\u06A9\u0627\u0644\u0645 \u0644\u06CC\u0628\u0644 \u0633\u06CC\u0679 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 \u06CC\u06C1\u0627\u06BA \u06AF\u06BE\u0633\u06CC\u0679\u06CC\u06BA",
  // Header of the Default Group Column
  group: "\u06AF\u0631\u0648\u067E",
  // Row Drag
  rowDragRow: "\u0642\u0637\u0627\u0631",
  rowDragRows: "\u0642\u0637\u0627\u0631\u06CC\u06BA",
  // Other
  loadingOoo: "\u0644\u0648\u0688 \u06C1\u0648 \u0631\u06C1\u0627 \u06C1\u06D2 ...",
  loadingError: "\u063A\u0644\u0637\u06CC",
  noRowsToShow: "\u062F\u06A9\u06BE\u0627\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 \u06A9\u0648\u0626\u06CC \u0642\u0637\u0627\u0631 \u0646\u06C1\u06CC\u06BA",
  enabled: "\u0641\u0639\u0627\u0644",
  // Menu
  pinColumn: "\u06A9\u0627\u0644\u0645 \u06A9\u0648 \u067E\u0646 \u06A9\u0631\u06CC\u06BA",
  pinLeft: "\u0628\u0627\u0626\u06CC\u06BA \u067E\u0646 \u06A9\u0631\u06CC\u06BA",
  pinRight: "\u062F\u0627\u0626\u06CC\u06BA \u067E\u0646 \u06A9\u0631\u06CC\u06BA",
  noPin: "\u06A9\u0648\u0626\u06CC \u067E\u0646 \u0646\u06C1\u06CC\u06BA",
  valueAggregation: "\u0648\u06CC\u0644\u06CC\u0648 \u0627\u06CC\u06AF\u0631\u06CC\u06AF\u06CC\u0634\u0646",
  noAggregation: "\u06A9\u0648\u0626\u06CC \u0646\u06C1\u06CC\u06BA",
  autosizeThisColumn: "\u0627\u0633 \u06A9\u0627\u0644\u0645 \u06A9\u06D2 \u0633\u0627\u0626\u0632 \u06A9\u0648 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0628\u0646\u0627\u0626\u06CC\u06BA",
  autosizeAllColumns: "\u062A\u0645\u0627\u0645 \u06A9\u0627\u0644\u0645 \u06A9\u06D2 \u0633\u0627\u0626\u0632 \u06A9\u0648 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0628\u0646\u0627\u0626\u06CC\u06BA",
  groupBy: "\u06AF\u0631\u0648\u067E \u0628\u0646\u062F\u06CC \u06A9\u06D2 \u0630\u0631\u06CC\u0639\u06D2",
  ungroupBy: "\u06AF\u0631\u0648\u067E \u06A9\u0648 \u062E\u062A\u0645 \u06A9\u0631\u06CC\u06BA",
  ungroupAll: "\u062A\u0645\u0627\u0645 \u06AF\u0631\u0648\u067E \u0628\u0646\u062F\u06CC \u06A9\u0648 \u062E\u062A\u0645 \u06A9\u0631\u06CC\u06BA",
  addToValues: "${variable} \u06A9\u0648 \u0648\u06CC\u0644\u06CC\u0648\u0632 \u0645\u06CC\u06BA \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  removeFromValues: "${variable} \u06A9\u0648 \u0648\u06CC\u0644\u06CC\u0648\u0632 \u0633\u06D2 \u06C1\u0679\u0627\u0626\u06CC\u06BA",
  addToLabels: "${variable} \u06A9\u0648 \u0644\u06CC\u0628\u0644\u0632 \u0645\u06CC\u06BA \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  removeFromLabels: "${variable} \u06A9\u0648 \u0644\u06CC\u0628\u0644\u0632 \u0633\u06D2 \u06C1\u0679\u0627\u0626\u06CC\u06BA",
  resetColumns: "\u06A9\u0627\u0644\u0645\u0632 \u06A9\u0648 \u0631\u06CC \u0633\u06CC\u0679 \u06A9\u0631\u06CC\u06BA",
  expandAll: "\u062A\u0645\u0627\u0645 \u0642\u0637\u0627\u0631\u0648\u06BA \u06A9\u06D2 \u06AF\u0631\u0648\u067E \u06A9\u0648 \u067E\u06BE\u06CC\u0644\u0627\u0626\u06CC\u06BA",
  collapseAll: "\u062A\u0645\u0627\u0645 \u0642\u0637\u0627\u0631\u0648\u06BA \u06A9\u06D2 \u06AF\u0631\u0648\u067E \u06A9\u0648 \u0628\u0646\u062F \u06A9\u0631\u06CC\u06BA",
  copy: "\u06A9\u0627\u067E\u06CC",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "\u06C1\u06CC\u0688\u0631\u0632 \u06A9\u06D2 \u0633\u0627\u062A\u06BE \u06A9\u0627\u067E\u06CC \u06A9\u0631\u06CC\u06BA",
  copyWithGroupHeaders: "\u06AF\u0631\u0648\u067E \u06C1\u06CC\u0688\u0631\u0632 \u06A9\u06D2 \u0633\u0627\u062A\u06BE \u06A9\u0627\u067E\u06CC \u06A9\u0631\u06CC\u06BA",
  cut: "\u06A9\u0679",
  paste: "\u067E\u06CC\u0633\u0679",
  ctrlV: "Ctrl+V",
  export: "\u0627\u06CC\u06A9\u0633\u067E\u0648\u0631\u0679",
  csvExport: "CSV \u0627\u06CC\u06A9\u0633\u067E\u0648\u0631\u0679",
  excelExport: "Excel \u0627\u06CC\u06A9\u0633\u067E\u0648\u0631\u0679",
  columnFilter: "\u06A9\u0627\u0644\u0645 \u0641\u0644\u0679\u0631",
  columnChooser: "\u06A9\u0627\u0644\u0645\u0632 \u06A9\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u06A9\u0631\u06CC\u06BA",
  chooseColumns: "\u06A9\u0627\u0644\u0645\u0632 \u06A9\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u06A9\u0631\u06CC\u06BA",
  sortAscending: "\u0627\u0653\u0631\u0648\u06C1\u06CC\u0628\u06BE\u06A9 \u062A\u0631\u062A\u06CC\u0628",
  sortDescending: "\u0632\u0648\u0627\u0644 \u067E\u0630\u06CC\u0631 \u062A\u0631\u062A\u06CC\u0628",
  sortUnSort: "\u062A\u0631\u062A\u06CC\u0628 \u0645\u0679\u0627\u0626\u06CC\u06BA",
  // Enterprise Menu Aggregation and Status Bar
  sum: "\u0645\u062C\u0645\u0648\u0639\u06C1",
  first: "\u067E\u06C1\u0644\u0627",
  last: "\u0622\u062E\u0631\u06CC",
  min: "\u06A9\u0645 \u0633\u06D2 \u06A9\u0645",
  max: "\u0632\u06CC\u0627\u062F\u06C1 \u0633\u06D2 \u0632\u06CC\u0627\u062F\u06C1",
  none: "\u06A9\u0648\u0626\u06CC \u0646\u06C1\u06CC\u06BA",
  count: "\u0634\u0645\u0627\u0631",
  avg: "\u0627\u0648\u0633\u0637",
  filteredRows: "\u0641\u0644\u0679\u0631\u0688",
  selectedRows: "\u0645\u0646\u062A\u062E\u0628",
  totalRows: "\u06A9\u0644 \u0642\u0637\u0627\u0631\u06CC\u06BA",
  totalAndFilteredRows: "\u0642\u0637\u0627\u0631\u06CC\u06BA",
  more: "\u0645\u0632\u06CC\u062F",
  to: "\u06A9\u0648",
  of: "\u06A9\u0627",
  page: "\u0635\u0641\u062D\u06C1",
  pageLastRowUnknown: "?",
  nextPage: "\u0627\u06AF\u0644\u0627 \u0635\u0641\u062D\u06C1",
  lastPage: "\u0622\u062E\u0631\u06CC \u0635\u0641\u062D\u06C1",
  firstPage: "\u067E\u06C1\u0644\u0627 \u0635\u0641\u062D\u06C1",
  previousPage: "\u067E\u0686\u06BE\u0644\u0627 \u0635\u0641\u062D\u06C1",
  pageSizeSelectorLabel: "\u0635\u0641\u062D\u06C1 \u06A9\u0627 \u0633\u0627\u0626\u0632:",
  footerTotal: "\u06A9\u0644",
  // Pivoting
  pivotColumnGroupTotals: "\u06A9\u0644",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "\u067E\u06CC\u0648\u0679 \u0686\u0627\u0631\u0679 \u0627\u0648\u0631 \u067E\u06CC\u0648\u0679 \u0645\u0648\u0688",
  pivotChart: "\u067E\u06CC\u0648\u0679 \u0686\u0627\u0631\u0679",
  chartRange: "\u0686\u0627\u0631\u0679 \u0631\u06CC\u0646\u062C",
  columnChart: "\u06A9\u0627\u0644\u0645",
  groupedColumn: "\u06AF\u0631\u0648\u067E\u0688",
  stackedColumn: "\u0627\u0633\u0679\u06CC\u06A9\u0688",
  normalizedColumn: "100% \u0627\u0633\u0679\u06CC\u06A9\u0688",
  barChart: "\u0628\u0627\u0631",
  groupedBar: "\u06AF\u0631\u0648\u067E\u0688",
  stackedBar: "\u0627\u0633\u0679\u06CC\u06A9\u0688",
  normalizedBar: "100% \u0627\u0633\u0679\u06CC\u06A9\u0688",
  pieChart: "\u067E\u0627\u0626\u06CC",
  pie: "\u067E\u0627\u0626\u06CC",
  donut: "\u0688\u0648\u0646\u0679",
  line: "\u0644\u0627\u0626\u0646",
  xyChart: "\u0627\u06CC\u06A9\u0633 \u0648\u0627\u0626\u06CC (\u0633\u06A9\u06CC\u0679\u0631)",
  scatter: "\u0633\u06A9\u06CC\u0679\u0631",
  bubble: "\u0628\u0628\u0644",
  areaChart: "\u0627\u06CC\u0631\u06CC\u0627",
  area: "\u0627\u06CC\u0631\u06CC\u0627",
  stackedArea: "\u0627\u0633\u0679\u06CC\u06A9\u0688",
  normalizedArea: "100% \u0627\u0633\u0679\u06CC\u06A9\u0688",
  histogramChart: "\u06C1\u0633\u0679\u0648\u06AF\u0631\u0627\u0645",
  polarChart: "\u067E\u0648\u0644\u0631",
  radarLine: "\u0631\u0627\u0688\u0627\u0631 \u0644\u0627\u0626\u0646",
  radarArea: "\u0631\u0627\u0688\u0627\u0631 \u0627\u06CC\u0631\u06CC\u0627",
  nightingale: "\u0646\u0627\u0626\u0679\u06CC\u0646\u06AF\u06CC\u0644",
  radialColumn: "\u0631\u06CC\u0688\u06CC\u0644 \u06A9\u0627\u0644\u0645",
  radialBar: "\u0631\u06CC\u0688\u06CC\u0644 \u0628\u0627\u0631",
  statisticalChart: "\u0634\u0645\u0627\u0631\u06CC\u0627\u062A\u06CC",
  boxPlot: "\u0628\u0627\u06A9\u0633 \u067E\u0644\u0627\u0679",
  rangeBar: "\u0631\u06CC\u0646\u062C \u0628\u0627\u0631",
  rangeArea: "\u0631\u06CC\u0646\u062C \u0627\u06CC\u0631\u06CC\u0627",
  hierarchicalChart: "\u0645\u0631\u062A\u0628",
  treemap: "\u0679\u0631\u06CC \u0645\u06CC\u067E",
  sunburst: "\u0633\u0646 \u0628\u0631\u0633\u0679",
  specializedChart: "\u062E\u0635\u0648\u0635\u06CC",
  waterfall: "\u0648\u0627\u0679\u0631 \u0641\u0627\u0644",
  heatmap: "\u06C1\u06CC\u0679 \u0645\u06CC\u067E",
  combinationChart: "\u06A9\u0645\u0628\u06CC\u0646\u06CC\u0634\u0646",
  columnLineCombo: "\u06A9\u0627\u0644\u0645 \u0627\u0648\u0631 \u0644\u0627\u0626\u0646",
  AreaColumnCombo: "\u0627\u06CC\u0631\u06CC\u0627 \u0627\u0648\u0631 \u06A9\u0627\u0644\u0645",
  // Charts
  pivotChartTitle: "\u067E\u06CC\u0648\u0679 \u0686\u0627\u0631\u0679",
  rangeChartTitle: "\u0631\u06CC\u0646\u062C \u0686\u0627\u0631\u0679",
  settings: "\u0686\u0627\u0631\u0679",
  data: "\u062A\u0631\u062A\u06CC\u0628",
  format: "\u062D\u0633\u0628 \u0636\u0631\u0648\u0631\u062A",
  categories: "\u0632\u0645\u0631\u06D2",
  defaultCategory: "(\u06A9\u0648\u0626\u06CC \u0646\u06C1\u06CC\u06BA)",
  series: "\u0633\u06CC\u0631\u06CC\u0632",
  switchCategorySeries: "\u0632\u0645\u0631\u06C1 / \u0633\u06CC\u0631\u06CC\u0632 \u062A\u0628\u062F\u06CC\u0644\u06CC \u06A9\u0631\u06CC\u06BA",
  categoryValues: "\u0632\u0645\u0631\u06C1 \u06A9\u06CC \u0642\u062F\u0631\u06CC\u06BA",
  seriesLabels: "\u0633\u06CC\u0631\u06CC\u0632 \u0644\u06CC\u0628\u0644\u0632",
  aggregate: "\u0645\u062C\u0645\u0648\u0639\u06CC",
  xyValues: "\u0627\u06CC\u06A9\u0633 \u0648\u0627\u0626\u06CC \u0642\u062F\u0631\u06CC\u06BA",
  paired: "\u062C\u0648\u0691\u0627 \u0645\u0648\u0688",
  axis: "\u0645\u062D\u0648\u0631",
  xAxis: "\u0627\u0641\u0642\u06CC \u0645\u062D\u0648\u0631",
  yAxis: "\u0639\u0645\u0648\u062F\u06CC \u0645\u062D\u0648\u0631",
  polarAxis: "\u067E\u0648\u0644\u0631 \u0645\u062D\u0648\u0631",
  radiusAxis: "\u0631\u06CC\u0688\u06CC\u0626\u0633 \u0645\u062D\u0648\u0631",
  navigator: "\u0646\u06CC\u0648\u06CC \u06AF\u06CC\u0679\u0631",
  zoom: "\u0632\u0648\u0645",
  animation: "\u062D\u0631\u06A9\u062A",
  crosshair: "\u06A9\u0631\u0627\u0633 \u06C1\u0626\u06CC\u0631",
  color: "\u0631\u0646\u06AF",
  thickness: "\u0645\u0648\u0679\u0627\u0626\u06CC",
  preferredLength: "\u067E\u0633\u0646\u062F\u06CC\u062F\u06C1 \u0644\u0645\u0628\u0627\u0626\u06CC",
  xType: "\u0627\u06CC\u06A9\u0633 \u0642\u0633\u0645",
  axisType: "\u0645\u062D\u0648\u0631 \u06A9\u06CC \u0642\u0633\u0645",
  automatic: "\u062E\u0648\u062F\u06A9\u0627\u0631",
  category: "\u0632\u0645\u0631\u06C1",
  number: "\u0646\u0645\u0628\u0631",
  time: "\u0648\u0642\u062A",
  timeFormat: "\u0648\u0642\u062A \u06A9\u06CC \u0634\u06A9\u0644",
  autoRotate: "\u062E\u0648\u062F\u06A9\u0627\u0631 \u06AF\u06BE\u0645\u0627\u0624",
  labelRotation: "\u06AF\u06BE\u0645\u0627\u0624",
  circle: "\u06AF\u0648\u0644",
  polygon: "\u06A9\u062B\u06CC\u0631\u0627\u0644\u0627\u0636\u0644\u0627\u0639",
  square: "\u0686\u0648\u06A9\u0648\u0631",
  cross: "\u06A9\u0631\u0627\u0633",
  diamond: "\u06C1\u06CC\u0631\u06D2",
  plus: "\u067E\u0644\u0633",
  triangle: "\u062A\u06A9\u0648\u0646",
  heart: "\u062F\u0644",
  orientation: "\u062A\u0631\u062C\u06CC\u062D",
  fixed: "\u0645\u0633\u062A\u062D\u06A9\u0645",
  parallel: "\u0645\u062A\u0648\u0627\u0632\u06CC",
  perpendicular: "\u0639\u0645\u0648\u062F\u06CC",
  radiusAxisPosition: "\u0645\u0642\u0627\u0645",
  ticks: "\u0679\u06A9\u0633",
  gridLines: "\u06AF\u0631\u0688 \u0644\u0627\u0626\u0646\u0633",
  width: "\u0686\u0648\u0691\u0627\u0626\u06CC",
  height: "\u0627\u0648\u0646\u0686\u0627\u0626\u06CC",
  length: "\u0644\u0645\u0628\u0627\u0626\u06CC",
  padding: "\u067E\u06CC\u0688\u0646\u06AF",
  spacing: "\u0641\u0627\u0635\u0644\u06C1",
  chartStyle: "\u0686\u0627\u0631\u0679 \u0627\u0646\u062F\u0627\u0632",
  title: "\u0639\u0646\u0648\u0627\u0646",
  chartTitles: "\u0639\u0646\u0648\u0627\u0646\u0627\u062A",
  chartTitle: "\u0686\u0627\u0631\u0679 \u0639\u0646\u0648\u0627\u0646",
  chartSubtitle: "\u0630\u06CC\u0644\u06CC \u0639\u0646\u0648\u0627\u0646",
  horizontalAxisTitle: "\u0627\u0641\u0642\u06CC \u0645\u062D\u0648\u0631 \u0639\u0646\u0648\u0627\u0646",
  verticalAxisTitle: "\u0639\u0645\u0648\u062F\u06CC \u0645\u062D\u0648\u0631 \u0639\u0646\u0648\u0627\u0646",
  polarAxisTitle: "\u067E\u0648\u0644\u0631 \u0645\u062D\u0648\u0631 \u0639\u0646\u0648\u0627\u0646",
  titlePlaceholder: "\u0686\u0627\u0631\u0679 \u0639\u0646\u0648\u0627\u0646",
  background: "\u067E\u0633 \u0645\u0646\u0638\u0631",
  font: "\u0641\u0648\u0646\u0679",
  weight: "\u0648\u0632\u0646",
  top: "\u0627\u0648\u067E\u0631",
  right: "\u062F\u0627\u0626\u06CC\u06BA",
  bottom: "\u0646\u06CC\u0686\u06D2",
  left: "\u0628\u0627\u0626\u06CC\u06BA",
  labels: "\u0644\u06CC\u0628\u0644\u0632",
  calloutLabels: "\u06A9\u0627\u0644 \u0622\u0624\u0679 \u0644\u06CC\u0628\u0644",
  sectorLabels: "\u0633\u06CC\u06A9\u0679\u0631 \u0644\u06CC\u0628\u0644\u0632",
  positionRatio: "\u0645\u0642\u0627\u0645 \u062A\u0646\u0627\u0633\u0628",
  size: "\u0633\u0627\u0626\u0632",
  shape: "\u0634\u06A9\u0644",
  minSize: "\u06A9\u0645 \u0627\u0632 \u06A9\u0645 \u0633\u0627\u0626\u0632",
  maxSize: "\u0632\u06CC\u0627\u062F\u06C1 \u0633\u06D2 \u0632\u06CC\u0627\u062F\u06C1 \u0633\u0627\u0626\u0632",
  legend: "\u0644\u06CC\u062C\u0646\u0688",
  position: "\u0645\u0642\u0627\u0645",
  markerSize: "\u0645\u0627\u0631\u06A9\u0631 \u0633\u0627\u0626\u0632",
  markerStroke: "\u0645\u0627\u0631\u06A9\u0631 \u0627\u0633\u0679\u0631\u0648\u06A9",
  markerPadding: "\u0645\u0627\u0631\u06A9\u0631 \u067E\u06CC\u0688\u0646\u06AF",
  itemSpacing: "\u0622\u0626\u0679\u0645 \u0641\u0627\u0635\u0644\u06C1",
  itemPaddingX: "\u0622\u0626\u0679\u0645 \u067E\u06CC\u0688\u0646\u06AF \u0627\u06CC\u06A9\u0633",
  itemPaddingY: "\u0622\u0626\u0679\u0645 \u067E\u06CC\u0688\u0646\u06AF \u0648\u0627\u0626\u06CC",
  layoutHorizontalSpacing: "\u0627\u0641\u0642\u06CC \u0641\u0627\u0635\u0644\u06C1",
  layoutVerticalSpacing: "\u0639\u0645\u0648\u062F\u06CC \u0641\u0627\u0635\u0644\u06C1",
  strokeWidth: "\u0627\u0633\u0679\u0631\u0648\u06A9 \u0686\u0648\u0691\u0627\u0626\u06CC",
  offset: "\u0622\u0641\u0633\u06CC\u0679",
  offsets: "\u0622\u0641\u0633\u06CC\u0679\u0633",
  tooltips: "\u0679\u0648\u0644 \u0679\u067E\u0633",
  callout: "\u06A9\u0627\u0644 \u0622\u0624\u0679",
  markers: "\u0645\u0627\u0631\u06A9\u0631\u0632",
  shadow: "\u0633\u0627\u06CC\u0627",
  blur: "\u062F\u06BE\u0646\u062F\u0644\u0627\u06C1\u0679",
  xOffset: "\u0627\u06CC\u06A9\u0633 \u0622\u0641\u0633\u06CC\u0679",
  yOffset: "\u0648\u0627\u0626\u06CC \u0622\u0641\u0633\u06CC\u0679",
  lineWidth: "\u0644\u0627\u0626\u0646 \u0686\u0648\u0691\u0627\u0626\u06CC",
  lineDash: "\u0644\u0627\u0626\u0646 \u0688\u06CC\u0634",
  lineDashOffset: "\u0688\u06CC\u0634 \u0622\u0641\u0633\u06CC\u0679",
  scrollingZoom: "\u0627\u0633\u06A9\u0631\u0648\u0644\u0646\u06AF",
  scrollingStep: "\u0627\u0633\u06A9\u0631\u0648\u0644\u0646\u06AF \u0642\u062F\u0645",
  selectingZoom: "\u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u0627",
  durationMillis: "\u062F\u0648\u0631\u0627\u0646\u06CC\u06C1 (\u0645\u0644\u06CC \u0633\u06CC\u06A9\u0646\u0688)",
  crosshairLabel: "\u0644\u06CC\u0628\u0644",
  crosshairSnap: "\u0646\u0648\u0688 \u067E\u0631 \u0627\u0633\u0646\u06CC\u067E",
  normal: "\u0646\u0627\u0631\u0645\u0644",
  bold: "\u0628\u0648\u0644\u0688",
  italic: "\u0645\u0627\u0626\u0644",
  boldItalic: "\u0628\u0648\u0644\u0688 \u0645\u0627\u0626\u0644",
  predefined: "\u067E\u06C1\u0644\u06D2 \u0633\u06D2 \u0637\u06D2 \u0634\u062F\u06C1",
  fillOpacity: "\u0628\u06BE\u0631\u0627\u0624 \u06A9\u06CC \u0634\u0641\u0627\u0641\u06CC\u062A",
  strokeColor: "\u0644\u0627\u0626\u0646 \u06A9\u0627 \u0631\u0646\u06AF",
  strokeOpacity: "\u0644\u0627\u0626\u0646 \u06A9\u06CC \u0634\u0641\u0627\u0641\u06CC\u062A",
  miniChart: "\u0645\u0646\u06CC \u0686\u0627\u0631\u0679",
  histogramBinCount: "\u0628\u06CC\u0646 \u0634\u0645\u0627\u0631",
  connectorLine: "\u06A9\u0646\u06CC\u06A9\u0679\u0631 \u0644\u0627\u0626\u0646",
  seriesItems: "\u0633\u06CC\u0631\u06CC\u0632 \u0622\u0626\u0679\u0645\u0632",
  seriesItemType: "\u0622\u0626\u0679\u0645 \u06A9\u06CC \u0642\u0633\u0645",
  seriesItemPositive: "\u0645\u062B\u0628\u062A",
  seriesItemNegative: "\u0645\u0646\u0641\u06CC",
  seriesItemLabels: "\u0622\u0626\u0679\u0645 \u0644\u06CC\u0628\u0644\u0632",
  columnGroup: "\u06A9\u0627\u0644\u0645",
  barGroup: "\u0628\u0627\u0631",
  pieGroup: "\u067E\u0627\u0626",
  lineGroup: "\u0644\u0627\u0626\u0646",
  scatterGroup: "\u0627\u06CC\u06A9\u0633 \u0648\u0627\u0626\u06CC (\u0627\u0633\u06A9\u06CC\u0679\u0631)",
  areaGroup: "\u0639\u0644\u0627\u0642\u06C1",
  polarGroup: "\u067E\u0648\u0644\u0631",
  statisticalGroup: "\u0634\u0645\u0627\u0631\u06CC\u0627\u062A\u06CC",
  hierarchicalGroup: "\u062F\u0631\u062C\u06C1 \u0628\u0646\u062F\u06CC",
  specializedGroup: "\u0645\u062A\u062E\u0635\u0635",
  combinationGroup: "\u0645\u062C\u0645\u0648\u0639\u06C1",
  groupedColumnTooltip: "\u06AF\u0631\u0648\u067E",
  stackedColumnTooltip: "\u0627\u0633\u0679\u06CC\u06A9",
  normalizedColumnTooltip: "100% \u0627\u0633\u0679\u06CC\u06A9",
  groupedBarTooltip: "\u06AF\u0631\u0648\u067E",
  stackedBarTooltip: "\u0627\u0633\u0679\u06CC\u06A9",
  normalizedBarTooltip: "100% \u0627\u0633\u0679\u06CC\u06A9",
  pieTooltip: "\u067E\u0627\u0626",
  donutTooltip: "\u0688\u0648\u0646\u0679",
  lineTooltip: "\u0644\u0627\u0626\u0646",
  groupedAreaTooltip: "\u0639\u0644\u0627\u0642\u06C1",
  stackedAreaTooltip: "\u0627\u0633\u0679\u06CC\u06A9",
  normalizedAreaTooltip: "100% \u0627\u0633\u0679\u06CC\u06A9",
  scatterTooltip: "\u0627\u0633\u06A9\u06CC\u0679\u0631",
  bubbleTooltip: "\u0628\u0627\u0628\u0644",
  histogramTooltip: "\u06C1\u0633\u0679\u0648\u0631\u06AF\u0631\u0627\u0645",
  radialColumnTooltip: "\u0631\u06CC\u0688\u06CC\u0626\u0644 \u06A9\u0627\u0644\u0645",
  radialBarTooltip: "\u0631\u06CC\u0688\u06CC\u0626\u0644 \u0628\u0627\u0631",
  radarLineTooltip: "\u0631\u06CC\u0688\u0627\u0631 \u0644\u0627\u0626\u0646",
  radarAreaTooltip: "\u0631\u06CC\u0688\u0627\u0631 \u0639\u0644\u0627\u0642\u06C1",
  nightingaleTooltip: "\u0646\u0627\u0626\u0679\u06CC\u0646\u06AF\u0644",
  rangeBarTooltip: "\u0631\u06CC\u0646\u062C \u0628\u0627\u0631",
  rangeAreaTooltip: "\u0631\u06CC\u0646\u062C \u0639\u0644\u0627\u0642\u06C1",
  boxPlotTooltip: "\u0628\u0627\u06A9\u0633 \u067E\u0644\u0627\u0679",
  treemapTooltip: "\u0679\u0631\u06CC \u0645\u06CC\u067E",
  sunburstTooltip: "\u0633\u0646 \u0628\u0631\u0633\u0679",
  waterfallTooltip: "\u0648\u0627\u0679\u0631\u0641\u0627\u0644",
  heatmapTooltip: "\u06C1\u06CC\u0679 \u0645\u06CC\u067E",
  columnLineComboTooltip: "\u06A9\u0627\u0644\u0645 \u0648 \u0644\u0627\u0626\u0646",
  areaColumnComboTooltip: "\u0639\u0644\u0627\u0642\u06C1 \u0648 \u06A9\u0627\u0644\u0645",
  customComboTooltip: "\u062D\u0633\u0628 \u0645\u0646\u0634\u0627 \u0645\u062C\u0645\u0648\u0639\u06C1",
  innerRadius: "\u0627\u0646\u062F\u0631\u0648\u0646\u06CC \u0634\u0639\u0627\u0639",
  startAngle: "\u0622\u063A\u0627\u0632 \u0632\u0627\u0648\u06CC\u06C1",
  endAngle: "\u0627\u062E\u062A\u062A\u0627\u0645\u06CC \u0632\u0627\u0648\u06CC\u06C1",
  reverseDirection: "\u0627\u0644\u0679\u06CC \u0633\u0645\u062A",
  groupPadding: "\u06AF\u0631\u0648\u067E \u067E\u06CC\u0688\u0646\u06AF",
  seriesPadding: "\u0633\u06CC\u0631\u06CC\u0632 \u067E\u06CC\u0688\u0646\u06AF",
  tile: "\u0679\u0627\u0626\u0644",
  whisker: "\u0648\u0633\u06A9\u0631",
  cap: "\u06A9\u06CC\u067E",
  capLengthRatio: "\u0644\u0645\u0628\u0627\u0626\u06CC \u062A\u0646\u0627\u0633\u0628",
  labelPlacement: "\u0644\u06CC\u0628\u0644 \u06A9\u06CC \u062C\u06AF\u06C1",
  inside: "\u0627\u0646\u062F\u0631",
  outside: "\u0628\u0627\u06C1\u0631",
  noDataToChart: "\u06A9\u0648\u0626\u06CC \u062F\u0633\u062A\u06CC\u0627\u0628 \u0688\u06CC\u0679\u0627 \u0686\u0627\u0631\u0679 \u0628\u0646\u0627\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 \u0646\u06C1\u06CC\u06BA \u06C1\u06D2\u06D4",
  pivotChartRequiresPivotMode: "\u067E\u06CC\u0648\u0679 \u0686\u0627\u0631\u0679 \u06A9\u06CC\u0644\u0626\u06D2 \u067E\u06CC\u0648\u0679 \u0645\u0648\u0688 \u062C\u0627\u0631\u06CC \u06A9\u0631\u0646\u0627 \u0636\u0631\u0648\u0631\u06CC \u06C1\u06D2\u06D4",
  chartSettingsToolbarTooltip: "\u0645\u06CC\u0646\u0648",
  chartLinkToolbarTooltip: "\u06AF\u0631\u0688 \u0633\u06D2 \u0645\u0631\u0628\u0648\u0637",
  chartUnlinkToolbarTooltip: "\u06AF\u0631\u0688 \u0633\u06D2 \u063A\u06CC\u0631 \u0645\u0631\u0628\u0648\u0637",
  chartDownloadToolbarTooltip: "\u0686\u0627\u0631\u0679 \u0688\u0627\u0624\u0646 \u0644\u0648\u0688 \u06A9\u0631\u06CC\u06BA",
  chartMenuToolbarTooltip: "\u0645\u06CC\u0646\u0648",
  chartEdit: "\u0686\u0627\u0631\u0679 \u0645\u06CC\u06BA \u062A\u0631\u0645\u06CC\u0645 \u06A9\u0631\u06CC\u06BA",
  chartAdvancedSettings: "\u0627\u0639\u0644\u06CC \u062A\u0631\u062A\u06CC\u0628\u0627\u062A",
  chartLink: "\u06AF\u0631\u0688 \u0633\u06D2 \u0645\u0631\u0628\u0648\u0637 \u06A9\u0631\u06CC\u06BA",
  chartUnlink: "\u06AF\u0631\u0688 \u0633\u06D2 \u063A\u06CC\u0631 \u0645\u0631\u0628\u0648\u0637 \u06A9\u0631\u06CC\u06BA",
  chartDownload: "\u0686\u0627\u0631\u0679 \u0688\u0627\u0624\u0646 \u0644\u0648\u0688 \u06A9\u0631\u06CC\u06BA",
  histogramFrequency: "\u062A\u0648\u0627\u062A\u0631",
  seriesChartType: "\u0633\u06CC\u0631\u06CC\u0632 \u0686\u0627\u0631\u0679 \u0642\u0633\u0645",
  seriesType: "\u0633\u06CC\u0631\u06CC\u0632 \u0642\u0633\u0645",
  secondaryAxis: "\u062B\u0627\u0646\u0648\u06CC \u0645\u062D\u0648\u0631",
  seriesAdd: "\u0633\u06CC\u0631\u06CC\u0632 \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  categoryAdd: "\u0632\u0645\u0631\u06C1 \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  bar: "\u0628\u0627\u0631",
  column: "\u06A9\u0627\u0644\u0645",
  histogram: "\u06C1\u0633\u0679\u0648\u0631\u06AF\u0631\u0627\u0645",
  advancedSettings: "\u0627\u0639\u0644\u06CC \u062A\u0631\u062A\u06CC\u0628\u0627\u062A",
  direction: "\u0633\u0645\u062A",
  horizontal: "\u0627\u0641\u0642\u06CC",
  vertical: "\u0639\u0645\u0648\u062F\u06CC",
  seriesGroupType: "\u06AF\u0631\u0648\u067E \u06A9\u06CC \u0642\u0633\u0645",
  groupedSeriesGroupType: "\u06AF\u0631\u0648\u067E",
  stackedSeriesGroupType: "\u0627\u0633\u0679\u06CC\u06A9",
  normalizedSeriesGroupType: "100% \u0627\u0633\u0679\u06CC\u06A9",
  legendEnabled: "\u0641\u0639\u0627\u0644",
  invalidColor: "\u0631\u0646\u06AF \u06A9\u06CC \u0642\u06CC\u0645\u062A \u063A\u0644\u0637 \u06C1\u06D2",
  groupedColumnFull: "\u06AF\u0631\u0648\u067E \u06A9\u0627\u0644\u0645",
  stackedColumnFull: "\u0627\u0633\u0679\u06CC\u06A9 \u06A9\u0627\u0644\u0645",
  normalizedColumnFull: "100% \u0627\u0633\u0679\u06CC\u06A9 \u06A9\u0627\u0644\u0645",
  groupedBarFull: "\u06AF\u0631\u0648\u067E \u0628\u0627\u0631",
  stackedBarFull: "\u0627\u0633\u0679\u06CC\u06A9 \u0628\u0627\u0631",
  normalizedBarFull: "100% \u0627\u0633\u0679\u06CC\u06A9 \u0628\u0627\u0631",
  stackedAreaFull: "\u0627\u0633\u0679\u06CC\u06A9 \u0639\u0644\u0627\u0642\u06C1",
  normalizedAreaFull: "100% \u0627\u0633\u0679\u06CC\u06A9 \u0639\u0644\u0627\u0642\u06C1",
  customCombo: "\u062D\u0633\u0628 \u0645\u0646\u0634\u0627 \u0645\u062C\u0645\u0648\u0639\u06C1",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. \u0633\u0637\u062D ${variable}. \u062A\u0631\u0645\u06CC\u0645 \u06A9\u06D2 \u0644\u06CC\u06D2 ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. \u0633\u0637\u062D ${variable}. ${variable} \u062A\u0631\u0645\u06CC\u0645 \u06A9\u06D2 \u0644\u06CC\u06D2 ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA.",
  ariaAdvancedFilterBuilderList: "\u0627\u06CC\u0688\u0648\u0627\u0646\u0633\u0688 \u0641\u0644\u0679\u0631 \u0628\u0644\u0688\u0631 \u0641\u06C1\u0631\u0633\u062A",
  ariaAdvancedFilterBuilderFilterItem: "\u0641\u0644\u0679\u0631 \u0634\u0631\u0637",
  ariaAdvancedFilterBuilderGroupItem: "\u0641\u0644\u0679\u0631 \u06AF\u0631\u0648\u067E",
  ariaAdvancedFilterBuilderColumn: "\u06A9\u0627\u0644\u0645",
  ariaAdvancedFilterBuilderOption: "\u0627\u062E\u062A\u06CC\u0627\u0631",
  ariaAdvancedFilterBuilderValueP: "\u0642\u062F\u0631",
  ariaAdvancedFilterBuilderJoinOperator: "\u062C\u0648\u0627\u0626\u0646 \u0627\u067E\u0631\u06CC\u0679\u0631",
  ariaAdvancedFilterInput: "\u0627\u06CC\u0688\u0648\u0627\u0646\u0633\u0688 \u0641\u0644\u0679\u0631 \u0627\u0646\u067E\u0679",
  ariaChecked: "\u0686\u06CC\u06A9\u0688",
  ariaColumn: "\u06A9\u0627\u0644\u0645",
  ariaColumnGroup: "\u06A9\u0627\u0644\u0645 \u06AF\u0631\u0648\u067E",
  ariaColumnFiltered: "\u06A9\u0627\u0644\u0645 \u0641\u0644\u0679\u0631\u0688",
  ariaColumnSelectAll: "\u062A\u0645\u0627\u0645 \u06A9\u0627\u0644\u0645\u0632 \u06A9\u0648 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u06D2 \u06A9\u06CC \u0679\u0648\u06AF\u0644 \u06A9\u0631\u06CC\u06BA",
  ariaDateFilterInput: "\u062A\u0627\u0631\u06CC\u062E \u0641\u0644\u0679\u0631 \u0627\u0646\u067E\u0679",
  ariaDefaultListName: "\u0641\u06C1\u0631\u0633\u062A",
  ariaFilterColumnsInput: "\u06A9\u0627\u0644\u0645\u0632 \u0641\u0644\u0679\u0631 \u0627\u0646\u067E\u0679",
  ariaFilterFromValue: "\u0642\u062F\u0631 \u0633\u06D2 \u0641\u0644\u0679\u0631 \u06A9\u0631\u06CC\u06BA",
  ariaFilterInput: "\u0641\u0644\u0679\u0631 \u0627\u0646\u067E\u0679",
  ariaFilterList: "\u0641\u0644\u0679\u0631 \u0641\u06C1\u0631\u0633\u062A",
  ariaFilterToValue: "\u0642\u062F\u0631 \u062A\u06A9 \u0641\u0644\u0679\u0631 \u06A9\u0631\u06CC\u06BA",
  ariaFilterValue: "\u0641\u0644\u0679\u0631 \u0642\u062F\u0631",
  ariaFilterMenuOpen: "\u0641\u0644\u0679\u0631 \u0645\u06CC\u0646\u0648 \u06A9\u06BE\u0648\u0644\u06CC\u06BA",
  ariaFilteringOperator: "\u0641\u0644\u0679\u0631\u0646\u06AF \u0627\u067E\u0631\u06CC\u0679\u0631",
  ariaHidden: "\u0686\u06BE\u067E\u0627 \u06C1\u0648\u0627",
  ariaIndeterminate: "\u063A\u06CC\u0631 \u0645\u0633\u062A\u062D\u06A9\u0645",
  ariaInputEditor: "\u0627\u0646\u067E\u0679 \u0627\u06CC\u0688\u06CC\u0679\u0631",
  ariaMenuColumn: "\u06A9\u0627\u0644\u0645 \u0645\u06CC\u0646\u0648 \u06A9\u06BE\u0648\u0644\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 ALT DOWN \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaFilterColumn: "\u0641\u0644\u0679\u0631 \u06A9\u06BE\u0648\u0644\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 CTRL ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaRowDeselect: "\u0627\u0633 \u0635\u0641 \u06A9\u0648 \u063A\u06CC\u0631 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaRowSelectAll: "\u062A\u0645\u0627\u0645 \u0635\u0641\u0648\u06BA \u06A9\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u0679\u0648\u06AF\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaRowToggleSelection: "\u0635\u0641 \u06A9\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u0679\u0648\u06AF\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaRowSelect: "\u0627\u0633 \u0635\u0641 \u06A9\u0648 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaRowSelectionDisabled: "\u0627\u0633 \u0635\u0641 \u06A9\u0627 \u0627\u0646\u062A\u062E\u0627\u0628 \u063A\u06CC\u0631 \u0641\u0639\u0627\u0644 \u06C1\u06D2",
  ariaSearch: "\u062A\u0644\u0627\u0634",
  ariaSortableColumn: "\u062A\u0631\u062A\u06CC\u0628 \u062F\u06CC\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaToggleVisibility: "\u0645\u0631\u0626\u06CC\u062A \u0679\u0648\u06AF\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaToggleCellValue: "\u0633\u06CC\u0644 \u06A9\u06CC \u0642\u062F\u0631 \u0679\u0648\u06AF\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaUnchecked: "\u063A\u06CC\u0631 \u0686\u06CC\u06A9\u0688",
  ariaVisible: "\u0646\u0638\u0631 \u0622\u0646\u06D2 \u0648\u0627\u0644\u0627",
  ariaSearchFilterValues: "\u0641\u0644\u0679\u0631 \u0642\u062F\u0631\u0648\u06BA \u06A9\u06CC \u062A\u0644\u0627\u0634 \u06A9\u0631\u06CC\u06BA",
  ariaPageSizeSelectorLabel: "\u0635\u0641\u06C1 \u06A9\u0627 \u0633\u0627\u0626\u0632",
  ariaChartMenuClose: "\u0686\u0627\u0631\u0679 \u0627\u06CC\u0688\u06CC\u0679 \u0645\u06CC\u0646\u0648 \u0628\u0646\u062F \u06A9\u0631\u06CC\u06BA",
  ariaChartSelected: "\u0645\u0646\u062A\u062E\u0628 \u0634\u062F\u06C1",
  ariaSkeletonCellLoadingFailed: "\u0635\u0641 \u0644\u0648\u0688 \u06A9\u0631\u0646\u06D2 \u0645\u06CC\u06BA \u0646\u0627\u06A9\u0627\u0645",
  ariaSkeletonCellLoading: "\u0635\u0641 \u06A9\u0627 \u0688\u06CC\u0679\u0627 \u0644\u0648\u0688 \u06C1\u0648 \u0631\u06C1\u0627 \u06C1\u06D2",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "\u0642\u0637\u0627\u0631 \u06AF\u0631\u0648\u067E\u0633",
  ariaValuesDropZonePanelLabel: "\u0642\u062F\u0631\u06CC\u06BA",
  ariaPivotDropZonePanelLabel: "\u06A9\u0627\u0644\u0645 \u0644\u06CC\u0628\u0644\u0632",
  ariaDropZoneColumnComponentDescription: "\u06C1\u0679\u0627\u0646\u06D2 \u06A9\u06CC\u0644\u0626\u06D2 DELETE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaDropZoneColumnValueItemDescription: "\u062C\u0645\u0639 \u06A9\u06D2 \u0627\u0646\u062F\u0627\u0632 \u06A9\u0648 \u062A\u0628\u062F\u06CC\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06CC\u0644\u0626\u06D2 ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaDropZoneColumnGroupItemDescription: "\u062A\u0631\u062A\u06CC\u0628 \u062F\u06CC\u0646\u06D2 \u06A9\u06CC\u0644\u0626\u06D2 ENTER \u062F\u0628\u0627\u0626\u06CC\u06BA",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " \u06A9\u0627 ",
  ariaDropZoneColumnComponentSortAscending: "\u0628\u0691\u06BE\u062A\u06D2 \u06C1\u0648\u0626\u06D2 \u062A\u0631\u062A\u06CC\u0628",
  ariaDropZoneColumnComponentSortDescending: "\u06AF\u0631\u062A\u06D2 \u06C1\u0648\u0626\u06D2 \u062A\u0631\u062A\u06CC\u0628",
  ariaLabelDialog: "\u0688\u0627\u0626\u06CC\u0644\u0627\u06AF",
  ariaLabelColumnMenu: "\u06A9\u0627\u0644\u0645 \u0645\u06CC\u0646\u0648",
  ariaLabelColumnFilter: "\u06A9\u0627\u0644\u0645 \u0641\u0644\u0679\u0631",
  ariaLabelCellEditor: "\u0633\u06CC\u0644 \u0627\u06CC\u0688\u06CC\u0679\u0631",
  ariaLabelSelectField: "\u0641\u06CC\u0644\u0688 \u0645\u0646\u062A\u062E\u0628 \u06A9\u0631\u06CC\u06BA",
  // aria labels for rich select
  ariaLabelRichSelectField: "\u0627\u0645\u06CC\u0631 \u0645\u0646\u062A\u062E\u0628 \u0641\u06CC\u0644\u0688",
  ariaLabelRichSelectToggleSelection: "\u0627\u0646\u062A\u062E\u0627\u0628 \u06A9\u0648 \u062A\u0628\u062F\u06CC\u0644 \u06A9\u0631\u0646\u06D2 \u06A9\u06D2 \u0644\u0626\u06D2 SPACE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaLabelRichSelectDeselectAllItems: "\u062A\u0645\u0627\u0645 \u0627\u0634\u06CC\u0627\u0621 \u06A9\u0648 \u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u06D2 \u06C1\u0679\u0627\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 DELETE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaLabelRichSelectDeleteSelection: "\u0622\u0626\u0679\u0645 \u06A9\u0648 \u0627\u0646\u062A\u062E\u0627\u0628 \u0633\u06D2 \u06C1\u0679\u0627\u0646\u06D2 \u06A9\u06D2 \u0644\u06CC\u06D2 DELETE \u062F\u0628\u0627\u0626\u06CC\u06BA",
  ariaLabelTooltip: "\u0679\u0648\u0644 \u0679\u067E",
  ariaLabelContextMenu: "\u0633\u06CC\u0627\u0642 \u0648 \u0633\u0628\u0627\u0642 \u0645\u06CC\u0646\u0648",
  ariaLabelSubMenu: "\u0630\u06CC\u0644\u06CC \u0645\u06CC\u0646\u0648",
  ariaLabelAggregationFunction: "\u062A\u062C\u0645\u0639 \u06A9\u0627 \u0641\u0646\u06A9\u0634\u0646",
  ariaLabelAdvancedFilterAutocomplete: "\u0627\u0639\u0644\u06CC \u0641\u0644\u0679\u0631 \u062E\u0648\u062F\u06A9\u0627\u0631 \u0645\u06A9\u0645\u0644",
  ariaLabelAdvancedFilterBuilderAddField: "\u0627\u0639\u0644\u06CC \u0641\u0644\u0679\u0631 \u0628\u0644\u0688\u0631 \u0641\u06CC\u0644\u0688 \u0634\u0627\u0645\u0644 \u06A9\u0631\u06CC\u06BA",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "\u0627\u0639\u0644\u06CC \u0641\u0644\u0679\u0631 \u0628\u0644\u0688\u0631 \u06A9\u0627\u0644\u0645 \u0645\u0646\u062A\u062E\u0628 \u0641\u06CC\u0644\u0688",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "\u0627\u0639\u0644\u06CC \u0641\u0644\u0679\u0631 \u0628\u0644\u0688\u0631 \u0622\u067E\u0634\u0646 \u0645\u0646\u062A\u062E\u0628 \u0641\u06CC\u0644\u0688",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "\u0627\u0639\u0644\u06CC \u0641\u0644\u0679\u0631 \u0628\u0644\u0688\u0631 \u062C\u0648\u0627\u0626\u0646 \u0622\u067E\u0631\u06CC\u0679\u0631 \u0645\u0646\u062A\u062E\u0628 \u0641\u06CC\u0644\u0688",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "\u06A9\u0627\u0644\u0645 \u06A9\u06CC \u0641\u06C1\u0631\u0633\u062A",
  ariaFilterPanelList: "\u0641\u0644\u0679\u0631 \u06A9\u06CC \u0641\u06C1\u0631\u0633\u062A",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u0633\u0686",
  false: "\u062C\u06BE\u0648\u0679",
  invalidDate: "\u063A\u0644\u0637 \u062A\u0627\u0631\u06CC\u062E",
  invalidNumber: "\u063A\u0644\u0637 \u0646\u0645\u0628\u0631",
  january: "\u062C\u0646\u0648\u0631\u06CC",
  february: "\u0641\u0631\u0648\u0631\u06CC",
  march: "\u0645\u0627\u0631\u0686",
  april: "\u0627\u067E\u0631\u06CC\u0644",
  may: "\u0645\u0626\u06CC",
  june: "\u062C\u0648\u0646",
  july: "\u062C\u0648\u0644\u0627\u0626\u06CC",
  august: "\u0627\u06AF\u0633\u062A",
  september: "\u0633\u062A\u0645\u0628\u0631",
  october: "\u0627\u06A9\u062A\u0648\u0628\u0631",
  november: "\u0646\u0648\u0645\u0628\u0631",
  december: "\u062F\u0633\u0645\u0628\u0631",
  // Time formats
  timeFormatSlashesDDMMYYYY: "\u0688\u06CC \u0688\u06CC/\u0627\u06CC\u0645 \u0627\u06CC\u0645/\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatSlashesMMDDYYYY: "\u0627\u06CC\u0645 \u0627\u06CC\u0645/\u0688\u06CC \u0688\u06CC/\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatSlashesDDMMYY: "\u0688\u06CC \u0688\u06CC/\u0627\u06CC\u0645 \u0627\u06CC\u0645/\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatSlashesMMDDYY: "\u0627\u06CC\u0645 \u0627\u06CC\u0645/\u0688\u06CC \u0688\u06CC/\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatDotsDDMYY: "\u0688\u06CC \u0688\u06CC.\u0627\u06CC\u0645.\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatDotsMDDYY: "\u0627\u06CC\u0645.\u0688\u06CC \u0688\u06CC.\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatDashesYYYYMMDD: "\u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC-\u0627\u06CC\u0645 \u0627\u06CC\u0645-\u0688\u06CC \u0688\u06CC",
  timeFormatSpacesDDMMMMYYYY: "\u0688\u06CC \u0688\u06CC \u0627\u06CC\u0645 \u0627\u06CC\u0645 \u0627\u06CC\u0645 \u0627\u06CC\u0645 \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC \u0648\u0627\u0626\u06CC",
  timeFormatHHMMSS: "\u0627\u06CC\u0686 \u0627\u06CC\u0686:\u0627\u06CC\u0645 \u0627\u06CC\u0645:\u0627\u06CC\u0633 \u0627\u06CC\u0633",
  timeFormatHHMMSSAmPm: "\u0627\u06CC\u0686 \u0627\u06CC\u0686:\u0627\u06CC\u0645 \u0627\u06CC\u0645:\u0627\u06CC\u0633 \u0627\u06CC\u0633 \u0627\u06D2 \u0627\u06CC\u0645/\u067E\u06CC \u0627\u06CC\u0645"
};

// community-modules/locale/src/vi-VN.ts
var AG_GRID_LOCALE_VN = {
  // Set Filter
  selectAll: "(Ch\u1ECDn T\u1EA5t C\u1EA3)",
  selectAllSearchResults: "(Ch\u1ECDn T\u1EA5t C\u1EA3 C\xE1c K\u1EBFt Qu\u1EA3 T\xECm Ki\u1EBFm)",
  addCurrentSelectionToFilter: "Th\xEAm l\u1EF1a ch\u1ECDn hi\u1EC7n t\u1EA1i v\xE0o b\u1ED9 l\u1ECDc",
  searchOoo: "T\xECm ki\u1EBFm...",
  blanks: "(Tr\u1ED1ng)",
  noMatches: "Kh\xF4ng c\xF3 k\u1EBFt qu\u1EA3",
  // Number Filter & Text Filter
  filterOoo: "L\u1ECDc...",
  equals: "B\u1EB1ng",
  notEqual: "Kh\xF4ng b\u1EB1ng",
  blank: "Tr\u1ED1ng",
  notBlank: "Kh\xF4ng tr\u1ED1ng",
  empty: "Ch\u1ECDn m\u1ED9t",
  // Number Filter
  lessThan: "Nh\u1ECF h\u01A1n",
  greaterThan: "L\u1EDBn h\u01A1n",
  lessThanOrEqual: "Nh\u1ECF h\u01A1n ho\u1EB7c b\u1EB1ng",
  greaterThanOrEqual: "L\u1EDBn h\u01A1n ho\u1EB7c b\u1EB1ng",
  inRange: "Trong kho\u1EA3ng",
  inRangeStart: "T\u1EEB",
  inRangeEnd: "\u0110\u1EBFn",
  // Text Filter
  contains: "Ch\u1EE9a",
  notContains: "Kh\xF4ng ch\u1EE9a",
  startsWith: "B\u1EAFt \u0111\u1EA7u v\u1EDBi",
  endsWith: "K\u1EBFt th\xFAc v\u1EDBi",
  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: "Tr\u01B0\u1EDBc",
  after: "Sau",
  // Filter Conditions
  andCondition: "V\xC0",
  orCondition: "HO\u1EB6C",
  // Filter Buttons
  applyFilter: "\xC1p d\u1EE5ng",
  resetFilter: "\u0110\u1EB7t l\u1EA1i",
  clearFilter: "X\xF3a",
  cancelFilter: "H\u1EE7y",
  // Filter Titles
  textFilter: "B\u1ED9 l\u1ECDc v\u0103n b\u1EA3n",
  numberFilter: "B\u1ED9 l\u1ECDc s\u1ED1",
  dateFilter: "B\u1ED9 l\u1ECDc ng\xE0y th\xE1ng",
  setFilter: "B\u1ED9 l\u1ECDc t\u1EADp h\u1EE3p",
  // Group Column Filter
  groupFilterSelect: "Ch\u1ECDn tr\u01B0\u1EDDng:",
  // Advanced Filter
  advancedFilterContains: "ch\u1EE9a",
  advancedFilterNotContains: "kh\xF4ng ch\u1EE9a",
  advancedFilterTextEquals: "b\u1EB1ng",
  advancedFilterTextNotEqual: "kh\xF4ng b\u1EB1ng",
  advancedFilterStartsWith: "b\u1EAFt \u0111\u1EA7u v\u1EDBi",
  advancedFilterEndsWith: "k\u1EBFt th\xFAc v\u1EDBi",
  advancedFilterBlank: "tr\u1ED1ng",
  advancedFilterNotBlank: "kh\xF4ng tr\u1ED1ng",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "\u0111\xFAng",
  advancedFilterFalse: "sai",
  advancedFilterAnd: "V\xC0",
  advancedFilterOr: "HO\u1EB6C",
  advancedFilterApply: "\xC1p d\u1EE5ng",
  advancedFilterBuilder: "Tr\xECnh t\u1EA1o",
  advancedFilterValidationMissingColumn: "Thi\u1EBFu c\u1ED9t",
  advancedFilterValidationMissingOption: "Thi\u1EBFu t\xF9y ch\u1ECDn",
  advancedFilterValidationMissingValue: "Thi\u1EBFu gi\xE1 tr\u1ECB",
  advancedFilterValidationInvalidColumn: "Kh\xF4ng t\xECm th\u1EA5y c\u1ED9t",
  advancedFilterValidationInvalidOption: "Kh\xF4ng t\xECm th\u1EA5y t\xF9y ch\u1ECDn",
  advancedFilterValidationMissingQuote: "Gi\xE1 tr\u1ECB thi\u1EBFu d\u1EA5u ngo\u1EB7c k\xE9p",
  advancedFilterValidationNotANumber: "Gi\xE1 tr\u1ECB kh\xF4ng ph\u1EA3i l\xE0 m\u1ED9t s\u1ED1",
  advancedFilterValidationInvalidDate: "Gi\xE1 tr\u1ECB kh\xF4ng ph\u1EA3i l\xE0 ng\xE0y h\u1EE3p l\u1EC7",
  advancedFilterValidationMissingCondition: "Thi\u1EBFu \u0111i\u1EC1u ki\u1EC7n",
  advancedFilterValidationJoinOperatorMismatch: "C\xE1c to\xE1n t\u1EED k\u1EBFt n\u1ED1i trong m\u1ED9t \u0111i\u1EC1u ki\u1EC7n ph\u1EA3i gi\u1ED1ng nhau",
  advancedFilterValidationInvalidJoinOperator: "Kh\xF4ng t\xECm th\u1EA5y to\xE1n t\u1EED k\u1EBFt n\u1ED1i",
  advancedFilterValidationMissingEndBracket: "Thi\u1EBFu d\u1EA5u ngo\u1EB7c k\u1EBFt th\xFAc",
  advancedFilterValidationExtraEndBracket: "Qu\xE1 nhi\u1EC1u d\u1EA5u ngo\u1EB7c k\u1EBFt th\xFAc",
  advancedFilterValidationMessage: "Bi\u1EC3u th\u1EE9c c\xF3 l\u1ED7i. ${variable} - ${variable}.",
  advancedFilterValidationMessageAtEnd: "Bi\u1EC3u th\u1EE9c c\xF3 l\u1ED7i. ${variable} \u1EDF cu\u1ED1i bi\u1EC3u th\u1EE9c.",
  advancedFilterBuilderTitle: "B\u1ED9 l\u1ECDc N\xE2ng cao",
  advancedFilterBuilderApply: "\xC1p d\u1EE5ng",
  advancedFilterBuilderCancel: "H\u1EE7y",
  advancedFilterBuilderAddButtonTooltip: "Th\xEAm B\u1ED9 l\u1ECDc ho\u1EB7c Nh\xF3m",
  advancedFilterBuilderRemoveButtonTooltip: "X\xF3a b\u1ECF",
  advancedFilterBuilderMoveUpButtonTooltip: "Di chuy\u1EC3n l\xEAn",
  advancedFilterBuilderMoveDownButtonTooltip: "Di chuy\u1EC3n xu\u1ED1ng",
  advancedFilterBuilderAddJoin: "Th\xEAm Nh\xF3m",
  advancedFilterBuilderAddCondition: "Th\xEAm B\u1ED9 l\u1ECDc",
  advancedFilterBuilderSelectColumn: "Ch\u1ECDn m\u1ED9t c\u1ED9t",
  advancedFilterBuilderSelectOption: "Ch\u1ECDn m\u1ED9t t\xF9y ch\u1ECDn",
  advancedFilterBuilderEnterValue: "Nh\u1EADp m\u1ED9t gi\xE1 tr\u1ECB...",
  advancedFilterBuilderValidationAlreadyApplied: "B\u1ED9 l\u1ECDc hi\u1EC7n t\u1EA1i \u0111\xE3 \u0111\u01B0\u1EE3c \xE1p d\u1EE5ng.",
  advancedFilterBuilderValidationIncomplete: "Kh\xF4ng ph\u1EA3i t\u1EA5t c\u1EA3 \u0111i\u1EC1u ki\u1EC7n \u0111\u1EC1u \u0111\xE3 ho\xE0n th\xE0nh.",
  advancedFilterBuilderValidationSelectColumn: "Ph\u1EA3i ch\u1ECDn m\u1ED9t c\u1ED9t.",
  advancedFilterBuilderValidationSelectOption: "Ph\u1EA3i ch\u1ECDn m\u1ED9t t\xF9y ch\u1ECDn.",
  advancedFilterBuilderValidationEnterValue: "Ph\u1EA3i nh\u1EADp m\u1ED9t gi\xE1 tr\u1ECB.",
  // Side Bar
  columns: "C\u1ED9t",
  filters: "B\u1ED9 l\u1ECDc",
  // columns tool panel
  pivotMode: "Ch\u1EBF \u0111\u1ED9 Pivot",
  groups: "Nh\xF3m h\xE0ng",
  rowGroupColumnsEmptyMessage: "K\xE9o v\xE0o \u0111\xE2y \u0111\u1EC3 thi\u1EBFt l\u1EADp c\xE1c nh\xF3m h\xE0ng",
  values: "Gi\xE1 tr\u1ECB",
  valueColumnsEmptyMessage: "K\xE9o v\xE0o \u0111\xE2y \u0111\u1EC3 t\u1ED5ng h\u1EE3p",
  pivots: "Nh\xE3n c\u1ED9t",
  pivotColumnsEmptyMessage: "K\xE9o v\xE0o \u0111\xE2y \u0111\u1EC3 thi\u1EBFt l\u1EADp nh\xE3n c\u1ED9t",
  // Header of the Default Group Column
  group: "Nh\xF3m",
  // Row Drag
  rowDragRow: "h\xE0ng",
  rowDragRows: "c\xE1c h\xE0ng",
  // Other
  loadingOoo: "\u0110ang t\u1EA3i...",
  loadingError: "L\u1ED6I",
  noRowsToShow: "Kh\xF4ng c\xF3 h\xE0ng \u0111\u1EC3 hi\u1EC3n th\u1ECB",
  enabled: "\u0110\xE3 b\u1EADt",
  // Menu
  pinColumn: "Ghim C\u1ED9t",
  pinLeft: "Ghim Tr\xE1i",
  pinRight: "Ghim Ph\u1EA3i",
  noPin: "Kh\xF4ng Ghim",
  valueAggregation: "T\u1ED5ng H\u1EE3p Gi\xE1 Tr\u1ECB",
  noAggregation: "Kh\xF4ng",
  autosizeThisColumn: "T\u1EF1 \u0110i\u1EC1u Ch\u1EC9nh K\xEDch Th\u01B0\u1EDBc C\u1ED9t N\xE0y",
  autosizeAllColumns: "T\u1EF1 \u0110i\u1EC1u Ch\u1EC9nh K\xEDch Th\u01B0\u1EDBc T\u1EA5t C\u1EA3 C\xE1c C\u1ED9t",
  groupBy: "Nh\xF3m Theo",
  ungroupBy: "B\u1ECF Nh\xF3m Theo",
  ungroupAll: "B\u1ECF Nh\xF3m T\u1EA5t C\u1EA3",
  addToValues: "Th\xEAm ${variable} v\xE0o gi\xE1 tr\u1ECB",
  removeFromValues: "X\xF3a ${variable} kh\u1ECFi gi\xE1 tr\u1ECB",
  addToLabels: "Th\xEAm ${variable} v\xE0o nh\xE3n",
  removeFromLabels: "X\xF3a ${variable} kh\u1ECFi nh\xE3n",
  resetColumns: "\u0110\u1EB7t L\u1EA1i C\u1ED9t",
  expandAll: "M\u1EDF R\u1ED9ng T\u1EA5t C\u1EA3 C\xE1c Nh\xF3m H\xE0ng",
  collapseAll: "\u0110\xF3ng T\u1EA5t C\u1EA3 C\xE1c Nh\xF3m H\xE0ng",
  copy: "Sao Ch\xE9p",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Sao Ch\xE9p K\xE8m Ti\xEAu \u0110\u1EC1",
  copyWithGroupHeaders: "Sao Ch\xE9p K\xE8m Ti\xEAu \u0110\u1EC1 Nh\xF3m",
  cut: "C\u1EAFt",
  paste: "D\xE1n",
  ctrlV: "Ctrl+V",
  export: "Xu\u1EA5t",
  csvExport: "Xu\u1EA5t CSV",
  excelExport: "Xu\u1EA5t Excel",
  columnFilter: "B\u1ED9 L\u1ECDc C\u1ED9t",
  columnChooser: "Ch\u1ECDn C\u1ED9t",
  chooseColumns: "Ch\u1ECDn C\u1ED9t",
  sortAscending: "S\u1EAFp X\u1EBFp T\u0103ng D\u1EA7n",
  sortDescending: "S\u1EAFp X\u1EBFp Gi\u1EA3m D\u1EA7n",
  sortUnSort: "X\xF3a S\u1EAFp X\u1EBFp",
  // Enterprise Menu Aggregation and Status Bar
  sum: "T\u1ED5ng",
  first: "\u0110\u1EA7u ti\xEAn",
  last: "Cu\u1ED1i c\xF9ng",
  min: "Nh\u1ECF nh\u1EA5t",
  max: "L\u1EDBn nh\u1EA5t",
  none: "Kh\xF4ng c\xF3",
  count: "\u0110\u1EBFm",
  avg: "Trung b\xECnh",
  filteredRows: "\u0110\xE3 l\u1ECDc",
  selectedRows: "\u0110\xE3 ch\u1ECDn",
  totalRows: "T\u1ED5ng s\u1ED1 h\xE0ng",
  totalAndFilteredRows: "H\xE0ng",
  more: "Th\xEAm",
  to: "\u0111\u1EBFn",
  of: "trong",
  page: "Trang",
  pageLastRowUnknown: "?",
  nextPage: "Trang k\u1EBF",
  lastPage: "Trang cu\u1ED1i",
  firstPage: "Trang \u0111\u1EA7u",
  previousPage: "Trang tr\u01B0\u1EDBc",
  pageSizeSelectorLabel: "K\xEDch c\u1EE1 trang:",
  footerTotal: "T\u1ED5ng",
  // Pivoting
  pivotColumnGroupTotals: "T\u1ED5ng c\u1ED9ng",
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Bi\u1EC3u \u0111\u1ED3 Pivot & Ch\u1EBF \u0111\u1ED9 Pivot",
  pivotChart: "Bi\u1EC3u \u0111\u1ED3 Pivot",
  chartRange: "Ph\u1EA1m vi Bi\u1EC3u \u0111\u1ED3",
  columnChart: "C\u1ED9t",
  groupedColumn: "Nh\xF3m",
  stackedColumn: "X\u1EBFp ch\u1ED3ng",
  normalizedColumn: "X\u1EBFp ch\u1ED3ng 100%",
  barChart: "Thanh",
  groupedBar: "Nh\xF3m",
  stackedBar: "X\u1EBFp ch\u1ED3ng",
  normalizedBar: "X\u1EBFp ch\u1ED3ng 100%",
  pieChart: "Bi\u1EC3u \u0111\u1ED3 Tr\xF2n",
  pie: "Tr\xF2n",
  donut: "V\xF2ng",
  line: "\u0110\u01B0\u1EDDng",
  xyChart: "X Y (Ph\xE2n t\xE1n)",
  scatter: "Ph\xE2n t\xE1n",
  bubble: "Bong b\xF3ng",
  areaChart: "Khu v\u1EF1c",
  area: "Khu v\u1EF1c",
  stackedArea: "X\u1EBFp ch\u1ED3ng",
  normalizedArea: "X\u1EBFp ch\u1ED3ng 100%",
  histogramChart: "Bi\u1EC3u \u0111\u1ED3 T\u1EA7n s\u1ED1",
  polarChart: "Bi\u1EC3u \u0111\u1ED3 C\u1EF1c",
  radarLine: "\u0110\u01B0\u1EDDng Radar",
  radarArea: "Khu v\u1EF1c Radar",
  nightingale: "Nightingale",
  radialColumn: "C\u1ED9t Radial",
  radialBar: "Thanh Radial",
  statisticalChart: "Th\u1ED1ng k\xEA",
  boxPlot: "Bi\u1EC3u \u0111\u1ED3 H\u1ED9p",
  rangeBar: "Thanh Ph\u1EA1m vi",
  rangeArea: "Khu v\u1EF1c Ph\u1EA1m vi",
  hierarchicalChart: "Ph\xE2n c\u1EA5p",
  treemap: "B\u1EA3n \u0111\u1ED3 C\xE2y",
  sunburst: "Sunburst",
  specializedChart: "Chuy\xEAn bi\u1EC7t",
  waterfall: "Th\xE1c n\u01B0\u1EDBc",
  heatmap: "B\u1EA3n \u0111\u1ED3 Nhi\u1EC7t",
  combinationChart: "K\u1EBFt h\u1EE3p",
  columnLineCombo: "C\u1ED9t & \u0110\u01B0\u1EDDng",
  AreaColumnCombo: "Khu v\u1EF1c & C\u1ED9t",
  // Charts
  pivotChartTitle: "Bi\u1EC3u \u0111\u1ED3 Pivot",
  rangeChartTitle: "Bi\u1EC3u \u0111\u1ED3 Ph\u1EA1m vi",
  settings: "Bi\u1EC3u \u0111\u1ED3",
  data: "Thi\u1EBFt l\u1EADp",
  format: "T\xF9y ch\u1EC9nh",
  categories: "Danh m\u1EE5c",
  defaultCategory: "(Kh\xF4ng c\xF3)",
  series: "Chu\u1ED7i",
  switchCategorySeries: "Chuy\u1EC3n \u0111\u1ED5i Danh m\u1EE5c / Chu\u1ED7i",
  categoryValues: "Gi\xE1 tr\u1ECB Danh m\u1EE5c",
  seriesLabels: "Nh\xE3n Chu\u1ED7i",
  aggregate: "T\u1ED5ng h\u1EE3p",
  xyValues: "Gi\xE1 tr\u1ECB X Y",
  paired: "Ch\u1EBF \u0111\u1ED9 Gh\xE9p \u0111\xF4i",
  axis: "Tr\u1EE5c",
  xAxis: "Tr\u1EE5c Ngang",
  yAxis: "Tr\u1EE5c D\u1ECDc",
  polarAxis: "Tr\u1EE5c C\u1EF1c",
  radiusAxis: "Tr\u1EE5c B\xE1n k\xEDnh",
  navigator: "\u0110\u1ECBnh h\u01B0\u1EDBng",
  zoom: "Thu ph\xF3ng",
  animation: "Ho\u1EA1t \u1EA3nh",
  crosshair: "\u0110\u01B0\u1EDDng c\u1EAFt ch\xE9o",
  color: "M\xE0u s\u1EAFc",
  thickness: "\u0110\u1ED9 d\xE0y",
  preferredLength: "Chi\u1EC1u d\xE0i \u01AFa th\xEDch",
  xType: "Lo\u1EA1i X",
  axisType: "Lo\u1EA1i Tr\u1EE5c",
  automatic: "T\u1EF1 \u0111\u1ED9ng",
  category: "Danh m\u1EE5c",
  number: "S\u1ED1",
  time: "Th\u1EDDi gian",
  timeFormat: "\u0110\u1ECBnh d\u1EA1ng Th\u1EDDi gian",
  autoRotate: "T\u1EF1 \u0111\u1ED9ng Xoay",
  labelRotation: "Xoay nh\xE3n",
  circle: "H\xECnh tr\xF2n",
  polygon: "H\xECnh \u0111a gi\xE1c",
  square: "H\xECnh vu\xF4ng",
  cross: "H\xECnh ch\u1EEF th\u1EADp",
  diamond: "H\xECnh kim c\u01B0\u01A1ng",
  plus: "D\u1EA5u c\u1ED9ng",
  triangle: "H\xECnh tam gi\xE1c",
  heart: "H\xECnh tr\xE1i tim",
  orientation: "H\u01B0\u1EDBng",
  fixed: "C\u1ED1 \u0111\u1ECBnh",
  parallel: "Song song",
  perpendicular: "Vu\xF4ng g\xF3c",
  radiusAxisPosition: "V\u1ECB tr\xED",
  ticks: "V\u1EA1ch",
  gridLines: "\u0110\u01B0\u1EDDng l\u01B0\u1EDBi",
  width: "Chi\u1EC1u r\u1ED9ng",
  height: "Chi\u1EC1u cao",
  length: "Chi\u1EC1u d\xE0i",
  padding: "\u0110\u1EC7m",
  spacing: "Kho\u1EA3ng c\xE1ch",
  chartStyle: "Phong c\xE1ch Bi\u1EC3u \u0111\u1ED3",
  title: "Ti\xEAu \u0111\u1EC1",
  chartTitles: "Ti\xEAu \u0111\u1EC1 Bi\u1EC3u \u0111\u1ED3",
  chartTitle: "Ti\xEAu \u0111\u1EC1 Bi\u1EC3u \u0111\u1ED3",
  chartSubtitle: "Ph\u1EE5 \u0111\u1EC1",
  horizontalAxisTitle: "Ti\xEAu \u0111\u1EC1 Tr\u1EE5c Ngang",
  verticalAxisTitle: "Ti\xEAu \u0111\u1EC1 Tr\u1EE5c D\u1ECDc",
  polarAxisTitle: "Ti\xEAu \u0111\u1EC1 Tr\u1EE5c C\u1EF1c",
  titlePlaceholder: "Ti\xEAu \u0111\u1EC1 Bi\u1EC3u \u0111\u1ED3",
  background: "N\u1EC1n",
  font: "Ph\xF4ng ch\u1EEF",
  weight: "Tr\u1ECDng l\u01B0\u1EE3ng",
  top: "Tr\xEAn",
  right: "Ph\u1EA3i",
  bottom: "D\u01B0\u1EDBi",
  left: "Tr\xE1i",
  labels: "Nh\xE3n",
  calloutLabels: "Nh\xE3n G\u1ECDi ra",
  sectorLabels: "Nh\xE3n Khu v\u1EF1c",
  positionRatio: "T\u1EF7 l\u1EC7 V\u1ECB tr\xED",
  size: "K\xEDch th\u01B0\u1EDBc",
  shape: "H\xECnh d\u1EA1ng",
  minSize: "K\xEDch th\u01B0\u1EDBc T\u1ED1i thi\u1EC3u",
  maxSize: "K\xEDch th\u01B0\u1EDBc T\u1ED1i \u0111a",
  legend: "Ch\xFA gi\u1EA3i",
  position: "V\u1ECB tr\xED",
  markerSize: "K\xEDch th\u01B0\u1EDBc \u0110\xE1nh d\u1EA5u",
  markerStroke: "\u0110\u01B0\u1EDDng vi\u1EC1n \u0110\xE1nh d\u1EA5u",
  markerPadding: "\u0110\u1EC7m \u0110\xE1nh d\u1EA5u",
  itemSpacing: "Kho\u1EA3ng c\xE1ch M\u1EE5c",
  itemPaddingX: "\u0110\u1EC7m M\u1EE5c X",
  itemPaddingY: "\u0110\u1EC7m M\u1EE5c Y",
  layoutHorizontalSpacing: "Kho\u1EA3ng c\xE1ch Ngang",
  layoutVerticalSpacing: "Kho\u1EA3ng c\xE1ch D\u1ECDc",
  strokeWidth: "\u0110\u1ED9 r\u1ED9ng \u0110\u01B0\u1EDDng",
  offset: "\u0110\u1ED9 l\u1EC7ch",
  offsets: "\u0110\u1ED9 l\u1EC7ch",
  tooltips: "Ch\xFA th\xEDch",
  callout: "G\u1ECDi ra",
  markers: "\u0110\xE1nh d\u1EA5u",
  shadow: "B\xF3ng",
  blur: "M\u1EDD",
  xOffset: "\u0110\u1ED9 l\u1EC7ch X",
  yOffset: "\u0110\u1ED9 l\u1EC7ch Y",
  lineWidth: "\u0110\u1ED9 r\u1ED9ng \u0110\u01B0\u1EDDng",
  lineDash: "\u0110\u01B0\u1EDDng Dash",
  lineDashOffset: "\u0110\u1ED9 l\u1EC7ch Dash",
  scrollingZoom: "Cu\u1ED9n",
  scrollingStep: "B\u01B0\u1EDBc Cu\u1ED9n",
  selectingZoom: "Ch\u1ECDn",
  durationMillis: "Th\u1EDDi l\u01B0\u1EE3ng (ms)",
  crosshairLabel: "Nh\xE3n",
  crosshairSnap: "B\u1EAFt \u0111i\u1EC3m",
  normal: "B\xECnh th\u01B0\u1EDDng",
  bold: "\u0110\u1EADm",
  italic: "Nghi\xEAng",
  boldItalic: "\u0110\u1EADm Nghi\xEAng",
  predefined: "\u0110\u1ECBnh ngh\u0129a tr\u01B0\u1EDBc",
  fillOpacity: "\u0110\u1ED9 m\u1EDD \u0110\u1ED5",
  strokeColor: "M\xE0u \u0110\u01B0\u1EDDng",
  strokeOpacity: "\u0110\u1ED9 m\u1EDD \u0110\u01B0\u1EDDng",
  miniChart: "Bi\u1EC3u \u0111\u1ED3 Nh\u1ECF",
  histogramBinCount: "S\u1ED1 l\u01B0\u1EE3ng Bin",
  connectorLine: "\u0110\u01B0\u1EDDng K\u1EBFt n\u1ED1i",
  seriesItems: "M\u1EE5c Chu\u1ED7i",
  seriesItemType: "Lo\u1EA1i M\u1EE5c",
  seriesItemPositive: "T\xEDch c\u1EF1c",
  seriesItemNegative: "Ti\xEAu c\u1EF1c",
  seriesItemLabels: "Nh\xE3n M\u1EE5c",
  columnGroup: "C\u1ED9t",
  barGroup: "Thanh",
  pieGroup: "Bi\u1EC3u \u0111\u1ED3 Tr\xF2n",
  lineGroup: "\u0110\u01B0\u1EDDng",
  scatterGroup: "X Y (Ph\xE2n t\xE1n)",
  areaGroup: "Khu v\u1EF1c",
  polarGroup: "C\u1EF1c",
  statisticalGroup: "Th\u1ED1ng k\xEA",
  hierarchicalGroup: "Ph\xE2n c\u1EA5p",
  specializedGroup: "Chuy\xEAn bi\u1EC7t",
  combinationGroup: "K\u1EBFt h\u1EE3p",
  groupedColumnTooltip: "Nh\xF3m",
  stackedColumnTooltip: "X\u1EBFp ch\u1ED3ng",
  normalizedColumnTooltip: "X\u1EBFp ch\u1ED3ng 100%",
  groupedBarTooltip: "Nh\xF3m",
  stackedBarTooltip: "X\u1EBFp ch\u1ED3ng",
  normalizedBarTooltip: "X\u1EBFp ch\u1ED3ng 100%",
  pieTooltip: "Bi\u1EC3u \u0111\u1ED3 Tr\xF2n",
  donutTooltip: "Bi\u1EC3u \u0111\u1ED3 V\xF2ng",
  lineTooltip: "\u0110\u01B0\u1EDDng",
  groupedAreaTooltip: "Khu v\u1EF1c",
  stackedAreaTooltip: "X\u1EBFp ch\u1ED3ng",
  normalizedAreaTooltip: "X\u1EBFp ch\u1ED3ng 100%",
  scatterTooltip: "Ph\xE2n t\xE1n",
  bubbleTooltip: "Bong b\xF3ng",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "C\u1ED9t Xuy\xEAn t\xE2m",
  radialBarTooltip: "Thanh Xuy\xEAn t\xE2m",
  radarLineTooltip: "\u0110\u01B0\u1EDDng Radar",
  radarAreaTooltip: "Khu v\u1EF1c Radar",
  nightingaleTooltip: "Bi\u1EC3u \u0111\u1ED3 Nightingale",
  rangeBarTooltip: "Thanh Ph\u1EA1m vi",
  rangeAreaTooltip: "Khu v\u1EF1c Ph\u1EA1m vi",
  boxPlotTooltip: "Bi\u1EC3u \u0111\u1ED3 H\u1ED9p",
  treemapTooltip: "Bi\u1EC3u \u0111\u1ED3 C\xE2y",
  sunburstTooltip: "Bi\u1EC3u \u0111\u1ED3 Sunburst",
  waterfallTooltip: "Bi\u1EC3u \u0111\u1ED3 Th\xE1c n\u01B0\u1EDBc",
  heatmapTooltip: "Bi\u1EC3u \u0111\u1ED3 Nhi\u1EC7t",
  columnLineComboTooltip: "C\u1ED9t & \u0110\u01B0\u1EDDng",
  areaColumnComboTooltip: "Khu v\u1EF1c & C\u1ED9t",
  customComboTooltip: "K\u1EBFt h\u1EE3p T\xF9y ch\u1EC9nh",
  innerRadius: "B\xE1n k\xEDnh Trong",
  startAngle: "G\xF3c B\u1EAFt \u0111\u1EA7u",
  endAngle: "G\xF3c K\u1EBFt th\xFAc",
  reverseDirection: "\u0110\u1EA3o ng\u01B0\u1EE3c H\u01B0\u1EDBng",
  groupPadding: "\u0110\u1EC7m Nh\xF3m",
  seriesPadding: "\u0110\u1EC7m Chu\u1ED7i",
  tile: "\xD4",
  whisker: "R\xE2u",
  cap: "N\u1EAFp",
  capLengthRatio: "T\u1EF7 l\u1EC7 Chi\u1EC1u d\xE0i",
  labelPlacement: "V\u1ECB tr\xED",
  inside: "B\xEAn trong",
  outside: "B\xEAn ngo\xE0i",
  noDataToChart: "Kh\xF4ng c\xF3 d\u1EEF li\u1EC7u \u0111\u1EC3 t\u1EA1o bi\u1EC3u \u0111\u1ED3.",
  pivotChartRequiresPivotMode: "Bi\u1EC3u \u0111\u1ED3 Pivot y\xEAu c\u1EA7u k\xEDch ho\u1EA1t Ch\u1EBF \u0111\u1ED9 Pivot.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Li\xEAn k\u1EBFt v\u1EDBi L\u01B0\u1EDBi",
  chartUnlinkToolbarTooltip: "H\u1EE7y li\xEAn k\u1EBFt t\u1EEB L\u01B0\u1EDBi",
  chartDownloadToolbarTooltip: "T\u1EA3i xu\u1ED1ng Bi\u1EC3u \u0111\u1ED3",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Ch\u1EC9nh s\u1EEDa Bi\u1EC3u \u0111\u1ED3",
  chartAdvancedSettings: "C\xE0i \u0111\u1EB7t N\xE2ng cao",
  chartLink: "Li\xEAn k\u1EBFt v\u1EDBi L\u01B0\u1EDBi",
  chartUnlink: "H\u1EE7y li\xEAn k\u1EBFt t\u1EEB L\u01B0\u1EDBi",
  chartDownload: "T\u1EA3i xu\u1ED1ng Bi\u1EC3u \u0111\u1ED3",
  histogramFrequency: "T\u1EA7n s\u1ED1",
  seriesChartType: "Lo\u1EA1i Bi\u1EC3u \u0111\u1ED3 Chu\u1ED7i",
  seriesType: "Lo\u1EA1i Chu\u1ED7i",
  secondaryAxis: "Tr\u1EE5c Ph\u1EE5",
  seriesAdd: "Th\xEAm m\u1ED9t chu\u1ED7i",
  categoryAdd: "Th\xEAm m\u1ED9t danh m\u1EE5c",
  bar: "Thanh",
  column: "C\u1ED9t",
  histogram: "Histogram",
  advancedSettings: "C\xE0i \u0111\u1EB7t N\xE2ng cao",
  direction: "H\u01B0\u1EDBng",
  horizontal: "Ngang",
  vertical: "D\u1ECDc",
  seriesGroupType: "Lo\u1EA1i Nh\xF3m",
  groupedSeriesGroupType: "Nh\xF3m",
  stackedSeriesGroupType: "X\u1EBFp ch\u1ED3ng",
  normalizedSeriesGroupType: "X\u1EBFp ch\u1ED3ng 100%",
  legendEnabled: "B\u1EADt",
  invalidColor: "Gi\xE1 tr\u1ECB m\xE0u s\u1EAFc kh\xF4ng h\u1EE3p l\u1EC7",
  groupedColumnFull: "C\u1ED9t Nh\xF3m",
  stackedColumnFull: "C\u1ED9t X\u1EBFp ch\u1ED3ng",
  normalizedColumnFull: "C\u1ED9t X\u1EBFp ch\u1ED3ng 100%",
  groupedBarFull: "Thanh Nh\xF3m",
  stackedBarFull: "Thanh X\u1EBFp ch\u1ED3ng",
  normalizedBarFull: "Thanh X\u1EBFp ch\u1ED3ng 100%",
  stackedAreaFull: "Khu v\u1EF1c X\u1EBFp ch\u1ED3ng",
  normalizedAreaFull: "Khu v\u1EF1c X\u1EBFp ch\u1ED3ng 100%",
  customCombo: "K\u1EBFt h\u1EE3p T\xF9y ch\u1EC9nh",
  // ARIA
  ariaAdvancedFilterBuilderItem: "${variable}. M\u1EE9c ${variable}. Nh\u1EA5n ENTER \u0111\u1EC3 ch\u1EC9nh s\u1EEDa.",
  ariaAdvancedFilterBuilderItemValidation: "${variable}. M\u1EE9c ${variable}. ${variable} Nh\u1EA5n ENTER \u0111\u1EC3 ch\u1EC9nh s\u1EEDa.",
  ariaAdvancedFilterBuilderList: "Danh s\xE1ch B\u1ED9 l\u1ECDc N\xE2ng cao",
  ariaAdvancedFilterBuilderFilterItem: "\u0110i\u1EC1u ki\u1EC7n L\u1ECDc",
  ariaAdvancedFilterBuilderGroupItem: "Nh\xF3m L\u1ECDc",
  ariaAdvancedFilterBuilderColumn: "C\u1ED9t",
  ariaAdvancedFilterBuilderOption: "T\xF9y ch\u1ECDn",
  ariaAdvancedFilterBuilderValueP: "Gi\xE1 tr\u1ECB",
  ariaAdvancedFilterBuilderJoinOperator: "To\xE1n t\u1EED K\u1EBFt n\u1ED1i",
  ariaAdvancedFilterInput: "\u0110\u1EA7u v\xE0o B\u1ED9 l\u1ECDc N\xE2ng cao",
  ariaChecked: "\u0111\xE3 \u0111\xE1nh d\u1EA5u",
  ariaColumn: "C\u1ED9t",
  ariaColumnGroup: "Nh\xF3m c\u1ED9t",
  ariaColumnFiltered: "C\u1ED9t \u0111\xE3 l\u1ECDc",
  ariaColumnSelectAll: "Chuy\u1EC3n \u0111\u1ED5i Ch\u1ECDn t\u1EA5t c\u1EA3 c\xE1c c\u1ED9t",
  ariaDateFilterInput: "\u0110\u1EA7u v\xE0o B\u1ED9 l\u1ECDc Ng\xE0y",
  ariaDefaultListName: "Danh s\xE1ch",
  ariaFilterColumnsInput: "\u0110\u1EA7u v\xE0o L\u1ECDc C\u1ED9t",
  ariaFilterFromValue: "L\u1ECDc t\u1EEB gi\xE1 tr\u1ECB",
  ariaFilterInput: "\u0110\u1EA7u v\xE0o B\u1ED9 l\u1ECDc",
  ariaFilterList: "Danh s\xE1ch L\u1ECDc",
  ariaFilterToValue: "L\u1ECDc \u0111\u1EBFn gi\xE1 tr\u1ECB",
  ariaFilterValue: "Gi\xE1 tr\u1ECB L\u1ECDc",
  ariaFilterMenuOpen: "M\u1EDF Menu B\u1ED9 l\u1ECDc",
  ariaFilteringOperator: "To\xE1n t\u1EED L\u1ECDc",
  ariaHidden: "\u1EA9n",
  ariaIndeterminate: "kh\xF4ng x\xE1c \u0111\u1ECBnh",
  ariaInputEditor: "Tr\xECnh ch\u1EC9nh s\u1EEDa \u0111\u1EA7u v\xE0o",
  ariaMenuColumn: "Nh\u1EA5n ALT xu\u1ED1ng \u0111\u1EC3 m\u1EDF menu c\u1ED9t",
  ariaFilterColumn: "Nh\u1EA5n CTRL ENTER \u0111\u1EC3 m\u1EDF b\u1ED9 l\u1ECDc",
  ariaRowDeselect: "Nh\u1EA5n SPACE \u0111\u1EC3 b\u1ECF ch\u1ECDn h\xE0ng n\xE0y",
  ariaRowSelectAll: "Nh\u1EA5n SPACE \u0111\u1EC3 chuy\u1EC3n \u0111\u1ED5i ch\u1ECDn t\u1EA5t c\u1EA3 c\xE1c h\xE0ng",
  ariaRowToggleSelection: "Nh\u1EA5n SPACE \u0111\u1EC3 chuy\u1EC3n \u0111\u1ED5i ch\u1ECDn h\xE0ng",
  ariaRowSelect: "Nh\u1EA5n SPACE \u0111\u1EC3 ch\u1ECDn h\xE0ng n\xE0y",
  ariaRowSelectionDisabled: "Ch\u1ECDn h\xE0ng b\u1ECB v\xF4 hi\u1EC7u ho\xE1 cho h\xE0ng n\xE0y",
  ariaSearch: "T\xECm ki\u1EBFm",
  ariaSortableColumn: "Nh\u1EA5n ENTER \u0111\u1EC3 s\u1EAFp x\u1EBFp",
  ariaToggleVisibility: "Nh\u1EA5n SPACE \u0111\u1EC3 chuy\u1EC3n \u0111\u1ED5i hi\u1EC3n th\u1ECB",
  ariaToggleCellValue: "Nh\u1EA5n SPACE \u0111\u1EC3 chuy\u1EC3n \u0111\u1ED5i gi\xE1 tr\u1ECB \xF4",
  ariaUnchecked: "ch\u01B0a \u0111\xE1nh d\u1EA5u",
  ariaVisible: "hi\u1EC3n th\u1ECB",
  ariaSearchFilterValues: "T\xECm ki\u1EBFm gi\xE1 tr\u1ECB l\u1ECDc",
  ariaPageSizeSelectorLabel: "K\xEDch th\u01B0\u1EDBc Trang",
  ariaChartMenuClose: "\u0110\xF3ng Menu Ch\u1EC9nh s\u1EEDa Bi\u1EC3u \u0111\u1ED3",
  ariaChartSelected: "\u0110\xE3 ch\u1ECDn",
  ariaSkeletonCellLoadingFailed: "H\xE0ng kh\xF4ng t\u1EA3i \u0111\u01B0\u1EE3c",
  ariaSkeletonCellLoading: "D\u1EEF li\u1EC7u h\xE0ng \u0111ang t\u1EA3i",
  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Nh\xF3m H\xE0ng",
  ariaValuesDropZonePanelLabel: "Gi\xE1 Tr\u1ECB",
  ariaPivotDropZonePanelLabel: "Nh\xE3n C\u1ED9t",
  ariaDropZoneColumnComponentDescription: "Nh\u1EA5n DELETE \u0111\u1EC3 x\xF3a",
  ariaDropZoneColumnValueItemDescription: "Nh\u1EA5n ENTER \u0111\u1EC3 thay \u0111\u1ED5i lo\u1EA1i t\u1ED5ng h\u1EE3p",
  ariaDropZoneColumnGroupItemDescription: "Nh\u1EA5n ENTER \u0111\u1EC3 s\u1EAFp x\u1EBFp",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " c\u1EE7a ",
  ariaDropZoneColumnComponentSortAscending: "t\u0103ng d\u1EA7n",
  ariaDropZoneColumnComponentSortDescending: "gi\u1EA3m d\u1EA7n",
  ariaLabelDialog: "H\u1ED9p tho\u1EA1i",
  ariaLabelColumnMenu: "Menu C\u1ED9t",
  ariaLabelColumnFilter: "B\u1ED9 L\u1ECDc C\u1ED9t",
  ariaLabelCellEditor: "Ch\u1EC9nh S\u1EEDa \xD4",
  ariaLabelSelectField: "Ch\u1ECDn Tr\u01B0\u1EDDng",
  // aria labels for rich select
  ariaLabelRichSelectField: "Tr\u01B0\u1EDDng ch\u1ECDn n\xE2ng cao",
  ariaLabelRichSelectToggleSelection: "Nh\u1EA5n SPACE \u0111\u1EC3 chuy\u1EC3n \u0111\u1ED5i l\u1EF1a ch\u1ECDn",
  ariaLabelRichSelectDeselectAllItems: "Nh\u1EA5n DELETE \u0111\u1EC3 b\u1ECF ch\u1ECDn t\u1EA5t c\u1EA3 c\xE1c m\u1EE5c",
  ariaLabelRichSelectDeleteSelection: "Nh\u1EA5n DELETE \u0111\u1EC3 b\u1ECF ch\u1ECDn m\u1EE5c",
  ariaLabelTooltip: "Ch\xFA gi\u1EA3i c\xF4ng c\u1EE5",
  ariaLabelContextMenu: "Tr\xECnh \u0111\u01A1n ng\u1EEF c\u1EA3nh",
  ariaLabelSubMenu: "Tr\xECnh \u0111\u01A1n ph\u1EE5",
  ariaLabelAggregationFunction: "H\xE0m t\u1ED5ng h\u1EE3p",
  ariaLabelAdvancedFilterAutocomplete: "T\u1EF1 \u0111\u1ED9ng ho\xE0n th\xE0nh b\u1ED9 l\u1ECDc n\xE2ng cao",
  ariaLabelAdvancedFilterBuilderAddField: "B\u1ED9 l\u1ECDc n\xE2ng cao - Th\xEAm tr\u01B0\u1EDDng",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "B\u1ED9 l\u1ECDc n\xE2ng cao - Ch\u1ECDn c\u1ED9t",
  ariaLabelAdvancedFilterBuilderOptionSelectField: "B\u1ED9 l\u1ECDc n\xE2ng cao - Ch\u1ECDn t\xF9y ch\u1ECDn",
  ariaLabelAdvancedFilterBuilderJoinSelectField: "B\u1ED9 l\u1ECDc n\xE2ng cao - Ch\u1ECDn to\xE1n t\u1EED n\u1ED1i",
  // ARIA Labels for the Side Bar
  ariaColumnPanelList: "Danh s\xE1ch C\u1ED9t",
  ariaFilterPanelList: "Danh s\xE1ch B\u1ED9 l\u1ECDc",
  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
  // Data types
  true: "\u0110\xFAng",
  false: "Sai",
  invalidDate: "Ng\xE0y kh\xF4ng h\u1EE3p l\u1EC7",
  invalidNumber: "S\u1ED1 kh\xF4ng h\u1EE3p l\u1EC7",
  january: "Th\xE1ng M\u1ED9t",
  february: "Th\xE1ng Hai",
  march: "Th\xE1ng Ba",
  april: "Th\xE1ng T\u01B0",
  may: "Th\xE1ng N\u0103m",
  june: "Th\xE1ng S\xE1u",
  july: "Th\xE1ng B\u1EA3y",
  august: "Th\xE1ng T\xE1m",
  september: "Th\xE1ng Ch\xEDn",
  october: "Th\xE1ng M\u01B0\u1EDDi",
  november: "Th\xE1ng M\u01B0\u1EDDi M\u1ED9t",
  december: "Th\xE1ng M\u01B0\u1EDDi Hai",
  // Time formats
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS SA/CH"
};
export { AG_GRID_LOCALE_BG, AG_GRID_LOCALE_BR, AG_GRID_LOCALE_CN, AG_GRID_LOCALE_CZ, AG_GRID_LOCALE_DE, AG_GRID_LOCALE_DK, AG_GRID_LOCALE_EG, AG_GRID_LOCALE_EN, AG_GRID_LOCALE_ES, AG_GRID_LOCALE_FI, AG_GRID_LOCALE_FR, AG_GRID_LOCALE_GR, AG_GRID_LOCALE_HK, AG_GRID_LOCALE_HR, AG_GRID_LOCALE_HU, AG_GRID_LOCALE_IL, AG_GRID_LOCALE_IR, AG_GRID_LOCALE_IT, AG_GRID_LOCALE_JP, AG_GRID_LOCALE_KR, AG_GRID_LOCALE_NL, AG_GRID_LOCALE_NO, AG_GRID_LOCALE_PK, AG_GRID_LOCALE_PL, AG_GRID_LOCALE_PT, AG_GRID_LOCALE_RO, AG_GRID_LOCALE_SE, AG_GRID_LOCALE_SK, AG_GRID_LOCALE_TR, AG_GRID_LOCALE_TW, AG_GRID_LOCALE_UA, AG_GRID_LOCALE_VN };