import { Component, computed, input, OnInit, signal } from '@angular/core';
import { DsBoxModule, DsToastService } from '@bmw-ds/components';
import { VehicleStayService } from '../shared/vehicle-stay/vehicle-stay.service';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import { DateService } from '../shared/date/date.service';
import { Lane } from '../shared/streams/lane.model';
import { VehicleAllButtonCellRendererComponent } from '../vehicle-all-button-cell-renderer/vehicle-all-button-cell-renderer.component';
import { ActionsCellRendererComponent } from '../actions-cell-renderer/actions-cell-renderer.component';
import { CustomerCellRendererComponent } from '../customer-cell-renderer/customer-cell-renderer.component';
import { DirectionCellRendererComponent } from '../direction-cell-renderer/direction-cell-renderer.component';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { SizeColumnsToContentStrategy, ColDef, GridOptions, GetRowIdFunc, GetRowIdParams, ModuleRegistry } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';

ModuleRegistry.registerModules([ClientSideRowModelModule, ColumnsToolPanelModule, SetFilterModule, MenuModule, FiltersToolPanelModule]);

@Component({
  selector: 'app-vehicles-list',
  standalone: true,
  imports: [
    DsBoxModule,
    AgGridAngular,
  ],
  templateUrl: './vehicles-list.component.html',
  styleUrl: './vehicles-list.component.scss'
})
export class VehiclesListComponent implements OnInit{
  lanes = input.required<Lane[]>();
  localeText = AG_GRID_LOCALE_DE;
  
  constructor(
    private vehicleService: VehicleStayService,
    private dateService: DateService,
    private toastService: DsToastService,
  ) { }

  async ngOnInit() {
    try {
      await this.vehicleService.loadAll();
    }
    catch(err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden der Fahrzeuge.'
      });
    }
  }

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }

  vehicle = computed(() => {
    const lanes = this.lanes();
    const laneIds = lanes.map(value => value.laneId);      
    const hoursToShow = 12;
    const minDate = new Date();
    minDate.setUTCHours(minDate.getUTCHours()-hoursToShow);
    const maxDate = new Date();
    maxDate.setUTCHours(maxDate.getUTCHours()+hoursToShow);
    return this.vehicleService.data().filter(vehicle =>
      laneIds.includes(vehicle.lane) &&
      vehicle.identifiedDatetime &&
      vehicle.identifiedDatetime >= minDate &&
      vehicle.identifiedDatetime <= maxDate
    );
  });

  columns = signal<ColDef<VehicleStay>[]>([
    {
      // valueGetter: d => this.lanes().filter(lane => lane.laneId === d.data?.lane)[0].direction,
      headerName: 'Richtung',
      cellRendererSelector: params => {
        return {
          component: DirectionCellRendererComponent,
          params: {value: this.lanes().filter(lane => lane.laneId === params.data?.lane)[0]}
        };
      },
      cellStyle: {textAlign: 'center', lineHeight: '1em'},
    },    
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      headerName: 'Kunde',
      cellRenderer: CustomerCellRendererComponent,
      cellStyle: {'white-space': 'normal'},
      wrapText: true,
      autoHeight: true,
    },
    {
      field: 'appointment.appointmentDatetime',
      valueFormatter: p => p.value?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      headerName: 'Termin',
    },
    {
      headerName: 'Warnung',
      cellRenderer: HintCellRendererComponent
    },
    {
      field: 'identifiedDatetime',
      valueFormatter: p => p.value?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      headerName: 'Zeit',
      sort: 'desc',
    },
    {
      headerName: 'Verheiratet',
      cellRenderer: VehicleAllButtonCellRendererComponent,
    },
    {
      valueGetter: d => this.lanes().filter(lane => lane.laneId === d.data?.lane)[0]?.name,
      headerName: 'Fahrstraße',
    },
    {
      filter: 'agNumberColumnFilter',
      valueGetter: d => d.data && d.data.identifiedDatetime && d.data.exitedDatetime && d.data.exitedDatetime > d.data.identifiedDatetime ? Math.round(this.dateService.getTimeDelta(d.data.exitedDatetime ? d.data.exitedDatetime : new Date(), d.data.identifiedDatetime)) : null,
      valueFormatter: p => {
        const msTotal = p.value;
        const minTotal = msTotal / 1000 / 60;
        const hours = Math.floor(minTotal / 60);
        const min = Math.round(minTotal % 60);
        return `${hours}h ${min.toString().padStart(2, "0")}m`;
      },
      headerName: 'Dauer',
    },
    {
      field: 'customerIsWaiting',
      headerName: 'Kunde wartet',
      hide: false,
      valueGetter: d => d.data?.customerIsWaiting ? "Ja" :  "",
    },
    {
      headerName: 'Firma',
      field: 'vehicle.customer.companyName',
    },
    {
      sortable: false,
      filter: false,
      hide: true,
      cellRendererSelector: params => {
        return {
          component: ActionsCellRendererComponent,
          params: {
            value: {
              lane: this.lanes().filter(lane => lane.laneId === params.data?.lane)[0],
              vehicle: params.data,
            }
          }
        };
      },
      cellStyle: {lineHeight: '1em'},
    }
  ]);
  gridOptions = signal<GridOptions>({
    statusBar: {
      statusPanels: [
          // { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          // { statusPanel: 'agFilteredRowCountComponent' },
          // { statusPanel: 'agSelectedRowCountComponent' },
          // { statusPanel: 'agAggregationComponent' }
      ]
  }});
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });

  public paginationPageSize = 6;
  public paginationPageSizeSelector: number[] | boolean = [6, 10, 25, 50, 100, 200, 500, 1000];

  public getRowId: GetRowIdFunc = (params: GetRowIdParams<VehicleStay>) =>
    params.data.id;
}
