import { Component, computed, input, Signal } from '@angular/core';
import { DsTabsModule } from '@bmw-ds/components';
import { VehicleStayService } from '../../shared/vehicle-stay/vehicle-stay.service';
import { VehicleStay } from '../../shared/vehicle-stay/vehicle-stay.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-daily-kpi',
  standalone: true,
  imports: [
    CommonModule,
    DsTabsModule
  ],
  templateUrl: './daily-kpi.component.html',
  styleUrl: './daily-kpi.component.scss'
})
export class DailyKpiComponent {
  /**
   * Service Signals
   */
  public serviceVehicleStays = input.required<VehicleStay[]>();
  
  public servicePunctuality: Signal<number> = computed(() => {
    const punctualVehicleStays = this.serviceVehicleStays().filter(vehicleStay => (vehicleStay.appointment && vehicleStay.identifiedDatetime) && vehicleStay.identifiedDatetime.getTime() <= vehicleStay.appointment?.appointmentDatetime.getTime());

    return punctualVehicleStays && this.serviceVehicleStays().length > 0 ? punctualVehicleStays.length / this.serviceVehicleStays().length * 100 : 0;
  });
  public servicePairing: Signal<number> = computed(() => {
    return this.serviceVehicleStays().filter(vehicleStay => vehicleStay.vehicle && vehicleStay.vehicle.pairing).length;
  });
  public serviceArrived: Signal<number> = computed(() => {
    return this.serviceVehicleStays().filter(vehicleStay => vehicleStay.appointment && vehicleStay.identifiedDatetime).length;
  });
  public serviceWithoutAppointment: Signal<number> = computed(() => {
    return this.serviceVehicleStays().filter(vehicleStay => !vehicleStay.appointment && vehicleStay.identifiedDatetime).length;
  });

  /**
   * Guest Signals
   */
  public guestVehicleStays = input.required<VehicleStay[]>();
  public guestArrived: Signal<number> = computed(() => {
    return this.guestVehicleStays().filter(vehicleStay => vehicleStay.identifiedDatetime).length;
  });
  public guestPaired: Signal<number> = computed(() => {
    return this.guestVehicleStays().filter(vehicleStay => vehicleStay.vehicle && vehicleStay.vehicle.pairing).length;
  });

  constructor(public vehicleStayService: VehicleStayService,
  ) {}
}
