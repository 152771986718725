import { Component, computed, effect, EventEmitter, input, Output, signal } from '@angular/core';
import { DsButtonModule, DsFormsModule, DsMessageModule, DsModalModule, DsSelectModule, DsToastService, SelectListOption } from '@bmw-ds/components';
import { FormsModule } from '@angular/forms';
import { CommonModule, JsonPipe } from '@angular/common';
import { PairingService } from '../shared/pairing/pairing.service';
import { PairingUpsert } from '../shared/pairing/pairing-upsert.model';
import { ValidLocationNameValidatorDirective } from '../shared/location.directive';
import { VisitReason } from '../shared/visit-reason.type';

@Component({
  selector: 'app-pairing-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DsFormsModule,
    DsModalModule,
    DsSelectModule,
    DsButtonModule,
    DsMessageModule,
    JsonPipe,
    ValidLocationNameValidatorDirective,
  ],
  templateUrl: './pairing-dialog.component.html',
  styleUrl: './pairing-dialog.component.scss'
})
export class PairingDialogComponent {
  @Output() dismissModalEvent = new EventEmitter<void>();
  @Output() pairingResultEvent = new EventEmitter<boolean>();

  private static instanceId = 0;
  public uniqueInstanceId = 'PairingDialogComponent-' + PairingDialogComponent.instanceId++;

  public license_plate = input.required<string>();
  public pairing = input<Partial<PairingUpsert> | null>();

  public reason = signal<VisitReason>("Gast");
  public isNew = computed(() => typeof this.pairing() === "undefined" || this.pairing() === null);
  public serviceOptions: SelectListOption[] = [
    { id: 'GA', label: 'GA' },
    { id: 'Gast', label: 'Gast' },
    { id: 'Service', label: 'Service' },
  ];
  public vin = signal<string>("");
  public guideNumber = signal(0);
  public customerIsWaiting = signal(false);

  constructor(
    private pairingService: PairingService,
    private toastService: DsToastService,
  ) {
    effect(() => {
      const pairing = this.pairing();
      this.reason.set(pairing?.reason ?? "Gast");
      this.guideNumber.set(pairing?.guideNumber ?? 0);
      this.vin.set(pairing?.vin ?? "");
    }, { allowSignalWrites: true });
  }

  public showModal = true;

  public async onSubmit() {
    try {
      await this.pairingService.upsertPairing(this.license_plate(), {
        reason: this.reason(),
        guideNumber: this.guideNumber(),
        vin: this.vin(),
        customerIsWaiting: this.customerIsWaiting(),
      }, this.isNew());
      this.toastService.pushToast({
        toastText: 'Verheiratung erfolgreich',
        tone: 'positive'
      });
    } catch (err) {
      this.toastService.pushToast({
        toastText: 'Es ist ein Fehler aufgetreten',
        tone: 'critical'
      });
    }
    this.dismissModalEvent.emit();
  }

  public dismissModal(): void {
    this.dismissModalEvent.emit();
  }

  public handleServiceSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      this.reason.set(item.id as VisitReason);
    }
  }
}
