import { Component } from '@angular/core';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-customer-cell-renderer',
  standalone: true,
  imports: [],
  templateUrl: './customer-cell-renderer.component.html',
  styleUrl: './customer-cell-renderer.component.scss'
})
export class CustomerCellRendererComponent {
  params?: ICellRendererParams<VehicleStay>;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
