import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { TitleStrategy, provideRouter } from '@angular/router';

import { routes } from './app.routes';

import {
 DsImprintModule, 
} from '@bmw-ds/components';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { CustomTitleStrategy } from './custom-title.strategy';
import { environment } from '../environments/environment';
import { AuthInterceptor, LogLevel, provideAuth } from 'angular-auth-oidc-client';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideHttpClient(),
    provideAnimations(),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    BrowserAnimationsModule,
    provideAnimations(),
    importProvidersFrom(
      DsImprintModule.forRoot({
      phone: '5555555',
      electronicContact: 'change-me@bmwgroup.com'
      }),
    ),
    provideAuth({
      config: {
        authority: environment.issuer,
        redirectUrl: environment.redirectURL,
        postLogoutRedirectUri: environment.redirectURL,
        clientId: environment.clientId,
        scope: 'openid profile email address bmwids organization groups',
        responseType: 'code',
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        secureRoutes: [environment.secureRouteRootUrl],
        historyCleanupOff: true,
        ignoreNonceAfterRefresh: true,
      }
    }),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ...environment.providers,
  ],
};