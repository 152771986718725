import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import debug from 'debug';
import { environment } from '../../../environments/environment';
import { catchError, firstValueFrom, retry, Subscription, tap } from "rxjs";
import { DataService } from "../data/data.service";
import { webSocket } from "rxjs/webSocket";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseBackendService<T> implements DataService<T> {
    private changeSubscription?: Subscription;

    protected abstract getEndpoint(): string;
    protected getWebsocketEndpoint?: () => string;

    public data = signal<T[]>([]);
    public dataChangesAreMonitored = signal<boolean>(false);
  
    private logDebug = debug('app:BaseDataService:log');
    private logError = debug('app:BaseDataService:error*');

    constructor(protected httpClient: HttpClient) {
      this.logDebug.log = console.log.bind(console);
      this.logError.log = console.error.bind(console);
    }
  
    protected mapBackendToFrontend(item: unknown): T {
      return item as T;
    }

    /**
     * Executed when a Websocket event occurs.
     * Should upsert data.
     * @param change untransferred data received by the websocket 
     */
    protected applyChangesToData?: (change: unknown) => void;

    public async loadAll(): Promise<T[]> {
      this.logDebug('Loading all');
      try {
        const url = environment.apiUrl + this.getEndpoint();
        const transferData = await firstValueFrom(this.httpClient.get<unknown[]>(url));
        const paredData = transferData.map(this.mapBackendToFrontend);
        this.data.set(paredData);
        this.logDebug('Loaded all');
        this.startWebsocketMonitoring();
        return paredData;
      } catch (err) {
        this.logError('Failed to load all: %O', err);
        throw err;
      }
    }
    
    private startWebsocketMonitoring() {
      if (typeof this.getWebsocketEndpoint === "undefined" || this.getWebsocketEndpoint().length === 0 || typeof this.applyChangesToData === "undefined" ) {
        this.logDebug('Skipping web socket creation because required methods are not overwritten');
        return;
      }
      if (typeof environment.wsUrl === "undefined" || environment.wsUrl === null || environment.wsUrl.length === 0) {
        this.logDebug('Skipping web socket creation because base url is not set');
        return;
      }
      const subUrl = this.getWebsocketEndpoint();
      if (typeof subUrl === "undefined" || subUrl === null || subUrl.length === 0) {
        this.logDebug('Skipping web socket creation because sub url is not defined');
        return;
      }
      if (typeof this.changeSubscription !== "undefined") {
        this.changeSubscription.unsubscribe();
        this.changeSubscription = undefined;
        this.dataChangesAreMonitored.set(false);
      }
      this.logDebug('Creating new web socket connection');
      try {
        const socket$ = webSocket<T>({
          url: environment.wsUrl + subUrl,
          closeObserver: {
            next: (event) => {
              this.logDebug('Connection closed %O', event);
            }
          },
          closingObserver: {
            next: (event) => {
              this.logDebug('Closing connection %O', event);
            }
          },
          openObserver: {
            next: (event) => {
              this.logDebug('Opening connection %O', event);
            }
          },
        });
        const changes$ = socket$.pipe(
          tap(value => this.logDebug('New value: %O', value)),
          catchError(err => {
            this.logError('WebSocket error: %O', err);
            throw err;
          }),
          retry({count: environment.reconnect.retries, delay: environment.reconnect.delayInSeconds}),
        );
        this.changeSubscription = changes$.subscribe(next => this.applyChangesToData!(next))
        this.dataChangesAreMonitored.set(true);
      } catch (err) {
        this.logError('Failed to start websocket monitoring: %O', err);
        throw err;
      }
    }
  }
  