import { Component, input, signal } from '@angular/core';
import { DsLoadingAreaModule, DsToastModule, } from '@bmw-ds/components';


import { JsonPipe } from '@angular/common';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { VehicleStay } from '../shared/vehicle-stay/vehicle-stay.model';
import GridExportEvent from '../visitors-and-appointments/grid-export-event';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';
import { VehicleStatusCellRendererComponent } from '../vehicle-status-cell-renderer/vehicle-status-cell-renderer.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, GridApi, GridReadyEvent, SideBarDef, ColDef, ICellRendererParams, GridOptions, SizeColumnsToContentStrategy, GetRowIdFunc, GetRowIdParams } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, MenuModule, SetFilterModule]);

@Component({
  selector: 'app-visitors-and-appointments-ag-grid',
  standalone: true,
  imports: [
    AgGridAngular,
    DsToastModule,
    DsLoadingAreaModule,
    JsonPipe,
  ],
  templateUrl: './visitors-and-appointments-ag-grid.component.html',
  styleUrl: './visitors-and-appointments-ag-grid.component.scss'
})
export class VisitorsAndAppointmentsAgGridComponent {
  appointments = input.required<VehicleStay[]>();
  gridApi!: GridApi;
  localeText = AG_GRID_LOCALE_DE;

  constructor(private gridExportEvent: GridExportEvent) {
    this.exportGridToCsv = this.exportGridToCsv.bind(this);
    this.gridExportEvent.getEventEmitter().subscribe(this.exportGridToCsv);
  }

  private exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv();
    }
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",

        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
        },
      },
    ],
    defaultToolPanel: "",
  };

  columns = signal<ColDef<VehicleStay>[]>([
    {
      field: 'appointment.appointmentDatetime',
      headerName: 'Termin',
      filter: 'agDateColumnFilter',
      valueGetter: (p) => p.data?.appointment?.appointmentDatetime,
      valueFormatter: p => p.data?.appointment?.appointmentDatetime ? `${p.data?.appointment?.appointmentDatetime?.toLocaleDateString()} ${p.data?.appointment?.appointmentDatetime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}` : "",
      sort: "asc",
    },
    {
      field: 'vehicle.customer.lastName',
      headerName: 'Kunde',
      cellRenderer: (params: ICellRendererParams) => {
        return params.data.vehicle?.customer?.lastName + ' ' + params.data.vehicle?.customer?.firstName;
      },
    },
    {
      field: 'vehicle.customer.driver',
      headerName: 'Fahrer',
    },
    {
      field: 'vehicle.customer.companyName',
      headerName: 'Firma',
      hide: true,
    },
    {
      field: 'vehicle.visitReason',
      headerName: 'Grund',
    },
    {
      headerName: 'Status',
      hide: false,
      cellRenderer: VehicleStatusCellRendererComponent,
    },
    {
      headerName: 'Verspätung',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'appointment.appointmentDatetime'
      }
    },
    {
      field: 'vehicle.licensePlate',
      headerName: 'Kennzeichen',
    },
    {
      field: 'vehicle.carType',
      headerName: 'Modell',
      hide: true,
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunftsdatum',
      filter: 'agDateColumnFilter',
      valueGetter: (params) => params.data?.identifiedDatetime ? params.data?.identifiedDatetime : params.data?.transitedDatetime,
      valueFormatter: (params) => {
        if (params.data?.identifiedDatetime) {
          return params.data?.identifiedDatetime.toLocaleDateString();
        }

        if (params.data?.transitedDatetime) {
          return params.data?.transitedDatetime.toLocaleDateString();
        }

        return "";
      },
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Ankunftszeit',
      valueGetter: (params) => {
        if (params.data?.identifiedDatetime) {
          return params.data?.identifiedDatetime.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        }

        if (params.data?.transitedDatetime) {
          return params.data?.transitedDatetime.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        }
        return "";
      },
    },
    {
      field: 'identifiedDatetime',
      headerName: 'Identifiziert',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.identifiedDatetime,
      valueFormatter: p => {
        if (p.data?.identifiedDatetime) {
          return `${p.data?.identifiedDatetime?.toLocaleDateString()} ${p.data?.identifiedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'transitedDatetime',
      headerName: 'Durchfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.transitedDatetime,
      valueFormatter: p => {
        if (p.data?.transitedDatetime) {
          return `${p.data?.transitedDatetime?.toLocaleDateString()} ${p.data?.transitedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'exitedDatetime',
      headerName: 'Ausfahrt',
      filter: 'agDateColumnFilter',
      valueGetter: p => p.data?.exitedDatetime,
      valueFormatter: p => {
        if (p.data?.exitedDatetime) {
          return `${p.data?.exitedDatetime?.toLocaleDateString()} ${p.data?.exitedDatetime?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
        }
        return "";
      },
      hide: true,
    },
    {
      field: 'vehicle.internalContact',
      headerName: 'Interner Kontakt',
    },
  ]);
  gridOptions = signal<GridOptions>({
  });
  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
      },
    ],
  };

  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }

  public paginationPageSize = 50;
  public paginationPageSizeSelector: number[] | boolean = [25, 50, 100, 200, 500, 1000];

  public getRowId: GetRowIdFunc = (params: GetRowIdParams<VehicleStay>) =>
    params.data.id;
}
