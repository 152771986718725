import { Injectable } from '@angular/core';
import { VehicleStayService } from './vehicle-stay.service';
import { VehicleStayTransfer } from './vehicle-stay-transfer.model';
import { VehicleStay } from './vehicle-stay.model';
import { BaseBackendService } from '../base-backend/base-backend.service';
import debug from 'debug';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleStayBackendService extends BaseBackendService<VehicleStay> implements VehicleStayService {

  protected override getEndpoint() {
    return "v1/vehicle/list";
  }

  protected override getWebsocketEndpoint = () => {
    return "v1/vehicle";
  }
  
  #logError = debug('app:VehicleStayBackendService:error*');

  constructor(httpClient: HttpClient) {
    super(httpClient);
    this.#logError.log = console.error.bind(console);
  }

  protected override mapBackendToFrontend(data: VehicleStayTransfer): VehicleStay {
    const {
      appointment,
      identifiedDatetime,
      exitedDatetime,
      transitedDatetime,
      ...otherFields
    } = data;

    const ret: VehicleStay = {
      identifiedDatetime: identifiedDatetime ? new Date(identifiedDatetime): undefined,
      exitedDatetime: exitedDatetime ? new Date(exitedDatetime): undefined,
      transitedDatetime: transitedDatetime ? new Date(transitedDatetime): undefined,
      ...otherFields
    };

    if (appointment) {
      const {
        appointmentDatetime,
        ...otherAppointmentFields
      } = appointment;
      ret.appointment = {
        appointmentDatetime: new Date(appointmentDatetime),
        ...otherAppointmentFields    
      };
    }

    return ret;
  }

  protected override applyChangesToData? = (change: unknown) => {
    const newTransferVehicle = change as VehicleStayTransfer;
    const newVehicle = this.mapBackendToFrontend(newTransferVehicle);
    if (typeof newVehicle.id === "undefined" || newVehicle.id === null || newVehicle.id === "") {
      this.#logError("Skipping unmatchable vehicle without license plate");
      return;
    }
    const currentData = this.data();
    let found = false;
    for (let i = 0; i<currentData.length; i++) {
      const oldVehicle = currentData[i];
      if (oldVehicle.id === newVehicle.id) {
        currentData[i] = newVehicle;
        found = true;
      }
    }
    if (!found) {
      this.data.set([newVehicle, ...currentData]);
    } else {
      this.data.set([...currentData]);
    }
  };
}
